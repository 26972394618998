import { styled } from "@mui/material";
import { COLORS } from "../../../../styles/themes/default/colors";
import { useMediaQuery, createTheme } from "@mui/material";

const InputFieldDark = styled("input")((darkTheme) => ({
  borderRadius: "8px",
  border: "1px solid red",
  fontFamily: "Montserrat !important",
  height: "2.625rem",
  "@media only screen and (max-width: 1400px) and (min-width: 1280px)": {
    height: "1.875rem",
    fontSize: "0.6875rem",
  },
  "&:hover": {
    border: `1px solid ${COLORS.purple}33`,
  },
  "&:focus": {
    border: `1px solid ${COLORS.purple}`,
    outline: "none",
  },
}));
export const MultipleSearch = ({
  placeholder,
  disabled,
  iconStart,
  iconEnd,
  message,
  messageType,
  onChange,
  css,
  value,
  onClick,
  darkTheme,
  register,
}) => {
  const theme1 = createTheme({
    breakpoints: {
      values: {
        sl: 1400,
      },
    },
  });

  const smallLaptop = useMediaQuery(theme1.breakpoints.down("sl"));
  let sx = {
    border:
      messageType === "success"
        ? "1px solid #51DA88"
        : messageType === "error"
        ? "1px solid #DA5151"
        : "1px solid rgba(189, 189, 189, 0.4)",
    backgroundImage: `url(${iconStart}), url(${iconEnd})`,
    backgroundPosition: smallLaptop
      ? "left 11px center, right 11px center"
      : "left 16px center, right 16px center",
    backgroundRepeat: "no-repeat",
    backgroundColor: "#FAFAFA",
    paddingLeft: iconStart ? "37px" : "6px",
    paddingRight: iconEnd ? "35px" : "16px",
    ...css,
  };

  return (
    <div>
      <InputFieldDark
        type="text"
        placeholder={placeholder}
        disabled={disabled}
        onClick={onClick}
        onChange={onChange}
        sx={sx}
        value={value}
        darkTheme={darkTheme}
      />
    </div>
  );
};
