import React from "react";
import { Route, Switch } from "react-router-dom";
import DnDAnalytics from "./D&DAnalytics";
import "../../styles/templates.scss";

const Analytics = ({ match }) => {
  const { path } = match;

  return (
    <div className="w100 d-flex justify-between pl16 pr16 pb16">
      <div className="w100 mw1920">
        <Switch>
          <Route path={path} component={DnDAnalytics} />
        </Switch>
      </div>
    </div>
  );
};

export default Analytics;
