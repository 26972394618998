import React, { useEffect, useState } from "react";
import Header from "./Header/Header";
import SingleLineChart from "./StatisticsComponents/SingleLineChart";

import "./styles/analytics.scss";
import "./styles/statistics.scss";
import HalfDonutChart from "./StatisticsComponents/HalfDonutChart";
import MultipleAreaPlot from "./StatisticsComponents/MultipleAreaChart";
import PortCharts from "./StatisticsComponents/PortCharts";
import GaugeBar from "./StatisticsComponents/GaugeBar";
import DisputeReason from "./StatisticsComponents/DisputeReason";
import apiFactory from "../Container/service/apiFactory";
import { CONTAINER_URL } from "../../utils/constants";
import { useQuery } from "react-query";
import alertService from "../../services/alertService";
import useStore from "../../store/store";
import TopDemurrageReasons from "./StatisticsComponents/TopDemurrageReasons";

const DnDAnalytics = () => {
  const [customers, setCustomers] = useState([]);
  const [ports, setPorts] = useState([]);
  const [searchQuery, setSearchQuery] = useState("");
  const currentUser = useStore((state) => state.currentUser);
  const [selectedItem, setSelectedItem] = useState("")
  const [portSwitch, setPortSwitch] = useState(false);

  const { isLoading, data, error } = useQuery({
    queryKey: ["containersData", searchQuery],
    queryFn: () => {
      return apiFactory
        .get(`${CONTAINER_URL}analytics/all${searchQuery}`)
        .then((res) => res.data);
    },
    staleTime: 0,
    cacheTime: 0,
    retry: false,
  });
  useEffect(() => {
    async function getFilters() {
      await apiFactory
        .get(`${CONTAINER_URL}analytics/filter_values`)
        .then((resp) => {
          setCustomers(resp?.data?.shippers);
          setPorts(resp?.data?.ports)
        })
        .catch((error) => alertService.error(1, "Problem on our side"));
    }
    getFilters();

  }, []);
  return (
    <div style={{ height: window.innerWidth > 1920 ? "100%" : "" }}>
      <Header
        user={currentUser}
        ports={ports}
        customers={customers}
        setSearchQuery={setSearchQuery}
        setSelectedItem={setSelectedItem}
      />
      <div className="d-flex f-dir-col h90">
        <div className="analytics_top_box d-flex gap16 flex1">
          <SingleLineChart
            color="#51DA88"
            title="Fees paid"
            series={data?.fees_paid}
            isLoading={isLoading}
          />
          <SingleLineChart
            color="#3AB7EC"
            title="Fees saved"
            subtitle="(cont.moved timely)"
            series={data?.fees_avoided}
            isLoading={isLoading}
          />
          <HalfDonutChart
            title="Due fees"
            dotColor="#FF1DA4"
            colors={["#FF1DA4", "#41BAEE"]}
            values={[
              data?.due_and_at_risk?.due_dem_count,
              data?.due_and_at_risk?.due_det_count,
            ]}
            total={
              data?.due_and_at_risk?.due_dem + data?.due_and_at_risk?.due_det
            }
            isLoading={isLoading}
          />
          <HalfDonutChart
            title="Current at-risk fees"
            subtitle="next 24 hours"
            dotColor="#DDDF6E"
            colors={["#C8DF6E", "#51DA87"]}
            values={[
              data?.due_and_at_risk?.at_risk_dem_count,
              data?.due_and_at_risk?.at_risk_det_count,
            ]}
            total={
              data?.due_and_at_risk?.at_risk_dem +
              data?.due_and_at_risk?.at_risk_det
            }
            isLoading={isLoading}
          />
        </div>
        <div className="analytics_bottom_box d-flex mt16">
          <div className="w75 d-flex f-dir-col gap16">
            <div className="d-flex gap16">
              {selectedItem ?
                <PortCharts
                  isLoading={isLoading}
                  data={data}
                  selectedItem={selectedItem}
                  setSelectedItem={setSelectedItem}
                />
                :
                <MultipleAreaPlot
                  isLoading={isLoading}
                  title={searchQuery.includes('port') ? "Top 3 customers" : "Top 3 ports"}
                  data={searchQuery.includes('port') ?
                    {
                      det: data?.top_3_shippers_for_port_det,
                      dem: data?.top_3_shippers_for_port_dem
                    } : {
                      det: data?.top_3_ports_det,
                      dem: data?.top_3_ports_dem
                    }}
                  clickable={!searchQuery.includes('port')}
                  setSelectedItem={setSelectedItem}
                  demDetSwitch={portSwitch}
                  setDemDetSwitch={setPortSwitch}
                />
              }
              <GaugeBar
                series={data?.dnd_relation}
                isLoading={isLoading}
                title={"D&D breakdown"}
              />
            </div>
            <div className="d-flex gap16">
              <MultipleAreaPlot
                isLoading={isLoading}
                title="Top 3 SSLs"
                data={{
                  det: data?.top_3_ssl_det,
                  dem: data?.top_3_ssl_dem
                }}
              />
              <TopDemurrageReasons isLoading={isLoading} data={data?.top_dem_reasons} />
            </div>
          </div>
          <div style={{ width: "24%", marginLeft: "16px" }}>
            <DisputeReason isLoading={isLoading} data={data?.dispute_reasons} />
          </div>
        </div>
      </div>
    </div>
  );
};
export default DnDAnalytics;
