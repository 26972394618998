import React from "react";

import "../styles/header.scss";
import Filter from "./Filters";

const Header = (props) => {
  return (
    <div className="d-flex justify-between mb8">
      <Filter
        user={props.user}
        ports={props.ports}
        customers={props.customers}
        setSearchQuery={props.setSearchQuery}
        setSelectedItem={props.setSelectedItem}
      />
    </div>
  );
};

export default Header;
