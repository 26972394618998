export const periods = [
  { label: "Free", color: "#75FA79" },
  { label: "1st", color: "#00FFFF" },
  { label: "2nd", color: "#D6FC51" },
  { label: "3rd", color: "#ED602A" },
];
export const colorDict = {
  "Out to Load": "#EA3CDB",
  "Vessel Departing": "#D6FC51",
  "On Water": "#00FFFF",
  "At Port": "#0055FF",
  "Vessel Arriving": "#BD00FF",
  "Out to Delivery": "#ED602A",
  "Empty Returned": "#75FA79",
};
export const bgColorToColor = {
  "#75FA79": "#1f1f1f",
  "#00FFFF": "#1f1f1f",
  "#D6FC51": "#1f1f1f",
  "#ED602A": "#f1f1f1",
};

export const perStatusOptions = ["On Time", "Behind"];
export const journeyStatusesOptions = [
  "Vessel Arriving",
  "Vessel Departing",
  "Empty Returned",
  "At Port",
  "On Water",
  "Out to Delivery",
  "Out to Load",
];
export const importExportOptions = ["Import", "Export"];

export const available_terminals = [
  "FENIX MARINE CONTAINER TERMINAL (FMS)",
  "LSA APM Terminal Pier 400( W185 )",
  "EVERPORT CONTAINER TERMINAL (ETS)",
  "WEST BASIN CONTAINER TERMINAL",
  "ITS-CONGLOBAL - WILMINGTON (DEPOT)",
  "ITS (INTL TRANSPORTATION SERVICES)",
  "ITS-CONGLOBAL - OAKLAND (REF) (DEPOT)",
  "TRAPAC OAKLAND",
  "American President Line",
  "UP - LATC (LTC )",
  "PACIFIC CONTAINER TERMINAL (PIER J)",
  "Long Beach Container Terminal , LLC",
  "LONG BEACH PIER T TERMINAL",
  "YTI (YUSEN TERMINAL INTL)",
  "BNSF HOBART (404)",
  "TRAPAC LOS ANGELES",
  "OAKLAND INTERNATIONAL CONTAINER TERMINAL (OICT)",
  "TRAPAC CONTAINER TERMINAL",
  "Long Beach Container Terminal , LLC",
  "4Gen Logistics",
  "STREET TURN - LAX",
  "UP RAIL - OAKLAND INTERMODAL FACILITY",
  "THREE HARBORS SERVICES - OAKLAND (DEPOT)",
  "Container Maintenance Co",
  "CALIFORNIA CARTAGE COMPANY",
  "LONG BEACH ITS PIER G ( Y309 )",
  "1717 Middle Harbor Rd Oakland, CA 94607",
  "301 HANJIN ROAD LONG BEACH, CA 90802",
  "ITS Pier G",
  "Ben E. Nutter Terminal",
  "APM TERMINALS - ELIZABETH",
  "PORT NEWARK CONTAINER TERMINAL",
  "MAHER NEWARK",
  "ELIZABETH MARINE TERMINAL (404)",
  "Elizabeth Marine TE",
  "GCT BAYONNE TERMINAL",
  "Kearny Tvt Conrail - Csx Railroad",
  "Marine Terminal Elizabeth.NJ",
  "PORT LIBERTY BAYONNE",
  "MARSH STREET DEPOT",
  "302 PORT JERSEY BLVD",
  "SOUTH FLORIDA CONTAINER TERMINAL (SFCT)",
  "HOKER'S POINT (TAM-PTB)",
  "PORT EVERGLADES TERMINAL",
  "SEABOARD MARINE - PORT OF MIAMI",
  "Miami POMTOC",
  "Quality Container Transport",
  "FLORIDA INTERNATIONAL TERMINAL",
  "SSA JACKSONVILLE",
  "JAXPORT ICTF AT DAMES POINT",
  "FEC - JACKSONVILLE",
  "FEC - MIAMI",
];

export const statusBoxClass = {
  "Out to Load": "purple_box",
  "Vessel Departing": "yellow_box",
  "On Water": "ligth_blue_box",
  "Vessel Arriving": "dark_purple_box",
  "At Port": "dark_blue_box",
  "Out to Delivery": "orange_box",
  "Empty Returned": "green_box",
};

export const timePeriods = [
  { start: "00:00", end: "01:00", time: "AM" },
  { start: "01:00", end: "02:00", time: "AM" },
  { start: "02:00", end: "03:00", time: "AM" },
  { start: "03:00", end: "04:00", time: "AM" },
  { start: "04:00", end: "05:00", time: "AM" },
  { start: "05:00", end: "06:00", time: "AM" },
  { start: "06:00", end: "07:00", time: "AM" },
  { start: "07:00", end: "08:00", time: "AM" },
  { start: "08:00", end: "09:00", time: "AM" },
  { start: "09:00", end: "10:00", time: "AM" },
  { start: "10:00", end: "11:00", time: "AM" },
  { start: "11:00", end: "12:00", time: "PM" },
  { start: "12:00", end: "01:00", time: "PM" },
  { start: "01:00", end: "02:00", time: "PM" },
  { start: "02:00", end: "03:00", time: "PM" },
  { start: "03:00", end: "04:00", time: "PM" },
  { start: "04:00", end: "05:00", time: "PM" },
  { start: "05:00", end: "06:00", time: "PM" },
  { start: "06:00", end: "07:00", time: "PM" },
  { start: "07:00", end: "08:00", time: "PM" },
  { start: "08:00", end: "09:00", time: "PM" },
  { start: "09:00", end: "10:00", time: "PM" },
  { start: "10:00", end: "11:00", time: "PM" },
  { start: "11:00", end: "12:00", time: "AM" },
];

export const centerUs = {
  lat: 37.0902,
  lng: -95.7129,
};
export const mapStyles = [
  {
    elementType: "geometry",
    stylers: [
      {
        color: "#212121",
      },
    ],
  },
  {
    elementType: "labels.icon",
    stylers: [
      {
        visibility: "off",
      },
    ],
  },
  {
    elementType: "labels.text.fill",
    stylers: [
      {
        color: "#757575",
      },
    ],
  },
  {
    elementType: "labels.text.stroke",
    stylers: [
      {
        color: "#212121",
      },
    ],
  },
  {
    featureType: "administrative",
    elementType: "geometry",
    stylers: [
      {
        color: "#757575",
      },
    ],
  },
  {
    featureType: "administrative.country",
    elementType: "labels.text.fill",
    stylers: [
      {
        color: "#9e9e9e",
      },
    ],
  },
  {
    featureType: "administrative.land_parcel",
    stylers: [
      {
        visibility: "off",
      },
    ],
  },
  {
    featureType: "administrative.locality",
    elementType: "labels.text.fill",
    stylers: [
      {
        color: "#bdbdbd",
      },
    ],
  },
  {
    featureType: "poi",
    elementType: "labels.text.fill",
    stylers: [
      {
        color: "#757575",
      },
    ],
  },
  {
    featureType: "poi.park",
    elementType: "geometry",
    stylers: [
      {
        color: "#181818",
      },
    ],
  },
  {
    featureType: "poi.park",
    elementType: "labels.text.fill",
    stylers: [
      {
        color: "#616161",
      },
    ],
  },
  {
    featureType: "poi.park",
    elementType: "labels.text.stroke",
    stylers: [
      {
        color: "#1b1b1b",
      },
    ],
  },
  {
    featureType: "road",
    elementType: "geometry.fill",
    stylers: [
      {
        color: "#2c2c2c",
      },
    ],
  },
  {
    featureType: "road",
    elementType: "labels.text.fill",
    stylers: [
      {
        color: "#8a8a8a",
      },
    ],
  },
  {
    featureType: "road.arterial",
    elementType: "geometry",
    stylers: [
      {
        color: "#373737",
      },
    ],
  },
  {
    featureType: "road.highway",
    elementType: "geometry",
    stylers: [
      {
        color: "#3c3c3c",
      },
    ],
  },
  {
    featureType: "road.highway.controlled_access",
    elementType: "geometry",
    stylers: [
      {
        color: "#4e4e4e",
      },
    ],
  },
  {
    featureType: "road.local",
    elementType: "labels.text.fill",
    stylers: [
      {
        color: "#616161",
      },
    ],
  },
  {
    featureType: "transit",
    elementType: "labels.text.fill",
    stylers: [
      {
        color: "#757575",
      },
    ],
  },
  {
    featureType: "water",
    elementType: "geometry",
    stylers: [
      {
        color: "#000000",
      },
    ],
  },
  {
    featureType: "water",
    elementType: "labels.text.fill",
    stylers: [
      {
        color: "#3d3d3d",
      },
    ],
  },
];
