import React from "react";

import ReactApexChart from "react-apexcharts";
import { formatMonths, handleGraphBarWidth, kFormatDataValues } from "../utils/constants";

const ColumnBarStatistics = (props) => {


  let series =
    [
      {
        name: "Fees paid",
        data: kFormatDataValues(props?.data?.fees_paid),
      },
      {
        name: "Fees saved",
        data: kFormatDataValues(props?.data?.fees_avoided),
      },
      {
        name: "Fees disputed",
        data: kFormatDataValues(props?.data?.fees_disputed),
      },
    ];

  let options = {
    chart: {
      type: "bar",
      toolbar: {
        show: true,
        tools: {
          download: false,
        },
      },
    },
    plotOptions: {
      bar: {
        horizontal: false,
        columnWidth: handleGraphBarWidth(props?.data?.months?.length, 2),
        borderRadius: 2,
        opacity: 1,
      },
    },
    legend: {
      show: false,
    },
    dataLabels: {
      enabled: false,
    },
    stroke: {
      colors: ["solid"],
      width: 2,//2
    },
    xaxis: {
      categories: formatMonths(props?.data?.months),
      labels: {
        style: {
          fontFamily: "Montserrat",
          colors: "#adadad",
          fontWeight: 600,
          fontSize: "12px",
        },
      },
      axisBorder: {
        show: true,
        color: "#f9f9f9",
        height: 3,
        width: "100%",
      },
      axisTicks: {
        show: true,
      },
    },
    grid: {
      show: true,
      strokeDashArray: 5,
      padding: {
        bottom: -5,
        right: -10,
        top: -10,
      },
    },
    yaxis: {
      labels: {
        formatter: function (value) {
          return `$${value.toFixed(1)}k`;
        },
        style: {
          fontFamily: "Montserrat",
          colors: "#adadad",
          fontWeight: 600,
          fontSize: "12px",
        },
      },
      axisBorder: {
        show: true,
        color: "#f9f9f9",
        width: 4,
        height: "100%",
      },
    },
    colors: ["#FF1DA4", "#51DA87", "#3AB7EC"],
    fill: {
      type: ["solid", "solid", "solid"],
    },
  };
  return (
    <div style={{ width: "75%" }}>
      <ReactApexChart
        options={options}
        series={series}
        type="bar"
        height={window.innerWidth > 1920 ? 361 : 241}
      />
    </div>
  );
};

export default ColumnBarStatistics;
