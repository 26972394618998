import { Typography, createTheme, useMediaQuery } from "@mui/material";
import React from "react";
import { COLORS } from "../../../styles/themes/default/colors";

export default function Status({ status, guest }) {
  const theme1 = createTheme({
    breakpoints: {
      values: {
        sl: 1400,
      },
    },
  });

  const smallLaptop = useMediaQuery(theme1.breakpoints.down("sl"));

  const titles = {
    0: "Draft:",
    1: "Opened:",
    2: "Approved:",
    3: "Cleared:",
    4: "Invoiced:",
    5: "Paid back:",
    6: "Rejected",
  };

  const subtitles = {
    0: "Submit demurrage charge to request approval",
    1: "Pending approval",
    2: guest
      ? "The Approval Authority approved this payment request"
      : "Attach a receipt and confirm a payment date",
    3: "Attach the invoice and validate  the due date",
    4: "Waiting on the customer payment  ",
    5: "The payment was paid back by the customer",
    6: "The Approval Authority rejected this payment request",
  };

  const progress =
    status === "SA" || status === ""
      ? 0
      : status === "OP"
      ? 1
      : status === "AP"
      ? 2
      : status === "PA"
      ? 3
      : status === "IN"
      ? 4
      : status === "CO"
      ? 5
      : 6;

  return (
    <div
      style={{
        width: "100%",
        backgroundColor: COLORS.ligth_grey,
        heigth: "100%",
        display: "flex",
      }}
    >
      {[1, 2, 3, 4, 5].map((id) => {
        return (
          <div
            key={id}
            style={{
              width: "21%",
              height: smallLaptop ? "65px" : "92px",
              borderRadius: "8px",
              backgroundColor: COLORS.off_white,
              marginRight: "1%",
              display: "flex",
              transition: "0.3s ease-in-out all",
              opacity: progress === 6 && id > 2 ? 0.4 : 1,
            }}
          >
            <div
              style={{
                "@media only screen and (max-width: 1400px) and (min-width: 1280px)": {
                  fontSize: "1.0625rem",
                },
                transition: "0.3s ease-in-out all",

                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                width: "49px",
                margin:
                  (progress === 6 && id > 2) ||
                  !(progress >= id || (progress === 0 && id === 1))
                    ? "8px 0px"
                    : "0px",
                borderRadius: "8px 0px 0px 8px",

                fontSize: smallLaptop ? "1.1rem" : "1.5rem",
                borderStyle: "solid",
                borderColor:
                  progress === 0 && id === 1
                    ? COLORS.primary
                    : COLORS.dark_grey,
                borderRightWidth:
                  progress < id || (progress === 6 && id > 2) ? "1px" : "0px",
                borderLeftWidth: progress === 0 && id === 1 ? "1px" : "0px",
                borderTopWidth: progress === 0 && id === 1 ? "1px" : "0px",
                borderBottomWidth: progress === 0 && id === 1 ? "1px" : "0px",
                fontWeight: "600",
                backgroundColor:
                  id === 2 && progress === 6
                    ? COLORS.error
                    : id > 2 && progress === 6
                    ? COLORS.off_white
                    : id === 5 && progress === 5
                    ? COLORS.success
                    : id === progress
                    ? COLORS.primary
                    : id < progress
                    ? COLORS.dark_grey
                    : COLORS.off_white,
                color:
                  progress === 0 && id === 1
                    ? COLORS.primary
                    : progress === 6 && id > 2
                    ? COLORS.dark_grey
                    : id <= progress
                    ? COLORS.white
                    : COLORS.dark_grey,
              }}
            >
              {id}
            </div>

            <div
              style={{
                padding: "16px",
                width: "80%",
                display: "flex",
                flexDirection: "column",
                justifyContent: "space-around",
              }}
            >
              <p
                style={{
                  fontSize: smallLaptop ? "11px" : "16px",
                  margin: 0,
                  color:
                    id === 5 && progress === 5
                      ? COLORS.success
                      : progress === id || (progress === 0 && id === 1)
                      ? COLORS.primary
                      : COLORS.dark_grey,
                  fontWeight: "600",
                }}
              >
                {progress === 0 && id === 1
                  ? titles[0]
                  : progress === 6 && id === 2
                  ? titles[6]
                  : titles[id]}
              </p>
              <Typography
                style={{
                  fontSize: smallLaptop ? "11px" : "14px",
                  margin: 0,
                  lineHeight: "1",
                  color:
                    progress === id || (progress === 0 && id === 1)
                      ? COLORS.off_black
                      : COLORS.dark_grey,
                }}
              >
                {progress === 0 && id === 1
                  ? subtitles[0]
                  : progress === 6 && id === 2
                  ? subtitles[6]
                  : subtitles[id]}
              </Typography>
            </div>
          </div>
        );
      })}
    </div>
  );
}
