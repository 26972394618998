import React from "react";

import { IconButton } from "@mui/material";
import { COLORS } from "../../../styles/themes/default/colors";
import MenuIcon from "@mui/icons-material/Menu";

import { AvatarIcon } from "../../../components/ProfilePicture";
import NotificationBell from "../../../assets/img/Navbar/notification_bell.svg";

const GuestApprovalNavbar = ({
  handleClick,
  openDrawer,
  setOpenDrawer,
  user,
}) => {
  return (
    <div style={{ width: "100%" }}>
      <nav
        className="navbar navbar-expand navbar-light"
        style={{
          display: "flex",
          padding: 19,
        }}
      >
        <div style={{ width: "15%" }}>
          <IconButton
            color="inherit"
            aria-label="open drawer"
            edge="start"
            onClick={() => {
              setOpenDrawer(!openDrawer);
            }}
            sx={{
              marginLeft: 2,
              display: { marginRight: "none" },
              outline: "none",
            }}
          >
            {!openDrawer && <MenuIcon />}
          </IconButton>
        </div>
        <div
          className="navbar-nav"
          style={{
            display: "flex",
            justifyContent: "space-between",
            width: "85%",
          }}
        >
          <p
            style={{
              margin: 0,
              fontWeight: "600",
              color: COLORS.primary,
              fontSize: "24px",
            }}
          >
            Demurage details
          </p>
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <button
              style={{
                border: "none",
                outline: "none",
                height: 42,
                background: "white",
                marginRight: "1rem",
                borderRadius: "8px",
                width: "50px",
              }}
              onClick={() => handleClick(true)}
            >
              <img src={NotificationBell} alt="bell" />
            </button>

            <button
              onClick={() => handleClick(true)}
              style={{
                outline: "none",
                height: 42,
                borderRadius: "8px",
                marginRight: "1rem",
                border: "none",
                backgroundColor: COLORS.off_white,
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                fontSize: "16px",
                color: "#8C8C8C",
                paddingRight: "16px",
              }}
            >
              <AvatarIcon
                src={
                  user?.picture_64
                    ? `data:image/png;base64,${user?.picture_64}`
                    : ""
                }
                firstName={user?.first_name}
                lastName={user?.last_name}
                style={{
                  width: "24px",
                  height: "24px",
                  fontSize: "10px",
                  margin: "9px 16px",
                  color: "#8C8C8C",
                  border: "1px solid #8C8C8C",
                  backgroundColor: COLORS.off_white,
                }}
              />
              {user?.first_name} {user?.last_name}
            </button>
          </div>
        </div>
      </nav>
    </div>
  );
};

export default GuestApprovalNavbar;
