import React from "react";
import { Route, Switch } from "react-router-dom";

import Details from "./UserDetail";
import UserList from "./UserList";

const Admin = ({ match }) => {
  const { path } = match;

  return (
    <div
      style={{
        padding: "1.5rem",
        width: "100%",
        display: "flex",
        justifyContent: "center",
      }}
    >
      <div style={{ width: "100%", maxWidth: "1920px" }}>
        <Switch>
          <Route exact path={`${path}/users/:user_id`} component={Details} />
          <Route path={`${path}/users`} component={UserList} />
        </Switch>
      </div>
    </div>
  );
};

export default Admin;
