import React, { useEffect, useState } from "react";
import Select from "../../../../components/Cdl/Select";

import whiteXIcon from "../../../../assets/img/white_x.svg";

import SearchIcon from "../../assets/search_icon.svg";
import OnTimeStatusIcon from "../../assets/ontime_icon.svg";
import JourneyStatusIcon from "../../assets/journey_status.svg";
import WatchListIcon from "../../assets/not_saved_purple.svg";
import WatchListActiveIcon from "../../assets/purple_saved.svg";
import ImportExportIcon from "../../assets/import_export.svg";
import ExcelIcon from "../../assets/excel_icon.svg";
import ResetIcon from "../../assets/reset_icon.svg";

import { useMediaQuery, createTheme } from "@mui/material";

import "../../styles/filters.scss";
import { COLORS } from "../../../../styles/themes/default/colors";
import {
  importExportOptions,
  journeyStatusesOptions,
  perStatusOptions,
} from "../../utils/contants";
import { MultipleSearch } from "./MultipleSearch";
import { CustomLoader } from "../../../../components/CustomLoader";
import containerStore from "../../../../store/containerStore";

const Filters = ({
  setStatisticFilter,
  watchList,
  setQueryParams,
  getExcelData,
  loadingExcel,
  loading,
  journeyStatus,
  setJourneyStatus,
  setChartStatusFilter,
  setMainColor,
}) => {
  const [perStatus, setPerStatus] = useState("");
  const [searchQuery, setSearchQuery] = useState("");
  const [importExport, setImportExport] = useState("");
  const [inputHasValue, setInputHasValue] = useState(false);
  const setGetContainerId = containerStore((state) => state.setGetContainerId)

  const containerUrlRouter = containerStore(
    (state) => state.containerUrlRouter
  );
  const setContainerUrlRouter = containerStore(
    (state) => state.setContainerUrlRouter
  );

  const theme1 = createTheme({
    breakpoints: {
      values: {
        sl: 1400,
      },
    },
  });

  const smallLaptop = useMediaQuery(theme1.breakpoints.down("sl"));

  const filterToString = () => {
    if (
      journeyStatus.length === 0 &&
      perStatus.length === 0 &&
      !searchQuery &&
      importExport.length === 0
    ) {
      return "";
    }
    let query = "";
    if (journeyStatus) {
      query += `&journey_statuses=${journeyStatus}`;
    }
    if (perStatus) {
      query += `&on_time_statuses=${perStatus === "On Time" ? "on_time" : "behind"
        }`;
    }
    if (searchQuery) {
      query += `&searches=${searchQuery}`;
    }
    if (importExport.length > 0 && !importExport.includes("All")) {
      query += `&export=${importExport.includes("Import") ? false : true}`;
    }

    return query;
  };

  const handleJourneyStatus = (value) => {
    setJourneyStatus(value);
    setStatisticFilter(value);
    setChartStatusFilter("");
    if (!value) setMainColor("");
  };

  useEffect(() => {
    setQueryParams(filterToString());
  }, [perStatus, journeyStatus, importExport]);

  const handleApply = (event) => {
    event.preventDefault();
    setQueryParams(filterToString());
    if (searchQuery) {
      setInputHasValue(true);
    } else {
      setInputHasValue(false);
    }
  };

  return (
    <div className="filterBar">
      <form onSubmit={handleApply} className="filterBar">
        {inputHasValue ? (
          <div
            style={{
              width: smallLaptop ? "150px" : "340px",
              backgroundColor: COLORS.off_black,
              color: COLORS.dark_grey,
              border: "none",
              borderRadius: "8px",
              padding: "8px",
              height: "42px",
              display: "flex",
              alignItems: "center",
              overflowX: "auto",
              overflowY: "hidden",
            }}
          >
            <img
              src={SearchIcon}
              alt=""
              style={{ marginRight: "8px", marginLeft: "11px" }}
            />
            <div
              style={{
                backgroundColor: "#343434",
                color: COLORS.off_white,
                borderRadius: "8px",
                padding: "4px 7px 4px 7px",
                flexGrow: 0,
                display: "inline-flex",
                alignItems: "center",
                fontSize: smallLaptop ? "10px" : "14px",
              }}
            >
              {/* <p style={{ margin: "0", width: "auto" }}>{searchQuery}</p> */}

              <p style={{ margin: "0" }}> {searchQuery} </p>
              <button
                onClick={() => {
                  setSearchQuery("");
                  handleApply();
                }}
                style={{
                  border: "none",
                  outline: "none",
                  backgroundColor: "transparent",
                }}
              >
                <img
                  src={whiteXIcon}
                  style={{
                    width: "10px",
                    height: "10px",
                    marginLeft: "2px",
                    marginBottom: "1px",
                  }}
                  alt=""
                />
              </button>
            </div>
          </div>
        ) : (
          <MultipleSearch
            iconStart={SearchIcon}
            placeholder="Search by container, BOL, location..."
            value={searchQuery}
            onChange={(e) => {
              setSearchQuery(e.target.value);
            }}
            css={{
              width: smallLaptop ? "150px" : "340px",
              backgroundColor: COLORS.off_black,
              color: COLORS.dark_grey,
              border: "none",
            }}
            darkTheme={true}
          />
        )}
      </form>

      <Select
        icon={OnTimeStatusIcon}
        value={perStatus}
        onChange={setPerStatus}
        placeholder="Schedule"
        css={{
          width: smallLaptop ? "130px" : "180px",
          marginLeft: "16px",
          backgroundColor: COLORS.off_black,
          border: "none",
        }}
        cssDiv={{
          marginLeft: "16px",
          width: smallLaptop ? "130px" : "180px",
          backgroundColor: COLORS.off_black,
          color: COLORS.dark_grey,
        }}
        options={perStatusOptions}
        darkTheme={true}
        closeOnSelect={true}
        type="select"
      />
      <Select
        value={journeyStatus}
        onChange={handleJourneyStatus}
        icon={JourneyStatusIcon}
        placeholder="Journey status"
        css={{
          width: smallLaptop ? "160px" : "250px",
          marginLeft: "16px",
          backgroundColor: COLORS.off_black,
          border: "none",
        }}
        cssDiv={{
          marginLeft: "16px",
          width: smallLaptop ? "160px" : "250px",
          backgroundColor: COLORS.off_black,
          color: COLORS.dark_grey,
        }}
        options={journeyStatusesOptions}
        darkTheme={true}
        closeOnSelect={true}
        type="select"
      />
      <Select
        value={importExport}
        onChange={setImportExport}
        icon={ImportExportIcon}
        placeholder="IM/EX"
        css={{
          width: smallLaptop ? "105px" : "160px",
          marginLeft: "16px",
          backgroundColor: COLORS.off_black,
          border: "none",
        }}
        cssDiv={{
          marginLeft: "16px",
          width: smallLaptop ? "105px" : "160px",
          backgroundColor: COLORS.off_black,
          color: COLORS.dark_grey,
        }}
        options={importExportOptions}
        darkTheme={true}
        closeOnSelect={true}
        type="select"
      />
      <div>
        <button
          type="button"
          className={
            watchList ? "watch_list_white_button" : "watch_list_button"
          }
          onClick={() => {
            setContainerUrlRouter([]);
            if (
              containerUrlRouter.length > 0 &&
              containerUrlRouter.at(-1).startsWith("visibility/favorite")
            ) {
              setContainerUrlRouter([]);
            } else {
              setContainerUrlRouter(["visibility/favorite"]);
            }
          }}
        >
          <img
            src={watchList ? WatchListActiveIcon : WatchListIcon}
            alt=""
            style={{ width: smallLaptop ? "10px" : "16px" }}
          />
        </button>
        <div
          className="hover_filter"
          style={{ transform: "translate(-10px,10px)" }}
        >
          <div className="hover_filter_inner_div">Watch list</div>
        </div>
      </div>
      <div>
        <button
          className="reset_button"
          onClick={() => {
            setQueryParams("");
            journeyStatus
              ? (() => {
                setJourneyStatus("");
                setStatisticFilter("");
              })()
              : setJourneyStatus("");
            setPerStatus("");
            setSearchQuery("");
            setImportExport("");
            setInputHasValue(false);
            setGetContainerId("")
          }}
        >
          <img
            src={ResetIcon}
            alt=""
            style={{
              marginTop: "6px",
              marginRight: "2px",
              width: smallLaptop ? "15px" : "",
            }}
          />
        </button>
        <div
          className="hover_filter"
          style={{ transform: "translate(7px,10px)" }}
        >
          <div className="hover_filter_inner_div">Reset</div>
        </div>
      </div>
      {!loadingExcel ? (
        <div>
          <button
            className="excel_button"
            onClick={getExcelData}
            disabled={loading}
          >
            <img
              src={ExcelIcon}
              alt=""
              style={{ width: smallLaptop ? "11px" : "" }}
            />
          </button>
          <div
            className="hover_filter"
            style={{ transform: "translate(5px,10px)" }}
          >
            <div className="hover_filter_inner_div">Export</div>
          </div>
        </div>
      ) : (
        <div
          style={{
            marginLeft: "16px",
            width: "42px",
            height: "42px",
            backgroundColor: COLORS.off_black,
            borderRadius: "8px",
            display: "flex",
            flex: 1,
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <CustomLoader
            darktheme={true}
            style={{ width: "26px", height: "26px" }}
          />
        </div>
      )}
    </div>
  );
};

export default Filters;
