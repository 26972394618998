import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import {
  Dialog,
  DialogContent,
  TextField,
  Button,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { styled } from "@mui/material/styles";

import { Formik, Form } from "formik";

import axios from "axios";
import { APP_URL } from "../../../utils/constants";

import PasswordChecklist from "react-password-checklist";
import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import closeModalIcon from "../../../assets/img/closeModalIcon.png";

import SuccessImg from "../../../assets/img/success.svg";
import ErrorImg from "../../../assets/img/Error.svg";

export default function GuestSetPasswordModal({
  openSetPassword,
  setOpenSetPassword,
  isSubmitting,
  setIsSubmitting,
  setOpenConfirmPassword,
  needRedirect,
  token,
  setUser,
}) {
  const [validationRule, setValidationRule] = useState(false);

  const [isVisiblePassword, setIsVisiblePassword] = useState(false);
  const [isVisibleConfirmPassword, setIsVisibleConfirmPassword] = useState(
    false
  );

  const history = useHistory();

  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");

  const theme = useTheme();
  const mdUp = useMediaQuery(theme.breakpoints.up("550"));

  const ActionButton = styled(Button)(({ theme }) => ({
    marginRight: "10px",
    background: "none",
    borderRadius: "8px",
    border: "none",
    fontFamily: "Montserrat !important",
    fontWeight: "600",
    "&:hover": {
      color: "#FF6B4A",
      background: "none",
    },
    "&:focus": {
      color: "#C82C0A",
      outline: "none",
      background: "none",
    },
  }));

  const ActionButtonSet = styled(Button)(({ theme }) => ({
    color: "white",
    background: "#EE4A26",
    borderRadius: "8px",
    border: "none",
    fontFamily: "Montserrat !important",
    fontWeight: "600",
    "&:hover": {
      background: "#FF6B4A",
      boxShadow: "2px 2px 5px rgba(238, 74, 38, 0.25)",
    },
    "&:focus": {
      background: "#C82C0A",
      outline: "none",
    },
    "&:disabled": {
      color: "white",
      background: "#EE4A26",
      opacity: 0.4,
    },
  }));

  const handleSetPassword = () => {
    if (password !== confirmPassword || password === "") {
      // add error message
      return;
    }
    setIsSubmitting(true);
    let data = {
      password: password,
    };
    axios
      .patch(`${APP_URL}/user/guest/setpassword/`, data, {
        headers: { Authorization: `${token}` },
      })
      .then((resp) => {
        setOpenSetPassword(false);
        axios
          .get(`${APP_URL}/user/guest/myprofile/`, {
            headers: {
              Authorization: `${token}`,
            },
          })
          .then((resp) => {
            setUser(resp.data.user);
          });
        if (needRedirect) {
          global.setCookie("");
          history.push("/");
        } else {
          setOpenConfirmPassword(true);
        }
      });
    setIsSubmitting(false);
  };

  return (
    <Dialog
      open={openSetPassword}
      onClose={() => {
        setOpenSetPassword(false);
        setPassword("");
        setConfirmPassword("");
      }}
    >
      <DialogContent style={{ padding: "0px" }}>
        <Formik onSubmit={handleSetPassword}>
          {({ errors, touched }) => (
            <Form>
              <div
                className="card-body"
                style={{ width: mdUp ? "30rem" : "20rem" }}
              >
                <div>
                  <div className="modalDivHeader">
                    <p className="titleModalText">Join DaaS</p>
                    <img src={closeModalIcon} className="closeModalImg" />
                  </div>
                  <p className="modalText">
                    We’re so glad you’ve chosen our platform!
                  </p>
                  <p className="modalText">
                    To help you get started, we kindly ask that you set up your
                    password first.
                  </p>
                </div>
                <div className="form-row">
                  <div className="form-group col">
                    <TextField
                      placeholder="Password"
                      name="password"
                      type={isVisiblePassword ? "text" : "password"}
                      className={`form-control${errors.address && touched.address ? " is-invalid" : ""
                        }`}
                      onChange={(e) => {
                        setPassword(e.target.value);
                      }}
                      InputProps={{
                        endAdornment: (
                          <Button
                            style={{ outline: "none" }}
                            onClick={() =>
                              setIsVisiblePassword(!isVisiblePassword)
                            }
                          >
                            {isVisiblePassword ? (
                              <VisibilityIcon style={{ color: "black" }} />
                            ) : (
                              <VisibilityOffIcon style={{ color: "black" }} />
                            )}
                          </Button>
                        ),
                      }}
                    />
                  </div>
                </div>
                <PasswordChecklist
                  style={{ marginTop: "3%", color: "#8C8C8C" }}
                  rules={[
                    "minLength",
                    "specialChar",
                    "number",
                    "capital",
                    "match",
                  ]}
                  iconComponents={{
                    ValidIcon: (
                      <img src={SuccessImg} style={{ margin: "5px 5px 0 0" }} />
                    ),
                    InvalidIcon: (
                      <img src={ErrorImg} style={{ margin: "5px 5px 0 0" }} />
                    ),
                  }}
                  minLength={8}
                  value={password}
                  valueAgain={confirmPassword}
                  onChange={(isValid) => {
                    setValidationRule(isValid);
                  }}
                />
                <div className="form-row">
                  <div className="form-group col">
                    <TextField
                      style={{ marginTop: "20px" }}
                      placeholder="Confirm password"
                      name="confirm-password"
                      type={isVisibleConfirmPassword ? "text" : "password"}
                      className={`form-control${errors.city && touched.city ? " is-invalid" : ""
                        }`}
                      onChange={(e) => {
                        setConfirmPassword(e.target.value);
                      }}
                      InputProps={{
                        endAdornment: (
                          <Button
                            style={{ outline: "none" }}
                            onClick={() =>
                              setIsVisibleConfirmPassword(
                                !isVisibleConfirmPassword
                              )
                            }
                          >
                            {isVisibleConfirmPassword ? (
                              <VisibilityIcon style={{ color: "black" }} />
                            ) : (
                              <VisibilityOffIcon style={{ color: "black" }} />
                            )}
                          </Button>
                        ),
                      }}
                    />
                  </div>
                </div>
                <div
                  className="form-row"
                  style={{
                    justifyContent: "end",
                    display: "flex",
                    marginTop: "20px",
                  }}
                >
                  <ActionButtonSet
                    onClick={handleSetPassword}
                    disabled={!validationRule}
                    variant="contained"
                    color="sunrise_orange"
                    sx={{ fontFamily: "Montserrat" }}
                  >
                    {isSubmitting && (
                      <span className="spinner-border spinner-border-sm mr-1" />
                    )}
                    Set Password
                  </ActionButtonSet>

                  <ActionButton
                    type="button"
                    variant="text"
                    color="sunrise_orange"
                    onClick={() => {
                      setOpenSetPassword(false);
                      setPassword("");
                      setConfirmPassword("");
                    }}
                  >
                    Cancel
                  </ActionButton>
                </div>
              </div>
            </Form>
          )}
        </Formik>
      </DialogContent>
    </Dialog>
  );
}
