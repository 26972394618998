import React from "react";
import PercentageBar from "./OptimizationPlan/PercentageBar";
import OptimizationMap from "./OptimizationPlan/OptimizationMap";

const OptimizationPlan = () => {

  return (
    <div className="template_box w25 flex-shrink_box">
      <p className="fw600 c-white mb8">Optimization plan</p>
      <PercentageBar />
      <OptimizationMap />
    </div>
  );
};
export default OptimizationPlan;
