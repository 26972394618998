import React from "react";
import { useHistory } from "react-router-dom";
import { COLORS } from "../../../../styles/themes/default/colors";
import OriginIcon from "../../assets/origin_icon.svg";
import MblIcon from "../../assets/mbl_icon.svg";
import TransitIcon from "../../assets/calendar.svg";
import { ReactComponent as VesselIcon } from "../../assets/ship_icon.svg";
import Co2WhiteIcon from "../../assets/co2_white_icon.svg";
import { ReactComponent as RailIcon } from "../../assets/rail_icon.svg";
import TruckIcon from "../../assets/grey_truck.svg";
import TypeIcon from "../../assets/type2_icon.svg";
import WeightIcon from "../../assets/weigth_icon.svg";
import Co2Icon from "../../assets/emmission_icon.svg";
import SteamshipLineIcon from "../../assets/steamshipline.svg";
import moreInfoIcon from "../../assets/info_icon.svg";
import VoyageIcon from "../../assets/voyageicon.svg";
import { formatNumber } from "../../utils/functions";
import { useMediaQuery, createTheme } from "@mui/material";
import esgStore from "../../../../store/esgStore";

const theme1 = createTheme({
  breakpoints: {
    values: {
      sl: 1400,
    },
  },
});

const formater = new Intl.NumberFormat({
  style: "currency",
  currency: "USD",
});

const GridInfo = ({ data }) => {
  const history = useHistory();
  const setOffsetContainerId = esgStore((state) => state.setOffsetContainerId);
  const smallLaptop = useMediaQuery(theme1.breakpoints.down("sl"));
  let vessels = "";
  if (data?.vessels) {
    data?.vessels.forEach((item) => {
      if (item && !vessels.includes(item)) {
        vessels += `${item} \n`;
      }
    });
  }
  let voyages = "";
  if (data?.voyages) {
    data?.voyages.forEach((item) => {
      if (item && !voyages.includes(item)) {
        voyages += `${item} \n`;
      }
    });
  }
  return (
    <div
      style={{
        width: "50%",
        display: "flex",
        padding: "16px",
        backgroundColor: "#090909",
        borderRadius: "8px",
      }}
    >
      <div
        style={{
          width: "50%",
          display: "flex",
          flexDirection: "column",
          justifyContent: "space-between",
        }}
      >
        <div style={{ display: "flex" }}>
          <div>
            <img
              src={OriginIcon}
              alt=""
              style={{
                marginBottom: "5px",
                width: smallLaptop ? "12px" : "16px",
              }}
            />
            <div
              style={{
                width: "1px",
                marginLeft: "6px",
                borderLeftStyle: "solid",
                height: "95%",
                borderLeftWidth: 1,
                borderLeftColor: COLORS.dark_grey,
              }}
            />
          </div>
          <div style={{ paddingLeft: "10px" }}>
            {data?.export ? (
              <>
                <p className="port_grid_title">Origin:</p>
                <div>
                  <p className="port_grid_main">
                    {" "}
                    {data?.origin?.facility_name ?? "_"}
                  </p>
                  <p className="port_grid_subtitle">
                    {data?.origin?.name ?? "_"}
                  </p>
                  <p className="port_grid_subtitle">
                    {data?.origin?.state ?? "_"}
                  </p>
                </div>
              </>
            ) : (
              <>
                <p className="port_grid_title">Port of Loading:</p>
                <div>
                  <p className="port_grid_main">
                    {data?.pol?.facility_name ?? "_"}
                  </p>
                  <p className="port_grid_subtitle">{data?.pol?.name ?? "_"}</p>
                  <p className="port_grid_subtitle">
                    {data?.pol?.state ?? "_"}
                  </p>
                </div>
              </>
            )}
          </div>
        </div>
        <div
          style={{
            width: "1px",
            marginLeft: "6px",
            borderLeftStyle: "solid",
            borderLeftWidth: 1,
            borderLeftColor: COLORS.dark_grey,
            height: "30%",
          }}
        />
        <div style={{ display: "flex" }}>
          <div>
            <img
              src={OriginIcon}
              alt=""
              style={{
                marginBottom: "5px",
                width: smallLaptop ? "12px" : "16px",
              }}
            />
            <div
              style={{
                width: "1px",
                marginLeft: "6px",
                height: "95%",

                borderLeftStyle: "solid",
                borderLeftWidth: 1,
                borderLeftColor: COLORS.dark_grey,
              }}
            />
          </div>
          <div style={{ paddingLeft: "10px" }}>
            {data?.export ? (
              <>
                <p className="port_grid_title">Port of Loading:</p>
                <div>
                  <p className="port_grid_main">
                    {" "}
                    {data?.pol?.facility_name ?? "_"}
                  </p>
                  <p className="port_grid_subtitle">{data?.pol?.name ?? "_"}</p>
                  <p className="port_grid_subtitle">
                    {data?.pol?.state ?? "_"}
                  </p>
                </div>
              </>
            ) : (
              <>
                <p className="port_grid_title">Port of Discharge:</p>
                <div>
                  <p className="port_grid_main">
                    {" "}
                    {data?.pod?.facility_name ?? "_"}
                  </p>
                  <p className="port_grid_subtitle">{data?.pod?.name ?? "_"}</p>
                  <p className="port_grid_subtitle">
                    {data?.pod?.state ?? "_"}
                  </p>
                </div>
              </>
            )}
          </div>
        </div>
        <div
          style={{
            width: "1px",
            marginLeft: "6px",
            borderLeftStyle: "solid",
            borderLeftWidth: 1,
            borderLeftColor: COLORS.dark_grey,
            height: "30%",
          }}
        />
        <div style={{ display: "flex" }}>
          <div>
            <img
              src={OriginIcon}
              alt=""
              style={{
                marginBottom: "5px",
                width: smallLaptop ? "12px" : "16px",
              }}
            />
          </div>
          <div style={{ paddingLeft: "10px" }}>
            {data?.export ? (
              <>
                <p className="port_grid_title">Port of Discharge:</p>
                <div>
                  <p className="port_grid_main">
                    {" "}
                    {data?.pod?.facility_name ?? "_"}
                  </p>
                  <p className="port_grid_subtitle">{data?.pod?.name ?? "_"}</p>
                  <p className="port_grid_subtitle">
                    {data?.pod?.state ?? "_"}
                  </p>
                </div>
              </>
            ) : (
              <>
                <p className="port_grid_title">Destination:</p>
                <div>
                  <p className="port_grid_main">
                    {data?.destination?.facility_name ?? "_"}
                  </p>
                  <p className="port_grid_subtitle">
                    {data?.destination?.name ?? "_"}
                  </p>
                  <p className="port_grid_subtitle">
                    {data?.destination?.state ?? "_"}
                  </p>
                </div>
              </>
            )}
          </div>
        </div>
      </div>
      <div style={{ width: "50%", height: "auto", position: "relative" }}>
        <div id="container_data">
          <div className="port_grid_box">
            <div className="port_grid_box_top">
              <img
                src={MblIcon}
                alt=""
                style={{
                  marginRight: "8px",
                  width: smallLaptop ? "12px" : "16px",
                }}
              />
              <p className="port_grid_title">Customer:</p>
            </div>
            <div style={{ paddingLeft: "24px" }}>
              <p className="port_grid_main">{data?.shipper_name ?? "_"}</p>
            </div>
          </div>
          <div className="port_grid_box">
            <div className="port_grid_box_top">
              <img
                src={SteamshipLineIcon}
                alt=""
                style={{
                  marginRight: "8px",
                  width: smallLaptop ? "12px" : "16px",
                }}
              />
              <p className="port_grid_title">Steamship line:</p>
            </div>
            <div style={{ paddingLeft: "24px" }}>
              <p className="port_grid_main">{data?.ssl_name ?? "_"}</p>
            </div>
          </div>
          <div className="port_grid_box">
            <div className="port_grid_box_top">
              <VesselIcon
                style={{
                  marginRight: "8px",
                  width: smallLaptop ? "12px" : "16px",
                  fill: COLORS.dark_grey,
                }}
              />
              <p className="port_grid_title">Vessels:</p>
            </div>
            <div style={{ paddingLeft: "24px" }}>
              <p className="port_grid_main" style={{ whiteSpace: "pre-wrap" }}>
                {vessels ?? "_"}
              </p>
            </div>
          </div>
          <div className="port_grid_box">
            <div className="port_grid_box_top">
              <img
                src={VoyageIcon}
                alt=""
                style={{
                  marginRight: "8px",
                  width: smallLaptop ? "12px" : "16px",
                }}
              />
              <p className="port_grid_title">Voyage:</p>
            </div>
            <div style={{ paddingLeft: "24px" }}>
              <p className="port_grid_main" style={{ whiteSpace: "pre-wrap" }}>
                {voyages ?? "_"}
              </p>
            </div>
          </div>
          <div className="port_grid_box">
            <div className="port_grid_box_top">
              <img
                src={MblIcon}
                alt=""
                style={{
                  marginRight: "8px",
                  width: smallLaptop ? "12px" : "16px",
                }}
              />
              <p className="port_grid_title">BOL:</p>
            </div>
            <div style={{ paddingLeft: "24px" }}>
              <p className="port_grid_main">{data?.mbl ?? "_"}</p>
            </div>
          </div>
          <div className="port_grid_box">
            <div className="port_grid_box_top">
              <img
                src={WeightIcon}
                alt=""
                style={{
                  marginRight: "8px",
                  width: smallLaptop ? "12px" : "16px",
                }}
              />
              <p className="port_grid_title">Weight:</p>
            </div>
            <div style={{ paddingLeft: "24px" }}>
              <p className="port_grid_main">
                {data?.weight ? `${formatNumber(data?.weight)}lb` : "_"}
              </p>
            </div>
          </div>
          <div className="port_grid_box">
            <div className="port_grid_box_top">
              <img
                src={TypeIcon}
                alt=""
                style={{
                  marginRight: "8px",
                  width: smallLaptop ? "12px" : "16px",
                }}
              />
              <p className="port_grid_title">Container type:</p>
            </div>
            <div style={{ paddingLeft: "24px" }}>
              <p className="port_grid_main">{data?.iso_type ?? "_"}</p>
            </div>
          </div>
          <div className="port_grid_box">
            <div className="port_grid_box_top">
              <img
                src={TransitIcon}
                alt=""
                style={{
                  marginRight: "8px",
                  width: smallLaptop ? "12px" : "16px",
                }}
              />
              <p className="port_grid_title">Vessel in transit:</p>
            </div>
            <div style={{ paddingLeft: "24px" }}>
              <p className="port_grid_main">
                {data?.days_in_transit
                  ? `${data?.days_in_transit}${data?.days_in_transit > 1 ? " days" : " day"
                  }`
                  : "_"}
              </p>
            </div>
          </div>
          <div>
            <div
              className="port_grid_box_top"
              style={{
                justifyContent: "space-between",
              }}
            >
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                }}
              >
                <img
                  src={Co2Icon}
                  alt=""
                  style={{
                    marginRight: "8px",
                    width: smallLaptop ? "12px" : "16px",
                  }}
                />
                <p className="port_grid_title">
                  CO<sub>2</sub> Emission:
                </p>
              </div>
              <button
                className="link_button"
                style={{ padding: 0 }}
                onClick={() => {
                  setOffsetContainerId(data?.container_id);
                  history.push("/esg/offset-projects");
                }}
              >
                Offset
              </button>
            </div>
            <div id="co2_main_box">
              <p className="port_grid_main">
                <span>&#8776;</span>{" "}
                {data?.co2_emission
                  ? `${formater.format(
                    parseFloat(
                      Object.values(data?.co2_emission).reduce(
                        (acc, curr) => {
                          return acc + curr;
                        },
                        0
                      )
                    ).toFixed(2)
                  )} KG`
                  : "_"}
              </p>
              <img className="co2_details_icon" src={moreInfoIcon} alt="" style={{ marginLeft: "16px" }} />
              <div id="co2_hover_box">
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    marginBottom: "16px",
                  }}
                >
                  <img src={Co2WhiteIcon} alt="" style={{ marginRight: "8px" }} />
                  <p className="co2_title">
                    CO<sub>2</sub> Emission:
                  </p>
                </div>

                <div className="port_grid_box">
                  <div className="port_grid_box_top">
                    <VesselIcon
                      style={{
                        marginRight: "8px",
                        width: smallLaptop ? "12px" : "16px",
                        fill: COLORS.dark_grey,
                      }}
                    />
                    <p className="port_grid_title">Vessels:</p>
                  </div>
                  <div style={{ paddingLeft: "24px" }}>
                    <p className="port_grid_main">
                      {`${formater.format(
                        parseFloat(data?.co2_emission?.vessel).toFixed(2)
                      )} KG`}{" "}
                    </p>
                  </div>
                </div>
                <div className="port_grid_box">
                  <div className="port_grid_box_top">
                    <img
                      style={{
                        marginRight: "8px",
                        width: smallLaptop ? "12px" : "16px",
                      }}
                      alt=""
                      src={TruckIcon}
                    />
                    <p className="port_grid_title">Truck:</p>
                  </div>
                  <div style={{ paddingLeft: "24px" }}>
                    <p
                      className="port_grid_main"
                      style={{ whiteSpace: "pre-wrap" }}
                    >
                      {`${formater.format(
                        parseFloat(data?.co2_emission?.truck).toFixed(2)
                      )} KG`}
                    </p>
                  </div>
                </div>
                <div>
                  <div className="port_grid_box_top">
                    <RailIcon
                      style={{
                        marginRight: "8px",
                        width: smallLaptop ? "12px" : "16px",
                        stroke: COLORS.dark_grey,
                      }}
                    />
                    <p className="port_grid_title">Rail:</p>
                  </div>
                  <div style={{ paddingLeft: "24px" }}>
                    <p
                      className="port_grid_main"
                      style={{ whiteSpace: "pre-wrap" }}
                    >
                      {`${formater.format(
                        parseFloat(data?.co2_emission?.rail).toFixed(2)
                      )} KG`}
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default GridInfo;
