import React from "react";
import { Typography, createTheme, useMediaQuery } from "@mui/material";
import { COLORS } from "../../../styles/themes/default/colors";
import { useState, useEffect } from "react";
import AutoComplete from "../../../components/Cdl/AutoComplete";
import "../styles/demurrage_styles.scss";
import LocationIcon from "../../../assets/img/DemurrageDetailAndRequest/location_icon.svg";
import MapComponent from "./GoogleMap";
import mapDotIcon from "../../Customer/CustomerProfile/components/assets/map_dot_icon.svg";
export default function DemurrageRequestLocation({
  setLocation,
  terminals,
  terminal,
  status,
  location,
  guest,
}) {
  const [value, setValue] = useState("");
  const isValid = location && Object.keys(location).length > 0;

  const theme1 = createTheme({
    breakpoints: {
      values: {
        sl: 1400,
      },
    },
  });

  const smallLaptop = useMediaQuery(theme1.breakpoints.down("sl"));

  return (
    <div className="location_outer_div">
      <div
        style={
          guest
            ? { height: smallLaptop ? "40%" : "55%" }
            : { height: smallLaptop ? "75%" : "75%" }
        }
      >
        <MapComponent
          coordinates={
            isValid ? { lat: location?.latitude, lng: location?.longitude } : {}
          }
        />
      </div>
      {isValid ? (
        <div className="location_inner_div">
          <div className="address_container">
            <Typography
              style={{
                fontWeight: 600,
                fontSize: smallLaptop ? "11px" : "16px",
              }}
            >
              Address:
            </Typography>

            <Typography
              style={{
                textTransform: "capitalize",
                fontSize: smallLaptop ? "11px" : "16px",
              }}
            >
              {location?.address?.toLowerCase()}
            </Typography>
            <Typography
              style={{
                textTransform: "capitalize",
                fontSize: smallLaptop ? "11px" : "16px",
              }}
            >{`${location?.city?.toLowerCase()}, ${
              location.state.name
            }`}</Typography>
            <Typography
              style={{
                textTransform: "capitalize",
                fontSize: smallLaptop ? "11px" : "16px",
              }}
            >
              {location?.postal_code?.toLowerCase()}
            </Typography>
          </div>
          <div>
            <Typography
              style={{
                textTransform: "capitalize",
                fontWeight: 600,
                fontSize: smallLaptop ? "11px" : "16px",
              }}
            >
              Facility type:
            </Typography>
            <Typography
              style={{
                textTransform: "capitalize",
                fontWeight: 600,
                fontSize: smallLaptop ? "11px" : "16px",
              }}
            >
              {location?.type?.toLowerCase()}
            </Typography>
          </div>
        </div>
      ) : (
        <div
          style={{
            width: "100%",
            height: smallLaptop ? "80px" : "110px",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            color: COLORS.dark_grey,
            fontSize: smallLaptop ? "11px" : "16px",
          }}
        >
          Terminal details will appear once you select it
        </div>
      )}
    </div>
  );
}
