import React from "react";
import { Box, Typography, createTheme, useMediaQuery } from "@mui/material";

import { Button } from "../../../components/Cdl/Button";

import DemurrageRequestLocation from "../../Demurrage/components/Location";
import ContainerSlider from "../../Demurrage/components/ContainerMini";
import StatusBar from "../../Demurrage/components/StatusBar";
import StatusBarMini from "../../Demurrage/components/Status";
import DemurrageComment from "../../Demurrage/components/Comments";
import DemurrageRequestDoc from "../../Demurrage/components/Documents";

import ShipperIcon from "../../../assets/img/Sidebar/customers_icon.svg";
import CarrierIcon from "../../../assets/img/DemurrageDetailAndRequest/Carrier.svg";
import CauseOfDemurrageIcon from "../../../assets/img/Sidebar/demurrages_icon.svg";

import { styled } from "@mui/material/styles";
import { formatDate } from "../../../utils/constants";

const theme1 = createTheme({
  breakpoints: {
    values: {
      xs: 0,
      smallMobile: 321,
      mobile: 376,
      middleMobile: 407,
      sm: 600,
      md: 900,
      lg: 1200,
      xl: 1536,
    },
  },
});

const smDown = theme1.breakpoints.down("sm");
const mdDown = theme1.breakpoints.down("md");

const GeneralInfoTypography = styled(Typography)(({ theme }) => ({
  margin: "0.7rem 0.7rem 0rem 0.7rem",
  fontSize: "14px",
  [mdDown]: {
    margin: 10,
  },
}));
const RowContainer = styled(Box)(({ theme }) => ({
  [mdDown]: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    width: "100%",
    border: "1px solid #DEDDDC",
    margin: "5px 5px 5px 0px",
  },
}));
const MainDiv = styled("div")({
  width: "99%",
  minWidth: "930px",
  paddingLeft: "10px",
  [mdDown]: {
    minWidth: "auto",
    width: "100%",
    padding: 0,
  },
});
const HeaderDiv = styled("div")({
  display: "flex",
  justifyContent: "space-between",
  [mdDown]: {
    flexDirection: "column",
    alignItems: "center",
  },
});
const GeneralInfoDiv = styled("div")({
  display: "flex",
  justifyContent: "space-between",
  width: "100%",
  marginTop: "10px",
});
const GeneralInfo = styled("div")({
  width: "70%",
  [mdDown]: {
    width: "100%",
  },
});
const BoxDiv = styled("div")({
  background: "white",
  borderRadius: "8px",
  padding: "15px 0 5px 0;",
  [mdDown]: {
    padding: "15px 15px 15px 15px;",
  },
  [smDown]: {
    padding: "15px 8px 8px 8px;",
  },
});

const ContainersDiv = styled("div")({
  borderRadius: "8px",
  background: "white",
  marginTop: "10px",
  padding: "15px 0 0",
});

const BoldHeader = styled("p")({
  marginLeft: "15px",
  fontWeight: "600",
  fontSize: "24px",
  [mdDown]: {
    paddingTop: "4px",
  },
});
const Table = styled("table")({
  borderTop: "1px solid rgba(189, 189, 189, 0.8)",
  margin: "0 0.3rem",
  width: "99%",
});
const TableHeaderCol = styled("th")({
  padding: "1rem 1.6rem",
  textAlign: "left",
  fontWeight: 500,
  color: "#8C8C8C",
});
const TableCell = styled("td")({
  paddingBottom: "0.7rem",
  textAlign: "left",
  paddingLeft: "50px",
});
const TableContainerHeader = styled("th")({
  padding: "1rem",
  textAlign: "center",
});
const TableContainerCell = styled("td")({
  padding: "0.7rem 2.5rem",
  textAlign: "center",
  borderBottom: "1px solid rgba(189, 189, 189, 0.2)",
});

const StatusBarContainer = styled("div")({
  width: "100%",
  display: "flex",
  alignItems: "center",
  [mdDown]: {
    width: "100%",
    padding: "25px 0",
  },
});

const TableHeader = styled("tr")({
  borderBottom: `1px solid rgba(189, 189, 189, 0.4)`,
});

const TotalAmountBox = styled("Box")({
  padding: "35px 15px 10px",
  display: "flex",
  justifyContent: "space-between",
});

export default function GuestApprovalDetails({
  approveRejectAction,
  demurrage,
  setNeedRedirect,
  user,
}) {
  const mdUp = useMediaQuery(theme1.breakpoints.up("md"));

  return (
    demurrage && (
      <>
        <MainDiv>
          <HeaderDiv>
            {mdUp && (
              <StatusBarContainer>
                <StatusBar
                  guest={true}
                  status={demurrage.status}
                  date={demurrage.status_change[0].date_updated}
                />
              </StatusBarContainer>
            )}

            {!mdUp && (
              <StatusBarContainer>
                <StatusBarMini
                  status={demurrage.status}
                  date={demurrage.status_change[0].date_updated}
                />
              </StatusBarContainer>
            )}

            {demurrage.status === "OP" ? (
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "space-between",
                }}
              >
                <Button
                  type="primary"
                  text="Approve"
                  onClick={() => {
                    approveRejectAction("AP");
                    setNeedRedirect(false);
                  }}
                  css={{ width: "130px", height: "42px" }}
                />

                <Button
                  type="secondary"
                  text="Reject"
                  onClick={() => {
                    approveRejectAction("RE");
                    setNeedRedirect(false);
                  }}
                  css={{ width: "130px", height: "42px" }}
                />
              </div>
            ) : (
              <></>
            )}
          </HeaderDiv>
          <GeneralInfoDiv>
            <GeneralInfo>
              <BoxDiv>
                <BoldHeader>Demurrage {demurrage?.dnd_id}</BoldHeader>
                {!mdUp ? (
                  <Box
                    style={{
                      display: "flex",
                      flexDirection: mdUp ? "row" : " column",
                    }}
                  >
                    <RowContainer sx={{ minWidth: "10rem" }}>
                      <GeneralInfoTypography
                        sx={{
                          fontWeight: 800,
                        }}
                      >
                        Shipper
                      </GeneralInfoTypography>
                      <GeneralInfoTypography
                        sx={{
                          textAlign: mdUp ? "" : "right",
                        }}
                      >
                        {demurrage.shipper.business_name
                          ? demurrage.shipper.business_name
                          : "_"}
                      </GeneralInfoTypography>
                    </RowContainer>
                    <RowContainer sx={{ minWidth: "11rem" }}>
                      <GeneralInfoTypography
                        sx={{
                          fontWeight: 800,
                        }}
                      >
                        Carrier
                      </GeneralInfoTypography>
                      <GeneralInfoTypography
                        sx={{
                          textAlign: mdUp ? "" : "right",
                        }}
                      >
                        {demurrage?.carrier ? demurrage?.carrier : "_"}
                      </GeneralInfoTypography>
                    </RowContainer>

                    <RowContainer sx={{ minWidth: "17rem" }}>
                      <GeneralInfoTypography
                        sx={{
                          fontWeight: 800,
                        }}
                      >
                        Cause of Demurrage
                      </GeneralInfoTypography>
                      <GeneralInfoTypography
                        sx={{
                          textAlign: mdUp ? "" : "right",
                        }}
                      >
                        {demurrage?.cause_of_demurrage?.cause
                          ? demurrage?.cause_of_demurrage?.cause
                          : "_"}
                      </GeneralInfoTypography>
                    </RowContainer>
                    <RowContainer sx={{ minWidth: "14rem" }}>
                      <GeneralInfoTypography
                        sx={{
                          fontWeight: 800,
                        }}
                      >
                        Pickup date
                      </GeneralInfoTypography>
                      <GeneralInfoTypography
                        sx={{
                          textAlign: mdUp ? "" : "right",
                        }}
                      >
                        {demurrage?.date_of_pickup
                          ? formatDate(demurrage?.date_of_pickup)
                          : "_"}
                      </GeneralInfoTypography>
                    </RowContainer>
                  </Box>
                ) : (
                  <Table>
                    <tr>
                      <TableHeaderCol>
                        <img
                          src={ShipperIcon}
                          style={{ margin: "0px 10px 3px 0" }}
                          alt=""
                        />
                        Shipper
                      </TableHeaderCol>
                      <TableHeaderCol>
                        <img
                          src={CauseOfDemurrageIcon}
                          style={{ margin: "0px 10px 3px 0" }}
                          alt=""
                        />
                        Cause of demurrage
                      </TableHeaderCol>
                      <TableHeaderCol>
                        <img
                          src={CarrierIcon}
                          style={{ margin: "0px 10px 3px 0" }}
                          alt=""
                        />
                        Carrier
                      </TableHeaderCol>
                    </tr>
                    <tr>
                      <TableCell>
                        {demurrage.shipper.business_name
                          ? demurrage.shipper.business_name
                          : "_"}
                      </TableCell>
                      <TableCell>
                        {demurrage?.cause_of_demurrage?.cause
                          ? demurrage?.cause_of_demurrage?.cause
                          : "_"}
                      </TableCell>
                      <TableCell>
                        {demurrage?.carrier ? demurrage?.carrier : "_"}
                      </TableCell>
                    </tr>
                  </Table>
                )}
              </BoxDiv>

              <ContainersDiv>
                <div style={{ display: "flex" }}>
                  <BoldHeader>Container information</BoldHeader>
                </div>
                {!mdUp ? (
                  <>
                    <ContainerSlider
                      style={{ padding: 100 }}
                      containers={demurrage.containers}
                      status={demurrage.status}
                      guest={true}
                    />
                    <TotalAmountBox>
                      <b>TOTAL AMOUNT</b>
                      <b>${parseFloat(demurrage.total_demurrage).toFixed(2)}</b>
                    </TotalAmountBox>
                  </>
                ) : (
                  <Table>
                    <TableHeader>
                      <TableContainerHeader>Container ID</TableContainerHeader>
                      <TableContainerHeader>Type</TableContainerHeader>
                      <TableContainerHeader>
                        Steamship Line
                      </TableContainerHeader>
                      <TableContainerHeader>MBL</TableContainerHeader>
                      <TableContainerHeader>Pickup number</TableContainerHeader>
                      <TableContainerHeader>Billing days</TableContainerHeader>
                      <TableContainerHeader>Amount</TableContainerHeader>
                    </TableHeader>
                    {demurrage.containers.length > 0 &&
                      demurrage.containers.map((item) => (
                        <tr>
                          <TableContainerCell>
                            {item.container_id ? item.container_id : "_"}
                          </TableContainerCell>
                          <TableContainerCell>
                            {item.container_info?.name
                              ? item.container_info?.name
                              : "_"}
                          </TableContainerCell>
                          <TableContainerCell>
                            {item.steamship_line?.name
                              ? item.steamship_line?.name
                              : "_"}
                          </TableContainerCell>
                          <TableContainerCell>
                            {item?.mbl ? item?.mbl : "_"}
                          </TableContainerCell>
                          <TableContainerCell>
                            {item.pickup_number ? item.pickup_number : "_"}
                          </TableContainerCell>
                          <TableContainerCell>
                            {item.dnd_duration ? item.dnd_duration : "_"}
                          </TableContainerCell>
                          <TableContainerCell style={{ textAlign: "right" }}>
                            ${item.demurrage_amount}
                          </TableContainerCell>
                        </tr>
                      ))}

                    <tr>
                      <TableContainerCell></TableContainerCell>
                      <TableContainerCell></TableContainerCell>
                      <TableContainerCell></TableContainerCell>
                      <TableContainerCell></TableContainerCell>
                      <TableContainerCell></TableContainerCell>
                      <TableContainerCell></TableContainerCell>
                      <TableContainerCell
                        style={{ width: "240px", textAlign: "right" }}
                      >
                        <b>TOTAL: </b>
                        <b style={{ color: "#EE4A26", marginLeft: "10px" }}>
                          ${parseFloat(demurrage.total_demurrage).toFixed(2)}
                        </b>
                      </TableContainerCell>
                    </tr>
                  </Table>
                )}
              </ContainersDiv>
              <div
                style={{
                  background: "white",
                  marginTop: "15px",
                  padding: "15px 15px 0px 15px",
                  borderRadius: "8px",
                }}
              >
                <BoldHeader style={{ margin: "0 15px" }}>Documents</BoldHeader>
                <div style={{ padding: "15px" }}>
                  <p>Attach receipt or invoice</p>
                  <DemurrageRequestDoc
                    documents={demurrage.documents}
                    isDetailPage={true}
                    status={demurrage.status}
                    user={user}
                  />
                  <p style={{ color: "#8C8C8C" }}>
                    Supported files: pdf, doc, docx.
                  </p>
                </div>
              </div>
            </GeneralInfo>
            <div style={{ width: "29%" }}>
              <div
                style={{
                  height: "500px",
                  background: "white",
                  borderRadius: "8px",
                  padding: "15px",
                }}
              >
                <BoldHeader>Terminal</BoldHeader>
                <DemurrageRequestLocation
                  showTerminal={demurrage.terminal}
                  location={demurrage.terminal}
                  status={demurrage.status}
                  guest={true}
                />
              </div>
              <div
                style={{
                  height: "300px",
                  background: "white",
                  borderRadius: "8px",
                  marginTop: "10px",
                  padding: "15px",
                }}
              >
                <BoldHeader>Note</BoldHeader>
                <DemurrageComment
                  comments={demurrage.comments}
                  demurrageId={demurrage.id}
                  disable={true}
                />
              </div>
            </div>
          </GeneralInfoDiv>
        </MainDiv>
      </>
    )
  );
}
