import React from "react";

import { COLORS } from "../styles/themes/default/colors";

const Tooltip = ({ color, text, type, left, status, title, display }) => {
  switch (type) {
    case "Customer status":
      let messageColor, message;
      if (status === true) {
        messageColor = COLORS.forest_green;
        message = "Customer is active";
      } else if (status === false) {
        messageColor = COLORS.blue;
        message = "Customer is inactive";
      } else if (status === null) {
        messageColor = COLORS.red;
        message = "Customer is yet to be activated";
      }
      return (
        <div
          className="customer-tooltip"
          style={{ backgroundColor: "white", color: messageColor }}
        >
          <p>{message}</p>
        </div>
      );
    case "Customer contract":
      return (
        <span className="customer-contract-tooltip">
          <p>{text}</p>
        </span>
      );
    case "Customer details":
      return (
        <span className="customer-details-tooltip">
          <p>Select to see the customer overview</p>
        </span>
      );
    case "MBL":
      return (
        <span className="demurrage-mbl-tooltip">
          <p>Master bill of lading</p>
        </span>
      );
    case "Status approval":
      return (
        <span className="demurage-status-approval-tooltip">
          <p>
            Demurrage request is waiting for an Admin to review and approve the
            payment.
          </p>
        </span>
      );
    case "Demurrage status":
      return (
        <span
          className="demurrage-status-tooltip"
          style={{ color: color, left: left }}
        >
          <p>{text}</p>
        </span>
      );
    case "Need attention":
      return (
        <div className="need-attention-tooltip">
          <p className="title">{title}</p>
          <p className="text">{text}</p>
        </div>
      );
    case "Progress bar":
      return (
        <div
          className="progress-bar-tooltip"
          style={{ visibility: display === true ? "visible" : "hidden" }}
        >
          <p className="text">{text}</p>
        </div>
      );
    case "McLeod":
      return (
        <div className="mcleod-tooltip">
          <p className="text">Company connected to McLeod</p>
        </div>
      );
  }
};

export default Tooltip;
