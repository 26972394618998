import React, { useEffect, useRef, useState } from "react";
import { COLORS } from "../../../../styles/themes/default/colors";
import moment from "moment";
import { ReactComponent as ShipIcon } from "../../assets/ship_icon_active.svg";
import { ReactComponent as TruckIcon } from "../../assets/truck.svg";
import { ReactComponent as TrainIcon } from "../../assets/rail_icon.svg";
import { useMediaQuery, createTheme } from "@mui/material";

import { countries } from "country-data";
import { formatDate } from "../../../../utils/constants";

const TrackingEventsView = ({ data, currentEvent, color }) => {
  const [divHeight, setDivHeight] = useState(0);
  const theme1 = createTheme({
    breakpoints: {
      values: {
        sl: 1400,
      },
    },
  });
  const smallLaptop = useMediaQuery(theme1.breakpoints.down("sl"));

  const listRef = useRef();
  const getIcon = (status) => {
    return ["Vessel Arriving", "Vessel Departing", "On Water"].includes(status)
      ? "ship"
      : "truck";
  };
  let progress = -1;
  if (data && data.length > 0) {
    data.forEach((item, index) => {
      if (JSON.stringify(item) === JSON.stringify(currentEvent)) {
        progress = index;
      }
    });
  }
  useEffect(() => {
    if (listRef?.current && progress !== -1) {
      const timer = setTimeout(() => {
        window.requestAnimationFrame(() => {
          listRef.current.scrollTo({
            top: progress > 3 ? (progress - 2) * 70 : progress * 70,
            behavior: "smooth",
          });
        });
      }, 100);
      return () => clearTimeout(timer);
    }
  });

  let data2 = [];
  if (data) {
    data2 = data.map((item, index) => {
      return {
        title: item.description,
        subtitle1: `${item.location_name}, ${
          item.location_state ? countries[item.location_state].name : ""
        }`,
        date: new Date(item.date),
        status: item.status,
        transport_type: item.transport_type,
        type: item.type,
      };
    });
  }

  useEffect(() => {
    if (document.getElementById("container_data")?.clientHeight) {
      setDivHeight(
        document.getElementById("container_data")?.clientHeight + 32
      );
    }
  }, [document.getElementById("container_data")?.clientHeight]);
  return (
    <div
      id="tracking_view_div"
      ref={listRef}
      style={{
        width: "50%",
        backgroundColor: "#090909",
        borderRadius: "8px",
        marginLeft: "8px",
        padding: "16px",
        overflow: "auto",
        height: `${divHeight}px`,
      }}
    >
      {data2.map((item, index) => {
        return (
          <div
            key={index}
            style={{
              display: "flex",
              height: "66px", // height: `${100 / data2.length}%`,
              // height:
              //   data2.length > 7 || data2.length === 0
              //     ? "70px"
              //     : `${100 / data2.length}%`,
            }}
          >
            <div style={{ width: "90px" }}>
              <p
                style={{
                  color:
                    progress === index
                      ? color
                      : index < progress
                      ? COLORS.off_white
                      : COLORS.dark_grey,
                  fontWeight: 600,
                  fontSize: smallLaptop ? "10px" : "14px",
                  margin: 0,
                }}
              >
                {formatDate(item.date)}
              </p>
              <p
                style={{
                  color:
                    index <= progress ? COLORS.off_white : COLORS.dark_grey,
                  fontSize: smallLaptop ? "10px" : "14px",
                  margin: 0,
                }}
              >
                {moment(item.date).format("LT")}
              </p>
            </div>

            <div
              style={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "flex-start",
                alignItems: "center",
                marginLeft: "16px",
              }}
            >
              {/* <ShipIcon
                style={{
                  width: "22px",
                  height: "22px",
                  stroke: progress === index ? color : "#8c8c8c",
                }}
              /> */}
              {item?.transport_type === "VESSEL" ? (
                <ShipIcon
                  style={{
                    width: smallLaptop ? "17px" : "22px",
                    height: smallLaptop ? "17px" : "22px",
                    fill:
                      progress === index
                        ? color
                        : index < progress
                        ? "#FAFAFA"
                        : "#8c8c8c",
                  }}
                />
              ) : item?.transport_type === "TRUCK" ? (
                <TruckIcon
                  style={{
                    width: smallLaptop ? "13px" : "18px",
                    height: smallLaptop ? "13px" : "18px",
                    fill:
                      progress === index
                        ? color
                        : index < progress
                        ? "#FAFAFA"
                        : "#8c8c8c",
                  }}
                />
              ) : item?.transport_type === "RAIL" ? (
                <TrainIcon
                  style={{
                    width: smallLaptop ? "13px" : "18px",
                    height: smallLaptop ? "13px" : "18px",
                    stroke:
                      progress === index
                        ? color
                        : index < progress
                        ? "#FAFAFA"
                        : "#8c8c8c",
                  }}
                />
              ) : item?.transport_type === null && item?.type === "land" ? (
                <TruckIcon
                  style={{
                    width: smallLaptop ? "13px" : "18px",
                    height: smallLaptop ? "13px" : "18px",
                    fill:
                      progress === index
                        ? color
                        : index < progress
                        ? "#FAFAFA"
                        : "#8c8c8c",
                  }}
                />
              ) : (
                <ShipIcon
                  style={{
                    width: smallLaptop ? "17px" : "22px",
                    height: smallLaptop ? "17px" : "22px",
                    fill:
                      progress === index
                        ? color
                        : index < progress
                        ? "#FAFAFA"
                        : "#8c8c8c",
                  }}
                />
              )}
              {/* {index !== data.length - 1 && ( */}
              <div
                style={{
                  width: "1px",
                  height: "100%",
                  borderLeftStyle: index <= progress ? "solid" : "dashed",
                  borderLeftWidth: index === data2.length - 1 ? 0 : 1,
                  borderLeftColor:
                    progress === index ? color : COLORS.dark_grey,
                }}
              />
            </div>
            <div style={{ marginLeft: "16px", width: "60%" }}>
              <p
                className="tracking_view_title"
                style={{
                  color:
                    progress === index
                      ? color
                      : index < progress
                      ? COLORS.off_white
                      : COLORS.dark_grey,
                  fontSize: smallLaptop ? "10px" : "14px",
                }}
              >
                {/* {item.title.length < 30
                  ? item.title
                  : item.title.slice(0, 30) + "..."} */}
                {item.title}
              </p>
              <p
                style={{
                  color:
                    progress === index ? COLORS.off_white : COLORS.dark_grey,
                  fontSize: smallLaptop ? "10px" : "14px",
                  margin: 0,
                }}
              >
                {item.subtitle1}
              </p>
            </div>
          </div>
        );
      })}
    </div>
  );
};
export default TrackingEventsView;
