import React from "react";
import { COLORS } from "../../../styles/themes/default/colors";
import { createTheme, useMediaQuery } from "@mui/material";

const CustomBadge = ({ number }) => {
  const theme1 = createTheme({
    breakpoints: {
      values: {
        sl: 1400,
      },
    },
  });
  const smallLaptop = useMediaQuery(theme1.breakpoints.down("sl"));
  return (
    <span
      style={{
        position: "absolute",
        top: "0px",
        left: smallLaptop ? "25px" : "30px",
        height: smallLaptop ? "13px" : "16px",
        width: smallLaptop ? "13px" : "16px",
        fontSize: smallLaptop ? "8px" : "10px",
        borderRadius: "25px",
        backgroundColor: COLORS.purple,
        fontWeight: 600,
        zIndex: 9999,
        color: "white",
      }}
    >
      {number}
    </span>
  );
};
export default CustomBadge;
