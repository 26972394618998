import React from "react";

import "../styles/demurrage_styles.scss";

import ShipperIcon from "../../../assets/img/Sidebar/customers_icon.svg";
import CarrierIcon from "../../../assets/img/DemurrageDetailAndRequest/Carrier.svg";
import CauseOfDemurrageIcon from "../../../assets/img/Sidebar/demurrages_icon.svg";
import { Typography, createTheme, useMediaQuery } from "@mui/material";
import { COLORS } from "../../../styles/themes/default/colors";

export default function GeneralInformationTable({
  shipper,
  carrier,
  cause,
  location,
}) {
  const theme1 = createTheme({
    breakpoints: {
      values: {
        sl: 1400,
      },
    },
  });

  const smallLaptop = useMediaQuery(theme1.breakpoints.down("sl"));

  const GeneralStyle = {
    width: "25%",
    display: "flex",
    alignItems: "flex-start",
  };

  const typographyStyle = {
    color: COLORS.dark_grey,
    marginBottom: "8px",
    fontSize: smallLaptop ? "0.685rem" : "1rem",
  };

  const typographyValueStyle = {
    fontSize: smallLaptop ? "0.685rem" : "1rem",
  };

  return (
    <div className="gi_row_table">
      <div style={GeneralStyle}>
        <img
          src={ShipperIcon}
          alt=""
          style={{ paddingTop: "5px", marginRight: "8px" }}
        />
        <div>
          <Typography style={typographyStyle}>Shipper</Typography>
          <Typography style={typographyValueStyle}>{shipper ?? "_"}</Typography>
        </div>
      </div>
      <div style={GeneralStyle}>
        <img
          src={CauseOfDemurrageIcon}
          alt=""
          style={{ paddingTop: "3px", marginRight: "8px" }}
        />
        <div>
          <Typography style={typographyStyle}>Cause of D&D</Typography>
          <Typography style={typographyValueStyle}>{cause ?? "_"}</Typography>
        </div>
      </div>
      <div style={GeneralStyle}>
        <img
          src={CarrierIcon}
          alt=""
          style={{ paddingTop: "2px", marginRight: "8px" }}
        />
        <div>
          <Typography style={typographyStyle}>Carrier</Typography>
          <Typography style={typographyValueStyle}>
            {" "}
            {carrier ? carrier : "_"}
          </Typography>
        </div>
      </div>
      <div style={GeneralStyle}>
        <img
          src={CarrierIcon}
          alt=""
          style={{ paddingTop: "2px", marginRight: "8px" }}
        />
        <div>
          <Typography style={typographyStyle}>Terminal</Typography>
          <Typography style={typographyValueStyle}>
            {" "}
            {location
              ? location.name.length > 32
                ? `${location.name.slice(0, 31)}...`
                : location.name
              : "_"}
          </Typography>
        </div>
      </div>
    </div>
  );
}
