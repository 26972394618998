import React from "react";
import { ReactComponent as LeftPaginationIcon } from "../assets/paginationLeftArrow.svg";
import { ReactComponent as RightPaginationIcon } from "../assets/paginationRightIcon.svg";
import { COLORS } from "../../../styles/themes/default/colors";

const ModalPagination = (props) => {
  return (
    <div className="d-flex gap16 align-center">
      <button
        onClick={() => {
          if (props.currentId !== 1) props.setCurrentId((prev) => prev - 1);
        }}
        style={{
          all: "unset",
          cursor: props.currentId === 1 ? "auto" : "pointer",
        }}
      >
        <LeftPaginationIcon
          fill={props.currentId === 1 ? COLORS.dark_grey : COLORS.off_white}
        />
      </button>
      <p className="c-white m0">
        {props.currentId}/{props.total}
      </p>
      <button
        onClick={() => {
          if (props.currentId !== props.total)
            props.setCurrentId((prev) => prev + 1);
        }}
        style={{
          all: "unset",
          cursor: props.currentId === props.total ? "auto" : "pointer",
        }}
      >
        <RightPaginationIcon
          fill={
            props.currentId === props.total
              ? COLORS.dark_grey
              : COLORS.off_white
          }
        />
      </button>
    </div>
  );
};
export default ModalPagination;
