export const formatMonths = (months) => {
    if (months?.length === 6) {
        return months?.map(month => month.substring(0,3));
    }
    else if (months?.length === 9 || months?.length === 12){
       return months?.map(month => month[0]); 
    }
    return months
}

export const handleGraphBarWidth = (numOfItems, widthRatio) => {
  return `${numOfItems * widthRatio}%`
}

export const kFormatDataValues = (values) => {
  return values?.map(value => value / 1000)
}

export const kFormatObjectValues = (objects) => {
  return objects = objects?.map(obj => {
    return {
      ...obj, 
      data: obj.data?.map(value => value / 1000)
    }
  })
}

export const calculateObjectPercentages = (data) => {
  if (!data) return;
  const total = data.total;
  let percentages = {};

  Object.keys(data).forEach(key => {
      if (key !== "total") {
          percentages[key] = Math.round(data[key] / total * 100);
      }
  });

  // Calculate sum of all fields excluding 'other' and 'total'
  let sumExcludingOther = Object.keys(percentages)
      .filter(key => key !== "other")
      .reduce((sum, key) => sum + parseInt(percentages[key]), 0);

  percentages["other"] = 100 - sumExcludingOther;

  return percentages;
};