import React from "react";
import ReactDOM from "react-dom/client";
import { Router, BrowserRouter } from "react-router-dom";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import history from "./router/history";
import "./styles/index.scss";

// try {
//   // accountService.refreshToken()
// } catch (error) {
// } finally {
//   startApp();
// }

// function startApp() {
//   render(
//     <Router history={history}>
//       <App />
//     </Router>,
//     document.getElementById("app")
//   );
// }

const app = ReactDOM.createRoot(document.getElementById("app"));
app.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
