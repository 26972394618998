import React from "react";
import { createTheme, useMediaQuery } from "@mui/material";

const theme1 = createTheme({
  breakpoints: {
    values: {
      sl: 1400,
    },
  },
});

export default function Note({ note, setNote }) {
  const smallLaptop = useMediaQuery(theme1.breakpoints.down("sl"));
  return (
    <textarea
      placeholder="Comment here ..."
      rows="5"
      onChange={(e) => {
        setNote(e.target.value);
      }}
      style={{
        width: "100%",
        border: "none",
        outline: "none",
        borderRadius: "8px",
        fontSize: "16px",
        boxSizing: "border-box",
        padding: "10px",
        height: "70%",
        fontSize: smallLaptop ? "11px" : "16px",
      }}
    />
  );
}
