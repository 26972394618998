import React from "react";

import CustomerRating from "./SecondColumnComponents/CustomerRating";
import RenewableOffset from "./SecondColumnComponents/RenewableOffset";
import EmissionAvoided from "./SecondColumnComponents/EmissionAvoided";

export default function EsgSecondColumn(props) {
  return (
    <div className="d-flex f-dir-col h100 gap16">
      <CustomerRating customer_rating={props.data.esg_customer_rating} />
      <RenewableOffset />
      <EmissionAvoided data={props.data} />
    </div>
  );
}
