import React from "react";
import InfoIcon from "../../assets/info_icon.svg";

const CartPaymentSummary = (props) => {
  return (
    <>
      <div className="cart_summary_div">
        <div className="cart_summary_row">
          <p className="c-white m0">Sub-total Amount</p>
          <p className="c-white m0">${parseFloat(props.value).toFixed(2)}</p>
        </div>
        <div className="cart_summary_row">
          <p className="c-white m0">Discount</p>
          <div style={{ display: "flex", gap: "8px", alignItems: "center" }}>
            <p className="c-dark-grey m0" style={{ fontSize: "14px" }}>
              (10%)
            </p>
            <p className="c-white m0">
              - ${parseFloat(props.value / 10).toFixed(2)}
            </p>
          </div>
        </div>
        <div className="cart_summary_row">
          <div style={{ display: "flex", gap: "8px", alignItems: "center" }}>
            <p className="c-white m0">Fee</p>
            <p className="c-dark-grey m0" style={{ fontSize: "14px" }}>
              (Fixed)
            </p>
          </div>
          <p className="c-white m0">+ $25.00</p>
        </div>
        <div className="cart_summary_row">
          <div style={{ display: "flex", alignItems: "center", gap: "8px" }}>
            <p className="c-white m0">Tax</p>
            <img src={InfoIcon} alt="info_icon" />
          </div>
          <p className="c-white m0">
            + ${parseFloat(props.value / 100).toFixed(2)}
          </p>
        </div>
      </div>
      <div className="cart_total_box">
        <p className="c-purple m0 fw600" style={{ fontSize: "24px" }}>
          Total
        </p>
        <p className="c-purple m0 fw600" style={{ fontSize: "24px" }}>
          $
          {parseFloat(
            props.value - props.value / 10 + 25 + props.value / 100
          ).toFixed(2)}
        </p>
      </div>
    </>
  );
};
export default CartPaymentSummary;
