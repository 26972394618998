import React from "react";
import AlertTable from "./AlertTable";
import AlertTableWithPayment from "./AlertTableWithPayment";
import Skeleton from "react-loading-skeleton";
import containerStore from "../../../../store/containerStore";

const AlertList = ({
  containers,
  loading,
  handleFavorite,
  handleRead,
  loadingContainer,
  setIsModalOpen,
  setIsDetentionModalOpen,
  clusterData,
  setClusterData,
}) => {
  const containerUrlRouter = containerStore(
    (state) => state.containerUrlRouter
  );
  const withPayment =
    containerUrlRouter.length > 0 &&
    containerUrlRouter.at(-1).startsWith("visibility/alert_containers");
  return (
    <div
      style={{
        width: "50%",
        marginLeft: "16px",
      }}
    >
      {loading ? (
        <Skeleton height={65} count={11} />
      ) : withPayment ? (
        <AlertTableWithPayment
          loading={loading}
          containers={containers}
          handleFavorite={handleFavorite}
          handleRead={handleRead}
          loadingContainer={loadingContainer}
          setIsModalOpen={setIsModalOpen}
          setIsDetentionModalOpen={setIsDetentionModalOpen}
          clusterData={clusterData}
          setClusterData={setClusterData}
        />
      ) : (
        <AlertTable
          loading={loading}
          containers={containers}
          pathname={containerUrlRouter.at(-1)}
          handleFavorite={handleFavorite}
          loadingContainer={loadingContainer}
        />
      )}
    </div>
  );
};

export default AlertList;
