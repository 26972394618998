import React, { useState, useEffect, useRef } from "react";
import { styled, createTheme, useMediaQuery } from "@mui/material";
import arrowDown from "../../assets/img/arrowDown.svg";
import dateIcon from "../../assets/img/dateIcon.svg";
import { Calendar } from "react-date-range";
import moment from "moment";
import { COLORS } from "../../styles/themes/default/colors";
import { parseISO } from "date-fns";
const ValueDiv = styled("div")(() => ({
  width: "200px",
  height: "42px",
  // background: "#FAFAFA",
  borderRadius: "8px",
  display: "flex",
  justifyContent: "space-between",
  "&:hover": { border: "1px solid rgba(238, 74, 38, 0.2)" },
  "@media only screen and (max-width: 1400px) and (min-width: 1280px)": {
    height: "30px",
  },
}));

export default function DatePickerSingle({
  value,
  onChange,
  placeholder,
  css,
  disabled,
  openAbove,
  error,
  minDate,
  darkTheme,
  alignRight,
}) {
  const theme1 = createTheme({
    breakpoints: {
      values: {
        sl: 1400,
      },
    },
  });
  const smallLaptop = useMediaQuery(theme1.breakpoints.down("sl"));

  const [isOpen, setIsOpen] = useState(false);
  const wrapperRef = useRef(null);

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const handleClickOutside = (event) => {
    const { current: wrap } = wrapperRef;
    if (wrap && !wrap.contains(event.target)) {
      setIsOpen(false);
    }
  };

  const toggleDatePickup = () => {
    if (!disabled) {
      setIsOpen(!isOpen);
    }
  };

  const handleSelect = (date) => {
    onChange(date);
    setIsOpen(false);
  };

  return (
    <div className="pos-rel" ref={wrapperRef}>
      <ValueDiv
        onClick={toggleDatePickup}
        style={{
          border: "none",
          // error && !value
          //   ? `1px solid ${COLORS.error}`
          //   : isOpen
          //   ? "1px solid #C82C0A"
          //   : "1px solid #BDBDBD66",
          ...css,
          backgroundColor: darkTheme ? "#313131" : COLORS.off_white,
        }}
      >
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            width: "100%",
          }}
        >
          <div style={{ display: "flex", alignItems: "center" }}>
            <img
              src={dateIcon}
              style={{
                marginLeft: "15px",
                height: smallLaptop ? "13px" : "20px",
                // marginTop: "10px",
              }}
              alt=""
            />
            <div
              className="selected-value"
              style={{
                paddingLeft: "10px",
                color: "#8C8C8C",
                fontSize: smallLaptop ? "11px" : "16px",
              }}
            >
              {value ? moment(value).format("L") : placeholder}
            </div>
          </div>
          <img
            src={arrowDown}
            style={{
              marginRight: "8px",
              height: "8px",
              // marginTop: smallLaptop ? "12px" : "17px",
            }}
            alt=""
          />
        </div>
      </ValueDiv>
      {isOpen && (
        <div
          style={{
            top: openAbove ? "-347px" : "43px",
            overflow: "auto",
            position: "absolute",
            borderRadius: "8px 8px 8px 8px",
            border: `1px solid ${darkTheme ? "#ff00b8" : "#EE4A26"}`,
            backgroundColor: darkTheme ? "#313131" : "#FAFAFA",
            zIndex: 1000,
            right: alignRight ? "0px" : "auto",
            left: alignRight ? "auto" : "0px",
          }}
        >
          <Calendar
            date={value}
            onChange={handleSelect}
            color={darkTheme ? "#ff00b8" : "#FF6B4A"}
            minDate={minDate}
            className={darkTheme ? "custom-calendar" : ""}
          />
        </div>
      )}
    </div>
  );
}
