import React from "react";

import ReactApexChart from "react-apexcharts";

import "../styles/statistics.scss";
import { COLORS } from "../../../styles/themes/default/colors";
import { kFormatter } from "../../../utils/constants";

const DonutChart = (props) => {

  const options = {
    series:
      props?.series &&
      props.series.map((item) => {
        return item.percentage;
      }),
    colors: ["#FF1DA4", COLORS.activity],
    options: {
      chart: {
        type: "donut",
        offsetY: 0,
        sparkline: {
          enabled: false,
        },
      },
      plotOptions: {
        pie: {
          donut: {
            size: "90%",
          },
        },
        bar: {
          width: 1,
        },
      },
      tooltip: {
        enabled: false,
      },
      fill: {
        colors:
          props?.series &&
          props.series.map((item) => {
            return item.color;
          }),
      },
      stroke: {
        stroke: 0.1,
        width: 0,
        lineCap: "round", // Make radial lines rounded
      },
      dataLabels: {
        enabled: false,
      },
      legend: {
        show: false,
      },
    },
  };
  return (
    <div
      style={{
        display:
          window.innerWidth > 1920 && !props.isPortChart ? "" : "flex",
        flexDirection: props.isPortChart ? "column" : "",
        width:
          window.innerWidth > 1920 && !props.isPortChart ? "100%" : "",
      }}
    >
      <div
        style={{
          position: "relative",
          display: "flex",
          justifyContent:
            window.innerWidth > 1920 && props.isPortChart
              ? ""
              : "center",
        }}
      >
        <ReactApexChart
          options={options.options}
          series={options.series}
          type="donut"
          height={window.innerWidth < 1921 && props.isPortChart ? 180 : 220}
          width={220}
        />
        <div
          style={{
            position: "absolute",
            top: "32%",
            display: "flex",
            alignItems: "center",
            flexDirection: "column",
            width: "100%",
          }}
        >
          <p className="chart_32px">{'$' + kFormatter(props?.total)}</p>
          <p className="chart_subtitle"> Total</p>
        </div>
      </div>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
        }}
      >
        {props.isPortChart ? (
          <div
            style={{
              display: "grid",
              gridTemplateColumns: "50% 50%",
              marginLeft: 12,
            }}
          >
            {props?.series &&
              props.series.map((item, index) => {
                return (
                  <div
                    key={index}
                    style={{
                      display: "flex",
                      alignItems: "center",
                      gap: "8px",
                      paddingBottom: window.innerWidth > 1920 ? "16px" : "0",
                    }}
                  >
                    <div
                      style={{ backgroundColor: item?.color }}
                      className="chart_dot"
                    />
                    <p className="white_14px">{item.title}</p>
                  </div>
                );
              })}
          </div>
        ) : (
          <div className="multiple_area_legend_box" style={{ gap: '8px' }}>
            {props?.series &&
              props.series.map((item, index) => {
                return (
                  <div
                    key={index}
                    style={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: 'space-between',
                      paddingBottom: window.innerWidth > 1920 ? "16px" : "0",
                    }}
                  >
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        gap: "8px",
                      }}
                    >
                      <div
                        style={{ backgroundColor: item?.color }}
                        className="chart_dot"
                      />
                      <p className="white_14px"
                        style={{
                          maxWidth: window.innerWidth > 1920 ? "" : "140px",
                        }}
                      >{item.title}</p>
                    </div>
                    <p className="dark_grey_14px">{'$' + kFormatter(parseFloat(item.percentage / 100 * props?.total))}</p>
                  </div>
                );
              })}
          </div>
        )}

      </div>
    </div>
  );
};

export default DonutChart;
