import React from "react";
import { Menu, MenuItem } from "@mui/material";
import { useState, useRef } from "react";
import { useHistory } from "react-router-dom";
import useStore from "../store/store";
import accountService from "../services/accountService";
import logoutIcon from "../assets/img/Logout_black.webp";
import BorderColorIcon from "@mui/icons-material/BorderColor";
import { AvatarIcon } from "./ProfilePicture";
import { COLORS } from "../styles/themes/default/colors";

export default function UserDropDown({ setCookie, darkMode }) {
  const [open, setOpen] = useState(null);
  const history = useHistory();
  const setLoggedUser = useStore((state) => state.setUser);
  const currentUser = useStore((state) => state.currentUser);

  const anchorRef = useRef(null);
  const handleClose = () => {
    setOpen(null);
  };
  const logout = () => {
    setLoggedUser({});

    accountService.logout();
    setCookie();
  };
  const iconStyles = {
    width: "1.4rem",
    height: "1.4rem",
    marginRight: "0.8rem",
  };

  return (
    <>
      <button
        ref={anchorRef}
        onClick={() => {
          history.push("/profile");
        }}
        className="navigationProfileButton"
        style={{
          outline: "none",
          backgroundColor: darkMode ? "#313131" : COLORS.off_white,
        }}
      >
        <AvatarIcon
          src={
            currentUser.user?.picture_64
              ? `data:image/png;base64,${currentUser.user.picture_64}`
              : ""
          }
          firstName={currentUser?.user?.first_name}
          lastName={currentUser?.user?.last_name}
          style={{
            width: "24px",
            height: "24px",
            fontSize: "10px",
            margin: "9px 16px",
            color: "#8C8C8C",
            border: "1px solid #8C8C8C",
            backgroundColor: COLORS.off_white,
          }}
        />
        {currentUser?.user?.first_name} {currentUser?.user?.last_name}
      </button>
      <Menu
        anchorEl={open}
        keepMounted
        open={Boolean(open)}
        onClose={() => handleClose()}
        anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
        transformOrigin={{ vertical: "top", horizontal: "right" }}
        sx={{ mt: 3 }}
      >
        <MenuItem
          onClick={(e) => {
            history.push("/profile");
            handleClose();
          }}
        >
          <BorderColorIcon style={iconStyles} />
          Edit Profile
        </MenuItem>
        <MenuItem onClick={logout}>
          <img src={logoutIcon} style={iconStyles} alt="" />
          Logout
        </MenuItem>
      </Menu>
    </>
  );
}
