import React, { useEffect, useState } from "react";

import Pagination from "../../components/Pagination";
import AddCustomerModal from "./components/AddCustomerModal";
import { CustomLoader } from "../../components/CustomLoader";
import useStore from "../../store/store";
import { useHistory } from "react-router-dom";
import { AvatarIcon } from "../../components/ProfilePicture";

import SearchIcon from "../../assets/img/searchIcon.svg";
import PdfIcon from "../../assets/img/pdfIcon.svg";
import PaymentPlan from "../../assets/img/paymentPlan.svg";
import StatusIcon from "../../assets/img/progress_icon.svg";
import { saveAs } from "file-saver";

import { Input } from "../../components/Cdl/Input";
import { Button } from "../../components/Cdl/Button";
import Select from "../../components/Cdl/Select";

import fetchWrapper from "../../helpers/fetch-wrapper";
import { APP_URL } from "../../utils/constants";
import { COLORS } from "../../styles/themes/default/colors";

import Tooltip from "../../components/Tooltip";

import CustomerListStatistics from "./components/CustomerListStatistics";

import { createTheme, useMediaQuery } from "@mui/material";

import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

import { styled } from "@mui/material/styles";

const theme1 = createTheme({
  breakpoints: {
    values: {
      xs: 0,
      smallMobile: 321,
      mobile: 376,
      middleMobile: 407,
      sm: 600,
      md: 900,
      lg: 1200,
      xl: 1536,
    },
  },
});

const smallMobileDown = theme1.breakpoints.down("smallMobile");
const mobileDown = theme1.breakpoints.down("mobile");
const middleMobileDown = theme1.breakpoints.down("middleMobile");
const smDown = theme1.breakpoints.down("sm");
const mdDown = theme1.breakpoints.down("md");
const lgDown = theme1.breakpoints.down("lg");

const LoaderDiv = styled("div")({
  width: "100%",
  height: "500px",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
});

const MainDiv = styled("div")({
  width: "100%",
  height: "100%",
  display: "flex",
  flexDirection: "column",
});

const Header = styled("div")({
  display: "flex",
  width: "100%",
  borderRadius: "5px",
  marginBottom: "20px",
  paddingTop: "24px",
  flex: 0,

  [lgDown]: {
    padding: "20px",
  },

  [mdDown]: {
    alignItems: "baseline",
  },

  [smDown]: {
    padding: "15px",
  },
});

const FiltersDiv = styled("div")({
  display: "flex",
  width: "100%",
  alignItems: "center",
  borderTopLeftRadius: "5px",
  borderTopRightRadius: "5px",
  position: "relative",

  [smDown]: {
    alignItems: "unset",
    flexDirection: "column",
  },
});

const StatisticsAccordion = styled(Accordion)({
  backgroundColor: COLORS.white,
  boxShadow: "none",
  border: "unset",
  flex: 0,
  padding: "0",
  borderRadius: "5px",

  "&:hover": {
    cursor: `default !important`,
  },

  ":before": {
    backgroundColor: "unset",
  },
});

const AccordionSummaryDiv = styled(AccordionSummary)({
  padding: "0",
  color: COLORS.black,
  justifyContent: `space-between !important`,
  fontWeight: "600",
  minHeight: `48px !important`,

  "&:hover": {
    cursor: `default !important`,
  },
});

const AccordionContent = styled(AccordionDetails)({
  padding: "0",
});

const AccordionTitle = styled("div")({
  display: "flex",
  justifyContent: "space-between",
  width: "100%",
  "@media only screen and (max-width: 1400px) and (min-width: 1280px)": {
    fontSize: "0.688rem",
  },
});

const ExpandText = styled("span")({
  color: COLORS.activity,
  fontSize: "14px",
  lineHeight: "17px",
  fontWeight: "600",
  marginBottom: "0",
  position: "relative",
  top: "5px",
  "&:hover": {
    cursor: `pointer !important`,
  },
  "@media only screen and (max-width: 1400px) and (min-width: 1280px)": {
    fontSize: "0.688rem",
  },
});

const Content = styled("div")({
  display: "flex",
  flex: 1,
  flexDirection: "column",
  marginTop: "16px",
});

const TableDiv = styled("div")({
  backgroundColor: COLORS.white,
  width: "100%",
  height: "100%",
  alignItems: "center",
  borderRadius: "8px 8px 0px 0px",
  textAlign: "-webkit-center",
});

const Table = styled("table")({
  width: "98%",
  display: "block",
});

const HeaderCells = styled("th")({
  padding: "10px 0",
  width: "10.5%",
  fontSize: "14px",

  [lgDown]: {
    fontSize: "14px",
  },

  [smDown]: {
    fontSize: "12px",
    width: "24%",
  },

  [middleMobileDown]: {
    width: "19%",
  },

  [mobileDown]: {
    width: "30%",
  },
  "@media only screen and (max-width: 1400px) and (min-width: 1280px)": {
    fontSize: "0.625rem",
  },
});

const TableCells = styled("td")({
  padding: "10px 0",
  width: "10.5%",
  fontSize: "14px",

  [lgDown]: {
    fontSize: "14px",
  },

  [smDown]: {
    fontSize: "11px",
    padding: "10px 5px",
    width: "24%",
  },

  [middleMobileDown]: {
    textOverflow: "ellipsis",
    overflow: "hidden",
    width: "30%",
  },

  [mobileDown]: {
    width: "30%",
  },

  "@media only screen and (max-width: 1400px) and (min-width: 1280px)": {
    fontSize: "0.625rem",
  },
});

const TableHeader = styled("tr")({
  display: "flex",
  borderBottom: "1px solid rgba(189, 189, 189, 0.8)",
});

const TableRow = styled("tr")({
  borderBottom: "1px solid rgba(189, 189, 189, 0.4)",
  height: "70px",
  "&:hover": {
    backgroundColor: "#F6F6F6",
    cursor: "pointer",
  },
  display: "flex",
});

const CountryMobile = styled("p")({
  display: "none",
  margin: "0",
  color: "rgba(30, 30, 30, 0.5)",
  fontSize: "0.6rem",

  [smDown]: {
    display: "block",
  },
});

const PaginationDiv = styled("div")({
  background: "white",
  borderRadius: "0px 0 8px 8px",
  padding: "8px 16px ",
});

const CustomerList = () => {
  const currentUser = useStore((state) => state.currentUser);
  const history = useHistory();

  const [customers, setCustomers] = useState([]);
  const [filteredCustomers, setFilteredCustomers] = useState([]);
  const [openCustomerModal, setOpenCustomerModal] = useState(false);

  const [showStatistics, setShowStatistics] = useState(false);
  const [topPaidDemurrage, setTopPaidDemurrage] = useState();
  const [topMissingPaidDemurrage, setTopMissingPaidDemurrage] = useState();
  const [activated, setActivated] = useState("");
  const [invited, setInvited] = useState("");
  const [customerLength, setCustomerLength] = useState("");

  //pagination
  const [currentPage, setCurrentPage] = useState(1);
  const [paymentFilter, setPaymentFilter] = useState([]);
  const [customerStatusFilter, setCustomerStatusFilter] = useState([]);
  const [searchCustomer, setSearchCustomer] = useState("");
  const [updateList, setUpdateList] = useState(false);

  const [loader, setLoader] = useState(true);

  const statusesToFilter = (statuses) => {
    let tmp = [];
    statuses.map((item) => {
      switch (item) {
        case "New":
          return tmp.push(null);
        case "Active":
          return tmp.push(true);
        case "Inactive":
          return tmp.push(false);
        default:
          return (tmp = []);
      }
    });
    return tmp;
  };

  const paymentToFilter = (payments) => {
    let tmp = [];
    payments.map((item) => {
      if (item.slice(3, 5) === "30") {
        tmp.push(30);
      } else if (item.slice(3, 5) === "14") {
        tmp.push(14);
      } else {
        tmp.push("other");
      }
    });
    return tmp;
  };

  useEffect(() => {
    async function getShipperUsers() {
      setLoader(true);
      await fetchWrapper.get(`${APP_URL}/shipper/`).then((resp) => {
        let customersList = [];
        for (let i = 0; i < numOfRows(resp); i++) {
          customersList.push(resp[i]);
        }

        let paid = resp[0];
        for (let i = 0; i < resp.length; i++) {
          if (
            resp[i].demurrage_statistics.total_paid_demurrage_amount >
            paid.demurrage_statistics.total_paid_demurrage_amount
          ) {
            paid = resp[i];
          }
        }
        setCustomerLength(resp.length);

        setTopPaidDemurrage(paid);
        let activeCustomersLength = 0;
        let invitedCustomersLength = 0;
        let missingPaid = resp[0];
        for (let i = 0; i < resp.length; i++) {
          if (resp[i].is_active === true) {
            activeCustomersLength++;
          }
          if (resp[i].is_invited === true) {
            invitedCustomersLength++;
          }
          if (
            resp[i].demurrage_statistics.total_unpaid_demurrage_amount >
            missingPaid.demurrage_statistics.total_unpaid_demurrage_amount
          ) {
            missingPaid = resp[i];
          }
        }
        setTopMissingPaidDemurrage(missingPaid);
        let activeCustomers = (activeCustomersLength * 100) / resp.length;
        setActivated(parseInt(activeCustomers));
        setInvited(invitedCustomersLength);
        setFilteredCustomers(customersList);
        setCustomers(resp);
        setLoader(false);
      });
    }
    getShipperUsers();
  }, [updateList]);

  const numOfRows = (item) => {
    if (item.length > 10 && window.innerWidth <= 1920) {
      return 10;
    } else {
      return item.length;
    }
  };
  const isFilterUp =
    searchCustomer ||
    customerStatusFilter.length > 0 ||
    paymentFilter.length > 0;
  useEffect(() => {
    setCurrentPage(1);
    let filtered = [...customers];
    if (searchCustomer) {
      filtered = filtered.filter(
        (item) =>
          item?.business_name
            .toLowerCase()
            .includes(searchCustomer.toLowerCase()) ||
          item?.contact?.user?.full_name
            ?.toLowerCase()
            .includes(searchCustomer.toLowerCase())
      );
    }
    if (customerStatusFilter.length > 0) {
      filtered = filtered.filter((item) =>
        statusesToFilter(customerStatusFilter).includes(item?.is_active)
      );
    }
    if (paymentFilter.length > 0) {
      const paymentFilters = paymentToFilter(paymentFilter);
      filtered = filtered.filter(
        (item) =>
          paymentFilters.includes(item?.repayment_days) ||
          (paymentFilters.includes("other") &&
            item?.repayment_days !== 14 &&
            item?.repayment_days !== 30)
      );
    }
    setFilteredCustomers(filtered);
  }, [paymentFilter, customerStatusFilter, searchCustomer, customers]);

  const downloadFile = (item) => {
    fetchWrapper
      .get(`${APP_URL}/shipper/contracts/${item.id}/`)
      .then((resp) => {
        saveAs(`data:image;base64,${resp.document_64}`, item.name);
      });
  };

  const customerStatus = (status) => {
    if (status === true) return "Activated";
    else if (status === null) return "New customer";
    else if (status === false) return "Deactivated";
  };

  const middleMobileDownQuerry = useMediaQuery(
    theme1.breakpoints.down("middleMobile")
  );
  const smDownQuerry = useMediaQuery(theme1.breakpoints.down("sm"));

  const TableBody = styled("tbody")({
    display: "block",
    width: "100%",
    position: "relative",
  });

  return (
    <>
      <MainDiv>
        {loader ? (
          <LoaderDiv>
            <CustomLoader size="7vh" />
          </LoaderDiv>
        ) : (
          <>
            <Header>
              <FiltersDiv>
                <Input
                  iconStart={SearchIcon}
                  css={{
                    fontSize: "16px",
                    width: "350px",
                    "@media only screen and (max-width: 1400px) and (min-width: 1280px)":
                      {
                        fontSize: "0.6875rem",
                        width: "260px",
                      },
                  }}
                  placeholder="Search by company or contact name"
                  value={searchCustomer}
                  onChange={(e) => {
                    setSearchCustomer(e.target.value);
                  }}
                />
                <Select
                  icon={PaymentPlan}
                  placeholder="Payment plan"
                  value={paymentFilter}
                  onChange={setPaymentFilter}
                  options={["5% 30-day", "3% 14-day", "Other"]}
                  css={{
                    marginLeft: "20px",
                    "@media only screen and (max-width: 1400px) and (min-width: 1280px)":
                      {
                        width: "160px",
                      },
                  }}
                  cssDiv={{ marginLeft: "20px" }}
                />
                <Select
                  icon={StatusIcon}
                  placeholder="Status"
                  value={customerStatusFilter}
                  onChange={setCustomerStatusFilter}
                  options={["New", "Active", "Inactive"]}
                  css={{
                    marginLeft: "20px",
                    "@media only screen and (max-width: 1400px) and (min-width: 1280px)":
                      {
                        width: "130px",
                      },
                  }}
                  cssDiv={{
                    marginLeft: "20px",
                  }}
                />
                {/* <PaymentPlanDropdownMenu
                paymentFilter={paymentFilter}
                setPaymentFilter={setPaymentFilter}
              /> */}
                {/* <CustomerStatusDropdownMenu
                customerStatusFilter={customerStatusFilter}
                setCustomerStatusFilter={setCustomerStatusFilter}
              /> */}
              </FiltersDiv>
              {currentUser?.user?.role === "CA" && (
                <Button
                  type="primary"
                  text="+ Add customer"
                  css={{ width: "160px" }}
                  onClick={() => history.push("/customers/add")}
                />
              )}
            </Header>

            <StatisticsAccordion
              className="customer-list-accordion"
              expanded={showStatistics}
            >
              <AccordionSummaryDiv
                expandIcon={
                  <ExpandMoreIcon
                    onClick={() => setShowStatistics(!showStatistics)}
                    sx={{
                      color: COLORS.activity,
                      "&:hover": {
                        cursor: `pointer !important`,
                      },
                      "@media only screen and (max-width: 1400px) and (min-width: 1280px)":
                        {
                          marginTop: showStatistics ? "-0.5rem" : "0.5rem",
                        },
                    }}
                  />
                }
                aria-controls="panel1a-content"
                id="panel1a-header"
              >
                <AccordionTitle>
                  Customer statistics
                  <ExpandText
                    onClick={() => setShowStatistics(!showStatistics)}
                  >
                    {showStatistics ? "Hide stats" : "Show stats"}
                  </ExpandText>
                </AccordionTitle>
              </AccordionSummaryDiv>
              <AccordionContent>
                <CustomerListStatistics
                  topPaidDemurrage={topPaidDemurrage}
                  topMissingPaidDemurrage={topMissingPaidDemurrage}
                  activated={activated}
                  invited={invited}
                  showStatistics={showStatistics}
                  setShowStatistics={setShowStatistics}
                  customerLength={customerLength}
                />
              </AccordionContent>
            </StatisticsAccordion>

            <Content>
              <TableDiv>
                <Table>
                  <TableHeader>
                    <HeaderCells
                      style={{
                        textAlign: "center",
                        width: "14.5%",
                      }}
                    >
                      Status
                    </HeaderCells>
                    <HeaderCells>Company</HeaderCells>
                    <HeaderCells
                      style={{ display: smDownQuerry ? "none" : "" }}
                    >
                      City
                    </HeaderCells>
                    <HeaderCells
                      style={{
                        display: smDownQuerry ? "none" : "",
                        width: "8.5%",
                      }}
                    >
                      State
                    </HeaderCells>
                    <HeaderCells
                      style={{
                        display: smDownQuerry ? "none" : "",
                        textAlign: "center",
                      }}
                    >
                      Interest
                    </HeaderCells>
                    <HeaderCells
                      style={{
                        textAlign: "center",
                        display: smDownQuerry ? "none" : "",
                      }}
                    >
                      Repayment days
                    </HeaderCells>
                    <HeaderCells
                      style={{
                        textAlign: "center",
                        display: smDownQuerry ? "none" : "",
                      }}
                    >
                      Approval authority
                    </HeaderCells>
                    <HeaderCells
                      style={{
                        textAlign: "center",
                        display: middleMobileDownQuerry ? "none" : "",
                      }}
                    >
                      Contract
                    </HeaderCells>
                    <HeaderCells
                      style={{
                        width: "14%",
                      }}
                    >
                      Contact person
                    </HeaderCells>
                    {/* <HeaderCells
                    style={{ display: middleMobileDownQuerry ? "none" : "" }}
                  >
                    Actions
                  </HeaderCells> */}
                  </TableHeader>
                  <TableBody>
                    {filteredCustomers?.length > 0 &&
                      filteredCustomers
                        .slice((currentPage - 1) * 10, currentPage * 10)
                        .map((item, index) => (
                          <>
                            <TableRow
                              key={index}
                              onClick={() =>
                                history.push(`/customers/${item.id}`)
                              }
                              className="customer-details"
                            >
                              <TableCells
                                style={{
                                  textAlign: "center",
                                  padding: "20px 2%",
                                  width: smDownQuerry ? "5%" : "14.5%",
                                }}
                                className="customer-status"
                              >
                                <Tooltip
                                  status={item?.is_active}
                                  type={"Customer status"}
                                />
                                <p
                                  style={
                                    customerStatus(item?.is_active) ===
                                    "Activated"
                                      ? {
                                          background: "rgba(81, 218, 136, 0.2)",
                                          color: "#51DA88",
                                          fontWeight: "600",
                                          margin: "0",
                                        }
                                      : customerStatus(item?.is_active) ===
                                        "Deactivated"
                                      ? {
                                          background:
                                            "rgba(140, 140, 140, 0.2)",
                                          color: "#8C8C8C",
                                          fontWeight: "600",
                                          margin: "0",
                                        }
                                      : {
                                          background: "rgba(81, 177, 218, 0.2)",
                                          color: "#51B1DA",
                                          fontWeight: "600",
                                          margin: "0",
                                        }
                                  }
                                >
                                  {customerStatus(item?.is_active)}
                                </p>
                              </TableCells>
                              <TableCells>
                                {item?.business_name.length > 20
                                  ? item?.business_name.slice(0, 20) + "..."
                                  : item?.business_name}
                                <CountryMobile>
                                  {item?.city || item?.state
                                    ? item?.city + ", " + item?.state
                                    : "_"}
                                </CountryMobile>
                              </TableCells>
                              <TableCells
                                style={{
                                  display: smDownQuerry ? "none" : "",
                                  color: "#8C8C8C",
                                }}
                              >
                                {item?.city ? item?.city : "_"}
                              </TableCells>
                              <TableCells
                                style={{
                                  width: "8.5%",
                                  display: smDownQuerry ? "none" : "",
                                  color: "#8C8C8C",
                                }}
                              >
                                {item?.state ? item?.state : "_"}
                              </TableCells>
                              <TableCells style={{ textAlign: "center" }}>
                                <p
                                  style={{
                                    color: "#EE4A26",
                                    margin: "0",
                                    fontWeight: "600",
                                  }}
                                >
                                  {item?.interest_percentage
                                    ? parseInt(item?.interest_percentage) + "%"
                                    : "_"}
                                </p>
                              </TableCells>
                              <TableCells style={{ textAlign: "center" }}>
                                {item?.repayment_days
                                  ? item?.repayment_days
                                  : "_"}
                              </TableCells>
                              <TableCells
                                style={{
                                  textAlign: "center",
                                  color: "#8C8C8C",
                                }}
                              >
                                {item?.authority_approval === "CUS"
                                  ? "Customer"
                                  : "CDL 1000"}
                              </TableCells>
                              <TableCells
                                className="customer-contract"
                                style={{
                                  display: middleMobileDownQuerry ? "none" : "",
                                  alignItems: "center",
                                  textAlign: "center",
                                }}
                              >
                                {item?.contract === null ? (
                                  "_"
                                ) : (
                                  <Button
                                    type="icon"
                                    icon={PdfIcon}
                                    css={{ display: "contents" }}
                                    onClick={() => downloadFile(item?.contract)}
                                  />
                                )}
                                <Tooltip
                                  text={
                                    item?.contract === null
                                      ? "A contract is not uploaded"
                                      : item?.contract.name
                                  }
                                  type={"Customer contract"}
                                />
                              </TableCells>
                              <TableCells
                                style={{
                                  width: "14%",
                                  display: "flex",
                                  flexDirection: "column",
                                  justifyContent: "center",
                                }}
                              >
                                {!item?.contact?.user?.full_name ? (
                                  <p>_</p>
                                ) : (
                                  <>
                                    <div
                                      style={{
                                        display: "flex",
                                      }}
                                    >
                                      <AvatarIcon
                                        src={
                                          item?.contact?.user?.picture_64 !==
                                          null
                                            ? `data:image/png;base64,${item?.contact?.user?.picture_64}`
                                            : ""
                                        }
                                        firstName={
                                          item?.contact?.user?.first_name
                                        }
                                        lastName={
                                          item?.contact?.user?.last_name
                                        }
                                        style={{
                                          width: "2rem",
                                          height: "2rem",
                                          backgroundColor: "#C4C4C4",
                                          fontSize: "15px",
                                          fontFamily: "Montserrat",
                                        }}
                                      />
                                      <p
                                        style={{
                                          margin: "5px 0px 0px 4px",
                                          paddingLeft: "11px",
                                        }}
                                      >
                                        {item?.contact?.user?.full_name}
                                      </p>
                                    </div>
                                    <div
                                      style={{
                                        display: "flex",
                                      }}
                                    >
                                      <p
                                        style={{
                                          margin: "0px",
                                          paddingLeft: "50px",
                                          color: "#8C8C8C",
                                          textAlign: "left",
                                        }}
                                      >
                                        {item?.contact?.user?.phone_number}
                                      </p>
                                    </div>
                                  </>
                                )}
                              </TableCells>

                              {<Tooltip type={"Customer details"} />}
                            </TableRow>
                          </>
                        ))}
                  </TableBody>
                </Table>
              </TableDiv>
              {window.innerWidth <= 1920 && (
                <PaginationDiv>
                  <Pagination
                    page={currentPage}
                    setPage={setCurrentPage}
                    totalNumber={
                      isFilterUp ? filteredCustomers.length : customerLength
                    }
                    itemsPerPage={10}
                  />
                </PaginationDiv>
              )}
            </Content>
          </>
        )}
      </MainDiv>
      <AddCustomerModal
        updateList={updateList}
        setUpdateList={setUpdateList}
        openCustomerModal={openCustomerModal}
        setOpenCustomerModal={setOpenCustomerModal}
      />
    </>
  );
};

export default CustomerList;
