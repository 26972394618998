import React, { useState, useEffect } from "react";
import {
  Box,
  CircularProgress,
  useMediaQuery,
  createTheme,
} from "@mui/material";

import PlusIcon from "../../../assets/img/DemurrageDetailAndRequest/plus_icon.svg";
import SteamShipIcon from "../../../assets/img/DemurrageDetailAndRequest/steamship_icon.svg";
import TypeIcon from "../../../assets/img/DemurrageDetailAndRequest/type_icon.svg";
import HashtagActiveIcon from "../../../assets/img/DemurrageDetailAndRequest/Hashtag.svg";
import HashtagDisplayIcon from "../../../assets/img/DemurrageDetailAndRequest/HastagDisplay.svg";
import BiilingDaysIcon from "../../../assets/img/DemurrageDetailAndRequest/billing_days_icon.svg";
import DollarIcon from "../../../assets/img/DemurrageDetailAndRequest/dollar_icon.svg";
import EditIcon from "../../../assets/img/DemurrageDetailAndRequest/edit_icon2.svg";
import DeleteIcon from "../../../assets/img/DemurrageDetailAndRequest/trash_can_icon.svg";
import SuccessImg from "../../../assets/img//DemurrageDetailAndRequest/success.svg";
import WarningIcon from "../../../assets/img/DemurrageDetailAndRequest/Warning.svg";

import fetchWrapper from "../../../helpers/fetch-wrapper";
import { APP_URL, formatValue } from "../../../utils/constants";
import alertService from "../../../services/alertService";

import { COLORS } from "../../../styles/themes/default/colors";
import { Button } from "../../../components/Cdl/Button";
import { Input } from "../../../components/Cdl/Input";
import AutoComplete from "../../../components/Cdl/AutoComplete";
import { TableDescription } from "../../../components/Cdl/Typography";

import "../styles/demurrage_styles.scss";

export default function DemurrageRequestContainer({
  containers,
  setContainers,
  status,
  totalAmount,
  terminal,
}) {
  const theme1 = createTheme({
    breakpoints: {
      values: {
        sl: 1400,
      },
    },
  });
  const smallLaptop = useMediaQuery(theme1.breakpoints.down("sl"));

  const [containerId, setContainerId] = useState("");
  const [type, setType] = useState("");
  const [types, setTypes] = useState([]);
  const [steamship, setSteamship] = useState("");
  const [steamships, setSteamships] = useState([]);
  const [billing, setBilling] = useState();
  const [mbl, setMBL] = useState("");
  const [demurrageDays, setDemurrageDays] = useState("");
  const [detentionDays, setDetentionDays] = useState("");
  const [amount, setAmount] = useState("");
  const [currentEditableContainer, setCurrentEditableContainer] = useState({});
  const [searching, setSearching] = useState(false);
  const [isFound, setIsFound] = useState();
  const [steamshipEditable, setSteamshipEditable] = useState("");
  const [containerTypeEditable, setContainerTypeEditable] = useState("");
  const [mockedData, setMockedData] = useState({});

  useEffect(() => {
    fetchWrapper.get(`${APP_URL}/dnd/container_types/`).then((resp) => {
      setTypes(typeof resp === "string" ? [] : resp);
    });
    fetchWrapper.get(`${APP_URL}/dnd/steamship_lines/`).then((resp) => {
      setSteamships(typeof resp === "string" ? [] : resp);
    });
  }, []);

  const handleEdit = (item) => {
    let new_containers = containers.map((container, key) => {
      if (key === item) {
        if (container.editable) {
          return {
            ...currentEditableContainer,
            editable: false,
            steamship_line: steamshipEditable,
            container_info: containerTypeEditable,
          };
        } else {
          container.editable = true;
          setSteamshipEditable(container.steamship_line);
          setContainerTypeEditable(container.container_info);
          setCurrentEditableContainer(container);
        }
      } else {
        if (container.editable) {
          return {
            ...currentEditableContainer,
            editable: false,
            steamship_line: steamshipEditable,
            container_info: containerTypeEditable,
          };
        }
        container.editable = false;
      }
      return container;
    });

    setContainers(new_containers);
  };
  const handleChange = (label, value) => {
    if (label === "containerId") {
      setCurrentEditableContainer({
        ...currentEditableContainer,
        container_id: value,
      });
    }
    if (label === "steamship") {
      setCurrentEditableContainer({
        ...currentEditableContainer,
        steamship_line: value,
      });
    }
    if (label === "demurrageDays") {
      setCurrentEditableContainer({
        ...currentEditableContainer,
        free_days_count: value,
      });
    }
    if (label === "detentionDays") {
      setCurrentEditableContainer({
        ...currentEditableContainer,
        detention_free_days: value,
      });
    }
    if (label === "billing") {
      setCurrentEditableContainer({
        ...currentEditableContainer,
        dnd_duration: value,
      });
    }
    if (label === "amount") {
      setCurrentEditableContainer({
        ...currentEditableContainer,
        demurrage_amount: value,
      });
    }
    if (label === "type") {
      setCurrentEditableContainer({
        ...currentEditableContainer,
        container_info: value,
      });
    }
    if (label === "mbl") {
      setCurrentEditableContainer({
        ...currentEditableContainer,
        mbl: value,
      });
    }
  };

  const handleDelete = (item) => {
    setContainers(
      containers.filter(
        (container) => container.container_id !== item.container_id
      )
    );
  };

  const addContainer = () => {
    let errorAmount = "";
    let errorMBL = "";
    let errorContainerId = "";
    let errorInvalidContainerId = "";
    let errorInvalidAmount = "";
    let shouldReturn = false;
    containers.forEach((item) => {
      if (item.container_id === containerId) {
        alertService.error(1, "Container with this id is allready added");
        shouldReturn = true;
      }
    });
    if (shouldReturn) {
      return;
    }
    if (amount === "") {
      errorAmount = "Amount is required";
    }
    if (containerId === "") {
      errorContainerId = "Container ID is required";
    }
    if (mbl === "") {
      errorMBL = "MBL is required";
    }

    if (amount !== "" && containerId !== "" && mbl !== "") {
      let container = {
        container_id: containerId,
        type: type ? type : undefined,
        steamship_line: steamship ? steamship : undefined,
        dnd_duration: billing ? billing : undefined,
        demurrage_days: demurrageDays ? demurrageDays : undefined,
        detention_days: detentionDays ? detentionDays : undefined,
        mbl: mbl,
        demurrage_amount: !detentionDays ? amount : 0,
        detention_amount: detentionDays ? amount : 0,
        editable: false,
        customer: "test",
      };
      setContainers([...containers, { ...mockedData, ...container }]);
      setAmount("");
      setDemurrageDays("");
      setDetentionDays("");
      setBilling("");
      setType("");
      setMBL("");
      setSteamship("");
      setContainerId("");
      setIsFound(null);
    } else {
      let errorMessage = errorAmount ? `${errorAmount}\n` : "";
      errorMessage += errorContainerId ? errorContainerId + "\n" : "";
      errorMessage += errorInvalidAmount ? errorInvalidAmount + "\n" : "";
      errorMessage += errorMBL ? errorMBL + "\n" : "";
      errorMessage += errorInvalidContainerId
        ? errorInvalidContainerId + "\n"
        : "";

      alertService.error(1, errorMessage);
    }
  };
  async function handleSearch() {
    if (Object.keys(terminal).length === 0) {
      alertService.error(1, "Select terminal first");
      return;
    }
    setAmount("");
    setDemurrageDays("");
    setDetentionDays("");
    setBilling("");
    setType("");
    setMBL("");
    setSteamship("");
    setIsFound(null);
    setSearching(true);

    await fetchWrapper
      .get(`${APP_URL}/dnd/test/is_container_in_list/${containerId}/`)
      // .post(search_url, { terminal: terminal.name, container_id: containerId })
      .then((resp) => {
        setType(resp?.type ?? "");
        setSteamship(resp?.steamship_line ?? "");
        setBilling(resp?.dnd_duration ?? "");
        setAmount(resp?.demurrage_amount ?? "");
        setMBL(resp?.mbl ?? "");
        setDemurrageDays(resp.demurrage_days);
        setDetentionDays(resp.detention_days);

        if (resp?.steamship_line && resp?.type && resp?.demurrage_amount) {
          setIsFound(true);
        } else setIsFound(false);

        setSearching(false);
        setMockedData(resp);
      })
      .catch((error) => {
        setSearching(false);
        setIsFound(false);
      });
  }
  return (
    <Box>
      <Box
        sx={{
          display: "flex",
          height: "58px",
        }}
      >
        <Input
          placeholder={"Container ID *"}
          css={{
            width: smallLaptop ? "120px" : "200px",
            margin: "8px 8px 0px 0px",
          }}
          iconStart={containerId ? HashtagActiveIcon : HashtagDisplayIcon}
          onChange={(e) => {
            setContainerId(e.target.value);
          }}
          value={containerId}
        />
        <Button
          disabled={containerId === ""}
          text={
            searching ? (
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <CircularProgress size={25} sx={{ color: "white" }} />
              </div>
            ) : isFound ? (
              <img src={SuccessImg} alt="" />
            ) : isFound === false ? (
              <img src={WarningIcon} alt="" />
            ) : (
              "Search"
            )
          }
          bgColor={
            isFound
              ? "rgba(81,218,136,0.2)"
              : isFound === false
              ? "rgba(245,175,69,0.2)"
              : "#EE4A26"
          }
          type={"primary"}
          css={{
            width: "80px",
            height: smallLaptop ? "30px" : "42px",
            margin: "8px 8px 0px 0px",
          }}
          onClick={handleSearch}
        />
      </Box>
      <Box
        sx={{
          display: "flex",
          height: "58px",
          width: "100%",
          justifyContent: "space-between",
        }}
      >
        <AutoComplete
          iconStart={TypeIcon}
          options={types.map((item) => {
            return item.name;
          })}
          disabled={searching}
          cssDiv={{ width: "200px" }}
          css={{
            width: smallLaptop ? "120px" : "200px",
            margin: "8px 8px 0px 0px",
          }}
          placeholder={"Type"}
          search={type}
          setSearch={setType}
        />

        <AutoComplete
          iconStart={SteamShipIcon}
          options={steamships.map((item) => {
            return item.name;
          })}
          cssDiv={{ width: "203px" }}
          css={{
            width: smallLaptop ? "115px" : "200px",
            margin: "8px 8px 0px 0px",
          }}
          disabled={searching}
          placeholder={"Steamship"}
          search={steamship}
          setSearch={setSteamship}
        />
        <Input
          placeholder={"MBL *"}
          css={{
            width: smallLaptop ? "85px" : "140px",
            margin: "8px 8px 0px 0px",
          }}
          disabled={searching}
          iconStart={TypeIcon}
          onChange={(e) => {
            setMBL(e.target.value);
          }}
          value={mbl}
        />
        <Input
          placeholder={"Demurrage days"}
          css={{
            width: smallLaptop ? "110px" : "180px",
            margin: "8px 8px 0px 0px",
          }}
          disabled={searching}
          iconStart={BiilingDaysIcon}
          onChange={(e) => {
            setDemurrageDays(e.target.value);
          }}
          value={demurrageDays}
        />
        <Input
          placeholder={"Detention days"}
          css={{
            width: smallLaptop ? "110px" : "180px",
            margin: "8px 8px 0px 0px",
          }}
          disabled={searching}
          iconStart={BiilingDaysIcon}
          onChange={(e) => {
            setDetentionDays(e.target.value);
          }}
          value={detentionDays}
        />
        <Input
          placeholder={"Amount *"}
          css={{
            width: smallLaptop ? "90px" : "130px",
            margin: "8px 8px 0px 0px",
            backgroundSize: "11px",
          }}
          disabled={searching}
          iconStart={DollarIcon}
          onChange={(e) => {
            setAmount(e.target.value);
          }}
          value={amount}
        />
        <Button
          text={<img src={PlusIcon} alt="plus icon" />}
          type={"primary"}
          css={{
            width: smallLaptop ? "32px" : "40px",
            height: smallLaptop ? "30px" : "42px",
            margin: "8px 8px 0px 0px",
          }}
          disabled={searching}
          onClick={() => {
            addContainer();
          }}
        />
      </Box>
      <div
        style={{
          display: "flex",
          alignItems: "center",
        }}
      >
        <img
          src={
            searching
              ? ""
              : isFound
              ? SuccessImg
              : isFound === false
              ? WarningIcon
              : ""
          }
          alt=""
          style={{ marginRight: "8px" }}
        />
        <TableDescription
          css={{
            textAlign: "left",
            margin: 0,

            color: searching
              ? "#8C8C8C"
              : isFound
              ? COLORS.success
              : isFound === false
              ? COLORS.warning
              : "",
          }}
          text={
            searching ? (
              "We are looking for available information about your container. Thank you for your patience."
            ) : isFound ? (
              "Container found"
            ) : isFound === false ? (
              "We couldn't find your container. Please fill in manually."
            ) : (
              <div style={{ height: "20px" }} />
            )
          }
        />
      </div>
      {containers.length > 0 ? (
        <table className="container_info_table">
          <tr
            style={{
              borderTop: "1px solid #BDBDBDCC",
              fontSize: "14px",
              height: "58px",
            }}
          >
            <th
              style={{
                width: "156px",
                fontSize: smallLaptop ? "11px" : "16px",
              }}
            >
              Container Id
            </th>
            <th
              style={{
                width: "115px",
                fontSize: smallLaptop ? "11px" : "16px",
              }}
            >
              Type
            </th>
            <th
              style={{
                width: "217px",
                fontSize: smallLaptop ? "11px" : "16px",
              }}
            >
              Steamship Line
            </th>
            <th
              style={{
                width: "142px",
                fontSize: smallLaptop ? "11px" : "16px",
              }}
            >
              MBL
            </th>
            <th
              style={{
                width: "153px",
                fontSize: smallLaptop ? "11px" : "16px",
              }}
            >
              Demurrage days
            </th>
            <th
              style={{
                width: "124px",
                fontSize: smallLaptop ? "11px" : "16px",
              }}
            >
              Detention days
            </th>
            <th
              style={{ width: "84px", fontSize: smallLaptop ? "11px" : "16px" }}
            >
              Amount
            </th>
            <th
              style={{ width: "92px", fontSize: smallLaptop ? "11px" : "16px" }}
            >
              {" "}
              Actions
            </th>
          </tr>
          {containers.map((row, index) => {
            return (
              <>
                {row.editable ? (
                  <tr
                    key={index}
                    style={{
                      borderTop: "1px solid #BDBDBD33",
                      fontSize: "14px",
                      height: "58px",
                    }}
                  >
                    <td align="left">
                      <Input
                        placeholder={"Container ID"}
                        css={{ width: "100%" }}
                        iconStart={
                          currentEditableContainer.container_id
                            ? HashtagActiveIcon
                            : HashtagDisplayIcon
                        }
                        onChange={(e) => {
                          handleChange("containerId", e.target.value);
                        }}
                        value={currentEditableContainer.container_id}
                      />
                    </td>
                    <td align="left">
                      <AutoComplete
                        placeholder={"Type"}
                        iconStart={TypeIcon}
                        css={{ width: "100%" }}
                        setSearch={setContainerTypeEditable}
                        search={containerTypeEditable}
                        options={types.map((item) => {
                          return item.name;
                        })}
                      />
                    </td>
                    <td align="left">
                      <AutoComplete
                        placeholder={"Steamship line"}
                        options={steamships.map((item) => {
                          return item.name;
                        })}
                        css={{ width: "100%" }}
                        iconStart={SteamShipIcon}
                        setSearch={setSteamshipEditable}
                        search={steamshipEditable}
                      />
                    </td>
                    <td align="left">
                      <Input
                        placeholder={"MBL"}
                        css={{ width: "100%" }}
                        icon={TypeIcon}
                        onChange={(e) => {
                          handleChange("mbl", e.target.value);
                        }}
                        value={currentEditableContainer.mbl}
                      />
                    </td>
                    <td align="left">
                      <Input
                        placeholder={"Demurrage days"}
                        css={{ width: "100%" }}
                        iconStart={BiilingDaysIcon}
                        onChange={(e) => {
                          handleChange("demurrageDays", e.target.value);
                        }}
                        value={currentEditableContainer.demurraged_days}
                      />
                    </td>
                    <td align="left">
                      <Input
                        placeholder={"Detention days"}
                        css={{ width: "100%" }}
                        iconStart={BiilingDaysIcon}
                        onChange={(e) => {
                          handleChange("detentionDays", e.target.value);
                        }}
                        value={currentEditableContainer.detention_days}
                      />
                    </td>
                    <td align="left">
                      <Input
                        placeholder={"Amount"}
                        css={{ width: "100%" }}
                        iconStart={DollarIcon}
                        onChange={(e) => {
                          handleChange("amount", e.target.value);
                        }}
                        value={currentEditableContainer.demurrage_amount}
                      />
                    </td>
                    <td>
                      <div>
                        <button
                          onClick={() => {
                            handleEdit(index);
                          }}
                          style={{
                            outline: "none",
                            border: "none",
                            backgroundColor: COLORS.off_white,
                          }}
                        >
                          <img src={EditIcon} alt="" />
                        </button>
                        <button
                          onClick={() => handleDelete(row)}
                          style={{
                            outline: "none",
                            border: "none",
                            backgroundColor: COLORS.off_white,
                            marginLeft: "8px",
                          }}
                        >
                          <img src={DeleteIcon} alt="" />
                        </button>
                      </div>
                    </td>
                  </tr>
                ) : (
                  <tr
                    key={index}
                    style={{
                      borderTop: "1px solid #BDBDBD33",
                      fontSize: smallLaptop ? "11px" : "14px",
                      height: "58px",
                    }}
                  >
                    <td> {row.container_id ?? "_"}</td>
                    <td> {row.type ?? "_"}</td>
                    <td> {row.steamship_line ?? "_"}</td>
                    <td> {row.mbl ?? "_"}</td>
                    <td> {row.demurrage_days ?? "_"}</td>
                    <td> {row.detention_days ?? "_"}</td>
                    <td>
                      {row?.is_in_demurrage
                        ? formatValue(row?.demurrage_amount)
                        : formatValue(row?.detention_amount)}
                    </td>
                    <td>
                      <div>
                        <button
                          onClick={() => {
                            handleEdit(index);
                          }}
                          style={{
                            outline: "none",
                            border: "none",
                            backgroundColor: COLORS.off_white,
                          }}
                        >
                          <img
                            src={EditIcon}
                            alt=""
                            style={{ width: smallLaptop ? "11px" : "16px" }}
                          />
                        </button>
                        <button
                          onClick={() => handleDelete(row)}
                          style={{
                            outline: "none",
                            border: "none",
                            backgroundColor: COLORS.off_white,
                            marginLeft: "8px",
                          }}
                        >
                          <img
                            src={DeleteIcon}
                            alt=""
                            style={{ width: smallLaptop ? "11px" : "16px" }}
                          />
                        </button>
                      </div>
                    </td>
                  </tr>
                )}
              </>
            );
          })}
          <tr
            style={{
              fontSize: "14px",
              borderTop: "1px solid #BDBDBDCC",
              height: "49px",
            }}
          >
            <td />
            <td />
            <td />
            <td />
            <td />
            <td
              style={{
                textAlign: "right",
                paddingRight: "10px",
                fontWeight: "600",
                fontSize: smallLaptop ? "11px" : "16px",
              }}
            >
              Total:
            </td>
            <td
              style={{
                color: COLORS.primary,
                fontWeight: "600",
                fontSize: smallLaptop ? "11px" : "16px",
              }}
            >
              ${Number(totalAmount).toLocaleString()}
            </td>
            <td />
          </tr>
        </table>
      ) : (
        <div
          style={{
            width: "100%",
            height: smallLaptop ? "100px" : "155px",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            borderTop: "1px solid #BDBDBDCC",
            color: COLORS.dark_grey,
            fontSize: smallLaptop ? "11px" : "16px",
          }}
        >
          Add container to see total amount
        </div>
      )}
    </Box>
  );
}
