import React, { useEffect, useState } from "react";
import AlternativeFuelRow from "./AlternativeFuel/AlternativeFuelRow";
import RadarChart from "./AlternativeFuel/Graph";

const AlternativeFuelBox = (props) => {
  let emissionStartValue = 200000;
  const [focus, setFocus] = useState(false);

  useEffect(() => {
    if (focus) return;
    let newArray = props.data.map((item, index) => {
      return index !== 0 && item.value === 0
        ? { ...item, isActive: false }
        : item;
    });

    props.setData(newArray);
  }, [focus]);

  const changeIsActive = (id) => {
    let newData = JSON.parse(JSON.stringify(props.data));

    newData[id].isActive = !newData[id].isActive;
    if (!newData[id].isActive) {
      newData[0].value += newData[id].value;

      newData[id].value = 0;
      newData[id].emission = 0;
    }
    props.setData(newData);
  };
  const changeValue = (id, value) => {
    let newData = JSON.parse(JSON.stringify(props.data));

    newData[id].value = parseInt(value);
    newData[id].emission = (newData[id].value / 100) * emissionStartValue;
    newData[0].value -= value - props.data[id].value;
    newData[0].emission -= newData[id].emission - props.data[id].emission;

    if (newData[0].value < 0) {
      return;
    }
    if (value === 0 && !focus) {
      newData[id].isActive = false;
    }
    props.setData(newData);
  };
  return (
    <div className="template_box w25 flex-shrink_box">
      <p className="fw600 c-white mb8">Fuel consumption</p>
      <p className="c-dark-grey fs14 m0">
        Set granual goals in an effort to avoid CO2 emissions
      </p>
      <RadarChart
        labels={props.data
          .filter((item) => item.showOnGraph)
          .map((item) => {
            return item.name;
          })}
        values={props.data
          .filter((item) => item.showOnGraph)
          .map((item) => {
            return item.value;
          })}
      />
      {props.data &&
        props.data.map((item, index) => {
          return (
            <AlternativeFuelRow
              id={index}
              key={index}
              data={item}
              changeActive={changeIsActive}
              changeValue={changeValue}
              setFocus={setFocus}
            />
          );
        })}
    </div>
  );
};
export default AlternativeFuelBox;
