import create from "zustand";
import { persist, devtools } from "zustand/middleware";

let store = (set) => ({
  currentUser: {},
  setUser: (user) => set((state) => ({ currentUser: user })),
  navbarLabel: {},
  setNavbarLabel: (label) => set((state) => ({ navbarLabel: label })),
  openSidebar: {},
  setOpenSidebar: (label) => set((state) => ({ openSidebar: label })),
  fetchNotifications: false,
  setFetchNotifications:(value) => set((state) => ({ fetchNotifications: value})),
});
store = devtools(store);
store = persist(store);

const useStore = create(store);
export default useStore;
