import React from "react";
import ReactApexChart from "react-apexcharts";
import FiberManualRecordIcon from "@mui/icons-material/FiberManualRecord";
import {
  formatMonths,
  handleGraphBarWidth,
} from "../../../D&DAnalytics/utils/constants";

const TopEmissionPortRail = (props) => {
  const series = props.top_terminals_by_emission.data;
  const colors = ["#FF00B8", "#00FF65", "#00FFFF", "#BD00FF"];

  const options = {
    chart: {
      zoom: {
        enabled: false,
      },
      toolbar: {
        show: false,
      },
    },
    plotOptions: {
      bar: {
        horizontal: false,
        columnWidth: handleGraphBarWidth(
          props.top_terminals_by_emission.months.length,
          3
        ),
        borderRadius: 2,
        opacity: 1,
      },
    },
    legend: {
      show: false,
    },
    dataLabels: {
      enabled: false,
    },
    stroke: {
      colors: ["solid"],
      width: 2,
    },
    grid: {
      show: true,
      strokeDashArray: 5,
      padding: {
        top: -15,
      },
    },
    yaxis: {
      labels: {
        formatter: function (value) {
          return `${parseInt(value / 1000)} tCO2`;
        },
      },

      axisBorder: {
        show: true,
        color: "#F1F1F1",
        width: 2,
        height: "100%",
      },
    },
    xaxis: {
      categories: formatMonths(props.top_terminals_by_emission.months),
      type: "value",
      axisBorder: {
        show: true,
        color: "#f9f9f9",
        height: 2,
        width: "100%",
      },
      axisTicks: {
        show: false,
      },
    },
    colors: colors,
    fill: {
      type: ["solid", "solid", "solid"],
    },
  };
  return (
    <div
      className="emmissionsPortRail"
      style={{ display: "flex", flexDirection: "column", flexGrow: 1 }}
    >
      <p className="p-16-b-white">Top Emission Port/Rail</p>
      <div style={{ flexGrow: 1 }}>
        <ReactApexChart
          options={options}
          series={series}
          type="bar"
          height="100%"
        />
      </div>
      <div className="emmissionsPortRailTextFlex">
        {props.top_terminals_by_emission?.data &&
          props.top_terminals_by_emission.data.map((item, index) => {
            return (
              <div key={index} className="emmissionsPortRailText">
                <FiberManualRecordIcon
                  style={{ color: colors[index], width: "8px" }}
                />
                <p className="c-white m0">{item.name}</p>
              </div>
            );
          })}
      </div>
    </div>
  );
};
export default TopEmissionPortRail;
