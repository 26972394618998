import React, { useEffect, useState } from "react";

import TruckIcon from "../../assets/truck.svg";
import NotSavedIcon from "../../assets/not_saved_purple.svg";
import SavedIcon from "../../assets/purple_saved.svg";
import { COLORS } from "../../../../styles/themes/default/colors";
import DropDownIcon from "../../assets/drop_down.svg";
import LocationIcon from "../../assets/location_purple.svg";
import moment from "moment";
import PortGridInfo from "../MutualComponents/GridInfo";
import PortTrackingView from "../MutualComponents/TrackingView";
import PortDemurrageInfo from "../MutualComponents/DemurrageInformation";

import "../../styles/alertStyles.scss";

const StatusBar = ({ status }) => {
  const cls =
    status === "OTD"
      ? "purple_box"
      : status === "DT"
      ? "green_box"
      : status === "AT"
      ? "yellow_box"
      : status === "ER"
      ? "ligth_blue_box"
      : "dark_blue_box";
  const title =
    status === "OTD"
      ? "Out to delivery"
      : status === "DT"
      ? "Departing today"
      : status === "AT"
      ? "Arriving today"
      : status === "ER"
      ? "Empty returned"
      : "At port";
  const dotColor =
    status === "OTD"
      ? "#9f30c6"
      : status === "DT"
      ? "#51da87"
      : status === "AT"
      ? "#c8df6e"
      : status === "ER"
      ? "#41baee"
      : "#2372e9";

  return (
    <div className={cls}>
      <div className="dot" style={{ backgroundColor: dotColor }} /> {title}
    </div>
  );
};

const AlertTableRow = ({ index, onItemClick }) => {
  const alertIds = {
    1: "ZCSU7662930",
    2: "SEGU5136093",
    3: "TRLU7433463",
    4: "TGHU6806752",
    5: "MSMU4693759",
    6: "TRHU8315235",
    7: "TCNU2301333",
    8: "TEMU6341210",
    9: "CMAU8198999",
  };
  const [openDetail, setOpenDetail] = useState(null);
  const [isClosed, setIsClosed] = useState(null);
  const [gradientColor, setGradientColor] = useState("#1f1f1f");
  const listOfColors = [
    "#1f1f1",
    "#343434",
    "#4b4b4b",
    "#626262",
    "#7b7b7b",
    "#626262",
    "#4b4b4b",

    "#343434",
    "#1f1f10",

    "#1f1f18",

    "#1f1f1f",
  ];

  useEffect(() => {
    if (isClosed === false && openDetail === false) {
      listOfColors.forEach((color, index) => {
        setTimeout(() => {
          setGradientColor(color);
        }, 50 * index);
      });
    }
  }, [isClosed]);
  return (
    <>
      <div
        className="alert_table_row"
        onClick={() => {
          onItemClick(index);
        }}
        style={{
          background: `linear-gradient(to right , #1f1f1f, ${gradientColor})`,
          marginBottom: openDetail ? "0px" : "16px",
        }}
      >
        <div className="alert_dot" />

        <div className="container_id_alert">#{alertIds[index]} </div>
        {!openDetail ? (
          <div
            style={{
              width: "370px",
              display: "flex",
              justifyContent: "flex-start",
              alignItems: "center",
            }}
          >
            <div
              className="dot"
              style={{
                backgroundColor:
                  index % 5 === 0
                    ? "#9f30c6"
                    : index % 5 === 1
                    ? "#51da87"
                    : index % 5 === 2
                    ? "#c8df6e"
                    : index % 5 === 3
                    ? "#41baee"
                    : "#2372e9",
              }}
            />
          </div>
        ) : (
          <div
            style={{
              width: "370px",
              display: "flex",
              justifyContent: "flex-start",
              alignItems: "center",
            }}
          >
            <StatusBar
              status={
                index % 5 === 0
                  ? "OTD"
                  : index % 5 === 1
                  ? "DT"
                  : index % 5 === 2
                  ? "AT"
                  : index % 5 === 3
                  ? "ER"
                  : "AP"
              }
            />
          </div>
        )}

        <div
          style={{
            width: "60px",
            paddingLeft: "25px",
            display: "flex",
            justifyContent: "flex-start",
            alignItems: "center",
          }}
        >
          <button
            style={{
              outline: "none",
              border: "none",
              backgroundColor: "transparent",
            }}
          >
            <img src={index % 2 === 0 ? SavedIcon : NotSavedIcon} alt="" />
          </button>
        </div>
        <div
          style={{
            width: "150px",
            display: "flex",
            justifyContent: "flex-start",
            alignItems: "center",
          }}
        >
          <button
            onClick={() => {
              setOpenDetail(!openDetail);
              setIsClosed(!openDetail);
            }}
            style={{
              width: "100%",
              display: "flex",
              justifyContent: "flex-end",
              paddingRight: "16px",
              color: COLORS.activity,
              fontSize: "14px",
              fontWeight: 600,
              backgroundColor: "transparent",
              border: "none",
              alignItems: "center",
              outline: "none",
            }}
          >
            {openDetail ? "Hide details" : "Show details"}
            <img
              src={DropDownIcon}
              alt=""
              style={{
                marginLeft: "8px",
                transform: openDetail ? "rotate(180deg)" : "rotate(0deg)",
              }}
            />
          </button>
        </div>
      </div>
      <div
        style={{
          width: "98%",
          height: openDetail ? "750px" : "0px",
          transition: "0.3s all ease-in-out",
          overflow: openDetail ? "visible" : "hidden",
        }}
      >
        <div
          style={{
            width: "100%",
            marginTop: "8px",
            display: "flex",
            marginBottom: "16px",
          }}
        >
          <PortGridInfo />
          <PortTrackingView />
        </div>
      </div>
    </>
  );
};

export default AlertTableRow;
