import React from "react";

import RadialBarEsg from "./FirstColumnComponents/RadialBarEsg";

import GeoChart from "./FirstColumnComponents/GeoChart";
import useStore from "../../../store/store";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import EmissionPerShipmentGraph from "./FirstColumnComponents/EmissionPerShipment";

export default function EsgFirstColumn(props) {
  const currentUser = useStore((state) => state.currentUser);
  const history = useHistory();

  return (
    <div className="d-flex f-dir-col h100 gap16">
      {currentUser.user.role.startsWith("C") ? (
        <>
          <div className="p16 bc-black d-flex f-dir-col f-grow1 br8">
            <div className="d-flex justify-between">
              <p className="p-16-b-white">Heat Map</p>
              <p
                className="c-blue"
                style={{ cursor: "pointer" }}
                onClick={() => {
                  history.push("/esg/goals");
                }}
              >
                Edit Goals
              </p>
            </div>
            <div className="w100 f-grow1">
              <GeoChart data={props.data} />
            </div>
          </div>
          <div className="bc-black p16 br8">
            <div className="d-flex justify-between">
              <p className="c-white fw600" style={{ marginBottom: 0 }}>
                Total Emissions
              </p>
            </div>
            <RadialBarEsg data={props.data} />
          </div>
        </>
      ) : (
        <div className="p16 bc-black d-flex f-dir-col f-grow1 br8">
          <div className="d-flex justify-between">
            <p className="c-white fw600">Emissions</p>
            <p
              className="c-blue fw600"
              style={{ cursor: "pointer" }}
              onClick={() => {
                history.push("/esg/goals");
              }}
            >
              Edit Goals
            </p>
          </div>
          <div className="w100">
            <GeoChart data={props.data} />
          </div>
          <RadialBarEsg data={props.data} />
        </div>
      )}
      <div className="bc-black p8 br8">
        <p className="p-16-b-white" style={{ marginBottom: "24px" }}>
          Emissions per shipment
        </p>
        <div className="emmissionsShipmentGraf">
          <div
            style={{
              flexGrow: 1,
              display: "flex",
              justifyContent: "center",
            }}
            className="d-flex justify-center f-grow1"
          >
            <p className="pt16 c-white fs32 m0 fw600">
              {(parseFloat(props.esg_per_shipment) / 1000).toFixed(2)} tCO
              <sub>2</sub>e
            </p>
          </div>
          <div>
            <EmissionPerShipmentGraph
              value={(parseFloat(props.esg_per_shipment) / 1000).toFixed(2)}
            />
          </div>
        </div>
      </div>
    </div>
  );
}
