import React, { useState, useEffect } from "react";

import Chart from "react-apexcharts";
import { useMediaQuery, createTheme } from "@mui/material";

import Skeleton from "react-loading-skeleton";
import "../../styles/columnBarStatistics.scss";
import { COLORS } from "../../../../styles/themes/default/colors";

const SingleLabelColumnBar = ({
  loading,
  colors,
  showTable,
  streetTurnData,
  customSortData,
}) => {
  const theme1 = createTheme({
    breakpoints: {
      values: {
        sl: 1400,
      },
    },
  });
  const smallLaptop = useMediaQuery(theme1.breakpoints.down("sl"));
  // const [locations, setLocations] = useState();
  const [series, setSeries] = useState([
    { name: "Maersk", data: [5] },
    { name: "OOCL", data: [5] },
    { name: "ONE", data: [4] },
    { name: "CMA CGM", data: [3] },
    { name: "Evergreen", data: [3] },
    { name: "MSC", data: [2] },
  ]);
  const [options, setOptions] = useState({});
  useEffect(() => {
    // if (streetTurnData) {
    //   setSeries(streetTurnData);
    // }
    setOptions({
      chart: {
        type: "bar",
        toolbar: {
          show: false,
          tools: {
            download: false,
          },
        },
      },
      plotOptions: {
        bar: {
          horizontal: false,
          columnWidth: "80%",
          borderRadius: 8,
          opacity: 1,
          borderRadiusApplication: "end",
        },
      },
      legend: {
        show: false,
      },
      dataLabels: {
        enabled: false,
      },
      stroke: {
        colors: ["solid"],
        width: 35,
      },
      xaxis: {
        categories: [""],
        labels: {
          style: {
            fontFamily: "Montserrat",
            colors: "#adadad",
            fontWeight: 600,
            fontSize: "12px",
          },
        },
        axisBorder: {
          show: true,
          color: "#f9f9f9",
          height: 2,
          width: "100%",
        },
      },
      grid: { show: true, strokeDashArray: 5, padding: { bottom: 0 } },
      yaxis: {
        labels: {
          formatter: function (value) {
            return value;
          },
          style: {
            fontFamily: "Montserrat",
            colors: "#adadad",
            fontWeight: 600,
            fontSize: "12px",
          },
        },
        axisBorder: {
          show: true,
          color: "#f9f9f9",
          width: 2,
          height: "100%",
        },
      },
      colors: colors,
      fill: {
        // type: ["solid", "solid", "solid"],
      },
      tooltip: {
        enabled: false,
        onDatasetHover: {
          highlightDataSeries: false,
        },
        marker: {
          show: false,
        },
        // custom: null,
      },
    });
  }, [loading, streetTurnData]);

  return (
    <div className="single_column_bar">
      {loading ? (
        <Skeleton height={150} />
      ) : (
        <div
          style={{
            width: "100%",
            flexGrow: 1,
          }}
        >
          <Chart options={options} series={series} type="bar" height="100%" />
        </div>
      )}
      {loading ? (
        <Skeleton width={300} count={3} style={{ marginTop: "10px" }} />
      ) : (
        <div className="single_column_bar_legend" style={{ flexGrow: 0 }}>
          {series.map((item, index) => {
            return (
              <div
                style={{ display: "flex", gap: "4px", alignItems: "center" }}
                key={index}
              >
                <div
                  style={{
                    width: "8px",
                    height: "8px",
                    borderRadius: "25px",
                    backgroundColor: colors.at(index),
                  }}
                />
                <p className="c-white m0" style={{ fontSize: "14px" }}>
                  {item.name}
                </p>
              </div>
            );
          })}
        </div>
      )}
    </div>
  );
};

export default SingleLabelColumnBar;
