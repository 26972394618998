import React, { useState } from "react";
import ReactApexChart from "react-apexcharts";
import Skeleton from "react-loading-skeleton";
import BlackSwitch from "./BlackSwitch";
import { formatMonths, kFormatObjectValues } from "../utils/constants";

const colors = ["#FF1DA4", "#51DA88", "#41BAEE"]

const MultipleAreaPlot = ({
  isLoading,
  title,
  data,
  setSelectedItem,
  clickable = false,
  demDetSwitch: externalDemDetSwitch,
  setDemDetSwitch: externalSetDemDetSwitch,
}) => {
  const [internalDemDetSwitch, setInternalDemDetSwitch] = useState(false);

  // Check if external switch and setter are provided
  const isExternalSwitchProvided = externalDemDetSwitch !== undefined;
  const isExternalSetterProvided = externalSetDemDetSwitch !== undefined;

  // Determine which switch value and setter function to use
  const demDetSwitch = isExternalSwitchProvided ? externalDemDetSwitch : internalDemDetSwitch;
  const setDemDetSwitch = isExternalSetterProvided ? externalSetDemDetSwitch : setInternalDemDetSwitch;

  const handleClick = (item) => {
    if (clickable)
      setSelectedItem({ name: item?.name, isDem: !demDetSwitch });
  }

  let seriesDem = kFormatObjectValues(data?.dem?.charges)
  let seriesDet = kFormatObjectValues(data?.det?.charges)

  let options = {
    colors: colors,
    chart: {
      type: "area",
      zoom: {
        enabled: false,
      },
      toolbar: {
        show: false,
      },
    },
    grid: {
      show: true,
      strokeDashArray: 5,
      padding: {
        bottom: -5,
        top: -10,
        right: -10,
      },
    },
    dataLabels: {
      enabled: false,
    },
    stroke: {
      curve: "straight",
      width: 2,
    },
    fill: {
      type: 'gradient',
      gradient: {
        shadeIntensity: 0,
        opacityFrom: 0.6,
        opacityTo: 0.1,
        stops: [0, 100]
      }
    },
    labels: formatMonths(data?.det?.months),
    xaxis: {
      type: "value",
      labels: {
        style: {
          fontFamily: "Montserrat",
          fontSize: "12px",
          fontWeight: 600,
          colors: '#adadad'
        },
      },
      axisBorder: {
        show: true,
        color: '#f9f9f9',
        height: 2,
        width: '100%',
      },
      axisTicks: {
        show: true,
        borderType: 'solid',
        color: '#f9f9f9',
        height: 8,
      }
    },
    yaxis: {
      labels: {
        formatter: function (value) {
          return `$${value.toFixed(1)}k`;
        },
        style: {
          fontFamily: "Montserrat",
          colors: '#adadad',
          fontWeight: 600,
          fontSize: "12px",
        },
      },
      axisBorder: {
        show: true,
        color: '#f9f9f9',
        width: 2,
        height: '100%',
      },
    },
    menu: {
      show: false,
    },
    legend: {
      horizontalAlign: "left",
      show: false,
    },
  };
  return (
    <div className="statistics_template_box" style={{ flexGrow: 1 }}>
      {isLoading ? (
        <div style={{ height: "100%", width: "100%" }}>
          <Skeleton width="100%" height="100%" />
        </div>
      ) : (
        <>
          <div className="multiple_area_header_box">
            <p className="chart_title">
              {title}
            </p>
            <BlackSwitch
              demDetSwitch={demDetSwitch}
              setDemDetSwitch={setDemDetSwitch}
            />
          </div>
          <div className="multiple_area_flex_box">
            <ReactApexChart
              type="area"
              options={options}
              series={demDetSwitch ? seriesDet : seriesDem}
              height={window.innerWidth > 1920 ? 300 : 241}
              style={{ width: window.innerWidth > 1920 ? "100%" : "72%" }}
            />
            <div className="multiple_area_legend_box_ports">
              {data ? (demDetSwitch ? data?.det?.charges?.map((item, index) => {
                return (
                  <div
                    key={index}
                    style={{ display: "flex", alignItems: "center", gap: "6px", cursor: clickable ? "pointer" : "" }}
                    onClick={() => handleClick(item)}
                  >
                    <div
                      style={{ backgroundColor: colors[index] }}
                      className="chart_dot"
                    />
                    <div style={{ width: '180px' }}>
                      <p className="white_14px" style={{
                        textDecoration: clickable ? "underline" : "none",
                        textDecorationColor: 'gray'
                      }}
                      >
                        {item.name}
                      </p>
                    </div>
                  </div>
                )
              }) : data?.dem?.charges?.map((item, index) => {
                return (
                  <div
                    key={index}
                    style={{ display: "flex", alignItems: "center", gap: "6px", cursor: clickable ? "pointer" : "" }}
                    onClick={() => handleClick(item)}
                  >
                    <div
                      style={{ backgroundColor: colors[index] }}
                      className="chart_dot"
                    />
                    <div style={{ width: '180px' }} >
                      <p className="white_14px" style={{
                        textDecoration: clickable ? "underline" : "none",
                        textDecorationColor: 'gray'
                      }}>
                        {item.name}
                      </p>
                    </div>
                  </div>
                )
              })) :
                demDetSwitch ? (
                  <div className="multiple_area_legend_box_ports">
                    <div
                      style={{ display: "flex", alignItems: "center", gap: "6px" }}
                    >
                      <div
                        style={{ backgroundColor: "#FF1DA4" }}
                        className="chart_dot"
                      />
                      <p className="white_14px">CMA CGM</p>
                    </div>
                    <div
                      style={{ display: "flex", alignItems: "center", gap: "6px" }}
                    >
                      <div
                        style={{ backgroundColor: "#51DA88" }}
                        className="chart_dot"
                      />
                      <p className="white_14px">MSC</p>
                    </div>
                    <div
                      style={{ display: "flex", alignItems: "center", gap: "4px" }}
                    >
                      <div
                        style={{ backgroundColor: "#41BAEE" }}
                        className="chart_dot"
                      />
                      <p className="white_14px">Evergreen</p>
                    </div>
                  </div>
                ) : (
                  <div className="multiple_area_legend_box_ports">
                    <div
                      style={{ display: "flex", alignItems: "center", gap: "4px" }}
                    >
                      <div
                        style={{ backgroundColor: "#FF1DA4" }}
                        className="chart_dot"
                      />
                      <p className="white_14px">Long Beach, CA</p>
                    </div>
                    <div
                      style={{ display: "flex", alignItems: "center", gap: "4px" }}
                    >
                      <div
                        style={{ backgroundColor: "#51DA88" }}
                        className="chart_dot"
                      />
                      <p className="white_14px">Houston, TX</p>
                    </div>
                    <div
                      style={{ display: "flex", alignItems: "center", gap: "4px" }}
                    >
                      <div
                        style={{ backgroundColor: "#41BAEE" }}
                        className="chart_dot"
                      />
                      <p className="white_14px">New Jersey, NY</p>
                    </div>
                  </div>
                )}
            </div>
          </div>
        </>
      )}
    </div>
  );
};
export default MultipleAreaPlot;
