import React, { useState, useEffect } from "react";

import ReactApexChart from "react-apexcharts";
import { useMediaQuery, createTheme } from "@mui/material";

import Skeleton from "react-loading-skeleton";
import "../../styles/columnBarStatistics.scss";
import { COLORS } from "../../../../styles/themes/default/colors";
import { kFormatter } from "../../../../utils/constants";

const ColumnBarStatistics = ({
  statistics,
  loading,
  colors,
  showTable,
  streetTurnData,
  customSortData,
}) => {
  const theme1 = createTheme({
    breakpoints: {
      values: {
        sl: 1400,
      },
    },
  });

  const smallLaptop = useMediaQuery(theme1.breakpoints.down("sl"));
  // const [locations, setLocations] = useState();
  const [series, setSeries] = useState([]);
  const [options, setOptions] = useState({});
  useEffect(() => {
    let data;
    if (statistics?.ssls !== undefined) {
      setSeries(statistics.ssls);
    } else if (statistics?.terminals !== undefined) {
      setSeries(statistics.terminals);
    } else if (statistics?.data !== undefined) {
      setSeries(statistics.data);
    }
    else {
      if (showTable !== undefined && !showTable) {
        data = [{ name: "APM PORT ELIZABET", data: [1254, 3321, 4964] }];
      } else {
        data = [
          { name: "APM PORT ELIZABET", data: [1254, 3321, 4964] },
          { name: "CONTAINER TRANSPORTATION INC", data: [543, 4486, 2321] },
          { name: "APL CHICAGO", data: [4922, 1540, 3810] },
        ];
      }

      let data2 = [
        { name: "Maersk", data: [5] },
        { name: "OOCL", data: [5] },
        { name: "ONE", data: [4] },
        { name: "CMA CGM", data: [3] },
        { name: "Evergreen", data: [3] },
        { name: "MSC", data: [2] },
      ];
      if (colors) {
        setSeries(
          streetTurnData && Object.keys(streetTurnData).length > 0
            ? streetTurnData
            : data
        );
      } else {
        setSeries(data);
      }
    }
    setOptions({
      chart: {
        zoom: {
          enabled: false,
        },
        toolbar: {
          show: false,
        },
      },
      plotOptions: {
        bar: {
          horizontal: false,
          columnWidth: "20%",
          borderRadius: 2,
          opacity: 1,
          borderRadiusApplication: "end",
        },
      },
      legend: {
        show: false,
      },
      dataLabels: {
        enabled: false,
      },
      stroke: {
        colors: ["transparent"],
        width: colors && colors.length > 3 ? 10 : 3,
      },
      grid: {
        show: true, strokeDashArray: 5,
        padding: {
          bottom: -10,
          right: -5,
          top: -15,
        },
      },
      xaxis: {
        categories: statistics?.months
          ? statistics?.months
          : colors
            ? colors.length > 3
              ? [""]
              : ["July", "August", "September"]
            : ["July", "August", "September"],
        axisBorder: {
          show: true,
          color: "#f9f9f9",
          height: 3,
          width: "100%",
        },
      },
      yaxis: {
        labels: {
          formatter: function (value) {
            return `${statistics?.departing_vessels !== undefined ? "" : "$"}${kFormatter(value)} `;
          },
        },
        axisBorder: {
          show: true,
          color: "#f9f9f9",
          width: 4,
          height: "100%",
        },
      },
      colors: colors ?? ["#FF1DA4", "#51DA88", "#41BAEE"],
      fill: {
        type: ["solid", "solid", "solid"],
      },
    });
  }, [statistics, loading, streetTurnData]);
  return (
    <div className="column-bar-statistics-div">
      {loading ? (
        <Skeleton height={150} />
      ) : (
        <div
          style={{
            width: "100%",
            height: `calc(100% - ${27 * series.length + 5}px)`,
          }}
        >
          <ReactApexChart
            options={options}
            series={series}
            type="bar"
            height={"100%"}
          />
        </div>
      )}
      {loading ? (
        <Skeleton width={300} count={3} style={{ marginTop: "10px" }} />
      ) : (
        <div id="columnBarLegends" style={{ flexGrow: 0 }}>
          {!streetTurnData &&
            series.slice(0, 3).map((item, index) => {
              if (colors && colors.length > 0) {
                if (colors.length <= 5) {
                  return (
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        marginBottom: "8px",
                      }}
                      key={index}
                    >
                      <div
                        style={{
                          width: "8px",
                          height: "8px",
                          backgroundColor: colors.at(index),
                          borderRadius: "25px",
                          marginRight: "8px",
                        }}
                      />
                      <p
                        style={{
                          fontSize: smallLaptop ? "10px" : "14px",
                          lineHeight: "17px",
                          color: COLORS.off_white,
                          margin: 0,
                        }}
                      >
                        {item.name}
                      </p>
                    </div>
                  );
                }
              } else {
                return (
                  <p className="location" key={index}>
                    {item.name}
                  </p>
                );
              }
            })}
        </div>
      )}
    </div>
  );
};

export default ColumnBarStatistics;
