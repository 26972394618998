import React, { useState } from "react";

import { createTheme, useMediaQuery } from "@mui/material";
import FormControlLabel from "@mui/material/FormControlLabel";
import { styled } from "@mui/material/styles";
import Switch from "@mui/material/Switch";
import FiberManualRecordIcon from "@mui/icons-material/FiberManualRecord";

import { COLORS } from "../../../styles/themes/default/colors";

const SingleNotification = ({
  title,
  description,
  hasActiveEmail,
  hasActiveApp,
  id,
  handleChangeAppNotifications,
  handleChangeEmailNotifications,
  hasEmailNotifications,
}) => {
  const [inAppChecked, setInAppChecked] = useState(hasActiveApp);
  const [emailChecked, setEmailChecked] = useState(hasActiveEmail);
  const theme1 = createTheme({
    breakpoints: {
      values: {
        xs: 0,
        mobile: 376,
        sm: 600,
        md: 900,
        lg: 1200,
        xl: 1536,
        sl: 1400,
      },
    },
  });

  const smallLaptop = useMediaQuery(theme1.breakpoints.down("sl"));
  const smDown = theme1.breakpoints.down("sm");
  const mobileDown = theme1.breakpoints.down("mobile");

  const IOSSwitch = styled((props) => (
    <Switch
      focusVisibleClassName=".Mui-focusVisible"
      disableRipple
      {...props}
    />
  ))(({ theme }) => ({
    width: smallLaptop ? 34 : 42,
    height: smallLaptop ? 18 : 26,
    padding: 0,
    margin: 0,

    "& .css-5ryogn-MuiButtonBase-root-MuiSwitch-switchBase.Mui-disabled": {
      color: COLORS.sunrise_orange,
    },
    "& .css-5ryogn-MuiButtonBase-root-MuiSwitch-switchBase.Mui-checked.Mui-disabled":
      {
        color: COLORS.white,
      },

    "& .MuiSwitch-switchBase": {
      padding: 0,
      margin: 2,
      transitionDuration: "1000ms",
      color: COLORS.sunrise_orange,
      "&.Mui-checked": {
        transform: "translateX(16px)",
        color: "#fff",
        "& + .MuiSwitch-track": {
          backgroundColor: COLORS.sunrise_orange,
          opacity: 1,
          border: 0,
        },
        "&.Mui-disabled + .MuiSwitch-track": {
          opacity: 0.5,
        },
      },
      "&.Mui-focusVisible .MuiSwitch-thumb": {
        color: "#33cf4d",
        border: "6px solid #fff",
      },
      "&.Mui-disabled .MuiSwitch-thumb": {
        opacity: 0.7,
      },
      "&.Mui-disabled + .MuiSwitch-track": {
        opacity: 0.7,
      },
    },
    "& .MuiSwitch-thumb": {
      width: smallLaptop ? 14 : 22,
      height: smallLaptop ? 14 : 22,
    },
    "& .MuiSwitch-track": {
      borderRadius: 26 / 2,
      backgroundColor: "#ffffff",
      border: "1px solid rgba(140, 140, 140, 0.4)",
      opacity: 1,
    },
    [mobileDown]: {
      width: 38,
      height: 22,
      "& .MuiSwitch-thumb": {
        width: 18,
        height: 18,
      },
    },
  }));

  return (
    <div>
      <p className="p-16" style={{ paddingLeft: "20px" }}>
        {description}
      </p>
      <div style={{ display: "flex", paddingRight: "50px" }}>
        <div style={{ width: "80%" }}>
          <p className="p-16-b" style={{ margin: "7px 0px 0px 20px" }}>
            <FiberManualRecordIcon
              style={{ width: "10px", margin: "0px 4px 4px 0px" }}
            />
            {title}
          </p>
        </div>
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            width: "20%",
          }}
        >
          <FormControlLabel
            sx={{ margin: "0" }}
            control={
              <IOSSwitch
                sx={{ m: 0.8 }}
                checked={inAppChecked}
                onChange={(e, value) => {
                  handleChangeAppNotifications(id);
                  setInAppChecked(e.target.checked);
                }}
              />
            }
          />
          <FormControlLabel
            sx={{ margin: "0" }}
            control={
              <IOSSwitch
                sx={{ m: 0.8 }}
                checked={emailChecked}
                onChange={(e, value) => {
                  handleChangeEmailNotifications(id);
                  setEmailChecked(e.target.checked);
                }}
              />
            }
            disabled={hasEmailNotifications ? false : true}
          />
        </div>
      </div>
    </div>
  );
};

export default SingleNotification;
