import React from "react";
import { Dialog, createTheme, useMediaQuery } from "@mui/material";
import closeModalIcon from "../../../../../assets/img/closeModalIcon.png";

import moment from "moment";
import "../../style/dndModalStyles.scss";
import { Button } from "../../../../../components/Cdl/Button";
import { formatValue } from "../../../../../utils/constants";

export default function DndModal({ data, setData }) {
  const theme1 = createTheme({
    breakpoints: {
      values: {
        sl: 1400,
      },
    },
  });

  const smallLaptop = useMediaQuery(theme1.breakpoints.down("sl"));

  const periods = { 0: "Free", 1: "1st", 2: "2nd", 3: "3rd" };

  const formatDayAndMonth = (date) => {
    return date ? moment(date, "YYYY-MM-DD").format("MM.DD") : "";
  };
  const isOpen = Object.keys(data).length > 0;

  return (
    <>
      <Dialog
        sx={{
          "& .MuiDialog-container": {
            "& .MuiPaper-root": {
              maxWidth: "none",
              width: smallLaptop ? "800px" : "1150px",
            },
          },
        }}
        open={isOpen}
        onClose={() => {
          setData({});
        }}
      >
        <div className="mainModalDiv">
          <div className="headerDiv">
            <p className="headerText">D&D details</p>
            <img
              src={closeModalIcon}
              className="closeImg"
              onClick={() => {
                setData({});
              }}
              alt="close icon"
              style={{
                cursor: "pointer",
              }}
            />
          </div>
          <div className="contentDiv">
            <>
              <div className="contentHeaderDiv">
                <p className="containersHeaderText">
                  Container ID:
                  <p className="containerId">{data.container_id}</p>
                </p>
              </div>

              <div className="freeDayDiv">
                <div
                  style={{
                    display: "flex",
                    padding: "20px 0",
                    width: "70%",
                    background: "#fafafa",
                  }}
                >
                  <div className="portFreeDayDiv">
                    <div className="portFreeRow">
                      <p className="labelGray">Demurrage first free day:</p>
                      <p className="labelBold">
                        {data?.demurrage_first_free_day ?? "_"}
                      </p>
                    </div>
                    <p className="labelTime">08:00 AM</p>
                    <div className="portFreeRow">
                      <p className="labelGray">Demurrage last free day:</p>
                      <p className="labelBold">
                        {data?.demurrage_last_free_day ?? "_"}
                      </p>
                    </div>
                    <p className="labelTime">00:00 AM</p>
                    <div className="portFreeRow">
                      <p className="labelGray">Demurrage free days:</p>
                      <p className="labelBold">
                        {data?.is_in_demurrage && data?.demurrage_tariffs
                          ? data?.demurrage_tariffs[0]?.days
                          : "_"}
                      </p>
                    </div>
                  </div>
                  <div className="lineFreeDayDiv">
                    <div className="portFreeRow">
                      <p className="labelGray">Detention first free day:</p>
                      <p className="labelBold">{data?.first_free_day ?? "_"}</p>
                    </div>
                    <p className="labelTime" style={{ height: "21px" }}></p>
                    <div className="portFreeRow">
                      <p className="labelGray">Detention last free day:</p>
                      <p className="labelBold">
                        {data?.last_detention_free_day ?? "_"}
                      </p>
                    </div>
                    <p className="labelTime" style={{ height: "21px" }}></p>
                    <div className="portFreeRow">
                      <p className="labelGray">Detention free days:</p>
                      <p className="labelBold">{data.detention_free_days}</p>

                      <p className="labelBold">
                        {data?.is_in_detention && data?.detention_tariffs
                          ? data?.detention_tariffs[0]?.days
                          : "_"}
                      </p>
                    </div>
                  </div>
                </div>
                <div className="containerInfoDiv">
                  <div className="containerInfoRow">
                    <p className="labelGray">Steamship line:</p>
                    <p className="labelBold">{data?.steamship_line}</p>
                  </div>
                  <br />
                  <div className="containerInfoRow">
                    <p className="labelGray">Customer:</p>
                    <p className="labelBold">{data?.customer}</p>
                  </div>
                  <br />
                  <div className="containerInfoRow">
                    <p className="labelGray">Container type:</p>
                    <div className="containerType">
                      <p className="labelBold">{`${data?.type} ${data?.size}`}</p>
                    </div>
                  </div>
                </div>
              </div>
              <div className="containerdetails">
                <div
                  className="containersFees"
                  style={{
                    borderBottom: 0,
                  }}
                >
                  <div className="demurrageFees">
                    <p className="p-14-b">Demurrage fees</p>
                    <table className="feesTable">
                      <tr>
                        <td className="tableDiv">Tariff</td>
                        <td className="tableDiv">Days</td>
                        <td className="tableDiv">Dates</td>
                        <td
                          className="tableDiv"
                          style={{ textAlign: "right", paddingRight: "16px" }}
                        >
                          Rate
                        </td>
                        <td className="tableAmountDiv">Amount</td>
                      </tr>
                      {data?.is_in_demurrage &&
                        data?.demurrage_tariffs &&
                        data?.demurrage_tariffs.map((fee, index) => {
                          return fee.name !== "At Risk" ? (
                            <tr>
                              <td className="tableColumn">{periods[index]}</td>
                              <td className="tableColumn">{fee?.days}</td>
                              <td className="tableColumn">{`${formatDayAndMonth(
                                fee?.start_date
                              )}-${formatDayAndMonth(fee?.end_date)}`}</td>
                              <td className="tableColumnRate">
                                {formatValue(fee?.rate)}
                              </td>
                              <td className="tableAmountText">
                                {formatValue(fee?.amount)}
                              </td>
                            </tr>
                          ) : (
                            <tr>
                              <td className="grayLabel">At risk</td>
                              <td className="grayLabel">{fee.days}</td>
                              <td className="grayLabel">
                                {`${formatDayAndMonth(
                                  fee?.start_date
                                )}-${formatDayAndMonth(fee?.end_date)}`}
                              </td>
                              <td
                                className="grayLabel"
                                style={{
                                  textAlign: "right",
                                  paddingRight: "16px",
                                }}
                              >
                                {formatValue(fee?.rate)}
                              </td>
                              <td className="grayLabelTotal">
                                {formatValue(fee?.amount)}
                              </td>
                            </tr>
                          );
                        })}
                    </table>
                  </div>
                  <div className="detentionFees">
                    <p className="p-14-b">Detention fees</p>
                    <table className="feesTable">
                      <tr>
                        <td className="tableDiv">Tariff</td>
                        <td className="tableDiv">Days</td>
                        <td className="tableDiv">Dates</td>
                        <td
                          className="tableDiv"
                          style={{
                            textAlign: "right",
                            paddingRight: "16px",
                          }}
                        >
                          Rate
                        </td>
                        <td className="tableAmountDiv">Amount</td>
                      </tr>
                      {data?.is_in_detention &&
                        data?.detention_tariffs &&
                        data?.detention_tariffs.map((fee, index) => {
                          return fee.name !== "At Risk" ? (
                            <tr>
                              <td className="tableColumn">{periods[index]}</td>
                              <td className="tableColumn">{fee?.days}</td>
                              <td className="tableColumn">
                                {`${formatDayAndMonth(fee?.start_date)}-
                                  ${formatDayAndMonth(fee?.end_date)}`}
                              </td>
                              <td className="tableColumnRate">
                                {formatValue(fee?.rate)}
                              </td>
                              <td className="tableAmountText">
                                {formatValue(fee?.amount)}
                              </td>
                            </tr>
                          ) : (
                            <tr>
                              <td className="grayLabel">At risk</td>
                              <td className="grayLabel">{fee.days}</td>
                              <td className="grayLabel">
                                {`${formatDayAndMonth(
                                  fee?.start_date
                                )}-${formatDayAndMonth(fee?.end_date)}`}
                              </td>
                              <td
                                className="grayLabel"
                                style={{
                                  textAlign: "right",
                                  paddingRight: "16px",
                                }}
                              >
                                {formatValue(fee?.rate)}
                              </td>
                              <td className="grayLabelTotal">
                                {formatValue(fee?.amount)}
                              </td>
                            </tr>
                          );
                        })}
                    </table>
                  </div>
                </div>
                <div className="containersFees">
                  <div className="demurrageFees">
                    <table
                      className="feesTable"
                      style={{
                        borderTop: `1px solid rgba(140, 140, 140, 0.4)`,
                      }}
                    >
                      <tr>
                        <td>Demurrage total:</td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td style={{ float: "right", fontWeight: "600" }}>
                          {formatValue(data?.current_demurrage_amount)}
                        </td>
                      </tr>
                      {data?.is_in_demurrage &&
                        data?.demurrage_tariffs &&
                        data?.demurrage_tariffs.some(
                          (fee) => fee.name === "At Risk"
                        ) && (
                          <tr>
                            <td
                              className="grayLabelTotal"
                              style={{ float: "left" }}
                            >
                              At risk total:
                            </td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td
                              className="grayLabelTotal"
                              style={{ float: "right" }}
                            >
                              {formatValue(data?.potential_demurrage_amount)}
                            </td>
                          </tr>
                        )}
                    </table>
                  </div>
                  <div className="detentionFees">
                    <table
                      className="feesTable"
                      style={{
                        borderTop: `1px solid rgba(140, 140, 140, 0.4)`,
                      }}
                    >
                      <tr>
                        <td>Detention total:</td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td style={{ float: "right", fontWeight: "600" }}>
                          {formatValue(data?.current_detention_amount)}
                        </td>
                      </tr>
                      {data?.is_in_detention &&
                        data?.detention_tariffs &&
                        data?.detention_tariffs.some(
                          (fee) => fee.name === "At Risk"
                        ) && (
                          <tr>
                            <td
                              className="grayLabelTotal"
                              style={{ float: "left" }}
                            >
                              At risk total:
                            </td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td
                              className="grayLabelTotal"
                              style={{ float: "right" }}
                            >
                              {formatValue(data?.potential_detention_amount)}
                            </td>
                          </tr>
                        )}
                    </table>
                  </div>
                </div>
                <div className="containerTotal">
                  <p className="p-16-b">Container total:</p>
                  <p className="p-16-b">
                    {formatValue(
                      data?.demurrage_amount ?? 0 + data?.detention_amount ?? 0
                    )}
                  </p>
                </div>
              </div>
            </>
          </div>
          <div className="footerDiv">
            <div className="flexDiv">
              <p className="p-16-b">D&D Total: </p>
              <p className="totalDemurrageAmonut">
                {/* {container?.current_demurrage_amount
                  ? formatValue(container.current_demurrage_amount)
                  : "$0"} */}
              </p>
            </div>
            <div className="flexDiv">
              <Button
                text="Close"
                type="link"
                css={{ width: "75px" }}
                onClick={() => {
                  setData({});
                }}
              />
            </div>
          </div>
        </div>
      </Dialog>
    </>
  );
}
