import React from "react";
import FiberManualRecordIcon from "@mui/icons-material/FiberManualRecord";
import { kFormatter } from "../../../../../utils/constants"


const PercentageBar = () => {
    const colors = ["#FF1DA4", "#51DA88", "#353535"]
    const data = [
        { name: "Current optimization", value: 1537, percentage: 5 },
        { name: "Available optimization", value: 7585, percentage: 27 },
        { name: "Total", value: 28064, percentage: 68 }
    ]

    const getBorderRadius = (index, length) => ({
        borderTopLeftRadius: index === 0 ? "0.5rem" : "0px",
        borderBottomLeftRadius: index === 0 ? "0.5rem" : "0px",
        borderTopRightRadius: index === length - 1 ? "0.5rem" : "0px",
        borderBottomRightRadius: index === length - 1 ? "0.5rem" : "0px",
        marginRight: index === length - 1 ? "0" : "2px",
    });

    return (
        <div className="w100 mb16">
            <p className="c-white fs14 text-center">
                {`You are currently optimizing ${data[0].percentage}% of your total `}
                tC0<sub>2</sub>e
            </p>
            <p className="fw600 c-white m0 text-center fs24">{kFormatter(data[2].value)}</p>
            <p className="fw600 mb16 c-dark-grey text-center fs14">
                Total tC0<sub>2</sub>e
            </p>
            <div className="w100 d-flex">
                {data?.map((item, index) => (
                    <div
                        key={index}
                        style={{
                            height: "12px",
                            width: index === data.length - 1 ? `calc(${item.percentage}% + 4px)` : `${item.percentage}%`,
                            backgroundColor: colors[index],
                            ...getBorderRadius(index, data.length),
                        }}
                    />
                ))}
            </div>
            <div className="w100 mt16">
                {data?.map((item, index) => (
                    <div
                        key={index}
                        className="w100 d-flex justify-between"
                    >
                        <div className="d-flex gap8"
                            style={{
                                width: "210px",
                            }}
                        >
                            <FiberManualRecordIcon
                                style={{ color: colors[index], width: "15px" }}
                            />
                            <p className="c-white fs14 m0">{item.name}{' '}
                                {index === data.length - 1 ? <>tCO<sub>2</sub>e</> : ''}</p>
                        </div>
                        <p className="fw600 fs14 c-white m0">{item.percentage}%</p>
                        <div className="d-flex gap4 justify-end" style={{ width: "140px" }}>
                            <p className="c-white fs14 m0">
                                {Intl.NumberFormat("en-US").format(parseInt(item.value, 10))}
                            </p>
                            <p className="c-dark-grey fs14 m0">tC0<sub>2</sub>e</p>
                        </div>
                    </div>
                ))}
            </div>
        </div >
    );
};
export default PercentageBar;