import React, { useEffect } from "react";
import { Route, Switch } from "react-router-dom";

import EsgDetails from "./EsgDetails";
import Cart from "./Cart";
import OffsetProjects from "./components/OffsetComponents/OffsetProjects";
import OffSetProjectDetail from "./components/OffsetComponents/OffsetProjectDetails";
import SetGoalsSettings from "./components/SetGoalsSettings";
import apiFactory from "../Container/service/apiFactory";
import { CONTAINER_URL } from "../../utils/constants";
import alertService from "../../services/alertService";
import esgStore from "../../store/esgStore";
import EmissionReductionPlaning from "./EmissionReductionPlaning";

const Esg = ({ match }) => {
  const { path } = match;

  const setCustomers = esgStore((state) => state.setCustomersFilter);
  useEffect(() => {
    async function getFilters() {
      await apiFactory
        .get(`${CONTAINER_URL}analytics/filter_values`)
        .then((resp) => {
          setCustomers(resp.data?.shippers);
        })
        .catch((error) => alertService.error(1, "Problem on our side"));
    }

    getFilters();
  });
  return (
    <div
      // className="p-4"
      id="esg_main_div"
      className="pl16 pr16 d-flex justify-center w100"
    >
      <div
        className="w100 d-flex f-dir-col"
        style={{
          maxWidth: "1920px",
        }}
      >
        <Switch>
          <Route exact path={`${path}`} component={EsgDetails} />
          <Route exact path={`${path}/cart`} component={Cart} />
          <Route
            exact
            path={`${path}/offset-projects`}
            component={OffsetProjects}
          />
          <Route
            exact
            path={`${path}/offset-projects/:id`}
            component={OffSetProjectDetail}
          />
          <Route
            exact
            path={`${path}/goals`}
            component={EmissionReductionPlaning}
          />
        </Switch>
      </div>
    </div>
  );
};

export default Esg;
