import React from "react";

import { useMediaQuery, useTheme, createTheme } from "@mui/material";
import { styled } from "@mui/material/styles";

import { Input } from "../../components/Cdl/Input";
import AutoComplete from "../../components/Cdl/AutoComplete";
import { Button } from "../../components/Cdl/Button";
import Select from "../../components/Cdl/Select";
import DatePicker from "../../components/Cdl/DatePicker";

import { COLORS } from "../../styles/themes/default/colors";

import SearchIcon from "../../assets/img/search_icon.svg";
import ProgressIcon from "../../assets/img/progress_icon.svg";
import ExcelIcon from "../../assets/img/excel_icon.svg";
import CustomerIcon from "../../assets/img/customer_icon.svg";
import LocationIcon from "../../assets/img/location_icon.svg";
import CreatorIcon from "../../assets/img/creator_icon.svg";
import CalendarIcon from "../../assets/img/calendar_icon.svg";

const theme1 = createTheme({
  breakpoints: {
    values: {
      xs: 0,
      mobile: 376,
      sm: 600,
      md: 900,
      lg: 1200,
      xl: 1536,
      sl: 1400,
    },
  },
});

const smDown = theme1.breakpoints.down("sm");
const mdDown = theme1.breakpoints.down("md");
const lgDown = theme1.breakpoints.down("lg");
const mobileDown = theme1.breakpoints.down("mobile");

const MainDiv = styled("div")({
  display: "flex",
  flexDirection: "row",
  width: "100%",
  marginBottom: "16px",
});

const DashboardFilters = ({
  user,
  setOpenExcelModal,
  excelDataLength,
  searchQuery,
  setSearchQuery,
  status,
  setStatus,
  customers,
  customer,
  locations,
  setCustomer,
  resetFilters,
  location,
  setLocation,
  creators,
  creator,
  setCreator,
  activeTab,
  date,
  setDate,
  handleApply,
}) => {
  const StatusOptions =
    activeTab === "active"
      ? ["Opened", "Approved", "Cleared", "Invoiced"]
      : ["Rejected", "Paid Back"];
  const smallLaptop = useMediaQuery(theme1.breakpoints.down("sl"));
  const SearchStyle = {
    marginRight: "8px",
    width: smallLaptop ? "125px" : "180px",
  };

  const ApplyButtonStyle = {
    height: smallLaptop ? "30px" : "42px",
    width: "80px",
    marginRight: "8px",
  };

  const RejectButtonStyle = {
    height: smallLaptop ? "30px" : "42px",
    width: "55px",
    backgroundColor: COLORS.white,
    marginRight: "8px",
  };

  const ExcelButtonStyle = {
    height: smallLaptop ? "30px" : "42px",
    width: smallLaptop ? "30px" : "42px",
    backgroundColor: COLORS.white,
    marginRight: "8px",
    padding: smallLaptop ? "10px" : "13px",
  };

  const ExcelIconStyle = {
    height: smallLaptop ? "12px" : "16px",
    width: smallLaptop ? "12px" : "16px",
  };

  const DateStyle = {
    marginRight: "16px",
    // width: smallLaptop ? "180px" : "125px",
  };

  return (
    <MainDiv>
      <Input
        placeholder="Search"
        iconStart={SearchIcon}
        css={SearchStyle}
        value={searchQuery}
        onChange={(e) => setSearchQuery(e.target.value)}
      />
      <Select
        placeholder="Status"
        icon={ProgressIcon}
        css={SearchStyle}
        cssDiv={{ width: "180px" }}
        options={StatusOptions}
        value={status}
        onChange={setStatus}
      />
      {(user?.user?.role === "CA" ||
        user?.user?.role === "CR" ||
        user?.user?.role === "CC") && (
        <AutoComplete
          placeholder="Customer"
          iconStart={CustomerIcon}
          css={SearchStyle}
          options={customers}
          search={customer}
          setSearch={setCustomer}
          cssDiv={{ width: smallLaptop ? "210px" : "300px" }}
        />
      )}
      <AutoComplete
        placeholder="Location"
        options={locations}
        iconStart={LocationIcon}
        css={SearchStyle}
        search={location}
        setSearch={setLocation}
        cssDiv={{ width: smallLaptop ? "210px" : "300px" }}
      />
      {(user?.user?.role === "CA" ||
        user?.user?.role === "CR" ||
        user?.user?.role === "CC") && (
        <AutoComplete
          placeholder="Creator"
          iconStart={CreatorIcon}
          css={SearchStyle}
          options={creators}
          search={creator}
          setSearch={setCreator}
        />
      )}
      <DatePicker
        placeholder="Due date"
        value={date}
        onChange={setDate}
        css={DateStyle}
      />
      <Button
        type="primary"
        text="Apply"
        css={ApplyButtonStyle}
        onClick={handleApply}
      />
      <Button
        type="secondary"
        text="Reset"
        css={RejectButtonStyle}
        onClick={resetFilters}
      />
      <Button
        css={ExcelButtonStyle}
        icon={ExcelIcon}
        iconCss={ExcelIconStyle}
        onClick={setOpenExcelModal}
        disabled={excelDataLength === 0 ? true : false}
      />
    </MainDiv>
  );
};

export default DashboardFilters;
