import React, { useState, useEffect } from "react";
import { Dialog, colors, createTheme, useMediaQuery } from "@mui/material";
import closeModalIcon from "../../assets/img/closeModalIcon.png";
import DemurrageModalPagination from "./DemurrageModalPagination";

import fetchWrapper from "../../helpers/fetch-wrapper";
import { APP_URL } from "../../utils/constants";

import { Button } from "../../components/Cdl/Button";
import moment from "moment";
import "./styles/demurrageDetailsModal.scss";
import { formatValue } from "../../utils/constants";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import containerStore from "../../store/containerStore";
import InfoIcon from "../../assets/img/info_icon.svg";
import ColorCalendar from "../Container/modals/ColorCalendar";
import { COLORS } from "../../styles/themes/default/colors";

export default function DemurrageDetailsModal({
  openDetailsModal,
  setOpenDetailsModal,
  modalContainer,
  isRisk,
}) {
  const [containerDisplay, setContainerDisplay] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalNumber, setTotalNumber] = useState(0);

  const [container, setContainer] = useState("");

  const theme1 = createTheme({
    breakpoints: {
      values: {
        sl: 1400,
      },
    },
  });

  const smallLaptop = useMediaQuery(theme1.breakpoints.down("sl"));

  const history = useHistory();
  const setAtRiskCOntainer = containerStore(
    (state) => state.setAtRiskContainer
  );
  const periods = [
    { label: "Free", color: "#75FA79" },
    { label: "1st", color: "#00FFFF" },
    { label: "2nd", color: "#D6FC51" },
    { label: "3rd", color: "#ED602A" },
    { label: "4rd", color: "#ED602A" },
    { label: "5rd", color: "#ED602A" },
  ];
  const bgColorToColor = {
    "#75FA79": "#1f1f1f",
    "#00FFFF": "#1f1f1f",
    "#D6FC51": "#1f1f1f",
    "#ED602A": "#f1f1f1",
  };

  const formatDayAndMonth = (date) => {
    return date ? moment(date, "YYYY-MM-DD").format("MM.DD") : "";
  };

  useEffect(() => {
    if (!modalContainer || modalContainer.length === 0) return;
    fetchWrapper
      .get(`${APP_URL}/container/get-container/${modalContainer[0].id}/`)
      .then((resp) => {
        setContainer(resp);
        setTotalNumber([resp]?.length);
        setContainerDisplay(
          [resp]?.slice((currentPage - 1) * 1, currentPage * 1)
        );
      });
  }, [currentPage, modalContainer]);

  const columnWidths = ["5%", "14%", "8%", "18%", "23%", "12%", "16%"];

  return (
    <>
      <Dialog
        sx={{
          "& .MuiDialog-container": {
            "& .MuiPaper-root": {
              maxWidth: "none",
              width: smallLaptop ? "800px" : "1150px",
            },
          },
        }}
        open={openDetailsModal}
        onClose={() => {
          setOpenDetailsModal(false);
          setCurrentPage(1);
        }}
      >
        <div className="mainModalDiv">
          <div className="headerDiv">
            <p className="headerText">D&D details</p>
            <img
              src={closeModalIcon}
              className="closeImg"
              onClick={() => {
                setOpenDetailsModal(false);
                setCurrentPage(1);
              }}
              alt="close icon"
              style={{
                cursor: "pointer",
              }}
            />
          </div>
          <div className="contentDiv">
            {containerDisplay?.map((item) => (
              <>
                <div className="contentHeaderDiv">
                  <p className="containersHeaderText">
                    Container ID:
                    <p className="containerId">{item.container_id}</p>
                  </p>
                  <DemurrageModalPagination
                    page={currentPage}
                    setPage={setCurrentPage}
                    totalNumber={totalNumber}
                    itemsPerPage={1}
                  />
                </div>

                <div className="freeDayDiv">
                  <div
                    style={{
                      display: "flex",
                      padding: "20px 0",
                      width: "70%",
                      background: "#fafafa",
                    }}
                  >
                    <div className="portFreeDayDiv">
                      <div className="portFreeRow">
                        <p className="labelGray">Demurrage first free day:</p>
                        <p className="labelBold">
                          {item?.demurrage_first_free_day ?? "_"}
                          {/* {moment(item.first_free_date).format("MM.DD.YYYY")} */}
                        </p>
                      </div>
                      <p className="labelTime">
                        08:00 AM
                        {/* {moment(item.first_free_date).format("HH:mm A")} */}
                      </p>
                      <div className="portFreeRow">
                        <p className="labelGray">Demurrage last free day:</p>
                        <p className="labelBold">
                          {item?.demurrage_last_free_day ?? "_"}
                          {/* {moment(item.last_free_date).format("MM.DD.YYYY")} */}
                        </p>
                      </div>
                      <p className="labelTime">
                        00:00 AM
                        {/* {moment(item.last_free_date).format("HH:mm A")} */}
                      </p>
                      <div className="portFreeRow">
                        <p className="labelGray">Demurrage free days:</p>
                        {/* <p className="labelBold">{item.detention_free_days}</p> */}
                        <p className="labelBold">
                          {item?.is_in_demurrage
                            ? item?.demurrage_tariffs[0]?.days
                            : "_"}
                        </p>
                      </div>
                    </div>
                    <div className="lineFreeDayDiv">
                      <div className="portFreeRow">
                        <p className="labelGray">Detention first free day:</p>
                        <p className="labelBold">
                          {/* {item?.first_free_day ?? "_"} */}

                          {/* {moment(item.detention_first_free_date).format(
                          "MM.DD.YYYY"
                        )} */}
                          {item?.detention_first_free_day ?? "_"}
                        </p>
                      </div>
                      <p className="labelTime" style={{ height: "21px" }}>
                        {/* {"aa"} */}
                        {/* {moment(item.detention_first_free_date).format("HH:mm A")} */}
                      </p>
                      <div className="portFreeRow">
                        <p className="labelGray">Detention last free day:</p>
                        <p className="labelBold">
                          {/* {item?.last_detention_free_day ?? "_"} */}

                          {/* {moment(new Date()).format("L")} */}
                          {/* {moment(item.detention_last_free_date).format(
                          "MM.DD.YYYY"
                        )} */}
                          {item?.detention_last_free_day ?? "_"}
                        </p>
                      </div>
                      {/* HH:mm */}
                      <p className="labelTime" style={{ height: "21px" }}>
                        {/* 00:00 AM */}
                        {/* {moment(item.detention_last_free_date).format("HH:mm A")} */}
                      </p>
                      <div className="portFreeRow">
                        <p className="labelGray">Detention free days:</p>
                        {/* <p className="labelBold">{item.detention_free_days}</p> */}

                        <p className="labelBold">
                          {item?.is_in_detention
                            ? item?.detention_tariffs[0]?.days
                            : "_"}
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="containerInfoDiv">
                    <div className="containerInfoRow">
                      <p className="labelGray">Steamship line:</p>
                      <p className="labelBold">{item?.steamship_line}</p>
                    </div>
                    <br />
                    <div className="containerInfoRow">
                      <p className="labelGray">Customer:</p>
                      <p className="labelBold">{item?.customer}</p>
                    </div>
                    <br />
                    <div className="containerInfoRow">
                      <p className="labelGray">Container type:</p>
                      <div className="containerType">
                        <p className="labelBold">{`${item?.type} ${item?.size}`}</p>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="containerdetails">
                  <div
                    className="containersFees"
                    style={{
                      borderBottom: 0,
                    }}
                  >
                    <div className="demurrageFees">
                      <p className="p-14-b">Demurrage fees</p>
                      <table className="feesTable">
                        <tr>
                          <td style={{ width: columnWidths[0] }} />
                          <td
                            className="tableDiv"
                            style={{ width: columnWidths[1] }}
                          >
                            Tariff
                          </td>
                          <td
                            className="tableDiv"
                            style={{ width: columnWidths[2] }}
                          >
                            Type
                          </td>

                          <td
                            className="tableDiv"
                            style={{
                              width: columnWidths[3],
                              textAlign: "center",
                            }}
                          >
                            Days
                          </td>
                          <td
                            className="tableDiv"
                            style={{ width: columnWidths[4] }}
                          >
                            Dates
                          </td>
                          <td
                            className="tableDiv"
                            style={{
                              textAlign: "right",
                              paddingRight: "16px",
                              width: columnWidths[5],
                            }}
                          >
                            Rate
                          </td>
                          <td
                            className="tableAmountDiv"
                            style={{ width: columnWidths[6] }}
                          >
                            Amount
                          </td>
                        </tr>
                        {item?.is_in_demurrage &&
                          item?.demurrage_tariffs &&
                          item?.demurrage_tariffs.map((fee, index) => {
                            return fee.name !== "At Risk" ? (
                              <tr>
                                <td style={{ width: columnWidths[0] }}>
                                  <div
                                    className="tariffDot"
                                    style={{
                                      backgroundColor: periods[index].color,
                                    }}
                                  />
                                </td>
                                <td
                                  className="tableColumn"
                                  style={{ width: columnWidths[1] }}
                                >
                                  {periods[index].label}
                                </td>
                                <td
                                  className="tableColumn"
                                  style={{ width: columnWidths[2] }}
                                >
                                  {item.are_demurrage_days_calendar_days
                                    ? "CD"
                                    : "WD"}
                                </td>
                                <td
                                  className="tableColumn"
                                  style={{
                                    width: columnWidths[3],
                                    textAlign: "center",
                                  }}
                                >
                                  {fee?.days}
                                </td>
                                <td
                                  className="tableColumn"
                                  style={{ width: columnWidths[4] }}
                                >{`${formatDayAndMonth(
                                  fee?.start_date
                                )}-${formatDayAndMonth(fee?.end_date)}`}</td>
                                <td
                                  className="tableColumnRate"
                                  style={{ width: columnWidths[5] }}
                                >
                                  {formatValue(fee?.rate)}
                                </td>
                                <td
                                  className="tableAmountText"
                                  style={{ width: columnWidths[6] }}
                                >
                                  {formatValue(fee?.amount)}
                                </td>
                              </tr>
                            ) : (
                              <tr>
                                <td style={{ width: columnWidths[0] }}>
                                  <div
                                    className="tariffDot"
                                    style={{
                                      backgroundColor: COLORS.dark_grey,
                                    }}
                                  />
                                </td>
                                <td
                                  className="grayLabel"
                                  style={{ width: columnWidths[1] }}
                                >
                                  At risk
                                </td>
                                <td
                                  className="tableColumn"
                                  style={{ width: columnWidths[2] }}
                                >
                                  {item.are_demurrage_days_calendar_days
                                    ? "CD"
                                    : "WD"}
                                </td>
                                <td
                                  className="grayLabel"
                                  style={{
                                    width: columnWidths[3],
                                    textAlign: "center",
                                  }}
                                >
                                  {fee.days}
                                </td>
                                <td
                                  className="grayLabel"
                                  style={{ width: columnWidths[4] }}
                                >
                                  {`${formatDayAndMonth(fee?.start_date)}`}
                                </td>
                                <td
                                  className="grayLabel"
                                  style={{
                                    textAlign: "right",
                                    paddingRight: "16px",
                                    width: columnWidths[5],
                                  }}
                                >
                                  {formatValue(fee?.rate)}
                                </td>
                                <td
                                  className="grayLabelTotal"
                                  style={{ width: columnWidths[6] }}
                                >
                                  {formatValue(fee?.amount)}
                                </td>
                              </tr>
                            );
                          })}
                      </table>
                    </div>
                    <div className="detentionFees">
                      <p className="p-14-b">Detention fees</p>
                      <table className="feesTable">
                        <tr>
                          <td style={{ width: columnWidths[0] }} />
                          <td
                            className="tableDiv"
                            style={{ width: columnWidths[1] }}
                          >
                            Tariff
                          </td>
                          <td
                            className="tableDiv"
                            style={{ width: columnWidths[2] }}
                          >
                            Type
                          </td>

                          <td
                            className="tableDiv"
                            style={{
                              width: columnWidths[3],
                              textAlign: "center",
                            }}
                          >
                            Days
                          </td>
                          <td
                            className="tableDiv"
                            style={{ width: columnWidths[4] }}
                          >
                            Dates
                          </td>
                          <td
                            className="tableDiv"
                            style={{
                              textAlign: "right",
                              paddingRight: "16px",
                              width: columnWidths[5],
                            }}
                          >
                            Rate
                          </td>
                          <td
                            className="tableAmountDiv"
                            style={{ width: columnWidths[6] }}
                          >
                            Amount
                          </td>
                        </tr>
                        {item?.is_in_detention &&
                          item?.detention_tariffs &&
                          item?.detention_tariffs.map((fee, index) => {
                            return fee.name !== "At Risk" ? (
                              <tr>
                                <td style={{ width: columnWidths[0] }}>
                                  <div
                                    className="tariffDot"
                                    style={{
                                      backgroundColor: periods[index].color,
                                    }}
                                  />
                                </td>
                                <td
                                  className="tableColumn"
                                  style={{ width: columnWidths[1] }}
                                >
                                  {periods[index].label}
                                </td>
                                <td
                                  className="tableColumn"
                                  style={{ width: columnWidths[2] }}
                                >
                                  {item.are_detention_days_calendar_days
                                    ? "CD"
                                    : "WD"}
                                </td>

                                <td
                                  className="tableColumn"
                                  style={{
                                    width: columnWidths[3],
                                    textAlign: "center",
                                  }}
                                >
                                  {fee?.days}
                                </td>
                                <td
                                  className="tableColumn"
                                  style={{ width: columnWidths[4] }}
                                >
                                  {`${formatDayAndMonth(
                                    fee?.start_date
                                  )}-${formatDayAndMonth(fee?.end_date)}`}
                                </td>
                                <td
                                  className="tableColumnRate"
                                  style={{ width: columnWidths[5] }}
                                >
                                  {formatValue(fee?.rate)}
                                </td>
                                <td
                                  className="tableAmountText"
                                  style={{ width: columnWidths[6] }}
                                >
                                  {formatValue(fee?.amount)}
                                </td>
                              </tr>
                            ) : (
                              <tr>
                                <td style={{ width: columnWidths[0] }}>
                                  <div
                                    className="tariffDot"
                                    style={{
                                      backgroundColor: COLORS.dark_grey,
                                    }}
                                  />
                                </td>
                                <td
                                  className="grayLabel"
                                  style={{ width: columnWidths[1] }}
                                >
                                  At risk
                                </td>
                                <td
                                  className="tableColumn"
                                  style={{ width: columnWidths[2] }}
                                >
                                  {item.are_detention_days_calendar_days
                                    ? "CD"
                                    : "WD"}
                                </td>{" "}
                                <td
                                  className="grayLabel"
                                  style={{
                                    width: columnWidths[3],
                                    textAlign: "center",
                                  }}
                                >
                                  {fee.days}
                                </td>
                                <td
                                  className="grayLabel"
                                  style={{ width: columnWidths[4] }}
                                >
                                  {`${formatDayAndMonth(fee?.start_date)}`}
                                </td>
                                <td
                                  className="grayLabel"
                                  style={{
                                    textAlign: "right",
                                    paddingRight: "16px",
                                    width: columnWidths[5],
                                  }}
                                >
                                  {formatValue(fee?.rate)}
                                </td>
                                <td
                                  className="grayLabelTotal "
                                  style={{ width: columnWidths[6] }}
                                >
                                  {formatValue(fee?.amount)}
                                </td>
                              </tr>
                            );
                          })}
                      </table>
                    </div>
                  </div>
                  <div className="containersFees">
                    <div className="demurrageFees">
                      <table
                        className="feesTable"
                        style={{
                          borderTop: `1px solid rgba(140, 140, 140, 0.4)`,
                        }}
                      >
                        <tr>
                          <td>Demurrage total:</td>
                          <td></td>
                          <td></td>
                          <td></td>
                          <td style={{ float: "right", fontWeight: "600" }}>
                            <div className="total_value_div">
                              {formatValue(item?.current_demurrage_amount)}
                              {item?.demurrage_fee_by_pricing &&
                                item?.demurrage_fee_by_pricing.length > 0 && (
                                  <img
                                    src={InfoIcon}
                                    alt="info_icon"
                                    className="info_icon_modal"
                                  />
                                )}
                              <div className="calendar_div">
                                {item?.demurrage_tariffs &&
                                  item?.demurrage_tariffs.length > 0 && (
                                    <ColorCalendar
                                      tariffsDates={
                                        item?.demurrage_tariffs
                                          ? item.demurrage_tariffs.map(
                                              (item, index) => {
                                                return {
                                                  startDate: item.start_date,
                                                  endDate: item.end_date,
                                                  bgColor: periods[index].color,
                                                  color:
                                                    bgColorToColor[
                                                      periods[index].color
                                                    ],
                                                };
                                              }
                                            )
                                          : []
                                      }
                                      areWeekDays={
                                        !item.are_demurrage_days_calendar_days
                                      }
                                      atRisk={{
                                        date:
                                          item?.demurrage_tariffs &&
                                          item?.demurrage_tariffs.at(-1)
                                            .name === "At Risk"
                                            ? new Date(
                                                item?.demurrage_tariffs.at(
                                                  -1
                                                ).start_date
                                              )
                                            : null,
                                        bgColor: COLORS.dark_grey,
                                        color: COLORS.off_white,
                                      }}
                                    />
                                  )}
                              </div>
                            </div>
                          </td>
                        </tr>
                        {item?.is_in_demurrage &&
                          item?.demurrage_tariffs.some(
                            (fee) => fee.name === "At Risk"
                          ) && (
                            <tr>
                              <td
                                className="grayLabelTotal"
                                style={{ float: "left" }}
                              >
                                At risk total:
                              </td>
                              <td></td>
                              <td></td>
                              <td></td>
                              <td
                                className="grayLabelTotal"
                                style={{ float: "right" }}
                              >
                                {formatValue(item?.potential_demurrage_amount)}
                              </td>
                            </tr>
                          )}
                      </table>
                    </div>
                    <div className="detentionFees">
                      <table
                        className="feesTable"
                        style={{
                          borderTop: `1px solid rgba(140, 140, 140, 0.4)`,
                        }}
                      >
                        <tr>
                          <td>Detention total:</td>
                          <td></td>
                          <td></td>
                          <td></td>
                          <td style={{ float: "right", fontWeight: "600" }}>
                            <div className="total_value_div">
                              {formatValue(item?.current_detention_amount)}
                              {item?.detention_tariffs &&
                                item?.detention_tariffs.length > 0 && (
                                  <img
                                    src={InfoIcon}
                                    alt="info_icon"
                                    className="info_icon_modal"
                                  />
                                )}
                              <div className="calendar_div">
                                {item?.detention_tariffs &&
                                  item?.detention_tariffs.length > 0 && (
                                    <ColorCalendar
                                      tariffsDates={
                                        item?.detention_tariffs
                                          ? item.detention_tariffs.map(
                                              (item, index) => {
                                                return {
                                                  startDate: item.start_date,
                                                  endDate: item.end_date,
                                                  bgColor: periods[index].color,
                                                  color:
                                                    bgColorToColor[
                                                      periods[index].color
                                                    ],
                                                };
                                              }
                                            )
                                          : []
                                      }
                                      areWeekDays={
                                        !item.are_detention_days_calendar_days
                                      }
                                      atRisk={{
                                        date:
                                          item?.detention_tariffs &&
                                          item?.detention_tariffs.at(-1)
                                            .name === "At Risk"
                                            ? new Date(
                                                item?.detention_tariffs.at(
                                                  -1
                                                ).start_date
                                              )
                                            : null,
                                        bgColor: COLORS.dark_grey,
                                        color: COLORS.off_white,
                                      }}
                                    />
                                  )}
                              </div>
                            </div>
                          </td>
                        </tr>
                        {item?.is_in_detention &&
                          item?.detention_tariffs.some(
                            (fee) => fee.name === "At Risk"
                          ) && (
                            <tr>
                              <td
                                className="grayLabelTotal"
                                style={{ float: "left" }}
                              >
                                At risk total:
                              </td>
                              <td></td>
                              <td></td>
                              <td></td>
                              <td
                                className="grayLabelTotal"
                                style={{ float: "right" }}
                              >
                                {formatValue(item?.potential_detention_amount)}
                              </td>
                            </tr>
                          )}
                      </table>
                    </div>
                  </div>
                  <div className="containerTotal">
                    <p className="p-16-b">Container total:</p>
                    <p className="p-16-b">
                      {formatValue(
                        item?.demurrage_amount ??
                          0 + item?.detention_amount ??
                          0
                      )}
                    </p>
                  </div>
                </div>
              </>
            ))}
          </div>
          <div className="footerDiv">
            <div className="flexDiv">
              <p className="p-16-b">D&D Total: </p>
              <p className="totalDemurrageAmonut">
                {container &&
                (container?.current_demurrage_amount ||
                  container?.current_detention_amount)
                  ? formatValue(
                      container.current_demurrage_amount ??
                        0 + container?.current_detention_amount ??
                        0
                    )
                  : "$0"}
              </p>
            </div>
            <div className="flexDiv">
              <Button
                text="Close"
                type="link"
                css={{ width: "75px" }}
                onClick={() => {
                  setOpenDetailsModal(false);
                  setCurrentPage(1);
                }}
              />
              <Button
                text={isRisk ? "Manage" : "Pay"}
                type="primary"
                css={{ width: "100px", marginLeft: "0.6rem" }}
                onClick={() => {
                  if (isRisk) {
                    setAtRiskCOntainer(containerDisplay.at(0));
                    history.push("/demurrage/request");
                  }
                }}
              />
            </div>
          </div>
        </div>
      </Dialog>
    </>
  );
}
