import React, { useState } from "react";

import apiFactory from "../Container/service/apiFactory";
import { CONTAINER_URL } from "../../utils/constants";
import { useQuery } from "react-query";

import EsgFilters from "./components/EsgFilters";

import EsgFirstColumn from "./components/EsgFirstColumn";
import EsgSecondColumn from "./components/EsgSecondColumn";
import EsgThirdColumn from "./components/EsgThirdColumn";

import "./styles/esgDetails.scss";

import useStore from "../../store/store";

import Skeleton from "react-loading-skeleton";

export default function EsgDetails() {
  const [searchQuery, setSearchQuery] = useState("");

  const currentUser = useStore((state) => state.currentUser);

  const { isLoading, data } = useQuery({
    queryKey: ["esgData", searchQuery],
    queryFn: () => {
      return apiFactory
        .get(`${CONTAINER_URL}esg/stats${searchQuery}`)
        .then((res) => res.data);
    },
    staleTime: 0,
    cacheTime: 0,
    retry: false,
  });

  return (
    <div
      className="h100 d-flex f-dir-col"
      // style={{ height: "100%", display: "flex", flexDirection: "column" }}
    >
      <EsgFilters setSearchQuery={setSearchQuery} user={currentUser} />
      {isLoading ? (
        <div className="w100 d-flex mt16">
          <div className="w33">
            <Skeleton width="100%" height={400} count={2} />
          </div>
          <div className="w33">
            <Skeleton width="100%" height={400} count={2} />
          </div>
          <div className="w33">
            <Skeleton width="100%" height={400} count={2} />
          </div>
        </div>
      ) : (
        <>
          <div className="d-flex gap16 pb16 pt16 f-grow1">
            <div className="w25 h100">
              <EsgFirstColumn
                data={data?.esg_per_state}
                esg_per_shipment={data?.esg_per_shipment}
              />
            </div>
            <div className="w25 h100">
              <EsgSecondColumn data={data} />
            </div>
            <div className="w50 h100">
              <EsgThirdColumn data={data} />
            </div>
          </div>
        </>
      )}
    </div>
  );
}
