import React, { useEffect, useState } from "react";
import "./styles/emission_reduction.scss";

import TimeLineGraph from "./components/EmissionReduction/TimelineGraph";
import ReductionSandbox from "./components/EmissionReduction/ReductionSandbox";
import AlternativeFuelBox from "./components/EmissionReduction/AlternativeFuelBox";
import OffsetProjectBox from "./components/EmissionReduction/OffsetProjectsBox";
import OptimizationPlan from "./components/EmissionReduction/OptimizationPlan";
import useStore from "../../store/store";
import { ReactComponent as BadgeOne } from "./assets/OffsetBadges/SDG1.svg";
import { ReactComponent as BadgeTwo } from "./assets/OffsetBadges/SDG2.svg";
import { ReactComponent as BadgeThree } from "./assets/OffsetBadges/SDG3.svg";
import { ReactComponent as BadgeFour } from "./assets/OffsetBadges/SDG4.svg";
import { ReactComponent as BadgeFive } from "./assets/OffsetBadges/SDG5.svg";
import { ReactComponent as BadgeSix } from "./assets/OffsetBadges/SDG6.svg";
import { ReactComponent as BadgeSeven } from "./assets/OffsetBadges/SDG7.svg";
import { ReactComponent as BadgeEight } from "./assets/OffsetBadges/SDG8.svg";
import { ReactComponent as BadgeNine } from "./assets/OffsetBadges/SDG9.svg";
import { ReactComponent as BadgeTen } from "./assets/OffsetBadges/SDG10.svg";
import { ReactComponent as BadgeEleven } from "./assets/OffsetBadges/SDG11.svg";
import { ReactComponent as BadgeTwelve } from "./assets/OffsetBadges/SDG12.svg";
import { ReactComponent as BadgeThirteen } from "./assets/OffsetBadges/SDG13.svg";
import { ReactComponent as BadgeFourtheen } from "./assets/OffsetBadges/SDG14.svg";
import { ReactComponent as BadgeFifteen } from "./assets/OffsetBadges/SDG15.svg";
import { ReactComponent as BadgeSixteen } from "./assets/OffsetBadges/SDG16.svg";
import { ReactComponent as BadgeSeventeen } from "./assets/OffsetBadges/SDG17.svg";
import {
  badgesToActiveColor,
  contributionPeriodToCoefficient,
  emissionStartValue,
} from "./utils/constants";
import { calculateGraphLines } from "./utils/functions";

const EmissionReductionPlaning = () => {
  const [graphData, setGraphData] = useState({});
  const [offsetHeaderData, setOffsetHeaderData] = useState({
    autoContribution: false,
    contributionPeriod: "Yearly",
    totalCO2offset: 0,
  });

  const [reductionSandboxData, setReductionSandboxData] = useState({
    firstEmission: 0,
    secondEmission: 0,
    annualGrowth: 0,
    targetReduction: 0,
    annualTop: 0,
    targetYear: 2050,
  });
  const [offsetBoxData, setOffsetBoxData] = useState([
    {
      name: "No poverty",
      value: 100.0,
      isActive: true,
      icon: <BadgeOne />,
      color: badgesToActiveColor[0],
    },
    {
      name: "Zero hunger",
      value: 0.0,
      isActive: false,
      icon: <BadgeTwo />,
      color: badgesToActiveColor[1],
    },
    {
      name: "Good health and well-being",
      value: 0.0,
      isActive: false,
      icon: <BadgeThree />,
      color: badgesToActiveColor[2],
    },
    {
      name: "Quality education",
      value: 0.0,
      isActive: false,
      icon: <BadgeFour />,
      color: badgesToActiveColor[3],
    },
    {
      name: "Gender equality",
      value: 0.0,
      isActive: false,
      icon: <BadgeFive />,
      color: badgesToActiveColor[4],
    },
    {
      name: "Clean water and sanitation",
      value: 0.0,
      isActive: false,
      icon: <BadgeSix />,
      color: badgesToActiveColor[5],
    },
    {
      name: "Affordable and clean energy",
      value: 0.0,
      isActive: false,
      icon: <BadgeSeven />,
      color: badgesToActiveColor[6],
    },
    {
      name: "Decent wirk and economic growth",
      value: 0.0,
      isActive: false,
      icon: <BadgeEight />,
      color: badgesToActiveColor[7],
    },
    {
      name: "Industry, innovation and infrastructure",
      value: 0.0,
      isActive: false,
      icon: <BadgeNine />,
      color: badgesToActiveColor[8],
    },
    {
      name: "Reduced inequalities",
      value: 0.0,
      isActive: false,
      icon: <BadgeTen />,
      color: badgesToActiveColor[9],
    },
    {
      name: "Sustainable cities and communities",
      value: 0.0,
      isActive: false,
      icon: <BadgeEleven />,
      color: badgesToActiveColor[10],
    },
    {
      name: "Responsible consumption and production",
      value: 0.0,
      isActive: false,
      icon: <BadgeTwelve />,
      color: badgesToActiveColor[11],
    },
    {
      name: "Climate action",
      value: 0.0,
      isActive: false,
      icon: <BadgeThirteen />,
      color: badgesToActiveColor[12],
    },
    {
      name: "Life below water",
      value: 0.0,
      isActive: false,
      icon: <BadgeFourtheen />,
      color: badgesToActiveColor[13],
    },
    {
      name: "Life on land",
      value: 0.0,
      isActive: false,
      icon: <BadgeFifteen />,
      color: badgesToActiveColor[14],
    },
    {
      name: "Peace, justice and strong institutions",
      value: 0.0,
      isActive: false,
      icon: <BadgeSixteen />,
      color: badgesToActiveColor[15],
    },
    {
      name: "Partnership for the goals",
      value: 0.0,
      isActive: false,
      icon: <BadgeSeventeen />,
      color: badgesToActiveColor[16],
    },
  ]);
  const [alternativeFuelData, setAlternativeFuelData] = useState([
    {
      name: "Petrol/Diesel",
      isActive: true,
      value: 100,
      showOnGraph: false,
      emission: emissionStartValue,
    },
    {
      name: "Biodiesel",
      isActive: false,
      value: 0,
      showOnGraph: true,
      emission: 0,
    },
    {
      name: "Natural Gas",
      isActive: false,
      value: 0,
      showOnGraph: true,
      emission: 0,
    },
    {
      name: "Hydrogen",
      isActive: false,
      value: 0,
      showOnGraph: true,
      emission: 0,
    },
    {
      name: "Electric",
      isActive: false,
      value: 0,
      showOnGraph: true,
      emission: 0,
    },
    {
      name: "Ethanol",
      isActive: false,
      value: 0,
      showOnGraph: true,
      emission: 0,
    },
    {
      name: "Propane",
      isActive: false,
      value: 0,
      showOnGraph: true,
      emission: 0,
    },
  ]);
  const setNavbarLabel = useStore((state) => state.setNavbarLabel);
  useEffect(() => {
    setNavbarLabel({
      label: "Emission Reduction Planning",
      back_link: "/esg",
    });
  }, [setNavbarLabel]);

  const toggleOffset = (label, value) => {
    if (
      offsetHeaderData.autoContribution &&
      reductionSandboxData.secondEmission > 0
    ) {
      if (label === "sandbox" && value > 0) {
        setOffsetHeaderData({
          ...offsetHeaderData,
          totalCO2offset:
            (((value * 1.0) / 100) * reductionSandboxData.secondEmission) /
            contributionPeriodToCoefficient[offsetHeaderData.contributionPeriod],
        });
      } else if (label === "offset" && value > 0) {
        setReductionSandboxData({
          ...reductionSandboxData,
          annualTop: parseInt(
            ((value * 100) / reductionSandboxData.secondEmission) *
            contributionPeriodToCoefficient[
            offsetHeaderData.contributionPeriod
            ]
          ),
        });
      }
    }
  };
  useEffect(() => {
    // firstEmission: 0,
    // secondEmission: 0,
    // annualGrowth: 0,
    // targetReduction: 0,
    // annualTop: 0,
    // targetYear: 2050,

    if (
      reductionSandboxData.firstEmission &&
      reductionSandboxData.secondEmission
    ) {
      setGraphData(
        calculateGraphLines(
          reductionSandboxData.firstEmission,
          reductionSandboxData.secondEmission,
          reductionSandboxData.annualGrowth > 0
            ? (reductionSandboxData.annualGrowth * 1.0) / 100
            : 0,
          reductionSandboxData.targetReduction > 0
            ? (reductionSandboxData.targetReduction * 1.0) / 100
            : 0,
          reductionSandboxData.annualTop > 0
            ? (reductionSandboxData.annualTop * 1.0) / 100
            : 0,
          reductionSandboxData.targetYear
        )
      );
    }
  }, [reductionSandboxData]);

  return (
    <div className="w100 h100 d-flex f-dir-col">
      <TimeLineGraph data={graphData} emission2015={reductionSandboxData.firstEmission}/>
      <div className="d-flex w100 f-wrap gap16">
        <ReductionSandbox
          data={reductionSandboxData}
          setData={setReductionSandboxData}
          toggleOffset={toggleOffset}
        />
        <AlternativeFuelBox
          data={alternativeFuelData}
          setData={setAlternativeFuelData}
        />
        <OffsetProjectBox
          data={offsetBoxData}
          setData={setOffsetBoxData}
          headerData={offsetHeaderData}
          setHeaderData={setOffsetHeaderData}
          toggleOffset={toggleOffset}
        />
        <OptimizationPlan />
      </div>
    </div>
  );
};

export default EmissionReductionPlaning;
