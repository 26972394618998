import { TextField } from "@mui/material";
import React from "react";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import { COLORS } from "../../../styles/themes/default/colors";

const EsgTextField = (props) => {
  const handleBlur = () => {
    if (props.followFocus) {
      props.setFocus(false);
    }
    if (
      props.minValue !== undefined
        ? props.value <= props.minValue
        : props.value < 1
    ) {
      props.setValue(props.minValue !== undefined ? props.minValue : 1);
    }
  };

  const handleOnChange = (event) => {
    const { value } = event.target;
    let numericValue = value.replace(/[^0-9,]/g, "");
    numericValue = numericValue.replace(/,/g, "");
    if (numericValue === "") {
      numericValue = 0;
    }
    props.setValue(numericValue);
  };

  const handleArrowClick = (increment) => {
    const newValue = props.decimal
      ? parseFloat(props.value) + increment
      : parseInt(props.value, 10) + increment;

    if (props.is_function) {
      props.setValue(newValue);
    } else {
      props.setValue((prev) => prev + increment);
    }
  };
  return (
    <TextField
      value={
        props.year
          ? parseInt(props.value)
          : props.decimal
            ? parseFloat(props.value).toFixed(2)
            : Intl.NumberFormat("en-US").format(parseInt(props.value, 10))
      }
      variant="outlined"
      size="small"
      onFocus={() => {
        if (props.followFocus) {
          props.setFocus(true);
        }
      }}
      sx={{
        ...props.styles,
        borderRadius: "8px",
        backgroundColor: "#313131",
        // height: "42px",
        border:
          props.error && props.value ? `1px solid ${COLORS.primary}` : "none",

        ".css-1qujxt1-MuiInputBase-root-MuiOutlinedInput-root": {
          border: "none !important",
        },

        Input: {
          color: props.textColor ? props.textColor : "#8c8c8c",
          border: "none",
        },
      }}
      onChange={handleOnChange}
      onBlur={handleBlur}
      InputProps={{
        endAdornment: (
          <div style={{ paddingLeft: "8px", height: "42px" }}>
            <button
              style={{
                padding: 0,
                backgroundColor: "transparent",
                border: "none",
                outline: "none",
                height: "21px",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
              onClick={() => handleArrowClick(props.decimal ? 0.1 : 1)}
            >
              <KeyboardArrowUpIcon
                sx={{ color: COLORS.dark_grey, height: "20px" }}
              />
            </button>
            <button
              style={{
                padding: 0,
                backgroundColor: "transparent",
                border: "none",
                outline: "none",
                height: "21px",
              }}
              onClick={() => handleArrowClick(props.decimal ? -0.1 : -1)}
              disabled={
                props.minValue !== undefined
                  ? props.value <= props.minValue
                  : props.value <= 1
              }
            >
              <KeyboardArrowUpIcon
                sx={{
                  color: COLORS.dark_grey,
                  transform: "rotate(180deg)",
                  height: "20px",
                }}
              />
            </button>
          </div>
        ),
      }}
    />
  );
};
export default EsgTextField;
