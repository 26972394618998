import React from "react";
import { Route, Switch } from "react-router-dom";

import ContainersDetails from "./ContainersDetails";

const Container = ({ match }) => {
  const { path } = match;

  return (
    <div
      style={{
        width: "100%",
        justifyContent: "center",
        display: "flex",
        // padding: "0 1.5rem 1.5rem",
        padding: "0px 16px 16px 16px",
      }}
      // className="p-4"
    >
      <div style={{ width: "100%", maxWidth: "1920px" }}>
        <Switch>
          <Route exact path={path} component={ContainersDetails} />
        </Switch>
      </div>
    </div>
  );
};

export default Container;
