import React from "react";

import Chart from "react-apexcharts";

import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { kFormatNumber } from "../../utils/functions";

const Top5SustainableGoals = (props) => {
  const history = useHistory();
  // const data = [
  //   {
  //     image_url: firstBadge,
  //     title: "Climate action",
  //     percent: "100%",
  //     value: 700,
  //   },
  //   {
  //     image_url: secondBadge,
  //     title: "Life below water",
  //     percent: "15%",
  //     value: 700,
  //   },
  //   {
  //     image_url: thirdBadge,
  //     title: "Life on land",
  //     percent: "15%",
  //     value: 700,
  //   },
  //   {
  //     image_url: fourthBadge,
  //     title: "Peace, justice and strong institutions",
  //     percent: "15%",
  //     value: 700,
  //   },
  //   {
  //     image_url: fifthBadge,
  //     title: "Partnerships for the goals",
  //     percent: "15%",
  //     value: 700,
  //   },
  // ];
  let optionsRenewable = {
    plotOptions: {
      radialBar: {
        track: {
          show: false,
        },
        hollow: {
          size: "50%",
        },
        startAngle: 180,
        endAngle: -180,
        dataLabels: {
          name: {
            fontSize: "22px",
          },
          value: {
            fontSize: "16px",
          },
        },
        total: {
          show: true,
          formatter: function () {
            return "3k";
          },
        },
      },
    },
    grid: {
      padding: {
        left: -5,
        right: -70,
        top: -15,
        bottom: -90,
      },
    },

    colors: ["#48773C", "#007DBB", "#40AE49", "#00FF65", "#00558A", "#1A3668"],
    stroke: {
      width: 0.2,
      lineCap: "round", // Make radial lines rounded
    },
  };
  let seriesRenewable =
    props.data?.projects && props.data?.total_amount > 0
      ? props.data.projects.map((item) => {
          return (item.total_amount * 100) / props.data.total_amount;
        })
      : [];

  return (
    <div className="renewableProjectsDiv">
      <div className="renewableProjectsHeaderDiv">
        <p className="c-white fw600 m0">Top 5 Sustainable Development Goals</p>
        <p
          className="c-blue m0 fw600"
          onClick={() => {
            history.push("/esg/offset-projects");
          }}
          style={{ cursor: "pointer", fontSize: "14px" }}
        >
          Offset now
        </p>
      </div>
      <div className="renewableFlexDiv">
        <div style={{ position: "relative" }}>
          <Chart
            options={optionsRenewable}
            series={seriesRenewable}
            type="radialBar"
            height="100%"
            width={130}
            style={{ position: "relative" }}
            // width={200}
          />

          <div
            style={{
              position: "absolute",
              bottom: "43%",
              right: "0px",
              paddingRight: "8px",
            }}
          >
            <p
              className="c-white fw600 m0"
              style={{ fontSize: "24px", textAlign: "center" }}
            >
              {kFormatNumber(props.data.total_amount * 1000)}
            </p>
            <p
              className="c-dark-grey m0 fw600"
              style={{ fontSize: "14px", textAlign: "center" }}
            >
              tCO<sub>2</sub>
            </p>
          </div>
        </div>

        <div
          style={{
            flexGrow: 1,
            height: "100%",
            display: "flex",
            flexDirection: "column",
            gap: "8px",
          }}
        >
          {props.data?.projects &&
            props.data?.projects.map((item, index) => {
              return (
                <div
                  key={index}
                  style={{
                    display: "flex",
                    width: "100%",
                    justifyContent: "space-between",
                    alignItems: "center",
                  }}
                >
                  <div className="top5esg_image_and_name">
                    <img
                      src={item.esg_url}
                      alt="badge"
                      style={{ width: "30px", borderRadius: "4px" }}
                    />
                    <p className="top5esgname">
                      {item.esg_name?.length > 27
                        ? item.esg_name.slice(0, 24) + "..."
                        : item.esg_name}
                    </p>
                    {item.esg_name?.length > 27 && (
                      <div className="top5esgname_hover">{item.esg_name}</div>
                    )}
                  </div>
                  <div style={{ display: "flex", alignItems: "center" }}>
                    <p className="c-white m0" style={{ fontSize: "14px" }}>
                      {props.data.total_amount > 0
                        ? parseFloat(
                            (item.total_amount * 100) / props.data.total_amount
                          ).toFixed(2) + "%"
                        : 0}
                    </p>
                    <p
                      className="c-white fw600 m0"
                      style={{ fontSize: "14px", marginLeft: "16px" }}
                    >
                      {parseFloat(item.total_amount).toFixed(2)}
                    </p>
                    <p
                      className="c-dark-grey m0"
                      style={{ fontSize: "14px", marginLeft: "4px" }}
                    >
                      tCO<sub>2</sub>e
                    </p>
                  </div>
                </div>
              );
            })}
        </div>
      </div>
    </div>
  );
};
export default Top5SustainableGoals;
