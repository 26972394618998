import React from "react";

import { IconButton, useMediaQuery, createTheme } from "@mui/material";
import { styled } from "@mui/material/styles";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";

import { COLORS } from "../styles/themes/default/colors";

const MainDiv = styled("div")({
  display: "flex",
  justifyContent: "space-between",
  width: "100%",
});

const DemurrageCounter = styled("p")({
  fontSize: "1rem",
  lineHeight: "19px",
  color: COLORS.dark_grey,
  marginBottom: "0",
  marginTop: "10px",
  "@media only screen and (max-width: 1400px) and (min-width: 1280px)": {
    fontSize: "0.6875rem",
  },
});

const PaginationDiv = styled("div")({
  display: "flex",
  alignItems: "center",
});

const PageNumber = styled("p")({
  margin: "0 5px",
  "@media only screen and (max-width: 1400px) and (min-width: 1280px)": {
    fontSize: "0.6875rem",
  },
});

const Pagination = ({ totalNumber, page, setPage, itemsPerPage }) => {
  let showingNumber =
    totalNumber === 0
      ? 0
      : page * itemsPerPage > totalNumber
      ? totalNumber - itemsPerPage * (page - 1)
      : itemsPerPage;
  return (
    <MainDiv>
      <DemurrageCounter>{`Showing ${showingNumber} out of ${totalNumber}`}</DemurrageCounter>
      <PaginationDiv>
        <IconButton
          className="paginationIconArrowLeft"
          onClick={() => {
            setPage(page - 1);
          }}
          disabled={page === 1}
        >
          <ChevronLeftIcon />
        </IconButton>
        <PageNumber>{page}</PageNumber>
        <IconButton
          className="paginationIconArrowRight"
          onClick={() => {
            setPage(page + 1);
          }}
          disabled={page === Math.ceil(totalNumber / itemsPerPage)}
        >
          <ChevronRightIcon />
        </IconButton>
      </PaginationDiv>
    </MainDiv>
  );
};

export default Pagination;
