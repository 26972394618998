import React from "react";
import {
  Content,
  ContentDiv,
  ContentHeader,
  ContentTitle,
  ContractSize,
  ContractValue,
  Icon,
  InformationLabel,
  InformationValue,
  LabelDiv,
  UploadButton,
} from "../style/StyledComponents";
import { COLORS } from "../../../../styles/themes/default/colors";

import UploadIconRed from "../components/assets/upload_red.svg";
import approvalIcon from "../components/assets/approval_icon.svg";
import mapDotIcon from "../components/assets/map_dot_icon.svg";
import percentageIcon from "../components/assets/percentage_icon.svg";
import grayContractIcon from "../components/assets/gray_contract_icon.svg";
import downloadContractIcon from "../components/assets/download_contract_icon.svg";
import fetchWrapper from "../../../../helpers/fetch-wrapper";
import { APP_URL } from "../../../../utils/constants";
import { saveAs } from "file-saver";
import { createTheme, useMediaQuery } from "@mui/material";

const ContarctInfromation = (props) => {
  const theme1 = createTheme({
    breakpoints: {
      values: {
        sl: 1400,
      },
    },
  });
  const smallLaptop = useMediaQuery(theme1.breakpoints.down("sl"));

  const downloadDoc = (item) => {
    fetchWrapper
      .get(`${APP_URL}/shipper/contracts/${item.id}/`)
      .then((resp) => {
        saveAs(`data:image;base64,${resp.document_64}`, item.name);
      });
  };
  return (
    <ContentDiv style={{ marginBottom: "16px" }}>
      <ContentHeader>
        <ContentTitle>Contract information</ContentTitle>
      </ContentHeader>
      <Content style={{ flexDirection: "row" }}>
        <div>
          <LabelDiv>
            <Icon src={approvalIcon} alt="Approval icon" />
            <InformationLabel>Approval authority:</InformationLabel>
          </LabelDiv>
          <InformationValue style={{ marginBottom: "0px" }}>
            {props.currentCustomer?.authority_approval === "CDL"
              ? "CDL1000"
              : "Customer"}
          </InformationValue>
        </div>
        <div>
          <LabelDiv>
            <Icon src={mapDotIcon} alt="Map Dot Icon" />
            <InformationLabel>Repayment days:</InformationLabel>
          </LabelDiv>
          <InformationValue style={{ marginBottom: "0px" }}>
            {props.currentCustomer?.repayment_days}
          </InformationValue>
        </div>
        <div>
          <LabelDiv>
            <Icon src={percentageIcon} alt="Percentage icon" />
            <InformationLabel>Rate:</InformationLabel>
          </LabelDiv>
          <InformationValue style={{ marginBottom: "0px" }}>
            {`${props.currentCustomer?.interest_percentage?.slice(0, 3)} %`}
          </InformationValue>
        </div>
        <div>
          <LabelDiv>
            <Icon src={grayContractIcon} alt="Gray contract icon" />
            <InformationLabel>Contract:</InformationLabel>
          </LabelDiv>
          {props.documents?.contract?.name ? (
            <ContractValue
              onClick={() => downloadDoc(props.documents.contract)}
              style={{
                display: "flex",
                alignItems: "center",
                margin: "0",
              }}
            >
              <Icon
                src={downloadContractIcon}
                alt="Download contract icon"
                style={{
                  display:
                    props.documents?.contract?.name !== undefined ? "" : "none",
                }}
              />
              {props.documents?.contract?.name}{" "}
              <ContractSize
                style={{
                  margin: 0,
                  marginLeft: "8px",
                  display:
                    props.documents?.contract?.name !== undefined
                      ? "block"
                      : "none",
                }}
              >
                {props.documents?.contract?.size > 1024 * 1024
                  ? `${parseFloat(
                      props.documents?.contract?.size / 1024 / 1024
                    ).toFixed(2)}MB`
                  : `${parseFloat(
                      props.documents?.contract?.size / 1024
                    ).toFixed(2)}KB`}
              </ContractSize>
            </ContractValue>
          ) : (
            <p
              style={{
                fontSize: smallLaptop ? "10px" : "14px",
                lineHeight: "17px",
                fontWeight: "600",
                color: COLORS.activity,
                marginLeft: smallLaptop ? "17px" : "24px",
                width: "100%",
                marginBottom: "0px",
              }}
            >
              Unknown
            </p>
          )}
        </div>
        <UploadButton
          onClick={() => {
            document.getElementById("selectFile").click();
          }}
        >
          <img
            src={UploadIconRed}
            alt="upload"
            style={{ marginRight: "8px" }}
          />
          Upload contract
        </UploadButton>
      </Content>
      <input
        id="selectFile"
        type="file"
        style={{ visibility: "hidden", width: 0, height: 0 }}
        onChange={(event) => {
          props.handleFileAdd(event);
        }}
      />
    </ContentDiv>
  );
};

export default ContarctInfromation;
