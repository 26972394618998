import React from "react";
import { COLORS } from "../styles/themes/default/colors";

const IOSwitch = (props) => {
  return (
    <div
      onClick={() => {
        props.setIsActive();
      }}
      style={{
        height: props.height ? `${props.height}px` : "16px",
        width: props.width ? `${props.width}px` : "32px",
        transition: "0.3s all ease-in-out",
        borderRadius: "8px",
        backgroundColor: props.isActive
          ? props.darkTheme
            ? COLORS.purple
            : COLORS.primary
          : COLORS.off_white,
        display: "flex",
        cursor: "pointer",
        justifyContent: props.isActive ? "flex-end" : "flex-start",
        padding: "2px",
        alignItems: "center",
      }}
    >
      <div
        style={{
          height: props.height ? `${props.height - 4}px` : "12px",
          width: props.height ? `${props.height - 4}px` : "12px",
          borderRadius: "25px",
          backgroundColor: !props.isActive
            ? props.darkTheme
              ? COLORS.purple
              : COLORS.primary
            : COLORS.off_white,
        }}
      />
    </div>
  );
};
export default IOSwitch;
