import React, { useEffect, useRef } from "react";
import DetailsTableRow from "./DetailsTableRow";
import Skeleton from "react-loading-skeleton";
import containerStore from "../../../../store/containerStore";

const DetailsTable = ({
  containers,

  loading,
  handleFavorite,
  clusterData,

  loadingContainer,
  setIsModalOpen,
  setIsDetentionModalOpen,
  isTerminalView,
}) => {
  const listRef = useRef(null);
  const currentDisplayContainer = containerStore(
    (state) => state.currentDisplayContainer
  );

  const setGetContainerId = containerStore((state) => state.setGetContainerId);

  useEffect(() => {
    if (clusterData?.ids && clusterData.ids.length > 0 && listRef.current) {
      window.requestAnimationFrame(() => {
        listRef.current.scrollTo({
          top: clusterData?.minIndex * 81,
          behavior: "smooth",
        });
      });
    } else if (
      currentDisplayContainer &&
      Object.keys(currentDisplayContainer).length > 0
    ) {
      const timer = setTimeout(() => {
        let index = -1;

        if (containers?.at(0)?.group) {
          containers.forEach((item) => {
            item.group.forEach((cont) => {
              if (cont.container_id === currentDisplayContainer?.container_id) {
                index = cont.index;
              }
            });
          });
        }
        window.requestAnimationFrame(() => {
          listRef.current.scrollTo({
            top: index * 81,
            behavior: "smooth",
          });
        });
      }, 50);
      return () => clearTimeout(timer);
    }
  }, [clusterData, containers, currentDisplayContainer]);
  useEffect(() => {
    if (
      containers &&
      containers.length === 1 &&
      containers.at(0)?.group &&
      containers.at(0).group.length === 1 &&
      Object.keys(currentDisplayContainer).length === 0 &&
      !loading
    ) {
      setGetContainerId(containers.at(0).group.at(0).container_id);
    }
  }, [containers]);
  return (
    <div
      className="main_container"
      style={{
        height: 0,
        flexGrow: 1,
      }}
    >
      {loading ? (
        <Skeleton height={65} style={{ marginTop: "15px" }} count={10} />
      ) : (
        <div ref={listRef} className="port_table">
          {containers.map((item) => {
            return item.group.map((container) => {
              return (
                <DetailsTableRow
                  setIsModalOpen={setIsModalOpen}
                  setIsDetentionModalOpen={setIsDetentionModalOpen}
                  data={container}
                  key={container.index}
                  index={container.index}
                  handleFavorite={handleFavorite}
                  isInCluster={
                    clusterData?.ids &&
                    clusterData.ids.includes(container.container_id)
                  }
                  loadingContainer={loadingContainer}
                />
              );
            });
          })}
        </div>
      )}
    </div>
  );
};
export default DetailsTable;
