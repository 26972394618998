import React from "react";

import ReactApexChart from "react-apexcharts";

import { COLORS } from "../../../styles/themes/default/colors";
import { formatValue } from "../../../utils/constants";
import Skeleton from "react-loading-skeleton";

const GaugeBarStatistics = (props) => {

  const valueFormatterUS = (value) => {
    return value > 999
      ? (value / 1000).toLocaleString("en-US", { style: 'decimal', maximumFractionDigits: 1 }) + 'k'
      : value.toLocaleString("en-US", { style: 'decimal', maximumFractionDigits: 1 });
  }

  const total = props?.series?.demurrage + props?.series?.detention

  const options = {
    series:
      props?.series?.dem_count &&
        props?.series?.det_count &&
        props.series.dem_count + props.series.det_count > 0
        ? [
          (props?.series.dem_count * 100) /
          (props.series.dem_count + props.series.det_count),
        ]
        : [0],
    options: {
      chart: {
        type: "radialBar",
        offsetY: -15,
      },
      plotOptions: {
        radialBar: {
          hollow: {
            size: "60px",
          },
          startAngle: -120,
          endAngle: 120,
          track: {
            background: "#9F30C6",
          },
        },
      },

      fill: {
        colors: "#C8DF6E",
      },
      stroke: {
        dashArray: 4,
      },
    },
  };
  return (
    <div className="gauge_chart_div">
      {props.isLoading ? (
        <div style={{ width: "100%", height: "300px" }}>
          <Skeleton width="100%" height="100%" />
        </div>
      ) : (
        <>
          <div className="chart_title_box">
            <p className="chart_title">{props?.title}</p>
            <p className="chart_subtitle">{props?.subtitle}</p>
          </div>
          <div
            style={{
              position: "relative",
              display: "flex",
              justifyContent: "center",
              width: "100%",
            }}
          >
            <ReactApexChart
              options={options.options}
              series={options.series}
              type="radialBar"
              height={window.innerWidth > 1920 ? 430 : 330}
            />
            <div
              style={{
                position: "absolute",
                top: window.innerWidth > 1920 ? "50%" : "30%",
                display: "flex",
                alignItems: "center",
                width: "100%",
                flexDirection: "column",
              }}
            >
              <p
                style={{
                  color: COLORS.off_white,
                  fontSize: "24px",
                  fontWeight: 600,
                  margin: 0,
                }}
              >
                {'$' + valueFormatterUS(total)}
              </p>
              <p className="dark_grey_14px"> Demurrage & Detention</p>
              <div
                style={{
                  display: "flex",
                  width: "60%",
                  height: "24px",
                  marginTop: "16px",
                  justifyContent: "space-evenly",
                }}
              >
                <p style={{ fontWeight: 600, color: "#C8DF6E" }}>
                  {parseInt(
                    props.series?.dem_count &&
                      props.series?.det_count &&
                      props.series?.det_count + props.series?.dem_count > 0
                      ? (props?.series?.dem_count * 100) /
                      (props?.series?.dem_count + props?.series?.det_count)
                      : 0
                  )}
                  %
                </p>
                <p style={{ fontWeight: 600, color: "#9F30C6" }}>
                  {100 -
                    parseInt(
                      props.series?.dem_count &&
                        props.series?.det_count &&
                        props.series?.det_count + props.series?.dem_count > 0
                        ? (props?.series?.dem_count * 100) /
                        (props?.series?.dem_count +
                          props?.series?.det_count)
                        : 0
                    )}
                  %
                </p>
              </div>
              <div
                style={{
                  display: "flex",
                  width: "65%",
                  marginTop: "32px",
                  justifyContent: "space-evenly",
                }}
              >
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    width: "100%",
                  }}
                >
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      flexDirection: "column",
                    }}
                  >
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        gap: "8px",
                      }}
                    >
                      <div
                        style={{ backgroundColor: "#C8DF6E" }}
                        className="chart_dot"
                      />
                      <p className="white_14px">Demurrage</p>
                      <p className="chart_subtitle">
                        {props?.series?.dem_count}
                      </p>
                    </div>
                    <p className="chart_subtitle">
                      {formatValue(props?.series?.demurrage)}
                    </p>{" "}
                  </div>
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      flexDirection: "column",
                    }}
                  >
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        gap: "8px",
                      }}
                    >
                      <div
                        style={{ backgroundColor: "#9F30C6" }}
                        className="chart_dot"
                      />
                      <p className="white_14px">Detention</p>
                      <p className="chart_subtitle">
                        {props?.series?.det_count}
                      </p>
                    </div>
                    <p className="chart_subtitle">
                      {formatValue(props?.series?.detention)}
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </>
      )}
    </div>
  );
};

export default GaugeBarStatistics;
