import React from "react";

import OffsetHeader from "./OffsetProjectsBox/OffsetHeader";
import OffsetProjectRow from "./OffsetProjectsBox/OffsetProjectsRow";

const OffsetProjectBox = (props) => {
  let totalValue = props.data.reduce((acc, cur) => acc + cur.value, 0);

  const changeIsActive = (id) => {
    let newData = [...props.data];

    newData[id].isActive = !newData[id].isActive;
    if (!newData[id].isActive) {
      newData[id].value = 0;
    }
    let numOfActives = 0;
    newData.forEach((item) => {
      numOfActives += item.isActive ? 1 : 0;
    });
    let setValue = 100.0 / numOfActives;
    newData = newData.map((item) => {
      return item.isActive ? { ...item, value: setValue } : item;
    });

    props.setData(newData);
  };
  const changeValue = (id, value) => {
    let newData = [...props.data];
    if (value === "") {
      value = 0;
    }
    newData[id].value = parseFloat(value);
    if (value === 0) {
      newData[id].isActive = false;
    }

    props.setData(newData);
  };
  return (
    <div className="template_box w25 flex-shrink_box d-flex f-dir-col">
      <OffsetHeader
        data={props.headerData}
        setData={props.setHeaderData}
        toggleOffset={props.toggleOffset}
      />
      <div
        className="f-grow1"
        style={{
          height: 0,
          overflowY: "auto",
          paddingRight: "16px",
          paddingTop: "16px",
        }}
      >
        {totalValue !== 100 && totalValue !== 0 && (
          <p className="c-orange fw600 fs14" style={{ textAlign: "center" }}>
            Sum of values needs to be 100%
          </p>
        )}
        {props.data &&
          props.data.length > 0 &&
          props.data
            .sort((a, b) => {
              return a.isActive && !b.isActive ? -1 : 1;
            })
            .map((item, index) => {
              return (
                <OffsetProjectRow
                  key={index}
                  data={item}
                  id={index}
                  changeIsActive={changeIsActive}
                  changeValue={changeValue}
                  redBorder={totalValue !== 100 && totalValue !== 0}
                />
              );
            })}
      </div>
    </div>
  );
};
export default OffsetProjectBox;
