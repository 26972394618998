import React, { useState } from "react";
import { Formik, Field, Form, ErrorMessage } from "formik";
import * as Yup from "yup";

import accountService from "../../../services/accountService";
import alertService from "../../../services/alertService";
import useStore from "../../../store/store";

import { Dialog, Button } from "@mui/material";
import { styled } from "@mui/material/styles";

import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";

import { Button as ButtonCdl } from "../../../components/Cdl/Button";

import closeModalIcon from "../../../assets/img/closeModalIcon.png";

import "../styles/guestApprovalModal.scss";

const ActionButton = styled(Button)(({ theme }) => ({
  marginRight: "10px",
  background: "none",
  borderRadius: "8px",
  border: "none",
  fontFamily: "Montserrat !important",
  fontWeight: "600",
  "&:hover": {
    color: "#FF6B4A",
    background: "none",
  },
  "&:focus": {
    color: "#C82C0A",
    outline: "none",
    background: "none",
  },
}));

export default function GuestLogin({ openLogin, setOpenLogin, history }) {
  const setLoggedUser = useStore((state) => state.setUser);

  const [isVisiblePassword, setIsVisiblePassword] = useState(false);

  const initialValues = {
    email: "",
    password: "",
  };

  const validationSchema = Yup.object().shape({
    email: Yup.string().email("Email is invalid").required("Email is required"),
    password: Yup.string().required("Password is required"),
  });

  async function onSubmit({ email, password }, { setSubmitting }) {
    try {
      const user = await accountService.login(email, password);
      setLoggedUser(user);
      history.push("/");
      window.location.reload(true);
    } catch (error) {
      setSubmitting(false);
      alertService.error(error.response.status, "Wrong email or password!");
    }
  }

  return (
    <>
      <Dialog
        open={openLogin}
        onClose={() => {
          setOpenLogin(false);
        }}
      >
        <Formik
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={onSubmit}
        >
          {({ errors, touched, isSubmitting }) => (
            <Form>
              <div className="card-body">
                <div>
                  <div className="modalDivHeader">
                    <p className="titleModalText">Join DaaS</p>
                    <img src={closeModalIcon} className="closeModalImg" />
                  </div>
                  <p className="modalText">
                    We’re so glad you’ve chosen our platform!
                  </p>
                  <p className="modalText">
                    To access all of our features, please log in with your
                    account.
                  </p>
                </div>
                <div className="form-group">
                  <Field
                    placeholder="Email"
                    name="email"
                    type="text"
                    className={`custom-form-input form-control${
                      errors.email && touched.email ? " is-invalid" : ""
                    }`}
                    style={{
                      height: "45px",
                      borderRadius: "5px",
                    }}
                  />
                  <ErrorMessage
                    name="email"
                    component="div"
                    className="invalid-feedback"
                  />
                </div>
                <div className="form-group" style={{ position: "relative" }}>
                  <Field
                    placeholder="Password"
                    name="password"
                    type={isVisiblePassword ? "text" : "password"}
                    className={`custom-form-input form-control${
                      errors.password && touched.password ? " is-invalid" : ""
                    }`}
                    style={{ borderRadius: "5px" }}
                  />
                  <div
                    className="visibility-icon"
                    style={{ position: "absolute", top: "10px", right: "30px" }}
                  >
                    <VisibilityIcon
                      style={{
                        display: isVisiblePassword ? "block" : "none",
                      }}
                      onClick={() => setIsVisiblePassword(!isVisiblePassword)}
                    />
                    <VisibilityOffIcon
                      style={{
                        display: isVisiblePassword ? "none" : "block",
                      }}
                      onClick={() => setIsVisiblePassword(!isVisiblePassword)}
                    />
                  </div>
                  <ErrorMessage
                    name="password"
                    component="div"
                    className="invalid-feedback"
                  />
                </div>
                <div
                  className="form-row"
                  style={{ justifyContent: "end", display: "flex" }}
                >
                  <ButtonCdl
                    type="primary"
                    text="Confirm"
                    css={{
                      width: "120px",
                      height: "42px",
                      marginRight: "10px",
                    }}
                    onClick={onSubmit}
                  />
                  <ActionButton
                    type="button"
                    variant="text"
                    color="sunrise_orange"
                    onClick={() => setOpenLogin(false)}
                  >
                    Cancel
                  </ActionButton>
                </div>
              </div>
            </Form>
          )}
        </Formik>
      </Dialog>
    </>
  );
}
