import React from "react";
import {
  Dialog,
  DialogTitle,
  DialogActions,
  DialogContent,
  useTheme,
  useMediaQuery,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";

import { useState } from "react";
import { COLORS } from "../../../styles/themes/default/colors";
import { Button } from "../../../components/Cdl/Button";
import { AvatarIcon } from "../../../components/ProfilePicture";
import { styled } from "@mui/material/styles";

import useStore from "../../../store/store";
import fetchWrapper from "../../../helpers/fetch-wrapper";
import alertService from "../../../services/alertService";

const Description = styled("p")({
  fontSize: "16px",
  fontWeight: "400",
  lineHeight: "20px",
});

const Content = styled("div")({
  display: "flex",
  flexDirection: "row",
  textAlign: "left",
  marginBottom: "8px",
});

const Informations = styled("div")({
  marginLeft: "8px",
  "p:nth-of-type(1)": {
    marginTop: "2px",
  },
});

const Text = styled("p")({
  fontSize: "16px",
  lineHeight: "20px",
  color: COLORS.off_black,
  marginBottom: "16px",
});

const Label = styled("p")({
  fontSize: "14px",
  lineHeight: "17px",
  color: COLORS.dark_grey,
  marginBottom: "8px",
});

export default function AddUserModal2({
  open,
  setOpenModal,
  deleteUser,
  user,
  deletedUserId,
}) {
  const currentUser = useStore((state) => state.currentUser);

  const [isSent, setisSent] = useState(null);
  const addButtonStyle = {
    marginRight: "5%",
    padding: "11px 16px",
  };
  const closeButtonStyle = {
    padding: "11px 16px",
  };

  const roleToFull = (role) => {
    switch (role) {
      case "CC":
        return "Accountant";
      case "CR":
      case "SR":
        return "Regular";
      case "CA":
      case "SA":
        return "Administrator";
    }
  };

  return (
    <Dialog
      open={open}
      onClose={() => {
        setOpenModal(false);
      }}
      PaperProps={{
        style: {
          backgroundColor: COLORS.ligth_grey,
          padding: "16px",
        },
      }}
    >
      <DialogTitle
        style={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
          padding: "0",
          marginBottom: "24px",
          fontSize: "24px",
          fontWeight: "600",
          lineHeight: "29px",
        }}
      >
        Delete Confirmation
        <CloseIcon
          onClick={(e) => {
            setisSent(null);
            setOpenModal(false);
          }}
        />
      </DialogTitle>
      <DialogContent
        style={{
          textAlign: "center",
          backgroundColor: COLORS.ligth_grey,
          padding: "0",
        }}
      >
        <Description>
          Are you sure you want to delete the following user?
        </Description>
        <Content>
          <AvatarIcon
            firstName={user.first_name}
            lastName={user.last_name}
            style={{
              width: "1.5rem",
              height: "1.5rem",
              fontSize: "10px",
              backgroundColor: "#C4C4C4",
              fontFamily: "Montserrat",
              boxShadow: "none",
            }}
          />
          <Informations>
            <Text>{user.full_name}</Text>
            <Label>Phone:</Label>
            <Text>{user?.phone_number ? user?.phone_number : "Unknown"}</Text>
            <Label>Email:</Label>
            <Text>{user?.email}</Text>
            <Label>Role:</Label>
            <Text>{roleToFull(user.role)}</Text>
          </Informations>
        </Content>
      </DialogContent>

      <DialogActions
        style={{
          justifyContent: "flex-end",
          display: "flex",
        }}
      >
        <Button
          type="primary"
          text="Delete"
          css={addButtonStyle}
          onClick={() => {
            deleteUser(deletedUserId);
            setOpenModal(false);
          }}
        />
        <Button
          text="Close"
          type="link"
          css={closeButtonStyle}
          onClick={(e) => {
            setisSent(null);
            setOpenModal(false);
          }}
        />
      </DialogActions>
    </Dialog>
  );
}
