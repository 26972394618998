import React from "react";
import {
  Dialog,
  DialogTitle,
  DialogActions,
  DialogContent,
  Button,
  DialogContentText,
} from "@mui/material";

import HelpOutlineIcon from "@mui/icons-material/HelpOutline";
import { COLORS } from "../../../styles/themes/default/colors";
import CssTextField from "../../../components/StyledTextField";

export default function DemurrageRejectModal({
  openRejectModal,
  setOpenRejectModal,
  changeStatus,
  setRejectComment,
  rejectComment,
}) {
  const handleReject = () => {
    changeStatus("RE");
    setOpenRejectModal(false);
  };
  return (
    <Dialog
      open={openRejectModal}
      onClose={() => {
        setOpenRejectModal(false);
      }}
    >
      <DialogTitle
        style={{
          alignItems: "center",
          display: "flex",
          flexDirection: "column",
          fontFamily: "Montserrat",
          fontWeight: "bold",
        }}
      >
        <HelpOutlineIcon
          style={{
            width: "50px",
            height: "100px",
            color: COLORS.sunrise_orange,
          }}
        />
        Reject demurrage?
      </DialogTitle>
      <DialogContent
        style={{
          marginLeft: "30px",
          marginRight: "30px",
          textAlign: "center",
        }}
      >
        <DialogContentText style={{ fontFamily: "Montserrat" }}>
          In the comment section below, you can add the reason for rejecting the
          demurrage payment. After you add a comment, you can click on the
          REJECT button, which will transfer demurrage to the status REJECT.
        </DialogContentText>
        <CssTextField
          multiline
          rows={5}
          placeholder="Add your comment or reason for rejection..."
          onChange={(e) => {
            setRejectComment(e.target.value);
          }}
          style={{ width: "100%", marginTop: "40px" }}
          InputProps={{
            style: {
              fontSize: "15px",
              fontFamily: "Montserrat",
            },
          }}
        />
      </DialogContent>

      <DialogActions
        style={{ justifyContent: "center", display: "flex", margin: "30px" }}
      >
        <Button
          style={{
            fontWeight: "bold",
            boxShadow: "none",
          }}
          color="sunrise_orange"
          variant="contained"
          onClick={() => handleReject()}
        >
          Reject
        </Button>
        <Button
          style={{
            boxShadow: "none",
            fontFamily: "Montserrat",
            fontWeight: "bold",
          }}
          color="sunrise_orange"
          variant="outlined"
          onClick={() => {
            setOpenRejectModal(false);
          }}
        >
          Back
        </Button>
      </DialogActions>
    </Dialog>
  );
}
