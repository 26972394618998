import React, { useState, useEffect } from "react";

import { useHistory, useParams } from "react-router-dom";
import Cookies from "js-cookie";

import { createTheme } from "@mui/material";
import axios from "axios";
import { APP_URL } from "../../utils/constants";
import alertService from "../../services/alertService";

import GuestSetPasswordModal from "./components/GuestSetPasswordModal";
import GuestApprovalNavbar from "./components/GuestApprovalNavbar";
import GuestSideBar from "./components/GuestSideBar";
import GuestApprovalDetails from "./components/GuestApprovalDetails";
import GuestConfirmPassword from "./components/GuestConfirmPassword";
import GuestLogin from "./components/GuestLogin";

import { styled } from "@mui/material/styles";

const theme1 = createTheme({
  breakpoints: {
    values: {
      xs: 0,
      smallMobile: 321,
      mobile: 376,
      middleMobile: 407,
      sm: 600,
      md: 900,
      lg: 1200,
      xl: 1536,
    },
  },
});
const mdDown = theme1.breakpoints.down("md");

const MainDiv = styled("div")({
  padding: `0px 0px 0px 270px !important`,
  width: "100%",
  position: "absolute",
  display: "flex",
  [mdDown]: {
    padding: `0px !important`,
  },
});
const MainDivSmall = styled("div")({
  width: "100%",
  position: "absolute",
  display: "flex",
  backgroundColor: "white",
  [mdDown]: {
    padding: `0px !important`,
  },
});

export default function GuestApprovalPage() {
  let { token } = useParams();
  let { id } = useParams();
  const history = useHistory();
  const [openDrawer, setOpenDrawer] = useState(true);

  const [openSetPassword, setOpenSetPassword] = useState(false);
  const [openConfirmPassword, setOpenConfirmPassword] = useState(false);
  const [openLogin, setOpenLogin] = useState(false);

  const [isSubmitting, setIsSubmitting] = useState(false);
  const [status, setStatus] = useState(false);
  const [demurrage, setDemurrage] = useState();
  const [user, setUser] = useState();

  // const [token, setToken] = useState();
  const [needRedirect, setNeedRedirect] = useState(false);

  let cookie = Cookies.get("access_token");

  if (cookie) {
    history.push(`/demurrage/details/${id}`);
  }

  useEffect(() => {
    axios
      .get(`${APP_URL}/dnd/guest/${id}/`, {
        headers: { Authorization: `${token}` },
      })
      .then((response) => setDemurrage(response.data))
      .catch(() => {
        global.setCookie("");
        history.push("/");
      });

    axios
      .get(`${APP_URL}/user/guest/myprofile/`, {
        headers: {
          Authorization: `${token}`,
        },
      })
      .then((resp) => {
        setUser(resp.data.user);
      })
      .catch(() => {
        global.setCookie("");
        history.push("/");
      });
  }, []);

  const approveRejectAction = (status) => {
    if (demurrage.status !== "OP") {
      alertService.error({}, `The status has already been changed.`);
    } else {
      setStatus(status);
      if (user.is_activated) {
        setOpenConfirmPassword(true);
      } else {
        setOpenSetPassword(true);
      }
    }
  };

  const handleClick = (redirect) => {
    if (user.is_activated) {
      setOpenLogin(true);
    } else {
      setNeedRedirect(redirect);
      setOpenSetPassword(true);
    }
  };

  return (
    <>
      <GuestApprovalNavbar
        openDrawer={openDrawer}
        setOpenDrawer={setOpenDrawer}
        handleClick={handleClick}
        user={user}
      />
      <div>
        <GuestSideBar
          handleClick={handleClick}
          openDrawer={openDrawer}
          setOpenDrawer={setOpenDrawer}
        />
        {!openDrawer ? (
          <MainDivSmall className="p-4">
            <GuestApprovalDetails
              approveRejectAction={approveRejectAction}
              demurrage={demurrage}
              setNeedRedirect={setNeedRedirect}
              user={user}
            />
          </MainDivSmall>
        ) : (
          <MainDiv className="p-4">
            <GuestApprovalDetails
              approveRejectAction={approveRejectAction}
              demurrage={demurrage}
              setNeedRedirect={setNeedRedirect}
              user={user}
            />
          </MainDiv>
        )}
      </div>
      <GuestSetPasswordModal
        openSetPassword={openSetPassword}
        setOpenSetPassword={setOpenSetPassword}
        token={token}
        setOpenConfirmPassword={setOpenConfirmPassword}
        isSubmitting={isSubmitting}
        setIsSubmitting={setIsSubmitting}
        needRedirect={needRedirect}
        setUser={setUser}
      />
      <GuestConfirmPassword
        id={id}
        token={token}
        status={status}
        openConfirmPassword={openConfirmPassword}
        setOpenConfirmPassword={setOpenConfirmPassword}
        demurrage={demurrage}
        setDemurrage={setDemurrage}
        user={user}
      />
      <GuestLogin
        setOpenLogin={setOpenLogin}
        openLogin={openLogin}
        demurrage={demurrage}
      />
    </>
  );
}
