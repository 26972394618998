import React, { useState, useRef } from "react";

import Chart from "react-google-charts";
import useStore from "../../../../store/store";
import "../../styles/geochart.scss";

import Co2Icon from "../../assets/co2_white_icon.svg";
import ShipIcon from "../../assets/ship.svg";
import TruckIcon from "../../assets/grey_truck.svg";
import RailIcon from "../../assets/rail_icon.svg";
import { GOOGLE_API_KEY } from "../../../../utils/constants";

const GeoChart = (props) => {
  const currentUser = useStore((state) => state.currentUser);
  const [isLeft, setIsLeft] = useState(true);
  const divRef = useRef(null);
  let colors = ["#FF1DA4", "#0FF", "#CE42FF", "#0B51F7", "#00FF65"];
  const options = {
    region: "US", // Africa
    colorAxis: {
      colors: currentUser.user.role.startsWith("C")
        ? ["#FF00B8", "#00FF65"]
        : colors.slice(
            0,
            props?.data?.states ? props.data.states.length - 1 : -1
          ),
    },
    backgroundColor: "#111111",

    datalessRegionColor: "#313131",
    displayMode: "regions",
    resolution: "provinces",
    legend: "none",
    tooltip: {
      isHtml: true,
      legend: "none",
    },
  };
  const customTooltip = (props) => {
    const tooltipClass = isLeft
      ? "tooltip_container_left"
      : "tooltip_container_right";
    return `<div class="${tooltipClass}" style="background-color:#313131;padding:16px;color:#f1f1f1;font-family:Montserrat;">
    <div style="display:flex;align-items:center;gap:8px;margin-bottom:8px;">
    <img src="${Co2Icon}"/>
    <p style="margin:0;font-weight:600;font-family:Montserrat;">${props.name
      .substring(0, 2)
      .toUpperCase()}, CO<sub>2</sub> Emission</p>
    </div>
    <div style="display:flex;align-items:center;gap:8px;margin-bottom:8px;">
    <img src="${ShipIcon}" style="width:16px;height:16px;"/>
    <p style="margin:0;font-weight:600;color:#8c8c8c;">Vessel:</p>
    </div>
    <div style="display:flex;padding-left:24px;gap:4px;">
    <p style="margin:0;margin-bottom:8px;color:#f1f1f1;font-weight:600;">${(
      parseFloat(props.value.vessel) / 1000
    ).toFixed(2)}</p>
    <p style="margin:0;margin-bottom:8px;color:#8c8c8c;">tCO<sub>2</sub></p>
    </div>
    <div style="display:flex;align-items:center;gap:8px; margin-bottom:8px;">
    <img src="${TruckIcon}" style="width:16px;height:16px;"/>
    <p style="margin:0;font-weight:600;color:#8c8c8c;">Truck:</p>
    </div>
    <div style="display:flex;padding-left:24px;gap:4px;">
    <p style="margin:0;margin-bottom:8px;color:#f1f1f1;font-weight:600;">${(
      parseFloat(props.value.truck) / 1000
    ).toFixed(2)}</p>
    <p style="margin:0;margin-bottom:8px;color:#8c8c8c;">tCO<sub>2</sub></p>
    </div>
    <div style="display:flex;align-items:center;gap:8px;margin-bottom:8px;">
    <img src="${RailIcon}" style="width:16px;height:16px;"/>
    <p style="margin:0;font-weight:600;color:#8c8c8c;">Rail:</p>
    </div>
    <div style="display:flex;padding-left:24px;gap:4px;">
    <p style="margin:0;margin-bottom:8px;color:#f1f1f1;font-weight:600;">${(
      parseFloat(props.value.rail) / 1000
    ).toFixed(2)}</p>
    <p style="margin:0;margin-bottom:8px;color:#8c8c8c;">tCO<sub>2</sub></p>
    </div>
    </div>`;
  };
  let data = [
    ["State", "Emmision", { role: "tooltip", p: { html: true } }],
  ].concat(
    props?.data?.states && props.data.states.length > 0
      ? props.data.states
          .filter((item) => item.name !== "other")
          .map((item, index) => {
            return [item.name, index, customTooltip(item)];
          })
      : []
  );

  const handleMouseMove = (e) => {
    const { left } = divRef.current.getBoundingClientRect();
    const scaleX = window.devicePixelRatio;
    setIsLeft(
      e.clientX < (scaleX > 1 ? (left + 146) * (2 - scaleX) : left + 188)
    );
  };

  return (
    <div
      id="map"
      ref={divRef}
      onMouseMove={handleMouseMove}
      className="br8 h100"
    >
      <Chart
        chartType="GeoChart"
        width="100%"
        height="100%"
        data={data}
        options={options}
        mapsApiKey={GOOGLE_API_KEY}
      />
    </div>
  );
};
export default GeoChart;
