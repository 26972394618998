import React from "react";

const OffsetBadges = (props) => {
  return (
    <div
      className="offset_badges_div"
      style={{ gap: props.gap, display: "flex", overflow: "hidden" }}
    >
      {props.badges.map((item, index) => {
        return (
          <img
            key={index}
            alt="badge"
            src={item}
            style={{
              height: props.size,
              width: props.size,
              borderRadius: "4px",
            }}
          />
        );
      })}
    </div>
  );
};
export default OffsetBadges;
