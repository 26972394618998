import React, { useEffect, useState } from "react";

import TrashCan from "../../assets/trash_can_icon.svg";

import "../../styles/carttable.scss";
import QuantityCell from "./QuantityCell";
import { COLORS } from "../../../../styles/themes/default/colors";
import esgStore from "../../../../store/esgStore";

const CartTable = ({ values, setValues }) => {
  const [tableHeigth, setTableHeigth] = useState(0);

  useEffect(() => {
    const handleResize = () => {
      let height = document.getElementById("app")?.clientHeight - 700;
      setTableHeigth(height < 370 ? 370 : height);
      // Your logic for handling resize goes here
    };
    window.addEventListener("resize", handleResize);
    handleResize();
    // Cleanup function to remove the event listener when the component unmounts
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const offsetProjects = esgStore((state) => state.offsetProjects);
  return (
    <div className="carttable_main_div">
      <div className="carttable_header">
        <div style={{ width: "40%" }}>
          <p className="c-white fw600 m0">Project</p>
        </div>
        <div
          style={{ width: "15%", display: "flex", justifyContent: "flex-end" }}
        >
          <p className="c-white fw600 m0">Price</p>
        </div>
        <div
          style={{ width: "30%", display: "flex", justifyContent: "center" }}
        >
          <p className="c-white fw600 m0">Quantity</p>
        </div>
        <div style={{ width: "15%" }}>
          <p className="c-white fw600 m0">Total</p>
        </div>
      </div>
      <div
        style={{
          // flexGrow: 1,
          // height: 0,
          maxHeight: `${tableHeigth}px`,
          overflowY: "auto",
          width: "100%",
        }}
      >
        {Object.keys(values).length > 0 ? (
          Object.keys(values).map((item, index) => {
            let data = offsetProjects.at(item);
            return (
              <div className="carttable_row" key={index}>
                <div
                  style={{
                    width: "40%",
                    display: "flex",
                    height: "100%",
                    padding: "16px",
                    paddingLeft: "0",
                  }}
                >
                  <img
                    src={data.image_url}
                    alt=""
                    style={{ width: "70px", borderRadius: "4px" }}
                  />
                  <div
                    style={{
                      height: "100%",
                      display: "flex",
                      flexDirection: "column",
                      justifyContent: "space-around",
                      marginLeft: "16px",
                    }}
                  >
                    <p className="c-white m0" style={{ fontSize: "14px" }}>
                      {data.name.slice(0, 50)}
                    </p>
                    {/* <p
                      className="c-dark-grey m0"
                      style={{ fontSize: "14px" }}
                    >
                      {data.subtitle}
                    </p> */}
                  </div>
                </div>
                <div
                  style={{
                    width: "15%",
                    display: "flex",
                    height: "100%",
                    alignItems: "center",
                    justifyContent: "flex-end",
                  }}
                >
                  <p className="c-white m0">
                    ${parseFloat(data.price_value).toFixed(2)} / tCO<sub>2</sub>
                    e
                  </p>
                </div>
                <div
                  style={{
                    width: "30%",
                    display: "flex",
                    height: "100%",
                    justifyContent: "center",
                  }}
                >
                  <QuantityCell
                    changeValue={(new_value) => {
                      setValues({ ...values, [item]: new_value });
                    }}
                    value={values[item]}
                  />
                </div>
                <div
                  style={{
                    width: "15%",
                    display: "flex",
                    height: "100%",
                    alignItems: "center",
                    justifyContent: "space-between",
                    padding: "16px",
                    paddingLeft: "0",
                  }}
                >
                  <p className="c-purple m0 fw600">
                    ${parseFloat(values[item] * data.price_value).toFixed(2)}
                  </p>
                  <button
                    style={{ backgroundColor: "transparent", border: "none" }}
                  >
                    <img
                      src={TrashCan}
                      alt="trash_can"
                      onClick={() => {
                        const updated_values = { ...values };
                        delete updated_values[item];

                        setValues(updated_values);
                      }}
                    />
                  </button>
                </div>
              </div>
            );
          })
        ) : (
          <div
            style={{
              width: "100%",
              height: "100%",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              color: COLORS.purple,
              fontWeight: "600",
            }}
          >
            No items in the cart!
          </div>
        )}
      </div>
    </div>
  );
};
export default CartTable;
