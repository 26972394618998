import { useEffect, useState } from "react";
import { Formik, Form } from "formik";
import {
  Button,
  TextField,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { useParams, useHistory } from "react-router-dom";
import { Link } from "react-router-dom";

import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import fetchWrapper from "../../helpers/fetch-wrapper";
import { APP_URL } from "../../utils/constants";
import PasswordChecklist from "react-password-checklist";
import HistoryIcon from "@mui/icons-material/History";
import { CustomLoader } from "../../components/CustomLoader";
import { COLORS } from "../../styles/themes/default/colors";
export default function PasswordSetup() {
  const [isVisiblePassword, setIsVisiblePassword] = useState(false);
  const [isVisibleConfirmPassword, setIsVisibleConfirmPassword] =
    useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [validationRule, setValidationRule] = useState(false);

  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");

  const [isValidToken, setIsValidToken] = useState(null);

  let { token } = useParams();

  const history = useHistory();
  const theme = useTheme();
  const mdUp = useMediaQuery(theme.breakpoints.up("550"));

  useEffect(() => {
    async function checkToken() {
      let data = {
        token: token,
      };
      fetchWrapper.post(`${APP_URL}/user/checktoken/`, data).then((resp) => {
        if (resp.status === "valid") setIsValidToken(true);
        else setIsValidToken(false);
      });
    }
    checkToken();
  });

  const togglePasswordVisibility = () => {
    setIsVisiblePassword(!isVisiblePassword);
  };
  const toggleConfirmPasswordVisibility = () => {
    setIsVisibleConfirmPassword(!isVisibleConfirmPassword);
  };

  const handleSetPassword = () => {
    if (password !== confirmPassword || password === "") {
      // add error message
      return;
    }
    setIsSubmitting(true);

    let data = {
      password: password,
    };
    fetchWrapper
      .patch(`${APP_URL}/user/setpassword/${token}/`, data)
      .then((resp) => {
        history.push("/account/login");
      });

    setIsSubmitting(false);
  };

  return (
    <>
      {isValidToken === null ? (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "500px",
          }}
        >
          <CustomLoader />
        </div>
      ) : isValidToken ? (
        <Formik>
          {({ errors, touched }) => (
            <Form>
              <div
                className="card-body"
                style={{ width: mdUp ? "30rem" : "20rem" }}
              >
                <div className="form-row">
                  <div className="form-group col">
                    <h3 style={{ textAlign: "center" }}>
                      <b>Set Password</b>
                    </h3>
                  </div>
                </div>
                <div className="form-row">
                  <div className="form-group col">
                    <TextField
                      placeholder="Password"
                      name="password"
                      type={isVisiblePassword ? "text" : "password"}
                      className={`form-control${
                        errors.address && touched.address ? " is-invalid" : ""
                      }`}
                      onChange={(e) => {
                        setPassword(e.target.value);
                      }}
                      InputProps={{
                        endAdornment: (
                          <Button
                            style={{ outline: "none" }}
                            onClick={togglePasswordVisibility}
                          >
                            {isVisiblePassword ? (
                              <VisibilityIcon />
                            ) : (
                              <VisibilityOffIcon />
                            )}
                          </Button>
                        ),
                      }}
                    />
                  </div>
                </div>
                <PasswordChecklist
                  style={{ marginTop: "3%" }}
                  rules={[
                    "minLength",
                    "specialChar",
                    "number",
                    "capital",
                    "match",
                  ]}
                  minLength={8}
                  value={password}
                  valueAgain={confirmPassword}
                  onChange={(isValid) => {
                    setValidationRule(true);
                  }}
                />
                <div className="form-row">
                  <div className="form-group col">
                    <TextField
                      style={{ marginTop: "20px" }}
                      placeholder="Confirm password"
                      name="confirm-password"
                      type={isVisibleConfirmPassword ? "text" : "password"}
                      className={`form-control${
                        errors.city && touched.city ? " is-invalid" : ""
                      }`}
                      onChange={(e) => {
                        setConfirmPassword(e.target.value);
                      }}
                      InputProps={{
                        endAdornment: (
                          <Button
                            style={{ outline: "none" }}
                            onClick={toggleConfirmPasswordVisibility}
                          >
                            {isVisibleConfirmPassword ? (
                              <VisibilityIcon />
                            ) : (
                              <VisibilityOffIcon />
                            )}
                          </Button>
                        ),
                      }}
                    />
                  </div>
                </div>

                <div
                  className="form-group"
                  style={{ display: "flex", justifyContent: "center" }}
                >
                  <Button
                    onClick={handleSetPassword}
                    // type="submit"
                    disabled={!validationRule}
                    variant="contained"
                    style={{
                      marginTop: "20px",
                      backgroundColor: "#E4E835",
                      color: "#1E1E1E",
                      fontWeight: 600,
                      width: "100%",
                      borderRadius: "5px",
                      boxShadow: "none",
                    }}
                    sx={{ fontFamily: "Montserrat" }}
                    // className="btn btn-primary"
                  >
                    {isSubmitting && (
                      <span className="spinner-border spinner-border-sm mr-1" />
                    )}
                    Set Password
                  </Button>
                  {/* <Link to="login" className="btn btn-link">
                                Cancel
                            </Link> */}
                </div>
              </div>
            </Form>
          )}
        </Formik>
      ) : (
        <div>
          <div style={{ display: "flex", justifyContent: "space-around" }}>
            <HistoryIcon
              style={{
                color: COLORS.sunrise_orange,
                width: "33%",
                height: "33%",
                align: "center",
                marginBottom: "4%",
                marginTop: "2%",
              }}
            />
          </div>
          <Typography
            style={{
              textAlign: "center",
              fontSize: "30px",
              marginBottom: "4%",
            }}
            sx={{
              fontFamily: "Montserrat",
            }}
          >
            Your token has expired!
          </Typography>

          <Typography
            style={{
              textAlign: "center",
              fontSize: "20px",
              marginBottom: "4%",
              marginLeft: "12%",
              marginRight: "12%",
            }}
            sx={{
              fontFamily: "Montserrat",
            }}
          >
            It has been 24h hours since your token was sent. Please go to the
            forgot password page and request another password reset.
          </Typography>

          <Link to="/account/login">
            <Typography
              style={{
                color: COLORS.sunrise_orange,
                textAlign: "center",
                fontSize: "20px",
                marginBottom: "4%",
              }}
              sx={{
                fontFamily: "Montserrat",
              }}
            >
              <b>&lt;&lt; Back to login page </b>
            </Typography>
          </Link>
        </div>
      )}
    </>
  );
}
