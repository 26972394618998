import React from "react";
import "../styles/blackSwitch.scss"

const BlackSwitch = (props) => {

    return (
        <div className="switch-button">
            <span className={!props?.demDetSwitch ? 'active' : 'inactive'}>Dem</span>
            <div className="switch-checkbox">
                <label className="switch">
                    <input
                        type="checkbox"
                        checked={props?.demDetSwitch}
                        onChange={() => {
                            props?.setDemDetSwitch(!props.demDetSwitch);
                        }}
                    />
                    <span className="slider round"></span>
                </label>
            </div>
            <span className={props?.demDetSwitch ? 'active' : 'inactive'}>Det</span>
        </div>
    );
}
export default BlackSwitch;