import React from "react";

import ProcurementChart from "./ThirdColumnComponents/ProcurementChart";

import ProcurementDiversity from "./ThirdColumnComponents/ProcurementDiversity";
import TopEmissionPortRail from "./ThirdColumnComponents/TopEmissionPortRail";
import Top5SustainableGoals from "./ThirdColumnComponents/Top5SustainableGoals";
export default function EsgThirdColumn(props) {
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        height: "100%",
        gap: "16px",
      }}
    >
      <div className="procurementDiv">
        <p className="sustainableLabel">Sustainable Procurement</p>
        <ProcurementChart data={props?.data?.esg_sustainable_procurement} />
      </div>

      <div className="renewableGraphDiv">
        <Top5SustainableGoals
          data={props?.data?.esg_top5_sustainable_projects}
        />
        <ProcurementDiversity data={props?.data?.esg_supplier_diversity} />
      </div>
      <TopEmissionPortRail
        top_terminals_by_emission={props?.data?.top_terminals_by_emission}
      />
    </div>
  );
}
