import React from "react";

import ReactApexChart from "react-apexcharts";

import "../styles/statistics.scss";
import { COLORS } from "../../../styles/themes/default/colors";
import { kFormatter } from "../../../utils/constants";
import Skeleton from "react-loading-skeleton";

const HalfDonutChart = (props) => {

  const options = {
    series: props.values,
    colors: ["#FF1DA4", COLORS.activity],
    options: {
      chart: {
        type: "donut",
        offsetY: 0,
        sparkline: {
          enabled: false,
        },
      },
      plotOptions: {
        pie: {
          startAngle: -90,
          endAngle: 90,
          offsetY: 10,
          donut: {
            size: "95%",
          },
        },
        bar: {
          width: 1,
        },
      },
      tooltip: {
        enabled: false,
      },
      fill: {
        colors: props.colors,
      },
      stroke: {
        stroke: 0.1,
        width: 0,
        lineCap: "round", // Make radial lines rounded
      },
      dataLabels: {
        enabled: false,
      },
      legend: {
        show: false,
      },
    },
  };
  return (
    <div className="half_donut_box">
      {props.isLoading ? (
        <div style={{ width: "100%", height: "170px" }}>
          <Skeleton width="100%" height="100%" />
        </div>
      ) : (
        <>
          <div className="chart_title_box">
            <div style={{ backgroundColor: props.dotColor }} className="chart_dot" />
            <p className="chart_title">{props.title}</p>
            {props.subtitle && (
              <p className="chart_subtitle">({props.subtitle})</p>
            )}
          </div>
          <div >
            <div
              style={{
                position: "relative",
                width: "100%",
              }}
            >
              <ReactApexChart
                options={options.options}
                series={options.series}
                type="donut"
                height={window.innerWidth > 1920 ? 340 : 300}
              />
              <div className="half_donut_label_box">
                <p className="chart_24px">{'$' + kFormatter(props?.total)}</p>
                <p className="chart_subtitle">Total</p>
                <div className="chart_flex_box">
                  <p
                    className="chart_title"
                    style={{ color: props.colors.at(0) }}
                  >
                    {(props.values.at(0) === 0 && props.values.at(1) === 0)
                      ? 0
                      :
                      parseInt(
                        (props.values.at(0) * 100) /
                        (props.values.at(0) + props.values.at(1))
                      )}
                    %
                  </p>
                  <p
                    className="chart_title"
                    style={{ color: props.colors.at(1) }}
                  >
                    {(props.values.at(0) === 0 && props.values.at(1) === 0)
                      ? 0
                      : 100 -
                      parseInt(
                        (props.values.at(0) * 100) /
                        (props.values.at(0) + props.values.at(1))
                      )}
                    %
                  </p>
                </div>
              </div>
              <div className="half_donut_legend_box">
                <div className="half_donut_legend_item">
                  <div
                    style={{ display: "flex", alignItems: "center", gap: "4px" }}
                  >
                    <div
                      style={{ backgroundColor: props.colors.at(0) }}
                      className="chart_dot"
                    />
                    <p className="white_14px">Demurrage</p>
                  </div>

                  <p className="chart_subtitle">{props.values.at(0)}</p>
                </div>
                <div className="half_donut_legend_item">
                  <div
                    style={{ display: "flex", alignItems: "center", gap: "4px" }}
                  >
                    <div
                      style={{ backgroundColor: props.colors.at(1) }}
                      className="chart_dot"
                    />
                    <p className="white_14px">Detention</p>
                  </div>
                  <p className="chart_subtitle">{props.values.at(1)}</p>
                </div>
              </div>
            </div>
          </div>
        </>
      )}
    </div>
  );
};

export default HalfDonutChart;
