import React from "react";
import {
  Dialog,
  DialogTitle,
  DialogActions,
  DialogContent,
  Button,
  useMediaQuery,
  createTheme,
} from "@mui/material";
import { styled } from "@mui/material/styles";

import { COLORS } from "../../../styles/themes/default/colors";
import { CSVLink } from "react-csv";

const theme1 = createTheme({
  breakpoints: {
    values: {
      xs: 0,
      smallMobile: 321,
      mobile: 376,
      middleMobile: 407,
      sm: 600,
      md: 900,
      lg: 1200,
      xl: 1536,
    },
  },
});

const smallMobileDown = theme1.breakpoints.down("smallMobile");
const mobileDown = theme1.breakpoints.down("mobile");
const middleMobileDown = theme1.breakpoints.down("middleMobile");
const smDown = theme1.breakpoints.down("sm");
const mdDown = theme1.breakpoints.down("md");
const lgDown = theme1.breakpoints.down("lg");

const ModalDialogTitle = styled(DialogTitle)({
  alignItems: "center",
  display: "flex",
  flexDirection: "column",
  fontSize: "30px",
  fontWeight: "bold",

  [smDown]: {
    fontSize: "24px",
  },

  [middleMobileDown]: {
    fontSize: "19px",
    padding: "0",
    marginBottom: "20px",
  },
});

const ModalDialogContent = styled(DialogContent)({
  marginLeft: "30px",
  marginRight: "30px",
  textAlign: "center",
  backgroundColor: COLORS.white,

  [smDown]: {
    marginLeft: "0",
    marginRight: "0",
    fontSize: "14px",
    padding: 0,
  },
});

const ModalDialogActions = styled(DialogActions)({
  justifyContent: "center",
  display: "flex",
});

const ExportButton = styled(Button)({
  fontWeight: "bolder",
  minWidth: "8rem",
  marginRight: "5%",
  boxShadow: "none",

  [mdDown]: {
    minWidth: "5rem",
  },

  [middleMobileDown]: {
    fontSize: "12px",
  },
});

const CloseButton = styled(Button)({
  fontWeight: "bolder",
  minWidth: "8rem",
  marginLeft: "10px",

  [mdDown]: {
    minWidth: "5rem",
  },

  [middleMobileDown]: {
    fontSize: "12px",
  },
});

const ExcelModal = ({
  openExcelModal,
  setOpenExcelModal,
  getExcelData,
  excelDataLength,
}) => {
  return (
    <Dialog
      open={openExcelModal}
      onClose={() => {
        setOpenExcelModal(false);
      }}
      PaperProps={{
        style: {
          backgroundColor: COLORS.white,
          height: "350px",
          width: "500px",
          padding: smDown ? "50px" : "20px",
        },
      }}
    >
      <ModalDialogTitle>Export to Excel</ModalDialogTitle>
      <ModalDialogContent>
        Are you sure that you want to export
        <b>
          {excelDataLength}{" "}
          {excelDataLength > 1 ? "demurrage requests" : "demurrage request"}
        </b>{" "}
        from the list to an excel file?
      </ModalDialogContent>

      <ModalDialogActions>
        <CSVLink
          data={getExcelData()}
          filename={`Demurrage list ${new Date().getFullYear()}/${
            new Date().getMonth() + 1
          }/${new Date().getDate()}`}
          style={{
            textDecoration: "none",
          }}
        >
          <ExportButton
            variant="contained"
            color="sunrise_orange"
            onClick={(e) => {
              setTimeout(() => {
                setOpenExcelModal(false);
              }, 500);
            }}
          >
            Export
          </ExportButton>
        </CSVLink>
        <CloseButton
          variant="outlined"
          color="sunrise_orange"
          onClick={(e) => {
            setOpenExcelModal(false);
          }}
        >
          Close
        </CloseButton>
      </ModalDialogActions>
    </Dialog>
  );
};

export default ExcelModal;
