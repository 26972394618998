import { APP_URL } from "../utils/constants";
import accountService from "../services/accountService";
import axios from "axios";
import Cookies from "js-cookie";
import alertService from "../services/alertService";

const fetchWrapper = {
  get,
  post,
  put,
  patch,
  delete: _delete,
  getFile,
};

async function get(url) {
  const requestOptions = {
    headers: authHeader(url),
  };
  try {
    const response = await axios.get(url, requestOptions);
    return response.data;
  } catch (error) {
    handleError(error);
  }
}

async function post(url, body) {
  const requestOptions = {
    headers: { "Content-Type": "application/json", ...authHeader(url) },
  };
  try {
    const response = await axios.post(url, body, requestOptions);
    return response.data;
  } catch (error) {
    handleError(error);
  }
}

async function put(url, body) {
  const requestOptions = {
    headers: { "Content-Type": "application/json", ...authHeader(url) },
  };
  try {
    const response = await axios.put(url, body, requestOptions);
    return response.data;
  } catch (error) {
    handleError(error);
  }
}

async function patch(url, body) {
  const requestOptions = {
    headers: { "Content-Type": "application/json", ...authHeader(url) },
  };
  try {
    const response = await axios.patch(url, body, requestOptions);
    return response.data;
  } catch (error) {
    handleError(error);
  }
}

// prefixed with underscored because delete is a reserved word in javascript
async function _delete(url, id) {
  const requestOptions = {
    headers: { "Content-Type": "application/json", ...authHeader(url) },
    data: { id },
  };
  try {
    const response = await axios.delete(url, requestOptions);
    return response.data;
  } catch (error) {
    handleError(error);
  }
}

async function getFile(url, data) {
  try {
    const response = await axios.post(url, data, {
      headers: { "Content-Type": "application/json", ...authHeader(url) },
      responseType: "blob",
    });
    return response.data;
  } catch (error) {
    handleError(error);
  }
}

function authHeader(url) {
  // return auth header with jwt if user is logged in and request is to the api url
  let accessToken = Cookies.get("access_token");
  const isLoggedIn = accessToken;
  const isApiUrl = url.startsWith(APP_URL);
  if (isLoggedIn && isApiUrl) {
    return { Authorization: `Bearer ${accessToken}` };
  } else {
    return {};
  }
}

function handleError(error) {
  if (
    error.response.status === 401 &&
    Cookies.get("access_token") !== undefined
  ) {
    alertService.error(
      error.response.status,
      "Your session has expired, login again"
    );
    global.setCookie();
    accountService.logout();
  } else throw error;
}

export default fetchWrapper;
