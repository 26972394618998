import React from "react";
import { Route, Redirect } from "react-router-dom";
import Cookies from "js-cookie";

const PrivateRoute = ({ setCookie, component: Component, roles, ...rest }) => {
  let cookie = Cookies.get("access_token");
  return (
    <Route
      {...rest}
      render={(props) => {
        if (!cookie) {
          setCookie();
          return (
            <Redirect
              to={{
                pathname: "/account/login",
                state: { from: props.location },
              }}
            />
          );
        }

        // if (roles && roles.indexOf(user.role) === -1) {
        //     return <Redirect to={{ pathname: '/' }} />
        // }

        return <Component {...props} />;
      }}
    />
  );
};

export default PrivateRoute;
