import { bg } from "date-fns/locale";
import styled from "styled-components";
import { CustomLoader } from "../CustomLoader";

const Primary = styled("button")(({ bgColor }) => ({
  color: "white",
  background: bgColor ?? "#EE4A26",
  borderRadius: "8px",
  border: "none",
  fontFamily: "Montserrat !important",
  fontWeight: "600",
  fontSize: "1rem",
  height: "2.625rem",
  "@media only screen and (max-width: 1400px) and (min-width: 1280px)": {
    height: "1.875rem",
    fontSize: "0.6875rem",
  },
  "&:hover": {
    background: bgColor ?? "#FF6B4A",
    boxShadow: "2px 2px 5px rgba(238, 74, 38, 0.25)",
  },
  "&:focus": {
    background: bgColor ?? "#C82C0A",
    outline: "none",
  },
  "&:disabled": {
    background: bgColor ?? "#EE4A26",
    opacity: 0.4,
  },
}));
const Secondary = styled("button")(() => ({
  color: "#EE4A26",
  background: "rgba(238, 74, 38, 0.2)",
  borderRadius: "8px",
  border: "none",
  fontFamily: "Montserrat !important",
  fontWeight: "600",
  height: "2.625rem",
  "@media only screen and (max-width: 1400px) and (min-width: 1280px)": {
    height: "1.875rem",
    fontSize: "0.6875rem",
  },
  "&:hover": {
    color: "#FF6B4A",
    boxShadow: "2px 2px 5px rgba(238, 74, 38, 0.25)",
  },
  "&:focus": {
    color: "#C82C0A",
    outline: "none",
  },
  "&:disabled": {
    opacity: 0.4,
  },
}));
const Link = styled("button")(() => ({
  color: "#EE4A26",
  background: "none",
  borderRadius: "8px",
  border: "none",
  fontFamily: "Montserrat !important",
  fontWeight: "600",
  height: "2.625rem",
  "@media only screen and (max-width: 1400px) and (min-width: 1280px)": {
    height: "1.875rem",
    fontSize: "0.6875rem",
  },
  "&:hover": {
    color: "#FF6B4A",
  },
  "&:focus": {
    color: "#C82C0A",
    outline: "none",
  },
  "&:disabled": {
    opacity: 0.4,
  },
}));

const Icon = styled("button")(() => ({
  display: "flex",
  color: "#EE4A26",
  background: "#FAFAFA",
  borderRadius: "8px",
  border: "none",
  fontFamily: "Montserrat !important",
  fontWeight: "600",
  "@media only screen and (max-width: 1400px) and (min-width: 1280px)": {
    fontSize: "0.6875rem",
  },
  "&:hover": {
    color: "#FF6B4A",
    boxShadow: "2px 2px 5px rgba(140, 140, 140, 0.15)",
  },
  "&:focus": {
    color: "#C82C0A",
    border: "1px solid rgba(140, 140, 140, 0.2)",
    outline: "none",
  },
  "&:disabled": {
    opacity: 0.4,
  },
}));

export const Button = ({
  text,
  type,
  onClick,
  css,
  icon,
  iconCss,
  disabled,
  bgColor,
  loading,
}) => {
  return (
    <>
      {type === "primary" ? (
        <Primary
          style={css}
          onClick={onClick}
          disabled={disabled}
          bgColor={bgColor}
        >
          {loading ? <CustomLoader loaderColor="#f1f1f1" size="3vh" /> : text}
        </Primary>
      ) : type === "secondary" ? (
        <Secondary style={css} onClick={onClick} disabled={disabled}>
          {text}
        </Secondary>
      ) : type === "link" ? (
        <Link style={css} onClick={onClick} disabled={disabled}>
          {text}
        </Link>
      ) : (
        <Icon style={css} onClick={onClick} disabled={disabled}>
          <img src={icon} style={iconCss} />
          {text}
        </Icon>
      )}
    </>
  );
};
