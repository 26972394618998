import alertService from "../../../services/alertService";
import { MCLOUD_APP_URL } from "../../../utils/constants";
import apiFactory from "../../Container/service/apiFactory";

export const kFormatNumber = (number) => {
  number = number / 1000;
  if (number >= 1000000) {
    return (number / 1000000).toFixed(1) + "M";
  } else if (number >= 1000) {
    return (number / 1000).toFixed(1) + "k";
  } else {
    return number?.toFixed(1);
  }
};
export async function toggleFavorite(offsetId) {
  await apiFactory
    .patch(
      `${MCLOUD_APP_URL}offset/favorite-offsets/?offset_id=${offsetId}`,
      {}
    )
    .then((resp) => {
      return resp;
    })
    .catch((error) => alertService.error(error.response.status));
}
const calculateAnualGrowthRate = (
  startEmission,
  endEmission,
  startYear,
  endYear
) => {
  let yearsDifference = endYear - startYear;
  return (endEmission / startEmission) ** (1 / yearsDifference) - 1;
};
// const calculateEmissionForYearByAnualGrowth = (
//   targetYear,
//   startYear,
//   emissionStartYear,
//   anualGrowthProjection
// ) => {
//   let yearDifference = targetYear - startYear;
//   return emissionStartYear * (1 + anualGrowthProjection) ** yearDifference;
// };
export const calculateGraphLines = (
  emission_2015,
  emission_2023,
  anual_growth_projection,
  target_2015_reduction_by_2030,
  anual_top_offset,
  net_0_target_year
) => {
  if (emission_2023 === 0 && emission_2015 === 0) {
    return;
  }
  anual_growth_projection = anual_growth_projection
    ? anual_growth_projection
    : calculateAnualGrowthRate(emission_2015, emission_2023, 2015, 2023);
  // red_line

  let redLine = {
    2023: emission_2023,
  };
  let anualGrowthRedLine = anual_growth_projection;

  for (let i = 2024; i < net_0_target_year + 1; ++i) {
    redLine[i] = redLine[i - 1] * (anualGrowthRedLine + 1.0);
  }
  if (target_2015_reduction_by_2030 === 0) {
    return {
      redLine: redLine,
      greenLine: {
        2015: 0.0,
        2023: 0.0,
        2030: 0.0,
        2050: 0.0,
      },
      blueLine: {
        2015: 0.0,
        2023: 0.0,
        2030: 0.0,
        2050: 0.0,
      },
    };
  }

  let greenLine = {
    2023: anual_top_offset ? redLine[2023] * anual_top_offset : 0,
  };
  let anualGrowthBlueLineTo2030 = calculateAnualGrowthRate(
    redLine[2023] - greenLine[2023],
    (1 - target_2015_reduction_by_2030) * emission_2015,
    2023,
    2030
  );

  let blueLine = {
    2023: redLine[2023] - greenLine[2023],
  };
  for (let i = 2024; i < 2031; ++i) {
    blueLine[i] = blueLine[i - 1] * (1.0 + anualGrowthBlueLineTo2030);
  }
  for (let i = 2024; i < 2031; ++i) {
    greenLine[i] = redLine[i] - blueLine[i];
  }
  let anualGrowthGreenLine = calculateAnualGrowthRate(
    greenLine[2030],
    redLine[net_0_target_year],
    2030,
    net_0_target_year
  );

  for (let i = 2031; i <= net_0_target_year; ++i) {
    greenLine[i] = greenLine[i - 1] * (1.0 + anualGrowthGreenLine);
  }
  for (let i = 2031; i <= net_0_target_year; ++i) {
    blueLine[i] = redLine[i] - greenLine[i];
  }
  return { blueLine: blueLine, greenLine: greenLine, redLine: redLine };
};
// export const normalizeArray = (arr) => {
//   let minValue = Math.min(...arr);
//   let maxValue = Math.max(...arr);

//   let delta = maxValue - minValue;

//   return arr.map((item) => {
//     return (item - minValue) / delta;
//   });
// };
// export const subtractArray = (arr1, arr2) => {
//   return arr1.map((item, index) => {
//     return Math.abs(item - arr2[index]);
//   });
// };
