import React, { useState, useEffect, useRef } from "react";
import { styled } from "@mui/material";
import arrowDown from "../../assets/img/arrowDown.svg";
import dateIcon from "../../assets/img/dateIcon.svg";
import { DateRangePicker } from "react-date-range";
import { useMediaQuery, useTheme, createTheme } from "@mui/material";
import moment from "moment";

const ValueDiv = styled("div")(() => ({
  width: "260px",
  height: "42px",
  background: "#FAFAFA",
  borderRadius: "8px",
  display: "flex",
  justifyContent: "space-between",
  border: "1px solid rgba(189, 189, 189, 0.4)",
  "&:hover": { border: "1px solid rgba(238, 74, 38, 0.2)" },
  "@media only screen and (max-width: 1400px) and (min-width: 1280px)": {
    height: "30px",
    width: "130px",
  },
}));

export default function DatePicker({
  value,
  onChange,
  placeholder,
  css,
  disabled,
}) {
  const [isOpen, setIsOpen] = useState(false);
  const wrapperRef = useRef(null);

  const theme1 = createTheme({
    breakpoints: {
      values: {
        sl: 1400,
      },
    },
  });

  const smallLaptop = useMediaQuery(theme1.breakpoints.down("sl"));

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const handleClickOutside = (event) => {
    const { current: wrap } = wrapperRef;
    if (wrap && !wrap.contains(event.target)) {
      setIsOpen(false);
    }
  };

  const toggleDatePickup = () => {
    if (!disabled) {
      setIsOpen(!isOpen);
    }
  };

  const handleSelect = (ranges) => {
    onChange({
      ...value,
      startDate: ranges.selection.startDate,
      endDate: ranges.selection.endDate,
    });
  };

  const formatDate = (date) => {
    return moment(new Date(date)).format("MM/DD/YYYY");
  };

  const displayValue = value?.startDate && value?.endDate
    ? `${formatDate(value.startDate)}-${formatDate(value.endDate)}`
    : placeholder;

  return (
    <div className="pos-rel" ref={wrapperRef}>
      <ValueDiv
        onClick={toggleDatePickup}
        style={{
          border: isOpen
            ? "1px solid #C82C0A"
            : "1px solid rgba(189, 189, 189, 0.4)",
          ...css,
        }}
      >
        <div style={{ display: "flex" }}>
          <img
            src={dateIcon}
            style={{
              marginLeft: "15px",
              height: "20px",
              width: smallLaptop ? "12px" : "18px",
              marginTop: smallLaptop ? "5px" : "10px",
            }}
          />
          <div
            className="selected-value"
            style={{
              paddingLeft: "10px",
              marginTop: smallLaptop ? "7px" : "8px",
              color: "#8C8C8C",
              fontSize: smallLaptop ? "11px" : "16px",
            }}
          >
            {displayValue}
          </div>
        </div>
        <img
          src={arrowDown}
          style={{
            marginRight: "15px",
            height: "8px",
            marginTop: smallLaptop ? "12px" : "17px",
          }}
        />
      </ValueDiv>
      {isOpen && (
        <div
          style={{
            top: "43px",
            overflow: "auto",
            position: "absolute",
            borderRadius: "8px 8px 8px 8px",
            border: "1px solid #EE4A26",
            backgroundColor: "#FAFAFA",
            zIndex: 1000,
          }}
        >
          <DateRangePicker
            ranges={[value]}
            onChange={handleSelect}
            months={2}
            direction="horizontal"
            rangeColors={["#FF6B4A", "green", "black", "blue"]}
            showMonthAndYearPickers={true}
            style={{ width: smallLaptop ? "200px" : "" }}
          />
        </div>
      )}
    </div>
  );
}
