import { BehaviorSubject } from "rxjs";
import { APP_URL } from "../utils/constants";
import Cookies from "js-cookie";
import fetchWrapper from "../helpers/fetch-wrapper";
import history from "../router/history";
const userSubject = new BehaviorSubject(null);
// const baseUrl = `${APP_URL}/api/accounts`

// let refreshTokenTimeout

const accountService = {
  login: async function (email, password) {
    let client_id = "clientId";
    let grant_type = "password";
    let client_secret = "clientSecret";

    const response = await fetchWrapper.post(`${APP_URL}/user/token/`, {
      grant_type,
      username: email,
      password,
      client_id,
      client_secret,
    });

    Cookies.set("access_token", response.access_token, { path: "/" });
    Cookies.set("refresh_token", response.refresh_token, { path: "/" });
    global.setCookie(response.access_token);
    const responseUser = await fetchWrapper.get(`${APP_URL}/user/myprofile/`);
    userSubject.next(responseUser);

    return responseUser;
    // this.startRefreshTokenTimer()
  },
  logout: async function () {
    // await fetchWrapper.post(`${baseUrl}/revoke-token`, {})
    // this.stopRefreshTokenTimer()
    Cookies.remove("access_token", { path: "/" });
    history.push("/account/login");
  },
  // refreshToken: async function () {
  //     const user = await fetchWrapper.post(`${baseUrl}/refresh-token`, {})
  //     userSubject.next(user)
  //     this.startRefreshTokenTimer()
  //     return user
  // },
  register: async function (params) {
    const newUser = await fetchWrapper.post(
      `${APP_URL}/shipper/signup/`,
      params
    );
    return newUser;
  },
  // verifyEmail: async function (token) {
  //     return await fetchWrapper.post(`${baseUrl}/verify-email`, { token })
  // },
  forgotPassword: async function (email) {
    return await fetchWrapper.post(`${APP_URL}/user/resetpassword/`, { email });
  },
  // validateResetToken: async function (token) {
  //     return await fetchWrapper.post(`${baseUrl}/validate-reset-token`, { token })
  // },
  changePassword: async function (data) {
    return await fetchWrapper.patch(`${APP_URL}/user/changepassword/`, data);
  },
  getAll: async function () {
    return await fetchWrapper.get(`${APP_URL}/user/`);
  },
  getAllShipperUsers: async function () {
    return await fetchWrapper.get(`${APP_URL}/shipper/users/`);
  },
  getById: async function (id) {
    return await fetchWrapper.get(`${APP_URL}/user/${id}/`);
  },
  create: async function (params) {
    const newUser = await fetchWrapper.post(`${APP_URL}/user/`, params);
    return newUser;
  },
  update: async function (params) {
    const user = await fetchWrapper.patch(`${APP_URL}/user/myprofile/`, params);
    return user;
  },
  delete: async function (id) {
    const userProfile = JSON.parse(localStorage.getItem("currentUser"));
    const deletedUser = await fetchWrapper.delete(`${APP_URL}/user/`, id);
    if (id === userProfile.id) {
      //   logout();
    }
    return deletedUser;
  },
  user: userSubject.asObservable(),
  get userValue() {
    return userSubject.value;
  },
  // startRefreshTokenTimer: function () {
  //     const jwtToken = JSON.parse(atob(userSubject.value.jwtToken.split('.')[1]))

  //     const expires = new Date(jwtToken.exp * 1000)
  //     const timeout = expires.getTime() - Date.now() - 60 * 1000
  //     refreshTokenTimeout = setTimeout(this.refreshToken, timeout)
  // },
  // stopRefreshTokenTimer: function () {
  //     clearTimeout(refreshTokenTimeout)
  // },
};

export default accountService;
