import React, { useEffect, useState } from "react";

import useStore from "../../store/store";

import "./styles/containerDetails.scss";
import TrackingMap from "./components/Map/TrackingMap";
import { CONTAINER_URL } from "../../utils/constants";
import ContainerStatistics from "./components/ContainerStatistics";
import Filters from "./components/Header/Filters";
import Notifications from "./components/Header/Notifications";
import WatchList from "./components/WatchList/WatchList";

import PayModal from "./modals/PayDetailsModal";
import DetentionModal from "./modals/DetentionModal";

import AlertList from "./components/Alert/AlertList";
import PortStatistics from "./components/statistics_components/PortStatistics";
import { colorDict } from "./utils/contants";
import {
  HaversineDistance,
  checkForLabel,
  getGroupedData,
  getGroupedDrillDownData,
} from "./utils/functions";
import DetailsTable from "./components/DetailsTable/DetailsTable";
import SearchHeader from "./components/DetailsTable/SearchHeader";
import { useQuery } from "react-query";
import apiFactory from "./service/apiFactory";
import alertService from "../../services/alertService";
import { saveAs } from "file-saver";
import { createTheme, useMediaQuery } from "@mui/material";
import containerStore from "../../store/containerStore";

export default function ContainersDetails() {
  const [containers, setContainers] = useState([]);
  const [statistics, setStatistics] = useState({});

  const [mainColor, setMainColor] = useState("");

  const [router, setRouter] = useState("");

  const [filterParams, setFilterParams] = useState("");
  const [journeyStatus, setJourneyStatus] = useState("");
  const [chartStatusFilter, setChartStatusFilter] = useState("");
  const [chartRiskFilter, setChartRiskFilter] = useState("");
  const [statisticFilter, setStatisticFilter] = useState("");
  const [riskFilter, setRiskFilter] = useState("");
  const [containersPerStatusMaxValue, setContainersPerStatusMaxValue] =
    useState("");
  const [queryParams, setQueryParams] = useState("");

  const [alertNumbering, setAlertNumbering] = useState({});

  const [redirect, setRedirect] = useState(true);
  const [loadingExcel, setLoadingExcel] = useState(false);

  const [clusterData, setClusterData] = useState([]);

  const [showTable, setShowTable] = useState(false);

  const [resetParams, setResetParams] = useState(false);

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isDetentionModalOpen, setIsDetentionModalOpen] = useState(false);

  const setNavbarLabel = useStore((state) => state.setNavbarLabel);
  const currentDisplayContainer = containerStore(
    (state) => state.currentDisplayContainer
  );
  const setCurrentDisplayContainer = containerStore(
    (state) => state.setCurrentDisplayContainer
  );

  const getContainerId = containerStore((state) => state.getContainerId);
  const setGetContainerId = containerStore((state) => state.setGetContainerId);
  const notificationContainerId = containerStore(
    (state) => state.notificationContainerId
  );
  const setFetchNotifications = useStore(
    (state) => state.setFetchNotifications
  );
  const containerUrlRouter = containerStore(
    (state) => state.containerUrlRouter
  );
  const setContainerUrlRouter = containerStore(
    (state) => state.setContainerUrlRouter
  );
  const theme1 = createTheme({
    breakpoints: {
      values: {
        bs: 2000,
      },
    },
  });
  const bigScreen = useMediaQuery(theme1.breakpoints.up("bs"));

  const prev_data = ["states", "cities", "terminals"];
  // URL formater useEffect
  useEffect(() => {
    setContainers([]);
    setCurrentDisplayContainer({});
    if (!notificationContainerId) {
      setGetContainerId("");
    }

    let url_start = "visibility/view";
    if (filterParams) {
      setRouter("filter");
      url_start = "container/filter";
    }
    if (containerUrlRouter.length > 0) {
      let labelData = checkForLabel(containerUrlRouter.at(-1));
      if (labelData.length > 0) {
        setNavbarLabel({
          label: labelData.at(0),
          is_function: true,
          f: () => {
            setContainerUrlRouter([]);
          },
        });
        setRouter(labelData.at(1));
      } else if (containerUrlRouter.at(-1).includes("&terminals=")) {
        setRouter("terminal");
      }
    } else {
      setNavbarLabel({});
    }
    let query = `${filterParams}${
      filterParams?.includes("journey_statuses") ? "" : chartStatusFilter
    }${chartRiskFilter}`;
    if (
      containerUrlRouter.at(-1) !== undefined &&
      !containerUrlRouter.at(-1).startsWith("visibility/favorite") &&
      !containerUrlRouter.at(-1).startsWith("visibility/alert_containers") &&
      !containerUrlRouter.at(-1).includes("redirect") &&
      redirect
    ) {
      query += `&redirect=${true}`;
    }
    if (
      containerUrlRouter.at(-1) &&
      containerUrlRouter.at(-1).startsWith("visibility/alert_containers") &&
      filterParams
    ) {
      setQueryParams(containerUrlRouter.at(-1) + query.replace("&", "?"));
    } else {
      setQueryParams(
        containerUrlRouter.at(-1)
          ? containerUrlRouter.at(-1) + query
          : url_start + query.replace("&", "?")
      );
    }

    if (!redirect) {
      setRedirect(true);
    }
  }, [
    containerUrlRouter,
    chartStatusFilter,
    chartRiskFilter,
    filterParams,
    setNavbarLabel,
    redirect,
  ]);
  // Data getter
  const { isLoading, data } = useQuery({
    queryKey: ["containersData", queryParams],
    queryFn: () => {
      if (queryParams) {
        return apiFactory
          .get(`${CONTAINER_URL}${queryParams}`)
          .then((res) => res.data);
      }
    },
    staleTime: 0,
    cacheTime: 0,
    retry: false,
  });

  const { isLoading: loadingContainer, data: detailsResponse } = useQuery({
    queryKey: ["displayingContainers", getContainerId, data],
    queryFn: () => {
      if (getContainerId && data) {
        setCurrentDisplayContainer({});
        return apiFactory
          .get(
            `${CONTAINER_URL}container/detail?container_ids=${getContainerId}`
          )
          .then((res) => res.data);
      }
    },
  });
  useEffect(() => {
    if (detailsResponse && Object.keys(detailsResponse).length > 0) {
      let resp = detailsResponse.at(0);
      let index = -1;
      if (containers.at(0)?.group) {
        containers.forEach((item) => {
          item.group.forEach((cont) => {
            if (cont.container_id === getContainerId) {
              index = cont.index;
            }
          });
        });
      } else {
        index = 1;
      }

      setCurrentDisplayContainer({
        ...detailsResponse.at(0),
        coordinates: {
          lng: resp.lng ?? resp?.current_event?.lng,
          lat: resp.lat ?? resp?.current_event?.lat,
        },
        color: colorDict[resp?.current_event?.status],
        whole_route: false,
        icon: getIcon(
          resp?.current_event?.transport_type,
          resp?.current_event?.type
        ),
        index: index,
      });
    } else {
      setCurrentDisplayContainer({});
    }
  }, [detailsResponse]);

  async function handleFavorite(containerId) {
    await apiFactory.patch(
      `${CONTAINER_URL}visibility/favorite?container_id=${containerId}`,
      {}
    );
  }

  async function handleRead(containerId) {
    await apiFactory
      .put(`${CONTAINER_URL}visibility/read?container_id=${containerId}`, {})
      .then((resp) => {
        setFetchNotifications(true);
      });
  }
  async function getExcelData() {
    setLoadingExcel(true);
    await apiFactory
      .getFile(
        `${CONTAINER_URL}container/excel?${queryParams.split("?").at(-1)}`
      )
      .then((resp) => {
        saveAs(resp, "Container Details");
        setLoadingExcel(false);
      })
      .catch((error) => {
        setLoadingExcel(false);
        alertService.error(error?.response?.status);
      });
  }
  async function getAlertNumbering() {
    await apiFactory
      .get(`${CONTAINER_URL}visibility/notifications_numbers`)
      .then((resp) => {
        setAlertNumbering(resp.data);
      });
  }
  const getNavbarLabel = (dataContext) => {
    if (dataContext.terminals.length > 0) {
      return dataContext.terminals.join(" &");
    }
    if (dataContext.cities.length > 0) {
      return dataContext.cities.join(" & ");
    } else {
      return dataContext.states.join(" & ");
    }
  };
  // Data handler
  useEffect(() => {
    if (data !== undefined) {
      getAlertNumbering();
      if (data?.context) {
        if (
          data?.data.length === 1 &&
          data?.data.at(0).next.startsWith("container/filter")
        ) {
          setContainerUrlRouter(
            containerUrlRouter.slice(0, -1).concat([data.data.at(0).next])
          );
          return;
        }
        let group_distance = 100;
        if (window.google) {
          const bounds = new window.google.maps.LatLngBounds();
          data.data.forEach((item) => {
            bounds.extend({
              lat: item.lat ?? item.current_event.lat,
              lng: item.lat ?? item.current_event.lng,
            });
          });

          group_distance =
            HaversineDistance(
              {
                lat: bounds.getNorthEast().lat(),
                lng: bounds.getNorthEast().lng(),
              },
              {
                lat: bounds.getSouthWest().lat(),
                lng: bounds.getSouthWest().lng(),
              }
            ) / 20;
        }
        let d1 = getGroupedDrillDownData(
          data.data.map((item) => {
            return {
              name: item.name,
              coordinates: {
                lat: item?.lat ?? item.current_event.lat,
                lng: item?.lng ?? item.current_event.lng,
              },
              number: item.number,
              ianaCode: item?.iana_code,
              color: colorDict[item?.current_event?.status],
              next: item.next,
              type_of_next: item.type_of_next,
              type_of_prev: item.type_of_next
                ? prev_data[prev_data.indexOf(item.type_of_next) - 1]
                : prev_data.at(-1),
            };
          }),
          group_distance
        );
        if (containerUrlRouter.length > 0) {
          setNavbarLabel({
            label: getNavbarLabel(data.context.filters),
            is_function: true,
            f: () => {
              setContainerUrlRouter(containerUrlRouter.slice(0, -1));
            },
          });
        }
        setCurrentDisplayContainer({});
        setContainers(d1);
        setStatistics(data.statistics);

        setRouter("");
      } else {
        if (data.data.length > 0) {
          if (
            data?.statistics?.terminal_labels &&
            data?.statistics?.terminal_labels.length > 0
          ) {
            setNavbarLabel({
              label: data?.statistics?.terminal_labels
                ? data?.statistics?.terminal_labels.join(" & ")
                : ".",
              is_function: true,
              f: () => {
                setContainerUrlRouter(containerUrlRouter.slice(0, -1));
              },
            });
          }

          let d1 = getGroupedData(
            data.data.map((item) => {
              return {
                ...item,
                coordinates: {
                  lng: item.lng ?? item?.current_event?.lng,
                  lat: item.lat ?? item?.current_event?.lat,
                },
                name: item.container_id,
                number: 1,
                color: colorDict[item?.current_event?.status],
                icon: getIcon(
                  item?.current_event?.transport_type,
                  item?.current_event?.type
                ),
              };
            })
          );
          setContainers(d1);
          if (data.data.length === 1) {
            setShowTable(true);
          }

          setStatistics(data.statistics ?? {});
        } else {
          alertService.info("No containers available");
          if (
            containerUrlRouter.at(-1) &&
            containerUrlRouter.at(-1).startsWith("visibility/alert_containers")
          ) {
            setQueryParams(queryParams.split("?").at(0));
            setResetParams((prev) => !prev);
          } else {
            setContainerUrlRouter([]);
          }
        }
      }
    }
  }, [data]);

  const getIcon = (transport_type, type) => {
    return transport_type === "VESSEL"
      ? "ship"
      : transport_type === "TRUCK"
      ? "truck"
      : transport_type === "RAIL"
      ? "train"
      : transport_type === null && type === "sea"
      ? "ship"
      : transport_type === null && type === "land"
      ? "truck"
      : "";
  };
  return (
    // <div className="containerZoom">
    <div className="vis_main_container">
      <PayModal
        isModalOpen={isModalOpen}
        setIsModalOpen={setIsModalOpen}
        data={currentDisplayContainer}
        loadingContainer={loadingContainer}
      />
      <DetentionModal
        isDetentionModalOpen={isDetentionModalOpen}
        setIsDetentionModalOpen={setIsDetentionModalOpen}
        data={currentDisplayContainer}
        loadingContainer={loadingContainer}
      />
      <div className="header">
        <Filters
          resetParams={resetParams}
          statisticFilter={statisticFilter}
          setStatisticFilter={setStatisticFilter}
          watchList={router === "watch_list"}
          setQueryParams={setFilterParams}
          getExcelData={getExcelData}
          loadingExcel={loadingExcel}
          journeyStatus={journeyStatus}
          setJourneyStatus={setJourneyStatus}
          setChartStatusFilter={setChartStatusFilter}
          setMainColor={setMainColor}
        />
        <Notifications alertNumbering={alertNumbering} />
      </div>

      <div className="body">
        <div
          style={{
            width:
              router === ""
                ? bigScreen
                  ? "80%"
                  : "70%"
                : router !== "filter"
                ? "50%"
                : showTable
                ? "50%"
                : bigScreen
                ? "80%"
                : "70%",
            transition: "0.3s all ease-in-out",
            // height: "630px",
          }}
        >
          <div style={{ height: "100%", width: "100%" }}>
            {router === "terminal" && (
              <PortStatistics
                statistics={statistics}
                loading={isLoading}
                containers={containers}
              />
            )}
            <TrackingMap
              mainColor={mainColor}
              data={containers}
              isTerminalView={router === "terminal"}
              isDetailView={
                router === "filter" ||
                router === "watch_list" ||
                router === "alert" ||
                router === "filter"
              }
              loading={isLoading}
              setClusterData={setClusterData}
              setShowTable={setShowTable}
            />
          </div>
        </div>

        {router === "watch_list" ? (
          <WatchList
            data={containers}
            handleFavorite={handleFavorite}
            clusterData={clusterData}
            loading={isLoading}
            loadingContainer={loadingContainer}
            setIsModalOpen={setIsModalOpen}
            setIsDetentionModalOpen={setIsDetentionModalOpen}
          />
        ) : router === "alert" ? (
          <AlertList
            loading={isLoading}
            containers={containers}
            handleFavorite={handleFavorite}
            handleRead={handleRead}
            loadingContainer={loadingContainer}
            setIsModalOpen={setIsModalOpen}
            setIsDetentionModalOpen={setIsDetentionModalOpen}
            clusterData={clusterData}
            setClusterData={setClusterData}
          />
        ) : router === "terminal" ? (
          <div
            style={{
              width: "50%",
              paddingLeft: "16px",
              display: "flex",
              flexDirection: "column",
            }}
          >
            <DetailsTable
              containers={containers}
              handleFavorite={handleFavorite}
              clusterData={clusterData}
              loading={isLoading}
              loadingContainer={loadingContainer}
              setIsModalOpen={setIsModalOpen}
              setIsDetentionModalOpen={setIsDetentionModalOpen}
              isTerminalView={router === "terminal"}
            />
          </div>
        ) : router === "filter" ? (
          <div
            style={{
              width: showTable ? "50%" : bigScreen ? "20%" : "30%",
              paddingLeft: "16px",
              display: "flex",
              flexDirection: "column",
            }}
          >
            <SearchHeader
              setShowTable={setShowTable}
              showTable={showTable}
              setClusetData={setClusterData}
            />
            {showTable ? (
              <DetailsTable
                containers={containers}
                handleFavorite={handleFavorite}
                clusterData={clusterData}
                getContainerId={getContainerId}
                loading={isLoading}
                loadingContainer={loadingContainer}
                setIsModalOpen={setIsModalOpen}
                setIsDetentionModalOpen={setIsDetentionModalOpen}
              />
            ) : (
              <ContainerStatistics
                chartStatusFilter={chartStatusFilter}
                setChartStatusFilter={setChartStatusFilter}
                setChartRiskFilter={setChartRiskFilter}
                statistics={statistics}
                loading={isLoading}
                setMainColor={setMainColor}
                setRedirect={setRedirect}
                filterParams={filterParams}
                statisticFilter={statisticFilter}
                setStatisticFilter={setStatisticFilter}
                riskFilter={riskFilter}
                setRiskFilter={setRiskFilter}
                showTable={showTable}
                setJourneyStatus={setJourneyStatus}
                containersPerStatusMaxValue={containersPerStatusMaxValue}
                setContainersPerStatusMaxValue={setContainersPerStatusMaxValue}
              />
            )}
          </div>
        ) : (
          <div style={{ width: bigScreen ? "20%" : "30%", marginLeft: "16px" }}>
            <ContainerStatistics
              chartStatusFilter={chartStatusFilter}
              setChartStatusFilter={setChartStatusFilter}
              setChartRiskFilter={setChartRiskFilter}
              statistics={statistics}
              loading={isLoading}
              setMainColor={setMainColor}
              setRedirect={setRedirect}
              filterParams={filterParams}
              statisticFilter={statisticFilter}
              setStatisticFilter={setStatisticFilter}
              riskFilter={riskFilter}
              setRiskFilter={setRiskFilter}
              setJourneyStatus={setJourneyStatus}
              containersPerStatusMaxValue={containersPerStatusMaxValue}
              setContainersPerStatusMaxValue={setContainersPerStatusMaxValue}
            />
          </div>
        )}
      </div>
    </div>
  );
}
