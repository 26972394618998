import React from "react";
import {
  Box,
  styled,
  Typography,
  useMediaQuery,
  useTheme,
  createTheme,
} from "@mui/material";
import DraftsIcon from "@mui/icons-material/Drafts";
import ThumbUpIcon from "@mui/icons-material/ThumbUp";
import WalletIcon from "@mui/icons-material/Wallet";
import RequestQuoteIcon from "@mui/icons-material/RequestQuote";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import ThumbDownIcon from "@mui/icons-material/ThumbDown";
import { COLORS } from "../../../styles/themes/default/colors";
import moment from "moment";
import Tooltip from "../../../components/Tooltip";

const statusTooltipTexts = {
  Opened:
    "CDL created a demurrage request. The request is editable until the status is changed or submitted to a customer.",
  Submited:
    "CDL created and submitted a demurrage request to a Customer. The request cannot be edited anymore.",
  ApprovedWaiting:
    "The status of the demurrage request payment is either approved or rejected by the Approval Authority.",
  Approved:
    "The status of the demurrage request payment is approved by the approval authority.",
  DemurrageCleared:
    "CDL made a payment for demurrage charges. Payment receipts are in the request’s attachment.",
  Completed: "Demurrage is fully repaid and the demurrage request is closed.",
  Rejected:
    "The status of the demurrage request payment is rejected by the approval authority.",
  Invoiced:
    "CDL invoiced the customer for paid demurrage charges and the repayment period started. The invoices are in the request’s attachment. ",
};

const theme1 = createTheme({
  breakpoints: {
    values: {
      xs: 0,
      smallMobile: 321,
      mobile: 376,
      middleMobile: 407,
      sm: 600,
      md: 900,
      lg: 1200,
      xl: 1536,
    },
  },
});

const smallMobileDown = theme1.breakpoints.down("smallMobile");
const mobileDown = theme1.breakpoints.down("mobile");
const middleMobileDown = theme1.breakpoints.down("middleMobile");
const smDown = theme1.breakpoints.down("sm");
const mdDown = theme1.breakpoints.down("md");
const lgDown = theme1.breakpoints.down("lg");
const xlDown = theme1.breakpoints.down("xl");

const StatusIcon = styled(Box)(({ theme }) => ({
  backgroundColor: "#DEDDDC",
  width: 50,
  height: 50,
  borderRadius: 25,
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  [mdDown]: {
    width: 45,
    height: 45,
  },
  [smDown]: {
    width: 30,
    height: 30,
  },
}));
const Line = styled(Box)(({ theme }) => ({
  backgroundColor: "#DEDDDC",
  width: "5px",
  height: "5.5rem",

  [mdDown]: {
    height: "3px",
    width: "10%",
  },
  [smDown]: {
    width: "1%",
  },
}));

const StatusBar = ({ status, date }) => {
  const theme = useTheme();
  const mdUp = useMediaQuery(theme.breakpoints.up("md"));
  const smUp = useMediaQuery(theme.breakpoints.up("sm"));

  const LineWithTitle = (text, withoutLine = false) => {
    let displayDate;
    if (date) {
      displayDate = moment(new Date(date)).format("MMM DD, YYYY");
    } else {
      displayDate = moment(new Date()).format("MMM DD, YYYY");
    }

    return (
      <Box
        style={{
          height: mdUp ? "5.5rem" : "5px",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
          margin: 0,
        }}
      >
        <>
          <Typography
            style={{
              fontSize: "13px",
              marginTop: "2px",
              fontWeight: 500,
              textAlign: "center",
            }}
          >
            {text}
          </Typography>
          <Typography
            style={{
              fontSize: "13px",
              fontWeight: 500,
              textAlign: "center",
            }}
          >
            {displayDate}
          </Typography>
        </>

        {!withoutLine && <Line />}
      </Box>
    );
  };

  const iconStyle = {
    color: COLORS.white,
    width: mdUp ? 30 : smUp ? 25 : 22,
    height: mdUp ? 30 : smUp ? 25 : 22,
  };

  const progress =
    status === "OP" || status === "SA" || status === ""
      ? 0
      : status === "AP"
      ? 1
      : status === "PA"
      ? 2
      : status === "IN"
      ? 3
      : status === "CO"
      ? 4
      : -1;

  return (
    <Box
      style={{
        display: "flex",
        flexDirection: mdUp ? "column" : "row",
        alignItems: "center",
        justifyContent: "center",
        marginLeft: mdUp ? "9px" : 0,
        width: "100%",
      }}
    >
      <StatusIcon
        sx={{
          backgroundColor: progress >= -1 ? COLORS.sunrise_orange : "#DEDDDC",
        }}
        className="demurrage-status"
      >
        <Tooltip
          text={statusTooltipTexts.Opened}
          color={COLORS.forest_green}
          type={"Demurrage status"}
          left={"-100px"}
        />
        <DraftsIcon style={iconStyle} />
      </StatusIcon>
      {progress === 0 && mdUp ? (
        LineWithTitle(status === "OP" || status === "" ? "Opened" : "Saved")
      ) : (
        <Line
          sx={{
            backgroundColor:
              progress >= 1 || progress === -1
                ? COLORS.sunrise_orange
                : "#DEDDDC",
          }}
        />
      )}
      {status === "RE" ? (
        <>
          <StatusIcon
            disabled
            sx={{
              backgroundColor:
                progress >= -1 ? COLORS.sunrise_orange : "#DEDDDC",
            }}
            className="demurrage-status"
          >
            <Tooltip
              text={statusTooltipTexts.Rejected}
              color={COLORS.forest_green}
              type={"Demurrage status"}
              left={"-100px"}
            />
            <ThumbDownIcon style={iconStyle} />
          </StatusIcon>
          {mdUp && LineWithTitle("Rejected", true)}
        </>
      ) : (
        <>
          <StatusIcon
            disabled
            sx={{
              backgroundColor:
                progress >= 1 ? COLORS.sunrise_orange : "#DEDDDC",
            }}
            className="demurrage-status"
          >
            <Tooltip
              text={
                status === "AP" ||
                status === "PA" ||
                status === "CO" ||
                status === "IN"
                  ? statusTooltipTexts.Approved
                  : statusTooltipTexts.ApprovedWaiting
              }
              color={
                status === "AP" ||
                status === "PA" ||
                status === "CO" ||
                status === "IN"
                  ? COLORS.forest_green
                  : COLORS.tooltip_gray
              }
              type={"Demurrage status"}
              left={"-100px"}
            />
            <ThumbUpIcon style={iconStyle} />
          </StatusIcon>
          {progress === 1 && mdUp ? (
            LineWithTitle("Approved")
          ) : (
            <Line
              sx={{
                backgroundColor:
                  progress >= 2 ? COLORS.sunrise_orange : "#DEDDDC",
              }}
            />
          )}

          <StatusIcon
            sx={{
              backgroundColor:
                progress >= 2 ? COLORS.sunrise_orange : "#DEDDDC",
            }}
            className="demurrage-status"
          >
            <Tooltip
              text={statusTooltipTexts.DemurrageCleared}
              color={
                status === "PA" || status === "CO" || status === "IN"
                  ? COLORS.forest_green
                  : COLORS.tooltip_gray
              }
              left={
                status === "PA" || status === "CO" || status === "IN"
                  ? "-100px"
                  : "-100px"
              }
              type={"Demurrage status"}
            />
            <WalletIcon style={iconStyle} />
          </StatusIcon>
          {progress === 2 && mdUp ? (
            LineWithTitle("Demurrage Cleared")
          ) : (
            <Line
              sx={{
                backgroundColor:
                  progress >= 3 ? COLORS.sunrise_orange : "#DEDDDC",
              }}
            />
          )}

          <StatusIcon
            sx={{
              backgroundColor:
                progress >= 3 ? COLORS.sunrise_orange : "#DEDDDC",
            }}
            className="demurrage-status"
          >
            <Tooltip
              text={statusTooltipTexts.Invoiced}
              color={
                status === "CO" || status === "IN"
                  ? COLORS.forest_green
                  : COLORS.tooltip_gray
              }
              left={"-100px"}
              type={"Demurrage status"}
            />
            <RequestQuoteIcon style={iconStyle} />
          </StatusIcon>
          {progress === 3 && mdUp ? (
            LineWithTitle("CDL invoiced")
          ) : (
            <Line
              sx={{
                backgroundColor:
                  progress >= 4 ? COLORS.sunrise_orange : "#DEDDDC",
              }}
            />
          )}

          <StatusIcon
            sx={{
              backgroundColor:
                progress >= 4 ? COLORS.sunrise_orange : "#DEDDDC",
            }}
            className="demurrage-status"
          >
            <Tooltip
              text={statusTooltipTexts.Completed}
              color={
                status === "CO" ? COLORS.forest_green : COLORS.tooltip_gray
              }
              left={"-100px"}
              type={"Demurrage status"}
            />
            <CheckCircleIcon style={iconStyle} />
          </StatusIcon>
        </>
      )}
    </Box>
  );
};

export default StatusBar;
