import React, { useEffect, useState } from "react";

import ReactApexChart from "react-apexcharts";

import { COLORS } from "../../../../styles/themes/default/colors";

import { useMediaQuery, createTheme } from "@mui/material";
import Skeleton from "react-loading-skeleton";
import "../../styles/gaugeBarStatistics.scss";
import "../../styles/donutStatistics.scss";
import { kFormatter } from "../../../../utils/constants";

const DonutBarStatistics = ({
  statistics,
  loading,
  colors,
  setChartRiskFilter,
  setRiskFilter,
  riskFilter,
  setRedirect,
}) => {
  const theme1 = createTheme({
    breakpoints: {
      values: {
        sl: 1400,
        bs: 2000,
      },
    },
  });
  const [options, setOptions] = useState({ series: [], options: {} });
  const [chartKey, setChartKey] = useState(0);
  useEffect(() => {
    setOptions({
      series: statistics
        ? riskFilter
          ? [

            riskFilter === "Due" && statistics.total_current,
            riskFilter === "Risk" && statistics.total_potential,
            riskFilter === "Free" && statistics.free_days_total
          ]
          : [
            statistics.total_current,
            statistics.total_potential,
            statistics.free_days_total,
          ]
        : [5, 5, 15],

      options: {
        chart: {
          padding: {
            left: 0, // Set the left padding to 0
            right: 0, // Set the right padding to 0
            top: 0, // Set the top padding to 0
            bottom: 0,
          },
          type: "donut",
          // offsetY: -20,
          sparkline: {
            enabled: false,
          },

          events: {
            dataPointSelection: (event, chartContext, config) => {
              if (!riskFilter) {
                setChartRiskFilter(
                  `&dnd_statuses=${riskMapping[config.dataPointIndex]}`
                );
                setRedirect(false);
                setRiskFilter(riskMapping[config.dataPointIndex]);
              }
            },
          },
        },
        plotOptions: {
          pie: {
            // offsetY: 20,
            donut: {
              size: "85%",
              // labels: {
              //   show: true,

              //   value: {
              //     show: true,
              //     offsetY: -3,
              //   },
              //   total: {
              //     show: true,
              //     label: "D&D risk",
              //     color: COLORS.dark_grey,
              //     offsetY: -10,
              //   },
              // },
              labels: {
                show: true,
                name: {
                  show: true,
                  fontWeight: 600,
                  offsetY: 20,
                  formatter: function (val) {
                    return val;
                  },
                },
                value: {
                  show: true,
                  fontWeight: 400,
                  offsetY: -20,
                },
                total: {
                  show: true,
                  showAlways: true,
                  label: "Due & Risk",
                  fontSize: "13px",
                  fontWeight: 400,
                  color: COLORS.dark_grey,
                  formatter: function (w) {
                    return `$${kFormatter(
                      statistics?.current + statistics?.potential
                    )}`;
                  },
                },
              },
            },
          },
        },
        tooltip: {
          enabled: false,
        },
        fill: {
          colors: displayColors,
        },
        stroke: {
          width: 0,
          stroke: 10,
        },
        dataLabels: {
          enabled: false,
        },

        legend: {
          show: false,
        },
        grid: {
          padding: {
            bottom: 0,
          },
        },
      },
    });

    setChartKey((prev) => prev + 1);
  }, [statistics]);
  // const smallLaptop = useMediaQuery(theme1.breakpoints.down("sl"));
  const bigScreen = useMediaQuery(theme1.breakpoints.up("bs"));
  const displayColors =
    colors && colors.length > 0
      ? colors
      : riskFilter
        ? [
          riskFilter === "Due" ? "#FF1DA4" : "transparent",
          riskFilter === "Risk" ? "#41BAEE" : "transparent",
          riskFilter === "Free" ? "#51DA87" : "transparent",
        ]
        : ["#FF1DA4", "#41BAEE", "#51DA87"];

  const riskMapping = ["Due", "Risk", "Free"];

  const handleClickLabel = (label) => {
    if (!riskFilter) {
      setChartRiskFilter(`&dnd_statuses=${label}&redirect_disabled=${true}`);
      setRedirect(false);

      setRiskFilter(label);
    }
  };
  return (
    <div className="donut_chart_div">
      {loading ? (
        <>
          <Skeleton
            circle
            width={130}
            height={120}
            style={{ marginLeft: "155px" }}
          />
        </>
      ) : (
        <div>
          <div className="donut_chart_main_box">
            <div className="donut_chart_box">
              <ReactApexChart
                key={chartKey}
                options={options.options}
                series={options.series}
                type="donut"
                height="190px"
                width="100%"
              />
            </div>
          </div>
          <div className="donut_chart_legend_box">
            {(!riskFilter || riskFilter === "Due") && (
              <div
                className="flex_box_legend"
                onClick={() => handleClickLabel("Due")}
              >
                <div style={{ display: "flex", alignItems: "center" }}>
                  <div
                    className="gauge_bar_legend_circle"
                    style={{
                      backgroundColor: colors ? colors.at(0) : COLORS.purple,
                    }}
                  />
                  <p className="gauge_bar_legend_label">Due</p>
                </div>
                <p className="gauge_bar_legend_value">
                  {statistics?.total_current}
                </p>
              </div>
            )}
            {(!riskFilter || riskFilter === "Risk") && (
              <div
                className="flex_box_legend"
                onClick={() => {
                  handleClickLabel("Risk");
                }}
              >
                <div style={{ display: "flex", alignItems: "center" }}>
                  <div
                    className="gauge_bar_legend_circle"
                    style={{
                      backgroundColor: colors ? colors.at(1) : "#41BAEE",
                    }}
                  />

                  <p className="gauge_bar_legend_label">At risk </p>
                </div>
                <p className="gauge_bar_legend_value">
                  {statistics?.total_potential}
                </p>
              </div>
            )}
            {(!riskFilter || riskFilter === "Free") && (
              <div
                className="flex_box_legend"
                onClick={() => {
                  handleClickLabel("Free");
                }}
              >
                <div style={{ display: "flex", alignItems: "center" }}>
                  <div
                    className="gauge_bar_legend_circle"
                    style={{
                      backgroundColor: colors ? colors.at(2) : "#51DA87",
                    }}
                  />

                  <p className="gauge_bar_legend_label">Free </p>
                </div>
                <p className="gauge_bar_legend_value">
                  {statistics?.free_days_total}
                </p>
              </div>
            )}
          </div>
        </div>
      )}
    </div>
  );
};

export default DonutBarStatistics;
