import React, { useEffect, useState } from "react";

import ReactApexChart from "react-apexcharts";

import { COLORS } from "../../../../styles/themes/default/colors";

import Skeleton from "react-loading-skeleton";
import { useMediaQuery, createTheme } from "@mui/material";
import "../../styles/gaugeBarStatistics.scss";

const GaugeBarStatistics = ({
  statistics,
  loading,
  colors,
  labels,
  values,
}) => {
  const theme1 = createTheme({
    breakpoints: {
      values: {
        sl: 1400,
      },
    },
  });

  const [options, setOptions] = useState({ series: [], options: {} });
  const [chartKey, setChartKey] = useState(0);
  const smallLaptop = useMediaQuery(theme1.breakpoints.down("sl"));
  useEffect(() => {
    setOptions({
      series:
        values && values.length > 0
          ? [(values.at(0) * 100) / (values.at(0) + values.at(1))]
          : statistics?.total_current +
            statistics?.total_potential +
            statistics?.free_days_total >
            0
            ? [
              (statistics?.total_current /
                (statistics?.total_current +
                  statistics?.total_potential +
                  statistics?.free_days_total)) *
              100,
              (statistics?.total_potential /
                (statistics?.total_current +
                  statistics?.total_potential +
                  statistics?.free_days_total)) *
              100,
              (statistics?.free_days_total /
                (statistics?.total_current +
                  statistics?.total_potential +
                  statistics?.free_days_total)) *
              100,
            ]
            : [65],
      // colors: ["#FF1DA4"],
      options: {
        chart: {
          type: "radialBar",
          offsetY: -35,
        },
        plotOptions: {
          radialBar: {
            startAngle: -120,
            endAngle: 120,
            track: {
              background: colors ? colors.at(1) : "#8c8c8c1A",
            },
            dataLabels: {
              value: {
                offsetY: -15,
                fontSize: "22px",
                lineHeight: "29px",
                fontWeight: "600",
                color: COLORS.off_white,
                fontFamily: "Montserrat",
                // formatter: function (value) {
                //   return `$${formatNumber(statistics.potential)}`;
                // },
              },

              total: {
                show: true,
                label: "Total",
                formatter: function (value) {
                  return values ? values.at(0) + values.at(1) : "25";
                },
              },
            },
          },
        },
        fill: {
          colors: colors ? colors.at(0) : COLORS.purple,
        },
        stroke: {
          dashArray: 3,
        },
        grid: {
          padding: {
            bottom: -10,
          },
        },
      },
    });
    setChartKey(chartKey + 1);
  }, [values, colors]);

  return (
    <div className="gauge-chart-div">
      {loading ? (
        <>
          <Skeleton
            circle
            width={130}
            height={120}
            style={{ marginLeft: "155px" }}
          />
        </>
      ) : (
        <div style={{ position: "relative", width: "100%" }}>
          <ReactApexChart
            options={options.options}
            series={options.series}
            type="radialBar"
            height={smallLaptop ? 200 : 250}
            width="100%"
            key={chartKey}
          />
          <div
            style={{
              position: "absolute",
              top: 60,
              width: "100%",
            }}
          >
            <div
              style={{
                width: "80%",
                marginLeft: "10%",
                display: "flex",
                justifyContent: "space-between",
              }}
            >
              <p
                style={{
                  fontWeight: "600",
                  color: colors.at(0),
                  margin: 0,
                  fontSize: smallLaptop ? "11px" : "16px",
                }}
              >
                {values && values.length
                  ? `${parseInt(
                    (values.at(0) * 100) / (values.at(0) + values.at(1))
                  )}%`
                  : ""}
              </p>
              <p
                style={{
                  fontWeight: "600",
                  color: colors.at(1),
                  margin: 0,
                  fontSize: smallLaptop ? "11px" : "16px",
                }}
              >
                {values && values.length
                  ? `${parseInt(
                    (values.at(1) * 100) / (values.at(0) + values.at(1))
                  )}%`
                  : ""}
              </p>
            </div>
            <div
              className="gauge_bar_legend_value"
              style={{
                display: "flex",
                width: "100%",
                justifyContent: "center",
                marginBottom: "25px",
                fontSize: smallLaptop ? "11px" : "16px",
              }}
            >
              Total
            </div>
            <div
              style={{
                display: "flex",
                width: "80%",
                marginLeft: "10%",
                height: "50px",
                justifyContent: "space-between",
              }}
            >
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  width: "50%",
                  borderRight: "1px solid #8c8c8c66",
                }}
              >
                <div
                  style={{
                    width: "8px",
                    height: "8px",
                    borderRadius: "25px",
                    backgroundColor: colors.at(0),
                    marginRight: "8px",
                  }}
                />
                <p
                  style={{
                    margin: 0,
                    color: COLORS.off_white,
                    fontSize: smallLaptop ? "10px" : "14px",
                    marginRight: "4px",
                  }}
                >
                  {labels.at(0)}
                </p>
                <p
                  style={{
                    color: COLORS.dark_grey,
                    margin: 0,
                    fontSize: smallLaptop ? "10px" : "14px",
                  }}
                >
                  ({values.at(0)})
                </p>
              </div>
              <div style={{ display: "flex", alignItems: "center" }}>
                <div
                  style={{
                    width: "8px",
                    height: "8px",
                    borderRadius: "25px",
                    backgroundColor: colors.at(1),
                    marginRight: "8px",
                  }}
                />
                <p
                  style={{
                    margin: 0,
                    color: COLORS.off_white,
                    fontSize: smallLaptop ? "10px" : "14px",
                    marginRight: "4px",
                  }}
                >
                  {labels.at(1)}
                </p>
                <p
                  style={{
                    color: COLORS.dark_grey,
                    margin: 0,
                    fontSize: smallLaptop ? "10px" : "14px",
                  }}
                >
                  ({values.at(1)})
                </p>
              </div>
            </div>
            {/* <div
              style={{
                display: "flex",
                color: COLORS.off_white,
                justifyContent: "space-around",
              }}
            >
              <div className="flex_box_legend">
                <div
                  className="gauge_bar_legend_circle"
                  style={{ backgroundColor: COLORS.purple }}
                />
                <p className="gauge_bar_legend_label">Due</p>
                <p className="gauge_bar_legend_value">
                  {" "}
                  ({statistics?.total_current})
                </p>
              </div>
              <div className="flex_box_legend">
                <div
                  className="gauge_bar_legend_circle"
                  style={{ backgroundColor: "#1DFF5C" }}
                />

                <p className="gauge_bar_legend_label">Risk </p>
                <p className="gauge_bar_legend_value">
                  ({statistics?.total_potential})
                </p>
              </div>

              <div className="flex_box_legend">
                <div
                  className="gauge_bar_legend_circle"
                  style={{ backgroundColor: COLORS.dark_grey }}
                />

                <p className="gauge_bar_legend_label">Free </p>
                <p className="gauge_bar_legend_value">
                  ({statistics?.free_days_total})
                </p>
              </div>
            </div> */}
          </div>
        </div>
      )}
    </div>
  );
};

export default GaugeBarStatistics;
