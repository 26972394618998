import React, { useEffect, useState } from "react";
import Pagination from "../../../../../components/Pagination";
import OffsetIcon from "../../components/assets/offset_icon.svg";
import PlantIcon from "../../components/assets/plant_icon.svg";
import { CustomLoader } from "../../../../../components/CustomLoader";
import moment from "moment";
import { Dialog } from "@mui/material";
import apiFactory from "../../../../Container/service/apiFactory";

const StatusCell = ({ isPending }) => {
  return (
    <div className={`offset_status_cell ${isPending ? "orange_s" : "green_s"}`}>
      {isPending ? "Pending" : "Success"}
    </div>
  );
};

const OffsetTable = (props) => {
  const [pictureUrl, setPictureUrl] = useState("");
  const [picture, setPicture] = useState("");
  const [loadingPicture, setLoadingPicture] = useState(false);
  let cellWidths = [11, 11, 11, 13, 11, 22, 5, 6, 8];
  useEffect(() => {
    async function getPicture() {
      setLoadingPicture(true);
      await apiFactory
        .getFile(`https://daas-dev-mcleod-api.cdl1000.com${pictureUrl}`)
        .then((resp) => {
          const file = new Blob([resp], { type: "application/pdf" });
          setPicture(URL.createObjectURL(file));
          setLoadingPicture(false);
        })
        .catch(() => {
          setLoadingPicture(false);
        });
    }
    if (pictureUrl) {
      getPicture();
    }
  }, [pictureUrl]);
  return (
    <>
      <Dialog
        open={pictureUrl}
        onClose={() => {
          setPictureUrl("");
        }}
        sx={{
          "& .MuiDialog-container": {
            "& .MuiPaper-root": {
              maxWidth: "none",
              width: "1150px",
              height: "700px",
            },
          },
        }}
      >
        {loadingPicture ? (
          <div
            style={{
              height: "100%",
              width: "100%",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <CustomLoader size="7vh" />
          </div>
        ) : (
          <embed src={picture} alt="offset_cer" style={{ height: "100%" }} />
        )}
      </Dialog>
      {props.isLoading ? (
        <div className="history_no_content_div">
          <CustomLoader size="5vh" />
        </div>
      ) : props.data && props.data.length === 0 ? (
        <div className="history_no_content_div">No Offsets purchased!</div>
      ) : (
        <>
          <table className="history_table_main">
            <thead>
              <tr
                className="history_row"
                style={{ borderBottom: "1px solid #bdbdbdcc" }}
              >
                <th className="left" style={{ width: `${cellWidths[0]}%` }}>
                  Container ID
                </th>
                <th className="right" style={{ width: `${cellWidths[1]}%` }}>
                  Date
                </th>
                <th className="" style={{ width: `${cellWidths[2]}%` }} />
                <th className="left" style={{ width: `${cellWidths[3]}%` }}>
                  BOL
                </th>
                <th className=" right" style={{ width: `${cellWidths[4]}%` }}>
                  tCO<sub>2</sub>
                </th>
                <th className="center" style={{ width: `${cellWidths[5]}%` }}>
                  Status
                </th>
                <th className="right" style={{ width: `${cellWidths[6]}%` }}>
                  Amount
                </th>
                <th className="" style={{ width: `${cellWidths[7]}%` }} />

                <th className="center" style={{ width: `${cellWidths[8]}%` }}>
                  Action
                </th>
              </tr>
            </thead>
            <tbody>
              {props.data &&
                props.data.map((item, index) => {
                  return (
                    <tr className="history_row" key={index}>
                      <td
                        className="left"
                        style={{ width: `${cellWidths[0]}%` }}
                      >
                        #{item.ContainerID}
                      </td>
                      <td
                        className="right"
                        style={{ width: `${cellWidths[1]}%` }}
                      >
                        {moment(item.Date).format("MM/DD/YYYY")}
                      </td>
                      <td className="" style={{ width: `${cellWidths[2]}%` }} />

                      <td
                        className="left"
                        style={{ width: `${cellWidths[3]}%` }}
                      >
                        {item.BOL}
                      </td>
                      <td
                        className="right"
                        style={{ width: `${cellWidths[4]}%` }}
                      >
                        {item.tCO2}
                        <img
                          src={PlantIcon}
                          alt="plant_icon"
                          style={{ width: "16px", marginLeft: "8px" }}
                        />
                      </td>
                      <td
                        className="center"
                        style={{
                          width: `${cellWidths[5]}%`,
                        }}
                      >
                        <StatusCell isPending={item.Status === "Pending"} />
                      </td>
                      <td
                        className="right orange"
                        style={{ width: `${cellWidths[6]}%` }}
                      >
                        ${parseFloat(item.FinalPrice).toFixed(2)}
                      </td>
                      <td className="" style={{ width: `${cellWidths[7]}%` }} />

                      <td
                        className="center"
                        style={{
                          width: `${cellWidths[8]}%`,
                        }}
                      >
                        <button
                          style={{
                            all: "unset",
                            cursor:
                              item.Status === "Pending" ? "auto" : "Pointer",
                          }}
                          disabled={item.Status === "Pending"}
                          onClick={() => {
                            setPictureUrl(item.CertificateLink);
                          }}
                        >
                          <img
                            alt="eye_icon"
                            src={OffsetIcon}
                            style={{
                              opacity: item.Status === "Pending" ? 0.4 : 1,
                            }}
                          />
                        </button>
                      </td>
                    </tr>
                  );
                })}
            </tbody>
          </table>
          <div className="table_footer">
            <Pagination
              totalNumber={props.totalOffsets}
              page={props.offsetPagination.page}
              setPage={(number) => {
                props.setOffsetPagination({
                  ...props.offsetPagination,
                  page: number,
                });
              }}
              itemsPerPage={props.offsetPagination.count}
            />
          </div>
        </>
      )}
    </>
  );
};
export default OffsetTable;
