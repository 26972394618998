import React, { useState, useEffect } from "react";

import Select from "../../../components/Cdl/Select";
import { COLORS } from "../../../styles/themes/default/colors";
import AutoComplete from "../../../components/Cdl/AutoComplete";

import CreatorIcon from "../../../assets/img/creator_icon.svg";

import OffsetProjectsIcon from "../assets/offsetProjectsinactive.svg";
import OffsetProjectsIconActive from "../assets/offsetProjectsActive.svg";

import CalendarIcon from "../../Container/assets/calendar.svg";

import CartIcon from "../assets/cartIcon.svg";
import ResetIcon from "../../Container/assets/reset_icon.svg";
import CartIconActive from "../assets/carticonactive.svg";
import { IconButton } from "@mui/material";
import {
  useHistory,
  useLocation,
} from "react-router-dom/cjs/react-router-dom.min";
import CustomBadge from "./CustomBadge";
import esgStore from "../../../store/esgStore";

export default function EsgFilters(props) {
  const [searchCustomer, setSearchCustomer] = useState("");
  const [customer, setCustomer] = useState("");
  const [date, setDate] = useState("");

  const customers = esgStore((state) => state.customersFilter);
  const esgCart = esgStore((state) => state.esgCart);
  const history = useHistory();
  const location = useLocation();
  const isAdmin =
    props.user?.user?.role === "CA" ||
    props.user?.user?.role === "CR" ||
    props.user?.user?.role === "CC";

  useEffect(() => {
    props?.setSearchQuery(filterToString());
  }, [date, customer]);

  const filterToString = () => {
    if (customer.length === 0 && date.length === 0) {
      return "";
    }
    let query = "?";
    if (customer) {
      query += `&shippers=${customer}`;
    }
    if (date) {
      switch (date) {
        case "Past 3 months":
          query += `&date_range=QTD`;
          break;
        case "Past 6 months":
          query += `&date_range=HYTD`;
          break;
        case "Past 9 months":
          query += `&date_range=NMTD`;
          break;
        default:
          break;
      }
    }
    return query;
  };

  const resetFilters = () => {
    setSearchCustomer("");
    setCustomer("");
    setDate("");
  };

  return (
    <div className="esgFiltersDiv" id="esg_filter_div">
      <div className="esgFilters">
        {isAdmin && (
          <AutoComplete
            iconStart={CreatorIcon}
            search={searchCustomer}
            setSearch={setSearchCustomer}
            triggerOnSelectOnly={true}
            setSelectedValue={setCustomer}
            options={customers}
            placeholder="All customers"
            css={{
              width: "400px",
              backgroundColor: COLORS.off_black,
              border: "none",
            }}
            cssDiv={{
              width: "400px",
              backgroundColor: COLORS.off_black,
              color: COLORS.dark_grey,
            }}
            darkTheme={true}
          />
        )}
        <Select
          icon={CalendarIcon}
          value={date}
          onChange={setDate}
          placeholder="Past 12 months"
          css={{
            width: "240px",
            backgroundColor: COLORS.off_black,
            color: COLORS.dark_grey,
            border: "none",
            marginLeft: isAdmin ? "1rem" : "",
          }}
          cssDiv={{
            marginLeft: isAdmin ? "16px" : "",
            width: "240px",
            backgroundColor: COLORS.off_black,
            color: COLORS.dark_grey,
          }}
          type="select"
          darkTheme={true}
          options={["Past 3 months", "Past 6 months", "Past 9 months"]}
        />
        <button
          className="reset_button"
          onClick={resetFilters}
          style={{
            width: "95px",
          }}
        >
          <img
            src={ResetIcon}
            alt=""
            style={{
              marginTop: "6px",
              marginRight: "2px",
            }}
          />
          Reset
        </button>

        <div style={{ position: "relative" }}>
          <button
            className="esg_header_button"
            onClick={() => {
              if (location.pathname.startsWith("/esg/offset-projects")) {
                history.push("/esg");
              } else {
                history.push("/esg/offset-projects");
              }
            }}
          >
            <img
              src={
                location.pathname.startsWith("/esg/offset-projects")
                  ? OffsetProjectsIconActive
                  : OffsetProjectsIcon
              }
              alt=""
            />
          </button>
          <div className="esg_hover_container">
            <div className="esg_hover_inner_container">Offset projects</div>
          </div>
        </div>
      </div>
      <div>
        <IconButton
          style={{ padding: "0", position: "relative" }}
          onClick={() => {
            history.push("/esg/cart");
          }}
        >
          <img
            src={location.pathname === "/esg/cart" ? CartIconActive : CartIcon}
            alt="cart"
          />
          {esgCart && Object.keys(esgCart).length > 0 && (
            <CustomBadge number={Object.keys(esgCart).length} />
          )}
        </IconButton>
      </div>
    </div>
  );
}
