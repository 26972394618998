import React, { useEffect, useState } from "react";

import SavedIcon from "../../assets/purple_saved.svg";
import NotSavedIcon from "../../assets/not_saved_purple.svg";
import ShowWholeRouteIcon from "../../assets/show_whole_route.svg";

import { COLORS } from "../../../../styles/themes/default/colors";
import OriginIcon from "../../assets/origin_icon.svg";

import "../../styles/watchlist.scss";
import "../../styles/alertStyles.scss";
import { FormControlLabel, Checkbox } from "@mui/material";
import useStore from "../../../../store/store";
import TableLocation from "../MutualComponents/Location";
import GridInfo from "../MutualComponents/GridInfo";
import TrackingEventsView from "../MutualComponents/TrackingView";
import { colorDict, statusBoxClass } from "../../utils/contants";
import DemurrageInformation from "../MutualComponents/DemurrageInformation";
import { formatValue } from "../../../../utils/constants";
import { CustomLoader } from "../../../../components/CustomLoader";
import { useMediaQuery, createTheme } from "@mui/material";
import DetentionInformation from "../MutualComponents/DetentionInformation";
import containerStore from "../../../../store/containerStore";
import store from "../../../../store/store";

const theme1 = createTheme({
  breakpoints: {
    values: {
      sl: 1400,
    },
  },
});

const StatusBar = ({ status }) => {
  const smallLaptop = useMediaQuery(theme1.breakpoints.down("sl"));
  return (
    <div
      className={statusBoxClass[status]}
      style={{
        fontSize: smallLaptop ? "11px" : "16px",
      }}
    >
      <div className="dot" style={{ backgroundColor: colorDict[status] }} />{" "}
      {status}
    </div>
  );
};

const AlertRowWithPayment = ({
  data,
  index,
  checked,
  handleClickChecked,
  handleFavorite,
  handleRead,
  setIsModalOpen,
  loadingContainer,
  setIsDetentionModalOpen,
  isInCluster,
  checkedTerminal,
}) => {
  const currentUser = useStore((state) => state.currentUser);
  const [openDetail, setOpenDetail] = useState(null);
  const [isRead, setIsRead] = useState(
    data?.read ? data?.read.includes(currentUser?.user?.id) : false
  )

  const smallLaptop = useMediaQuery(theme1.breakpoints.down("sl"));

  const [isFavorite, setIsFavorite] = useState(
    data?.favored_by ? data?.favored_by.includes(currentUser?.user?.id) : false
  );
  const currentDisplayContainer = containerStore(
    (state) => state.currentDisplayContainer
  );
  const setCurrentDisplayContainer = containerStore(
    (state) => state.setCurrentDisplayContainer
  );
  const getContainerId = containerStore((state) => state.getContainerId);
  const setGetContainerId = containerStore((state) => state.setGetContainerId);

  const maxLength = 28;
  const truncateString = (str) => {
    if (str?.length > maxLength) {
      return str.substring(0, maxLength) + "...";
    }
    return str;
  };

  const fullName =
    (data?.export ? data?.pol?.facility_name : data?.pod?.facility_name) ??
    data.destination.facility_name ??
    `${data.destination.name}, ${data.destination.state}`;

  const isNameLonger = fullName?.length > maxLength;
  const shortName = truncateString(fullName);

  useEffect(() => {
    if (getContainerId === data?.container_id) {
      setOpenDetail(true);
    } else {
      setOpenDetail(false);
    }
  }, [currentDisplayContainer]);

  document.querySelectorAll(".facility_name").forEach((el) => {
    el.addEventListener("mouseenter", function () {
      const hoverElement = this.nextElementSibling;
      if (
        hoverElement?.classList &&
        !hoverElement.classList.contains("facility_name_hover")
      )
        return;

      const boundingRect = this.getBoundingClientRect();
      const windowHeight = window.innerHeight;
      const spaceAbove = boundingRect.top;
      const spaceBelow = windowHeight - boundingRect.bottom;

      if (hoverElement) {
        hoverElement.style.display = "block";

        if (spaceAbove > spaceBelow) {
          // More space above, position the tooltip above
          hoverElement.style.bottom = `${this.offsetHeight + 30}px`;
          hoverElement.style.top = "auto";
        } else {
          // More space below, position the tooltip below
          hoverElement.style.top = `${this.offsetHeight + 30}px`;
          hoverElement.style.bottom = "auto";
        }
      }
    });

    el.addEventListener("mouseleave", function () {
      const hoverElement = this.nextElementSibling;
      if (
        hoverElement?.classList &&
        !hoverElement.classList.contains("facility_name_hover")
      )
        return;
      if (hoverElement) {
        hoverElement.style.display = "none";
      }
    });
  });

  const handleOpenContainer = () => {
    if (!loadingContainer) {
      if (currentDisplayContainer?.container_id === data.container_id) {
        setOpenDetail(false);
        setCurrentDisplayContainer({});
        setGetContainerId("");
      } else {
        setGetContainerId(data.container_id);
        handleRead(data?.container_id);
        setIsRead(true);
      }
    }
  }

  return (
    <>
      <div
        className={openDetail ? "alert_table_row_dark" : "alert_table_row"}
        onClick={handleOpenContainer}
        style={{
          marginBottom: openDetail ? "8px" : "16px",
          border: isInCluster ? `1px solid ${data.color}` : "none",
        }}
      >
        {!isRead && (<div className="alert_dot" />)}
        <div className="container_id_alert_payment">
          <div>
            <FormControlLabel
              style={{
                marginTop: "8px",
                opacity:
                  checkedTerminal !== "" && checkedTerminal !== fullName
                    ? 0.4
                    : 1,
              }}
              control={
                <Checkbox
                  disabled={
                    checkedTerminal !== "" && checkedTerminal !== fullName
                  }
                  onClick={(e) => {
                    e.stopPropagation();

                    handleClickChecked(data, fullName);
                  }}
                  style={{
                    color: checked?.includes(data.container_id)
                      ? COLORS.purple
                      : COLORS.dark_grey,
                  }}
                />
              }
              checked={checked?.includes(data.container_id)}
              label={
                <button
                  style={{
                    outline: "none",
                    border: "none",
                    backgroundColor: "transparent",
                    color: COLORS.off_white,
                    fontWeight: 600,
                    fontSize: smallLaptop ? "14px" : "16px",
                  }}
                  onClick={() => {
                    handleClickChecked(index);
                  }}
                >
                  #{data?.container_id}
                </button>
              }
            />
          </div>
        </div>
        {!openDetail ? (
          <div style={{ display: "flex", width: "72%" }}>
            <div
              style={{
                width: "50%",
                color: COLORS.off_white,
                display: "flex",
                alignItems: "center",
                justifyContent: "flex-start",
                fontSize: smallLaptop ? "11px" : "16px",
              }}
            >
              <img
                src={OriginIcon}
                alt=""
                style={{
                  marginRight: "8px",
                  width: smallLaptop ? "11px" : "16px",
                }}
              />
              <span
                className="facility_name"
                style={{ textTransform: "capitalize" }}
              >
                {shortName.toLowerCase()}
              </span>
              {isNameLonger && (
                <div className="facility_name_hover">
                  <div
                    style={{
                      backgroundColor: `#ff1da433`,
                      color: COLORS.purple,
                      fontWeight: "600",
                      padding: "8px",
                      borderRadius: "8px",
                    }}
                  >
                    {fullName}
                  </div>
                </div>
              )}
            </div>

            <div
              style={{
                color: "white",
                textAlign: "right",
                width: "50%",
                display: "flex",
                alignItems: "center",
                justifyContent: "flex-end",
              }}
            >
              {loadingContainer && getContainerId === data.container_id && (
                <div style={{ display: "flex", alignItems: "center" }}>
                  <CustomLoader darktheme="true" size="3vh" />
                </div>
              )}
              <p
                style={{
                  margin: 0,
                  fontSize: "14px",
                  color: COLORS.dark_grey,
                  marginRight: "21px",
                  width: "25%",
                  textAlign: "right",
                }}
              >
                {data?.is_in_detention ? "Detention" : "Demurrage"}
              </p>
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  width: "55%",
                  justifyContent: "flex-end",
                }}
              >
                <p className="white_text" style={{ fontWeight: "600" }}>
                  {formatValue(
                    data?.potential_demurrage_amount +
                    data?.potential_detention_amount
                  )}
                </p>
                {/* {data?.potential_detention_amount === null ? ( */}
                <button
                  onClick={(e) => {
                    e.stopPropagation();

                    if (data?.is_in_detention) {
                      setIsDetentionModalOpen(true);
                    } else {
                      setIsModalOpen(true);
                    }
                    setGetContainerId(data?.container_id);
                  }}
                  className="purple_button"
                  style={{ marginLeft: "16px" }}
                >
                  Pay
                </button>
              </div>
            </div>
          </div>
        ) : (
          <div
            style={{
              display: "flex",
              width: "72%",
              height: "100%",
              alignItems: "center",
            }}
          >
            <div style={{ width: smallLaptop ? "65%" : "70%" }}>
              <StatusBar status={data?.current_event?.status} />
            </div>
            <div
              style={{
                width: smallLaptop ? "35%" : "30%",
                display: "flex",
                alignItems: "center",
              }}
            >
              <button
                className="link_button"
                onClick={(e) => {
                  e.stopPropagation();
                  setCurrentDisplayContainer({
                    ...currentDisplayContainer,
                    whole_route: !currentDisplayContainer.whole_route,
                  });
                }}
              >
                <img
                  src={ShowWholeRouteIcon}
                  alt=""
                  style={{ marginRight: "8px" }}
                />
                Show route
              </button>
            </div>
          </div>
        )}
        <div className="watch_list_bookmark_cell">
          <button
            onClick={(e) => {
              e.stopPropagation();
              handleFavorite(data?.container_id);
              setIsFavorite(!isFavorite);
            }}
            className="transparent_button"
          >
            <img src={isFavorite ? SavedIcon : NotSavedIcon} alt="" />
          </button>
        </div>
      </div>
      {currentDisplayContainer?.container_id === data?.container_id && (
        <div
          style={{
            width: "98%",
            height: openDetail ? "auto" : "0px",
            transition: "0.3s all ease-in-out",
            overflow: openDetail ? "visible" : "hidden",
          }}
        >
          <TableLocation
            data={currentDisplayContainer}
            currentEvent={currentDisplayContainer?.current_event}
            lastUpdated={currentDisplayContainer?.last_updated}
          />
          <div
            style={{
              width: "100%",
              marginTop: "8px",
              display: "flex",
            }}
          >
            <GridInfo data={currentDisplayContainer} />
            <TrackingEventsView
              data={currentDisplayContainer?.events}
              currentEvent={currentDisplayContainer?.current_event}
              color={currentDisplayContainer?.color}
            />
          </div>
          {data?.is_in_detention ? (
            <DetentionInformation
              data={currentDisplayContainer}
              setIsModalOpen={setIsModalOpen}
              setIsDetentionModalOpen={setIsDetentionModalOpen}
            />
          ) : (
            <DemurrageInformation
              data={currentDisplayContainer}
              setIsModalOpen={setIsModalOpen}
              setIsDetentionModalOpen={setIsDetentionModalOpen}
            />
          )}
        </div>
      )}
    </>
  );
};

export default AlertRowWithPayment;
