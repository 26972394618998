import { React } from "react";

import {
  Box,
  Drawer,
  ListItem,
  useMediaQuery,
  useTheme,
  styled,
  Divider,
  Avatar,
} from "@mui/material";

import customerIcon from "../../../assets/img/Sidebar/customers_icon.svg";
import usersIcon from "../../../assets/img/Sidebar/users_icon.svg";
import settingsIcon from "../../../assets/img/Sidebar/settings_icon.svg";
import demurragesIcon from "../../../assets/img/Sidebar/demurrages_icon.svg";

import cdlLogo from "../../../img/cdl_white_logo.webp";
import KeyboardArrowLeftIcon from "@mui/icons-material/KeyboardArrowLeft";
import { drawerWidth } from "../../../utils/constants";
import { COLORS } from "../../../styles/themes/default/colors";

const GuestSideBar = ({ openDrawer, setOpenDrawer, handleClick }) => {
  const theme = useTheme();
  const mdUp = useMediaQuery(theme.breakpoints.up("md"));

  const iconStyles = {
    width: "15px",
    height: "15px",
    marginRight: "10px",
  };

  const BackContainer = styled(Box)(({ theme }) => ({
    position: "absolute",
    top: 20,
    left: drawerWidth - 22,
    zIndex: 1000,
  }));

  const DrawerHeader = styled(Box)(({ theme }) => ({
    display: "flex",
    margin: "20px 0px",
    justifyContent: "center",
  }));
  const DrawerContainer = styled(Box)({
    color: "#ffffff",
    backgroundColor: "#1a1a1a",
    height: "100%",
  });

  return (
    <>
      <Box
        component="nav"
        sx={{
          width: { md: openDrawer ? drawerWidth : 0 },
          flexShrink: { md: 0 },
        }}
        aria-label="sidebar"
      >
        <Drawer
          variant={mdUp ? "persistent" : "temporary"}
          anchor="left"
          open={openDrawer}
          onClose={() => setOpenDrawer(false)}
          ModalProps={{
            keepMounted: true,
          }}
          sx={{
            display: "block",
            "& .MuiDrawer-paper": {
              boxSizing: "border-box",
              width: openDrawer ? drawerWidth : 0,
              backgroundColor: "black",
              color: COLORS.white,
              overflow: "unset",
            },
          }}
        >
          <DrawerContainer>
            {openDrawer && (
              <>
                <BackContainer>
                  <Avatar
                    style={{ backgroundColor: "#1a1a1a" }}
                    // onClick={() => setOpenDrawer(false)}
                  >
                    <KeyboardArrowLeftIcon style={{ color: "white" }} />
                  </Avatar>
                </BackContainer>

                <DrawerHeader>
                  <img src={cdlLogo} alt="" style={{ width: "60%" }} />
                </DrawerHeader>

                <div
                  style={{
                    padding: "0 9%",
                    display: "flex",
                    flexDirection: "column",
                    height: "80vh",
                    justifyContent: "space-between",
                    // marginTop: window.innerHeight > 700 ? "60%" : "20%",
                  }}
                >
                  <div>
                    <ListItem
                      button
                      key={2}
                      style={{
                        color: "#8C8C8C",
                        borderBottom: "1px solid rgba(189, 189, 189, 0.4)",
                      }}
                      onClick={() => {
                        handleClick(true);
                      }}
                    >
                      <img src={demurragesIcon} style={iconStyles} alt="" />
                      Demurrages
                    </ListItem>

                    <ListItem
                      button
                      key={4}
                      style={{
                        color: "#8C8C8C",
                        borderBottom: "1px solid rgba(189, 189, 189, 0.4)",
                      }}
                      onClick={(e) => {
                        handleClick(true);
                      }}
                    >
                      <img src={customerIcon} style={iconStyles} alt="" />
                      Company info
                    </ListItem>

                    <ListItem
                      button
                      key={4}
                      style={{ color: "#8C8C8C" }}
                      onClick={(e) => {
                        handleClick(true);
                      }}
                    >
                      <img src={usersIcon} style={iconStyles} alt="" />
                      Users
                    </ListItem>
                  </div>
                  <div>
                    <ListItem
                      button
                      key={4}
                      style={{ color: "#8C8C8C" }}
                      onClick={(e) => {
                        handleClick(true);
                      }}
                    >
                      <img src={settingsIcon} style={iconStyles} alt="" />
                      Settings
                    </ListItem>
                  </div>
                </div>
                <Divider />
              </>
            )}
          </DrawerContainer>
        </Drawer>
      </Box>
    </>
  );
};

export default GuestSideBar;
