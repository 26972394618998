import { styled } from "@mui/material/styles";

import mapDotIcon from "../components/assets/map_dot_icon.svg";
import companyPhoneIcon from "../components/assets/company_phone_icon.svg";
import cityIcon from "../components/assets/city_icon.svg";
import zipCodeIcon from "../components/assets/post_box_icon.svg";
import emailIcon from "../components/assets/mail_icon.svg";

import idCardIcon from "../components/assets/id_card_icon.svg";
import WebSiteIcon from "../components/assets/website_icon.svg";
import CompanyIcon from "../components/assets/company_icon.svg";

import EditingSection from "./EditingSection";

import ContarctInfromation from "./ContractInformations";
import fetchWrapper from "../../../../helpers/fetch-wrapper";
import { APP_URL } from "../../../../utils/constants";

import { State } from "country-state-city";

import useStore from "../../../../store/store";
import alertService from "../../../../services/alertService";
const MainDiv = styled("div")({
  width: "100%",
  display: "flex",
  flex: 0,
  justifyContent: "space-between",
  flexDirection: "row",
  marginBottom: "16px",
  gap: "16px",
});

const CustomerInformation = ({
  currentCustomer,
  setCurrentCustomer,
  pointOfContact,
  documents,
  handleFileAdd,
}) => {
  const currentUser = useStore((state) => state.currentUser);
  const updatePointOfContact = (data) => {
    data["id"] = currentCustomer.contact.user.id;
    let url = `${APP_URL}/user/`;
    if (currentUser.user.role === "SA") {
      url = `${APP_URL}/shipper/users/`;
    }

    fetchWrapper
      .patch(url, data)
      .then((resp) => {
        setCurrentCustomer({
          ...currentCustomer,
          contact: { user: resp.user },
        });
      })
      .catch((error) => {
        alertService.error(error.response.status, "");
      });
  };

  const updateCustomerInfo = (data) => {
    data["id"] = currentCustomer.id;
    let url = `${APP_URL}/shipper/`;
    if (currentUser.user.role === "SA") {
      url = `${APP_URL}/shipper/mycompany/`;
    }

    fetchWrapper
      .patch(url, data)
      .then((resp) => {
        setCurrentCustomer(resp);
      })
      .catch((error) => {
        alertService.error(error.response.status, "");
      });
  };

  const states = State.getStatesOfCountry("US");

  return (
    <div>
      <MainDiv>
        <EditingSection
          data={[
            currentCustomer?.business_name,
            currentCustomer?.address,
            `${currentCustomer?.state ? currentCustomer?.state : "Unknown"}, ${
              currentCustomer?.city ? currentCustomer?.city : "Unknown"
            }`,
            currentCustomer?.zip_code,
          ]}
          icons={[CompanyIcon, mapDotIcon, cityIcon, zipCodeIcon]}
          labels={["Company name", "Company address", "State/City", "ZIP code"]}
          title="General Information"
          links={[]}
          backendLabels={[
            "bussiness_name",
            "address",
            "state,city",
            "zip_code",
          ]}
          hideEdit={["SR", "CR"].includes(currentUser.user.role)}
          updateFunction={updateCustomerInfo}
          usaStates={states}
        />
        <EditingSection
          data={[
            pointOfContact?.first_name,
            pointOfContact?.last_name,
            pointOfContact?.phone_number,
            pointOfContact?.email,
          ]}
          icons={[idCardIcon, idCardIcon, companyPhoneIcon, emailIcon]}
          labels={["First name", "Last name", "Phone number", "Main"]}
          title="Point of contact"
          links={[]}
          backendLabels={["first_name", "last_name", "phone_number", "email"]}
          hideEdit={["SR", "CR"].includes(currentUser.user.role)}
          updateFunction={updatePointOfContact}
        />
        <EditingSection
          data={[
            currentCustomer?.db_customer?.salesperson_first_name,
            currentCustomer?.db_customer?.salesperson_last_name,
            "www.cdl1000.com",
            currentCustomer?.db_customer?.salesperson_email,
          ]}
          icons={[idCardIcon, idCardIcon, WebSiteIcon, emailIcon]}
          labels={["First name", "Last name", "Website url", "Mail"]}
          title="Sales Person"
          links={[{ id: 2, link: "https://www.cdl1000.com/" }]}
          backendLabels={["first_name", "last_name", "website", "email"]}
          hideEdit={true}
        />
      </MainDiv>
      <ContarctInfromation
        currentCustomer={currentCustomer}
        documents={documents}
        handleFileAdd={handleFileAdd}
      />
    </div>
  );
};

export default CustomerInformation;
