export const sliceString = (str, len) => {
  return str.length > len ? `${str.slice(0, len)}...` : str;
};
export const formatNumber = (amount) => {
  if (amount < 1000) {
    return amount;
  } else if (amount % 1000 >= 100) {
    return `${parseInt(amount / 1000)},${parseInt(amount % 1000)}`;
  } else if (amount % 1000 >= 10) {
    return `${parseInt(amount / 1000)},0${parseInt(amount % 1000)}`;
  } else {
    return `${parseInt(amount / 1000)},00${parseInt(amount % 1000)}`;
  }
};
export const getEuclidDistance = (coord1, coord2) => {
  return Math.sqrt(
    Math.pow(coord1.lng - coord2.lng, 2) + Math.pow(coord1.lat - coord2.lat, 2)
  );
};
export const HaversineDistance = (coord1, coord2) => {
  const earthRadius = 6371; // Earth's radius in kilometers

  // Convert latitude and longitude from degrees to radians
  const lat1Rad = coord1.lat * (Math.PI / 180);
  const lon1Rad = coord1.lng * (Math.PI / 180);
  const lat2Rad = coord2.lat * (Math.PI / 180);
  const lon2Rad = coord2.lng * (Math.PI / 180);

  // Haversine formula
  const dLat = lat2Rad - lat1Rad;
  const dLon = lon2Rad - lon1Rad;
  const a =
    Math.sin(dLat / 2) * Math.sin(dLat / 2) +
    Math.cos(lat1Rad) *
      Math.cos(lat2Rad) *
      Math.sin(dLon / 2) *
      Math.sin(dLon / 2);
  const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
  const distanceInKilometers = earthRadius * c;

  return distanceInKilometers;
};
export const getGroupedDrillDownData = (data, distance = 50) => {
  let grouped_data = [];
  for (let i = 0; i < data.length; i++) {
    if (data[i].ianaCode) {
      data[i].cluster_name = `${data[i].ianaCode}`;
    } else {
      data[i].cluster_name = `${data[i].name}`;
    }
    data[i].cluster_display = `${data[i].name}`;
  }
  for (let i = 0; i < data.length; i++) {
    let is_added = false;
    for (let j = 0; j < grouped_data.length; j++) {
      if (
        !is_added &&
        HaversineDistance(data[i].coordinates, grouped_data[j].coordinates) <
          distance
      ) {
        grouped_data[j].number += data[i].number;
        grouped_data[j].cluster_display += ` & ${data[i].name}`;
        grouped_data[j].name += `+${data[i].name}`;
        grouped_data[j].cluster_name += `+${data[i].cluster_name}`;
        grouped_data[j].is_cluster = true;
        if (
          data[i].type_of_prev === "terminals" &&
          data[i].coordinates.lat !== grouped_data[j].coordinates.lat &&
          data[i].coordinates.lng !== grouped_data[j].coordinates.lng
        ) {
          grouped_data[j].next = `visibility/view?${grouped_data[j].next
            .split("?")
            .at(-1)}&terminals=${data[i].name}`;
        } else {
          grouped_data[j].next += `&${data[i].type_of_prev}=${data[i].name}`;
        }
        is_added = true;
      }
    }
    if (!is_added) {
      grouped_data.push({ ...data[i], is_cluster: false });
    }
  }

  return grouped_data;
};
export const getGroupedData = (data, distance = 1) => {
  let grouped_data = [];

  for (let i = 0; i < data.length; i++) {
    let is_added = false;
    for (let j = 0; j < grouped_data.length; j++) {
      if (
        !is_added &&
        HaversineDistance(
          data[i].coordinates,
          grouped_data[j].group.at(0).coordinates
        ) < distance
      ) {
        if (
          data[i].current_event.status !==
          grouped_data[j].group.at(0).current_event.status
        ) {
          grouped_data[j].mutual_statuses = true;
        }
        grouped_data[j].number++;
        grouped_data[j].group.push({ ...data[i] });
        is_added = true;
      }
    }
    if (!is_added) {
      grouped_data.push({
        group: [data[i]],
        number: 1,
        mutual_statuses: false,
      });
    }
  }
  let index = 0;

  for (let i = 0; i < grouped_data.length; i++) {
    for (let j = 0; j < grouped_data[i].group.length; j++) {
      grouped_data[i].group[j] = { ...grouped_data[i].group[j], index: index };
      index++;
    }
  }

  return grouped_data;
};

export const checkForLabel = (urlRouter) => {
  if (urlRouter.startsWith("visibility/favorite")) {
    return ["Watch List", "watch_list"];
  } else if (urlRouter.startsWith("visibility/alert_containers")) {
    return ["Demurrage risk", "alert"];
  } else if (urlRouter.startsWith("visibility/delay_updates")) {
    return ["Delay Updates", "alert"];
  } else if (urlRouter.startsWith("visibility/booking_changed")) {
    return ["Booking Changes", "alert"];
  } else if (urlRouter.startsWith("visibility/missed_pickups")) {
    return ["Missed Pickups", "alert"];
  }

  return [];
};

export const  hasExactlyOneNonZeroElement = (arr) => {
  let nonZeroCount = 0;
  
  for (let i = 0; i < arr.length; i++) {
    if (arr[i] !== 0) {
      nonZeroCount++;
    }
    
    if (nonZeroCount > 1) {
      return false;
    }
  }

  return nonZeroCount === 1;
}

export const calculatePercentages = (series, maxValue) =>
series.map(value => (value * 100) / maxValue);