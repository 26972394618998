import React from "react";
import LocationIcon from "../../assets/location_name.svg";

import "../../styles/locationcircle.scss";
import { COLORS } from "../../../../styles/themes/default/colors";
import containerStore from "../../../../store/containerStore";

const LocationCircle = ({ data, isTerminalView, mainColor, isOnLeftSide }) => {
  const setContainerUrlRouter = containerStore(
    (state) => state.setContainerUrlRouter
  );
  const containerUrlRouter = containerStore(
    (state) => state.containerUrlRouter
  );
  let color = mainColor ? mainColor : COLORS.purple;
  return (
    <div
      style={{
        display: "flex",
        alignItems: "flex-start",
        position: "relative",
      }}
    >
      <button
        className="location_button"
        onClick={() => {
          // setContainerUrlRouter([]);
          setContainerUrlRouter(containerUrlRouter.concat([data.next]));
        }}
      >
        {data.number > 150 ? (
          <div
            className="outer_circle"
            style={{
              backgroundColor: `${color}1a`,
            }}
          >
            <div
              className="middle_circle"
              style={{
                backgroundColor: `${color}4d`,
              }}
            >
              <div
                className="inner_circle"
                style={{
                  backgroundColor: `${color}80`,
                }}
              >
                {data.number}
              </div>
            </div>
          </div>
        ) : data.number > 100 ? (
          <div
            className="middle_circle"
            style={{
              backgroundColor: `${color}4d`,
            }}
          >
            <div
              className="inner_circle"
              style={{
                backgroundColor: `${color}80`,
              }}
            >
              {data.number}
            </div>
          </div>
        ) : (
          <div
            className="inner_circle_only"
            style={{
              backgroundColor: `${color}80`,
            }}
          >
            {data.number}
          </div>
        )}
      </button>
      <div
        className="hover_container"
        // style={{
        //   transform: `translate(${
        //     !isOnLeftSide
        //       ? `-${
        //           110 +
        //           (data?.is_cluster
        //             ? data?.cluster_display.length
        //             : data.name.length) *
        //             (data.name.length > 10 ? 10 : 8)
        //         }px`
        //       : "0px"
        //   },0)`,
        // }}
      >
        <img src={LocationIcon} alt="location-icon" />
        <p className="location_name">
          {data?.is_cluster ? data?.cluster_display : data.name}
        </p>
      </div>
    </div>
  );
};

export default LocationCircle;
