import React, { useRef } from "react";
import AlertTableRow from "./AlertTableRow";

import "../../styles/portDetail.scss";
import { COLORS } from "../../../../styles/themes/default/colors";
import NoContentScreen from "./NoContentScreen";
import { useMediaQuery, createTheme } from "@mui/material";

const AlertTable = ({ loading, containers, pathname }) => {
  const theme1 = createTheme({
    breakpoints: {
      values: {
        sl: 1400,
      },
    },
  });
  const smallLaptop = useMediaQuery(theme1.breakpoints.down("sl"));

  const no_content = containers.length === 0;
  let title;
  if (pathname) {
    title = pathname.startsWith("visibility/booking_changed")
      ? "booking change"
      : pathname.startsWith("visibility/delay_updates")
      ? "delay update"
      : "missed pickups";
  }

  const listRef = useRef(null);
  const onItemClick = (index) => {
    const itemHeight = listRef.current.rows[1].clientHeight;
    // listRef.current.scrollTop = itemHeight * index;
    window.requestAnimationFrame(() => {
      listRef.current.scrollTo({
        top: (itemHeight + 16) * index - 65,
        behavior: "smooth",
      });
    });
  };
  return (
    <>
      <div
        style={{
          display: "flex",
          width: "97%",
          justifyContent: "space-between",
          alignItems: "center",
          margin: "0px 8px",
          marginBottom: "5px",
        }}
      >
        <p
          style={{
            fontWeight: "600",
            fontSize: smallLaptop ? "18px" : "24px",
            color: COLORS.off_white,
            margin: 0,
          }}
        >
          Alerts
        </p>
        <button
          style={{
            backgroundColor: "transparent",
            color: COLORS.activity,
            outline: "none",
            border: "none",
            fontSize: smallLaptop ? "10px" : "14px",
            fontWeight: "600",
          }}
        >
          Clear all
        </button>
      </div>
      {!no_content ? (
        <div
          ref={listRef}
          style={{
            width: "100%",
            display: "flex",
            flexDirection: "column",
            overflow: "auto",
            paddingTop: "11px",
          }}
        >
          {[1, 2, 3, 4, 5].map((id) => {
            return (
              <AlertTableRow key={id} index={id} onItemClick={onItemClick} />
            );
          })}
        </div>
      ) : (
        <NoContentScreen title={title} />
      )}
    </>
  );
};

export default AlertTable;
