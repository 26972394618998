import React from "react";

import { Dialog, Button, createTheme, useMediaQuery } from "@mui/material";
import { styled } from "@mui/material/styles";

import { Button as ButtonCdl } from "../../../components/Cdl/Button";

import closeModalIcon from "../../../assets/img/closeModalIcon.png";

export default function SubmitDemurrageModal({
  openSubmitModal,
  setOpenSubmitModal,
  submitDemurrage,
}) {
  const theme1 = createTheme({
    breakpoints: {
      values: {
        sl: 1400,
      },
    },
  });
  const smallLaptop = useMediaQuery(theme1.breakpoints.down("sl"));
  return (
    <>
      <Dialog
        open={openSubmitModal}
        onClose={() => {
          setOpenSubmitModal(false);
        }}
      >
        <div className="card-body">
          <div>
            <div className="modalDivHeader">
              <p className="titleModalText">Submit demurrage </p>
              <img
                src={closeModalIcon}
                className="closeModalImg"
                onClick={() => setOpenSubmitModal(false)}
              />
            </div>
            <p className="modalText">
              By clicking the button below, you agree that all the provieded
              information in the demurrage is correct and ready to be shared
              with the customer.
            </p>
            <div
              style={{
                textAlign: "center",
                background: "rgba(238, 74, 38, 0.4)",
                borderRadius: "8px",
              }}
            >
              <p className="modalTextSubmitDemurrage">
                You can not undo this action!
              </p>
            </div>
          </div>
          <div
            className="form-row"
            style={{ justifyContent: "end", display: "flex" }}
          >
            <ButtonCdl
              type="primary"
              text="Confirm"
              css={{
                width: smallLaptop ? "85px" : "120px",
                height: smallLaptop ? "30px" : "42px",
                marginRight: "10px",
              }}
              onClick={submitDemurrage}
            />
            <ButtonCdl
              type="link"
              text="Cancel"
              onClick={() => setOpenSubmitModal(false)}
            />
          </div>
        </div>
      </Dialog>
    </>
  );
}
