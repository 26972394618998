import { TextField } from "@mui/material";
import styled from "styled-components";

const CssTextField = styled(TextField, {
  name: "CssTextField",
})({
  "& .MuiInputBase-root": {
    height: "2.625rem",
    "@media only screen and (max-width: 1400px) and (min-width: 1280px)": {
      height: "1.875rem",
    },
  },
  "& label.Mui-focused": {
    color: "black",
  },
  "& .MuiOutlinedInput-root": {
    "&.Mui-focused fieldset": {
      border: "1px solid #ee4a26",
    },
  },
});
export default CssTextField;

// const CssTextField = styled(TextField)`
//   & .MuiInputBase-root {
//     height: "3.625rem";
//   }
//   & label.Mui-focused {
//     color: black;
//   }
//   & .MuiOutlinedInput-root {
//     &.Mui-focused fieldset {
//       border: 1px solid #ee4a26;
//     }
//   }
// `;
// export default CssTextField;
