import React from "react";
import ColumnBarStatistics from "../ColumnBarStatistics";
import { COLORS } from "../../../../../styles/themes/default/colors";
import { useMediaQuery, createTheme } from "@mui/material";
import DonutWithTwoValues from "../DonutWithTwoValues";

const EmptyReturnedStatistics = ({ statistics }) => {
  const theme1 = createTheme({
    breakpoints: {
      values: {
        sl: 1400,
      },
    },
  });

  const smallLaptop = useMediaQuery(theme1.breakpoints.down("sl"));

  return (
    <div style={{ display: "flex", flexDirection: "column", height: "100%" }}>
      <div className="template_container">
        <p className="container_statistics_title">Detention</p>
        <DonutWithTwoValues
          colors={["#F4F90A", "#75FA79"]}
          labels={["Saved", "Paid"]}
          values={[statistics?.saved_count, statistics?.paid_count]}
          amounts={[statistics?.saved_amount, statistics?.paid_amount]}
        />
      </div>
      <div
        className="template_container"
        style={{ marginTop: "16px", flexGrow: 1 }}
      >
        <div className="div-switch-title">
          <p
            className="container_statistics_title"
            style={{ marginBottom: "0" }}
          >
            Top 3 empty return ports
          </p>

          {/* <SwichButton /> */}
        </div>
        {/* <div style={{ height: "90%" }}> */}
        <ColumnBarStatistics
          colors={["#258017", "#36C533", "#95E035"]}
          statistics={statistics?.top3_terminals}
          customSortData={true}
        />
        {/* </div> */}
      </div>
    </div>
  );
};

export default EmptyReturnedStatistics;
