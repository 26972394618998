import React, { useEffect, useState } from "react";

import { useParams } from "react-router-dom";
import useStore from "../../../store/store";

import CustomerInformation from "./components/CustomerInformation";
import CustomerStatistics from "./components/CustomerStatistics";
import CustomerUsers from "./components/CustomerUsers";

import fetchWrapper from "../../../helpers/fetch-wrapper";
import { APP_URL } from "../../../utils/constants";
import { COLORS } from "../../../styles/themes/default/colors";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

import { styled } from "@mui/material/styles";
import HistoryTable from "./components/History/HistoryTable";
import verifiedIcon from "../../../assets/img/Navbar/verified_icon.svg";
import DeactivateCustomerIcon from "../../../assets/img/deactivateCustomerIcon.svg";
import ActivateCustomerIcon from "../../../assets/img/Success_green.svg";

import "./style/customer_profile.scss";
import Tooltip from "../../../components/Tooltip";

const MainDiv = styled("div")({
  width: "100%",
  height: "100%",
  overflowWrap: "break-word",
  display: "flex",
  flexDirection: "column",
});
const HeaderDiv = styled("div")({
  display: "flex",
  gap: "8px",
  width: "100%",
  justifyContent: "space-between",
  marginBottom: "8px",
});
const HeaderButton = styled("button")({
  padding: "0px",
  cursor: "pointer",
});
const ExpandText = styled("span")({
  color: COLORS.activity,
  cursor: "pointer",
  fontSize: "14px",
  lineHeight: "17px",
  fontWeight: "600",
  marginBottom: "0",
  "@media only screen and (max-width: 1400px) and (min-width: 1280px)": {
    fontSize: "0.625rem",
  },
});
const activeButtonStyle = {
  border: "none",
  backgroundColor: "transparent",
  color: COLORS.off_black,
  fontWeight: "600",
  borderBottom: `2px solid ${COLORS.primary}`,
};
const deactiveButtonStyle = {
  border: "none",
  backgroundColor: "transparent",
  color: COLORS.dark_grey,
  borderBottom: "none",
};

const CustomerProfile = () => {
  const param = useParams();
  const customerId = param.id;
  const currentUser = useStore((state) => state.currentUser);

  const [router, setRouter] = useState("general");
  const [currentCustomer, setCurrentCustomer] = useState();
  const [customerUsers, setCustomerUsers] = useState([]);
  const [documents, setDocuments] = useState({});

  const [statistics, setStatistics] = useState();
  const [isExpanded, setIsExpanded] = useState(true);

  const setNavbarLabel = useStore((state) => state.setNavbarLabel);

  useEffect(() => {
    let url = `${APP_URL}/shipper/${customerId}/`;
    if (currentUser?.user?.role === "SA" || currentUser?.user?.role === "SR") {
      url = `${APP_URL}/shipper/mycompany/`;
      fetchWrapper.get(`${APP_URL}/shipper/users/`).then((resp) => {
        setCustomerUsers(resp);
      });
    } else {
      fetchWrapper
        .get(`${APP_URL}/shipper/users/company/${customerId}/`)
        .then((resp) => {
          setCustomerUsers(resp);
        });
      fetchWrapper
        .get(`${APP_URL}/analytic/shipper/${customerId}/`)
        .then((resp) => {
          setStatistics(resp);
        });
    }
    fetchWrapper.get(url).then((resp) => {
      setCurrentCustomer(resp);
      setStatistics(resp.demurrage_statistics);
      setDocuments({
        contract: {
          name: resp.contract?.name,
          id: resp.contract?.id,
          size: resp?.contract?.size,
        },
      });
    });
  }, []);

  useEffect(() => {
    if (currentUser?.user?.role !== "SA" && currentUser?.user?.role !== "SR") {
      setNavbarLabel({
        label: "Customer Profile",
        back_link: "/customers",
      });
    }
  }, [currentCustomer]);

  const handleFileAdd = (event) => {
    const files = event.target.files;
    let doc = {
      contract: files[0],
      company: customerId,
    };
    setDocuments(doc);
  };
  const toggleActivate = () => {
    fetchWrapper
      .patch(`${APP_URL}/shipper/`, {
        is_active: !currentCustomer.is_active,
        id: currentCustomer.id,
      })
      .then((resp) => {
        setCurrentCustomer({
          ...currentCustomer,
          is_active: !currentCustomer.is_active,
        });
      });
  };

  return (
    <div
      style={{
        width: "100%",
        justifyContent: "center",
        height: "100%",
        padding: "0px 24px 24px 24px",
      }}
    >
      <MainDiv>
        <div className="d-flex w100 justify-between mt24 mb8">
          <div className="d-flex gap8 align-center">
            <p className="m0 fs22 c-orange fw600">
              {currentCustomer?.business_name}
            </p>
            {currentCustomer?.mcleod_id && (
              <div className="mcleod-verification">
                <img src={verifiedIcon} alt="" />
                <Tooltip type="McLeod" />
              </div>
            )}
          </div>
          {["CA", "CR", "CC"].includes(currentUser.user.role) && (
            <button
              className={`${currentCustomer?.is_active ? "c-orange" : "c-green"
                } bc-transparent br8 fw600 p8 active_button`}
              onClick={toggleActivate}
            >
              {currentCustomer?.is_active ? "Deactivate" : "Activate"}
              <img
                src={
                  currentCustomer?.is_active
                    ? DeactivateCustomerIcon
                    : ActivateCustomerIcon
                }
                alt=""
                style={{ marginLeft: "8px" }}
              />
            </button>
          )}
        </div>
        <HeaderDiv>
          <div style={{ display: "flex", gap: "16px" }}>
            <HeaderButton
              style={
                router === "general" ? activeButtonStyle : deactiveButtonStyle
              }
              onClick={() => {
                setRouter("general");
              }}
            >
              General
            </HeaderButton>
            <HeaderButton
              style={
                router === "history" ? activeButtonStyle : deactiveButtonStyle
              }
              onClick={() => {
                setRouter("history");
              }}
            >
              History
            </HeaderButton>
          </div>
          {router !== "history" && (
            <ExpandText onClick={() => setIsExpanded(!isExpanded)}>
              <ExpandMoreIcon
                sx={{
                  color: COLORS.activity,
                  transform: isExpanded ? "rotate(180deg)" : "",
                  transition: "0.3s all ease-in-out",
                }}
                CircularProgressWithLabel
              />
              {isExpanded ? "Hide stats" : "Show stats"}
            </ExpandText>
          )}
        </HeaderDiv>
        {router === "history" ? (
          <HistoryTable customerName={currentCustomer?.business_name} />
        ) : (
          <>
            <CustomerStatistics
              statistics={statistics}
              currentCustomer={currentCustomer}
              isExpanded={isExpanded}
            />
            <CustomerInformation
              currentCustomer={currentCustomer}
              setCurrentCustomer={setCurrentCustomer}
              pointOfContact={currentCustomer?.contact?.user}
              documents={documents}
              handleFileAdd={handleFileAdd}
            />
            {currentUser?.user?.role.includes("C") && (
              <CustomerUsers users={customerUsers} />
            )}
          </>
        )}
      </MainDiv>
    </div>
  );
};

export default CustomerProfile;
