import React from "react";

export default function ChangePasswordProgress({
  message,
  setDisabledPasswordChange,
}) {
  const spanStyle = {
    width: "20%",
    height: "4px",
    backgroundColor: "#51DA88",
    borderRadius: "2px",
    display: "block",
    margin: "auto 5px",
  };

  const spanStyleGray = {
    width: "20%",
    height: "4px",
    backgroundColor: "#F1F1F1",
    borderRadius: "2px",
    display: "block",
    margin: "auto 5px",
  };

  const returnValue = () => {
    if (message === "Password has more than 8 characters.") {
      setDisabledPasswordChange(true);
      return 0;
    } else if (message === "Password has a number.") {
      setDisabledPasswordChange(true);
      return 1;
    } else if (message === "Password has special characters.") {
      setDisabledPasswordChange(true);
      return 2;
    } else if (message === "Password has a capital letter.") {
      setDisabledPasswordChange(true);
      return 3;
    } else if (message === "Passwords match.") {
      setDisabledPasswordChange(true);
      return 4;
    } else if (message === undefined) {
      setDisabledPasswordChange(false);
      return 5;
    }
  };

  return (
    <div>
      <div style={{ display: "flex", margin: "20px 0 10px 0px" }}>
        <span style={returnValue() > 0 ? spanStyle : spanStyleGray}></span>
        <span style={returnValue() > 1 ? spanStyle : spanStyleGray}></span>
        <span style={returnValue() > 2 ? spanStyle : spanStyleGray}></span>
        <span style={returnValue() > 3 ? spanStyle : spanStyleGray}></span>
        <span style={returnValue() > 4 ? spanStyle : spanStyleGray}></span>
      </div>
      <p className="p-16-gray">{message}</p>
    </div>
  );
}
