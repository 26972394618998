import React from "react";
import ReactApexChart from "react-apexcharts";

import { COLORS } from "../../../styles/themes/default/colors";
import Skeleton from "react-loading-skeleton";
import { calculateObjectPercentages } from "../utils/constants";

const DisputeReason = (props) => {

  const percentageObject = calculateObjectPercentages(props?.data)

  let series = [
    percentageObject?.involuntary_rolled,
    percentageObject?.denied_free_time,
    percentageObject?.government_hold,
    percentageObject?.communication_delays,
    percentageObject?.no_appointments,
    percentageObject?.other
  ]

  const handleValueInK = (percentage) => {
    return `$${parseFloat((percentage / 100) * props?.data?.total / 1000).toFixed(2)}k`
  }

  let options = {
    chart: {},
    plotOptions: {
      radialBar: {
        hollow: {
          size: "50px",
        },
        track: {
          show: false,
          strokeWidth: 0.2,
        },
        dataLabels: {
          track: { strokeWidth: 0.1 },
          name: {
            fontSize: "22px",
            color: COLORS.off_white,
          },
          value: {
            offsetY: -10,
            fontSize: "24px",
            lineHeight: "29px",
            fontWeight: "600",
            fontFamily: "Montserrat",
            color: COLORS.off_white,
            formatter: function (value) {
              return handleValueInK(value);
            },
          },
          total: {
            show: true,
            label: "Total",
            style: {
              color: "red",
            },
            formatter: function (value) {
              return `$${parseFloat(props?.data?.total / 1000).toFixed(2)}k`;
            },
          },
        },

        labels: [
          "Involuntary rolled",
          "Denied free time",
          "Government hold",
          "Communication delays",
          "No Appointments",
          "Other",
        ],
      },
    },

    colors: ["#9F30C6", "#0B51F7", "#41BAEE", "#51DA87", "#FF1DA4", "#C8DF6E"],
    stroke: {
      strokeWidth: 0,
      lineCap: "round",
    },
  };
  const legends = [
    {
      title: "Involuntary rolled",
      color: "#9F30C6",
      percentage: percentageObject?.involuntary_rolled + "%",
      fees: handleValueInK(percentageObject?.involuntary_rolled),
    },
    {
      title: "Denied free time",
      color: "#0B51F7",
      percentage: percentageObject?.denied_free_time + "%",
      fees: handleValueInK(percentageObject?.denied_free_time),
    },
    {
      title: "Government hold",
      color: "#41BAEE",
      percentage: percentageObject?.government_hold + "%",
      fees: handleValueInK(percentageObject?.government_hold),
    },
    {
      title: "Communication delays",
      color: "#51DA88",
      percentage: percentageObject?.communication_delays + "%",
      fees: handleValueInK(percentageObject?.communication_delays),
    },
    {
      title: "No Appointments",
      color: "#FF1DA4",
      percentage: percentageObject?.no_appointments + "%",
      fees: handleValueInK(percentageObject?.no_appointments),
    },
    {
      title: "Other",
      color: "#C8DF6E",
      percentage: percentageObject?.other + "%",
      fees: handleValueInK(percentageObject?.other),
    },
  ];
  return (
    <div className="radial_bar_box">
      {props.isLoading ? (
        <div style={{ width: "100%", height: "100%" }}>
          <Skeleton width="100%" height="100%" />
        </div>
      ) : (
        <>
          <div className="chart_title_box">
            <p className="chart_title">Dispute reasons</p>
          </div>
          <div
            style={
              window.innerWidth > 1920
                ? {
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "space-between",
                  height: "100%",
                }
                : {}
            }
          >
            <ReactApexChart
              style={{ marginTop: window.innerWidth > 1920 ? "10%" : "0" }}
              options={options}
              series={series}
              type="radialBar"
              height={380}
              width={380}
            />
            <div>
              {legends.map((item, index) => {
                return (
                  <div key={index} className="radial_bar_legend_item">
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        gap: "4px",
                        width: "50%",
                        paddingBottom: window.innerWidth > 1920 ? "16px" : "0",
                      }}
                    >
                      <div
                        style={{ backgroundColor: item.color }}
                        className="chart_dot"
                      />
                      <p className="white_14px">{item.title}</p>
                    </div>
                    <p
                      className="dark_grey_14px"
                      style={{ width: "20%", textAlign: "right" }}
                    >
                      {item.fees}
                    </p>
                    <p
                      className="white_14px_bold"
                      style={{ width: "30%", textAlign: "right" }}
                    >
                      {" "}
                      {item.percentage}
                    </p>
                  </div>
                );
              })}
            </div>
          </div>
        </>
      )}
    </div>
  );
};
export default DisputeReason;
