import React from "react";

import ReactApexChart from "react-apexcharts";

import { COLORS } from "../../../../styles/themes/default/colors";

import Skeleton from "react-loading-skeleton";
import "../../styles/gaugeBarStatistics.scss";
import { useMediaQuery, createTheme } from "@mui/material";
import { formatValue } from "../../../../utils/constants";

const DonutWithTwoValues = ({
  statistics,
  loading,
  colors,
  labels,
  values,
  amounts,
}) => {
  const theme1 = createTheme({
    breakpoints: {
      values: {
        sl: 1400,
      },
    },
  });

  const smallLaptop = useMediaQuery(theme1.breakpoints.down("sl"));
  const options = {
    series: values ?? [],
    // colors: ["#FF1DA4"],
    options: {
      chart: {
        type: "donut",
        offsetY: window.innerWidth > 1920 ? 0 : -15,
        sparkline: {
          enabled: false,
        },
      },
      plotOptions: {
        pie: {
          startAngle: -90,
          endAngle: 90,
          offsetY: 10,
          donut: {
            size: "90%",
          },
        },
        bar: {
          width: 1,
        },
      },
      tooltip: {
        enabled: false,
      },
      fill: {
        colors: colors,
      },
      stroke: {
        stroke: 0.1,
        width: 0,
        lineCap: "round", // Make radial lines rounded
      },
      dataLabels: {
        enabled: false,
      },
      legend: {
        show: false,
      },
      grid: {
        padding: {
          bottom: -150,
        },
      },
    },
  };
  return (
    <div className="gauge-chart-div">
      {loading ? (
        <>
          <Skeleton
            circle
            width={130}
            height={120}
            style={{ marginLeft: "155px" }}
          />
        </>
      ) : (
        <div
          style={{
            position: "relative",
            width: "100%",
          }}
        >
          <ReactApexChart
            options={options.options}
            series={options.series}
            type="donut"
            height={smallLaptop ? 210 : 300}
            width="100%"
          />
          <div
            style={{
              position: "absolute",
              top: smallLaptop ? 50 : 80,
              width: "100%",
            }}
          >
            <div
              style={{
                display: "flex",
                width: "60%",
                marginLeft: "20%",
                justifyContent: "space-evenly",
                marginBottom: "16px",
                color: "white",
              }}
            >
              <p
                style={{
                  fontWeight: "600",
                  color: colors.at(0),
                  margin: 0,
                  fontSize: smallLaptop ? "11px" : "16px",
                }}
              >
                {values && values.length
                  ? `${parseInt(
                    (values.at(0) * 100) / (values.at(0) + values.at(1))
                  )}%`
                  : ""}
              </p>
              <p
                style={{
                  fontWeight: "600",
                  color: colors.at(1),
                  margin: 0,
                  fontSize: smallLaptop ? "11px" : "16px",
                }}
              >
                {values && values.length
                  ? `${100 -
                  parseInt(
                    (values.at(0) * 100) / (values.at(0) + values.at(1))
                  )
                  }%`
                  : ""}
              </p>
            </div>
          </div>
          <div style={{ display: "flex", justifyContent: "center" }}>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                borderRight: "1px solid #8c8c8c66",
                paddingRight: "16px",
                width: "35%",
              }}
            >
              <p
                style={{
                  color: COLORS.off_white,
                  fontSize: smallLaptop ? "18px" : "24px",
                  fontWeight: "600",
                }}
              >
                {values && values.at(0)}
              </p>
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  marginBottom: "8px",
                }}
              >
                <div
                  style={{
                    width: "8px",
                    height: "8px",
                    borderRadius: "25px",
                    backgroundColor: colors.at(0),
                    marginRight: "8px",
                  }}
                />
                <p className="white_text">{labels.at(0)}</p>
              </div>
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  color: COLORS.dark_grey,
                  fontSize: "14px",
                }}
              >
                {amounts ? formatValue(amounts.at(0)) : "_"}
              </div>
            </div>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "flex-end",
                paddingLeft: "16px",
                width: "35%",
              }}
            >
              <p
                style={{
                  color: COLORS.off_white,
                  fontSize: smallLaptop ? "18px" : "24px",
                  fontWeight: "600",
                }}
              >
                {values && values.at(1)}
              </p>
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  marginBottom: "8px",
                }}
              >
                <div
                  style={{
                    width: "8px",
                    height: "8px",
                    borderRadius: "25px",
                    backgroundColor: colors.at(1),
                    marginRight: "8px",
                  }}
                />
                <p className="white_text">{labels.at(1)}</p>
              </div>
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  color: COLORS.dark_grey,
                  fontSize: "14px",
                }}
              >
                {amounts ? formatValue(amounts.at(1)) : "_"}
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default DonutWithTwoValues;
