export const COLORS = {
  safety_green: "#e3e937",
  sunrise_orange: "#ee4a26",
  twilight: "#bea6da",
  mid_green: "#d6e787",
  asphalt_grey: "#586660",
  forest_green: "#094f45",
  white: "#ffffff",
  warm_grey: "#eddfce",
  black: "#000000",
  blue: "#1288f5",
  red: "#e80403",
  border_gray: "#BFBFBF",
  tooltip_gray: "#484a49",
  link_blue: "#4375C7",

  primary: "#EE4A26",
  success: "#51DA88",
  error: "#DA5151",
  warning: "#DAB351",
  activity: "#51B1DA",
  off_white: "#FAFAFA",
  off_black: "#1F1F1F",
  dark_grey: "#8C8C8C",
  ligth_grey: "#F1F1F1",
  purple: "#FF00B8",
};
