import React from "react";

import { ReactComponent as ShipIcon } from "../../assets/ship_icon.svg";
import CalendarIcon from "../../assets/calendar.svg";
import ClockIcon from "../../assets/clock.svg";
import MoneyIcon from "../../assets/money.svg";

import "../../styles/notifications.scss";
import { COLORS } from "../../../../styles/themes/default/colors";
import { useMediaQuery, createTheme } from "@mui/material";
import useStore from "../../../../store/store";
import containerStore from "../../../../store/containerStore";
const theme1 = createTheme({
  breakpoints: {
    values: {
      sl: 1400,
    },
  },
});
const CustomBadge = ({ number }) => {
  const smallLaptop = useMediaQuery(theme1.breakpoints.down("sl"));
  return (
    <span
      style={{
        position: "absolute",
        top: "0px",
        left: smallLaptop ? "25px" : "30px",
        height: smallLaptop ? "13px" : "16px",
        width: smallLaptop ? "13px" : "16px",
        fontSize: smallLaptop ? "8px" : "10px",
        borderRadius: "25px",
        backgroundColor: COLORS.purple,
        fontWeight: 600,
        zIndex: 9999,
        color: "white",
      }}
    >
      {number}
    </span>
  );
};

const Notifications = ({ alertNumbering }) => {
  const smallLaptop = useMediaQuery(theme1.breakpoints.down("sl"));
  const containerUrlRouter = containerStore(
    (state) => state.containerUrlRouter
  );
  const setContainerUrlRouter = containerStore(
    (state) => state.setContainerUrlRouter
  );
  const page_link = containerUrlRouter.at(-1);

  const handleClick = (link) => {
    setContainerUrlRouter([link]);
  };
  return (
    <>
      <div className="notifications_div">
        <div className="notification_inner_div">
          <button
            className={
              page_link === "booking_changed"
                ? "notification_button_active"
                : "notification_button"
            }
            onClick={() => handleClick("visibility/booking_changed")}
          >
            <ShipIcon
              alt=""
              style={
                smallLaptop
                  ? { width: "13px", height: "13px", fill: "#8c8c8c" }
                  : { fill: "#8c8c8c" }
              }
            />
            {alertNumbering?.booking_changes > 0 && (
              <CustomBadge number={alertNumbering?.booking_changes} />
            )}
          </button>
          <div className="hover_notifications">
            <div className="hover_notification_inner_div">Booking changes</div>
          </div>
        </div>
        <div className="notification_inner_div">
          <button
            className={
              page_link === "delay_updates"
                ? "notification_button_active"
                : "notification_button"
            }
            onClick={() => handleClick("visibility/delay_updates")}
          >
            <img
              src={CalendarIcon}
              alt=""
              style={smallLaptop ? { width: "13px", height: "13px" } : {}}
            />
            {alertNumbering?.delay_updates > 0 && (
              <CustomBadge number={alertNumbering?.delay_updates} />
            )}
          </button>
          <div className="hover_notifications">
            <div className="hover_notification_inner_div">Delay Updates</div>
          </div>
        </div>
        <div className="notification_inner_div">
          <button
            className={
              page_link === "missed_pickups"
                ? "notification_button_active"
                : "notification_button"
            }
            onClick={() => handleClick("visibility/missed_pickups")}
          >
            <img
              src={ClockIcon}
              alt=""
              style={smallLaptop ? { width: "13px", height: "13px" } : {}}
            />
            {alertNumbering?.missing_pickups > 0 && (
              <CustomBadge number={alertNumbering?.missing_pickups} />
            )}
          </button>
          <div className="hover_notifications">
            <div className="hover_notification_inner_div">Missed pickups</div>
          </div>
        </div>
        <div className="notification_inner_div">
          <button
            className={
              page_link === "alert_containers"
                ? "notification_button_active"
                : "notification_button"
            }
            onClick={() => { if (containerUrlRouter.at(-1) !== "visibility/alert_containers") handleClick("visibility/alert_containers") }}
          >
            <img
              src={MoneyIcon}
              alt=""
              style={smallLaptop ? { width: "13px", height: "13px" } : {}}
            />
            {alertNumbering?.demurrage_risks > 0 && (
              <CustomBadge number={alertNumbering?.demurrage_risks} />
            )}
          </button>
          <div className="hover_notifications">
            <div className="hover_notification_inner_div">D&D risk</div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Notifications;
