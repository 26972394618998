import React, { useState, useEffect } from "react";

import Tooltip from "./Tooltip";

import { styled } from "@mui/material/styles";
import { COLORS } from "../styles/themes/default/colors";
import InfoIcon from "../assets/img/info_icon.svg";

const ProgressBar = styled("div")({
  display: "flex",
  flexDirection: "row",
  position: "relative",
});

const EmptyStep = styled("span")({
  width: "16px",
  height: "4px",
  backgroundColor: COLORS.dark_grey,
  opacity: "0.2",
  borderRadius: "2px",
  display: "block",
  margin: "auto 2px",
});

const FullStep = styled("span")({
  width: "16px",
  height: "4px",
  backgroundColor: COLORS.twilight,
  borderRadius: "2px",
  display: "block",
  margin: "auto 2px",
});

const InformationIcon = styled("img")({
  marginLeft: "5px",
});

const StepProgressBar = ({ status, user }) => {
  const [emptyBars, setEmptyBars] = useState(0);
  const [fullBars, setFullBars] = useState(0);
  const [showTooltip, setShowTooltip] = useState(false);

  const ProgressBarTextsCDL = {
    Opened:
      user === "CA"
        ? "Review the demurrage request and approve or reject it."
        : "Waiting for Approval.",
    Approved: "Attach receipts and confirm the payment date.",
    Rejected:
      "The request is rejected, it can not be proceeded to the next steps.",
    Invoiced: "Waiting on the customer payment.",
    Draft: "Submit demurrage charge to request approval.",
    "Paid Back": "The payment was paid back by the Customer.",
    Cleared: "Attach the invoice and validate the due date.",
  };

  const ProgressBarTextsShipper = {
    Opened: "Waiting for Approval.",
    Approved: "The demurrage request is waiting to be cleared.",
    Rejected: "The payment is rejected by the Approval Authority.",
    Invoiced: "The demurrage request is waiting to be paid back to CDL 1000.",
    "Paid Back": "The payment is paid back to CDL 1000",
    Cleared: "The demurrage request is waiting for CDL to issue the invoices.",
  };

  useEffect(() => {
    switch (status) {
      case "Opened":
      case "Draft":
        setEmptyBars(4);
        setFullBars(1);
        break;
      case "Approved":
      case "Rejected":
        setEmptyBars(3);
        setFullBars(2);
        break;
      case "Cleared":
        setEmptyBars(2);
        setFullBars(3);
        break;
      case "Invoiced":
        setEmptyBars(1);
        setFullBars(4);
        break;
      case "Paid Back":
        setEmptyBars(0);
        setFullBars(5);
        break;
    }
  }, []);

  return (
    <ProgressBar>
      {Array(fullBars)
        .fill(null)
        .map((value, index) => (
          <FullStep
            key={index}
            style={{
              backgroundColor:
                status === "Paid Back"
                  ? COLORS.success
                  : status === "Rejected"
                  ? COLORS.dark_grey
                  : "",
            }}
          />
        ))}
      {Array(emptyBars)
        .fill(null)
        .map((value, index) => (
          <EmptyStep key={index} />
        ))}
      <InformationIcon
        src={InfoIcon}
        alt="information-icon"
        className="progress-bar-icon"
        onClick={(e) => {
          e.stopPropagation();
          setShowTooltip(!showTooltip);
        }}
      />
      {showTooltip && (
        <Tooltip
          type="Progress bar"
          status={status}
          display={showTooltip}
          text={
            user === "CR" || user === "CA"
              ? ProgressBarTextsCDL[status]
              : ProgressBarTextsShipper[status]
          }
        />
      )}
    </ProgressBar>
  );
};

export default StepProgressBar;
