import React, { useState } from "react";

import { Button } from "@mui/material";
import { styled } from "@mui/material/styles";

import Pagination from "../../../../components/Pagination";

import { AvatarIcon } from "../../../../components/ProfilePicture";

import { COLORS } from "../../../../styles/themes/default/colors";

import listOfUsersIcon from "../components/assets/list_of_users_icon.svg";

const MainDiv = styled("div")({
  width: "100%",
  flex: 1,
  display: "flex",
  flexDirection: "column",
  backgroundColor: COLORS.off_white,
  padding: "16px",
  borderRadius: "5px",
});

const TitleDiv = styled("div")({
  display: "flex",
});

const Title = styled("h2")({
  fontSize: "16px",
  lineHeight: "20px",
  fontWeight: "600",
  color: COLORS.off_black,
  marginBottom: "0",
  "@media only screen and (max-width: 1400px) and (min-width: 1280px)": {
    fontSize: "0.6875rem",
  },
});

const Icon = styled("img")({
  height: "16px",
  width: "16px",
  marginRight: "10px",
  "@media only screen and (max-width: 1400px) and (min-width: 1280px)": {
    height: "11px",
    width: "11px",
    marginTop: "5px",
  },
});

const TableDiv = styled("div")({
  width: "100%",
});

const Table = styled("table")({
  width: "100%",
  display: "block",
  tableLayout: "fixed",
});

const TableRow = styled("tr")({
  borderBottom: "1px solid rgba(189, 189, 189, 0.4)",
  width: "100%",
  height: "60px",
  display: "flex",

  "td:nth-of-type(1)": {
    width: "5%",
  },

  "td:nth-of-type(7)": {
    width: "10%",
  },
});

const TableCell = styled("td")({
  fontSize: "16px",
  lineHeight: "20px",
  textAlign: "left",
  width: "17%",
  margin: "auto 0",
  "@media only screen and (max-width: 1400px) and (min-width: 1280px)": {
    fontSize: "0.6875rem",
  },
});

const ActionButton = styled(Button)({
  backgroundColor: "unset",
  margin: "5px 0",
  minWidth: `42px!important`,
});

const TableFooter = styled("div")({
  display: "flex",
  flexDirection: "row",
  justifyContent: "space-between",
  marginTop: "16px",
});

const NoUsersDiv = styled("div")({
  position: "absolute",
  top: "50%",
  transform: "translateY(-50%)",
  width: "100%",
  left: 0,
  textAlign: "center",
});

const UserAvatar = styled(AvatarIcon)({
  height: "24px",
  width: "24px",
  color: `COLORS.dark_grey !important`,
  backgroundColor: `COLORS.ligth_grey !important`,
  fontFamily: "Montserrat",
  boxShadow: "none",
  fontSize: "10px",
  lineHeight: "12px",
});

const CustomerUsers = ({ users }) => {
  const [page, setPage] = useState(1);
  const TableBody = styled("tbody")({
    display: "block",
    width: "100%",
    overflowY: "scroll",
    position: "relative",
  });
  return (
    <MainDiv>
      <TitleDiv>
        <Icon alt="List of users icon" src={listOfUsersIcon} />
        <Title>List of users</Title>
      </TitleDiv>
      <TableDiv>
        <Table>
          <TableRow>
            <TableCell></TableCell>
            <TableCell>First name</TableCell>
            <TableCell>Last name</TableCell>
            <TableCell>E-mail</TableCell>
            <TableCell>Phone number</TableCell>
            <TableCell>Role</TableCell>
            {/* <TableCell>Action</TableCell> */}
          </TableRow>
          <TableBody>
            {users.length === 0 ? (
              <NoUsersDiv>There are no users in this company.</NoUsersDiv>
            ) : (
              users.map((item, index) => (
                <>
                  <TableRow key={index}>
                    <TableCell>
                      <UserAvatar
                        firstName={item.user.first_name}
                        lastName={item.user.lastName}
                        src={`data:image/png;base64,${item.user.picture_64}`}
                      />
                    </TableCell>
                    <TableCell>{item.user.first_name}</TableCell>
                    <TableCell>{item.user.last_name}</TableCell>
                    <TableCell>{item.user.email}</TableCell>
                    <TableCell>
                      {item.user.phone_number === null
                        ? "Unknown"
                        : item.user.phone_number}
                    </TableCell>
                    <TableCell>
                      {item.user.role === "SA"
                        ? "Administrator"
                        : "Regular user"}
                    </TableCell>
                    {/* <TableCell>
                      <ActionButton
                      // onClick={() =>
                      //   history.push(`/demurrage/details/${item.id}`)
                      // }
                      >
                        <img src={editIcon} alt="Edit icon" />
                      </ActionButton>
                      <ActionButton
                      // onClick={() =>
                      //   history.push(`/demurrage/details/${item.id}`)
                      // }
                      >
                        <img src={redTrashCanIcon} alt="Delete icon" />
                      </ActionButton>
                    </TableCell> */}
                  </TableRow>
                </>
              ))
            )}
          </TableBody>
        </Table>
        {window.innerWidth <= 1920 && (
          <TableFooter>
            <Pagination
              totalNumber={users.length}
              page={page}
              setPage={setPage}
              itemsPerPage={10}
            />
          </TableFooter>
        )}
      </TableDiv>
    </MainDiv>
  );
};

export default CustomerUsers;
