import React, { useEffect, useState } from "react";

import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { COLORS } from "../../../../styles/themes/default/colors";
import CartTableSummary from "./CartTableSummary";
import esgStore from "../../../../store/esgStore";

const OrderDetails = (props) => {
  const [isExpanded, setIsExpanded] = useState(true);
  const [tableHeigth, setTableHeigth] = useState(
    document.getElementById("heigthRefDiv")?.clientHeight
  );

  const offsetProjects = esgStore((state) => state.offsetProjects);
  const FlexStyle = {
    display: "flex",
    width: "100%",
    justifyContent: "space-between",
    alignItems: "center",
    marginBottom: "16px",
  };
  const totalValue = parseFloat(
    Object.keys(props.values).reduce(
      (acc, curr) =>
        acc + props.values[curr] * parseFloat(offsetProjects[curr].price_value),
      0
    )
  );
  useEffect(() => {
    setTableHeigth(document.getElementById("heigthRefDiv")?.clientHeight);
  }, []);

  return (
    <div
      style={{
        width: "100%",
        display: "flex",
        flexDirection: "column",
        gap: "8px",
      }}
    >
      <div className="hide_show_stats_div">
        <p className="c-white fw600 m0">Order details</p>
        <p
          className="c-blue m0 fw600 "
          style={{ fontSize: "14px", cursor: "pointer" }}
          onClick={() => {
            setIsExpanded((prev) => !prev);
          }}
        >
          {isExpanded ? "Hide details" : "Show details"}
          <ExpandMoreIcon
            sx={{
              color: COLORS.activity,
              transform: isExpanded ? "rotate(180deg)" : "",
              transition: "0.3s all ease-in-out",
            }}
            CircularProgressWithLabel
          />
        </p>
      </div>
      <div
        style={{
          visibility: isExpanded ? "visible" : "hidden",
          transition: "0.2s all linear",
          height: isExpanded ? `${tableHeigth}px` : "0px",
          overflow: "hidden",
          borderRadius: "8px",
          display: "flex",
          gap: "8px",
        }}
      >
        <div
          style={{
            backgroundColor: "#313131",
            width: "70%",
            borderRadius: "8px",
          }}
        >
          <CartTableSummary values={props.values} tableHeight={tableHeigth} />
        </div>
        <div
          id="heigthRefDiv"
          style={{
            backgroundColor: "#313131",
            width: "30%",
            borderRadius: "8px",
            padding: "16px",
          }}
        >
          <div
            style={{
              borderBottom: "1px solid #8c8c8c66",
              marginBottom: "16px",
            }}
          >
            {Object.keys(props.values).map((item) => {
              let data = offsetProjects.at(item);
              return (
                <div
                  style={{
                    display: "flex",
                    width: "100%",
                    justifyContent: "space-between",
                    marginBottom: "16px",
                  }}
                >
                  <p className="c-white m0">
                    {data.name.length > 45
                      ? data.name.slice(0, 45) + "..."
                      : data.name}
                  </p>
                  <p className="c-white m0">
                    $
                    {parseFloat(
                      props.values[item] * parseFloat(data.price_value)
                    ).toFixed(2)}
                  </p>
                </div>
              );
            })}
          </div>
          <div style={FlexStyle}>
            <p className="c-white m0">Sub-total Amount</p>
            <p className="c-white m0">${totalValue.toFixed(2)}</p>
          </div>
          <div style={FlexStyle}>
            <p className="c-white m0">Promo code</p>
            <p className="c-white m0">-</p>
          </div>

          <div style={FlexStyle}>
            <p className="c-white m0">Discount</p>
            <p className="c-white m0">-${(totalValue / 10).toFixed(2)}</p>
          </div>
          <div style={{ ...FlexStyle, marginBottom: 0 }}>
            <p className="c-purple m0 fw600">Total</p>
            <p className="c-purple m0 fw600">
              ${(totalValue - totalValue / 10).toFixed(2)}
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};
export default OrderDetails;
