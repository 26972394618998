import React, { useRef, useEffect, useState } from "react";

import "../../styles/watchlist.scss";
import WatchListRow from "./WatchListRow";
import containerStore from "../../../../store/containerStore";

const WatchList = ({
  data,
  handleFavorite,

  clusterData,
  loadingContainer,
  setIsModalOpen,
  setIsDetentionModalOpen,
}) => {
  const currentDisplayContainer = containerStore(
    (state) => state.currentDisplayContainer
  );
  const setGetContainerId = containerStore((state) => state.setGetContainerId);
  const getContainerId = containerStore((state) => state.getContainerId);

  const listRef = useRef(null);
  useEffect(() => {
    if (clusterData?.ids && clusterData.ids.length > 0 && listRef.current) {
      window.requestAnimationFrame(() => {
        listRef.current.scrollTo({
          top: clusterData?.minIndex * 81,
          behavior: "smooth",
        });
      });
    } else if (getContainerId) {
      const timer = setTimeout(() => {
        let index = -1;
        data.forEach((item) => {
          item.group.forEach((cont) => {
            if (cont.container_id === getContainerId) {
              index = cont.index;
            }
          });
        });
        window.requestAnimationFrame(() => {
          listRef.current.scrollTo({
            top: index * 81,
            behavior: "smooth",
          });
        });
      }, 50);
      return () => clearTimeout(timer);
    }
  }, [clusterData, getContainerId]);
  useEffect(() => {
    if (
      data &&
      data.length === 1 &&
      data.at(0).group.length === 1 &&
      Object.keys(currentDisplayContainer).length === 0
    ) {
      setGetContainerId(data.at(0).group.at(0).container_id);
    }
  });

  return (
    <div
      ref={listRef}
      className="watch_list_table"
      style={{
        height: `${document.getElementById("map_box")?.clientHeight}px`,
      }}
    >
      {data.map((cluster, index) => {
        return cluster.group.map((item) => {
          return (
            <WatchListRow
              key={item.index}
              index={item.index}
              data={item}
              handleFavorite={handleFavorite}
              isInCluster={
                clusterData?.ids && clusterData.ids.includes(item.container_id)
              }
              setIsModalOpen={setIsModalOpen}
              loadingContainer={loadingContainer}
              setIsDetentionModalOpen={setIsDetentionModalOpen}
            />
          );
        });
      })}
    </div>
  );
};

export default WatchList;
