import React from "react";

import { IconButton } from "@mui/material";
import { styled } from "@mui/material/styles";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";

const MainDiv = styled("div")({
  display: "flex",
  justifyContent: "space-between",
  width: "100%",
  justifyContent: "end",
});

const PaginationDiv = styled("div")({
  display: "flex",
  alignItems: "center",
  color: "#41BAEE",
  fontWeight: 600,
});

const PageNumber = styled("p")({
  margin: "0 5px",
  "@media only screen and (max-width: 1400px) and (min-width: 1280px)": {
    fontSize: "12px",
  },
});

const DemurrageModalPagination = ({
  totalNumber,
  page,
  setPage,
  itemsPerPage,
}) => {
  return (
    <MainDiv>
      <PaginationDiv>
        <IconButton
          style={{ color: "#41BAEE" }}
          className="paginationIconArrowLeft"
          onClick={() => {
            setPage(page - 1);
          }}
          disabled={page === 1}
        >
          <ChevronLeftIcon />
        </IconButton>
        <PageNumber>{page}</PageNumber>/<PageNumber>{totalNumber}</PageNumber>
        <IconButton
          style={{ color: "#41BAEE" }}
          className="paginationIconArrowRight"
          onClick={() => {
            setPage(page + 1);
          }}
          disabled={page === Math.ceil(totalNumber / itemsPerPage)}
        >
          <ChevronRightIcon />
        </IconButton>
      </PaginationDiv>
    </MainDiv>
  );
};

export default DemurrageModalPagination;
