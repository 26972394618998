import React, { useEffect, useState } from "react";

import "../../styles/portDetail.scss";
import Skeleton from "react-loading-skeleton";
import { formatNumber } from "../../utils/functions";
import SwitchDemDet from "../MutualComponents/Switch";

const PortStatistics = ({ statistics, loading, containers }) => {
  const [number1, setNumber1] = useState(-1);
  const [number2, setNumber2] = useState(-1);
  const [demDetSwitch, setDemDetSwitch] = useState(false);

  useEffect(() => {
    if (
      containers &&
      containers.length > 0 &&
      number1 === -1 &&
      number2 === -1
    ) {
      let num = 0;

      containers.forEach((cluster) => {
        cluster.group.forEach((container) => {
          if (container.current_event.status === "At Port") {
            num++;
          }
        });
      });
      setNumber1(Math.floor(Math.random() * num));
      setNumber2(Math.floor(Math.random() * num));
    }
  }, [containers]);

  return (
    <>
      {loading ? (
        <div
          style={{
            display: "flex",
            height: "250px",
            justifyContent: "space-around",
          }}
        >
          <Skeleton height={250} width={358} />
          <Skeleton height={250} width={181} />
          <Skeleton height={250} width={181} />
        </div>
      ) : (
        statistics?.waiting_statistics && (
          <div className="port_header_box" id="port_statistics_box">
            <div className="left_header_box">
              <div
                style={{
                  display: "flex",
                  width: "100%",
                  justifyContent: "space-between",
                  alignItems: "flex-start",
                }}
              >
                <p className="header_title" style={{ marginBottom: 0 }}>
                  {demDetSwitch ? "Detention" : "Demurrage"}
                </p>
                <SwitchDemDet
                  demDetSwitch={demDetSwitch}
                  setDemDetSwitch={setDemDetSwitch}
                />
              </div>
              <div
                className="column"
                style={{ borderBottom: "1px solid #bdbdbd66" }}
              >
                <div
                  className="cell"
                  style={{ borderRight: "1px solid #bdbdbd66" }}
                >
                  <p className="value_typography_purple">
                    $
                    {formatNumber(
                      demDetSwitch
                        ? statistics?.demurrage_statistics?.due_det
                        : statistics?.demurrage_statistics?.due_dem
                    )}
                  </p>
                  <p className="key_typography">Due</p>
                </div>
                <div className="cell">
                  <p className="value_typography">
                    $
                    {formatNumber(
                      demDetSwitch
                        ? statistics?.demurrage_statistics?.at_risk_det
                        : statistics?.demurrage_statistics?.at_risk_dem
                    )}
                  </p>
                  <p className="key_typography">At risk</p>
                </div>
              </div>
              <div className="column">
                <div
                  className="cell"
                  style={{ borderRight: "1px solid #bdbdbd66" }}
                >
                  <p className="value_typography">
                    $
                    {formatNumber(
                      demDetSwitch
                        ? statistics?.demurrage_statistics?.saved_det
                        : statistics?.demurrage_statistics?.saved_dem
                    )}{" "}
                  </p>
                  {/* <img
                    alt="lock"
                    src={LockIcon}
                    style={{
                      marginBottom: "8px",
                      paddingTop: "8px",
                      width: smallLaptop ? "20px" : "26px",
                      // height: smallLaptop ? "18px" : "24px",
                    }}
                  /> */}
                  <p className="key_typography">Saved</p>
                </div>
                <div className="cell">
                  <p className="value_typography">
                    $
                    {formatNumber(
                      demDetSwitch
                        ? statistics?.demurrage_statistics?.paid_det
                        : statistics?.demurrage_statistics?.paid_dem
                    )}
                  </p>
                  <p className="key_typography">Cleared with DaaS</p>
                </div>
              </div>
            </div>
            <div className="mini_box">
              <p className="header_title"> Appointment</p>
              <div
                className="column"
                style={{ borderBottom: "1px solid #bdbdbd66" }}
              >
                <div className="cell_single">
                  <p className="value_typography_purple">{number1}</p>
                  <p className="key_typography">Missing</p>
                </div>
              </div>
              <div className="column">
                <div className="cell_single">
                  <p className="value_typography">0</p>
                  <p className="key_typography">Set</p>
                </div>
              </div>
            </div>
            <div className="mini_box">
              <p className="header_title"> Loads</p>
              <div
                className="column"
                style={{ borderBottom: "1px solid #bdbdbd66" }}
              >
                <div className="cell_single">
                  <p className="value_typography">
                    {statistics?.waiting_statistics["Waiting to Load"]}
                  </p>
                  <p className="key_typography">Waiting to Load</p>
                </div>
              </div>
              <div className="column">
                <div className="cell_single">
                  <p className="value_typography">
                    {statistics?.waiting_statistics["Waiting to Unload"]}
                  </p>
                  <p className="key_typography">Waiting to Unload</p>
                </div>
              </div>
            </div>
          </div>
        )
      )}
    </>
  );
};

export default PortStatistics;
