import React from "react";
import DonutWithTwoValues from "../DonutWithTwoValues";
import { useMediaQuery, createTheme } from "@mui/material";

const OutToLoadStatistics = ({ statistics }) => {
  const theme1 = createTheme({
    breakpoints: {
      values: {
        sl: 1400,
      },
    },
  });

  const smallLaptop = useMediaQuery(theme1.breakpoints.down("sl"));
  return (
    <div style={{ height: "100%", display: "flex", flexDirection: "column" }}>
      <div className="template_container">
        <p className="container_statistics_title">Arriving time</p>
        <DonutWithTwoValues
          colors={["#F5AF45", "#51DA87"]}
          labels={["Behind", "On Time"]}
          values={[statistics?.late, statistics?.on_time]}
        />
      </div>
      <div
        className="template_container"
        style={{
          marginTop: "16px",
          paddingRight: 0,
          flexGrow: 1,
          height: 0,
        }}
      >
        <p className="container_statistics_title">Customers and containers</p>
        <div
          style={{
            height: "calc(100% - 20%)",
            overflowY: "auto",
            paddingRight: "16px",
          }}
        >
          {statistics?.top_customers &&
            Object.keys(statistics.top_customers)
              .slice(0, 5)
              .map((item, index) => {
                return (
                  <div className="flex_box_space_between">
                    <p className="white_text">{item}</p>
                    <p className="dark_grey_text">
                      {statistics?.top_customers[item]}
                    </p>
                  </div>
                );
              })}
        </div>
      </div>
    </div>
  );
};

export default OutToLoadStatistics;
