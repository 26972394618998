import React, { useState, useEffect } from "react";
import useStore from "../../store/store";

import PhoneEnabledIcon from "@mui/icons-material/PhoneEnabled";
import PhoneDisabledIcon from "@mui/icons-material/PhoneDisabled";
import fetchWrapper from "../../helpers/fetch-wrapper";
import { APP_URL } from "../../utils/constants";
import { CustomLoader } from "../../components/CustomLoader";
// import UserRoleDropdownMenu from "./components/UserRoleDropdownMenu";

import { useMediaQuery, createTheme } from "@mui/material";
import { styled } from "@mui/material/styles";

import { AvatarIcon } from "../../components/ProfilePicture";
import { Link } from "react-router-dom";
import AddUserModal2 from "./components/AddUserModal";
import DeleteUserModal from "./components/DeleteUserModal";

import { COLORS } from "../../styles/themes/default/colors";

import PlusIcon from "../../assets/img/plusIcon.svg";
import EditIcon from "../../assets/img/editIcon.svg";
import DeleteIcon from "../../assets/img/deleteIcon.svg";
import SearchIcon from "../../assets/img/searchIcon.svg";
import roleIcon from "../../assets/img/roleIcon.svg";

import { Button } from "../../components/Cdl/Button";
import { Input } from "../../components/Cdl/Input";
import Select from "../../components/Cdl/Select";
import Pagination from "../../components/Pagination";
import alertService from "../../services/alertService";

const theme1 = createTheme({
  breakpoints: {
    values: {
      xs: 0,
      mobile: 376,
      sm: 600,
      md: 900,
      lg: 1200,
      xl: 1536,
    },
  },
});

const mobileDown = theme1.breakpoints.down("mobile");
const smDown = theme1.breakpoints.down("sm");
const smUp = theme1.breakpoints.up("sm");
const mdDown = theme1.breakpoints.down("md");
const lgDown = theme1.breakpoints.down("lg");

const LoaderDiv = styled("div")({
  width: "100%",
  height: "500px",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
});

const MainDiv = styled("div")({
  width: "100%",
  height: "100%",
  display: "flex",
  flexDirection: "column",
});

const Content = styled("div")({
  width: "100%",
  flex: 1,

  backgroundColor: COLORS.ligth_grey,

  [lgDown]: {
    padding: "20px",
  },

  [smDown]: {
    padding: "15px",
  },
});

const Header = styled("div")({
  backgroundColor: COLORS.ligth_grey,
  display: "flex",
  flex: 0,
  justifyContent: "space-between",
  alignItems: "center",
  width: "100%",
  flexFlow: "row wrap",

  [lgDown]: {
    padding: "20px",
  },

  [mdDown]: {
    alignItems: "baseline",
  },

  [smDown]: {
    padding: "15px",
  },
});

const TitleDiv = styled("div")({
  display: "flex",
  flex: "0 0 50%",
});

const ButtonDiv = styled("div")({
  height: "38px",
  display: "flex",
  flex: "0 0 50%",
  flexDirection: "row-reverse",

  [smDown]: {
    marginBottom: "10px",
  },
});

const FilterButtons = styled("div")({
  display: "flex",
  alignContent: "center",
  alignItems: "center",
  width: "40%",
  marginBottom: "0%",

  [lgDown]: {
    width: "60%",
  },

  [smDown]: {
    alignItems: "unset",
    flexDirection: "column",
    width: "100%",
  },
});

const SearchDiv = styled("div")({
  display: "flex",
  alignItems: "center",
  marginRight: "15px",
  justifyContent: "flex-end",

  [lgDown]: {
    justifyContent: "center",
  },

  [smDown]: {
    width: "100%",
  },
});

const TableFooter = styled("div")({
  display: "flex",
  width: "98%",
  flexDirection: "row",
  justifyContent: "space-between",
  marginTop: "26px",
});

const TableDiv = styled("div")({
  width: "100%",
  marginTop: "16px",
  height: "100%",
  backgroundColor: COLORS.white,
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  borderRadius: "5px",
});

const Table = styled("table")({
  width: "98%",
  borderCollapse: "collapse",
});

const TableRow = styled("tr")({
  backgroundColor: COLORS.white,
  height: "60px",
  borderBottom: "1px solid rgba(189,189,189,0.4)",

  "&:hover": {
    backgroundColor: "#F6F6F6",
  },
});

const TableRowHeader = styled("tr")({
  backgroundColor: COLORS.white,
  height: "60px",
  borderBottom: "1px solid #BDBDBD",
});

const TableCellHeader = styled("td")({
  textAlign: "center",
  width: "20%",
  fontWeight: "600",
  "@media only screen and (max-width: 1400px) and (min-width: 1280px)": {
    fontSize: "0.6875rem",
  },
});

const EmailMobile = styled("p")({
  display: "none",
  margin: "0",
  color: "rgba(30, 30, 30, 0.5)",
  fontSize: "0.6rem",

  [smDown]: {
    display: "block",
  },
});

const TDName = styled("td")({
  fontSize: "1rem",
  paddingLeft: "20%",
  paddingTop: "10px",
  display: "flex",

  [lgDown]: {
    fontSize: "0.9rem",
    paddingLeft: "10px",
  },

  [smDown]: {
    fontSize: "0.7rem",
  },
  "@media only screen and (max-width: 1400px) and (min-width: 1280px)": {
    fontSize: "0.6875rem",
  },
});

const TDEmail = styled("td")({
  width: "23%",
  paddingLeft: "7%",
  fontSize: "1rem",

  [lgDown]: {
    fontSize: "0.9rem",
  },

  [smDown]: {
    display: "none",
  },
  "@media only screen and (max-width: 1400px) and (min-width: 1280px)": {
    fontSize: "0.6875rem",
  },
});

const TDRole = styled("td")({
  width: "23%",
  paddingLeft: "9%",
  fontSize: "1rem",

  [lgDown]: {
    fontSize: "0.9rem",
  },

  [smDown]: {
    fontSize: "0.6rem",
  },

  [mobileDown]: {
    display: "none",
  },
  "@media only screen and (max-width: 1400px) and (min-width: 1280px)": {
    fontSize: "0.6875rem",
  },
});

const TDPhone = styled("td")({
  width: "23%",
  paddingLeft: "7%",
  fontSize: "1rem",

  [mdDown]: {
    fontSize: "0.9rem",
  },

  [smDown]: {
    paddingLeft: "5px",
  },

  "@media only screen and (max-width: 1400px) and (min-width: 1280px)": {
    fontSize: "0.6875rem",
  },
});

const TDIcons = styled("td")({
  width: "8%",
  fontSize: "1rem",
  paddingLeft: "4%",

  [mdDown]: {
    fontSize: "0.9rem",
  },

  "@media only screen and (max-width: 1400px) and (min-width: 1280px)": {
    paddingLeft: 0,
  },
});

const UserList = ({ match }) => {
  const { path } = match;
  const currentUser = useStore((state) => state.currentUser);
  const [users, setUsers] = useState([]);
  const [usersDisplay, setUsersDisplay] = useState([]);
  const [totalNumber, setTotalNumber] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [userRoleFilter, setUserRoleFilter] = useState([]);
  const [reset, setReset] = useState(false);

  const [deletedUserId, setDeletedUserId] = useState("");
  const [userToBeDeleted, setUserToBeDeleted] = useState({});
  const [openDeleteModal, setOpenDeleteModal] = useState(false);
  const [openAddUserModal, setOpenAddUserModal] = useState(false);
  const [searchQuery, setSearchQuery] = useState("");

  const [loader, setLoader] = useState(true);

  const mobileDownQuery = useMediaQuery(theme1.breakpoints.down("mobile"));
  const smDownQuerry = useMediaQuery(theme1.breakpoints.down("sm"));
  const mdUpQuerry = useMediaQuery(theme1.breakpoints.up("md"));
  const lgDownQuerry = useMediaQuery(theme1.breakpoints.down("lg"));

  const deleteUser = async (id) => {
    if (currentUser.user.role === "CA") {
      await fetchWrapper.delete(`${APP_URL}/user/`, id).then((resp) => {
        setUsers((users) => users.filter((x) => x.user.id !== id));
        setReset(!reset);
        alertService.success("User succesfully deleted");
      });
    } else if (currentUser.user.role === "SA") {
      await fetchWrapper
        .delete(`${APP_URL}/shipper/users/`, id)
        .then((resp) => {
          setUsers((users) => users.filter((x) => x.user.id !== id));
          setReset(!reset);
          alertService.success("User succesfully deleted");
        });
    }
  };

  useEffect(() => {
    async function getUsers() {
      let url =
        currentUser.user.role === "CA" ||
        currentUser.user.role === "CR" ||
        currentUser.user.role === "CC"
          ? `${APP_URL}/user/cdl/`
          : `${APP_URL}/shipper/users/`;
      await fetchWrapper.get(url).then((resp) => {
        let usersList = [];
        for (let i = 0; i < numOfRows(resp); i++) {
          usersList.push(resp[i]);
        }
        setUsersDisplay(usersList);
        setUsers(
          resp
            .filter((value) => value.user.id === currentUser.user.id)
            .concat(
              resp.filter((value) => value.user.id !== currentUser.user.id)
            )
        );
        setLoader(false);
        setTotalNumber(usersList.length);
      });
    }

    getUsers();
  }, [reset]);

  const numOfRows = (item) => {
    if (item.length > 10 && window.innerWidth <= 1920) {
      return 10;
    } else {
      return item.length;
    }
  };

  useEffect(() => {
    let filteredUsers = [];
    users.map((item) => {
      if (userRoleFilter.length > 0) {
        if (roleToFullName().includes(item.user.role)) {
          if (searchQuery !== "") {
            if (
              item.user.full_name
                .toLowerCase()
                .includes(searchQuery.toLowerCase())
            ) {
              filteredUsers.push(item);
            }
          } else {
            filteredUsers.push(item);
          }
        }
      } else {
        if (
          searchQuery !== "" &&
          item.user.full_name.toLowerCase().includes(searchQuery.toLowerCase())
        ) {
          filteredUsers.push(item);
        }
      }
    });
    setTotalNumber(filteredUsers.length);
    let usersList = [];
    for (let i = currentPage * 10 - 10; i < currentPage * 10; i++) {
      if (userRoleFilter.length > 0 || searchQuery !== "") {
        if (filteredUsers[i]) {
          usersList.push(filteredUsers[i]);
        }
      } else {
        if (users[i]) {
          usersList.push(users[i]);
        }
      }
    }
    setUsersDisplay(usersList);
  }, [currentPage, userRoleFilter, searchQuery]);

  const roleToFullName = () => {
    let res = [];
    userRoleFilter.map((item) => {
      if (
        currentUser?.user?.role === "SA" ||
        currentUser?.user?.role === "SR"
      ) {
        switch (item) {
          case "Administrator":
            return res.push("SA");
          case "Regular":
            return res.push("SR");
        }
      } else {
        switch (item) {
          case "Administrator":
            return res.push("CA");
          case "Regular":
            return res.push("CR");
          case "Accountant":
            return res.push("CC");
        }
      }
    });
    return res;
  };

  function handleDelete(user) {
    setOpenDeleteModal(true);
    setUserToBeDeleted(user);
    setDeletedUserId(user?.id);
  }

  return (
    <>
      {loader ? (
        <LoaderDiv>
          <CustomLoader size="7vh" />
        </LoaderDiv>
      ) : (
        <>
          <AddUserModal2
            open={openAddUserModal}
            setOpenModal={setOpenAddUserModal}
            setReset={setReset}
            reset={reset}
          />
          <DeleteUserModal
            open={openDeleteModal}
            setOpenModal={setOpenDeleteModal}
            user={userToBeDeleted}
            deleteUser={deleteUser}
            deletedUserId={deletedUserId}
          />
          <MainDiv>
            <Header>
              <TitleDiv>
                <SearchDiv>
                  <Input
                    iconStart={SearchIcon}
                    css={{ fontSize: "16px" }}
                    placeholder="Search by name"
                    value={searchQuery}
                    onChange={(e) => {
                      setSearchQuery(e.target.value);
                    }}
                  />
                </SearchDiv>
                <FilterButtons>
                  {/* <UserRoleDropdownMenu
                    userRoleFilter={userRoleFilter}
                    setUserRoleFilter={setUserRoleFilter}
                  /> */}
                  <Select
                    icon={roleIcon}
                    placeholder="Filter by role"
                    value={userRoleFilter}
                    onChange={setUserRoleFilter}
                    options={
                      currentUser?.user?.role === "SA" ||
                      currentUser?.user?.role === "SR"
                        ? ["Administrator", "Regular"]
                        : ["Administrator", "Regular", "Accountant"]
                    }
                  />
                </FilterButtons>
              </TitleDiv>

              <ButtonDiv>
                {currentUser.user.role === "SA" ||
                currentUser.user.role === "CA" ? (
                  <Button
                    onClick={() => {
                      setOpenAddUserModal(true);
                    }}
                    type="primary"
                    text="+ Add new user"
                    icon={PlusIcon}
                  >
                    {/* <img src={PlusIcon} style={{ marginRight: "10px" }} /> */}
                  </Button>
                ) : (
                  <></>
                )}
              </ButtonDiv>
            </Header>
            <Content>
              <TableDiv>
                <Table>
                  <tbody>
                    <TableRowHeader>
                      <TableCellHeader>Name</TableCellHeader>
                      <TableCellHeader>E-mail</TableCellHeader>
                      <TableCellHeader>Role</TableCellHeader>
                      <TableCellHeader>Phone number</TableCellHeader>
                      <TableCellHeader
                        style={{
                          display: currentUser?.user?.role.includes("A")
                            ? ""
                            : "none",
                        }}
                      >
                        Action
                      </TableCellHeader>
                    </TableRowHeader>
                    {usersDisplay.length > 0 &&
                      usersDisplay.map((user) => (
                        <>
                          <TableRow key={user?.user?.id}>
                            <TDName
                              sx={{
                                paddingLeft: currentUser?.user?.role.includes(
                                  "A"
                                )
                                  ? "20%"
                                  : "27%",
                              }}
                            >
                              <AvatarIcon
                                src={`data:image/png;base64,${user?.user?.picture_64}`}
                                firstName={user?.user.first_name}
                                lastName={user?.user.last_name}
                                style={{
                                  width: mdUpQuerry
                                    ? "2.3rem"
                                    : smDownQuerry
                                    ? "1.6rem"
                                    : "2rem",
                                  height: mdUpQuerry
                                    ? "2.3rem"
                                    : smDownQuerry
                                    ? "1.6rem"
                                    : "2rem",
                                  fontSize: smDownQuerry
                                    ? mobileDownQuery
                                      ? " 10px"
                                      : "12px"
                                    : "15px",
                                  backgroundColor: "#C4C4C4",
                                  fontFamily: "Montserrat",
                                  boxShadow: "none",
                                }}
                              />
                              <p
                                style={{
                                  paddingLeft: "20px",
                                  paddingTop: "8px",
                                }}
                              >
                                {user?.user.full_name}
                              </p>
                              <EmailMobile>{user?.user.email}</EmailMobile>
                            </TDName>
                            <TDEmail
                              sx={{
                                paddingLeft: currentUser?.user?.role.includes(
                                  "A"
                                )
                                  ? "7%"
                                  : "8%",
                              }}
                            >
                              {user?.user.email}
                            </TDEmail>
                            <TDRole
                              sx={{
                                paddingLeft: currentUser?.user?.role.includes(
                                  "A"
                                )
                                  ? "9%"
                                  : "10%",
                              }}
                            >
                              {user?.user.role === "CA" ||
                              user?.user.role === "SA"
                                ? smDownQuerry
                                  ? "Admin"
                                  : "Administrator"
                                : user?.user.role === "CC"
                                ? "Accountant"
                                : "Regular"}
                            </TDRole>
                            <TDPhone
                              sx={{
                                paddingLeft: currentUser?.user?.role.includes(
                                  "A"
                                )
                                  ? "7%"
                                  : "9%",
                              }}
                            >
                              {user?.user?.phone_number ? (
                                smDownQuerry ? (
                                  <a href={`tel${user?.user?.phone_number}`}>
                                    <PhoneEnabledIcon
                                      sx={{
                                        "&:hover": {
                                          color: " #FC420C",
                                        },
                                        color: "#C4C4C4",
                                      }}
                                      style={{
                                        outline: "none",
                                      }}
                                    />
                                  </a>
                                ) : (
                                  user?.user?.phone_number
                                )
                              ) : smDownQuerry ? (
                                <PhoneDisabledIcon
                                  sx={{
                                    "&:hover": {
                                      color: " #FC420C",
                                    },
                                    color: "#C4C4C4",
                                  }}
                                  style={{
                                    outline: "none",
                                  }}
                                />
                              ) : (
                                <div
                                  style={{
                                    margin: "5%",
                                    fontSize: mdUpQuerry ? "1rem" : "0.6rem",
                                    paddingLeft: "15%",
                                  }}
                                >
                                  _
                                </div>
                              )}
                            </TDPhone>
                            {currentUser.user.role === "CA" ||
                            currentUser.user.role === "SA" ? (
                              <TDIcons>
                                <div
                                  style={{
                                    display: "flex",
                                    justifyContent: "left",
                                  }}
                                >
                                  <Link
                                    to={
                                      currentUser.user.email ===
                                      user?.user.email
                                        ? "/profile"
                                        : `${path}/${user?.user.id}`
                                    }
                                  >
                                    <Button icon={EditIcon} type="icon" />
                                  </Link>
                                  {currentUser.user.id !== user?.user.id && (
                                    <Button
                                      type="icon"
                                      icon={DeleteIcon}
                                      css={{
                                        marginLeft: mdUpQuerry ? "40px" : "0",
                                      }}
                                      onClick={() => handleDelete(user?.user)}
                                    />
                                  )}
                                </div>
                              </TDIcons>
                            ) : (
                              <></>
                            )}
                          </TableRow>
                        </>
                      ))}
                    {!users && (
                      <tr>
                        <td colSpan="4" className="text-center">
                          <span className="spinner-border spinner-border-lg align-center" />
                        </td>
                      </tr>
                    )}
                  </tbody>
                </Table>
                {window.innerWidth <= 1920 && (
                  <TableFooter>
                    <Pagination
                      totalNumber={totalNumber}
                      page={currentPage}
                      setPage={setCurrentPage}
                      itemsPerPage={10}
                    />
                  </TableFooter>
                )}
              </TableDiv>
            </Content>
          </MainDiv>
        </>
      )}
    </>
  );
};

export default UserList;
