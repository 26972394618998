import React, { useEffect, useState } from "react";

import SavedIcon from "../../assets/purple_saved.svg";
import NotSavedIcon from "../../assets/not_saved_purple.svg";
import ShowWholeRouteIcon from "../../assets/show_whole_route.svg";

import { COLORS } from "../../../../styles/themes/default/colors";
import DropDownIcon from "../../assets/drop_down.svg";
// import LocationIcon from "../../assets/location_purple.svg";
import LocationRowIcon from "../../assets/wl_location.svg";

import "../../styles/watchlist.scss";
import TableLocation from "../MutualComponents/Location";
import { colorDict, statusBoxClass } from "../../utils/contants";
import useStore from "../../../../store/store";
import GridInfo from "../MutualComponents/GridInfo";
import TrackingEventsView from "../MutualComponents/TrackingView";
import DemurrageInformation from "../MutualComponents/DemurrageInformation";
import { formatValue } from "../../../../utils/constants";
import { CustomLoader } from "../../../../components/CustomLoader";
import { useMediaQuery, createTheme } from "@mui/material";
import DetentionInformation from "../MutualComponents/DetentionInformation";
import containerStore from "../../../../store/containerStore";

const theme1 = createTheme({
  breakpoints: {
    values: {
      sl: 1400,
    },
  },
});

const StatusBar = ({ status }) => {
  const smallLaptop = useMediaQuery(theme1.breakpoints.down("sl"));
  return (
    <div
      className={statusBoxClass[status]}
      style={{
        fontSize: smallLaptop ? "11px" : "",
        width: smallLaptop ? "130px" : "",
      }}
    >
      <div className="dot" style={{ backgroundColor: colorDict[status] }} />
      {status}
    </div>
  );
};

const WatchListRow = ({
  index,
  data,
  handleFavorite,

  isInCluster,

  loadingContainer,
  setIsModalOpen,
  setIsDetentionModalOpen,
}) => {
  const currentUser = useStore((state) => state.currentUser);

  const [openDetail, setOpenDetail] = useState(null);
  // const [gradientColor, setGradientColor] = useState("#1f1f1f");
  // const [isClosed, setIsClosed] = useState(null);
  const setGetContainerId = containerStore((state) => state.setGetContainerId);
  const getContainerId = containerStore((state) => state.getContainerId);

  const smallLaptop = useMediaQuery(theme1.breakpoints.down("sl"));

  const displayPay =
    data?.current_demurrage_amount || data?.current_detention_amount;

  const [isFavorite, setIsFavorite] = useState(
    data?.favored_by.includes(currentUser.user.id)
  );
  const currentDisplayContainer = containerStore(
    (state) => state.currentDisplayContainer
  );
  const setCurrentDisplayContainer = containerStore(
    (state) => state.setCurrentDisplayContainer
  );
  // const listOfColors = [
  //   "#1f1f1",
  //   "#343434",
  //   "#4b4b4b",
  //   "#626262",
  //   "#7b7b7b",
  //   "#626262",
  //   "#4b4b4b",
  //   "#343434",
  //   "#1f1f10",
  //   "#1f1f18",
  //   "#1f1f1f",
  // ];

  // useEffect(() => {
  //   if (isClosed === false && openDetail === false) {
  //     listOfColors.forEach((color, index) => {
  //       setTimeout(() => {
  //         setGradientColor(color);
  //       }, 50 * index);
  //     });
  //   }
  // }, [isClosed]);

  useEffect(() => {
    if (currentDisplayContainer?.container_id === data?.container_id) {
      setOpenDetail(true);
    } else {
      setOpenDetail(false);
    }
  }, [currentDisplayContainer]);
  return (
    <div style={{ width: "100%" }}>
      <div
        className={openDetail ? "watch_list_row_dark" : "watch_list_row"}
        style={{
          // transition: "all 1s ease-in-out",
          marginBottom: openDetail ? "10px" : "16px",
          border: isInCluster ? `1px solid ${data.color}` : "none",
        }}
        onClick={() => {
          if (!loadingContainer) {
            if (currentDisplayContainer?.container_id === data.container_id) {
              setOpenDetail(false);
              setCurrentDisplayContainer({});
              setGetContainerId("");
            } else {
              setGetContainerId(data.container_id);
            }
          }
        }}
      >
        <div className="container_id_watch_list">#{data?.container_id} </div>

        {openDetail ? (
          <div className="watch_list_middle_cell">
            <div className="watch_list_status_cell">
              <StatusBar status={data?.current_event?.status} />
            </div>
            <div
              style={{ width: "25%", display: "flex", alignItems: "center" }}
            >
              <button
                onClick={(e) => {
                  e.stopPropagation();
                  setCurrentDisplayContainer({
                    ...currentDisplayContainer,
                    whole_route: !currentDisplayContainer?.whole_route,
                  });
                }}
                className="link_button"
              >
                <img
                  src={ShowWholeRouteIcon}
                  alt=""
                  style={{
                    marginRight: "8px",
                    width: smallLaptop ? "11px" : "16px",
                  }}
                />
                Show route
              </button>
            </div>
          </div>
        ) : (
          <div className="watch_list_middle_cell">
            <div className="watch_list_dot_cell">
              <div
                className="dot"
                style={{
                  backgroundColor: colorDict[data?.current_event?.status],
                }}
              />
            </div>
            <span className="hover_status">
              <div
                style={{
                  width: "100%",
                  height: "100%",
                  backgroundColor: `${data?.color}33`,
                  color: data?.color,
                  padding: "8px",
                  borderRadius: "8px",
                }}
              >
                {data.current_event.status}
              </div>
            </span>
            <div
              className="watch_list_location_cell"
              style={{
                width: displayPay ? "60%" : "90%",
              }}
            >
              <img
                src={LocationRowIcon}
                alt=""
                style={{ marginRight: "8px" }}
              />
              {data?.current_event?.location_name},
              {data?.current_event?.location_state}
            </div>
            {loadingContainer && getContainerId === data.container_id && (
              <div style={{ display: "flex", alignItems: "center" }}>
                <CustomLoader darktheme="true" size="3vh" />
              </div>
            )}
            {displayPay && (
              <div className="watch_list_pay_cell">
                <p style={{ paddingRight: "16px" }} className="white_text">
                  {formatValue(
                    data?.current_demurrage_amount +
                      data?.current_detention_amount
                  )}
                </p>
                <button
                  onClick={(e) => {
                    e.stopPropagation();
                    if (data?.potential_detention_amount) {
                      setIsDetentionModalOpen(true);
                    } else {
                      setIsModalOpen(true);
                    }
                    setGetContainerId(data?.container_id);
                  }}
                  className="purple_button"
                >
                  Pay
                </button>
              </div>
            )}
          </div>
        )}

        <div className="watch_list_bookmark_cell">
          <button
            onClick={(e) => {
              e.stopPropagation();
              handleFavorite(data?.container_id);
              setIsFavorite(!isFavorite);
            }}
            className="transparent_button"
          >
            <img src={isFavorite ? SavedIcon : NotSavedIcon} alt="" />
          </button>
        </div>
      </div>
      <div
        style={{
          width: "100%",
          height: openDetail ? "auto" : "0px",
          transition: "0.3s all ease-in-out",
          overflow: openDetail ? "visible" : "hidden",
        }}
      >
        <TableLocation
          data={currentDisplayContainer}
          currentEvent={currentDisplayContainer?.current_event}
          lastUpdated={currentDisplayContainer?.last_updated}
        />

        <div
          style={{
            width: "100%",
            marginTop: "8px",
            display: "flex",
          }}
        >
          <GridInfo data={currentDisplayContainer} />
          <TrackingEventsView
            data={currentDisplayContainer?.events}
            currentEvent={currentDisplayContainer?.current_event}
            color={currentDisplayContainer.color}
          />
        </div>
        {data?.potential_detention_amount ? (
          <DetentionInformation
            data={currentDisplayContainer}
            setIsModalOpen={setIsModalOpen}
            setIsDetentionModalOpen={setIsDetentionModalOpen}
          />
        ) : (
          <DemurrageInformation
            data={currentDisplayContainer}
            setIsModalOpen={setIsModalOpen}
            setIsDetentionModalOpen={setIsDetentionModalOpen}
          />
        )}
      </div>
    </div>
  );
};

export default WatchListRow;
