import {
  Badge,
  Popover,
  Box,
  Typography,
  List,
  Button,
  ListItemText,
  createTheme,
  useMediaQuery,
} from "@mui/material";
import React from "react";
import { useRef, useState, useEffect, useCallback } from "react";
import formatDistanceToNow from "date-fns/formatDistanceToNow";
// import BellIcon from "../assets/img/Bell_Icon.webp";
import { styled } from "@mui/material";
import { COLORS } from "../styles/themes/default/colors";
// import SimpleBarReact from "simplebar-react";
// import { NoteOutlined } from "@mui/icons-material";
import fetchWrapper from "../helpers/fetch-wrapper";
import { APP_URL } from "../utils/constants";
import { CustomLoader } from "./CustomLoader";
import DueDate from "../assets/img/dateIcon.svg";
import customerIcon from "../assets/img/Sidebar/customers_icon.svg";

import useStore from "../store/store";
import FiberManualRecordIcon from "@mui/icons-material/FiberManualRecord";

import NotificationBell from "../assets/img/Navbar/notification_bell.svg";

import notificationIconDemurrage from "../assets/img/Sidebar/demurrages_icon.svg";

import { useHistory } from "react-router-dom";

import HistoryIcon from "../assets/img/Navbar/notifications_time.svg";
import CloseIcon from "../assets/img/closeModalIcon.png"

import { throttle } from 'lodash';

import "../styles/templates.scss"
import containerStore from "../store/containerStore";
import store from "../store/store";

const RootStyle = styled("div")(() => ({
  flexGrow: 1,
  height: "60vh",
  maxHeight: '100%',
  overflow: "auto",
  "&::-webkit-scrollbar": {
    width: "0.3rem",
    height: "0.3rem",
  },
  "&::-webkit-scrollbar-track": {
    background: "#f1f1f1",
  },
  "&::-webkit-scrollbar-thumb": {
    backgroundColor: "#888",
    borderRadius: "20px",
  },
  "&::-webkit-scrollbar-thumb:hover": {
    background: "#555",
  },
}));

const theme1 = createTheme({
  breakpoints: {
    values: {
      sl: 1400,
    },
  },
});

export default function Notifications({ darkMode }) {
  const [open, setOpen] = useState(null);
  const [currentlyHaveOnFE, setCurrentlyHaveOnFE] = useState(0);
  const [numOfUnreadNotifications, setNumOfUnreadNotifications] = useState(0);
  const fetchNotifications = useStore((state) => state.fetchNotifications)
  const setFetchNotifications = useStore((state) => state.setFetchNotifications)
  const [loading, setLoading] = useState(false);
  const [allNotificationsCount, setAllNotificationsCount] = useState(0);
  const [filter, setFilter] = useState(false);
  const [page, setPage] = useState(0);
  const [mounted, setMounted] = useState(false);
  const [reset, setReset] = useState(false);
  const [todayNotifications, setTodayNotifications] = useState([]);
  const [yesterdayNotifications, setYesterdayNotifications] = useState([]);
  const [earlierNotifications, setEarlierNotifications] = useState([]);
  const [resetNew, setResetNew] = useState(false);
  const count = 10;

  const smallLaptop = useMediaQuery(theme1.breakpoints.down("sl"));
  const history = useHistory();
  const selectedLabel = {
    fontWeight: "600",
    fontSize: smallLaptop ? "11px" : "16px",
    borderBottom: "2px solid #EE4A26",
    cursor: "pointer"
  };
  const unSelectedLabel = {
    color: "#8C8C8C",
    fontSize: smallLaptop ? "11px" : "16px",
    cursor: "pointer"
  };

  const checkDate = (date) => {
    let today = new Date();

    if (
      date.getMonth() === today.getMonth() &&
      date.getFullYear() === today.getFullYear()
    ) {
      if (date.getDate() === today.getDate()) {
        return "today";
      } else if (date.getDate() === today.getDate() - 1) {
        return "yesterday";
      }
    }
    return "earlier";
  };

  useEffect(() => {
    async function getNumber() {
      await fetchWrapper.get(`${APP_URL}/notification/new/`).then((resp) => {
        setNumOfUnreadNotifications(resp.number_of_unread);
      });
    }
    const timer = setInterval(() => getNumber(), 2 * 60 * 1000);
    getNumber();

    return () => {
      clearInterval(timer);
    };
  }, [resetNew]);

  useEffect(() => {
    async function fetchNumber() {
      if (fetchNotifications) {
        await fetchWrapper.get(`${APP_URL}/notification/new/`).then((resp) => {
          setNumOfUnreadNotifications(resp.number_of_unread);
          setFetchNotifications(false)
        });
      }
    }
    fetchNumber();
  }, [fetchNotifications]);

  useEffect(() => {
    async function getNew() {
      await fetchWrapper.get(`${APP_URL}/notification/new/`).then((resp) => {
        setNumOfUnreadNotifications(resp.number_of_unread);
      });
    }

    async function getNotifications() {
      setLoading(true);
      await fetchWrapper
        .get(
          `${APP_URL}/notification/list_all/?count=${count}&page=${page + 1}${filter ? "&read" : "&unread"}`
        )
        .then((resp) => {
          resp.results.forEach((notification) => {
            addNotificationToArray(notification)
          });
          setCurrentlyHaveOnFE(currentlyHaveOnFE + resp.results.length);
          setAllNotificationsCount(resp.count);
          setPage(page + 1);
          setLoading(false);
        });
    }
    if (
      (currentlyHaveOnFE < allNotificationsCount ||
        allNotificationsCount === 0) &&
      mounted
    ) {
      if (!loading) {
        getNotifications();
        getNew();
      }
    }
  }, [reset, filter]);


  const addNotificationToArray = (notification) => {
    let date = new Date(notification.created_at);
    let check_date = checkDate(date);
    if (check_date === "today") {
      setTodayNotifications((todayNotifications) => [
        ...todayNotifications,
        notification,
      ]);
    } else if (check_date === "yesterday") {
      setYesterdayNotifications((yesterdayNotifications) => [
        ...yesterdayNotifications,
        notification,
      ]);
    } else {
      setEarlierNotifications((earlierNotifications) => [
        ...earlierNotifications,
        notification,
      ]);
    }
  }

  const handleOpen = (event) => {
    setMounted(true);
    setOpen(event.currentTarget);
    setReset(!reset);
  };
  const handleClose = () => {
    handleReset();
    setPage(0);
    setMounted(false);
    setOpen(null);
  };
  const handleReset = () => {
    setCurrentlyHaveOnFE(0);
    setAllNotificationsCount(0);
    setTodayNotifications([]);
    setYesterdayNotifications([]);
    setEarlierNotifications([]);
    setPage(0);
  };
  const handleAllAsSeen = async () => {
    await fetchWrapper.patch(`${APP_URL}/notification/read_all/`).then(() => {
      handleReset();
      setTodayNotifications([]);
      setYesterdayNotifications([]);
      setEarlierNotifications([]);
      setReset(!reset);
    });
  };

  const handleDeleteAll = async () => {
    await fetchWrapper.delete(`${APP_URL}/notification/delete/`).then(() => {
      handleReset();
      setReset(!reset);
    });
  };

  const handleLoadMore = async () => {

    if (loading || (currentlyHaveOnFE >= allNotificationsCount && allNotificationsCount !== 0)) return;

    setLoading(true);

    try {
      if (page * count > currentlyHaveOnFE) await fetchCurrentPage();
      if (currentlyHaveOnFE < allNotificationsCount) await getMoreNotifications();
    } catch (error) {
      console.error("An error occurred:", error);
    } finally {
      setLoading(false);
    }


    async function fetchCurrentPage() {

      await fetchWrapper
        .get(`${APP_URL}/notification/list_all/?count=${count}&page=${page}${filter ? "&read" : "&unread"}`)
        .then((resp) => {
          resp.results.forEach((notification) => {
            if (!doesIdExist(notification.id, todayNotifications, yesterdayNotifications, earlierNotifications)) {
              addNotificationToArray(notification)
              setCurrentlyHaveOnFE(prev => prev + 1);
            }
          });
          setAllNotificationsCount(resp.count);
        });

    }
    async function getMoreNotifications() {

      await fetchWrapper
        .get(`${APP_URL}/notification/list_all/?count=${count}&page=${page + 1}${filter ? "&read" : "&unread"}`)
        .then((resp) => {
          resp.results.forEach((notification) => {
            addNotificationToArray(notification)
          });

          setCurrentlyHaveOnFE(prev => prev + resp.results.length);
          setAllNotificationsCount(resp.count);
          setPage(prev => prev + 1);
        });

    }
  };

  function doesIdExist(id, array1, array2, array3) {
    return array1.some(item => item.id === id) ||
      array2.some(item => item.id === id) ||
      array3.some(item => item.id === id);
  }

  const decrementNotificationsCount = () => {
    setCurrentlyHaveOnFE(prev => prev - 1)
    setAllNotificationsCount(prev => prev - 1)
  }

  const removeNotificationById = (notificationId) => {

    const findAndRemoveNotification = (notifications, setNotifications) => {
      const index = notifications.findIndex(notification => notification.id === notificationId);
      if (index !== -1) {
        setNotifications(notifications => [
          ...notifications.slice(0, index),
          ...notifications.slice(index + 1)
        ]);
        return true;
      }
      return false;
    };

    if (findAndRemoveNotification(todayNotifications, setTodayNotifications)) return decrementNotificationsCount();
    if (findAndRemoveNotification(yesterdayNotifications, setYesterdayNotifications)) return decrementNotificationsCount();
    if (findAndRemoveNotification(earlierNotifications, setEarlierNotifications)) return decrementNotificationsCount();
    return false
  };

  const anchorRef = useRef(null);
  return (
    <>
      <button
        ref={anchorRef}
        onClick={handleOpen}
        className="navigationNotificationIcon"
        style={{
          outline: "none",
          border: open ? "1px solid #EE4A26" : "none",
          backgroundColor: darkMode ? "#313131" : COLORS.off_white,
        }}
      >
        <Badge
          badgeContent={numOfUnreadNotifications}
          overlap="circular"
          sx={{
            "& .MuiBadge-badge": {
              fontSize: 9,
              height: 13,
              minWidth: 13,
              backgroundColor: darkMode ? COLORS.primary : COLORS.error,
              color: COLORS.off_white,
              fontWeight: 600,
            },
          }}
        >
          <img src={NotificationBell} alt="bell" />
        </Badge>
      </button>
      {mounted && (
        <NotificationList
          open={Boolean(open)}
          anchorEl={open}
          onClose={handleClose}
          darkMode={darkMode}
        >
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              width: "100%",
              padding: "10px",
              paddingBottom: "0px",
            }}
          >
            <Box sx={{ flexGrow: 1 }}>
              <Box
                style={{
                  width: "100%",
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                }}
              >
                <Typography
                  style={{
                    fontWeight: "600",
                    fontSize: smallLaptop ? "11px" : "16px",
                  }}
                >
                  Notifications
                </Typography>

                <Typography
                  onClick={() => filter ? handleDeleteAll() : handleAllAsSeen()}
                  style={{
                    color: "#41BAEE",
                    fontWeight: "600",
                    fontSize: smallLaptop ? "10px" : "14px",
                    cursor: "pointer",
                  }}
                >
                  {filter ? "Remove all" : "Mark all as seen "}
                </Typography>
              </Box>
              <Box
                style={{
                  width: "45%",
                  display: "flex",
                  gap: '20px',
                  marginTop: "5%",
                  marginBottom: "3%",
                }}
              >
                <Typography
                  style={!filter ? selectedLabel : unSelectedLabel}
                  onClick={() => {
                    handleReset();
                    setFilter(false);
                  }}
                >
                  Unread
                </Typography>
                <Typography
                  style={filter ? selectedLabel : unSelectedLabel}
                  onClick={() => {
                    handleReset();
                    setFilter(true);
                  }}
                >
                  Read
                </Typography>
              </Box>
            </Box>
          </Box>
          <Scrollbar
            sx={{ height: { xs: "auto", sm: "auto" } }}
            size_of_notifications={currentlyHaveOnFE}
            count={allNotificationsCount}
            loading={loading}
            handleLoadMore={handleLoadMore}
          >
            {todayNotifications.length > 0 && (
              <NotificationSubList headerText={"Today"}>
                <List disablePadding>
                  {todayNotifications.map((item) => {
                    return (
                      <NotificationItem
                        key={item.id}
                        handleClose={handleClose}
                        removeNotificationById={removeNotificationById}
                        history={history}
                        notification={item}
                        style={{ color: "red" }}
                        setNumOfUnreadNotifications={setNumOfUnreadNotifications}
                        darkMode={darkMode}
                      />
                    );
                  })}
                </List>
              </NotificationSubList>
            )}
            {yesterdayNotifications.length > 0 && (
              <NotificationSubList headerText={"Yesterday"}>
                <List disablePadding>
                  {yesterdayNotifications.map((item) => {
                    return (
                      <NotificationItem
                        key={item.id}
                        handleClose={handleClose}
                        removeNotificationById={removeNotificationById}
                        history={history}
                        notification={item}
                        style={{ color: "red" }}
                        setNumOfUnreadNotifications={setNumOfUnreadNotifications}
                        darkMode={darkMode}
                      />
                    );
                  })}
                </List>
              </NotificationSubList>
            )}
            {earlierNotifications.length > 0 && (
              <NotificationSubList headerText={"Older"}>
                <List disablePadding>
                  {earlierNotifications.map((item) => {
                    return (
                      <NotificationItem
                        key={item.id}
                        handleClose={handleClose}
                        removeNotificationById={removeNotificationById}
                        history={history}
                        notification={item}
                        style={{ color: "red" }}
                        setNumOfUnreadNotifications={setNumOfUnreadNotifications}
                        darkMode={darkMode}
                      />
                    );
                  })}
                </List>
              </NotificationSubList>
            )}
            {currentlyHaveOnFE === 0 && !loading && (
              <Box
                style={{
                  width: "100%",
                  display: "flex",
                  justifyContent: "center",
                  marginTop: "20px",
                }}
              >
                <Typography style={{ alignText: "center" }}>
                  {filter
                    ? "You have 0 notifications"
                    : "You have 0 unread notifications"}
                </Typography>
              </Box>
            )}
            {loading && (
              <Box style={{ display: "flex", justifyContent: "center" }}>
                <CustomLoader />
              </Box>
            )}
          </Scrollbar>
        </NotificationList>
      )}
    </>
  );
}

function Scrollbar({
  children,
  sx,
  size_of_notifications,
  count,
  loading,
  handleLoadMore
}) {
  const listInnerRef = useRef();

  const onScroll = () => {
    if (listInnerRef.current && size_of_notifications < count && !loading) {

      const { scrollTop, scrollHeight, clientHeight } = listInnerRef.current;

      if (Math.ceil(scrollTop + clientHeight) >= scrollHeight) {
        handleLoadMore();
      }
    }
  };

  const throttledOnScroll = throttle(onScroll, 200);

  return (
    <RootStyle onScroll={throttledOnScroll} ref={listInnerRef}>
      {children}
    </RootStyle>
  );
}
function NotificationList({ children, sx, darkMode, ...other }) {
  const smallLaptop = useMediaQuery(theme1.breakpoints.down("sl"));
  return (
    <Popover
      anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
      transformOrigin={{ vertical: "top", horizontal: "right" }}
      PaperProps={{
        elevation: 0,
        sx: {
          width: smallLaptop ? 320 : 440, // 250: 360
          overflow: "inherit",
          p: 0,
          mt: 1,
          "@media only screen and (max-width: 1365px) and (min-width: 1279px)":
          {
            ml: 0,
          },
          "@media only screen and (max-width: 1439px) and (min-width: 1365px)":
          {
            ml: 0,
          },
          "@media only screen and (max-width: 1599px) and (min-width: 1439px)":
          {
            ml: 33,
          },
          "@media only screen and (max-width: 1679px) and (min-width: 1599px)":
          {
            ml: 15,
          },
          "@media only screen and (max-width: 1900px) and (min-width: 1679px)":
          {
            ml: 6,
          },
          "@media (min-width: 1900px)": {
            ml: 0,
          },
        },
        style: {
          backgroundColor: darkMode ? "#313131" : COLORS.white,
          color: darkMode ? COLORS.off_white : COLORS.off_black,
          border: "1px solid #EE4A264D",
          borderRadius: "8px",
        },
      }}
      {...other}
    >
      {children}
    </Popover>
  );
}

function NotificationSubList({ children, headerText }) {
  const smallLaptop = useMediaQuery(theme1.breakpoints.down("sl"));
  return (
    <Box style={{ padding: "16px" }}>
      <Typography
        style={{
          width: "100%",
          textAlign: "left",
          fontWeight: "600",
          fontSize: smallLaptop ? "11px" : "16px",
        }}
      >
        {headerText}
      </Typography>
      {children}
    </Box>
  );
}

function NotificationItem({ notification, history, handleClose, removeNotificationById, darkMode, setNumOfUnreadNotifications, currentlyHaveOnFE }) {
  const currentUser = useStore((state) => state.currentUser);

  const setGetContainerId = containerStore((state) => state.setNotificationContainerId)
  const setContainerUrlRouter = containerStore((state) => state.setContainerUrlRouter)
  const [isButtonFadingOut, setIsButtonFadingOut] = useState(false);
  const [shipper, setShipper] = useState("");
  const [notificationText, setNotificationText] = useState("");
  const [containerId, setContainerId] = useState("");

  useEffect(() => {
    if (currentUser?.user?.role === "SA" || currentUser?.user?.role === "SR") {
      setNotificationText(
        notification.text.substring(0, notification.text.indexOf("."))
      );
      setContainerId(
        notification.text.substring(
          notification.text.indexOf(".") + 1,
          notification.text.length
        )
      );
    } else {
      setShipper(
        notification.text.substring(0, notification.text.indexOf("last"))
      );
      setNotificationText(
        notification.text.substring(
          notification.text.indexOf("last"),
          notification.text.indexOf(".")
        )
      );
      setContainerId(
        notification.text.substring(
          notification.text.indexOf(".") + 1,
          notification.text.length
        )
      );
    }
  }, []);

  function getTime(date) {
    return formatDistanceToNow(new Date(date), {
      addSuffix: true,
    });
  }
  const getNotificationIcon = (type) => {
    if (type === "DEMURRAGE_STATUS_CHANGE") {
      return notificationIconDemurrage;
    } else if (type === "DUE_DATE") {
      return DueDate;
    } else if (type === "NEW_USER") {
      return customerIcon;
    } else if (type === "SHIPPER_UPDATES") {
      return customerIcon;
    } else if (type === "DEMURRAGE_UPDATES") {
      return notificationIconDemurrage;
    } else {
      return customerIcon;
    }
  };


  const handleXClick = async (event, notification) => {
    event.stopPropagation()
    await fetchWrapper
      .delete(`${APP_URL}/notification/delete/${notification.id}/`)
      .then(() => {
        setIsButtonFadingOut(true)
        setTimeout(() => {
          setIsButtonFadingOut(false)
          removeNotificationById(notification.id)
        }, 500)
      });
  };

  const handleCheckClick = async (event, notification) => {
    event.stopPropagation()
    await fetchWrapper
      .patch(`${APP_URL}/notification/read/${notification.id}/`)
      .then(() => {
        setIsButtonFadingOut(true)
        setTimeout(() => {
          setIsButtonFadingOut(false)
          removeNotificationById(notification.id)
        }, 500)
        setNumOfUnreadNotifications(prevValue => prevValue - 1)
      });
  }

  const handleClickNotification = async (notification) => {
    await fetchWrapper
      .post(`${APP_URL}/notification/opened/${notification.id}/`)
      .then(() => {
        handleClose();
        if (notification.link) {
          if (notification.link.includes('containers')) {
            const parts = notification.link.split('/');
            const index = parts.indexOf('containers');
            if (index !== -1 && index + 1 < parts.length) {
              setContainerUrlRouter(["visibility/alert_containers"])
              setGetContainerId(parts[index + 1])

              history.push("/containers")
            }
          }
          else history.push(notification.link);
          window.location.reload();
        }
      });
  };
  const smallLaptop = useMediaQuery(theme1.breakpoints.down("sl"));
  return (
    <Button
      onClick={() => handleClickNotification(notification)}
      id={notification.id}
      sx={{
        width: "100%",
        position: 'relative',
        padding: smallLaptop ? "10px" : "16px",
        textAlign: "left",
        marginBottom: "8px",
        border: darkMode
          ? `1px solid ${COLORS.off_black}`
          : "1px solid rgba(140, 140, 140, 0.4)",
        borderRadius: "8px",
        backgroundColor: darkMode ? COLORS.off_black : COLORS.off_white,
        // backgroundColor: !notification.is_opened ? "#F2F1F1" : "white",
        "&:hover": {
          // color: COLORS.sunrise_orange,
          background: darkMode ? COLORS.off_black : "#FAFAFA",
          border: `1px solid ${COLORS.primary}4d`,
          // backgroundColor: "transparent",
        },
        "&:focus": {
          outline: "none",
        },
        ...(isButtonFadingOut && {
          animation: 'fadeOut 500ms forwards'
        })
      }}
    >
      <div
        onClick={(event) => notification.is_read ? handleXClick(event, notification) : handleCheckClick(event, notification)}
        style={{
          cursor: "pointer",
          zIndex: '10',
          position: 'absolute',
          right: "10px",
          top: '5px',
        }}
      >
        {notification.is_read ? (
          <img
            src={CloseIcon} // tmp solution of check icon
            alt=""
            style={{
              height: "15px",
              width: "15px",
            }}
          />
        ) : (
          <div
            style={{
              height: "12px",
              width: "12px",
              backgroundColor: COLORS.primary,
              borderRadius: "50%",
            }}
          />
        )

        }
      </div>

      <ListItemText
        secondary={
          <div style={{ display: "flex" }}>
            <img
              src={getNotificationIcon(notification.type)}
              style={{
                width: smallLaptop ? "11px" : "17px",
                height: smallLaptop ? "11px" : "20px",
                marginRight: "10px",
              }}
              alt=""
            />
            <div>

              <Typography
                style={{
                  textTransform: "none",
                  color: darkMode ? COLORS.off_white : "black",
                  fontWeight: "600",
                  fontSize: smallLaptop ? "11px" : "16px",
                  width: '80%',
                }}
              >
                {notification.title}
              </Typography>
              <Typography
                sx={{
                  display: "flex",
                  alignItems: "center",
                  color: darkMode ? COLORS.off_white : "#1F1F1F",
                  marginTop: "16px",
                  textTransform: "none",
                  fontSize: smallLaptop ? "11px" : "14px",
                }}
              >
                {notification.title === "Container at risk" ? (
                  <>
                    {currentUser?.user?.role === "SA" ||
                      currentUser?.user?.role === "SR" ? (
                      <div>
                        <p>{notificationText}</p>
                        <div style={{ display: "flex" }}>
                          <FiberManualRecordIcon
                            style={{
                              color: "#FF6B4A",
                              width: "10px",
                              marginRight: "5px",
                            }}
                          />
                          <p style={{ color: "#FF6B4A", fontWeight: 600 }}>
                            {containerId}
                          </p>
                        </div>
                      </div>
                    ) : (
                      <div>
                        <p>
                          <b>{shipper}</b>
                          {notificationText}
                        </p>
                        <div style={{ display: "flex" }}>
                          <FiberManualRecordIcon
                            style={{
                              color: "#FF6B4A",
                              width: "10px",
                              marginRight: "5px",
                            }}
                          />
                          <p style={{ color: "#FF6B4A", fontWeight: 600 }}>
                            {containerId}
                          </p>
                        </div>
                      </div>
                    )}
                  </>
                ) : (
                  <>{notification.text}</>
                )}
              </Typography>

              <Typography
                variant="caption"
                sx={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "end",
                  marginTop: "16px",
                  // color:
                  //   notification.priority === "HIGH" && !notification.is_opened
                  //     ? COLORS.sunrise_orange
                  //     : "text.disabled",
                  textTransform: "none",
                  color: COLORS.dark_grey,
                  fontSize: smallLaptop ? "11px" : "16px",
                }}
              >
                <img
                  style={{
                    width: smallLaptop ? "11px" : "16px",
                    height: smallLaptop ? "11px" : "16px",
                    marginRight: "2%",
                  }}
                  src={HistoryIcon}
                  alt="time icon"
                />
                {getTime(notification.created_at)}
              </Typography>
            </div>
          </div>
        }
      />
    </Button>
  );
}
