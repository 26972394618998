import React from "react";
import { kFormatNumber } from "../../utils/functions";
import FiberManualRecordIcon from "@mui/icons-material/FiberManualRecord";

const EmissionAvoided = (props) => {
  //   console.log(props.data.esg_emissions_avoided);
  const totalEmission = props.data.esg_per_state.total;
  const goal = props.data.esg_emissions_avoided.emissions_avoided_goal;
  const goalTotalPercentage =
    ((props.data.esg_emissions_avoided.alt_fuel_co2_saving +
      props.data.esg_emissions_avoided.street_turns_co2_saving) *
      100) /
    goal;

  const altFuelGoalPercentage =
    (props.data.esg_emissions_avoided.alt_fuel_co2_saving /
      ((goalTotalPercentage * goal) / 100)) *
    100;

  const streetTurnGoalPercentage =
    (props.data.esg_emissions_avoided.street_turns_co2_saving /
      ((goalTotalPercentage * goal) / 100)) *
    100;

  const totalEmissionPercentage =
    ((props.data.esg_emissions_avoided.alt_fuel_co2_saving +
      props.data.esg_emissions_avoided.street_turns_co2_saving) *
      100) /
    totalEmission;
  const altFuelTotalEmission =
    (props.data.esg_emissions_avoided.alt_fuel_co2_saving /
      ((totalEmissionPercentage * totalEmission) / 100)) *
    100;
  const streetTurnTotalEmission =
    (props.data.esg_emissions_avoided.street_turns_co2_saving /
      ((totalEmissionPercentage * totalEmission) / 100)) *
    100;
  return (
    <div className="emissionsDiv">
      <p className="p-16-b-white">Emissions Avoided</p>
      <div className="co2Labels">
        <p
          className="p-24-b-white"
          style={{
            marginBottom: "16px",
          }}
        >
          {kFormatNumber(props.data.esg_emissions_avoided?.emission_avoided)}{" "}
          tCO<sub>2</sub>e
        </p>
      </div>
      <div className="lineProcentsLabel">
        <p
          className="c-white fw600 m0"
          style={{ color: "#c8df6e", fontSize: "24px" }}
        >
          {goalTotalPercentage === Number.POSITIVE_INFINITY
            ? 0
            : goalTotalPercentage.toFixed(2)}
          %
        </p>
        <div className="lineProcents">
          <p className="p-16-gray" style={{ margin: 0 }}>
            Goal complete:
          </p>
          <div style={{ display: "flex", alignItems: "center", gap: "4px" }}>
            <p className="c-white fw600 m0" style={{ color: "#C8DF6E" }}>
              {kFormatNumber(props.data.esg_per_state.total / 10)}
            </p>
            <p className="c-white m0">
              tCO<sub>2</sub>e
            </p>
          </div>
        </div>
      </div>
      <div className="lineProcents">
        <div
          style={{
            height: "13px",
            borderRadius: "0.5rem",
            width: "100%",
            margin: 0,
            backgroundColor: "#C8DF6E33",
            position: "relative",

            // "linear-gradient(to right,#00FF65,rgba(0, 255, 101, 0.2))",
          }}
        >
          <div
            style={{
              position: "absolute",
              top: "0px",
              width: `${
                goalTotalPercentage > 100 ? 100 : goalTotalPercentage
              }%`,
              height: "100%",
              borderRadius: "0.5rem",
              overflow: "hidden",
              display: "flex",
            }}
          >
            <div
              style={{
                width: `${altFuelGoalPercentage}%`,
                height: "100%",
                background: "linear-gradient(90deg, #C8DF6E 0%, #EFFFAF 100%)",
              }}
            />
            <div
              style={{
                width: `${streetTurnGoalPercentage}%`,
                height: "100%",
                background: "linear-gradient(90deg, #00FF65 0%, #A5FFC8 100%)",
              }}
            />
          </div>
        </div>
      </div>
      <div
        style={{
          display: "flex",
          gap: "4px",
          marginBottom: "16px",
          marginTop: "8px",
        }}
      >
        <p
          className="c-white m0"
          style={{ color: "#C8DF6E", fontSize: "14px" }}
        >
          {goalTotalPercentage === Number.POSITIVE_INFINITY
            ? 0
            : ((goalTotalPercentage * altFuelGoalPercentage) / 100).toFixed(2)}
          %
        </p>
        <p className="c-dark-grey m0">+</p>
        <p
          className="c-white m0"
          style={{ color: "#23FF7A", fontSize: "14px" }}
        >
          {goalTotalPercentage === Number.POSITIVE_INFINITY
            ? 0
            : ((goalTotalPercentage * streetTurnGoalPercentage) / 100).toFixed(
                2
              )}
          %
        </p>
      </div>
      <div className="lineProcentsLabelBottom">
        <p
          className="c-white fw600 m0"
          style={{ fontSize: "24px", color: "#00ff65" }}
        >
          {totalEmissionPercentage === Number.POSITIVE_INFINITY
            ? 0
            : totalEmissionPercentage.toFixed(2)}
          %
        </p>
        <p style={{ color: "#51DA87", margin: 0 }}>Total emission avoided</p>
      </div>
      <div className="lineProcents">
        <div
          style={{
            height: "13px",
            borderRadius: "0.5rem",
            width: "100%",
            margin: 0,
            backgroundColor: "#00FF6533",
            position: "relative",
            overflow: "hidden",

            // "linear-gradient(to right,#00FF65,rgba(0, 255, 101, 0.2))",
          }}
        >
          <div
            style={{
              position: "absolute",
              top: "0px",
              width: `${totalEmissionPercentage}%`,
              height: "100%",
              borderRadius: "0.5rem",
              overflow: "hidden",
              display: "flex",
            }}
          >
            <div
              style={{
                width: `${altFuelTotalEmission}%`,
                height: "100%",
                background: "linear-gradient(90deg, #C8DF6E 0%, #EFFFAF 100%)",
              }}
            />
            <div
              style={{
                width: `${streetTurnTotalEmission}%`,
                height: "100%",
                background: "linear-gradient(90deg, #00FF65 0%, #A5FFC8 100%)",
              }}
            />
          </div>
        </div>
      </div>
      <div
        style={{
          display: "flex",
          gap: "4px",
          marginBottom: "24px",
          marginTop: "8px",
        }}
      >
        <p
          className="c-white m0"
          style={{ color: "#C8DF6E", fontSize: "14px" }}
        >
          {totalEmissionPercentage === Number.POSITIVE_INFINITY
            ? 0
            : ((totalEmissionPercentage * altFuelTotalEmission) / 100).toFixed(
                2
              )}
          %
        </p>
        <p className="c-dark-grey m0">+</p>
        <p
          className="c-white m0"
          style={{ color: "#23FF7A", fontSize: "14px" }}
        >
          {totalEmissionPercentage === Number.POSITIVE_INFINITY
            ? 0
            : (
                (totalEmissionPercentage * streetTurnTotalEmission) /
                100
              ).toFixed(2)}
          %
        </p>
      </div>
      <div
        style={{
          display: "flex",
          width: "100%",
          justifyContent: "space-evenly",
        }}
      >
        <div
          style={{
            margin: "0",
            display: "flex",
            alignItems: "center",
            gap: "8px",
          }}
        >
          <FiberManualRecordIcon style={{ color: "#C8DF6E", width: "8px" }} />
          <p className="p-16-white" style={{ margin: "0" }}>
            Alternative Fuel
          </p>
        </div>
        <div
          style={{
            margin: "0",
            display: "flex",
            alignItems: "center",
            gap: "8px",
          }}
        >
          <FiberManualRecordIcon style={{ color: "#00FF65", width: "8px" }} />
          <p className="p-16-white" style={{ margin: "0" }}>
            Street Turns
          </p>
        </div>
      </div>
    </div>
  );
};

export default EmissionAvoided;
