import React, { useEffect, useState } from "react";

import "../styles/setgoals.scss";
import { COLORS } from "../../../styles/themes/default/colors";
import { Switch, styled } from "@mui/material";
import EsgTextField from "./EsgTextFields";
import {
  alternative_fuels_goals,
  alternative_fuels_goals_per_state,
  carbon_goals,
  diverse_goals,
  max_anual_emission,
} from "../utils/scope3goalsMock";

const IOSSwitch = styled((props) => (
  <Switch focusVisibleClassName=".Mui-focusVisible" disableRipple {...props} />
))(({ theme }) => ({
  width: 42,
  height: 26,
  padding: 0,
  margin: 0,

  //   "& .css-5ryogn-MuiButtonBase-root-MuiSwitch-switchBase.Mui-disabled": {
  //     color: COLORS.sunrise_orange,
  //   },
  "& .css-5ryogn-MuiButtonBase-root-MuiSwitch-switchBase.Mui-checked.Mui-disabled":
    {
      color: COLORS.purple,
    },

  "& .MuiSwitch-switchBase": {
    padding: 0,
    margin: 2,
    transitionDuration: "300ms",
    color: COLORS.purple,
    "&.Mui-checked": {
      transform: "translateX(16px)",
      color: "#fff",
      "& + .MuiSwitch-track": {
        backgroundColor: COLORS.purple,
        color: COLORS.purple,
        opacity: 1,
        border: 0,
      },
      "&.Mui-disabled + .MuiSwitch-track": {
        opacity: 0.5,
      },
    },
    "&.Mui-focusVisible .MuiSwitch-thumb": {
      color: "#33cf4d",
      border: "6px solid #fff",
    },
    "&.Mui-disabled .MuiSwitch-thumb": {
      opacity: 0.7,
    },
    "&.Mui-disabled + .MuiSwitch-track": {
      opacity: 0.7,
    },
  },
  "& .MuiSwitch-thumb": {
    boxSizing: "border-box",
    width: 22,
    height: 22,
  },
  "& .MuiSwitch-track": {
    borderRadius: 26 / 2,
    backgroundColor: COLORS.off_white,
    border: "1px solid rgba(140, 140, 140, 0.4)",
    opacity: 1,
    // transition: theme.transitions.create(["background-color"], {
    //   duration: 500,
    // }),
  },
}));
const HeaderTemplateBox = (props) => {
  return (
    <>
      <div
        style={{
          marginBottom: "16px",
          display: "flex",
          width: "100%",
          justifyContent: "space-between",
        }}
      >
        <p className="c-purple m0 fw600">{props.title}</p>
        {props.should_contain_switch && (
          <div style={{ marginRight: "209px" }}>
            <IOSSwitch
              checked={props.activeSections[props.label]}
              onChange={() => {
                props.setActiveSections({
                  ...props.activeSections,
                  [props.label]: !props.activeSections[props.label],
                });
              }}
            />
          </div>
        )}
      </div>
      <p className="c-dark-grey m0" style={{ marginBottom: "8px" }}>
        {props.subtitle}
      </p>
    </>
  );
};

const SingleRow = (props) => {
  const [data, setData] = useState(props.item);
  useEffect(() => {
    setData(props.item);
  }, [props.data]);
  return (
    <div className="set_goals_single_row_box">
      <div className="set_goals_single_row_title">
        <div className="set_goals_single_row_dot" />
        <p className="c-dark-grey m0 fw600">{data.title}</p>
      </div>
      <div className="set_goals_single_row_title">
        {data.dont_show_switch ? (
          <></>
        ) : (
          <IOSSwitch
            checked={data.active}
            onChange={() => {
              let data1 = [...props.data];
              data1[props.id] = {
                ...props.data[props.id],
                active: !props.data[props.id].active,
              };
              props.setData(data1);
            }}
          />
        )}
        <div
          style={{
            display: "flex",
            alignItems: "center",
            visibility: data.active ? "visible" : "hidden",
          }}
        >
          <EsgTextField
            styles={{ width: "120px", marginLeft: "24px" }}
            value={data.value}
          />
          {data.unit ? (
            <p className="c-dark-grey m0 fw600" style={{ marginLeft: "16px" }}>
              tCO<sub>2</sub>e
            </p>
          ) : (
            <p
              className="c-dark-grey m0 fw600"
              style={{ marginLeft: "16px", width: "49px" }}
            >
              %
            </p>
          )}
        </div>
      </div>
    </div>
  );
};

const SetGoalsSettings = () => {
  const [maxAnualEmision, setMaxAnualEmision] = useState(max_anual_emission);
  const [alternativeFuelsGoalsByState, setAlternativeFuelsGoalsByState] =
    useState(alternative_fuels_goals_per_state);
  const [carbonGoals, setCarbonGoals] = useState(carbon_goals);
  const [diverseGoals, setDiverseGoals] = useState(diverse_goals);
  const [alternativeFuelsGoals, setAlternativeFuelsGoals] = useState(
    alternative_fuels_goals
  );
  const [activeSections, setActiveSections] = useState({
    fuel_state: true,
    fuel: true,
    diverse: true,
    carbon: true,
  });

  return (
    <div className="set_goals_main_box">
      <div className="set_goals_column_box">
        <div
          className="set_goals_template_box"
          style={{ height: "auto", width: "100%" }}
        >
          <HeaderTemplateBox
            label="max_anual"
            title="Max Annual Carbon Emission Goals"
            subtitle="Set an annual ceiling target for transportation and distribution"
          />
          {maxAnualEmision.map((item, index) => {
            return (
              <SingleRow
                item={item}
                key={index}
                id={index}
                data={maxAnualEmision}
                setData={setMaxAnualEmision}
              />
            );
          })}
        </div>
        <div
          className="set_goals_template_box"
          style={{
            flex: 1,
            width: "100%",
          }}
        >
          <HeaderTemplateBox
            title="Alternative Fuels Goals"
            subtitle="Set a target state emission distribution"
            should_contain_switch={true}
            setActiveSections={setActiveSections}
            activeSections={activeSections}
            label="fuel_state"
            add_goal={true}
          />
          <div
            style={{
              pointerEvents: activeSections.fuel_state ? "auto" : "none",
              opacity: activeSections.fuel_state ? "1" : "0.4",
            }}
          >
            {alternativeFuelsGoalsByState.map((item, index) => {
              return (
                <SingleRow
                  item={item}
                  key={index}
                  id={index}
                  data={alternativeFuelsGoalsByState}
                  setData={setAlternativeFuelsGoalsByState}
                />
              );
            })}
          </div>
        </div>
      </div>
      <div className="set_goals_column_box">
        <div
          className="set_goals_template_box"
          style={{ flex: 1, height: "auto" }}
        >
          <HeaderTemplateBox
            title="Alternative Fuels Goals"
            subtitle="Set granual goals in an effort to avoid CO2 emissions"
            should_contain_switch={true}
            setActiveSections={setActiveSections}
            activeSections={activeSections}
            label="fuel"
          />
          <div
            style={{
              pointerEvents: activeSections.fuel ? "auto" : "none",
              opacity: activeSections.fuel ? "1" : "0.4",
            }}
          >
            {alternativeFuelsGoals.map((item, index) => {
              return (
                <SingleRow
                  item={item}
                  key={index}
                  id={index}
                  data={alternativeFuelsGoals}
                  setData={setAlternativeFuelsGoals}
                />
              );
            })}
          </div>
        </div>
        <div
          className="set_goals_template_box"
          style={{ flex: 1, height: "auto" }}
        >
          <HeaderTemplateBox
            title="Diverse Procurement Goals"
            subtitle="Set diversity and inclusivity targets of the total transportation volume"
            should_contain_switch={true}
            setActiveSections={setActiveSections}
            activeSections={activeSections}
            label="diverse"
          />
          <div
            style={{
              pointerEvents: activeSections.diverse ? "auto" : "none",
              opacity: activeSections.diverse ? "1" : "0.4",
            }}
          >
            {diverseGoals.map((item, index) => {
              return (
                <SingleRow
                  item={item}
                  key={index}
                  id={index}
                  data={diverseGoals}
                  setData={setDiverseGoals}
                />
              );
            })}
          </div>
        </div>
        <div
          className="set_goals_template_box"
          style={{ flex: 1, height: "auto" }}
        >
          <HeaderTemplateBox
            title="Carbon Offset Goals"
            subtitle="Set target investments into the renewable energy projects"
            should_contain_switch={true}
            setActiveSections={setActiveSections}
            activeSections={activeSections}
            label="carbon"
          />
          <div
            style={{
              pointerEvents: activeSections.carbon ? "auto" : "none",
              opacity: activeSections.carbon ? "1" : "0.4",
            }}
          >
            {carbonGoals.map((item, index) => {
              return (
                <SingleRow
                  item={item}
                  key={index}
                  id={index}
                  data={carbonGoals}
                  setData={setCarbonGoals}
                />
              );
            })}
          </div>
        </div>
      </div>
    </div>
  );
};

export default SetGoalsSettings;
