import React from "react";

const EmissionPerShipmentGraph = (props) => {
  const value = props?.value ? parseFloat(props.value) * 2 : 0;
  let bars = Array.from({ length: 10 }, (_, i) => i + 1);
  return (
    <div
      style={{
        width: "157px",
        height: "87px",
        borderRadius: "8px",
        border: "2px solid #8C8C8C",
        display: "flex",
        justifyContent: "space-evenly",
        alignItems: "center",
        overflow: "hidden",
      }}
    >
      {bars.map((item) => {
        let percentageValue =
          item <= value
            ? 100
            : item > value && value > item - 1
            ? parseInt(value * 100) % 100
            : 0;

        return (
          <div
            key={item}
            style={{
              height: "85%",
              width: "3px",
              backgroundColor: "#8c8c8c66",
              display: "flex",
              justifyContent: "flex-end",
              flexDirection: "column",
              transition: "0.3s all linear",
            }}
          >
            <div
              style={{
                transition: "0.3s all linear",

                height: `${percentageValue}%`,
                width: "3px",
                backgroundColor: "#FF1DA4",
              }}
            />
          </div>
        );
      })}
    </div>
  );
};

export default EmissionPerShipmentGraph;
