import React from "react";
import Chart from "react-apexcharts";
import FiberManualRecordIcon from "@mui/icons-material/FiberManualRecord";
import useStore from "../../../../store/store";
import { kFormatNumber } from "../../utils/functions";
import { useMediaQuery } from "@mui/material";

// const customData = [
//   { name: "California", value: 860340 },
//   { name: "Texas", value: 218400 },
//   { name: "Maryland", value: 1228030 },
//   { name: "Illinois", value: 152800 },
//   { name: "Kansas", value: 112800 },
//   { name: "Others", value: 334800 },
// ];

const colors = ["#FF1DA4", "#0FF", "#CE42FF", "#0B51F7", "#00FF65", "#CBFF00"];

export default function RadialBarEsg(data = {}) {
  const currentUser = useStore((state) => state.currentUser);

  var options = {
    plotOptions: {
      radialBar: {
        track: {
          show: true,
          background: "#353535",
        },
        hollow: {
          size: "65%",
        },
        startAngle: -90,
        endAngle: 90,
        dataLabels: {
          name: {
            fontSize: "22px",
          },
          value: {
            fontSize: "20px",
          },
          // total: {
          //   show: true,
          //   label: "Total",
          //   formatter: function (w) {
          //     // By default this function returns the average of all series. The below is just an example to show the use of custom formatter function
          //     return "28K";
          //   },
          // },
        },
        grid: {
          padding: {},
        },
      },
    },
    stroke: {
      width: 0.2,
    },
    colors: colors,
    grid: {
      padding: {
        bottom: -140,
        left: -50,
        right: -50,
        top: -10,
      },
    },
  };
  var series = data?.data?.states
    ? data.data.states.slice(0, 6).map((state) => {
        if (data.data.total > 0) {
          return `${Math.round((state.value.total / data.data.total) * 100)}`;
        } else {
          return "0";
        }
      })
    : ["95", "87", "35", "85", "77", "62"];

  const largeScreen = useMediaQuery("(min-height:1200px");

  if (series.length < 6) {
    while (series.length < 6) {
      series.push("0");
    }
  }
  return (
    <div className="stateChartDiv" style={{}}>
      <div
        id="chart"
        className={`p-relative mb16 ${
          currentUser.user.role.startsWith("S") ? "mt16" : ""
        }`}
      >
        <Chart
          options={options}
          series={series}
          type="radialBar"
          width="100%"
        />
        <div
          className="p-absolute w100 d-flex justify-center f-dir-col"
          style={{
            bottom: "5%",
          }}
        >
          <p className="c-white fw600 fs32 t-align-center m0">
            {kFormatNumber(data?.data?.total)}
          </p>
          <p className="c-dark-grey fw600 t-align-center m0">Total tCO2e</p>
        </div>
      </div>

      <div
        style={{
          display: largeScreen ? "block" : "grid",
          gridTemplateColumns: "40% 35% 25%",
          // gap: "24px",
        }}
      >
        {data?.data?.states ? (
          data.data.states.slice(0, 6).map((item, index) => {
            return (
              <div
                style={{
                  display: largeScreen ? "flex" : "block",
                  marginBottom: largeScreen ? "8px" : "0px",
                }}
                key={index}
              >
                <div className="stateEmmisionsDetailsRow">
                  <FiberManualRecordIcon
                    style={{
                      color: colors[index],
                      width: "8px",
                      marginRight: "5px",
                    }}
                  />
                  <p
                    className="fs14 c-dark-grey m0"
                    style={{ textTransform: "capitalize" }}
                  >
                    {item.name}
                  </p>
                </div>
                <p className="c-white fw600 fs14 m0">
                  {kFormatNumber(item.value.total)} tCO2e
                </p>
              </div>
            );
          })
        ) : (
          <></>
        )}
      </div>
    </div>
  );
}
