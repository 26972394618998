import moment from "moment";

export const drawerWidth = window.innerWidth > 1920 ? "220px" : "12.5%";

export const CONTAINER_URL = "https://visibility-dev-api.cdl1000.com/api/v1/";
export const APP_URL = "https://daas-dev-api.cdl1000.com";
export const MCLOUD_APP_URL = "https://daas-dev-mcleod-api.cdl1000.com/api/v1/";
export const GOOGLE_API_KEY = "AIzaSyAwOEmBDqiAuM4B-2TpNMDr3sY7BnpUvn8";

export const maxUploadSize = 25165824;

export const shadows =
  "2px 2px #D0D0D0, 3px 3px #D8D8D8, 4px 4px #E0E0E0, 5px 5px #F0F0F0,6px 6px #F8F8F8";
export const shadows_orange =
  "2px 2px #A8A8A8, 3px 3px #D0D0D0, 4px 4px #D0D0D0,5px 5px #E0E0E0";

export function formatDate(date) {
  return moment(date).format("M.D.YYYY");
}

export function stringAvatar(name) {
  if (name.trim().length === 0) return { children: "" };
  try {
    const [firstName, lastName] = name.split(" ");
    return {
      children: `${firstName[0].toUpperCase()}${lastName[0].toUpperCase()}`,
    };
  } catch (e) {
    console.error(e);
    return { children: "Undefined" };
  }
}

export const AmountToThousands = (amount) => {
  return (amount / 1000).toFixed(1) + "k";
};
export const formatValue = (amount) => {
  const formater = new Intl.NumberFormat("en-US", {
    style: "currency",
    currency: "USD",
  });
  return formater.format(amount);
  // let number;
  // if (amount < 1000) {
  //   return amount;
  // } else if (amount % 1000 > 100) {
  //   return `${parseInt(amount / 1000)},${parseInt(amount % 1000)}
  //   }`;
  // } else if (amount % 1000 > 10) {
  //   return `${parseInt(amount / 1000)},0${parseInt(amount % 1000)}`;
  // } else {
  //   return `${parseInt(amount / 1000)},00${parseInt(amount % 1000)}`;
  // }
  // return number
};
export const kFormatter = (num) => {
  return Math.abs(num) > 999
    ? Math.sign(num) * (Math.abs(num) / 1000).toFixed(1) + "k"
    : Math.sign(num) * Math.abs(num);
};

export const industryList = [
  "Aerospace and defense",
  "Agriculture",
  "Auto transport",
  "Banking",
  "Beverages",
  "Business services",
  "Chemistry",
  "Computer hardware and software",
  "Construction",
  "Consumer manufacturing",
  "Consumer services",
  "Education",
  "Electronics",
  "Energy and utilities",
  "Environmental services",
  "Financial services",
  "Food",
  "Healthcare",
  "Industrial manufacturing",
  "Insurance",
  "Media",
  "Metals and mining",
  "Pharmaceuticals",
  "Real estate",
  "Retail",
  "Security",
  "Telecommunications",
  "Transport",
  "Other",
];
