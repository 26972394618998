import { useState } from "react";
import {
  Button,
  Typography,
  styled,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import React from "react";
import CssTextField from "../../../components/StyledTextField";
import PasswordChecklist from "react-password-checklist";

import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import accountService from "../../../services/accountService";
import { COLORS } from "../../../styles/themes/default/colors";
import alertService from "../../../services/alertService";

const SetPasswordModal = ({ open, email, setOpenModal }) => {
  const [oldPassword, setOldPassword] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [validationRule, setValidationRule] = useState(false);

  const [isOldVisible, setIsOldVisible] = useState(false);
  const [isNewVisible, setIsNewVisible] = useState(false);
  const [isConfirmVisible, setIsConfirmVisible] = useState(false);

  const theme = useTheme();
  const mdUp = useMediaQuery(theme.breakpoints.up("md"));

  if (!open) return null;

  const ChangePasswordButton = styled(Button)(({ theme }) => ({
    fontFamily: "Montserrat",
    fontWeight: "bolder",
    width: "80%",
    height: "40px",
    boxShadow: "none",
    marginTop: "10px",
  }));

  const TitleText = styled(Typography)(({ theme }) => ({
    fontSize: "20px",
    fontWeight: "bolder",
  }));
  const SubtitleText = styled(Typography)(({ theme }) => ({
    marginBottom: "20px",
    fontSize: "17px",
  }));

  const iconVisibleStyle = {
    color: "#D9D9D9",
  };

  const handleChangePassword = async () => {
    await accountService
      .changePassword({
        old_password: oldPassword,
        password: newPassword,
      })
      .then((resp) => {
        alertService.success("Password changed successfully!");
        setOldPassword("");
        setNewPassword("");
        setConfirmPassword("");
        setOpenModal(false);
      })
      .catch((error) => {
        alertService.error(error.response.status, "Wrong old password");
      });
  };

  const modalStyle = {
    position: "absolute",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    width: "100%",
    height: "100%",
    zIndex: 0,
    backdropFilter: "blur(5px)",
    top: 0,
    left: 0,
  };
  const innerDivStyle = {
    backgroundColor: COLORS.white,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    flexDirection: "column",
    width: "35rem",
    height: "550px",
    marginLeft: mdUp ? 0 : "10px",
    marginRigth: mdUp ? 0 : "10px",
  };
  const textFieldStyle = {
    width: "80%",
    marginBottom: "20px",
  };
  return (
    <>
      <div
        onClick={(e) => {
          setOpenModal(false);
        }}
        style={modalStyle}
      >
        <div
          onClick={(e) => {
            e.stopPropagation();
          }}
          style={innerDivStyle}
        >
          <TitleText>Change password</TitleText>
          <SubtitleText>for {email}</SubtitleText>
          <CssTextField
            style={textFieldStyle}
            size="small"
            value={oldPassword}
            type={isOldVisible ? "text" : "password"}
            placeholder="Old password"
            onChange={(e) => {
              setOldPassword(e.target.value);
            }}
            InputProps={{
              style: {
                fontFamily: "Montserrat",
              },
              endAdornment: (
                <Button
                  style={{ outline: "none" }}
                  onClick={(e) => {
                    setIsOldVisible(!isOldVisible);
                  }}
                >
                  {isOldVisible ? (
                    <VisibilityIcon style={iconVisibleStyle} />
                  ) : (
                    <VisibilityOffIcon style={iconVisibleStyle} />
                  )}
                </Button>
              ),
            }}
          />
          <CssTextField
            onChange={(e) => {
              setNewPassword(e.target.value);
            }}
            style={textFieldStyle}
            size="small"
            value={newPassword}
            type={isNewVisible ? "text" : "password"}
            placeholder="New password"
            InputProps={{
              endAdornment: (
                <Button
                  style={{ outline: "none" }}
                  onClick={(e) => {
                    setIsNewVisible(!isNewVisible);
                  }}
                >
                  {isNewVisible ? (
                    <VisibilityIcon style={iconVisibleStyle} />
                  ) : (
                    <VisibilityOffIcon style={iconVisibleStyle} />
                  )}
                </Button>
              ),
            }}
          />
          <PasswordChecklist
            style={{
              marginBottom: "20px",
              fontFamily: "Montserrat",
              width: "80%",
            }}
            rules={["minLength", "specialChar", "number", "capital", "match"]}
            minLength={8}
            value={newPassword}
            valueAgain={confirmPassword}
            onChange={(isValid) => {
              setValidationRule(isValid);
            }}
          />
          <CssTextField
            onChange={(e) => {
              setConfirmPassword(e.target.value);
            }}
            style={textFieldStyle}
            size="small"
            value={confirmPassword}
            type={isConfirmVisible ? "text" : "password"}
            placeholder="Confirm password"
            InputProps={{
              endAdornment: (
                <Button
                  style={{ outline: "none" }}
                  onClick={(e) => {
                    setIsConfirmVisible(!isConfirmVisible);
                  }}
                >
                  {isConfirmVisible ? (
                    <VisibilityIcon style={iconVisibleStyle} />
                  ) : (
                    <VisibilityOffIcon style={iconVisibleStyle} />
                  )}
                </Button>
              ),
            }}
          />
          <ChangePasswordButton
            color="sunrise_orange"
            variant="contained"
            disabled={!validationRule}
            onClick={handleChangePassword}
          >
            Change password
          </ChangePasswordButton>
          <ChangePasswordButton
            color="sunrise_orange"
            variant="text"
            onClick={() => setOpenModal(false)}
          >
            Close
          </ChangePasswordButton>
        </div>
      </div>
    </>
  );
};
export default SetPasswordModal;
