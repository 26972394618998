import React, { useEffect, useState } from "react";
import { Checkbox, Dialog, FormControlLabel } from "@mui/material";
import apiFactory from "../service/apiFactory";
import { CONTAINER_URL, formatValue } from "../../../utils/constants";
import { CustomLoader } from "../../../components/CustomLoader";
import "../styles/modals.scss";
import { COLORS } from "../../../styles/themes/default/colors";
import {
  available_terminals,
  bgColorToColor,
  periods,
} from "../utils/contants";
import ColorCalendar from "./ColorCalendar";
import TariffsTable from "./TariffsTable";
import InfoIcon from "../assets/info_icon.svg";
import closeModalIcon from "../../../assets/img/closeModalIcon.png";
import ModalPagination from "./ModalPagination";
import SchedulingDiv from "../components/MutualComponents/SchedulingDiv";
import alertService from "../../../services/alertService";
import fetchWrapper from "../../../helpers/fetch-wrapper";
import moment from "moment";

export default function MultipleContainerModal({
  setIsModalOpen,
  isModalOpen,
  container_ids,
}) {
  const onClose = () => {
    setIsModalOpen(false);
    setContainersData([]);
    setData({});
    setDisplayContainerId(0);
    setAppointments([]);
    setAvailableAppointments([]);
    setSchedulingNowDate([]);
    setSchedulingNowType([]);
    setOpenAppointment([]);
    setTariffs([]);
    setLoadingAppointments([]);
    setCheckedAll(false);
  };
  const [tariffs, setTariffs] = useState([]);
  const [loadingAppointments, setLoadingAppointments] = useState([]);
  const [availableAppointments, setAvailableAppointments] = useState([]);
  const [openAppointment, setOpenAppointment] = useState([]);
  const [appointments, setAppointments] = useState({
    date: "",
    time: "",
    type: "",
  });
  const [schedulingNowDate, setSchedulingNowDate] = useState([]);
  const [schedulingNowType, setSchedulingNowType] = useState([]);

  const [isLoading, setIsLoading] = useState(false);
  const [containersData, setContainersData] = useState([]);
  const [data, setData] = useState({});
  const [displayContainerId, setDisplayContainerId] = useState(0);

  const [checkedAll, setCheckedAll] = useState(false);

  useEffect(() => {
    async function getAppointments() {
      let newArray = [...loadingAppointments];
      newArray[displayContainerId] = true;
      setLoadingAppointments(newArray);
      await fetchWrapper
        .get(
          `https://cdl-appointment.qcerris.cloud/appointments/?appointment_type=${"empty dropoff"}&terminal_name=${
            data?.export ? data?.pol?.facility_name : data?.pod?.facility_name
          }&date=${moment(schedulingNowDate[displayContainerId]).format(
            "YYYY-MM-DD"
          )}`
        )
        .then((resp) => {
          if (Array.isArray(resp)) {
            let newArray = [...availableAppointments];

            newArray[displayContainerId] = resp;
            setAvailableAppointments(newArray);
          } else {
            alertService.error(1, resp.error);
          }

          let newArray = [...loadingAppointments];
          newArray[displayContainerId] = false;
          setLoadingAppointments(newArray);
        })
        .catch((error) => {
          alertService.error(error.response.status);
          let newArray = [...loadingAppointments];
          newArray[displayContainerId] = false;
          setLoadingAppointments(newArray);
        });
    }

    if (
      schedulingNowDate[displayContainerId] &&
      schedulingNowType[displayContainerId] &&
      availableAppointments[displayContainerId].length === 0
    ) {
      getAppointments();
    }
  }, [
    availableAppointments,
    data?.export,
    data?.pod?.facility_name,
    data?.pol?.facility_name,
    schedulingNowDate,
    schedulingNowType,
  ]);

  useEffect(() => {
    async function getTariffs(params) {
      if (
        appointments[displayContainerId]?.date &&
        tariffs[displayContainerId].length === 0
      ) {
        let containersBuildUrl = "";
        if (checkedAll) {
          containersData.forEach((item) => {
            containersBuildUrl += `container_ids=${item.container_id}&`;
          });
        } else {
          containersBuildUrl = `container_ids=${data.container_id}&`;
        }
        await apiFactory
          .get(
            `${CONTAINER_URL}container/appointment_dnd?${containersBuildUrl}appointment_date=${appointments[displayContainerId].date}&appointment_type=DETENTION`
          )
          .then((resp) => {
            let newArray = [...tariffs];
            let keys = Object.keys(resp.data);
            containersData.forEach((item, index) => {
              if (keys.includes(item.container_id)) {
                newArray[index] = resp.data[item.container_id];
              }
            });

            setTariffs(newArray);
            if (checkedAll) {
              let tempValue = appointments[displayContainerId];
              let newAppArray = appointments.map(() => {
                return tempValue;
              });
              setAppointments(newAppArray);
            }
          })
          .catch((error) => {
            alertService.error(error.response.status);
          });
      }
    }
    if (
      appointments[displayContainerId]?.date &&
      tariffs[displayContainerId]?.length === 0
    ) {
      getTariffs();
    }
  }, [appointments, data?.container_id, displayContainerId, tariffs]);

  useEffect(() => {
    if (isModalOpen) {
      setIsLoading(true);
      let queryString = "";
      container_ids.forEach((item) => {
        queryString += `&container_ids=${item}`;
      });
      queryString = queryString.replace("&", "?");
      apiFactory
        .get(`${CONTAINER_URL}container/detail${queryString}`)
        .then((res) => {
          setContainersData(res.data);
          setIsLoading(false);
        })
        .catch((error) => {
          alertService.error(error.response.status);
        });
    }
  }, [container_ids, isModalOpen]);
  useEffect(() => {
    if (containersData.length) {
      setData(containersData[displayContainerId]);
    }
  }, [containersData, displayContainerId]);
  useEffect(() => {
    setTariffs(Array.from({ length: container_ids.length }, () => []));
    setAvailableAppointments(
      Array.from({ length: container_ids.length }, () => [])
    );
    let appointmentArray = Array.from({ length: container_ids.length });
    appointmentArray = appointmentArray.map((item) => {
      return {
        date: "",
        time: "",
        type: "",
      };
    });
    setAppointments(appointmentArray);

    setSchedulingNowDate(
      Array.from({ length: container_ids.length }, () => "")
    );
    setSchedulingNowType(
      Array.from({ length: container_ids.length }, () => "")
    );
    setOpenAppointment(
      Array.from({ length: container_ids.length }, () => false)
    );
    setLoadingAppointments(
      Array.from({ length: container_ids.length }, () => null)
    );
  }, [container_ids.length, isModalOpen]);
  const paidDem =
    data?.demurrage_fee_by_pricing?.length > 0
      ? data?.demurrage_fee_by_pricing
          .map((item) => {
            return item.amount;
          })
          .reduce((acc, cur) => acc + cur, 0)
      : 0;

  const paidDet =
    data?.detention_fee_by_pricing?.length > 0
      ? data?.detention_fee_by_pricing
          .map((item) => {
            return item.amount;
          })
          .reduce((acc, cur) => acc + cur, 0)
      : 0;

  return (
    <>
      <Dialog
        open={isModalOpen}
        onClose={onClose}
        sx={{
          "& .MuiDialog-container": {
            "& .MuiPaper-root": {
              maxWidth: "none",
              width: "1150px",
            },
          },
        }}
      >
        <div className="mainModalDiv_dark">
          <div className="headerDiv_dark">
            <p className="headerText_dark">D&D Details - Bulk Payment</p>
            <div style={{ cursor: "pointer" }}>
              <img
                src={closeModalIcon}
                alt=""
                className="closeImg"
                onClick={onClose}
              />
            </div>
          </div>
          {isLoading && data && Object.keys(data).length === 0 ? (
            <div
              style={{
                height: "500px",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <CustomLoader darktheme="true" size="5vh" />
            </div>
          ) : (
            <>
              <div className="d-flex w100 justify-between mb16">
                <div className="d-flex">
                  <p
                    style={{
                      margin: 0,
                      fontWeight: 600,
                      color: COLORS.off_white,
                    }}
                  >
                    Container id
                  </p>
                  <p
                    style={{
                      margin: 0,
                      fontWeight: 600,
                      color: COLORS.purple,
                      marginLeft: "16px",
                    }}
                  >
                    {data?.container_id}
                  </p>
                </div>
                <ModalPagination
                  currentId={displayContainerId + 1}
                  total={containersData.length}
                  setCurrentId={setDisplayContainerId}
                />
              </div>
              <div className="contentDiv_dark">
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    marginBottom: "16px",
                  }}
                >
                  <div className="freeDayDiv_dark">
                    <div className="portFreeDayDiv_dark">
                      <div className="portFreeRow_dark">
                        <p className="labelGray_dark">
                          Demurrage first free day:
                        </p>
                        <p className="labelBold_dark">
                          {data?.first_free_demurrage_day ?? "TBD"}
                        </p>
                      </div>
                      <p className="labelTime_dark">
                        {data?.first_free_demurrage_day ? "12:00 AM" : <br />}
                      </p>
                      <div className="portFreeRow_dark">
                        <p className="labelGray_dark">
                          Demurrage last free day:
                        </p>
                        <p className="labelBold_dark">
                          {data?.last_demurrage_free_day ?? "TBD"}
                        </p>
                      </div>
                      <p className="labelTime_dark">
                        {data?.last_demurrage_free_day ? "11:59 AM" : <br />}
                      </p>
                      <div
                        className="portFreeRow_dark"
                        style={{ marginBottom: "34px" }}
                      >
                        {" "}
                        <p className="labelGray_dark">Demurrage free days:</p>
                        <p className="labelBold_dark">
                          {data?.demurrage_free_days ?? "4"}
                        </p>
                      </div>
                      <div className="portFreeRow_dark">
                        <p className="labelGray_dark">Import pickup Appt:</p>
                        <p className="labelBold_dark">
                          {data?.pickup_appointment ?? "TBD"}
                        </p>
                      </div>
                      <p
                        className="labelTime_dark"
                        style={{ marginBottom: "0" }}
                      >
                        {data?.pickup_appointment ? "12:00 AM" : ""}
                      </p>
                    </div>
                    <div className="lineFreeDayDiv_dark">
                      <div className="portFreeRow_dark">
                        <p className="labelGray_dark">
                          Detention first free day:
                        </p>
                        <p className="labelBold_dark">
                          {data?.first_free_detention_day ?? "TBD"}
                        </p>
                      </div>
                      <p className="labelTime_dark">
                        {data?.first_free_detention_day ? "12:00 AM" : <br />}
                      </p>

                      <div className="portFreeRow_dark ">
                        <p className="labelGray_dark">
                          Detention last free day:
                        </p>
                        <p className="labelBold_dark">
                          {data?.last_detention_free_day ?? "TBD"}
                        </p>
                      </div>
                      <p className="labelTime_dark">
                        {data?.last_detention_free_day ? "11:59 AM" : <br />}
                      </p>

                      <div
                        className="portFreeRow_dark"
                        style={{ marginBottom: "34px" }}
                      >
                        <p className="labelGray_dark">Detention free days:</p>
                        <p className="labelBold_dark">
                          {data?.detention_free_days ?? "4"}
                        </p>
                      </div>

                      <div className="portFreeRow_dark">
                        <p className="labelGray_dark">Empty return Appt: </p>
                        <p className="labelBold_dark">
                          {!data?.export &&
                          data?.current_event?.status !== "Empty Returned" &&
                          data?.is_in_detention
                            ? appointments[displayContainerId]?.date ??
                              "Not set"
                            : data?.return_appointment ?? "TBD"}
                        </p>
                      </div>
                      {!data?.export &&
                        data?.current_event?.status !== "Empty Returned" &&
                        data?.is_in_detention && (
                          <div
                            style={{
                              display: "flex",
                              width: "100%",
                              justifyContent: "flex-end",
                              paddingRight: appointments[displayContainerId]
                                ?.time
                                ? "0px"
                                : "16px",
                            }}
                          >
                            {appointments[displayContainerId]?.time ? (
                              <p
                                className="labelTime_dark"
                                style={{ marginBottom: "0" }}
                              >
                                {appointments[displayContainerId]?.time ??
                                  "Not set"}
                              </p>
                            ) : (
                              <button
                                className="link_button"
                                style={{ padding: 0 }}
                                onClick={() => {
                                  let newArray = [...openAppointment];
                                  newArray[displayContainerId] =
                                    !newArray[displayContainerId];
                                  setOpenAppointment(newArray);
                                }}
                              >
                                {openAppointment[displayContainerId]
                                  ? "Close scheduling"
                                  : "Set Appointment"}
                              </button>
                            )}
                          </div>
                        )}
                    </div>
                  </div>
                  <div
                    style={{
                      backgroundColor: COLORS.off_black,
                      marginLeft: "16px",
                      width: "30%",
                      borderRadius: "8px",
                    }}
                  >
                    <div
                      className="portFreeDayDiv_dark"
                      style={{
                        width: "100%",
                        padding: "16px",
                        paddingRight: 0,
                      }}
                    >
                      <div className="portFreeRow_dark">
                        <p className="labelGray_dark">Container type:</p>
                        <p className="labelBold_dark">{data?.iso_type}</p>
                      </div>
                      <p
                        style={{
                          paddingRight: "5%",
                          textAlign: "right",
                          fontStyle: "italic",
                          fontWeight: 400,
                          fontSize: "14px",
                          color: "#f1f1f1",
                        }}
                      >
                        {data?.iso_code_description}
                      </p>
                      <div
                        className="portFreeRow_dark"
                        style={{ marginBottom: "34px" }}
                      >
                        <p className="labelGray_dark">Terminal:</p>
                        <p className="labelBold_dark">
                          {(data?.export
                            ? data?.pol?.facility_name
                            : data?.pod?.facility_name) ??
                            data?.destination?.facility_name ??
                            `${data?.destination?.name}, ${data?.destination?.state}`}
                        </p>
                      </div>
                      <div
                        className="portFreeRow_dark"
                        style={{ marginBottom: "34px" }}
                      >
                        <p className="labelGray_dark">Steamship line:</p>
                        <p className="labelBold_dark">
                          {data?.ssl_short_name ?? data?.ssl_name}
                        </p>
                      </div>

                      <div className="portFreeRow_dark" style={{}}>
                        <p className="labelGray_dark">Customer:</p>
                        <p className="labelBold_dark">{data?.shipper_name}</p>
                      </div>
                    </div>
                  </div>
                </div>
                <div>
                  {openAppointment[displayContainerId] ? (
                    <>
                      <div className="d-flex align-center gap8">
                        <FormControlLabel
                          style={{ margin: 0 }}
                          control={
                            <Checkbox
                              disabled={false}
                              style={{
                                color: checkedAll
                                  ? COLORS.purple
                                  : COLORS.dark_grey,
                              }}
                              onClick={() => {
                                setCheckedAll((prev) => !prev);
                              }}
                            />
                          }
                          checked={checkedAll}
                        />
                        <p className="m0 c-white">
                          {" "}
                          Apply this schedule to all containers
                        </p>
                      </div>

                      <SchedulingDiv
                        setSchedulingNowDate={(data) => {
                          let newArray = [...schedulingNowDate];
                          newArray[displayContainerId] = data;
                          setSchedulingNowDate(newArray);
                        }}
                        setSchedulingNowType={(data) => {
                          let newArray = [...schedulingNowType];
                          newArray[displayContainerId] = data;
                          setSchedulingNowType(newArray);
                        }}
                        appointment={appointments[displayContainerId]}
                        setAppointment={(data) => {
                          let newArray = [...appointments];
                          newArray[displayContainerId] = data;
                          setAppointments(newArray);
                        }}
                        loadingAppointments={
                          loadingAppointments[displayContainerId]
                        }
                        notSuportedTerminal={
                          !(
                            available_terminals.includes(
                              data?.pod?.facility_name
                            ) ||
                            available_terminals.includes(
                              data?.pol?.facility_name
                            )
                          )
                        }
                        availableAppointments={
                          availableAppointments[displayContainerId].length > 0
                            ? availableAppointments[displayContainerId].map(
                                (item) => {
                                  return [item.start_time, item.end_time];
                                }
                              )
                            : []
                        }
                        setOpenAppointment={(data) => {
                          let newArray = [...openAppointment];
                          newArray[displayContainerId] = data;
                          setOpenAppointment(newArray);
                        }}
                      />
                    </>
                  ) : (
                    <>
                      <div
                        className="containersItem_dark"
                        style={{ backgroundColor: COLORS.off_black }}
                      >
                        <div className="containersFees_dark">
                          <div className="demurrageFees_dark">
                            <p className="containerFeesText_dark">
                              Demurrage fees
                            </p>
                            <TariffsTable
                              data={data.demurrage_fee_by_pricing}
                              showAtRisk={false}
                              areCalendarDays={
                                data.are_demurrage_free_days_calendar_days
                              }
                            />
                          </div>
                          <div className="perDiemFees_dark">
                            <p className="containerFeesText_dark">
                              Detention fees
                            </p>
                            <TariffsTable
                              data={data.detention_fee_by_pricing}
                              showAtRisk={true}
                              tariff={tariffs[displayContainerId]}
                              atRiskFees={data?.at_risk_detention_fee}
                              areCalendarDays={
                                data.are_detention_free_days_calendar_days
                              }
                            />
                          </div>
                        </div>
                        <div className="containersFees_dark">
                          <div className="demurrageFees_dark">
                            <table
                              className="feesTable_dark"
                              style={{
                                borderTop: `1px solid ${COLORS.dark_grey}66`,
                              }}
                            >
                              <tr>
                                <td style={{ float: "left" }}>Paid:</td>
                                <td></td>
                                <td></td>
                                <td></td>
                                <td>
                                  <div className="total_value_div">
                                    {formatValue(paidDem)}
                                    {data?.demurrage_fee_by_pricing &&
                                      data?.demurrage_fee_by_pricing.length >
                                        0 && (
                                        <img
                                          src={InfoIcon}
                                          alt="info_icon"
                                          className="info_icon_modal"
                                        />
                                      )}

                                    <div className="calendar_div">
                                      {data?.demurrage_fee_by_pricing &&
                                        data?.demurrage_fee_by_pricing.length >
                                          0 && (
                                          <ColorCalendar
                                            darkTheme={true}
                                            tariffsDates={
                                              data?.demurrage_fee_by_pricing
                                                ? data.demurrage_fee_by_pricing.map(
                                                    (item, index) => {
                                                      return {
                                                        startDate:
                                                          item.startDate,
                                                        endDate: item.endDate,
                                                        bgColor:
                                                          periods[index].color,
                                                        color:
                                                          bgColorToColor[
                                                            periods[index].color
                                                          ],
                                                      };
                                                    }
                                                  )
                                                : []
                                            }
                                            areWeekDays={
                                              !data.are_demurrage_free_days_calendar_days
                                            }
                                          />
                                        )}
                                    </div>
                                  </div>
                                </td>
                              </tr>
                              {data?.saved_dem !== null &&
                                data?.saved_dem !== 0 && (
                                  <tr
                                    style={{
                                      color: COLORS.dark_grey,
                                    }}
                                  >
                                    <td>Saved:</td>
                                    <td></td>
                                    <td></td>
                                    <td></td>
                                    <td
                                      style={{
                                        float: "right",
                                        color: COLORS.dark_grey,
                                      }}
                                    >
                                      {formatValue(data?.saved_dem)}
                                    </td>
                                  </tr>
                                )}
                            </table>
                          </div>
                          <div className="perDiemFees_dark">
                            {(data?.export && !data?.is_in_detention) ||
                            data?.current_event?.status === "Empty Returned" ? (
                              <table
                                className="feesTable_dark"
                                style={{
                                  borderTop: `1px solid ${COLORS.dark_grey}66`,
                                }}
                              >
                                <tr>
                                  <td>Paid:</td>
                                  <td></td>
                                  <td></td>
                                  <td></td>
                                  <td>
                                    <div className="total_value_div">
                                      {/* {formatValue(paidDet)} */}
                                      {data?.detention_fee_by_pricing &&
                                        data?.detention_fee_by_pricing.length >
                                          0 && (
                                          <img
                                            src={InfoIcon}
                                            alt="info_icon"
                                            className="info_icon_modal"
                                          />
                                        )}

                                      <div className="calendar_div">
                                        {data?.detention_fee_by_pricing &&
                                          data?.detention_fee_by_pricing
                                            .length > 0 && (
                                            <ColorCalendar
                                              darkTheme={true}
                                              tariffsDates={
                                                data?.detention_fee_by_pricing
                                                  ? data.detention_fee_by_pricing.map(
                                                      (item, index) => {
                                                        return {
                                                          startDate:
                                                            item.startDate,
                                                          endDate: item.endDate,
                                                          bgColor:
                                                            periods[index]
                                                              .color,
                                                          color:
                                                            bgColorToColor[
                                                              periods[index]
                                                                .color
                                                            ],
                                                        };
                                                      }
                                                    )
                                                  : []
                                              }
                                              areWeekDays={
                                                !data.are_detention_free_days_calendar_days
                                              }
                                              atRisk={{
                                                date: new Date(
                                                  data?.at_risk_detention_fee?.startDate
                                                ),
                                                bgColor: COLORS.dark_grey,
                                                color: "white",
                                              }}
                                            />
                                          )}
                                      </div>
                                    </div>
                                  </td>
                                </tr>
                                {data?.saved_det !== null &&
                                  data?.saved_det !== 0 && (
                                    <tr
                                      style={{
                                        color: COLORS.dark_grey,
                                      }}
                                    >
                                      <td>Saved:</td>
                                      <td></td>
                                      <td></td>
                                      <td></td>
                                      <td
                                        style={{
                                          float: "right",
                                        }}
                                      >
                                        {formatValue(data?.saved_det)}
                                      </td>
                                    </tr>
                                  )}
                              </table>
                            ) : (
                              <table
                                className="feesTable_dark"
                                style={{
                                  borderTop: `1px solid ${COLORS.dark_grey}66`,
                                }}
                              >
                                <tr style={{ position: "relative" }}>
                                  <td>Detention total:</td>
                                  <td></td>
                                  <td></td>
                                  <td></td>
                                  <td>
                                    <div className="total_value_div">
                                      {formatValue(paidDet)}
                                      {data?.detention_fee_by_pricing &&
                                        data?.detention_fee_by_pricing.length >
                                          0 && (
                                          <img
                                            src={InfoIcon}
                                            alt="info_icon"
                                            className="info_icon_modal"
                                          />
                                        )}

                                      <div className="calendar_div">
                                        {data?.detention_fee_by_pricing &&
                                          data?.detention_fee_by_pricing
                                            .length > 0 && (
                                            <ColorCalendar
                                              darkTheme={true}
                                              tariffsDates={
                                                data?.detention_fee_by_pricing
                                                  ? data.detention_fee_by_pricing.map(
                                                      (item, index) => {
                                                        return {
                                                          startDate:
                                                            item.startDate,
                                                          endDate: item.endDate,
                                                          bgColor:
                                                            periods[index]
                                                              .color,
                                                          color:
                                                            bgColorToColor[
                                                              periods[index]
                                                                .color
                                                            ],
                                                        };
                                                      }
                                                    )
                                                  : []
                                              }
                                              atRisk={{
                                                date: new Date(
                                                  data?.at_risk_detention_fee?.startDate
                                                ),
                                                bgColor: COLORS.dark_grey,
                                                color: "white",
                                              }}
                                              areWeekDays={
                                                !data.are_detention_free_days_calendar_days
                                              }
                                            />
                                          )}
                                      </div>
                                    </div>
                                  </td>
                                </tr>
                              </table>
                            )}
                          </div>
                        </div>
                      </div>
                      <div style={{ borderRadius: "8px" }}>
                        <div
                          className="containersTotalDiv_dark"
                          style={{ borderRadius: 0 }}
                        >
                          <p className="containersTotalText_dark">
                            Container total:
                          </p>
                          <p className="containersTotalText_dark">
                            {/* {formatValue(paidDet + paidDem)} */}
                          </p>
                        </div>
                        {data?.current_event?.status !== "Empty Returned" && (
                          <div className="containersTotalDiv_dark">
                            <p
                              style={{
                                margin: 0,
                                color: COLORS.dark_grey,
                                fontSize: "14px",
                              }}
                            >
                              At risk container total (next 24h):
                            </p>
                            <p
                              style={{
                                margin: 0,
                                color: COLORS.dark_grey,
                                fontSize: "14px",
                              }}
                            >
                              {formatValue(
                                data?.potential_demurrage_amount +
                                  data?.potential_detention_amount >
                                  0
                                  ? data?.potential_demurrage_amount +
                                      data?.potential_detention_amount
                                  : data?.current_demurrage_amount +
                                      data?.current_detention_amount
                              )}
                            </p>
                          </div>
                        )}
                      </div>

                      <div className="footerDiv_dark">
                        <div style={{ display: "flex" }}>
                          <p
                            style={{
                              fontWeight: 600,
                              color: COLORS.off_white,
                              margin: 0,
                            }}
                          >
                            Total selected:
                          </p>
                          <p
                            style={{
                              fontWeight: 600,
                              color: COLORS.purple,
                              margin: 0,
                              marginLeft: "16px",
                            }}
                          >
                            {formatValue(
                              containersData
                                .map((item) => {
                                  return (
                                    item.current_demurrage_amount ??
                                    item.current_detention_amount
                                  );
                                })
                                .reduce((acc, cur) => acc + cur, 0)
                            )}
                          </p>
                        </div>
                        <div>
                          <button
                            onClick={onClose}
                            style={{
                              backgroundColor: "transparent",
                              color: COLORS.purple,
                              padding: "12px",
                              borderRadius: "8px",
                              fontWeight: "600",
                              fontSize: "14px",
                              //   float: "right",
                              marginRight: "16px",
                              border: "none",
                              outline: "none",
                            }}
                          >
                            Close
                          </button>
                          {data?.current_event?.status !== "Empty Returned" && (
                            <button disabled={true} className="purple_button">
                              Pay now
                            </button>
                          )}
                        </div>
                      </div>
                    </>
                  )}
                </div>
              </div>
            </>
          )}
        </div>
      </Dialog>
    </>
  );
}
