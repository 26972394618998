import React, { useState, useEffect } from "react";

import CssTextField from "../../../components/StyledTextField";
import { COLORS } from "../../../styles/themes/default/colors";

import fetchWrapper from "../../../helpers/fetch-wrapper";
import { APP_URL } from "../../../utils/constants";

import AutoComplete from "../../../components/Cdl/AutoComplete";

import {
  Dialog,
  DialogTitle,
  DialogActions,
  DialogContent,
  Button,
  Select,
  MenuItem,
  InputLabel,
  FormControl,
  createTheme,
  useMediaQuery,
} from "@mui/material";
import alertService from "../../../services/alertService";
import InputMask from "react-input-mask";

export default function AddCustomerModal({
  updateList,
  setUpdateList,
  openCustomerModal,
  setOpenCustomerModal,
}) {
  const [isSent, setisSent] = useState(null);

  const [companyName, setCompanyName] = useState();
  const [companyNameOptions, setCompanyNameOptions] = useState();

  const [mcleodData, setMcleodData] = useState();

  const [contractData, setContractData] = useState({
    repayment_days: "14",
    authority_approval: "",
  });

  const [adminData, setAdminData] = useState({
    first_name: "",
    last_name: "",
    email: "",
    phone_number: "",
  });

  const [companyData, setCompanyData] = useState({
    business_name: "",
    business_email: "",
    business_phone: "",
  });

  useEffect(() => {
    fetchWrapper.get(`${APP_URL}/mcleod/customers/`).then((resp) => {
      setMcleodData(resp);

      let names = [];
      for (let i = 0; i < resp.length; i++) {
        names.push(resp[i].business_name);
      }
      setCompanyNameOptions(names);
    });
  }, []);

  const isValidData = () => {
    let error = "";

    if (companyName.trim() === "") {
      error += "Business name is required.\n";
    }
    if (contractData.repayment_days.trim() === "") {
      error += "Repayment days field is required.\n";
    }
    if (contractData.authority_approval.trim() === "") {
      error += "Approval authority is required.\n";
    }
    if (adminData.first_name.trim() === "") {
      error += "First name is required.\n";
    }
    if (adminData.last_name.trim() === "") {
      error += "Last name is required.\n";
    }
    if (adminData.email.trim() === "") {
      error += "Email is required.\n";
    }
    return error;
  };

  const handleAddCustomer = async () => {
    let error = isValidData();
    if (error !== "") {
      setisSent(false);
      alertService.error(1, error);
      return;
    }

    let mcleodId = mcleodData.find((item) => item.business_name === companyName)
      .id;

    let data = {
      contact: {
        first_name: adminData.first_name,
        last_name: adminData.last_name,
        email: adminData.email,
        phone_number: adminData.phone_number,
      },
      company: {
        mcleod_id: mcleodId,
        business_name: companyName,
        business_email: companyData.business_email,
        business_phone: companyData.business_phone,
        repayment_days: contractData.repayment_days,
        authority_approval: contractData.authority_approval,
      },
    };

    fetchWrapper
      .post(`${APP_URL}/shipper/`, data)
      .then((resp) => {
        setUpdateList(!updateList);
        setOpenCustomerModal(false);
        setisSent(null);
        alertService.success("Customer succesfully added");
      })
      .catch((error) => {
        let message = "";
        for (const [key, value] of Object.entries(error.response.data)) {
          message = value;
        }
        alertService.error(error.response.status, message);
      });
  };

  const theme1 = createTheme({
    breakpoints: {
      values: {
        xs: 0,
        smallMobile: 321,
        mobile: 376,
        middleMobile: 407,
        sm: 600,
        md: 900,
        lg: 1200,
        xl: 1536,
      },
    },
  });

  const smDownQuerry = useMediaQuery(theme1.breakpoints.down("sm"));
  const smallMobileDownQuery = useMediaQuery(
    theme1.breakpoints.down("smallMobile")
  );

  const subtitleStyle = {
    fontSize: smDownQuerry ? "1rem" : "1.25rem",
  };

  const textFieldStyle = {
    width: "90%",
    marginBottom: "5%",
    backgroundColor: COLORS.white,
    fontFamily: "Montserrat",
    border: "none",
  };
  const addButtonStyle = {
    fontWeight: "bolder",
    outline: "none",
    boxShadow: "none",
    marginRight: "5%",
    width: smDownQuerry ? "35%" : "30%",
    fontSize: smDownQuerry ? "12px" : "16px",
    height: "35px",
  };
  const closeButtonStyle = {
    fontWeight: "bolder",
    width: smDownQuerry ? "35%" : "30%",
    fontSize: smDownQuerry ? "12px" : "16px",
    height: "35px",
  };

  return (
    <div>
      <Dialog
        open={openCustomerModal}
        onClose={() => {
          setisSent(null);
          setOpenCustomerModal(false);
        }}
        PaperProps={{
          style: {
            backgroundColor: COLORS.warm_grey,
          },
        }}
      >
        <DialogTitle
          style={{
            alignItems: "center",
            display: "flex",
            flexDirection: "column",
            fontSize: smDownQuerry
              ? smallMobileDownQuery
                ? "20px"
                : "24px"
              : "28px",
            fontWeight: "bold",
          }}
        >
          Add New Customer
        </DialogTitle>
        <DialogContent
          style={{
            marginLeft: "30px",
            marginRight: "30px",
            textAlign: "center",
            backgroundColor: COLORS.warm_grey,
          }}
        >
          <h5 style={subtitleStyle}>Company Information</h5>
          <AutoComplete
            options={companyNameOptions}
            placeholder="Business name *"
            search={companyName}
            setSearch={setCompanyName}
            css={{ marginBottom: "20px", width: "90%", left: "5%" }}
          />
          {/* <CssTextField
            onChange={(e) => {
              setCompanyData({ ...companyData, business_name: e.target.value });
            }}
            inputProps={{
              style: {
                fontFamily: "Montserrat",
                fontSize: smDownQuerry ? "14px" : "16px",
              },
            }}
            error={isSent === false && companyData.business_name.trim() === ""}
            value={companyData.business_name}
            style={textFieldStyle}
            size="small"
            placeholder="Business name *"
          /> */}
          <CssTextField
            onChange={(e) => {
              setCompanyData({
                ...companyData,
                business_email: e.target.value,
              });
            }}
            inputProps={{
              style: {
                fontFamily: "Montserrat",
                fontSize: smDownQuerry ? "14px" : "16px",
              },
            }}
            value={companyData.business_email}
            style={textFieldStyle}
            size="small"
            placeholder="Busines email"
          />
          <InputMask
            mask="+1 (999) 999-9999"
            value={companyData.business_phone}
            alwaysShowMask
            onChange={(e) => {
              setCompanyData({
                ...companyData,
                business_phone: e.target.value,
              });
            }}
            placeholder="Phone number"
          >
            <CssTextField
              inputProps={{
                style: {
                  fontFamily: "Montserrat",
                  fontSize: smDownQuerry ? "14px" : "16px",
                },
              }}
              style={textFieldStyle}
              size="small"
              placeholder="Business phone"
            />
          </InputMask>
          <h5 style={subtitleStyle}>Point of contact</h5>
          <CssTextField
            error={isSent === false && adminData.first_name.trim() === ""}
            onChange={(e) => {
              setAdminData({
                ...adminData,
                first_name: e.target.value,
              });
            }}
            inputProps={{
              style: {
                fontFamily: "Montserrat",
                fontSize: smDownQuerry ? "14px" : "16px",
              },
            }}
            value={adminData.first_name}
            style={textFieldStyle}
            size="small"
            placeholder="First name *"
          />
          <CssTextField
            error={isSent === false && adminData.last_name.trim() === ""}
            onChange={(e) => {
              setAdminData({
                ...adminData,
                last_name: e.target.value,
              });
            }}
            inputProps={{
              style: {
                fontFamily: "Montserrat",
                fontSize: smDownQuerry ? "14px" : "16px",
              },
            }}
            value={adminData.last_name}
            style={textFieldStyle}
            size="small"
            placeholder="Last name *"
          />
          <CssTextField
            error={isSent === false && adminData.email.trim() === ""}
            onChange={(e) => {
              setAdminData({
                ...adminData,
                email: e.target.value,
              });
            }}
            inputProps={{
              style: {
                fontFamily: "Montserrat",
                fontSize: smDownQuerry ? "14px" : "16px",
              },
            }}
            value={adminData.email}
            style={textFieldStyle}
            size="small"
            placeholder="Email *"
          />
          <InputMask
            mask="+1 (999) 999-9999"
            value={adminData.phone_number}
            alwaysShowMask
            onChange={(e) => {
              setAdminData({
                ...adminData,
                phone_number: e.target.value,
              });
            }}
            placeholder="Phone number"
          >
            <CssTextField
              inputProps={{
                style: {
                  fontFamily: "Montserrat",
                  fontSize: smDownQuerry ? "14px" : "16px",
                },
              }}
              value={adminData.phone_number}
              style={textFieldStyle}
              size="small"
              placeholder="Phone number"
            />
          </InputMask>
          <h5 style={subtitleStyle}>Contract Information</h5>
          <CssTextField
            error={
              isSent === false && contractData.repayment_days.trim() === ""
            }
            onChange={(e) => {
              setContractData({
                ...contractData,
                repayment_days: e.target.value,
              });
            }}
            inputProps={{
              style: {
                fontFamily: "Montserrat",
                fontSize: smDownQuerry ? "14px" : "16px",
              },
            }}
            value={contractData.repayment_days}
            style={textFieldStyle}
            size="small"
            placeholder="Repayment days *"
          />
          <FormControl
            fullWidth
            style={{
              marginLeft: smDownQuerry
                ? smallMobileDownQuery
                  ? "11px"
                  : "12px"
                : "23px",
              textAlign: "left",
            }}
          >
            <InputLabel
              style={{
                color: "rgba(0, 0, 0, 0.4)",
                fontFamily: "Montserrat",
                fontSize: smDownQuerry ? "14px" : "16px",
                textAlign: "left",
              }}
              size="small"
            >
              Approval authority *
            </InputLabel>
            <Select
              error={
                isSent === false &&
                contractData.authority_approval.trim() === ""
              }
              label="Approval authority *"
              style={textFieldStyle}
              size="small"
              onChange={(e) =>
                setContractData({
                  ...contractData,
                  authority_approval: e.target.value,
                })
              }
            >
              <MenuItem value={"CDL"}>CDL 1000</MenuItem>
              <MenuItem value={"CUS"}>Customer</MenuItem>
            </Select>
          </FormControl>
        </DialogContent>
        <DialogActions
          style={{
            justifyContent: "center",
            display: "flex",
            marginBottom: "20px",
          }}
        >
          <Button
            variant="contained"
            color="sunrise_orange"
            style={addButtonStyle}
            onClick={handleAddCustomer}
          >
            Add customer
          </Button>
          <Button
            variant="outlined"
            color="sunrise_orange"
            style={closeButtonStyle}
            onClick={(e) => {
              setisSent(null);
              setOpenCustomerModal(false);
            }}
          >
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
