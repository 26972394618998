import React from "react";

import "../../styles/vesselStatistics.scss";
import Skeleton from "react-loading-skeleton";

const VesselsStatistics = ({ statistics, loading }) => {
  let keys;
  if (statistics?.vessel_statistics) {
    keys = Object.keys(statistics?.vessel_statistics);
  }
  const isLoading = loading || !statistics?.vessel_statistics;

  return (
    <div className="main_div">
      {!loading &&
      statistics?.vessel_statistics &&
      Object.keys(statistics?.vessel_statistics).length === 0 ? (
        <div className="no_data_container">
          There are no vessels at the moment
        </div>
      ) : (
        <>
          <div className="display_div_first">
            <p className="column_title">{isLoading ? <Skeleton /> : keys[0]}</p>

            {isLoading ? (
              <Skeleton width={200} style={{ marginTop: "1px" }} count={8} />
            ) : (
              statistics?.vessel_statistics &&
              keys.length >= 1 &&
              Object.keys(statistics?.vessel_statistics[keys[0]]).map(
                (key, index) => {
                  return (
                    <div key={index} className="column_row">
                      <p className="column_row_name">{key}</p>
                      <p className="column_row_number">
                        {statistics?.vessel_statistics[keys[0]][key]}
                      </p>
                    </div>
                  );
                }
              )
            )}
          </div>
          <div className="display_div_second">
            <p className="column_title">{isLoading ? <Skeleton /> : keys[1]}</p>

            {isLoading ? (
              <Skeleton width={200} style={{ marginTop: "1px" }} count={8} />
            ) : (
              statistics?.vessel_statistics &&
              keys.length >= 2 &&
              Object.keys(statistics?.vessel_statistics[keys[1]]).map(
                (key, index) => {
                  return (
                    <div key={index} className="column_row">
                      <p className="column_row_name">{key}</p>
                      <p className="column_row_number">
                        {statistics?.vessel_statistics[keys[1]][key]}
                      </p>
                    </div>
                  );
                }
              )
            )}
          </div>
        </>
      )}
    </div>
  );
};

export default VesselsStatistics;
