import React, { useEffect, useState } from "react";

import "../../styles/scheduling.scss";
import { COLORS } from "../../../../styles/themes/default/colors";
import Select from "../../../../components/Cdl/Select";

import ApptTypeIcon from "../../assets/apptTypeIcon.svg";
import ClockIcon from "../../assets/clock_sch.svg";

import DatePickerSingle from "../../../../components/Cdl/DatePickerSingle";
import moment from "moment";
import alertService from "../../../../services/alertService";
import { CustomLoader } from "../../../../components/CustomLoader";
import { timePeriods } from "../../utils/contants";
const SchedulingDiv = (props) => {
  const ChechButton = ({ isActive, setIsActive }) => {
    return (
      <div
        style={{
          width: "16px",
          height: "16px",
          borderRadius: "25px",
          border: `1px solid ${
            isActive ? COLORS.off_white : COLORS.dark_grey
          } `,
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          transition: "0.3s ease-in-out all",
          cursor: "pointer",
          marginRight: "8px",
        }}
      >
        {isActive && (
          <div
            style={{
              width: "8px",
              height: "8px",
              borderRadius: "25px",
              backgroundColor: COLORS.purple,
            }}
          />
        )}
      </div>
    );
  };
  const ScheduleTimeCell = ({
    data,
    schedulingNow,
    setSelectedTime,
    selectedTime,
    disabled,
  }) => {
    let is_active = JSON.stringify(data) === JSON.stringify(selectedTime);
    return (
      <button
        onClick={() => {
          setSelectedTime(data);
        }}
        disabled={disabled}
        style={{
          transition: "0.3s ease-in-out all",
          padding: "11px 7px",
          backgroundColor: "#313131",
          borderRadius: "8px",
          border: is_active
            ? `1px solid ${COLORS.off_white}`
            : "1px solid transparent",
          opacity: !schedulingNow ? 1 : disabled ? 0.4 : 1,
        }}
      >
        <p
          style={{
            margin: 0,
            color: is_active ? COLORS.off_white : COLORS.dark_grey,
            fontSize: "16px",
          }}
        >{`${data.start} - ${data.end} ${data.time}`}</p>
      </button>
    );
  };

  const SearchStyle = {
    marginRight: "16px",
    width: "235px",
    backgroundColor: "#313131",
    border: "none",
  };
  const [schedulingNow, setSchedulingNow] = useState(
    props.notSuportedTerminal ? false : true
  );
  const [date, setDate] = useState("");
  const [apptType, setApptType] = useState(
    props?.appointment?.type ? props.appointment.type : ""
  );
  const [time, setTime] = useState("");
  const [dateExisting, setDateExisting] = useState("");
  const [apptTypeExisitng, setApptTypeExisitng] = useState(
    props?.appointment?.type ? props.appointment.type : ""
  );
  const [timeExisitng, setTimeExisitng] = useState("");
  const [selectedTime, setSelectedTime] = useState({
    start: "",
    end: "",
    time: "",
    disabled: "",
  });

  const [messageIndex, setMessageIndex] = useState(0);
  const messages = [
    "Gathering data from the source website...",
    "Extracting relevant details...",
    "Ensuring data integrity...",
    "Cleaning and filtering extracted information...",
    "Finalizing data extraction process...",
  ];
  useEffect(() => {
    props.setSchedulingNowDate(date);
    props.setSchedulingNowType(apptType);
    if (date && apptType) {
      setMessageIndex(0);
    }
  }, [date, apptType]);
  useEffect(() => {
    if (time && schedulingNow) {
      let ampm = time.slice(-2);
      let startEnd = time.slice(0, -2).split("-");
      setSelectedTime({
        start: startEnd.at(0).trim(),
        end: startEnd.at(1).trim(),
        time: ampm,
        disabled: true,
      });
    } else {
      setSelectedTime({
        start: "",
        end: "",
        time: "",
        disabled: "",
      });
    }
  }, [time]);

  useEffect(() => {
    const intervalId = setInterval(() => {
      setMessageIndex((prevIndex) => (prevIndex + 1) % messages.length);
    }, 5000);
    return () => {
      clearInterval(intervalId);
    };
  }, [props.loadingAppointments, messages.length]);

  const resetValues = () => {
    setSelectedTime({
      start: "",
      end: "",
      time: "",
      disabled: "",
    });
    if (!props.appointment.type) {
      setApptTypeExisitng("");
      setApptType("");
    }
    setDateExisting("");
    setTimeExisitng("");
    setTime("");
    setDate("");
  };
  const checkForAppointment = (period) => {
    let start = parseInt(period.start.split(":").at(0));
    let end = parseInt(period.end.split(":").at(0));
    let added = true;
    props.availableAppointments.forEach((item) => {
      if (start >= item.at(0) && end <= item.at(1)) {
        added = false;
      }
    });
    return added;
  };

  return (
    <div className="scheduling_main_div">
      <div className="date_grid_div">
        <div
          className="scheduling_header_div"
          onClick={() => {
            if (!props.notSuportedTerminal) {
              resetValues();
              setSchedulingNow(true);
            }
          }}
        >
          <ChechButton isActive={schedulingNow} />
          <p
            className="scheduling_header_title"
            style={{
              color: schedulingNow ? COLORS.off_white : COLORS.dark_grey,
            }}
          >
            Schedule now
          </p>
        </div>
        <div
          style={{
            opacity: schedulingNow ? 1 : 0.4,
            transition: " 0.3s ease-in-out all",
            display: "flex",
            width: "100%",
            marginTop: "16px",
          }}
        >
          <DatePickerSingle
            disabled={!schedulingNow}
            css={SearchStyle}
            placeholder="Date"
            value={date}
            onChange={setDate}
            openAbove={false}
            darkTheme={true}
            minDate={new Date()}

            //   error={receiptError?.title}
          />
          <Select
            type="select"
            disabled={!schedulingNow}
            icon={ClockIcon}
            value={time}
            onChange={setTime}
            placeholder="Time"
            pillBackground="#1f1f1f"
            css={SearchStyle}
            cssDiv={{
              width: SearchStyle.width,
              backgroundColor: COLORS.off_black,
              color: COLORS.dark_grey,
            }}
            options={timePeriods
              .filter((item) => item.disabled)
              .map((item) => {
                return `${item.start} - ${item.end} ${item.time}`;
              })}
            darkTheme={true}
            closeOnSelect={true}
          />
          <Select
            type="select"
            disabled={!schedulingNow}
            icon={ApptTypeIcon}
            value={apptType}
            onChange={setApptType}
            pillBackground="#1f1f1f"
            placeholder="Appt. type"
            css={{ ...SearchStyle, marginRight: 0 }}
            cssDiv={{
              width: SearchStyle.width,
              backgroundColor: COLORS.off_black,
              color: COLORS.dark_grey,
            }}
            options={
              props.isDemurrageComponent
                ? ["Import Pickup", "Export Dropoff"]
                : ["Empty Return"]
            }
            darkTheme={true}
            closeOnSelect={true}
          />
        </div>
        <div
          style={{
            width: "100%",
            // backgroundColor: "red",
            height: "210px",
            marginBottom: "16px",
            marginTop: "16px",

            position: "relative",
          }}
        >
          <>
            <div
              style={{
                width: "100%",
                display: "grid",
                gridTemplateColumns: "172px 172px 172px 172px",
                gridGap: "16px",
                overflow: "auto",
                height: "210px",
                opacity:
                  (schedulingNow && !(date && apptType)) ||
                  props.loadingAppointments ||
                  props.notSuportedTerminal
                    ? 0.4
                    : 1,
              }}
            >
              {timePeriods.map((item, index) => {
                let disabled = checkForAppointment(item);
                return (
                  <ScheduleTimeCell
                    disabled={disabled}
                    data={item}
                    key={index}
                    schedulingNow={schedulingNow}
                    selectedTime={selectedTime}
                    setSelectedTime={setSelectedTime}
                  />
                );
              })}
            </div>
            {((schedulingNow && !(date && apptType)) ||
              props.notSuportedTerminal ||
              (props.loadingAppointments === false &&
                props.availableAppointments.length === 0) ||
              props.loadingAppointments) && (
              <div
                style={{
                  width: "100%",
                  height: "100%",
                  position: "absolute",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  color: COLORS.purple,
                  top: 0,
                }}
              >
                {props.notSuportedTerminal ? (
                  <p style={{ color: COLORS.purple, fontWeight: "600" }}>
                    No appointment scheduling supported for this terminal
                  </p>
                ) : props.loadingAppointments === false &&
                  props.availableAppointments.length === 0 ? (
                  <p style={{ color: COLORS.purple, fontWeight: "600" }}>
                    No appointments available
                  </p>
                ) : props.loadingAppointments ? (
                  <div className="d-flex gap16 align-center">
                    <p className="m0 c-purple fw600">
                      {messages[messageIndex]}
                    </p>
                    <CustomLoader darktheme={true} />
                  </div>
                ) : (
                  <p style={{ color: COLORS.purple, fontWeight: "600" }}>
                    Select Date and Import Type
                  </p>
                )}
              </div>
            )}
          </>
        </div>
        <div className="scheduling_button_div">
          <button
            disabled={!schedulingNow}
            className="scheduling_button"
            style={{ opacity: schedulingNow ? 1 : 0.4 }}
            onClick={() => {
              if (date === "" || selectedTime.start === "") {
                alertService.info("Select date and time");
                return;
              }

              props.setAppointment({
                date: moment(date).format("MM.DD.YYYY"),
                time: `${selectedTime.start} - ${selectedTime.end} ${selectedTime.time}`,
              });
              props.setOpenAppointment(false);
            }}
          >
            Schedule
          </button>
        </div>
      </div>

      <div className="appointment_div">
        <div>
          <div
            className="scheduling_header_div"
            onClick={() => {
              resetValues();
              setSchedulingNow(false);
            }}
          >
            <ChechButton
              isActive={!schedulingNow}
              setIsActive={setSchedulingNow}
            />
            <p
              className="scheduling_header_title"
              style={{
                color: !schedulingNow ? COLORS.off_white : COLORS.dark_grey,
              }}
            >
              Existing appointment
            </p>
          </div>
          <p
            style={{
              margin: "16px 0px",
              color: !schedulingNow ? COLORS.off_white : COLORS.dark_grey,
              opacity: !schedulingNow ? 1 : 0.4,
              transition: "0.3s ease-in-out all",
            }}
          >
            Already have an appointment?
          </p>
          <div
            style={{
              opacity: !schedulingNow ? 1 : 0.4,
              transition: " 0.3s ease-in-out all",
            }}
          >
            <DatePickerSingle
              disabled={schedulingNow}
              css={{ width: "100%", marginBottom: "16px" }}
              placeholder="Pick a date"
              value={dateExisting}
              onChange={setDateExisting}
              openAbove={true}
              darkTheme={true}
              alignRight={true}
              minDate={new Date()}
              //   error={receiptError?.title}
            />
            <Select
              type="select"
              pillBackground="#1f1f1f"
              disabled={schedulingNow}
              icon={ClockIcon}
              value={timeExisitng}
              onChange={setTimeExisitng}
              placeholder="Select time"
              css={{
                width: "100%",
                backgroundColor: "#313131",
                border: "none",
                marginBottom: "16px",
                // color: `${COLORS.dark_grey}66`,
              }}
              cssDiv={{
                width: "100%",
                backgroundColor: COLORS.off_black,
                color: COLORS.dark_grey,
              }}
              options={timePeriods.map((item) => {
                return `${item.start} - ${item.end} ${item.time}`;
              })}
              darkTheme={true}
              closeOnSelect={true}
            />
            <Select
              type="select"
              // disabled={!schedulingNow || props.appointment.type !== ""}
              icon={ApptTypeIcon}
              value={apptTypeExisitng}
              onChange={setApptTypeExisitng}
              placeholder="Appt. type"
              pillBackground="#1f1f1f"
              css={{
                width: "100%",
                backgroundColor: "#313131",
                border: "none",
                marginBottom: "16px",

                // color: `${COLORS.dark_grey}66`,
              }}
              cssDiv={{
                width: "100%",
                backgroundColor: COLORS.off_black,
                color: COLORS.dark_grey,
              }}
              options={
                props.isDemurrageComponent
                  ? ["Import Pickup", "Export Dropoff"]
                  : ["Empty Return"]
              }
              darkTheme={true}
              closeOnSelect={true}
            />
          </div>
        </div>
        <div className="scheduling_button_div">
          <button
            disabled={schedulingNow}
            className="scheduling_button"
            style={{ opacity: !schedulingNow ? 1 : 0.4 }}
            onClick={() => {
              if (dateExisting === "" || timeExisitng === "") {
                alertService.info("Select date and time");
                return;
              }
              props.setAppointment({
                date: moment(dateExisting).format("MM.DD.YYYY"),
                time: timeExisitng,
              });
              props.setOpenAppointment(false);
            }}
          >
            Save
          </button>
        </div>
      </div>
    </div>
  );
};

export default SchedulingDiv;
