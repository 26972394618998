import * as React from "react";
import CircularProgress, {
  CircularProgressProps,
} from "@mui/material/CircularProgress";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";

import { COLORS } from "../styles/themes/default/colors";

const CircularProgressWithLabel = ({ value, CircularProgressProps }) => {
  return (
    <Box sx={{ position: "relative", display: "inline-flex" }}>
      <CircularProgress
        variant="determinate"
        props={CircularProgressProps}
        sx={{
          color: COLORS.primary,
          width: `90px !important`,
          height: `90px !important`,
          "@media only screen and (max-width: 1400px) and (min-width: 1280px)": {
            width: `65px !important`,
            height: `65px !important`,
          },
        }}
        value={value * 100}
      />
      <Box
        sx={{
          top: 0,
          left: 0,
          bottom: 0,
          right: 0,
          position: "absolute",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <Typography
          sx={{
            fontSize: "24px",
            fontWeight: "600",
            lineHeight: "29px",
            "@media only screen and (max-width: 1400px) and (min-width: 1280px)": {
              fontSize: "1.01rem",
            },
          }}
          component="div"
        >{`${(value * 100).toFixed(0)}%`}</Typography>
      </Box>
    </Box>
  );
};

export default CircularProgressWithLabel;
