import React from "react";
import { COLORS } from "../styles/themes/default/colors";
import {
  Dialog,
  DialogTitle,
  DialogActions,
  DialogContent,
  Button,
} from "@mui/material";

export default function Modal({
  openModal,
  setOpenModal,
  title,
  content,
  buttons,
  icon,
  titleStyle,
  conetentStyle,
  //   actionsStyle,
  className,
}) {
  return (
    <Dialog
      open={openModal}
      onClose={() => setOpenModal(false)}
      className={className ? className : ""}
    >
      <DialogTitle
        style={
          titleStyle
            ? titleStyle
            : {
                alignItems: "center",
                display: "flex",
                flexDirection: "column",
                textAlign: "center",
                fontWeight: "800",
                fontFamily: "Montserrat",
              }
        }
      >
        {icon && (
          <img
            src={icon}
            style={{
              width: "50px",
              height: "50px",
              margin: "20px",
              color: COLORS.sunrise_orange,
            }}
            alt=""
          />
        )}
        {title}
      </DialogTitle>
      <DialogContent
        style={
          conetentStyle
            ? conetentStyle
            : { textAlign: "center", margin: "0 50px" }
        }
      >
        {content?.map((item) => (
          <p>{item}</p>
        ))}
      </DialogContent>
      <DialogActions
        style={{ justifyContent: "center", display: "flex", margin: "20px" }}
      >
        {buttons?.map((item) => (
          <Button
            variant={item.variant}
            style={item.style}
            onClick={item.click}
            color={item.color}
          >
            {item.label}
          </Button>
        ))}
      </DialogActions>
    </Dialog>
  );
}
