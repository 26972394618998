import React from "react";
import IOSwitch from "../../../../../components/CustomIOSwitch";
import Select from "../../../../../components/Cdl/Select";
import { COLORS } from "../../../../../styles/themes/default/colors";
import EsgTextField from "../../EsgTextFields";
import { contributionPeriodValueChanges } from "../../../utils/constants";

const OffsetHeader = (props) => {
  return (
    <div style={{ borderBottom: "1px solid #313131", paddingBottom: "16px" }}>
      <div className="d-flex justify-between align-center mb16">
        <p className="c-white fw600 m0">Offset projects</p>
        <div className="d-flex gap8 align-center">
          <IOSwitch
            darkTheme={true}
            isActive={props.data.autoContribution}
            setIsActive={() =>
              props.setData((prev) => {
                return { ...prev, autoContribution: !prev.autoContribution };
              })
            }
          />
          <p className="fw600 m0 c-white fs14">Auto contribution</p>
        </div>
      </div>
      <div className="d-flex justify-between align-center mb8">
        <p className="c-white m0">Contribution period</p>
        <Select
          type="select"
          value={props.data.contributionPeriod}
          onChange={(value) => {
            let newData = { ...props.data };

            newData.totalCO2offset *=
              contributionPeriodValueChanges[newData.contributionPeriod][value];

            newData.contributionPeriod = value;
            props.setData(newData);
          }}
          placeholder=""
          css={{
            width: "150px",
            backgroundColor: "#313131",
            border: "1px solid #8c8c8c80",
            padding: "8px",
          }}
          cssDiv={{
            width: "224px",
            backgroundColor: COLORS.off_black,
            color: COLORS.dark_grey,
          }}
          options={["Weekly", "Monthly", "Yearly"]}
          darkTheme={true}
          closeOnSelect={true}
          removeCell={true}
        />
      </div>
      <div className="d-flex justify-between align-center">
        <p className="c-white m0">
          tCO<sub>2</sub>e amount for offsets
        </p>
        <EsgTextField
          type="number"
          styles={{ width: "150px" }}
          value={props.data.totalCO2offset}
          is_function
          setValue={(value) => {
            let newData = { ...props.data };
            newData.totalCO2offset = value;
            props.setData(newData);
            props.toggleOffset("offset", value);
          }}
        />
      </div>
    </div>
  );
};
export default OffsetHeader;
