import React from "react";
import { Box, List, createTheme, useMediaQuery } from "@mui/material";
import { useState } from "react";
import useStore from "../../../store/store";

import { AvatarIcon } from "../../../components/ProfilePicture";
import moment from "moment";
import fetchWrapper from "../../../helpers/fetch-wrapper";
import { APP_URL } from "../../../utils/constants";
import alertService from "../../../services/alertService";

import sendMessageActive from "../../../assets/img/DemurrageDetailAndRequest/blueSendMessage.svg";
import sendMessage from "../../../assets/img/DemurrageDetailAndRequest/sendMessage.svg";

import "../styles/demurrage_styles.scss";

export default function DemurrageComment({
  comments,
  setComments,
  status,
  demurrageId,
  disable,
}) {
  const currentUser = useStore((state) => state.currentUser);
  const [typing, setTyping] = useState("");

  const theme1 = createTheme({
    breakpoints: {
      values: {
        sl: 1400,
      },
    },
  });

  const smallLaptop = useMediaQuery(theme1.breakpoints.down("sl"));

  const CommentContainerStyle = {
    width: "100%",
    height: "90%",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  };

  const getDate = (date_unformat) => {
    const today = new Date();
    const date = new Date(date_unformat);

    if (
      date.getMonth() === today.getMonth() &&
      date.getFullYear() === today.getFullYear()
    ) {
      if (date.getDate() === today.getDate()) {
        return moment(date).format("LT");
      } else if (date.getDate() === today.getDate() - 1) {
        return "Yesterday";
      }
    }
    return moment(date).format("MM/DD/yyyy");
  };
  function Comment({ comment, previousCommentUser }) {
    const shouldDisplayAvatar = comment.creator.id !== previousCommentUser;
    return (
      <Box
        style={{
          paddingRight:
            shouldDisplayAvatar && currentUser.user?.id === comment.creator.id
              ? "0px"
              : "40px",
          paddingLeft:
            shouldDisplayAvatar && currentUser.user?.id !== comment.creator.id
              ? "0px"
              : "33px",
        }}
        className={
          comment.creator.id === currentUser.user?.id
            ? "message_rigth"
            : "message_left"
        }
      >
        {shouldDisplayAvatar && (
          <AvatarIcon
            src={
              comment.creator?.picture_64
                ? `data:image/png;base64,${comment.creator.picture_64}`
                : ""
            }
            firstName={comment.creator.first_name}
            lastName={comment.creator.last_name}
            style={{
              color: "black",
              backgroundColor: "#D3D3D3",
              fontSize: "10px",
              height: "24px",
              width: "24px",
              marginRight: "8px",
              marginLeft:
                comment.creator.id === currentUser.user?.id ? "8px" : "0px",
            }}
          />
        )}
        <Box className="message_box">
          {shouldDisplayAvatar && (
            <p className="comment_user_name">{comment.creator.full_name}</p>
          )}

          <p className="message_text">{comment.text}</p>
          <p className="comment_date">{getDate(comment.created_at)}</p>
        </Box>
      </Box>
    );
  }
  async function handleAddComment() {
    if (!typing) {
      return;
    }
    if (demurrageId) {
      const data = { text: typing };
      await fetchWrapper
        .post(`${APP_URL}/dnd/add_comment/${demurrageId}/`, data)
        .then((resp) => {
          setComments(comments.concat([resp]));
          setTyping("");
        })
        .catch((error) => {
          alertService.error(error.response.status, "Comment problem");
        });
    } else {
      setComments(
        comments.concat([
          { text: typing, creator: currentUser.user, created_at: new Date() },
        ])
      );
      setTyping("");
    }
  }

  return (
    <Box sx={CommentContainerStyle}>
      <Box className="messages_box">
        {comments.length > 0 && (
          <List disablePadding>
            {comments.map((item, index) => {
              return (
                <Comment
                  comment={item}
                  key={index}
                  previousCommentUser={
                    index === 0 ? null : comments.at(index - 1).creator.id
                  }
                />
              );
            })}
          </List>
        )}
      </Box>
      <Box className="send_message_container">
        <input
          className="comment_input"
          onKeyDown={(ev) => {
            if (ev.key === "Enter") {
              handleAddComment();
            }
          }}
          disabled={status === "RE" || disable}
          placeholder="Comment here..."
          value={typing}
          onChange={(e) => setTyping(e.target.value)}
          size="small"
          InputProps={{ style: { fontSize: smallLaptop ? "10px" : "14px" } }}
        />

        <button
          className="send_message_button"
          onClick={() => handleAddComment()}
        >
          <img src={typing ? sendMessageActive : sendMessage} alt="" />
        </button>
      </Box>
    </Box>
  );
}
