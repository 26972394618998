import create from "zustand";
import { persist, devtools } from "zustand/middleware";

let store = (set) => ({
  esgCart: "",
  setEsgCart: (id) => set((state) => ({ esgCart: id })),
  offsetProjects: [],
  setOffsetProjects: (projects) =>
    set((state) => ({ offsetProjects: projects })),
  offsetContainerId: "",
  setOffsetContainerId: (id) => set((state) => ({ offsetContainerId: id })),
  customersFilter: [],
  setCustomersFilter: (customers) =>
    set((state) => ({ customersFilter: customers })),
});
store = devtools(store);
store = persist(store);

const esgStore = create(store);
export default esgStore;
