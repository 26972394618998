import React from "react";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";

const RenewableOffset = () => {
  const history = useHistory();
  return (
    <div className="renewableDiv">
      <div className="d-flex justify-between w100 align-center mb16">
        <p className="c-white m0 fw600">Renewable Offset</p>
        <p
          className="c-blue m0"
          onClick={() => {
            history.push("/esg/offset-projects");
          }}
          style={{ cursor: "pointer", fontSize: "14px" }}
        >
          View all
        </p>
      </div>
      <div className="co2Labels">
        <p className="p-24-b-white" style={{ marginBottom: "16px" }}>
          3 tCO<sub>2</sub>e
        </p>
      </div>
      <div className="lineProcentsLabel">
        <p className="c-purple m0 fw600" style={{ fontSize: "24px" }}>
          19%
        </p>
        <div className="lineProcents">
          <p className="p-16-gray" style={{ marginBottom: 0 }}>
            Goal:
          </p>
          <p className="c-purple m0 fw600">16k</p>
          <p className="c-white m0">
            tCO<sub>2</sub>e
          </p>
        </div>
      </div>
      <div className="lineProcents">
        <div
          style={{
            height: "13px",
            borderRadius: "0.5rem",
            width: "100%",
            margin: 0,
            backgroundColor: "#FF00B833",
            position: "relative",

            // "linear-gradient(to right,#00FF65,rgba(0, 255, 101, 0.2))",
          }}
        >
          <div
            style={{
              height: "13px",
              borderRadius: "0.5rem",
              width: "19%",
              margin: 0,
              background: "linear-gradient(90deg, #FF00B8 0%, #FF9DE4 101.47%)",
              position: "absolute",
              top: "0px",
            }}
          />
        </div>
      </div>
      <div className="lineProcentsLabelBottom">
        <p
          className="c-purple m0 fw600"
          style={{ color: "#00ff65", fontSize: "24px" }}
        >
          10%
        </p>
        <p className="c-white m0" style={{ color: "#51DA87" }}>
          Emission erased
        </p>
      </div>
      <div className="lineProcents">
        <div
          style={{
            height: "13px",
            borderRadius: "8px",
            width: "100%",
            margin: 0,
            backgroundColor: "#00FF6533",
            position: "relative",
            marginBottom: "16px",

            // "linear-gradient(to right,#00FF65,rgba(0, 255, 101, 0.2))",
          }}
        >
          <div
            style={{
              height: "13px",
              borderRadius: "8px",
              width: "10%",
              margin: 0,
              background: "linear-gradient(90deg, #00FF65 0%, #A5FFC8 100%)",
              position: "absolute",
              top: "0px",
            }}
          />
        </div>
      </div>
    </div>
  );
};

export default RenewableOffset;
