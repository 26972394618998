export const badgesToActiveColor = [
  "#EA1D2D",
  "#D19F2A",
  "#2D9A47",
  "#C22033",
  "#EF412A",
  "#00ADD8",
  "#FDB714",
  "#8F1838",
  "#F36E24",
  "#E01A83",
  "#F99D25",
  "#CD8B2A",
  "#48773C",
  "#007DBB",
  "#40AE49",
  "#00558A",
  "#1A3668",
];
export const emissionStartValue = 200000;

export const contributionPeriodToCoefficient = {
  Yearly: 1,
  Monthly: 12,
  Weekly: 52,
};
export const contributionPeriodValueChanges = {
  Yearly: {
    Monthly: 0.083,
    Weekly: 0.019,
  },
  Monthly: {
    Yearly: 12,
    Weekly: 0.23,
  },
  Weekly: {
    Monthly: 4.34,
    Yearly: 52,
  },
};
