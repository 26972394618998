import { React, useState } from "react";
import { Link } from "react-router-dom";

import "./styles/loginAndRegister.scss";
import { useForm } from "react-hook-form";

import accountService from "../../services/accountService";
import alertService from "../../services/alertService";

import { Input } from "../../components/Cdl/Input";
import ErrorImg from "../../assets/img/Error.svg";
import { Button as ButtonCDL } from "../../components/Cdl/Button";
import Button from "@mui/material/Button";

import CssTextField from "../../components/StyledTextField";
import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import { useMediaQuery, createTheme } from "@mui/material";
import useStore from "../../store/store";

const Login = ({ history }) => {
  const setLoggedUser = useStore((state) => state.setUser);
  const [isVisiblePassword, setIsVisiblePassword] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const theme1 = createTheme({
    breakpoints: {
      values: {
        sl: 1400,
      },
    },
  });

  const smallLaptop = useMediaQuery(theme1.breakpoints.down("sl"));

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();

  async function onSubmit(data) {
    try {
      setIsLoading(true);
      const user = await accountService.login(data.email, data.password);
      setLoggedUser(user);

      history.push("/");
    } catch (error) {
      alertService.error(error.response.status, "Wrong email or password!");
      setIsLoading(false);
    }
  }

  return (
    <div className="loginDiv">
      <p className="p-24-b-white">Login</p>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Input
          placeholder="E-mail"
          css={{
            width: "100%",
            background: "transparent",
            color: "#ffffff",
            border: "1px solid rgba(189, 189, 189, 0.3)",
          }}
          register={register("email", {
            required: true,
            pattern: /^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+.[a-zA-Z0-9-.]+$/i,
          })}
          message={
            errors.email?.type === "required"
              ? "Email is required"
              : errors.email?.type === "pattern"
              ? "Invalid email"
              : ""
          }
          messageType={
            errors.email?.type === "required" ||
            errors.email?.type === "pattern"
              ? "error"
              : ""
          }
        />
        <CssTextField
          {...register("password", { required: true })}
          placeholder="Password"
          type={isVisiblePassword ? "text" : "password"}
          style={{
            width: "100%",
            background: "transparent",
            marginTop: "10px",
          }}
          InputProps={{
            style: {
              fontFamily: "Montserrat",
              color: "#ffffff",
              padding: "0",
              fontSize: smallLaptop ? "11px" : "16px",
            },
            endAdornment: (
              <Button
                style={{ outline: "none", backgroundColor: "transparent" }}
                onClick={() => setIsVisiblePassword(!isVisiblePassword)}
              >
                {isVisiblePassword ? (
                  <VisibilityIcon className="visiableIcon" />
                ) : (
                  <VisibilityOffIcon className="visiableIcon" />
                )}
              </Button>
            ),
          }}
        />
        {errors.password?.type === "required" && (
          <div className="inputErrorDiv">
            <img
              src={ErrorImg}
              className="inputErrorIcon"
              alt="error passwonr"
            />
            <p className="passwordError">Password is required</p>
          </div>
        )}

        <div className="forgotTextDiv">
          <Link to="/account/forgot-password" className="p-14-b-blue">
            Forgot Password?
          </Link>
        </div>
        <ButtonCDL
          text="Login"
          type="primary"
          css={{ width: "100%", margin: "20px 0px" }}
          loading={isLoading}
        />
        <p className="p-14-b-white">
          Don't have a account?{" "}
          <Link to="/account/register" className="p-14-b-blue">
            Register
          </Link>{" "}
          now.
        </p>
      </form>
    </div>
  );
};

export default Login;
