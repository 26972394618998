import React, { useEffect, useRef, useState } from "react";
import ReactApexChart from "react-apexcharts";
import { COLORS } from "../../../../styles/themes/default/colors";

import Skeleton from "react-loading-skeleton";
import { useMediaQuery, createTheme } from "@mui/material";

import "../../styles/radialBarStatistics.scss";
import { colorDict } from "../../utils/contants";
import { hasExactlyOneNonZeroElement, calculatePercentages } from "../../utils/functions";

const RadialBarStatistics = ({
  statistics,
  loading,
  chartStatusFilter,
  setChartStatusFilter,
  statisticFilter,
  setStatisticFilter,
  setMainColor,
  riskFilter,
  setRedirect,
  containersPerStatusMaxValue,
  setContainersPerStatusMaxValue
}) => {
  const [containerStats, setContainerStats] = useState({});
  const [series, setSeries] = useState([]);
  const [options, setOptions] = useState({});
  const [chartNum, setChartNum] = useState(0);
  const [hasValues, setHasValues] = useState([]);

  const theme1 = createTheme({
    breakpoints: {
      values: {
        sl: 1400,
      },
    },
  });

  const smallLaptop = useMediaQuery(theme1.breakpoints.down("sl"));

  const statusMapping = [
    "Empty Returned",
    "Out to Delivery",
    "Vessel Arriving",
    "At Port",
    "On Water",
    "Vessel Departing",
    "Out to Load",
  ];

  useEffect(() => {
    if (statistics?.container_statuses && Object.keys(statistics).length > 0) {
      const sortedSeries = [];
      let new_colors = [];
      setContainerStats(statistics?.container_statuses);

      if (statisticFilter) {
        sortedSeries.push(statistics?.container_statuses[statisticFilter]);
        setSeries(calculatePercentages(sortedSeries, containersPerStatusMaxValue));
      } else if (riskFilter) {
        let new_values = [];
        statusMapping.forEach((item) => {
          if (statistics?.container_statuses[item] > 0) {
            sortedSeries.push(statistics?.container_statuses[item]);
            new_values.push(item);
            new_colors.push(colorDict[item]);
          }
        });
        setHasValues(new_values);
        setSeries(calculatePercentages(sortedSeries, containersPerStatusMaxValue));
      } else {
        const statusKeys = ["Empty Returned", "Out to Delivery", "Vessel Arriving", "At Port", "On Water", "Vessel Departing", "Out to Load"];
        sortedSeries.push(...statusKeys.map(key => statistics.container_statuses[key] || 0));

        let tmpMax = Math.max(
          ...sortedSeries.map((item) => {
            return item;
          })
        );
        if (!hasExactlyOneNonZeroElement(sortedSeries)) {
          tmpMax *= 1.1
        }
        setContainersPerStatusMaxValue(tmpMax);
        setSeries(calculatePercentages(sortedSeries, tmpMax));
      }

      setOptions({
        chart: {
          events: {
            dataPointSelection: (event, chartContext, config) => {
              if (!statisticFilter && !riskFilter) {
                setChartStatusFilter(
                  `&journey_statuses=${statusMapping[config.dataPointIndex]}`
                );
                setRedirect(false);
                setStatisticFilter(statusMapping[config.dataPointIndex]);
                setMainColor(colorDict[statusMapping[config.dataPointIndex]]);
              }
            },
          },
        },
        plotOptions: {
          radialBar: {
            hollow: {
              size: smallLaptop ? "20px" : "30px",
            },
            track: {
              show: false,
            },
            dataLabels: {
              name: {
                fontSize: smallLaptop ? "17px" : "22px",
                color: COLORS.off_white,
              },
              value: {
                offsetY: -10,
                fontSize: smallLaptop ? "18px" : "24px",
                lineHeight: "29px",
                fontWeight: "600",
                formatter: function (value) {
                  return Math.round((value * containersPerStatusMaxValue) / 100).toFixed(0);
                },
              },
              total: {
                show: true,
                label: "Total",
                formatter: function (value) {
                  return statistics?.container_statuses?.statistics_sum;
                },
              },
            },

            labels: statisticFilter
              ? statisticFilter
              : [
                "Vessel Arriving",
                "Vessel Departing",
                "Empty Returned",
                "At Port",
                "On Water",
                "Out to Delivery",
                "Out to Load",
              ],
          },
        },
        grid: {
          padding: {
            bottom: statisticFilter ? -20 : 0,
            top: statisticFilter ? -20 : 0,
          },
        },

        colors: statisticFilter
          ? [colorDict[statisticFilter]]
          : riskFilter
            ? new_colors
            : [
              colorDict["Empty Returned"],
              colorDict["Out to Delivery"],
              colorDict["Vessel Arriving"],
              colorDict["At Port"],
              colorDict["On Water"],
              colorDict["Vessel Departing"],
              colorDict["Out to Load"],
            ],
        stroke: {
          width: 0.01,
          lineCap: "round", // Make radial lines rounded
        },
      });
      setChartNum(chartNum + 1);
    }
  }, [statistics, statisticFilter, containersPerStatusMaxValue]);
  const handleLabelClick = (label) => {
    if (!riskFilter && !statisticFilter) {
      setChartStatusFilter(`&journey_statuses=${label}`);
      setStatisticFilter(label);
      setMainColor(colorDict[label]);
      setRedirect(false);
    }
  };
  return (
    <>
      {loading ? (
        <Skeleton
          circle
          width={220}
          height={220}
          style={{ marginLeft: "40px" }}
        />
      ) : (
        <ReactApexChart
          style={smallLaptop ? { position: "relative", right: "30px" } : {}}
          options={options}
          series={series}
          key={chartNum}
          type="radialBar"
          height={smallLaptop ? 190 : 270}
          width={smallLaptop ? 190 : 270}
        />
      )}
      <div className="container_statistics_values">
        {loading ? (
          <Skeleton width={150} count={10} style={{ marginTop: "6px" }} />
        ) : statisticFilter ? (
          <div
            style={
              smallLaptop
                ? {
                  fontSize: "11px",
                  width: "220px",
                  right: "20px",
                  position: "relative",
                  display: "flex",
                  alignItems: "center",
                }
                : {
                  display: "flex",
                  alignItems: "center",
                }
            }
          >
            <div
              style={{
                height: smallLaptop ? "5px" : "",
                width: smallLaptop ? "5px" : "",
                backgroundColor: colorDict[statisticFilter],
              }}
              className="single_statistic_label"
            />
            <p style={{ margin: 0 }}>{statisticFilter}</p>
            <p className="value">{containerStats[statisticFilter]}</p>
          </div>
        ) : riskFilter ? (
          <>
            {hasValues.map((item) => {
              return (
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    marginBottom: "16px",
                  }}
                >
                  <div
                    style={{
                      backgroundColor: colorDict[item],
                    }}
                    className="single_statistic_label"
                  />
                  <p style={{ margin: 0 }}>{item}</p>
                  <p className="value">{containerStats[item]}</p>
                </div>
              );
            })}
          </>
        ) : (
          <div className="radial_bar_legend_box">
            <div
              className="label"
              style={{ cursor: "pointer" }}
              onClick={() => handleLabelClick("Out to Load")}
            >
              <p className="radial_bar_legend_value">Out to Load</p>
              <p className="value">{containerStats["Out to Load"]}</p>
            </div>
            <div
              className="label"
              style={{ cursor: "pointer" }}
              onClick={() => handleLabelClick("Vessel Departing")}
            >
              <p className="radial_bar_legend_value">Vessel Departing</p>
              <p className="value">{containerStats["Vessel Departing"]}</p>
            </div>

            <div
              className="label"
              style={{ cursor: "pointer" }}
              onClick={() => handleLabelClick("On Water")}
            >
              <p className="radial_bar_legend_value">On Water</p>
              <p className="value">{containerStats["On Water"]}</p>
            </div>
            <div
              className="label"
              style={{ cursor: "pointer" }}
              onClick={() => handleLabelClick("At Port")}
            >
              <p className="radial_bar_legend_value">At Port</p>
              <p className="value">{containerStats["At Port"]}</p>
            </div>
            <div
              className="label"
              style={{ cursor: "pointer" }}
              onClick={() => handleLabelClick("Vessel Arriving")}
            >
              <p className="radial_bar_legend_value">Vessel Arriving</p>
              <p className="value">{containerStats["Vessel Arriving"]}</p>
            </div>

            <div
              className="label"
              style={{ cursor: "pointer" }}
              onClick={() => handleLabelClick("Out to Delivery")}
            >
              <p className="radial_bar_legend_value">Out to Delivery</p>
              <p className="value">{containerStats["Out to Delivery"]}</p>
            </div>
            <div
              className="label"
              style={{ cursor: "pointer" }}
              onClick={() => handleLabelClick("Empty Returned")}
            >
              <p className="radial_bar_legend_value">Empty Returned</p>
              <p className="value">{containerStats["Empty Returned"]}</p>
            </div>
          </div>
        )}
      </div>
    </>
  );
};
export default RadialBarStatistics;
