import {
  Box,
  styled,
  Typography,
  Autocomplete,
  IconButton,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "../styles/slider.css";
import { APP_URL } from "../../../utils/constants";
import fetchWrapper from "../../../helpers/fetch-wrapper";
import CssTextField from "../../../components/StyledTextField";
import { COLORS } from "../../../styles/themes/default/colors";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import alertService from "../../../services/alertService";

const ItemBox = styled(Box)(({ theme }) => ({
  display: "flex",
  justifyContent: "space-between",
  border: "1px solid #dedddc",
  margin: "10px 0px",
  width: "99.9%",
}));

const ItemTypography = styled(Typography)(({ theme }) => ({
  fontSize: "14px",
  margin: 10,
}));

const textfieldStyle = {
  width: "99.9%",
  fontSize: "14px",
  margin: "10px 10px 10px 0px",
  "& .css-9ddj71-MuiInputBase-root-MuiOutlinedInput-root": {
    height: "36px",
  },
};
const ContainerInputStyle = {
  fontSize: "14px",
};
const otherFieldStyle = {
  width: "99.9%",
  margin: "10px 10px 10px 0px",

  "& .css-1qqsdnr-MuiAutocomplete-root .MuiOutlinedInput-root.MuiInputBase-sizeSmall":
    {
      borderRadius: "5px",
      height: "30px",
    },
};
const LabelSpanStyle = { fontSize: "14px", textAlign: "center" };

export default function ContainerSlider({
  containers,
  setContainers,
  status,
  guest,
}) {
  const [types, setTypes] = useState([]);
  const [steamShips, setSteamShips] = useState([]);
  const [steamShip, setSteamShip] = useState("");

  const [containerId, setContainerId] = useState("");
  const [type, setType] = useState("");
  const [billing, setBilling] = useState();
  const [mbl, setMBL] = useState("");
  const [pickup, setPickup] = useState();
  const [amount, setAmount] = useState("");

  useEffect(() => {
    fetchWrapper.get(`${APP_URL}/dnd/container_types/`).then((resp) => {
      setTypes(typeof resp === "string" ? [] : resp);
    });
    fetchWrapper.get(`${APP_URL}/dnd/steamship_lines/`).then((resp) => {
      setSteamShips(typeof resp === "string" ? [] : resp);
    });
  }, []);

  let settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
  };

  const addContainer = () => {
    let errorAmount = "";
    let errorMBL = "";
    let errorContainerId = "";
    let errorInvalidContainerId = "";
    let errorInvalidAmount = "";

    if (containerId !== "") {
      errorInvalidContainerId =
        "You have entered an invalid Container ID number.";
    }

    // if (!containerAmountIsValid(amount) && amount !== "") {
    //   errorInvalidAmount = "Invalid amount";
    // }

    if (amount === "") {
      errorAmount = "Amount is required";
    }
    if (containerId === "") {
      errorContainerId = "Container ID is required";
    }
    if (mbl === "") {
      errorMBL = "MBL is required";
    }

    if (amount !== "" && containerId !== "" && mbl !== "") {
      let container = {
        container_id: containerId,
        container_info: type ? type : undefined,
        steamship_line: steamShip ? steamShip : undefined,
        dnd_duration: billing ? billing : undefined,
        pickup_number: pickup ? pickup : undefined,
        mbl: mbl,
        demurrage_amount: amount,
        editable: false,
      };
      setContainers([...containers, container]);
      setAmount("");
      setPickup("");
      setBilling("");
      setType("");
      setMBL("");
      setSteamShip("");
      setContainerId("");
    } else {
      let errorMessage = errorAmount ? `${errorAmount}\n` : "";
      errorMessage += errorContainerId ? errorContainerId + "\n" : "";
      errorMessage += errorInvalidAmount ? errorInvalidAmount + "\n" : "";
      errorMessage += errorMBL ? errorMBL + "\n" : "";
      errorMessage += errorInvalidContainerId
        ? errorInvalidContainerId + "\n"
        : "";

      alertService.error(1, errorMessage);
    }
  };
  return (
    <Box
      style={guest ? { width: "100%", padding: "0 11px" } : { width: "100%" }}
    >
      <Slider
        style={{ width: "100%" }}
        {...settings}
        arrows={false}
        customPaging={(i) => (
          <div
            style={{
              position: "absolute",
              width: "100%",
              top: "-10px",
              opacity: 0,
            }}
          >
            {i}
          </div>
        )}
        dotsClass="slick-dots line-indicator"
      >
        {(status === "" || status === "SA") && (
          <Box sx={{ width: "100%" }}>
            <CssTextField
              label={<span style={LabelSpanStyle}>Container ID</span>}
              inputProps={{
                style: ContainerInputStyle,
              }}
              size="small"
              value={containerId}
              name="containerId"
              sx={textfieldStyle}
              onChange={(e) => setContainerId(e.target.value)}
            />
            <Autocomplete
              sx={otherFieldStyle}
              value={type ? types.find((item) => item.id === type).name : null}
              onChange={(e, value) =>
                value
                  ? setType(types.find((item) => item.name === value).id)
                  : setType("")
              }
              options={
                types.length > 0
                  ? types.map((item) => {
                      return item.name;
                    })
                  : []
              }
              renderOption={(props, option, state) => (
                <div
                  style={{
                    fontFamily: "Montserrat",
                    backgroundColor: COLORS.white,
                  }}
                  {...props}
                >
                  {option}
                </div>
              )}
              renderInput={(params) => (
                <CssTextField
                  label={<span style={LabelSpanStyle}>Type</span>}
                  {...params}
                  size="small"
                  inputProps={{
                    ...params.inputProps,
                    style: ContainerInputStyle,
                  }}
                />
              )}
            />
            <Autocomplete
              sx={otherFieldStyle}
              value={
                steamShip
                  ? steamShips.find((item) => item.id === steamShip).name
                  : null
              }
              onChange={(e, value) =>
                value
                  ? setSteamShip(
                      steamShips.find((item) => item.name === value).id
                    )
                  : setSteamShip("")
              }
              options={
                steamShips.length > 0
                  ? steamShips.map((item) => {
                      return item.name;
                    })
                  : []
              }
              renderOption={(props, option, state) => (
                <div
                  style={{
                    fontFamily: "Montserrat",
                    backgroundColor: COLORS.white,
                  }}
                  {...props}
                >
                  {option}
                </div>
              )}
              renderInput={(params) => (
                <CssTextField
                  label={<span style={LabelSpanStyle}>Steamship Line</span>}
                  {...params}
                  size="small"
                  inputProps={{
                    ...params.inputProps,
                    style: ContainerInputStyle,
                  }}
                />
              )}
            />
            <CssTextField
              label={<span style={LabelSpanStyle}>MBL</span>}
              inputProps={{
                style: ContainerInputStyle,
              }}
              size="small"
              value={mbl}
              name="mbl"
              sx={textfieldStyle}
              onChange={(e) => {
                setMBL(e.target.value);
              }}
            />
            <CssTextField
              label={<span style={LabelSpanStyle}>Pickup number</span>}
              inputProps={{
                style: ContainerInputStyle,
              }}
              size="small"
              value={pickup}
              name="pickup"
              sx={textfieldStyle}
              onChange={(e) => {
                setPickup(e.target.value);
              }}
            />
            <CssTextField
              label={<span style={LabelSpanStyle}>Billing days</span>}
              inputProps={{
                style: ContainerInputStyle,
              }}
              size="small"
              value={billing}
              name="billing"
              sx={textfieldStyle}
              onChange={(e) => setBilling(e.target.value)}
            />
            <CssTextField
              label={<span style={LabelSpanStyle}>Demurrage Amount</span>}
              inputProps={{
                style: ContainerInputStyle,
              }}
              size="small"
              value={amount}
              name="amount"
              sx={textfieldStyle}
              onChange={(e) => setAmount(e.target.value)}
            />
            <Box
              style={{
                width: "100%",
                display: "flex",
                justifyContent: "center",
              }}
            >
              <IconButton style={{ outline: "none" }} onClick={addContainer}>
                <AddCircleOutlineIcon
                  style={{
                    width: 30,
                    height: 30,
                    color: COLORS.sunrise_orange,
                  }}
                />
              </IconButton>
            </Box>
          </Box>
        )}
        {/* <Box style={{ width: "100%" }}> */}
        {containers.map((slide, index) => (
          <Box id={index} style={{ width: "100%" }}>
            <ItemBox>
              <ItemTypography sx={{ fontWeight: 800 }}>
                Container ID
              </ItemTypography>
              <ItemTypography sx={{ textAlign: "right" }}>
                {" "}
                {slide.container_id}
              </ItemTypography>
            </ItemBox>
            <ItemBox>
              <ItemTypography sx={{ fontWeight: 800 }}>Type</ItemTypography>
              <ItemTypography sx={{ textAlign: "right" }}>
                {guest
                  ? slide.container_info?.name
                  : typeof slide.container_info === "string"
                  ? slide.container_info
                  : types.find(
                      (type) =>
                        type.id === slide.container_info?.id ||
                        type.id === slide.container_info
                    )?.name ?? "_"}
              </ItemTypography>
            </ItemBox>
            <ItemBox>
              <ItemTypography sx={{ fontWeight: 800 }}>
                Steamship Line
              </ItemTypography>
              <ItemTypography sx={{ textAlign: "right" }}>
                {guest
                  ? slide.steamship_line?.name
                  : typeof slide.steamship_line === "string"
                  ? slide.container_info
                  : steamShips.find(
                      (item) =>
                        item.id === slide.steamship_line?.id ||
                        item.id === slide.steamship_line
                    )?.name ?? "_"}
              </ItemTypography>
            </ItemBox>
            <ItemBox>
              <ItemTypography sx={{ fontWeight: 800 }}>MBL </ItemTypography>
              <ItemTypography sx={{ textAlign: "right" }}>
                {slide.mbl ?? "_"}
              </ItemTypography>
            </ItemBox>
            <ItemBox>
              <ItemTypography sx={{ fontWeight: 800 }}>
                Pickup number
              </ItemTypography>
              <ItemTypography sx={{ textAlign: "right" }}>
                {slide.pickup_number ?? "_"}
              </ItemTypography>
            </ItemBox>
            <ItemBox>
              <ItemTypography sx={{ fontWeight: 800 }}>
                Billing days
              </ItemTypography>
              <ItemTypography sx={{ textAlign: "right" }}>
                {slide.dnd_durationu ?? "_"}
              </ItemTypography>
            </ItemBox>
            <ItemBox>
              <ItemTypography sx={{ fontWeight: 800 }}>
                Demurrage Amount
              </ItemTypography>
              <ItemTypography sx={{ textAlign: "right" }}>
                {slide.demurrage_amount ?? "_"}
              </ItemTypography>
            </ItemBox>
          </Box>
        ))}
        {/* </Box> */}
      </Slider>
    </Box>
  );
}
