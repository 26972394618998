import React, { useState, useEffect } from "react";
import { useParams, useHistory } from "react-router-dom";

import fetchWrapper from "../../helpers/fetch-wrapper";
import { APP_URL, formatDate } from "../../utils/constants";

import { saveAs } from "file-saver";

import SubmitDemurrageModal from "./components/SubmitDemurrageModal";
import DemurrageRequestLocation from "./components/Location";
import DemurrageRequestContainer from "./components/ContainersInput";
import DemurrageRequestDoc from "./components/Documents";
import DeleteModal from "../../components/Modal";
import AlarmIcon from "../../assets/img/Alarm_fill.png";

import GeneralInformation from "./components/GeneralInformationInput";
import { Button } from "../../components/Cdl/Button";

import EyeIcon from "../../assets/img/DemurrageDetailAndRequest/eyeIcon.svg";
import DownloadIcon from "../../assets/img/DemurrageDetailAndRequest/Download.svg";

import DueDateIcon from "../../assets/img/DemurrageDetailAndRequest/Due_date_icon.svg";
import RejectedIcon from "../../assets/img/DemurrageDetailAndRequest/rejected_icon.svg";

import {
  Typography,
  styled,
  Box,
  createTheme,
  useMediaQuery,
} from "@mui/material";
import moment from "moment";

import useStore from "../../store/store";

import { COLORS } from "../../styles/themes/default/colors";
import alertService from "../../services/alertService";

import DemurrageRejectModal from "./components/RejectModal";

import DemurrageComment from "./components/Comments";
import ContainerTable from "./components/ContainersTable";
import Status from "./components/StatusBar";
import GeneralInformationTable from "./components/GeneralInformationTable";

import "./styles/demurrage_details_styles.scss";

const theme1 = createTheme({
  breakpoints: {
    values: {
      xs: 0,
      smallMobile: 321,
      mobile: 376,
      middleMobile: 407,
      sm: 600,
      md: 900,
      lg: 1200,
      xl: 1536,
      sl: 1400,
    },
  },
});

const mdDown = theme1.breakpoints.down("md");

const Container = styled(Box)(({ theme }) => ({
  width: "100%",
  display: "flex",
  flexDirection: "column",
  backgroundColor: COLORS.ligth_grey,
  padding: window.innerWidth > 1920 ? "5px" : "24px",
  paddingTop: "16px",

  [mdDown]: {
    padding: 0,
  },
}));
const ContainerTitle = styled(Typography)(({ theme }) => ({
  textAlign: "left",
  fontWeight: "600",
  fontSize: "24px",
  display: "flex",
  "@media only screen and (max-width: 1400px) and (min-width: 1280px)": {
    fontSize: "18px",
  },
}));
const Header = styled(Box)(({ theme }) => ({
  width: "100%",
  height: "92px",
  "@media only screen and (max-width: 1400px) and (min-width: 1280px)": {
    height: "65px",
  },
  display: "flex",
  flex: "0",
  marginBottom: "16px",
}));

const LocationContainer = styled(Box)(({ theme }) => ({
  borderRadius: "8px",
  width: "27%",
  marginLeft: "16px",
  backgroundColor: COLORS.off_white,
  padding: "16px",
  [mdDown]: {
    width: "100%",
  },
}));
const StatusContainer = styled(Box)(({ theme }) => ({
  width: "100%",
  display: "flex",
  backgroundColor: "blue",

  [mdDown]: {
    width: "100%",
    marginTop: "1rem",
    marginBottom: "1rem",
    justifyContent: "center",
  },
}));
const MainContainer = styled(Box)(({ theme }) => ({
  width: "100%",
  display: "flex",
  flexDirection: "column",
  flex: 1,
  marginTop: "16px",
}));

const FirstContainer = styled(Box)(({ theme }) => ({
  width: "100%",
  backgroundColor: COLORS.ligth_grey,
  display: "flex",
  flex: 1,
}));
const ContainerDiv = styled(Box)(({ theme }) => ({
  width: "73%",
}));

const SecondContainer = styled(Box)(({ theme }) => ({
  width: "100%",
  display: "flex",
  marginTop: "16px",
  flex: 0,

  [mdDown]: {
    flexDirection: "column",
  },
}));
const ContainerInfo = styled(Box)(({ theme }) => ({
  width: "100%",
  height: "100%",
  borderRadius: "8px",
  backgroundColor: COLORS.off_white,
  padding: "16px",
  paddingBottom: 0,
  flex: 0,

  [mdDown]: {
    width: "100%",
  },
}));
const CommentContainer = styled(Box)(({ theme }) => ({
  borderRadius: "8px",
  width: "27%",
  padding: "16px",
  marginLeft: "16px",
  backgroundColor: COLORS.off_white,
}));
const DocumentContainer = styled(Box)(({ theme }) => ({
  width: "73%",
  padding: "16px 16px 0 16px",
  borderRadius: "8px",
  backgroundColor: COLORS.off_white,
  [mdDown]: {
    width: "100%",
  },
}));

const GeneralInformationContainer = styled(Box)(({ theme }) => ({
  borderRadius: "8px",
  padding: "16px",
  flex: 0,
  backgroundColor: COLORS.off_white,
  [mdDown]: {
    width: "100%",
  },
}));
export default function DemurrageDetails() {
  const param = useParams();
  const history = useHistory();
  const demurrageId = param.id;
  const currentUser = useStore((state) => state.currentUser);

  const smallLaptop = useMediaQuery(theme1.breakpoints.down("sl"));

  const [totalAmount, setTotalAmount] = useState(0);

  // data from BE
  const [terminals, setTerminals] = useState([]);
  const [shippers, setShippers] = useState([]);
  const [causes, setCauses] = useState([]);
  const [beData, setBeData] = useState({});

  const [containers, setContainers] = useState([]);
  const [documents, setDocuments] = useState([]);
  const [deletedDoc, setDeletedDoc] = useState([]);
  const [isActiveCompany, setIsActiveCompany] = useState(false);

  const [documentDate, setDocumentDate] = useState("");

  // current values
  const [status, setStatus] = useState("");
  const [comments, setComments] = useState([]);
  const [shipper, setShipper] = useState("");
  const [carrier, setCarrier] = useState("");
  const [cause, setCause] = useState("");
  const [pickup, setPickup] = useState("");
  const [approvalAuth, setApprovalAuth] = useState("");
  const [location, setLocation] = useState({});
  const [customDemurrageId, setCustomDemurrageId] = useState("");
  const [dueDate, setDueDate] = useState({});

  const [showPage, setShowPage] = useState(false);
  const [newDataFromBE, setnewDataFromBE] = useState(false);

  const [paidDate, setPaidDate] = useState("");

  // const [isLoadingDownLoad, setIsLoadingDownload] = useState(false);
  // const [isLoadingPreview, setIsLoadingPreview] = useState(false);

  //modals
  const [openRejectModal, setOpenRejectModal] = useState(false);
  const [openDeleteModal, setOpenDeleteModal] = useState(false);
  const [openSubmitModal, setOpenSubmitModal] = useState(false);

  const [rejectComment, setRejectComment] = useState("");

  const [receiptError, setReceiptError] = useState({
    button: false,
    title: false,
  });
  const [invoiceError, setInvoiceError] = useState({
    button: false,
    title: false,
  });

  const setNavbarLabel = useStore((state) => state.setNavbarLabel);

  useEffect(() => {
    setNavbarLabel({
      label: "Demurrage details",
      back_link: "/demurrages/list",
    });
  });

  useEffect(() => {
    async function getData() {
      await fetchWrapper.get(`${APP_URL}/dnd/causes/`).then((resp) => {
        let cause = [];
        if (typeof resp !== "string") {
          resp.forEach((item) => {
            cause = [...cause, item.cause];
          });
          setCauses(resp);
        } else {
          setCauses([]);
        }
      });
      await fetchWrapper.get(`${APP_URL}/dnd/terminals/`).then((resp) => {
        if (typeof resp !== "string") {
          setTerminals(resp);
        } else {
          setTerminals([]);
        }
      });
      await fetchWrapper.get(`${APP_URL}/shipper/`).then((resp) => {
        if (typeof resp !== "string") {
          const shippersResp = resp.filter((item) => item.is_active === true);
          setShippers(shippersResp);
        } else {
          setShippers([]);
        }
      });
    }

    if (
      terminals.length === 0 &&
      shippers.length === 0 &&
      causes.length === 0
    ) {
      getData();
    }
  }, []);

  useEffect(() => {
    fetchWrapper.get(`${APP_URL}/dnd/${demurrageId}/`).then((resp) => {
      resp.containers.length > 0 &&
        setBeData({
          shipper: resp.shipper,
          comment: resp.comment,
          containers: resp.containers,
          carrier: resp.carrier,
          cause: resp.dnd_id?.cause,
          pickup: resp.date_of_pickup,
          documents: resp.documents,
          location: resp.terminal.name,
        });
      setPaidDate(resp.paid_date);
      setIsActiveCompany(resp.shipper.is_active);
      setCustomDemurrageId(resp.dnd_id);
      setContainers(resp.containers);
      setComments(resp.comments);
      setApprovalAuth(resp.shipper?.authority_approval);
      setShipper(resp.shipper?.business_name);
      setCarrier(resp.carrier);
      setCause(resp.cause_of_dnd?.cause);
      setPickup(resp.date_of_pickup);
      setStatus(resp.status);
      setDueDate(resp.due_date);
      setDocuments(resp.documents);
      setLocation(resp.terminal);
      setShowPage(true);
    });
  }, [newDataFromBE]);
  useEffect(() => {
    let amount = 0.0;
    containers.forEach((item) => {
      amount = amount + parseFloat(item.demurrage_amount);
      // + parseFloat(item.detention_amount);
    });
    setTotalAmount(amount.toFixed(2));
  }, [containers]);

  const handleDownload = async () => {
    // setIsLoadingDownload(true);
    const data = { id: [param.id] };
    await fetchWrapper
      .getFile(`${APP_URL}/dnd/demurrage_pdf/`, data)
      .then((resp) => {
        saveAs(resp, `Demurrage_${customDemurrageId}`);
        // setIsLoadingDownload(false);
      })
      .catch((resp) => {
        // setIsLoadingDownload(false);
      });
  };

  const handlePreview = async () => {
    // setIsLoadingPreview(true);
    const data = { id: [param.id] };
    await fetchWrapper
      .getFile(`${APP_URL}/dnd/demurrage_pdf/`, data)
      .then((resp) => {
        const file = new Blob([resp], { type: "application/pdf" });
        const fileURL = URL.createObjectURL(file);
        const pdfWindow = window.open();
        pdfWindow.location.href = fileURL;
        // setIsLoadingPreview(false);
      })
      .catch((resp) => {
        // setIsLoadingPreview(false);
      });
  };

  function returnError() {
    let errorShipper = "";
    let errorLocation = "";
    let errorContainers = "";
    if (shipper === "") {
      errorShipper = "Shipper is required";
    }
    if (location === "") {
      errorLocation = "Location is required";
    }
    if (containers.length === 0) {
      errorContainers = "Containers needs to be added";
    }
    if (shipper !== "" && !isActiveCompany) {
      errorShipper =
        "The customer is inactive. Activate the company so you can create its demurrage requests.";
    }
    return `${errorShipper + "\n" + errorLocation + "\n" + errorContainers}`;
  }

  const submit = () => {
    const isValid =
      shipper &&
      location?.id &&
      containers.length > 0 &&
      isActiveCompany === true;

    if (isValid) {
      setOpenSubmitModal(true);
    } else {
      alertService.error(1, returnError());
    }
  };

  async function save() {
    const isValid = shipper && location?.id && containers.length > 0;

    if (isValid) {
      const data = {
        id: demurrageId,
        shipper:
          typeof shipper !== "string"
            ? shipper
            : shippers.find((item) => item.business_name === shipper).id,
        carrier: carrier,
        cause_of_demurrage:
          typeof cause !== "string"
            ? cause
            : causes.find((item) => item.cause === cause).id,
        date_of_pickup: moment(pickup).format("YYYY-MM-DD"),
        terminal: location?.id,
        containers,
      };

      fetchWrapper.patch(`${APP_URL}/dnd/`, data).then((resp) => {
        if (documents.length > 0) {
          documents.forEach((item) => {
            if (item.document) {
              const newFormData = new FormData();
              newFormData.append("document", item.document);
              newFormData.append("document_type", item.document_type);
              newFormData.append("dnd", resp.id);
              fetchWrapper.post(`${APP_URL}/dnd/add_document/`, newFormData);
            }
          });
        }
        if (deletedDoc.length > 0) {
          deletedDoc.forEach((item) => {
            fetchWrapper.delete(`${APP_URL}/dnd/documents/${item.id}/`);
          });
        }
        history.push("/demurrage/list");
      });
    } else {
      alertService.error(1, returnError());
    }
  }

  const submitDemurrage = () => {
    const data = {
      id: demurrageId,
      shipper:
        typeof shipper !== "string"
          ? shipper
          : shippers.find((item) => item.business_name === shipper).id,
      carrier: carrier,
      cause_of_dnd:
        typeof cause !== "string"
          ? cause
          : causes.find((item) => item.cause === cause).id,
      date_of_pickup: moment(pickup).format("YYYY-MM-DD"),
      terminal: location.id,
      containers,
    };
    fetchWrapper.patch(`${APP_URL}/dnd/`, data).then((resp) => {
      if (deletedDoc.length > 0) {
        deletedDoc.forEach((item) => {
          fetchWrapper.delete(`${APP_URL}/dnd/documents/${item.id}/`);
        });
      }
      fetchWrapper.post(`${APP_URL}/dnd/submit/${resp.id}/`).then((resp) => {
        setOpenSubmitModal(false);
        setnewDataFromBE(!newDataFromBE);
      });
    });
  };

  const changeStatus = (value) => {
    let data;
    if (value === "PA") {
      setReceiptError({ button: false, title: false });
      data = {
        status: value,
        paid_date: moment(documentDate).format("YYYY-MM-DD"),
      };
      setDocumentDate("");
    } else if (value === "RE") {
      data = {
        status: value,
        rejection_comment: rejectComment,
      };
    } else if (value === "IN") {
      setInvoiceError({ button: false, title: false });
      setDocumentDate("");

      data = {
        status: value,
        invoice_date: moment(documentDate).format("YYYY-MM-DD"),
      };
    } else {
      data = {
        status: value,
      };
    }

    fetchWrapper
      .post(`${APP_URL}/dnd/status_change/${demurrageId}/`, data)
      .then(() => {
        setDocumentDate("");
        setnewDataFromBE(!newDataFromBE);
      })
      .catch((error) => {
        alertService.error(error.response.status);
      });
  };

  const handleFileAdd = (name, event) => {
    const files = event.target.files;

    const newFormData = new FormData();
    newFormData.append("document", files[0]);
    newFormData.append("document_type", name);
    newFormData.append("dnd", demurrageId);
    fetchWrapper
      .post(`${APP_URL}/dnd/add_document/`, newFormData)
      .then(() => {
        setnewDataFromBE(!newDataFromBE);
      })
      .catch((error) => {
        alertService.error(error.response.status, "File is too big");
      });
  };

  const returnButtonText = (value) => {
    if (value === "SA") {
      return "Submit";
    }
    if (value === "OP") {
      return "Approve";
    }
    if (value === "AP") {
      return "Clear";
    }
    if (value === "PA") {
      return "Invoice";
    }
    if (value === "IN") {
      return "Paid back";
    }
  };
  const buttonAction = () => {
    if (status === "SA") {
      submit();
    } else if (status === "OP") {
      changeStatus("AP");
    } else if (status === "AP") {
      let have_receipt = false;
      let errors = "";
      let button_error = false;
      let title_error = false;
      documents.length > 0 &&
        documents.forEach((item) => {
          if (item.document_type === "RE") {
            have_receipt = true;
          }
        });

      if (!have_receipt) {
        errors += "Attach receipt to move demurrage to status cleared\n";
        button_error = true;
      }
      if (documentDate === "") {
        errors += "Clearance date is required";
        title_error = true;
      }
      if (errors) {
        alertService.error(1, errors);
        setReceiptError({ button: button_error, title: title_error });
        return;
      } else {
        changeStatus("PA");
      }
    } else if (status === "PA") {
      let have_invoice = false;
      let button_error = false;
      let title_error = false;
      let errors = "";

      documents.length > 0 &&
        documents.forEach((item) => {
          if (item.document_type === "IN") {
            have_invoice = true;
          }
        });

      if (!have_invoice) {
        errors += "Attach invoice to move Demurrage to status Invoiced\n";
        button_error = true;
      }
      if (documentDate === "") {
        title_error = true;
        errors += "Invoiced date is required";
      }
      if (errors) {
        alertService.error(1, errors);
        setInvoiceError({ button: button_error, title: title_error });

        return;
      } else {
        changeStatus("IN");
      }
    } else if (status === "IN") {
      changeStatus("CO");
    }
  };
  const Buttons = () => {
    return (
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "space-around",
        }}
      >
        {((currentUser.user.role.startsWith("S") &&
          approvalAuth === "CUS" &&
          status === "OP") ||
          (currentUser.user.role.startsWith("C") &&
            approvalAuth !== "CUS" &&
            status === "OP") ||
          (currentUser.user.role.startsWith("C") && status !== "OP")) && (
          <>
            <Button
              text={returnButtonText(status)}
              type={"primary"}
              onClick={() => {
                buttonAction();
              }}
              css={{
                width: smallLaptop ? "100px" : "146px",
                height: smallLaptop ? "30px" : "42px",
              }}
            />
            {status === "SA" && (
              <Button
                text="Save as draft"
                type={"secondary"}
                onClick={save}
                css={{
                  width: smallLaptop ? "100px" : "146px",
                  height: smallLaptop ? "30px" : "42px",
                }}
              />
            )}
            {status === "OP" && (
              <Button
                text="Reject"
                type={"secondary"}
                onClick={() => {
                  setOpenRejectModal(true);
                }}
                css={{
                  width: smallLaptop ? "100px" : "146px",
                  height: smallLaptop ? "30px" : "42px",
                }}
              />
            )}
          </>
        )}
      </div>
    );
  };
  return (
    showPage && (
      <Container>
        <Header>
          <StatusContainer
            style={{
              width: status !== "RE" || status !== "CO" ? "100%" : "90%",
            }}
          >
            <Status status={status} guest={false} />
          </StatusContainer>
          {!(status === "RE" || status === "CO") && <Buttons />}
        </Header>
        <GeneralInformationContainer>
          <div
            style={{
              width: "100%",
              display: "flex",
              justifyContent: "space-between",
            }}
          >
            <ContainerTitle>Demurrage {customDemurrageId}</ContainerTitle>
            {status !== "SA" && (
              <div style={{ display: "flex", alignItems: "center" }}>
                <button
                  onClick={handlePreview}
                  style={{
                    fontSize: smallLaptop ? "10px" : "14px",
                    border: "none",
                    backgroundColor: "transparent",
                    color: COLORS.activity,
                    fontWeight: "600",
                    outline: "none",
                  }}
                >
                  <img
                    src={EyeIcon}
                    alt=""
                    style={{
                      marginRight: "5px",
                      width: smallLaptop ? "12px" : "16px",
                    }}
                  />
                  View PDF
                </button>
                <button
                  onClick={handleDownload}
                  style={{
                    fontSize: smallLaptop ? "10px" : "14px",
                    border: "none",
                    backgroundColor: "transparent",
                    color: COLORS.activity,
                    fontWeight: "600",
                    outline: "none",
                    marginLeft: "40px",
                  }}
                >
                  <img
                    src={DownloadIcon}
                    alt=""
                    style={{
                      marginRight: "5px",
                      width: smallLaptop ? "12px" : "16px",
                    }}
                  />
                  Download PDF
                </button>
              </div>
            )}
          </div>

          {status === "SA" ? (
            <GeneralInformation
              shipper={shipper}
              setShipper={setShipper}
              setCarrier={setCarrier}
              carrier={carrier}
              cause={cause}
              setCause={setCause}
              causes={causes}
              shippers={shippers}
              setLocation={setLocation}
              terminals={terminals}
              status={""}
              location={location}
              guest={false}
            />
          ) : (
            <GeneralInformationTable
              shipper={shipper}
              carrier={carrier}
              cause={cause}
              location={location}
            />
          )}
        </GeneralInformationContainer>
        <MainContainer>
          <FirstContainer>
            <ContainerDiv>
              {status === "IN" && dueDate && (
                <div className="invoice_message_container">
                  <div className="due_date_box">
                    <img
                      src={DueDateIcon}
                      alt=""
                      style={{ width: "16px", height: "16px" }}
                    />
                    <p className="invoice_header">Due Date:</p>
                    <p className="invoice_data">{formatDate(dueDate.date)}</p>
                  </div>
                  <div className="days_left_box">
                    <p className="invoice_header">Days remaining:</p>
                    <p className="invoice_data">{dueDate.days_left}</p>
                  </div>
                </div>
              )}
              {status === "RE" && (
                <>
                  <div className="rejected_box">
                    <img src={RejectedIcon} alt="" className="rejected_icon" />
                    <p className="rejected_box_header">Demurrage rejected</p>
                  </div>
                  <div className="rejected_reason_box">
                    <p className="reason_header">Reason :</p>
                    <p className="reason">
                      {rejectComment
                        ? rejectComment
                        : "There is no available description to be shown."}
                    </p>
                  </div>
                </>
              )}
              <ContainerInfo>
                <ContainerTitle>Container info</ContainerTitle>
                {status === "SA" ? (
                  <>
                    <Typography
                      sx={{
                        margin: "8px 0px",
                        fontSize: smallLaptop ? "11px" : "16px",
                      }}
                    >
                      Please enter a container ID to check if there is any
                      available information about the container
                    </Typography>
                    <DemurrageRequestContainer
                      containers={containers}
                      setContainers={setContainers}
                      status={""}
                      totalAmount={totalAmount}
                      terminal={location}
                    />
                  </>
                ) : (
                  <ContainerTable
                    containers={containers}
                    totalAmount={totalAmount}
                  />
                )}
              </ContainerInfo>
            </ContainerDiv>
            <LocationContainer
              sx={{
                minHeight:
                  status === "SA" ? "390px" : smallLaptop ? "350px" : "390px",
              }}
            >
              <DemurrageRequestLocation
                setLocation={setLocation}
                terminals={terminals}
                terminal={beData.location}
                status={status}
                location={location}
                guest={false}
                // isSent={isSent}
              />
            </LocationContainer>
          </FirstContainer>
          <SecondContainer>
            <DocumentContainer>
              <ContainerTitle>Document</ContainerTitle>
              <Typography
                sx={{
                  margin: "8px 0px",
                  fontSize: smallLaptop ? "0.685rem" : "1rem",
                }}
              >
                Attach receipt or invoice
              </Typography>
              <DemurrageRequestDoc
                handleFileAdd={handleFileAdd}
                documents={documents}
                setDocuments={setDocuments}
                isDetailPage={true}
                status={status}
                documentDate={documentDate}
                setDocumentDate={setDocumentDate}
                invoiceError={invoiceError}
                receiptError={receiptError}
                paidDate={paidDate}
              />
            </DocumentContainer>
            <CommentContainer>
              <ContainerTitle>Note</ContainerTitle>

              <DemurrageComment
                status={status}
                comments={comments}
                demurrageId={demurrageId}
                setComments={setComments}
              />
            </CommentContainer>
          </SecondContainer>
        </MainContainer>
        <DemurrageRejectModal
          openRejectModal={openRejectModal}
          setOpenRejectModal={setOpenRejectModal}
          setRejectComment={setRejectComment}
          rejectComment={rejectComment}
          changeStatus={changeStatus}
        />
        <DeleteModal
          openModal={openDeleteModal}
          setOpenModal={setOpenDeleteModal}
          title={"Delete demurrage?"}
          content={[
            `This action will permanently delete the demurrage request [${customDemurrageId}].`,
            "You can't undo this action!",
          ]}
          icon={AlarmIcon}
          buttons={[
            {
              label: "Delete",
              style: {
                background: COLORS.sunrise_orange,
                color: COLORS.white,
                fontWeight: 600,
              },
              click: () => {
                fetchWrapper
                  .delete(`${APP_URL}/dnd/`, [param.id])
                  .then((resp) => {
                    history.push("/dnd/list");
                  });
              },
            },
            {
              label: "Cancel",
              style: {
                background: COLORS.white,
                color: COLORS.black,
                fontWeight: 600,
              },
              click: () => setOpenDeleteModal(false),
            },
          ]}
        />

        <SubmitDemurrageModal
          openSubmitModal={openSubmitModal}
          setOpenSubmitModal={setOpenSubmitModal}
          submitDemurrage={submitDemurrage}
        />
      </Container>
    )
  );
}
