import React from "react";
import { COLORS } from "../../../../../styles/themes/default/colors";
import { useMediaQuery, createTheme } from "@mui/material";

const VesselArrivingStatistics = ({ statistics }) => {
  const theme1 = createTheme({
    breakpoints: {
      values: {
        sl: 1400,
      },
    },
  });

  const smallLaptop = useMediaQuery(theme1.breakpoints.down("sl"));

  const thClass = {
    fontWeight: "600",
    fontSize: smallLaptop ? "11px" : "14px",
    color: COLORS.dark_grey,
    width: "33%",
  };
  const tdClass = {
    fontWeight: "600",
    fontSize: "14px",
    fontSize: smallLaptop ? "11px" : "14px",
    color: COLORS.off_white,
    width: "33%",
  };
  const mockData = [
    {
      title: "Long Beach Terminal, LA",
      number: 24,
      content: [
        { name: "Maersk", number: 15, free_days: "3", amount: "$128" },
        { name: "MSC", number: 9, free_days: "3", amount: "$128" },
      ],
    },
    {
      title: "Hustone, TE",
      number: 24,
      content: [
        { name: "Maersk", number: 15, free_days: "3", amount: "$128" },
        { name: "MSC", number: 9, free_days: "3", amount: "$200" },
        { name: "Atlantis", number: 6, free_days: "3", amount: "$220" },
      ],
    },
    {
      title: "Savannah, GE",
      number: 10,
      content: [{ name: "MSC", number: 10, free_days: "3", amount: "$135" }],
    },
    // {
    //   title: "New Jersey, NY",
    //   number: 10,
    //   content: [{ name: "Maersk", number: 10, free_days: 5, amount: "$250" }],
    // },
  ];
  return (
    <div
      className="template_container"
      style={{
        padding: "16px",
        display: "flex",
        height: "100%",
        flexDirection: "column",

        overflowY: "auto",
      }}
    >
      <p className="container_statistics_title">Planning Ahead - Demurrage </p>
      <div style={{ flexGrow: 1, height: 0 }}>
        {statistics?.planing_ahead &&
          Object.keys(statistics.planing_ahead).map((item, index) => {
            return (
              <>
                <div
                  style={{ display: "flex", marginBottom: "8px" }}
                  key={index}
                >
                  <p
                    style={{
                      margin: 0,
                      fontSize: smallLaptop ? "11px" : "14px",
                      fontWeight: "600",
                      color: COLORS.off_white,
                      marginRight: "8px",
                    }}
                  >
                    {item}
                  </p>
                  <p
                    style={{
                      margin: 0,
                      fontSize: smallLaptop ? "11px" : "14px",
                      color: COLORS.dark_grey,
                    }}
                  >
                    ({statistics.planing_ahead[item].count})
                  </p>
                </div>
                <div
                  style={{
                    backgroundColor: "#313131",
                    padding: "16px",
                    width: "100%",
                    borderRadius: "8px",
                    marginBottom: "16px",
                  }}
                >
                  <table
                    style={{
                      backgroundColor: "#313131",
                      width: "100%",
                    }}
                  >
                    <thead>
                      <th style={thClass}>SSL</th>
                      <th style={{ ...thClass, textAlign: "center" }}>
                        Free days
                      </th>
                      <th style={{ ...thClass, textAlign: "right" }}>
                        1st billing day
                      </th>
                    </thead>
                    <tbody>
                      {statistics.planing_ahead[item].data &&
                        Object.keys(statistics.planing_ahead[item].data).map(
                          (row, index2) => {
                            let splited = row.split("|||");

                            return (
                              <tr key={index2}>
                                <td style={{ ...tdClass, display: "flex" }}>
                                  <p
                                    style={{
                                      marginBottom: "8px",
                                      display: "inline-block",
                                      whiteSpace: "nowrap",
                                    }}
                                  >
                                    {splited.at(0)}
                                  </p>
                                  <p
                                    style={{
                                      marginBottom: "8px",
                                      fontWeight: "400",
                                      color: COLORS.dark_grey,
                                      marginLeft: "8px",
                                    }}
                                  >
                                    ({statistics.planing_ahead[item].data[row]})
                                  </p>
                                </td>
                                <td style={{ ...thClass, textAlign: "center" }}>
                                  {splited.at(1)}
                                </td>
                                <td style={{ ...tdClass, textAlign: "right" }}>
                                  $ {splited.at(2)}
                                </td>
                              </tr>
                            );
                          }
                        )}
                    </tbody>
                  </table>
                </div>
              </>
            );
          })}
      </div>
    </div>
  );
};

export default VesselArrivingStatistics;
