import React from "react";
import ColumnBarStatistics from "../ColumnBarStatistics";

const VesselDepartingStatistics = ({ statistics }) => {
  return (
    <div style={{ height: "100%", display: "flex", flexDirection: "column" }}>
      <div className="template_container" style={{ paddingRight: 0 }}>
        <p className="container_statistics_title">Vessel order departure</p>
        {/* {statistics?.vessel_order_departure &&
          statistics.vessel_order_departure.slice(0, 3).map((item, index) => {
            return (
              <div className="flex_box_space_between" key={index}>
                <p className="white_text">{item.at(1)}</p>
                <p className="dark_grey_text">{item.at(2)}</p>
              </div>
            );
          })} */}
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            height: "160px",
            overflowY: "auto",
            paddingRight: "16px",
            gap: "1rem",
          }}
        >
          {statistics?.departing_vessels &&
            statistics?.departing_vessels.length > 0 &&
            statistics?.departing_vessels
              .filter((item) => item.at(1) !== "Unknown vessel")
              .map((item, index) => {
                return (
                  <div className="flex_box_space_between" key={index}>
                    <p className="white_text">{item[0]}</p>
                    <p className="dark_grey_text" style={{ margin: 0 }}>{item[1]}</p>
                  </div>
                );
              })}
        </div>
      </div>
      <div
        className="template_container"
        style={{
          // height: "calc(100% - 245px)",
          flexGrow: 1,
          marginTop: "16px",
        }}
      >
        <p className="container_statistics_title" style={{ marginBottom: 0 }}>
          Top 3 terminals by the number of vessels
        </p>
        {/* <div style={{ height: "90%" }}> */}
        <ColumnBarStatistics
          colors={["#B2D634", "#FCC251", "#FC8451"]}
          statistics={statistics}
        />
        {/* </div> */}
      </div>
    </div>
  );
};

export default VesselDepartingStatistics;
