import { React, useState } from "react";
import { Link } from "react-router-dom";

import "./styles/loginAndRegister.scss";
import { useForm, Controller } from "react-hook-form";

import accountService from "../../services/accountService";
import alertService from "../../services/alertService";

import { Input } from "../../components/Cdl/Input";
import { Button } from "../../components/Cdl/Button";
import AutoComplete from "../../components/Cdl/AutoComplete";
import { Checkbox } from "@mui/material";
import InputMask from "react-input-mask";

import { useMediaQuery, useTheme } from "@mui/material";

import ErrorImg from "../../assets/img/Error.svg";
import { industryList } from "../../utils/constants";

const Register = ({ history }) => {
  const [industry, setIndustry] = useState("");

  const {
    register,
    handleSubmit,
    control,
    formState: { errors },
  } = useForm();

  async function onSubmit(data) {
    try {
      const registrationData = {
        contact: {
          first_name: data.first_name,
          last_name: data.last_name,
          email: data.email,
          phone_number: data.phone_number,
          acceptTerms: data.acceptTerms,
        },
        company: {
          business_email: data.business_email,
          business_phone: data.business_phone,
          business_name: data.business_name,
          industry: industry,
        },
      };
      await accountService.register(registrationData);
      alertService.success(
        "Registration Successfull\nPlease check your email for further instructions. Thank you!"
      );
      history.push("/account/login");
    } catch (error) {
      alertService.error(error.response.status);
    }
  }

  const theme = useTheme();
  const breakPoint = useMediaQuery(theme.breakpoints.up("600"));

  const inputStyle = {
    width: "90%",
    backgroundColor: "transparent !important",
    color: "#ffffff",
    marginTop: "10px",
  };

  const inputStyleError = {
    width: "90%",
    backgroundColor: "transparent !important",
    border: "1px solid #DA5151",
    color: "#ffffff",
    marginTop: "10px",
  };

  return (
    <div className="loginDiv" style={{ marginTop: breakPoint ? 0 : "24px" }}>
      <p className="p-24-b-white">Create an administrator</p>
      <p className="registerText">
        This user will be a point of contact for your company and can add other
        company users.
      </p>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Input
          placeholder="First name *"
          css={inputStyle}
          register={register("first_name", {
            required: true,
          })}
          message={
            errors.first_name?.type === "required"
              ? "First name is required"
              : ""
          }
          messageType={errors.first_name?.type === "required" ? "error" : ""}
        />

        <Input
          placeholder="Last name *"
          css={inputStyle}
          register={register("last_name", { required: true })}
          message={
            errors.last_name?.type === "required" ? "Last name is required" : ""
          }
          messageType={errors.last_name?.type === "required" ? "error" : ""}
        />

        <Input
          placeholder="E-mail *"
          css={inputStyle}
          register={register("email", {
            required: true,
            pattern: /^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+.[a-zA-Z0-9-.]+$/i,
          })}
          message={
            errors.email?.type === "required"
              ? "E-mail is required"
              : errors.email?.type === "pattern"
              ? "Invalid email"
              : ""
          }
          messageType={
            errors.email?.type === "required" ||
            errors.email?.type === "pattern"
              ? "error"
              : ""
          }
        />

        <Controller
          control={control}
          name="phone_number"
          rules={{ required: true, pattern: /^\+1 \(\d{3}\) \d{3}-\d{4}$/i }}
          render={({ field }) => (
            <InputMask
              {...field}
              mask="+1 (999) 999-9999"
              placeholder="Phone number *"
            >
              <Input css={errors.phone_number ? inputStyleError : inputStyle} />
            </InputMask>
          )}
        />
        {(errors.phone_number?.type === "required" ||
          errors.phone_number?.type === "pattern") && (
          <div className="inputErrorDiv">
            <img src={ErrorImg} className="inputErrorIcon" />
            <p className="passwordError">Phone number is required</p>
          </div>
        )}

        <Input
          placeholder="Company name *"
          css={inputStyle}
          register={register("business_name", { required: true })}
          message={
            errors.business_name?.type === "required"
              ? "Company name is required"
              : ""
          }
          messageType={errors.business_name?.type === "required" ? "error" : ""}
        />

        <AutoComplete
          placeholder="Industry"
          options={industryList}
          search={industry}
          setSearch={setIndustry}
          css={inputStyle}
          cssDiv={{ width: "90%", marginTop: "8px", marginLeft: "5%" }}
        />

        <Input
          placeholder="Company e-mail"
          css={inputStyle}
          register={register("business_email")}
        />

        <Controller
          control={control}
          name="business_phone"
          render={({ field }) => (
            <InputMask
              {...field}
              mask="+1 (999) 999-9999"
              placeholder="Company phone"
            >
              <Input css={inputStyle} />
            </InputMask>
          )}
        />

        <div
          className={
            errors.acceptTerms?.type === "required"
              ? "checkboxDivError"
              : "checkboxDiv"
          }
        >
          <Controller
            name="acceptTerms"
            control={control}
            rules={{ required: true }}
            render={({ field }) => <Checkbox {...field} />}
          />

          <div className="p-14">
            I agree to the{" "}
            <a
              href="https://www.cdl1000.com/terms-and-conditions"
              target="_blank"
              rel="noopener noreferrer"
              className="p-14-b-blue"
            >
              Terms & Conditions
            </a>{" "}
            and{" "}
            <a
              href="https://www.cdl1000.com/privacy-policy"
              target="_blank"
              rel="noopener noreferrer"
              className="p-14-b-blue"
            >
              Privacy Policy.
            </a>{" "}
          </div>
        </div>

        <Button
          text="Register"
          type="primary"
          css={{ width: "90%", margin: "20px 0px" }}
        />

        <p className="p-14-b-white">
          Already have an account?{" "}
          <Link to="/account/login" className="p-14-b-blue">
            Login
          </Link>{" "}
          now.
        </p>
      </form>
    </div>
  );
};

export default Register;
