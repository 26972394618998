import React from "react";
import "../styles/calendar.scss";
import { COLORS } from "../../../styles/themes/default/colors";
const ColorCalendar = (props) => {
  const startDate = new Date(props.tariffsDates[0].startDate);
  const currentDayOfWeek = startDate.getDay();
  const daysToSubtract = currentDayOfWeek === 0 ? 0 : currentDayOfWeek;
  const firstSundayDate = new Date(startDate);

  firstSundayDate.setDate(startDate.getDate() - daysToSubtract);
  let weeks = [];

  for (let i = 0; i < 35; i++) {
    const currentDate = new Date(firstSundayDate);
    currentDate.setDate(firstSundayDate.getDate() + i);

    const weekNumber = Math.floor(i / 7);

    if (!weeks[weekNumber]) {
      weeks[weekNumber] = [];
    }

    weeks[weekNumber].push(currentDate);
  }
  const firstDateMonthName = firstSundayDate.toLocaleString("default", {
    month: "long",
  });
  const lastDateMonthName = weeks
    .at(-1)
    .at(-1)
    .toLocaleString("default", { month: "long" });
  const firstDateYear = firstSundayDate.getFullYear();
  const lastDateYear = weeks.at(-1).at(-1).getFullYear();

  const compareDates = (date1, date2) => {
    if (
      date1.getDate() === date2.getDate() &&
      date1.getMonth() === date2.getMonth() &&
      date1.getFullYear() === date2.getFullYear()
    ) {
      return true;
    } else {
      return false;
    }
  };
  return (
    <div
      style={{
        padding: "16px",
        border: `1px solid ${props.darkTheme ? COLORS.purple : COLORS.primary}`,
        borderRadius: "8px",
        overflow: "hidden",
        backgroundColor: `${props.darkTheme ? "#1f1f1f" : "#f1f1f1"}`,
      }}
    >
      <p className="months_header">
        {firstDateMonthName}, {firstDateYear} - {lastDateMonthName},{" "}
        {lastDateYear}
      </p>
      <table className="calendar">
        <thead>
          <tr style={{ borderBottom: "1px solid #8c8c8c" }}>
            <th className="rigth_border_cell">S</th>
            <th className="rigth_border_cell">M</th>
            <th className="rigth_border_cell">T</th>
            <th className="rigth_border_cell">W</th>
            <th className="rigth_border_cell">T</th>
            <th className="rigth_border_cell">F</th>
            <th>S</th>
          </tr>
        </thead>
        <tbody>
          {weeks.map((week, index) => (
            <tr
              key={index}
              style={{
                borderBottom:
                  index === weeks.length - 1 ? "" : "1px solid #8c8c8c",
              }}
            >
              {week.map((day, i) => {
                let bgColor = "transparent";
                let color = `${props.darkTheme ? "#f1f1f1" : "#1f1f1f"}`;

                if (!(props.areWeekDays && day.getDay() % 6 === 0)) {
                  for (let i = 0; i < props.tariffsDates.length; ++i) {
                    let startTime = new Date(props.tariffsDates[i].startDate);
                    let endTime = new Date(props.tariffsDates[i].endDate);
                    let time = day.getTime();
                    if (props.atRisk && props.atRisk?.date) {
                      if (compareDates(day, props.atRisk.date)) {
                        bgColor = props.atRisk.bgColor;
                        color = props.atRisk.color;
                      } else if (
                        (startTime.getTime() <= time &&
                          time <= endTime.getTime()) ||
                        compareDates(day, startTime) ||
                        compareDates(day, endTime)
                      ) {
                        bgColor = props.tariffsDates[i].bgColor;
                        color = props.tariffsDates[i].color;
                      }
                    }
                  }
                }
                return (
                  <td
                    key={i}
                    style={{ padding: "4px" }}
                    className={`date_cell ${i < 6 ? "rigth_border_cell" : ""}`}
                  >
                    <div
                      key={i}
                      style={{
                        color: color,
                        backgroundColor: bgColor,
                        borderRadius: "4px",
                        width: "auto",
                        padding: 0,
                      }}
                    >
                      {day.getDate()}
                    </div>
                  </td>
                );
              })}
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};
export default ColorCalendar;
