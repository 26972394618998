import React, { useState, useEffect } from "react";

import { IconButton, createTheme, useMediaQuery } from "@mui/material";
import { saveAs } from "file-saver";
import useStore from "../../../store/store";

import fetchWrapper from "../../../helpers/fetch-wrapper";
import { APP_URL, formatDate } from "../../../utils/constants";

import documentIcon from "../../../assets/img/DemurrageDetailAndRequest/document.svg";
import blueX from "../../../assets/img/DemurrageDetailAndRequest/blueX.svg";

import { COLORS } from "../../../styles/themes/default/colors";

import "../styles/demurrage_styles.scss";

import UploadIcon from "../../../assets/img/uploadIcon.svg";
import DatePickerSingle from "../../../components/Cdl/DatePickerSingle";
import moment from "moment";

export default function DemurrageRequestDoc({
  status,
  handleFileAdd,
  documents,
  setDocuments,
  deletedDoc,
  setDeletedDoc,
  isDetailPage,
  documentDate,
  setDocumentDate,
  receiptError,
  invoiceError,
  paidDate,
  user,
}) {
  const theme1 = createTheme({
    breakpoints: {
      values: {
        sl: 1400,
      },
    },
  });

  const smallLaptop = useMediaQuery(theme1.breakpoints.down("sl"));

  let currentUser = useStore((state) => state.currentUser);
  if (currentUser.user === undefined) {
    currentUser.user = user;
  }
  const [invoiceDocuments, setInvoiceDocuments] = useState(
    documents.filter((item) => item.document_type === "IN")
  );
  const [receiptDocuments, setReceiptDocuments] = useState(
    documents.filter((item) => item.document_type === "RE")
  );
  const [otherDocuments, setOtherDocuments] = useState(
    documents.filter((item) => item.document_type === "OT")
  );

  useEffect(() => {
    setReceiptDocuments(
      documents.filter((item) => item.document_type === "RE")
    );
    setInvoiceDocuments(
      documents.filter((item) => item.document_type === "IN")
    );
    setOtherDocuments(documents.filter((item) => item.document_type === "OT"));
  }, [documents]);

  const downloadFile = (item) => {
    if (item.document) {
      saveAs(item.document, item.filename);
    } else {
      fetchWrapper.get(`${APP_URL}/dnd/documents/${item.id}/`).then((resp) => {
        saveAs(`data:image;base64,${resp.document_64}`, item.filename);
      });
    }
  };

  const deleteFile = (item) => {
    if (item.document) {
      const docs = documents.filter((d) => d.document !== item.document);
      setDocuments(docs);
    } else {
      // for demurrage details
      // remove the file that was added before
      const docs = documents.filter((d) => d.id !== item.id);
      setDocuments(docs);
      fetchWrapper.delete(`${APP_URL}/demurrage/documents/${item.id}/`);
    }
  };
  const getHeigthReceipt = () => {
    const len = receiptDocuments.length;
    const subtractDate = status === "AP" ? 50 : 0;
    if (len === 0) {
      return smallLaptop
        ? `${(236 - subtractDate) / 1.43}px`
        : `${236 - subtractDate}px`;
    } else if (len === 1) {
      return smallLaptop
        ? `${(144 - subtractDate) / 1.4}px`
        : `${144 - subtractDate}px`;
    } else return smallLaptop ? `${45 / 1.4}px` : "45px";
  };
  const getHeigthInvoice = () => {
    const len = invoiceDocuments.length;
    const subtractDate = status === "PA" ? 50 : 0;

    if (len === 0) {
      return smallLaptop
        ? `${(236 - subtractDate) / 1.4}px`
        : `${236 - subtractDate}px`;
    } else if (len === 1) {
      return smallLaptop
        ? `${(144 - subtractDate) / 1.4}px`
        : `${144 - subtractDate}px`;
    } else return smallLaptop ? `${45 / 1.4}px` : "45px";
  };

  const getHeigthOtherDoc = () => {
    const len = otherDocuments.length;

    if (len === 0) {
      return smallLaptop ? `${236 / 1.4}px` : "236px";
    } else if (len === 1) {
      return smallLaptop ? `${144 / 1.43}px` : "144px";
    } else return smallLaptop ? `${52 / 1.4}px` : "52px";
  };

  const slicedName = (filename) => {
    // return filename;
    return filename.length > 27
      ? filename.slice(0, 20).concat(" . . .")
      : filename;
  };

  const DocumentItem = (item, previousItemTime) => {
    return (
      <div>
        <div className="document_name_container">
          <div style={{ display: "flex" }}>
            <img src={documentIcon} alt="" />
            <button className="file_name" onClick={(e) => downloadFile(item)}>
              {slicedName(item.filename)}
            </button>
          </div>
          <div style={{ display: "flex", alignItems: "center" }}>
            <p
              style={{
                margin: 0,
                marginRight: "16px",
                fontSize: "12px",
                color: COLORS.dark_grey,
              }}
            >
              {item.size > 1024 * 1024
                ? `${parseFloat(item.size / 1024 / 1024).toFixed(2)}MB`
                : `${parseFloat(item.size / 1024).toFixed(2)}KB`}
            </p>
            {!(
              status === "CO" ||
              status === "RE" ||
              currentUser.user.role.startsWith("S") ||
              (item.document_type === "RE" && ["PA", "IN"].includes(status)) ||
              (item.document_type === "IN" && status === "IN")
            ) && (
              <IconButton
                style={{
                  color: COLORS.black,
                  padding: 0,
                  marginRight: "5px",
                }}
                onClick={() => deleteFile(item)}
                disabled={status === "CO" || status === "RE"}
              >
                <img src={blueX} alt="" />
              </IconButton>
            )}
          </div>
        </div>
        {moment(previousItemTime).format("L") !==
          moment(item.uploaded_at).format("L") && (
          <p className="document_date">{formatDate(item.uploaded_at)}</p>
        )}
      </div>
    );
  };
  return (
    <div>
      <div style={{ display: "flex", height: "59%" }}>
        <div style={{ width: "33%", marginRight: "20px" }}>
          {status === "AP" && currentUser.user.role.startsWith("C") && (
            <DatePickerSingle
              css={{ width: "100%", marginBottom: "8px" }}
              placeholder="Clearance date"
              value={documentDate}
              onChange={setDocumentDate}
              openAbove={true}
              error={receiptError?.title}
            />
          )}
          {receiptDocuments.length > 0 &&
            receiptDocuments.map((item, index) => {
              return DocumentItem(
                item,
                index === receiptDocuments.length - 1
                  ? null
                  : receiptDocuments.at(index + 1).uploaded_at
              );
            })}
          {currentUser.user.role.startsWith("S") &&
            receiptDocuments.length === 0 && (
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  width: "100%",
                  height: "236px",
                  border: `1px dashed ${COLORS.dark_grey}`,
                  textAlign: "center",
                  color: COLORS.dark_grey,
                  borderRadius: "5px",
                }}
              >
                Receipts will be shown
                <br /> here after upload
              </div>
            )}

          {currentUser.user.role.startsWith("C") &&
            !["PA", "IN", "CO", "RE"].includes(status) && (
              <button
                disabled={
                  currentUser.user.role.startsWith("S") ||
                  status === "CO" ||
                  status === "RE"
                }
                style={{ height: getHeigthReceipt() }}
                className={
                  receiptError?.button
                    ? "document_button_error"
                    : "document_button"
                }
                onClick={() =>
                  document.getElementById("selectFileReceipt").click()
                }
              >
                <img
                  src={UploadIcon}
                  alt="upload_icon"
                  style={{ marginRight: "5px" }}
                />
                Receipt
              </button>
            )}
        </div>
        <div
          style={{
            width: "33%",
            marginRight: "20px",
            height: "100%",
          }}
        >
          {status === "PA" && currentUser.user.role.startsWith("C") && (
            <DatePickerSingle
              css={{ width: "100%", marginBottom: "8px" }}
              placeholder="Invoiced date"
              value={documentDate}
              onChange={setDocumentDate}
              openAbove={true}
              error={invoiceError?.title}
              minDate={new Date(paidDate)}
            />
          )}
          {invoiceDocuments.length > 0 &&
            invoiceDocuments.map((item, index) => {
              return DocumentItem(
                item,
                index === invoiceDocuments.length - 1
                  ? null
                  : invoiceDocuments.at(index + 1).uploaded_at
              );
            })}
          {currentUser.user.role.startsWith("S") &&
            invoiceDocuments.length === 0 && (
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  width: "100%",
                  height: "236px",
                  border: `1px dashed ${COLORS.dark_grey}`,
                  textAlign: "center",
                  color: COLORS.dark_grey,
                  borderRadius: "5px",
                }}
              >
                Invoices will be shown
                <br /> here after upload
              </div>
            )}
          {currentUser.user.role.startsWith("C") &&
            !["CO", "RE", "IN"].includes(status) && (
              <button
                disabled={status === "CO" || status === "RE"}
                className={
                  invoiceError?.button
                    ? "document_button_error"
                    : "document_button"
                }
                style={{ height: getHeigthInvoice() }}
                onClick={() =>
                  document.getElementById("selectFileInvoice").click()
                }
              >
                <img
                  src={UploadIcon}
                  alt="upload_icon"
                  style={{ marginRight: "5px" }}
                />
                Invoice
              </button>
            )}
        </div>
        <div style={{ width: "33%", marginRight: "20px" }}>
          {otherDocuments.length > 0 &&
            otherDocuments.map((item, index) => {
              return DocumentItem(
                item,
                index === otherDocuments.length - 1
                  ? null
                  : otherDocuments.at(index + 1).uploaded_at
              );
            })}
          {currentUser.user.role.startsWith("S") &&
            otherDocuments.length === 0 && (
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  width: "100%",
                  height: "236px",
                  border: `1px dashed ${COLORS.dark_grey}`,
                  textAlign: "center",
                  color: COLORS.dark_grey,
                  borderRadius: "5px",
                }}
              >
                Other files will be shown
                <br /> here after upload
              </div>
            )}
          {currentUser.user.role.startsWith("C") &&
            !["CO", "RE"].includes(status) && (
              <button
                disabled={
                  currentUser.user.role.startsWith("S") ||
                  status === "CO" ||
                  status === "RE"
                }
                style={{
                  height: getHeigthOtherDoc(),
                }}
                className="document_button"
                onClick={() =>
                  document.getElementById("selectFileOther").click()
                }
              >
                <img
                  src={UploadIcon}
                  alt="upload_icon"
                  style={{ marginRight: "5px" }}
                />
                Other
              </button>
            )}
        </div>
      </div>
      <input
        id="selectFileReceipt"
        type="file"
        style={{ visibility: "hidden", height: 0, width: 0 }}
        onChange={(event) => {
          handleFileAdd("RE", event);
        }}
      />
      <input
        id="selectFileOther"
        type="file"
        style={{ visibility: "hidden", height: 0, width: 0 }}
        onChange={(event) => handleFileAdd("OT", event)}
      />
      <input
        id="selectFileInvoice"
        type="file"
        style={{ visibility: "hidden", height: 0, width: 0 }}
        onChange={(event) => handleFileAdd("IN", event)}
      />
    </div>
  );
}
