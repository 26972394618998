import React from "react";
import { Route, Switch } from "react-router-dom";

import CustomerList from "./CustomerList";
import CustomerProfile from "./CustomerProfile/CustomerProfile";
import AddCustomer from "./AddCustomer";

const Customer = ({ match }) => {
  const { path } = match;

  return (
    <div
      style={{
        width: "100%",
        justifyContent: "center",
        padding: "0px 24px 24px 24px",
      }}
    >
      <div style={{ width: "100%", maxWidth: "1920px", height: "100%" }}>
        <Switch>
          <Route exact path={path} component={CustomerList} />
          <Route exact path={`${path}/add`} component={AddCustomer} />
          <Route path={`${path}/:id`} component={CustomerProfile} />
        </Switch>
      </div>
    </div>
  );
};

export default Customer;
