import React, { useState } from "react";
import { Link } from "react-router-dom";
import { Formik, Field, Form, ErrorMessage } from "formik";
import * as Yup from "yup";

import accountService from "../../services/accountService";
import alertService from "../../services/alertService";

const Update = ({ history }) => {
  const user = JSON.parse(localStorage.getItem("currentUser"));
  const initialValues = {
    first_name: user.user.first_name,
    last_name: user.user.last_name,
    email: user.user.email,
    role: user.user.role,
    phone_number: user.user.phone_number,
    location: user.location,
    id: "1",
  };

  const validationSchema = Yup.object().shape({
    first_name: Yup.string().required("First Name is required"),
    last_name: Yup.string().required("Last Name is required"),
    email: Yup.string().email("Email is invalid").required("Email is required"),
  });

  async function onSubmit(fields, { setStatus, setSubmitting }) {
    try {
      setStatus();
      await accountService.update(user.user.id, fields);
      alertService.success("Update successful", { keepAfterRouteChange: true });
      history.push(".");
    } catch (error) {
      setSubmitting(false);
      alertService.error(error);
    }
  }

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={onSubmit}
    >
      {({ errors, touched, isSubmitting }) => (
        <Form>
          <h1>Update Profile</h1>
          <div className="form-row">
            <div className="form-group col">
              <label>First Name</label>
              <Field
                name="first_name"
                type="text"
                className={
                  "form-control" +
                  (errors.first_name && touched.first_name ? " is-invalid" : "")
                }
              />
              <ErrorMessage
                name="first_name"
                component="div"
                className="invalid-feedback"
              />
            </div>
            <div className="form-group col-6">
              <label>Last Name</label>
              <Field
                name="last_name"
                type="text"
                className={
                  "form-control" +
                  (errors.last_name && touched.last_name ? " is-invalid" : "")
                }
              />
              <ErrorMessage
                name="last_name"
                component="div"
                className="invalid-feedback"
              />
            </div>
          </div>
          <div className="form-row">
            <div className="form-group col-6">
              <label>Email</label>
              <Field
                name="email"
                type="text"
                className={
                  "form-control" +
                  (errors.email && touched.email ? " is-invalid" : "")
                }
              />
              <ErrorMessage
                name="email"
                component="div"
                className="invalid-feedback"
              />
            </div>

            <div className="form-group col-6">
              <label>Phone number</label>
              <Field
                name="phone_number"
                type="text"
                className={
                  "form-control" +
                  (errors.phone_number && touched.phone_number
                    ? " is-invalid"
                    : "")
                }
              />
              <ErrorMessage
                name="phone_number"
                component="div"
                className="invalid-feedback"
              />
            </div>
          </div>
          <div className="form-row">
            <div className="form-group col">
              <label>Location</label>
              <Field
                disabled
                name="location"
                type="text"
                className={
                  "form-control" +
                  (errors.location && touched.location ? " is-invalid" : "")
                }
              />
              <ErrorMessage
                name="location"
                component="div"
                className="invalid-feedback"
              />
            </div>
            <div className="form-group col">
              <label>Role</label>
              <Field
                disabled
                name="role"
                as="select"
                className={
                  "form-control" +
                  (errors.role && touched.role ? " is-invalid" : "")
                }
              >
                <option value=""></option>
                <option value="CA">Cdl admin</option>
                <option value="CR">Cdl regular</option>
                <option value="SA">Shipper admin</option>
                <option value="SR">Shipper regular</option>
              </Field>
              <ErrorMessage
                name="role"
                component="div"
                className="invalid-feedback"
              />
            </div>
          </div>
          <div className="form-group">
            <button
              type="submit"
              disabled={isSubmitting}
              className="btn btn-primary mr-2"
            >
              {isSubmitting && (
                <span className="spinner-border spinner-border-sm mr-1"></span>
              )}
              Update
            </button>
            <Link to="/profile" className="btn btn-link">
              Cancel
            </Link>
          </div>
        </Form>
      )}
    </Formik>
  );
};

export default Update;
