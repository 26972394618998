import React from "react";
import { Link } from "react-router-dom";

import accountService from "../../../services/accountService";
import alertService from "../../../services/alertService";
import {  useMediaQuery, useTheme } from "@mui/material";
import { useState } from "react";

import { Input } from "../../../components/Cdl/Input";
import { Button } from "../../../components/Cdl/Button";

const ForgotPassword = () => {
  const [email, setEmail] = useState("");
  const [isSent, setIsSent] = useState(null);

  const theme = useTheme();
  const mdUp = useMediaQuery(theme.breakpoints.up("md"));

  async function Submit() {
    if (email.trim() === "") {
      setIsSent(false);
      return;
    }
    setIsSent(true);

    try {
      await accountService.forgotPassword(email);
      alertService.success(
        "Please check your email for password reset instructions"
      );
    } catch (error) {
      alertService.error(error);
    }
  }
  return (
    <div className="loginDiv">
      <p className="p-24-b-white">Forgot Password?</p>
      <p className="registerText">
        Please enter the email address connected to your account. If the email
        address matches an existing account, you will receive an email with
        reset link in a few minutes.
      </p>
      <Input
        placeholder="E-mail"
        css={{
          width: "100%",
          background: "transparent",
          color: "#ffffff",
          border: "1px solid rgba(189, 189, 189, 0.3)",
        }}
        onChange={(e) => setEmail(e.target.value)}
      />
      <Button
        text="Submit"
        onClick={Submit}
        type="primary"
        css={{ width: "100%", margin: "20px 0px" }}
      />
      <p className="p-14-b-white">
        Back to{" "}
        <Link to="/account/login" className="p-14-b-blue">
          Login
        </Link>
      </p>
    </div>
  );
};

export default ForgotPassword;
