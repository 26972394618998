import React from "react";
import EsgTextField from "../../EsgTextFields";
import { COLORS } from "../../../../../styles/themes/default/colors";

const ReductionRow = (props) => {
  return (
    <div className="d-flex f-dir-col gap4">
      <div className="d-flex gap4">
        <p className="m0 c-white">{props.label}</p>
        {props.starred && <p className="c-purple m0">*</p>}
      </div>
      <div className="d-flex align-center gap16">
        <EsgTextField
          styles={{
            width: "80%",
          }}
          textColor={COLORS.off_white}
          is_function
          value={props.value}
          setValue={(value) => {
            props.changeValue(props.name, value);
            if (props.toggleOffset !== undefined) {
              props.toggleOffset("sandbox", value);
            }
          }}
          year={props.year}
          minValue={0}
        />
        <p className="m0 c-dark-grey">{props.unit}</p>
      </div>
    </div>
  );
};
export default ReductionRow;
