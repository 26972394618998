import { styled } from "@mui/styles";
import { COLORS } from "../../../../styles/themes/default/colors";

export const ContentDiv = styled("div")({
  padding: "16px",
  backgroundColor: COLORS.off_white,
  display: "flex",
  flexDirection: "column",
  borderRadius: "8px",
  flexGrow: 1,
});
export const ContentHeader = styled("div")({
  display: "flex",
  width: "100%",
  justifyContent: "space-between",
  alignItems: "center",
  marginBottom: "16px",
});
export const Content = styled("div")({
  display: "flex",
  flexDirection: "row",
  justifyContent: "space-between",
});

export const ContentSection = styled("div")({
  display: "flex",
  flexDirection: "column",
  width: "100%",
  "p:nth-of-type(4)": {
    marginBottom: "0",
  },
});
export const ContentTitle = styled("p")({
  fontSize: "1rem",
  lineHeight: "20px",
  fontWeight: "600",
  color: COLORS.off_black,
  marginBottom: "16px",
  "@media only screen and (max-width: 1400px) and (min-width: 1280px)": {
    fontSize: "0.6875rem",
    marginBottom: "14px",
  },
});
export const LabelDiv = styled("div")({
  display: "flex",
});

export const InformationLabel = styled("p")({
  fontSize: "1rem",
  lineHeight: "20px",
  color: COLORS.dark_grey,
  marginBottom: "8px",
  "@media only screen and (max-width: 1400px) and (min-width: 1280px)": {
    fontSize: "0.6875rem",
    marginBottom: "5px",
  },
});

export const InformationValue = styled("p")({
  fontSize: "1rem",
  lineHeight: "20px",
  color: COLORS.off_black,
  marginLeft: "24px",
  marginBottom: "16px",
  fontWeight: 600,
  "@media only screen and (max-width: 1400px) and (min-width: 1280px)": {
    fontSize: "0.6875rem",
    marginLeft: "18px",
    marginBottom: "14px",
  },
});

export const Icon = styled("img")({
  marginRight: "6px",
  height: "16px",
  width: "16px",
  "@media only screen and (max-width: 1400px) and (min-width: 1280px)": {
    height: "11px",
    width: "11px",
    marginTop: "5px",
  },
});

export const ContractValue = styled("div")({
  fontSize: "14px",
  lineHeight: "17px",
  fontWeight: "600",
  color: COLORS.activity,
  marginLeft: "24px",
  cursor: "pointer",
  width: "100%",
});
export const UploadButton = styled("button")({
  all: "unset",
  borderRadius: "8px",
  backgroundColor: "#EE4A261A",
  color: "#EE4A26",
  fontSize: "14px",
  fontWeight: "600",
  height: "42px",
  padding: "4px 16px",
});

export const ContractSize = styled("p")({
  fontSize: "12px",
  lineHeight: "14px",
  color: COLORS.dark_grey,
  marginLeft: "28px",
  marginBottom: "0",
  marginTop: "8px",
});
