import React, { useState, useEffect } from "react";

import moment from "moment";
import { Box } from "@mui/material";
import { Button } from "../../components/Cdl/Button";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { styled } from "@mui/material/styles";
import { useHistory } from "react-router-dom";

import DashboardFilters from "./DashboardFilters";
import DashboardStatistics from "./DashboardStatistics";
import DashboardStatus from "./DashboardStatus";
import ExcelModal from "../Demurrage/components/ExcelModal";
import { CustomLoader } from "../../components/CustomLoader";
import StepProgressBar from "../../components/StepProgressBar";
import Tooltip from "../../components/Tooltip";
import Pagination from "../../components/Pagination";
import DemurrageDetailsModal from "./DemurrageDetailsModal";

import fetchWrapper from "../../helpers/fetch-wrapper";
import useStore from "../../store/store";
import { COLORS } from "../../styles/themes/default/colors";
import { APP_URL, formatDate, formatValue } from "../../utils/constants";
import ActionIcon from "../../assets/img/active.svg";
import cdlRiskLogo from "../../assets/img/cdlRiskLogo.png";
import paymentImg from "../../assets/img/paymentImg.png";
import AtRiskFilters from "./AtRiskFilters";
import containerStore from "../../store/containerStore";

const MainDiv = styled("div")({
  width: "100%",
  display: "flex",
  flexDirection: "column",
  padding: window.innerWidth > 1920 ? "0 5px" : "0 24px",
});
const Statistics = styled("div")({
  display: "flex",
  flexDirection: "row",
  justifyContent: "space-between",
  backgroundColor: COLORS.white,
  borderRadius: "5px",
  padding: "16px",
  "@media only screen and (max-width: 1400px) and (min-width: 1280px)": {
    padding: "0px",
  },
});

const StatisticsAccordion = styled(Accordion)({
  backgroundColor: COLORS.white,
  boxShadow: "none",
  border: "unset",
  padding: "0",
  borderRadius: "5px",

  ":before": {
    backgroundColor: "unset",
  },
});

const AccordionSummaryDiv = styled(AccordionSummary)({
  padding: "0",
  color: COLORS.black,
  justifyContent: "unset",
  fontWeight: "600",
});

const AccordionTitle = styled("div")({
  display: "flex",
  justifyContent: "space-between",
  width: "100%",
});

const AccordionTitleLeft = styled("div")({
  display: "flex",
  flex: "0 0 49.5%",
  "@media only screen and (max-width: 1400px) and (min-width: 1280px)": {
    fontSize: "0.685rem",
  },
});

const AccordionTitleRight = styled("div")({
  display: "flex",
  flex: "0 0 49.5%",
  paddingLeft: "16px",
  marginLeft: "28px",
  position: "relative",
  "@media only screen and (max-width: 1400px) and (min-width: 1280px)": {
    fontSize: "0.685rem",
  },
});

const ShowHideStats = styled("div")({
  position: "absolute",
  color: COLORS.activity,
  fontSize: "14px",
  lineHeight: "17px",
  right: "28px",
  top: "23px",
  transition: "200ms ease-in-out all",
  "@media only screen and (max-width: 1400px) and (min-width: 1280px)": {
    fontSize: "0.685rem",
  },
});

const AccordionContent = styled(AccordionDetails)({
  padding: "0",
});

const ActiveDoneDiv = styled("div")({
  display: "flex",
  flexDirection: "row",
  marginBottom: "16px",

  "p:first-of-type": {
    marginRight: "24px",
  },

  "p:nth-of-type(2)": {
    marginRight: "24px",
  },
});

const ActiveDoneLabel = styled("p")({
  marginBottom: "0",
  fontSize: "1rem",
  "@media only screen and (max-width: 1400px) and (min-width: 1280px)": {
    fontSize: "0.685rem",
  },
});

const buttonStyle = {
  lineHeight: "20px",
  cursor: "pointer",
};

const buttonStyleSelected = {
  lineHeight: "20px",
  fontWeight: "600",
  paddingBottom: "8px",
  borderBottom: `2px solid ${COLORS.primary}`,
  cursor: "pointer",
};

const TableDiv = styled("div")({
  width: "100%",
  flexGrow: 1,
  margin: "16px 0",
  backgroundColor: COLORS.white,
  borderRadius: "5px",
  height: "100%",
});

const Table = styled("table")({
  width: "100%",
  display: "table",
});

const TableHeader = styled("thead")({
  display: "table",
  width: "100%",
  padding: "0 10px",
});

const TableHeaderCell = styled("th")({
  fontSize: "14px",
  lineHeight: "17px",
  paddingTop: "12px",
  paddingBottom: "12px",
  textAlign: "left",
  width: "11%",
  "@media only screen and (max-width: 1400px) and (min-width: 1280px)": {
    fontSize: "0.625rem",
  },
});

const TableCell = styled("td")({
  fontSize: "14px",
  lineHeight: "17px",
  textAlign: "left",
  paddingTop: "10px",
  paddingBottom: "10px",
  display: "flex",
  alignItems: "center",
  "@media only screen and (max-width: 1400px) and (min-width: 1280px)": {
    fontSize: "0.625rem",
  },
});

const TableFooter = styled("div")({
  display: "flex",
  flexDirection: "row",
  justifyContent: "space-between",
  marginTop: "16px",
  marginLeft: "14px",
});

const NoDemurragesDiv = styled("div")({
  position: "absolute",
  top: "50%",
  width: "100%",
  left: 0,
  textAlign: "center",
});
const ParagraphMain = styled("p")({
  margin: 0,
  fontSize: "14px",
  "@media only screen and (max-width: 1400px) and (min-width: 1280px)": {
    fontSize: "0.625rem",
  },
});
const ParagraphSecond = styled("p")({
  margin: 0,
  fontSize: "14px",
  color: COLORS.dark_grey,
  "@media only screen and (max-width: 1400px) and (min-width: 1280px)": {
    fontSize: "0.625rem",
  },
});

const DemurrageDashboard = () => {
  const history = useHistory();

  const [resetApply, setResetApply] = useState(false);
  const [resetFilter, setResetFilter] = useState(false);

  const [openDetailsModal, setOpenDetailsModal] = useState(false);
  const [modalContainer, setModalContainer] = useState([]);
  const [modalCustomer, setModalCustomer] = useState("");

  const [rowDataActiveLength, setRowDataActiveLength] = useState(0);
  const [rowDataCompletedLength, setRowDataCompletedLength] = useState(0);
  const [rowDataAtRiskLength, setRowDataAtRiskLength] = useState(0);

  const [hasDemurrages, setHasDemurrages] = useState(false);
  const [numberOfDemurrages, setNumberOfDemurrages] = useState(0);

  const [statistics, setStatistics] = useState({});

  const [currentPage, setCurrentPage] = useState(1);
  let rowDisplay = window.innerWidth > 1920 ? 100000 : 30;

  const [rowData, setRowData] = useState([]);
  const [rowDataActive, setRowDataActive] = useState([]);
  const [rowDataCompleted, setRowDataCompleted] = useState([]);

  const [riskData, setRiskData] = useState([]);

  const [buttonActive, setButtonActive] = useState("active");

  const [status, setStatus] = useState([]);
  const [customer, setCustomer] = useState("");
  const [location, setLocation] = useState("");
  const [creator, setCreator] = useState("");
  const [searchQuery, setSearchQuery] = useState("");

  const [date, setDate] = useState({
    startDate: null,
    endDate: null,
    key: "selection",
  });

  const [excelBeData, setExcelBeData] = useState([]);

  const [terminals, setTerminals] = useState([]);
  const [customers, setCustomers] = useState([]);
  const [creators, setCreators] = useState([]);

  const [isFilterUp, setIsFilterUp] = useState(false);

  const [loader, setLoader] = useState(true);
  const [isExpanded, setIsExpanded] = useState(false);

  const [openExcelModal, setOpenExcelModal] = useState(false);
  const [excelDataLength, setExcelDataLength] = useState();

  const currentUser = useStore((state) => state.currentUser);
  const setAtRiskCOntainer = containerStore(
    (state) => state.setAtRiskContainer
  );

  const [atRiskCustomers, setAtRiskCustomers] = useState();
  const [atRiskLocation, setAtRiskLocation] = useState();
  const [atRiskCharge, setAtRiskCharge] = useState();
  const [searchParams, setSearchParams] = useState("");

  const TableHeaderRow = styled("tr")({
    borderBottom: "1px solid rgba(189, 189, 189, 0.4)",
    width: "100%",
    paddingLeft: "1%",
    display: "flex",

    "th:first-of-type":
      currentUser?.user?.role === "SR" || currentUser?.user?.role === "SA"
        ? buttonActive === "risk"
          ? { width: "11%" }
          : {
              width: "13%",
            }
        : buttonActive === "risk"
        ? { width: "11%" }
        : { width: "10%" },

    "th:nth-of-type(2)": {
      width:
        currentUser?.user?.role === "SR" || currentUser?.user?.role === "SA"
          ? buttonActive === "risk"
            ? "11%"
            : "0%"
          : "11%",
    },

    "th:nth-of-type(3)":
      currentUser?.user?.role === "SR" || currentUser?.user?.role === "SA"
        ? buttonActive === "risk"
          ? { width: "13%" }
          : {
              width: "18%",
            }
        : buttonActive === "risk"
        ? { width: "13%" }
        : { width: "14%" },

    "th:nth-of-type(4)":
      currentUser?.user?.role === "SR" || currentUser?.user?.role === "SA"
        ? buttonActive === "risk"
          ? { width: "8%" }
          : {
              textAlign: "center",
              width: "11%",
            }
        : buttonActive === "risk"
        ? { width: "8%" }
        : {
            textAlign: "center",
            width: "8%",
          },

    "th:nth-of-type(5)": {
      width:
        currentUser?.user?.role === "SR" || currentUser?.user?.role === "SA"
          ? buttonActive === "risk"
            ? "13%"
            : "9%"
          : buttonActive === "risk"
          ? "13%"
          : "6%",
      textAlign: buttonActive === "risk" ? "none" : "right",
    },

    "th:nth-of-type(6)": {
      width:
        currentUser?.user?.role === "SR" || currentUser?.user?.role === "SA"
          ? "14%"
          : buttonActive === "risk"
          ? "14%"
          : "11%",
      textAlign: buttonActive === "risk" ? "none" : "center",
    },

    "th:nth-of-type(7)": {
      width:
        currentUser?.user?.role === "SR" || currentUser?.user?.role === "SA"
          ? buttonActive === "risk"
            ? "8%"
            : "16%"
          : buttonActive === "risk"
          ? "8%"
          : "12%",
    },
    "th:nth-of-type(8)": {
      width:
        currentUser?.user?.role === "SR" || currentUser?.user?.role === "SA"
          ? buttonActive === "risk"
            ? "11%"
            : "14%"
          : "11%",
    },

    "th:nth-of-type(9)": {
      width:
        currentUser?.user?.role === "SR" || currentUser?.user?.role === "SA"
          ? buttonActive === "risk"
            ? "11%"
            : "0%"
          : buttonActive === "risk"
          ? "11%"
          : "12%",
    },
    "th:nth-of-type(10)": {
      width: "5%",
      textAlign: "center",
    },
  });

  const TableRow = styled("tr")({
    borderBottom: "1px solid rgba(189, 189, 189, 0.4)",
    width: "100%",
    height: "70px",
    display: "flex",
    paddingLeft: "1%",
    "&:hover": {
      backgroundColor: "#F6F6F6",
      cursor: "pointer",
    },

    "td:first-of-type":
      currentUser?.user?.role === "SR" || currentUser?.user?.role === "SA"
        ? buttonActive === "risk"
          ? { width: "11%" }
          : {
              width: "13%",
            }
        : buttonActive === "risk"
        ? { width: "11%" }
        : { width: "10%" },

    "td:nth-of-type(2)": {
      width:
        currentUser?.user?.role === "SR" || currentUser?.user?.role === "SA"
          ? buttonActive === "risk"
            ? "11%"
            : "0%"
          : "11%",
    },

    "td:nth-of-type(3)":
      currentUser?.user?.role === "SR" || currentUser?.user?.role === "SA"
        ? buttonActive === "risk"
          ? { width: "13%" }
          : {
              width: "18%",
            }
        : buttonActive === "risk"
        ? { width: "13%" }
        : { width: "14%" },

    "td:nth-of-type(4)":
      currentUser?.user?.role === "SR" || currentUser?.user?.role === "SA"
        ? buttonActive === "risk"
          ? { width: "8%" }
          : {
              justifyContent: "center",
              width: "11%",
            }
        : buttonActive === "risk"
        ? { width: "8%" }
        : {
            justifyContent: "center",
            width: "8%",
          },

    "td:nth-of-type(5)": {
      justifyContent: buttonActive === "risk" ? "none" : "right",

      color: buttonActive === "risk" ? "none" : COLORS.primary,
      fontWeight: buttonActive === "risk" ? "400" : "600",
      width:
        currentUser?.user?.role === "SR" || currentUser?.user?.role === "SA"
          ? buttonActive === "risk"
            ? "13%"
            : "9%"
          : buttonActive === "risk"
          ? "13%"
          : "6%",
    },

    "td:nth-of-type(6)": {
      justifyContent: buttonActive === "risk" ? "none" : "center",
      width:
        currentUser?.user?.role === "SR" || currentUser?.user?.role === "SA"
          ? "14%"
          : buttonActive === "risk"
          ? "14%"
          : "11%",
    },

    "td:nth-of-type(7)": {
      color: buttonActive !== "risk" ? "none" : COLORS.primary,
      fontWeight: buttonActive !== "risk" ? "400" : "600",
      width:
        currentUser?.user?.role === "SR" || currentUser?.user?.role === "SA"
          ? buttonActive === "risk"
            ? "8%"
            : "16%"
          : buttonActive === "risk"
          ? "8%"
          : "12%",
    },

    "td:nth-of-type(8)": {
      width:
        currentUser?.user?.role === "SR" || currentUser?.user?.role === "SA"
          ? buttonActive === "risk"
            ? "11%"
            : "14%"
          : "11%",
    },

    "th:nth-of-type(9)": {
      width:
        currentUser?.user?.role === "SR" || currentUser?.user?.role === "SA"
          ? buttonActive === "risk"
            ? "11%"
            : "0%"
          : buttonActive === "risk"
          ? "11%"
          : "12%",
    },
    "th:nth-of-type(10)": {
      width: "5%",
    },
  });

  const dateToProperFormat = (date) => {
    return moment(new Date(date)).format("YYYY-MM-DD");
  };

  const statusToAbbreviation = (status) => {
    let res = status.map((single) => {
      switch (single) {
        case "Opened":
          return "OP,SA";
        case "Invoiced":
          return "IN";
        case "Approved":
          return "AP";
        case "Cleared":
          return "PA";
        case "Rejected":
          return "RE";
        case "Paid Back":
          return "CO";
        default:
          return "";
      }
    });
    return res;
  };

  const getStatus = (status) => {
    switch (status) {
      case "OP":
        return "Opened";
      case "AP":
        return "Approved";
      case "RE":
        return "Rejected";
      case "IN":
        return "Invoiced";
      case "SA":
        return "Draft";
      case "CO":
        return "Paid Back";
      case "PA":
        return "Cleared";
      default:
        return "null";
    }
  };

  const getDueDateDays = (days) => {
    if (days > 1) {
      return `${days} days left`;
    } else if (days === 1) {
      return `${days} day left`;
    } else if (days === 0) {
      return "Due date is today ";
    } else if (days === -1) {
      return `${days * -1} day past due`;
    } else if (days < -1) {
      return `${days * -1} days past due`;
    }
  };

  const sortData = (data, page) => {
    let active = [];
    let completed = [];

    if (JSON.stringify(statistics) !== JSON.stringify(data.statistics)) {
      setStatistics(data.statistics);
      setIsExpanded(true);
    }
    setHasDemurrages(data.has_demurrages);
    setNumberOfDemurrages(data.count);

    data.results.forEach((item) => {
      let presentationAmount = 0;
      item.containers.forEach((i) => {
        presentationAmount =
          presentationAmount +
          // parseInt(i.detention_amount) +
          parseInt(i.demurrage_amount);
      });

      if (item.status === "CO" || item.status === "RE") {
        completed = [
          ...completed,
          {
            DemurrageID: item.dnd_id,
            Customer: item?.shipper?.business_name,
            Location: item.terminal.name,
            ContainerNo: item.containers.length,
            PickupDate: formatDate(item?.date_of_pickup),
            TotalAmount: `${presentationAmount}`,
            DueDate:
              item.due_date !== null ? formatDate(item.due_date.date) : "_",
            DaysLeft:
              item.due_date !== null
                ? getDueDateDays(item.due_date.days_left)
                : "_",
            due_days_left:
              item.due_date !== null ? item.due_date.days_left : "_",
            Status: getStatus(item.status),
            CDLCreator: item?.created_by?.user?.full_name,
            id: item.id,
            need_attention:
              item?.need_attention?.length > 0
                ? item?.need_attention?.at(0)
                : "",
            containers: item.containers,
          },
        ];
      } else {
        active = [
          ...active,
          {
            DemurrageID: item.dnd_id,
            Customer: item?.shipper?.business_name,
            Location: item.terminal.name,
            ContainerNo: item?.containers.length,
            PickupDate: formatDate(item?.date_of_pickup),
            TotalAmount: `${presentationAmount}`,
            DueDate:
              item.due_date !== null ? formatDate(item.due_date.date) : "_",
            DaysLeft:
              item.due_date !== null
                ? getDueDateDays(item.due_date.days_left)
                : "_",
            due_days_left:
              item.due_date !== null ? item.due_date.days_left : "_",
            Status: getStatus(item?.status),
            CDLCreator: item?.created_by?.user?.full_name,
            id: item.id,
            need_attention:
              item?.need_attention?.length > 0 ? item.need_attention.at(0) : "",
            containers: item.containers,
          },
        ];
      }
    });
    ////mokovano, mora da se prepravi
    setRowDataAtRiskLength(data.at_risk);
    setRowDataActiveLength(data.active);
    setRowDataCompletedLength(data.completed);
    setRowDataActive(active);
    setRowDataCompleted(completed);
    setRowData(buttonActive === "active" ? active : completed);

    setLoader(false);
  };

  useEffect(() => {
    async function getDataForFilters() {
      let data;
      if (
        currentUser?.user?.role === "CA" ||
        currentUser?.user?.role === "CR" ||
        currentUser?.user?.role === "CC"
      ) {
        data = {
          filters: ["customer", "creator", "terminal"],
        };
      } else {
        data = {
          filters: ["terminal"],
        };
      }
      fetchWrapper.post(`${APP_URL}/dnd/filters/`, data).then((resp) => {
        setCustomers(resp.customer);
        setCreators(resp.creator);
        setTerminals(resp.terminal);
      });
    }
    fetchWrapper
      .get(
        `${APP_URL}/dnd/excel_data/?terminal_name=${
          location ? location : ""
        }&creator=${creator ? creator : ""}&customer=${
          customer ? customer : ""
        }&status=${status ? statusToAbbreviation(status) : ""}&due_date=${
          date.startDate && date.endDate
            ? dateToProperFormat(date.startDate) +
              "_" +
              dateToProperFormat(date.endDate)
            : ""
        }&page=${currentPage}${
          buttonActive === "completed" ? "&completed" : ""
        }&count=${rowDisplay}`
      )
      .then((resp) => {
        setExcelBeData(resp);
      });

    setLoader(true);
    if (
      customers?.length === 0 &&
      creator?.length === 0 &&
      terminals?.length === 0
    ) {
      getDataForFilters();
    }
  }, [
    resetFilter,
    isFilterUp,
    buttonActive,
    currentPage,
    setCurrentPage,
    resetApply,
  ]);

  useEffect(() => {
    async function getDemurrages() {
      if (buttonActive === "risk") {
        if (searchParams) {
          await fetchWrapper
            .get(
              `${APP_URL}/container/daas_at_risk/?${searchParams}&page=${currentPage}&count=${rowDisplay}`
            )

            .then((resp) => {
              setRiskData(resp.results);
              setStatistics(resp.statistics);
              setLoader(false);
              setIsExpanded(true);
              setNumberOfDemurrages(resp.count);
              setRowDataAtRiskLength(resp.count);
            });
        } else {
          await fetchWrapper
            .get(
              `${APP_URL}/container/daas_at_risk/?page=${currentPage}&count=${rowDisplay}`
            )

            .then((resp) => {
              setRiskData(resp.results);
              // setRiskData(resp.data.data);
              setStatistics(resp.statistics);
              setLoader(false);
              setIsExpanded(true);
              setNumberOfDemurrages(resp.count);
              setRowDataAtRiskLength(resp.count);
              // sortData(resp, currentPage);
            });
        }
      } else if (isFilterUp) {
        await fetchWrapper
          .get(
            `${APP_URL}/dnd/?terminal_name=${
              location ? location : ""
            }&creator=${creator ? creator : ""}&customer=${
              customer ? customer : ""
            }&status=${status ? statusToAbbreviation(status) : ""}&search=${
              searchQuery ? searchQuery : ""
            }&due_date=${
              date.startDate && date.endDate
                ? dateToProperFormat(date.startDate) +
                  "_" +
                  dateToProperFormat(date.endDate)
                : ""
            }&page=${currentPage}${
              buttonActive === "completed" ? "&completed" : ""
            }&count=${rowDisplay}`
          )
          .then((resp) => {
            sortData(resp, currentPage);
          });
      } else {
        await fetchWrapper
          .get(
            `${APP_URL}/dnd/?page=${currentPage}${
              buttonActive === "completed" ? "&completed" : ""
            }&count=${rowDisplay}`
          )
          .then((resp) => {
            sortData(resp, currentPage);
          });
      }
    }
    setLoader(true);
    getDemurrages();
  }, [
    resetFilter,
    isFilterUp,
    buttonActive,
    currentPage,
    setCurrentPage,
    resetApply,
    searchParams,
  ]);

  useEffect(() => {
    setIsExpanded(false);
  }, [buttonActive]);

  useEffect(() => {
    async function getAtRiskFilters() {
      await fetchWrapper.get(`${APP_URL}/container/filters/`).then((resp) => {
        setAtRiskCustomers(resp.customer);
        setAtRiskLocation(resp.location?.filter((item) => item !== null));
        setAtRiskCharge(resp.charge);
      });
    }

    getAtRiskFilters();
  }, []);

  const resetFilters = () => {
    setDate({ ...date, startDate: null, endDate: null });
    setCurrentPage(1);
    setSearchQuery("");
    setCreator("");
    setStatus([]);
    setLocation("");
    setCustomer("");
    setSearchQuery("");
    setResetFilter(!resetFilter);
    setIsFilterUp(false);
  };

  const handleClick = (label) => {
    setCurrentPage(1);
    setButtonActive(label);
    if (label === "active") {
      setRowData(rowDataActive);
    } else if (label === "completed") {
      setRowData(rowDataCompleted);
    } else {
      // setRowData([]);
    }
  };

  const getExcelData = () => {
    let excelData = [];
    excelBeData.forEach((item) => {
      if (
        currentUser?.user?.role === "SA" ||
        currentUser?.user?.role === "SR"
      ) {
        excelData = [
          ...excelData,
          {
            "Demurrage ID": item.dnd_id,
            Location: item.terminal.name,
            "Container No": item?.containers.length,
            "Pickup date": formatDate(item?.date_of_pickup),
            "Total Amount": `${item.total_demurrage}`,
            "Due Date":
              item.due_date !== null ? formatDate(item.due_date.date) : "_",
            Status: getStatus(item?.status),
          },
        ];
      } else {
        excelData = [
          ...excelData,
          {
            "Demurrage ID": item.dnd_id,
            Customer: item?.shipper?.business_name,
            Location: item.terminal.name,
            "Container No": item?.containers.length,
            "Pickup date": formatDate(item?.date_of_pickup),
            "Total Amount": `${item.total_demurrage}`,
            "Due Date":
              item.due_date !== null ? formatDate(item.due_date.date) : "_",
            Status: getStatus(item?.status),
            "CDL Creator": item?.created_by?.user?.full_name,
          },
        ];
      }
    });
    setExcelDataLength(excelData.length);
    return excelData;
  };

  const handleApply = () => {
    dateToProperFormat(date);
    setIsFilterUp(true);
    setTimeout(() => {
      setCurrentPage(1);
      setResetApply(!resetApply);
    }, 0);
  };

  const TableBody = styled("tbody")({
    display: "block",
    width: "100%",
    minHeight: "50px",
    // height: window.innerHeight ? "none" : "400px",
    // height: isExpanded === true ? "380px" : "580px",
    position: "relative",
  });

  return (
    <MainDiv>
      <div>
        <ExcelModal
          openExcelModal={openExcelModal}
          setOpenExcelModal={setOpenExcelModal}
          getExcelData={getExcelData}
          excelDataLength={excelDataLength}
        />
        <ActiveDoneDiv>
          <ActiveDoneLabel
            style={
              buttonActive === "active" ? buttonStyleSelected : buttonStyle
            }
            onClick={(e) => {
              handleClick("active");
            }}
          >
            Active ({rowDataActiveLength ?? "0"})
          </ActiveDoneLabel>
          <ActiveDoneLabel
            style={buttonActive === "risk" ? buttonStyleSelected : buttonStyle}
            onClick={(e) => {
              handleClick("risk");
            }}
          >
            At risk ({rowDataAtRiskLength})
          </ActiveDoneLabel>
          <ActiveDoneLabel
            style={
              buttonActive === "completed" ? buttonStyleSelected : buttonStyle
            }
            onClick={(e) => {
              handleClick("completed");
            }}
          >
            Done ({rowDataCompletedLength ?? "0"})
          </ActiveDoneLabel>
        </ActiveDoneDiv>
        {buttonActive !== "risk" ? (
          <DashboardFilters
            user={currentUser}
            setOpenExcelModal={setOpenExcelModal}
            excelDataLength={excelDataLength}
            activeTab={buttonActive}
            searchQuery={searchQuery}
            setSearchQuery={setSearchQuery}
            status={status}
            setStatus={setStatus}
            customers={customers}
            customer={customer}
            setCustomer={setCustomer}
            locations={terminals}
            resetFilters={resetFilters}
            location={location}
            setLocation={setLocation}
            creators={creators}
            creator={creator}
            setCreator={setCreator}
            date={date}
            setDate={setDate}
            handleApply={handleApply}
          />
        ) : (
          <AtRiskFilters
            setOpenExcelModal={setOpenExcelModal}
            excelDataLength={excelDataLength}
            user={currentUser}
            setSearchParams={setSearchParams}
            customers={atRiskCustomers}
            locations={atRiskLocation}
            charges={atRiskCharge}
            dateToProperFormat={dateToProperFormat}
          />
        )}

        <StatisticsAccordion
          className="dashboard-accordion"
          expanded={isExpanded}
        >
          <AccordionSummaryDiv
            expandIcon={
              <ExpandMoreIcon
                onClick={() => setIsExpanded(!isExpanded)}
                sx={{
                  color: COLORS.activity,
                  paddingRight: isExpanded ? "0" : "5px",
                  paddingLeft: isExpanded ? "5px" : "0",
                }}
              />
            }
            aria-controls="panel1a-content"
            id="panel1a-header"
          >
            <AccordionTitle>
              <AccordionTitleLeft>
                {buttonActive === "active"
                  ? "Active demurrage containers"
                  : buttonActive === "risk"
                  ? "Demurrage risk statistics"
                  : "Done demurrage containers"}
              </AccordionTitleLeft>
              {buttonActive !== "risk" && (
                <AccordionTitleRight
                  className={`${
                    isExpanded ? "statuses-expanded" : "statuses-hidden"
                  }`}
                >
                  {buttonActive === "active"
                    ? "Active demurrage status"
                    : "Done demurrage status"}
                </AccordionTitleRight>
              )}
              <ShowHideStats
                sx={{
                  top: isExpanded ? "23px" : "15px",
                }}
                onClick={() => {
                  if (!loader) {
                    setIsExpanded(!isExpanded);
                  }
                }}
              >
                {isExpanded ? "Hide stats" : "Show stats"}
              </ShowHideStats>
            </AccordionTitle>
          </AccordionSummaryDiv>
          <AccordionContent>
            <Statistics>
              <DashboardStatistics
                statistics={statistics}
                active_tab={buttonActive}
              />
              {buttonActive !== "risk" && (
                <DashboardStatus
                  status={
                    buttonActive === "active"
                      ? "active"
                      : buttonActive === "risk"
                      ? "risk"
                      : "done"
                  }
                  statistics={statistics}
                />
              )}
            </Statistics>
          </AccordionContent>
        </StatisticsAccordion>
      </div>
      <TableDiv>
        {loader ? (
          <Box
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              height: "20rem",
              width: "100%",
            }}
          >
            <CustomLoader />
          </Box>
        ) : buttonActive === "risk" ? (
          <Table>
            <TableHeader>
              <TableHeaderRow>
                <TableHeaderCell style={{ width: "12%" }}>
                  Customer
                </TableHeaderCell>
                <TableHeaderCell style={{ width: "12%" }}>
                  Container ID
                </TableHeaderCell>
                <TableHeaderCell style={{ width: "12%" }}>
                  Location
                </TableHeaderCell>
                <TableHeaderCell style={{ width: "12%" }}>Type</TableHeaderCell>
                <TableHeaderCell style={{ width: "12%" }}>
                  Free Time
                </TableHeaderCell>
                <TableHeaderCell style={{ width: "14%" }}>
                  Last free day
                </TableHeaderCell>
                <TableHeaderCell
                  style={{
                    width: "12%",
                    display: "flex",
                    justifyContent: "flex-end",
                  }}
                >
                  Amount
                </TableHeaderCell>
                <TableHeaderCell style={{ width: "12%", textAlign: "center" }}>
                  Action
                </TableHeaderCell>
              </TableHeaderRow>
            </TableHeader>
            <TableBody>
              {hasDemurrages === false ? (
                <NoDemurragesDiv>
                  There are no D&D requests to display at the moment.
                </NoDemurragesDiv>
              ) : numberOfDemurrages === 0 ? (
                <NoDemurragesDiv>
                  We're sorry, but we couldn't find any results matching your
                  search criteria. Please try another filter combination.
                </NoDemurragesDiv>
              ) : (
                // rowData.map((item, index) => (
                riskData?.map((item, index) => {
                  return (
                    <TableRow
                      // sx={{ width: "100%" }}
                      key={index}
                      // onClick={() =>
                      //   history.push(`/demurrage/details/${item.id}`)
                      // }
                    >
                      <TableCell style={{ width: "12%" }}>
                        {item?.customer}
                      </TableCell>
                      <TableCell style={{ width: "12%" }}>
                        {item?.container_id}
                      </TableCell>
                      <TableCell style={{ width: "12%" }}>
                        {item?.location}
                      </TableCell>
                      <TableCell
                        style={{
                          flexDirection: "column",
                          justifyContent: "space-around",
                          alignItems: "flex-start",
                          width: "12%",
                        }}
                      >
                        <ParagraphMain>
                          {item.export ? "Export" : "Import"}
                        </ParagraphMain>
                        <ParagraphSecond>{`${item.type} ${item.size}`}</ParagraphSecond>
                      </TableCell>
                      <TableCell
                        style={{
                          flexDirection: "column",
                          justifyContent: "space-around",
                          alignItems: "flex-start",
                          width: "12%",
                        }}
                      >
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            width: "70%",
                          }}
                        >
                          <ParagraphSecond>
                            {item?.is_in_demurrage
                              ? "Demurrage:"
                              : "Detention:"}
                          </ParagraphSecond>
                          <ParagraphMain>
                            {item?.is_in_demurrage
                              ? item?.demurrage_tariffs[0]?.days
                              : item?.detention_tariffs[0]?.days}
                            CD
                          </ParagraphMain>
                        </div>
                      </TableCell>
                      <TableCell
                        sx={{
                          flexDirection: "column",
                          justifyContent: "space-around",
                          alignItems: "flex-start",
                          width: "14%",
                        }}
                      >
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            width: "80%",
                          }}
                        >
                          <ParagraphSecond>
                            {item?.is_in_demurrage
                              ? "Demurrage:"
                              : "Detention:"}
                          </ParagraphSecond>
                          <ParagraphMain>
                            {item?.is_in_demurrage
                              ? item?.demurrage_last_free_day ?? ""
                              : item?.detention_last_free_day ?? ""}
                          </ParagraphMain>
                        </div>
                      </TableCell>

                      <TableCell
                        style={{
                          width: "12%",
                          display: "flex",
                          justifyContent: "flex-end",
                        }}
                      >
                        {formatValue(
                          item?.is_in_demurrage
                            ? item?.demurrage_amount
                            : item?.detention_amount
                        )}
                      </TableCell>

                      <TableCell
                        style={{
                          width: "12%",
                          display: "flex",
                          height: "100%",
                          justifyContent: "center",
                        }}
                      >
                        <Button
                          css={{ margin: "10px" }}
                          type="icon"
                          icon={cdlRiskLogo}
                          onClick={(e) => {
                            e.stopPropagation();
                            setAtRiskCOntainer(item);
                            history.push("/demurrage/request");
                            // setModalContainer(item.containers);
                            // setOpenDetailsModal(true);
                            // history.push(`/demurrage/details/${item.id}`);
                          }}
                        />
                        <Button
                          css={{ margin: "10px" }}
                          type="icon"
                          icon={paymentImg}
                          onClick={(e) => {
                            e.stopPropagation();
                            // setModalContainer(item.containers);
                            // setOpenDetailsModal(true);
                            // history.push(`/demurrage/details/${item.id}`);
                          }}
                        />
                        <Button
                          css={{ margin: "10px" }}
                          type="icon"
                          icon={ActionIcon}
                          onClick={(e) => {
                            e.stopPropagation();
                            setModalContainer([item]);
                            setOpenDetailsModal(true);
                            // history.push(`/demurrage/details/${item.id}`);
                          }}
                        />
                      </TableCell>
                    </TableRow>
                  );
                })

                // ))
              )}
            </TableBody>
          </Table>
        ) : (
          <Table>
            <TableHeader>
              <TableHeaderRow>
                <TableHeaderCell>D&D ID</TableHeaderCell>
                <TableHeaderCell
                  style={
                    currentUser?.user?.role === "SR" ||
                    currentUser?.user?.role === "SA"
                      ? {
                          display: "none",
                        }
                      : { display: "" }
                  }
                >
                  Customer
                </TableHeaderCell>
                <TableHeaderCell>Location</TableHeaderCell>
                <TableHeaderCell>Containers</TableHeaderCell>
                <TableHeaderCell>Amount</TableHeaderCell>
                <TableHeaderCell>Status</TableHeaderCell>
                <TableHeaderCell>Progress</TableHeaderCell>
                <TableHeaderCell>Due Date</TableHeaderCell>
                <TableHeaderCell
                  style={
                    currentUser?.user?.role === "SR" ||
                    currentUser?.user?.role === "SA"
                      ? {
                          display: "none",
                        }
                      : { display: "" }
                  }
                >
                  CDL Creator
                </TableHeaderCell>
                <TableHeaderCell>Action</TableHeaderCell>

                {/* <TableHeaderCell>Action</TableHeaderCell> */}
              </TableHeaderRow>
            </TableHeader>
            <TableBody>
              {hasDemurrages === false ? (
                <NoDemurragesDiv>
                  There are no demurrage requests to display at the moment.
                </NoDemurragesDiv>
              ) : numberOfDemurrages === 0 ? (
                <NoDemurragesDiv>
                  We're sorry, but we couldn't find any results matching your
                  search criteria. Please try another filter combination.
                </NoDemurragesDiv>
              ) : (
                rowData.map((item, index) => (
                  <TableRow
                    key={index}
                    onClick={() =>
                      history.push(`/demurrage/details/${item.id}`)
                    }
                  >
                    <TableCell>{item.DemurrageID}</TableCell>
                    <TableCell
                      style={
                        currentUser?.user?.role === "SR" ||
                        currentUser?.user?.role === "SA"
                          ? {
                              display: "none",
                            }
                          : { display: "" }
                      }
                    >
                      {item.Customer}
                    </TableCell>
                    <TableCell>{item.Location}</TableCell>
                    <TableCell>{item.ContainerNo}</TableCell>
                    <TableCell>
                      {formatValue(
                        item?.containers.reduce(
                          (acc, container) =>
                            acc + container.is_in_demurrage
                              ? container.demurrage_amount
                              : container.detention_amount,
                          0
                        )
                      )}
                    </TableCell>
                    <TableCell
                      className={
                        item.need_attention !== "" ? "need-attention-cell" : ""
                      }
                      style={{
                        fontWeight: item.need_attention !== "" ? "600" : "400",
                      }}
                    >
                      <Tooltip
                        type="Need attention"
                        title={`Status ${item.Status}`}
                        text={item.need_attention}
                        user={currentUser?.user?.role}
                      />
                      {item.Status}
                    </TableCell>
                    <TableCell>
                      <StepProgressBar
                        status={item.Status}
                        user={currentUser?.user?.role}
                      />
                    </TableCell>
                    <TableCell>{item.DueDate}</TableCell>
                    <TableCell
                      style={
                        currentUser?.user?.role === "SR" ||
                        currentUser?.user?.role === "SA"
                          ? {
                              display: "none",
                            }
                          : { display: "", width: "12%" }
                      }
                    >
                      {item.CDLCreator}
                    </TableCell>
                    <TableCell
                      style={{
                        width: "5%",
                        display: "flex",
                        height: "100%",
                        justifyContent: "center",
                      }}
                    >
                      <Button
                        type="icon"
                        onClick={(e) => {
                          e.stopPropagation();
                          setModalContainer(item.containers);
                          setModalCustomer(item.Customer);
                          setOpenDetailsModal(true);
                          // history.push(`/demurrage/details/${item.id}`);
                        }}
                        icon={ActionIcon}
                      />
                    </TableCell>
                  </TableRow>
                ))
              )}
            </TableBody>
          </Table>
        )}
        {window.innerWidth <= 1920 && (
          <TableFooter>
            <Pagination
              totalNumber={
                buttonActive === "active"
                  ? rowDataActiveLength
                  : buttonActive === "risk"
                  ? rowDataAtRiskLength
                  : rowDataCompletedLength
              }
              page={currentPage}
              setPage={setCurrentPage}
              itemsPerPage={rowDisplay}
            />
          </TableFooter>
        )}
      </TableDiv>

      <DemurrageDetailsModal
        openDetailsModal={openDetailsModal}
        setOpenDetailsModal={setOpenDetailsModal}
        modalContainer={modalContainer}
        modalCustomer={modalCustomer}
        isRisk={buttonActive === "risk"}
      />
    </MainDiv>
  );
};

export default DemurrageDashboard;
