import React, { useEffect, useState } from "react";
import Select from "../../../components/Cdl/Select";
import { COLORS } from "../../../styles/themes/default/colors";
import { createTheme, useMediaQuery } from "@mui/material";

import searchIcon from "../assets/searchIcon.svg";
import sustainableGoalsIcon from "../assets/sustainableGoals.svg";
import ytdIcon from "../assets/ytdicon.svg";
import ResetIcon from "../../Container/assets/reset_icon.svg";
import AutoComplete from "../../../components/Cdl/AutoComplete";

const Filter = (props) => {
  const [searchPort, setSearchPort] = useState("")
  const [port, setPort] = useState("");
  const [customer, setCustomer] = useState('');
  const [date, setDate] = useState("");
  const theme1 = createTheme({
    breakpoints: {
      values: {
        sl: 1400,
      },
    },
  });

  useEffect(() => {
    props?.setSelectedItem("")
    props?.setSearchQuery(filterToString());
  }, [port, date, customer]);

  const filterToString = () => {
    if (
      port.length === 0 &&
      customer.length === 0 &&
      date.length === 0
    ) {
      return "";
    }
    let query = "?";
    if (port) {
      query += `&port=${port}`;
    }
    if (customer) {
      query += `&shipper=${customer}`;
    }
    if (date) {
      switch (date) {
        case "Past 3 months": query += `&date_range=QTD`;
          break;
        case "Past 6 months": query += `&date_range=HYTD`;
          break;
        case "Past 9 months": query += `&date_range=NMTD`;
          break;
        default:
          break;
      }
    }
    return query;
  };

  const resetFilters = () => {
    setSearchPort('')
    setPort('')
    setCustomer('')
    setDate('')
  }
  const smallLaptop = useMediaQuery(theme1.breakpoints.down("sl"));

  return (
    <div className="filterBar">
      <AutoComplete
        iconStart={searchIcon}
        search={searchPort}
        setSearch={setSearchPort}
        triggerOnSelectOnly={true}
        setSelectedValue={setPort}
        options={props.ports}
        placeholder="All terminals"
        css={{
          width: smallLaptop ? "150px" : "400px",
          backgroundColor: COLORS.off_black,
          border: "none",
        }}
        cssDiv={{
          width: smallLaptop ? "130px" : "400px",
          backgroundColor: COLORS.off_black,
          color: COLORS.dark_grey,
        }}
        darkTheme={true}
      />
      {(props.user?.user?.role === "CA" ||
        props.user?.user?.role === "CR" ||
        props.user?.user?.role === "CC") && (
          <Select
            icon={sustainableGoalsIcon}
            value={customer}
            onChange={setCustomer}
            placeholder="All customers"
            css={{
              width: smallLaptop ? "130px" : "320px",
              marginLeft: "16px",
              backgroundColor: COLORS.off_black,
            }}
            cssDiv={{
              marginLeft: "16px",
              width: smallLaptop ? "130px" : "320px",
              backgroundColor: COLORS.off_black,
              color: COLORS.dark_grey,
            }}
            options={props.customers}
            darkTheme={true}
            closeOnSelect={true}
            type="select"
          />
        )}
      <Select
        icon={ytdIcon}
        value={date}
        onChange={setDate}
        placeholder="Past 12 months"
        css={{
          width: smallLaptop ? "130px" : "240px",
          marginLeft: "16px",
          backgroundColor: COLORS.off_black,
        }}
        cssDiv={{
          marginLeft: "16px",
          width: smallLaptop ? "130px" : "240px",
          backgroundColor: COLORS.off_black,
          color: COLORS.dark_grey,
        }}
        options={["Past 3 months", "Past 6 months", "Past 9 months"]}
        darkTheme={true}
        closeOnSelect={true}
        type="select"
      />
      <button
        className="reset_button"
        onClick={resetFilters}
        style={{
          width: smallLaptop ? "50px" : "95px",
        }}
      >
        <img
          src={ResetIcon}
          alt=""
          style={{
            marginTop: "6px",
            marginRight: "2px",
            width: smallLaptop ? "15px" : "",
          }}
        />
        Reset
      </button>
    </div >
  );
};

export default Filter;
