import React from "react";
import useStore from "../../store/store";
import { useHistory } from "react-router-dom";

import accountService from "../../services/accountService";
import {
  styled,
  createTheme,
  useMediaQuery,
  useTheme,
  Box,
  Button,
} from "@mui/material";
import { AvatarIcon } from "../../components/ProfilePicture";
import { useState, useEffect } from "react";

import InputMask from "react-input-mask";

import SetPasswordModal from "./components/SetPasswordModal";

import { Button as ButtonCDL } from "../../components/Cdl/Button";
import Select from "../../components/Cdl/Select";
import { Input } from "../../components/Cdl/Input";
import CssTextField from "../../components/StyledTextField";

import DeleteIcon from "../../assets/img/deleteIcon.svg";
import EditIcon from "../../assets/img/editIcon.svg";
import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import alertService from "../../services/alertService";

import ChangePasswordProgress from "./components/ChangePasswordProgress";

const Details = ({ match }) => {
  const currentUser = useStore((state) => state.currentUser);
  const updateZustandUSer = useStore((state) => state.setUser);
  const setNavbarLabel = useStore((state) => state.setNavbarLabel);

  const history = useHistory();

  const theme = useTheme();
  const mdUp = useMediaQuery(theme.breakpoints.up("md"));
  const smUp = useMediaQuery(theme.breakpoints.up("sm"));

  const theme1 = createTheme({
    breakpoints: {
      values: {
        sl: 1400,
      },
    },
  });
  const smallLaptop = useMediaQuery(theme1.breakpoints.down("sl"));

  const [role, setRole] = useState("");
  const [first_name, setFirst_name] = useState("");
  const [last_name, setLast_name] = useState("");
  const [email, setEmail] = useState("");
  const [phone_number, setPhone_number] = useState("");
  const [company, setCompany] = useState("");

  const [selectedTab, setSelectedTab] = useState("General");

  const [isOldVisible, setIsOldVisible] = useState(false);
  const [isNewVisible, setIsNewVisible] = useState(false);
  const [isConfirmVisible, setIsConfirmVisible] = useState(false);
  const [oldPassword, setOldPassword] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [disabledPasswordChange, setDisabledPasswordChange] = useState(true);

  const [oldData, setOldData] = useState({});
  const [newData, setNewData] = useState({});
  const [iconView, setIconView] = useState("");
  const [reset, setReset] = useState(false);
  const [openModal, setOpenModal] = useState(false);
  useEffect(() => {
    setOldData(currentUser.user);

    setNewData(currentUser.user);
    setFirst_name(currentUser.user.first_name);
    setLast_name(currentUser.user.last_name);
    setEmail(currentUser.user.email);
    setPhone_number(currentUser.user.phone_number);
    setRole(currentUser.user.role);
    setCompany(currentUser?.company?.business_name);

    if (currentUser.user.picture_64) {
      setIconView(`data:image/png;base64,${currentUser.user.picture_64}`);
    }

    setNavbarLabel({
      label: "Edit profile",
      back_link: "/admin/users",
      display_icon: false,
    });
  }, [iconView, reset]);

  const returnPasswordStatus = () => {
    if (newPassword.length < 8) {
      return "Password has more than 8 characters.";
    } else if (!/\d/.test(newPassword)) {
      return "Password has a number.";
    } else if (!/[^a-zA-Z0-9]/.test(newPassword)) {
      return "Password has special characters.";
    } else if (!/[A-Z]/.test(newPassword)) {
      return "Password has a capital letter.";
    } else if (newPassword !== confirmPassword) {
      return "Passwords match.";
    }
  };

  const handleChangePassword = async () => {
    await accountService
      .changePassword({
        old_password: oldPassword,
        password: newPassword,
      })
      .then((resp) => {
        alertService.success("Password changed successfully!");
        setOldPassword("");
        setNewPassword("");
        setConfirmPassword("");
        setSelectedTab("General");
      })
      .catch((error) => {
        alertService.error(error.response.status, "Wrong old password");
      });
  };
  let disabled =
    oldData?.first_name === first_name &&
    oldData?.last_name === last_name &&
    oldData?.phone_number === phone_number &&
    oldData?.email === email;

  const addImage = async (e) => {
    setIconView(URL.createObjectURL(e.target.files[0]));

    let data = new FormData();
    data.append("picture", e.target.files[0]);
    data.append("id", currentUser.user.id);
    let user = await accountService.update(data);
    updateZustandUSer(user);
  };

  const handeDeleteImage = async () => {
    let data = new FormData();
    data.append("picture", "");
    data.append("id", currentUser.user.id);
    let user = await accountService.update(data);

    setIconView("");
    updateZustandUSer(user);
  };

  const displayRole = (role) => {
    switch (role) {
      case "CC":
        return "Accountant";
      case "CA":
      case "SA":
        return "Administrator";
      case "SR":
      case "CR":
        return "Regular user";
      default:
        return role;
    }
  };

  const returnError = () => {
    let error = "";
    if (first_name === "") {
      error += "First Name is required\n";
    }
    if (last_name === "") {
      error += "Last Name is required\n";
    }
    if (email === "") {
      error += "Email is required\n";
    } else if (!/^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(email)) {
      error += "Incorect email type.\n";
    }
    if (phone_number?.includes("_") && phone_number !== "+1 (___) ___-____") {
      error += "Invalid phone number format";
    }
    return error;
  };

  const handleSave = async () => {
    let error = returnError();

    if (error !== "") {
      alertService.error(1, error);
      return;
    }

    let data = {
      id: currentUser.user.id,
      first_name: first_name,
      last_name: last_name,
      email: email,
      phone_number: phone_number,
    };

    await accountService
      .update(data)
      .then((resp) => {
        alertService.success("Successfully updated profile");
        setReset(!reset);
        updateZustandUSer(resp);
        // history.push("/admin/users");
      })
      .catch((error) => {
        alertService.error(error.response.status);
      });
  };

  const iconVisibleStyle = {
    color: "#D9D9D9",
  };

  const Container = {
    zIndex: openModal ? -1 : 0,
    width: "100%",
    display: "flex",
    justifyContent: "space-around",
  };

  const AvatarBox = styled(Box)(({ theme }) => ({
    marginRight: smUp ? "5%" : "0%",
  }));

  const PictureChangeBox = styled(Box)(({ theme }) => ({
    marginTop: "10px",
    marginLeft: smallLaptop ? "0px" : "10px",
    paddingRight: smallLaptop ? "10px" : "0px",
    width: "100px",
    display: "flex",
  }));

  const selectedLabel = {
    borderBottom: "3px solid #EE4A26",
    fontWeight: "600",
    margin: "10px",
    "@media only screen and (max-width: 1400px) and (min-width: 1280px)": {
      fontSize: "0.6875rem",
    },
  };

  const unSelectedLabel = {
    color: "#8C8C8C",
    margin: "10px",
    "@media only screen and (max-width: 1400px) and (min-width: 1280px)": {
      fontSize: "0.6875rem",
    },
  };

  return (
    <div style={{ width: "100%" }}>
      <SetPasswordModal
        open={openModal}
        email={currentUser.user.email}
        setOpenModal={setOpenModal}
      />
      <div style={{ display: "flex", marginLeft: "20px" }}>
        <p
          onClick={() => setSelectedTab("General")}
          className={
            selectedTab === "General" ? "selectedLabel" : "unSelectedLabel"
          }
        >
          General
        </p>
        <p
          onClick={() => setSelectedTab("Privacy")}
          className={
            selectedTab === "Privacy" ? "selectedLabel" : "unSelectedLabel"
          }
        >
          Privacy
        </p>
      </div>
      {selectedTab === "General" ? (
        <>
          <Box style={Container}>
            <div
              className="personal_div"
              style={{ padding: "10px 0px 20px 20px" }}
            >
              <div style={{ width: "30%" }}>
                <p className="p-16-b">Personal</p>
                <AvatarBox>
                  <AvatarIcon
                    src={
                      currentUser?.user?.picture_64 !== null
                        ? `data:image/png;base64,${currentUser?.user?.picture_64}`
                        : ""
                    }
                    firstName={newData.first_name}
                    lastName={newData.last_name}
                    style={{
                      width: smallLaptop ? "5rem" : "7rem",
                      height: smallLaptop ? "5rem" : "7rem",
                      backgroundColor: "#C4C4C4",
                      fontSize: smUp ? "30px" : "25px",
                      fontFamily: "Montserrat",
                    }}
                  />
                </AvatarBox>
                <PictureChangeBox>
                  <ButtonCDL
                    type="icon"
                    icon={EditIcon}
                    iconCss={{
                      marginTop: "3px",
                      marginRight: "5px",
                      width: "11px",
                    }}
                    onClick={() => document.getElementById("add_photo").click()}
                  >
                    {newData?.picture_64 ? "CHANGE PHOTO" : "ADD PHOTO"}
                  </ButtonCDL>
                  <input
                    id="add_photo"
                    type="file"
                    style={{ visibility: "hidden", height: 0 }}
                    onChange={(event) => addImage(event)}
                  />

                  <ButtonCDL
                    type="icon"
                    icon={DeleteIcon}
                    iconCss={{
                      marginTop: "3px",
                      marginRight: "5px",
                      width: "11px",
                    }}
                    onClick={handeDeleteImage}
                  >
                    {newData?.picture_64 ? "CHANGE PHOTO" : "ADD PHOTO"}
                  </ButtonCDL>
                </PictureChangeBox>
              </div>
              <div className="full_name_div" style={{ marginTop: "25px" }}>
                <p className="p-16-gray">First name:</p>
                <Input
                  value={first_name}
                  onChange={(e) => setFirst_name(e.target.value)}
                  css={{ width: "90%" }}
                />

                <p className="p-16-gray" style={{ margin: "10px 0px 0px 0px" }}>
                  Last name:
                </p>
                <Input
                  value={last_name}
                  onChange={(e) => setLast_name(e.target.value)}
                  css={{ width: "90%" }}
                />
              </div>
            </div>
            <div className="contact_div">
              <p className="p-16-b">Contact</p>
              <p className="p-16-gray">Email:</p>
              <p className="p-16">{email}</p>
              <p className="p-16-gray">Phone number:</p>
              <InputMask
                mask="+1 (999) 999-9999"
                alwaysShowMask
                value={phone_number}
                onChange={(e) => {
                  setPhone_number(e.target.value);
                }}
                placeholder="Phone number"
              >
                <Input css={{ width: "90%" }} />
              </InputMask>
            </div>

            <div className="company_div">
              <p className="p-16-b">Company</p>
              <p className="p-16-gray">Company name:</p>
              <p className="p-16">{company ? company : "CDL"}</p>
              <p className="p-16-gray">Role:</p>
              <p className="p-16">{displayRole(role)}</p>
            </div>
          </Box>

          <div style={{ display: "flex", flexDirection: "row-reverse" }}>
            <div style={{ marginTop: "20px", marginRight: "1%" }}>
              <ButtonCDL
                disabled={disabled}
                onClick={() => handleSave()}
                type="primary"
                text="Save changes"
                css={{
                  marginRight: "10px",
                  width: smallLaptop ? "120px" : "164px",
                }}
              />
              <ButtonCDL
                onClick={() => setReset(!reset)}
                type="link"
                text="Discard"
                css={{ width: smallLaptop ? "80px" : "100px" }}
              />
            </div>
          </div>
        </>
      ) : (
        <div>
          <div
            style={{
              background: "white",
              width: "30%",
              borderRadius: "8px",
              marginLeft: "15px",
            }}
          >
            <div style={{ padding: "20px 30px" }}>
              <p className="p-16-b">Password change</p>
              <p className="p-16-gray">Current password</p>
              <CssTextField
                style={
                  smallLaptop
                    ? {
                        width: "100%",
                        background: "#FAFAFA",
                        height: "1.875rem",
                      }
                    : { width: "100%", background: "#FAFAFA" }
                }
                size="small"
                value={oldPassword}
                type={isOldVisible ? "text" : "password"}
                onChange={(e) => {
                  setOldPassword(e.target.value);
                }}
                InputProps={{
                  style: {
                    fontFamily: "Montserrat",
                    fontSize: "0.6875rem",
                  },
                  endAdornment: (
                    <Button
                      style={{ outline: "none", backgroundColor: "unset" }}
                      onClick={(e) => {
                        setIsOldVisible(!isOldVisible);
                      }}
                    >
                      {isOldVisible ? (
                        <VisibilityIcon style={iconVisibleStyle} />
                      ) : (
                        <VisibilityOffIcon style={iconVisibleStyle} />
                      )}
                    </Button>
                  ),
                }}
              />
              <p className="p-16-gray">New password</p>
              <CssTextField
                onChange={(e) => {
                  setNewPassword(e.target.value);
                }}
                style={
                  smallLaptop
                    ? {
                        width: "100%",
                        background: "#FAFAFA",
                        height: "1.875rem",
                      }
                    : { width: "100%", background: "#FAFAFA" }
                }
                size="small"
                value={newPassword}
                type={isNewVisible ? "text" : "password"}
                InputProps={{
                  style: {
                    fontFamily: "Montserrat",
                    fontSize: "0.6875rem",
                  },
                  endAdornment: (
                    <Button
                      style={{ outline: "none", backgroundColor: "unset" }}
                      onClick={(e) => {
                        setIsNewVisible(!isNewVisible);
                      }}
                    >
                      {isNewVisible ? (
                        <VisibilityIcon style={iconVisibleStyle} />
                      ) : (
                        <VisibilityOffIcon style={iconVisibleStyle} />
                      )}
                    </Button>
                  ),
                }}
              />
              <ChangePasswordProgress
                message={returnPasswordStatus()}
                setDisabledPasswordChange={setDisabledPasswordChange}
              />
              <p className="p-16-gray">Confirm new password</p>
              <CssTextField
                onChange={(e) => {
                  setConfirmPassword(e.target.value);
                }}
                style={
                  smallLaptop
                    ? {
                        width: "100%",
                        background: "#FAFAFA",
                        height: "1.875rem",
                      }
                    : { width: "100%", background: "#FAFAFA" }
                }
                size="small"
                value={confirmPassword}
                type={isConfirmVisible ? "text" : "password"}
                InputProps={{
                  style: {
                    fontFamily: "Montserrat",
                    fontSize: "0.6875rem",
                  },
                  endAdornment: (
                    <Button
                      style={{ outline: "none", backgroundColor: "unset" }}
                      onClick={(e) => {
                        setIsConfirmVisible(!isConfirmVisible);
                      }}
                    >
                      {isConfirmVisible ? (
                        <VisibilityIcon style={iconVisibleStyle} />
                      ) : (
                        <VisibilityOffIcon style={iconVisibleStyle} />
                      )}
                    </Button>
                  ),
                }}
              />
            </div>
          </div>
          <div
            style={{
              justifyContent: "end",
              display: "flex",
              width: "30%",
              marginLeft: "15px",
            }}
          >
            <ButtonCDL
              text="Save changes"
              onClick={handleChangePassword}
              type="primary"
              disabled={disabledPasswordChange}
              css={{ marginTop: "20px" }}
            />
          </div>
        </div>
      )}
    </div>
  );
};

export default Details;
