import React, { useEffect, useState } from "react";

import { styled } from "@mui/material/styles";
import OffsetIconBlue from "../components/assets/offset_blue.svg";

import CircularProgressWithLabel from "../../../../components/CircularProgressWithLabel";

import { APP_URL, AmountToThousands } from "../../../../utils/constants";
import { COLORS } from "../../../../styles/themes/default/colors";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import OffsetCertificateMocked from "../components/assets/Certificate_blur.png";
import PlantIcon from "../components/assets/plant_icon.svg";
import PlusIcon from "../components/assets/plus_icon_blue.svg";
import DeleteIcon from "../components/assets/delete_icon.svg";
import fetchWrapper from "../../../../helpers/fetch-wrapper";
import { Dialog } from "@mui/material";
import alertService from "../../../../services/alertService";

const MainDiv = styled("div")({
  flex: 0,
  marginBottom: "16px",
});

const StatisticsAccordion = styled("div")({
  boxShadow: "unset",
  backgroundColor: `${COLORS.ligth_grey} `,
  borderRadius: `8px !important`,
  display: "flex",
  gap: "16px",
});

const StatisticsDiv = styled("div")({
  width: "100%",
  display: "flex",
  flexDirection: "row",

  "div:nth-of-type(2)": {
    borderLeft: "1px solid rgba(189, 189, 189, 0.4)",
    borderRight: "1px solid rgba(189, 189, 189, 0.4)",
  },

  "div:nth-of-type(3)": {
    alignItems: "center",
    paddingTop: "10px",
  },
});

const SingleStatisticsDiv = styled("div")({
  display: "flex",
  flexDirection: "column",
  width: "33%",
  textAlign: "center",
  paddingTop: "40px",

  ".paid-amount": {
    color: COLORS.success,
  },

  "@media only screen and (max-width: 1400px) and (min-width: 1280px)": {
    paddingTop: "25px",
  },
});

const StatisticsValue = styled("p")({
  fontWeight: "600",
  fontSize: "24px",
  lineHeight: "29px",
  color: COLORS.off_black,
  marginBottom: "40px",
  "@media only screen and (max-width: 1400px) and (min-width: 1280px)": {
    fontSize: "18px",
    marginBottom: "20px",
  },
});

const StatisticsLabel = styled("p")({
  marginBottom: "0",
  fontSize: "14px",
  lineHeight: "17px",
  fontWeight: "400",
  color: COLORS.dark_grey,
  "@media only screen and (max-width: 1400px) and (min-width: 1280px)": {
    fontSize: "0.625rem",
    // paddingBottom: "10px",
  },
});

const Graph = styled("div")({
  display: "flex",
  marginBottom: "10px",
  "@media only screen and (max-width: 1400px) and (min-width: 1280px)": {
    marginBottom: "0",
  },
});
const StatisticsContainer = styled("div")({
  backgroundColor: COLORS.off_white,
  height: "100%",
  flexGrow: 1,
  padding: "16px",
  borderRadius: "8px",
});
const StatisticsHeader = styled("div")({
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
  width: "100%",
  marginBottom: "8px",
});
const StatisticsHeaderTitle = styled("p")({
  fontWeight: "600",
  justifyContent: COLORS.off_black,
  margin: "0",
});
const OffsetButton = styled("button")({
  background: "transparent",
  border: "none",
  color: COLORS.activity,
  fontWeight: "600",
});
const LogoButton = styled("button")({
  all: "unset",
  width: "92px",
  height: "92px",
  borderRadius: "50%",
  backgroundColor: "#41BAEE1A",
  color: "#41BAEE",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  position: "relative",
  marginBottom: "8px",
  transition: "0.3s ease-in-out all",
});
const LogoTypography = styled("p")({
  fontSize: "14px",
  color: COLORS.dark_grey,
  margin: 0,
  textAlign: "center",
});
const CustomerStatistics = ({ statistics, currentCustomer, isExpanded }) => {
  const [isPreviewOpen, setIsPreviewOpen] = useState(false);

  const [logoView, setLogoView] = useState("");

  async function handleLogoUpdate(file) {
    if (!["image/png", "image/svg+xml"].includes(file.type)) {
      alertService.info("Supported formats are .SVG and .PNG");
      return;
    }
    setLogoView(URL.createObjectURL(file));
    let data = new FormData();
    data.append("logo", file);

    await fetchWrapper
      .put(`${APP_URL}/shipper/${currentCustomer.id}/logo`, data)
      .then((resp) => {})
      .catch((error) => {
        alertService.error(error.response.status);
      });
  }
  async function deleteLogo() {
    let data = new FormData();

    await fetchWrapper
      .put(`${APP_URL}/shipper/${currentCustomer.id}/logo`, data)
      .then((resp) => {
        setLogoView("");
      })
      .catch((error) => {
        alertService.error(1);
      });
  }
  useEffect(() => {
    if (currentCustomer?.logo_64) {
      setLogoView(
        `data:image/${
          currentCustomer?.logo_type === "png" ? "png" : "svg+xml"
        };base64, ${currentCustomer.logo_64}`
      );
    }
  }, [currentCustomer]);
  const history = useHistory();
  return (
    <MainDiv style={{ marginBottom: isExpanded ? "16px" : "0px" }}>
      <Dialog
        open={isPreviewOpen}
        onClose={() => {
          setIsPreviewOpen(false);
        }}
        sx={{
          "& .MuiDialog-container": {
            "& .MuiPaper-root": {
              maxWidth: "none",
              width: "1150px",
            },
          },
        }}
      >
        <img src={OffsetCertificateMocked} alt="offset_cer_mocked" />
      </Dialog>
      <StatisticsAccordion
        expanded={isExpanded}
        disableGutters={true}
        style={{
          height: isExpanded ? "185px" : "0px",
          transition: "0.3s all ease-in-out",
          visibility: isExpanded ? "visible" : "hidden",
        }}
      >
        <StatisticsContainer>
          <StatisticsHeader>
            <StatisticsHeaderTitle>D&D Statistics</StatisticsHeaderTitle>
          </StatisticsHeader>
          <StatisticsDiv>
            <SingleStatisticsDiv>
              <StatisticsValue className="paid-amount">
                {`$ ${AmountToThousands(
                  statistics?.total_paid_demurrage_amount
                )}`}
              </StatisticsValue>
              <StatisticsLabel>Paid amount</StatisticsLabel>
            </SingleStatisticsDiv>
            <SingleStatisticsDiv>
              <StatisticsValue>{`$ ${AmountToThousands(
                statistics?.total_unpaid_demurrage_amount
              )}`}</StatisticsValue>
              <StatisticsLabel>Outstanding</StatisticsLabel>
            </SingleStatisticsDiv>
            <SingleStatisticsDiv>
              <Graph>
                <CircularProgressWithLabel
                  value={
                    statistics?.due_this_week?.total_due_date_this_week === 0 ||
                    statistics?.due_this_week?.total_due_date_demurrages === 0
                      ? 0
                      : statistics?.due_this_week?.total_due_date_this_week /
                        statistics?.due_this_week?.total_due_date_demurrages
                  }
                />
              </Graph>
              <StatisticsLabel>Due this week</StatisticsLabel>
            </SingleStatisticsDiv>
          </StatisticsDiv>
        </StatisticsContainer>
        <StatisticsContainer style={{ width: "auto" }}>
          <StatisticsHeader style={{ justifyContent: "space-between" }}>
            <StatisticsHeaderTitle>
              CO<sub>2</sub> Offset
            </StatisticsHeaderTitle>
            <OffsetButton
              onClick={() => {
                history.push("/esg/offset-projects");
              }}
            >
              <img
                src={OffsetIconBlue}
                alt="offset_icon"
                style={{ marginRight: "4px" }}
              />
              Offset now
            </OffsetButton>
          </StatisticsHeader>
          <StatisticsDiv>
            <SingleStatisticsDiv style={{ width: "50%" }}>
              <StatisticsValue>
                <img src={PlantIcon} alt="plant_icon" /> 0
              </StatisticsValue>
              <StatisticsLabel>Total Offset</StatisticsLabel>
            </SingleStatisticsDiv>
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                width: "50%",
                borderRight: "none",
              }}
            >
              <img
                src={OffsetCertificateMocked}
                alt="offset_cer"
                style={{
                  cursor: "pointer",
                  height: "100px",
                  width: "170px",
                  borderRadius: "8px",
                }}
                onClick={() => {
                  setIsPreviewOpen(true);
                }}
              />
            </div>
          </StatisticsDiv>
        </StatisticsContainer>
        <StatisticsContainer style={{ flexGrow: 0, maxWidth: "27%" }}>
          <StatisticsHeader>
            <StatisticsHeaderTitle>Logo</StatisticsHeaderTitle>
          </StatisticsHeader>

          <div style={{ width: "100%", display: "flex" }}>
            <div style={{ marginRight: "24px" }}>
              <LogoButton
                onClick={() => {
                  document.getElementById("logoSelect").click();
                }}
                style={{
                  backgroundColor: logoView ? "transparent" : "#41BAEE1A",
                  transition: "0.3s all ease-in-out",
                }}
              >
                {logoView ? (
                  <img
                    src={logoView}
                    alt="logo_icon"
                    style={{
                      objectFit: "contain",
                      width: "100%",
                      height: "100%",
                      overflow: "hidden",
                      borderRadius: "50%",
                    }}
                  />
                ) : (
                  <img src={PlusIcon} alt="plus_icon" />
                )}
                {logoView && (
                  <button
                    style={{
                      all: "unset",
                      position: "absolute",
                      top: "3%",
                      right: "3%",
                    }}
                    onClick={(e) => {
                      e.stopPropagation();
                      deleteLogo();
                    }}
                  >
                    <img src={DeleteIcon} alt="delete-icon" />
                  </button>
                )}
              </LogoButton>
              <LogoTypography>Max. 10MB</LogoTypography>
            </div>
            <div style={{}}>
              <LogoTypography
                style={{ textAlign: "left", marginBottom: "16px" }}
              >
                Your logo will be used to generate your CO2 offset certificate.
              </LogoTypography>
              <LogoTypography style={{ textAlign: "left" }}>
                Supported formats are <b>.SVG</b> and <b>.PNG</b>. Please make
                sure that there is no background on your logo, thank you
              </LogoTypography>
            </div>
          </div>
          <input
            id="logoSelect"
            type="file"
            style={{ visibility: "hidden", width: 0, height: 0 }}
            onChange={(event) => {
              handleLogoUpdate(event.target.files[0]);
            }}
          />
        </StatisticsContainer>
      </StatisticsAccordion>
    </MainDiv>
  );
};

export default CustomerStatistics;
