import React, { useState, useEffect } from "react";

import { useMediaQuery, createTheme } from "@mui/material";

import AutoComplete from "../../../components/Cdl/AutoComplete";
import "../styles/demurrage_styles.scss";
import { Input } from "../../../components/Cdl/Input";

import LocationIcon from "../../../assets/img/DemurrageDetailAndRequest/location_icon.svg";
import ShipperIcon from "../../../assets/img/Sidebar/customers_icon.svg";
import CarrierIcon from "../../../assets/img/DemurrageDetailAndRequest/Carrier.svg";
import CauseOfDemurrageIcon from "../../../assets/img/Sidebar/demurrages_icon.svg";

export default function GeneralInformation({
  shipper,
  setShipper,
  setCarrier,
  carrier,
  cause,
  setCause,
  shippers,
  causes,
  setLocation,
  terminals,
  terminal,
  location,
  guest,
}) {
  const theme1 = createTheme({
    breakpoints: {
      values: {
        sl: 1400,
      },
    },
  });

  const smallLaptop = useMediaQuery(theme1.breakpoints.down("sl"));

  const GeneralStyle = {
    width: smallLaptop ? "15.2rem" : "23rem",
  };

  const [value, setValue] = useState("");

  useEffect(() => {
    if (value && !guest) {
      let selectedTerminal = terminals.filter((item) => item.name === value);
      if (selectedTerminal.length > 0) {
        setLocation(selectedTerminal[0]);
      }
    } else if (terminal && !guest) {
      setValue(terminal);
    }
  }, [value]);
  useEffect(() => {
    if (location && Object.keys(location).length > 0) {
      setValue(location.name);
    }
  }, [location]);

  return (
    <div style={{ display: "flex", justifyContent: "space-between" }}>
      <div className="gi_row">
        <AutoComplete
          placeholder={"Select shipper *"}
          options={shippers.map((item) => {
            return item.business_name;
          })}
          cssDiv={GeneralStyle}
          iconStart={ShipperIcon}
          search={shipper}
          setSearch={setShipper}
          css={GeneralStyle}
        />
        <AutoComplete
          placeholder={"Cause of D&D"}
          iconStart={CauseOfDemurrageIcon}
          options={causes.map((item) => {
            return item.cause;
          })}
          search={cause}
          setSearch={setCause}
          css={GeneralStyle}
        />
        <Input
          placeholder={"Carrier"}
          iconStart={CarrierIcon}
          value={carrier}
          onChange={(e) => {
            setCarrier(e.target.value);
          }}
          css={GeneralStyle}
        />
      </div>
      <div style={{ width: "26%", textAlign: "right" }}>
        <AutoComplete
          placeholder={"Choose terminal location *"}
          iconStart={LocationIcon}
          options={terminals.map((item) => {
            return item.name;
          })}
          setSearch={setValue}
          search={value}
          css={{ width: "95%" }}
        />
      </div>
    </div>
  );
}
