import React, { useState, useEffect, useRef } from "react";

import { styled } from "@mui/material";

import arrowDown from "../../assets/img/arrowDown.svg";
import whiteXIcon from "../../assets/img/white_x.svg";

import { Button } from "./Button";

import { FormGroup, FormControlLabel, Checkbox } from "@mui/material";
import { COLORS } from "../../styles/themes/default/colors";
import { useMediaQuery, createTheme } from "@mui/material";
import { Cookie } from "@mui/icons-material";
import { dark } from "@material-ui/core/styles/createPalette";

export default function Select({
  options,
  value,
  onChange,
  placeholder,
  icon,
  css,
  cssDiv,
  disabled,
  type,
  darkTheme,
  closeOnSelect,
  pillBackground,
  valueLengthLimit = 18,
  removeCell,
}) {
  const theme1 = createTheme({
    breakpoints: {
      values: {
        sl: 1400,
      },
    },
  });

  const smallLaptop = useMediaQuery(theme1.breakpoints.down("sl"));

  const [isOpen, setIsOpen] = useState(false);
  const wrapperRef = useRef(null);

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const handleClickOutside = (event) => {
    const { current: wrap } = wrapperRef;
    if (wrap && !wrap.contains(event.target)) {
      setIsOpen(false);
    }
  };

  const toggleSelect = () => {
    if (!disabled) {
      setIsOpen(!isOpen);
    }
  };

  const handleOptionClick = (optionValue) => {
    if (type === "select") {
      setIsOpen(false);
      onChange(optionValue);
    } else {
      if (value.includes(optionValue)) {
        let newValue = value.filter((item) => item !== optionValue);
        onChange(newValue);
      } else {
        onChange([...value, optionValue]);
      }
    }
    if (closeOnSelect) {
      setIsOpen(false);
    }
  };

  const ValueDiv = styled("div")(() => ({
    width: "200px",
    height: "42px",
    background: "#FAFAFA",
    borderRadius: "8px 8px",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    border: darkTheme ? "" : "1px solid rgba(189, 189, 189, 0.4)",
    "&:hover": {
      border: darkTheme
        ? `1px solid ${COLORS.purple}33`
        : "1px solid rgba(238, 74, 38, 0.2)",
    },
    "&:focus": {
      borderWidth: "1px",
      borderStyle: "solid",
      borderColor: darkTheme ? COLORS.purple : COLORS.primary,
    },
    "@media only screen and (max-width: 1400px) and (min-width: 1280px)": {
      height: "1.875rem",
      fontSize: "0.6875rem",
    },
  }));

  const OptionDiv = styled("div")(() => ({
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    backgroundColor: "#FAFAFA",
    margin: "0",
    padding: "0 10px",
    "&:hover": {
      backgroundColor: "#F2F2F2",
    },
    "&:focus": {
      backgroundColor: "#EAEAEA",
    },
  }));

  return (
    <div
      className="pos-rel"
      ref={wrapperRef}
      // style={{
      //   borderWidth: "0.1 em",
      //   borderStyle: "solid",
      //   borderColor: "#8c8c8c80",
      //   borderRadius: "8px",
      // }}
    >
      <ValueDiv
        onClick={toggleSelect}
        sx={{
          border: isOpen
            ? `1px solid ${darkTheme ? COLORS.purple : COLORS.primary}`
            : "",
          ...css,
        }}
      >
        <div
          style={{
            display: "flex",
            width: "85%",
            alignItems: "center",
          }}
        >
          <img
            src={icon}
            style={{
              marginLeft: icon ? "15px" : "-5px",
              height: smallLaptop ? "11px" : "16px",
            }}
            alt=""
          />
          <div
            className="selected-value"
            style={{
              paddingLeft: "10px",
              color: "#8C8C8C",
              overflow: "hidden",
              "&:focus": {
                border: `1px solid ${COLORS.primary}`,
              },
            }}
          >
            {type === "select" ? (
              value ? (
                removeCell ? (
                  <p className={`${darkTheme ? "c-dark-grey" : "c-black"} m0`}>
                    {value.length > valueLengthLimit
                      ? `${value.substring(0, valueLengthLimit)} ...`
                      : value}
                  </p>
                ) : (
                  <div
                    style={{
                      borderRadius: "8px",
                      padding: "4px 7px 4px 7px",
                      display: "inline-block",
                      whiteSpace: "nowrap",
                      backgroundColor: pillBackground ?? "#343434",
                      color: COLORS.ligth_grey,
                      // fontWeight: 600,
                      fontSize: smallLaptop ? "10px" : "14px",
                      marginLeft: "5px",
                    }}
                  >
                    {value.length > valueLengthLimit
                      ? `${value.substring(0, valueLengthLimit)} ...`
                      : value}
                    <button
                      onClick={(e) => {
                        if (!disabled) {
                          e.stopPropagation();
                          onChange("");
                        }
                      }}
                      style={{
                        border: "none",
                        outline: "none",
                        backgroundColor: "transparent",
                      }}
                    >
                      <img
                        src={whiteXIcon}
                        style={{
                          width: "10px",
                          height: "10px",
                          marginLeft: "2px",
                          marginBottom: "1px",
                        }}
                        alt=""
                      />
                    </button>
                  </div>
                )
              ) : (
                placeholder
              )
            ) : value.length > 0 ? (
              <div
                style={{
                  display: "flex",
                  overflow: "hidden",
                  alignItems: "center",
                  whiteSpace: "nowrap",
                }}
              >
                {value.map((item) => {
                  return (
                    <div
                      style={{
                        borderRadius: "8px",
                        padding: "4px 7px 4px 7px",
                        display: "inline-block",
                        backgroundColor: "#343434",
                        color: COLORS.ligth_grey,
                        // fontWeight: 600,
                        fontSize: smallLaptop ? "10px" : "14px",
                        marginLeft: "5px",
                      }}
                    >
                      {item}
                      <button
                        onClick={(e) => {
                          e.stopPropagation();
                          onChange(value.filter((x) => x !== item));
                        }}
                        style={{
                          border: "none",
                          outline: "none",
                          backgroundColor: "transparent",
                        }}
                      >
                        <img
                          src={whiteXIcon}
                          style={{
                            width: "10px",
                            height: "10px",
                            marginLeft: "2px",
                            marginBottom: "1px",
                          }}
                          alt=""
                        />
                      </button>
                    </div>
                  );
                })}
              </div>
            ) : (
              placeholder
            )}
          </div>
        </div>
        <img
          src={arrowDown}
          style={{ marginRight: "10px", height: "8px" }}
          alt=""
        />
      </ValueDiv>
      {isOpen && (
        <FormGroup>
          <div
            style={{
              top: "50px",
              width: "200px",
              overflow: "auto",
              maxHeight: "200px",
              position: "absolute",
              borderRadius: "8px",
              backgroundColor: "#FAFAFA",
              boxShadow: "5px 5px 10px rgba(31, 31, 31, 0.15)",
              border: `1px solid ${
                darkTheme ? COLORS.dark_grey : COLORS.primary
              }`,
              zIndex: 1000,
              ...cssDiv,
            }}
          >
            {options.map((option, index) => (
              <OptionDiv
                style={
                  options.length - 1 !== index
                    ? {
                        backgroundColor: "transparent",
                        borderBottom: "1px solid rgba(140, 140, 140, 0.4)",
                        margin: "0px 7px",
                        // backgroundColor: option === value ? "#EAEAEA" : "#FAFAFA",
                      }
                    : {
                        borderBottom: "0px",
                        margin: "0px 7px",
                        backgroundColor: "transparent",

                        // backgroundColor: option === value ? "#EAEAEA" : "#FAFAFA",
                      }
                }
                key={index}
                onClick={() => handleOptionClick(option)}
              >
                {type === "select" ? (
                  <div
                    style={{
                      padding: "8px",
                      paddingLeft: "0",
                      cursor: "pointer",
                    }}
                  >
                    {option}
                  </div>
                ) : (
                  <FormControlLabel
                    sx={{ marginBottom: "0.3rem", marginTop: "0.3rem" }}
                    control={
                      <Checkbox
                        style={{
                          color: value.includes(option)
                            ? darkTheme
                              ? COLORS.purple
                              : COLORS.primary
                            : darkTheme
                            ? COLORS.dark_grey
                            : "rgba(189, 189, 189, 1)",
                        }}
                      />
                    }
                    label={
                      <Button
                        type="link"
                        text={option}
                        css={{
                          color: darkTheme ? COLORS.dark_grey : "black",
                          fontWeight: "400",
                          padding: 0,
                        }}
                        onClick={() => handleOptionClick(option)}
                      >
                        {option}
                      </Button>
                    }
                    checked={value.includes(option)}
                  />
                )}
              </OptionDiv>
            ))}
          </div>
        </FormGroup>
      )}
    </div>
  );
}
