import React, { useCallback, useEffect, useState } from "react";

import CircularProgressWithLabel from "../../components/CircularProgressWithLabel";
import SpeedGraphChart from "../../components/SpeedGraphChart";
import ReactApexChart from "react-apexcharts";

import { styled } from "@mui/material/styles";
import { useMediaQuery, useTheme, createTheme } from "@mui/material";

import { AmountToThousands, formatValue } from "../../utils/constants";
import { COLORS } from "../../styles/themes/default/colors";

const theme1 = createTheme({
  breakpoints: {
    values: {
      xs: 0,
      mobile: 376,
      sm: 600,
      md: 900,
      lg: 1200,
      xl: 1536,
      sl: 1400,
    },
  },
});

const smDown = theme1.breakpoints.down("sm");
const mdDown = theme1.breakpoints.down("md");
const lgDown = theme1.breakpoints.down("lg");
const mobileDown = theme1.breakpoints.down("mobile");

const MainDiv = styled("div")({
  display: "flex",
  flexDirection: "column",
  flex: "0 0 49.5%",
  backgroundColor: COLORS.white,
  borderRadius: "5px",
  marginRight: "6px",
});
const MainDivRisk = styled("div")({
  display: "flex",
  flexDirection: "column",
  flex: "0 0 100%",
  backgroundColor: COLORS.white,
  borderRadius: "5px",
  marginRight: "6px",
});

const Content = styled("div")({
  display: "flex",
  flexDirection: "row",

  "div:nth-of-type(1)": {
    paddingTop: 0,
    "div:nth-of-type(1)": {
      marginTop: 0,
      marginBottom: "0px",
    },
  },

  "div:nth-of-type(2)": {},

  "div:nth-of-type(3)": {
    div: {
      color: COLORS.primary,
      marginTop: "15px",
      marginBottom: "45px",
    },
  },
});
const ContentRisk = styled("div")({
  display: "flex",
  flexDirection: "row",
  "div:nth-of-type(1)": {},

  "div:nth-of-type(2)": {
    paddingTop: 0,
    "div:nth-of-type(2)": {
      marginTop: 0,
      marginBottom: "0px",
    },
  },

  "div:nth-of-type(3)": {},

  "div:nth-of-type(4)": {
    paddingTop: 0,
    "div:nth-of-type(4)": {
      marginTop: 0,
      marginBottom: "0px",
    },
  },
});

const Graph = styled("div")({
  fontWeight: "600",
  fontSize: "24px",
  lineHeight: "29px",
  color: COLORS.black,
  "@media only screen and (max-width: 1400px) and (min-width: 1280px)": {
    fontSize: "18px",
  },
  // marginTop: "15px",
  // marginBottom: "45px",
});

const StatisticsLabel = styled("p")({
  textAlign: "center",
  fontSize: "14px",
  lineHeight: "17px",
  color: COLORS.dark_grey,
  marginBottom: "0",
  "@media only screen and (max-width: 1400px) and (min-width: 1280px)": {
    fontSize: "0.625rem",
  },
});

const DashboardStatistics = ({ statistics, active_tab }) => {
  const smallLaptop = useMediaQuery(theme1.breakpoints.down("sl"));

  const StatisticsCellRisk = styled("div")({
    display: "flex",
    flexDirection: "column",
    flex: "0 0 25%",
    textAlign: "center",
    padding: smallLaptop ? "10px" : "15px",
  });

  const StatisticsCell = styled("div")({
    display: "flex",
    flexDirection: "column",
    flex: "0 0 33%",
    textAlign: "center",
    padding: smallLaptop ? "10px" : "15px",
  });

  const [key, setKey] = useState(1);
  const valueToPercentage = (value) => {
    return (value * 100) / statistics.total_number_of_containers;
  };
  useEffect(() => {
    setKey(key + 1);
  }, [statistics]);
  const options = useCallback(
    {
      series: [
        active_tab === "risk"
          ? (statistics?.first_demurrage_statistic
              ?.total_current_demurrage_amount *
              100) /
            statistics?.first_demurrage_statistic
              ?.total_potential_demurrage_amount
          : statistics?.number_of_containers === 0 &&
            statistics?.total_number_of_containers === 0
          ? 0
          : valueToPercentage(statistics.number_of_containers),
      ],
      colors: [COLORS.primary],
      options: {
        chart: {
          height: 100,
          type: "radialBar",
          offsetY: -45,
        },
        plotOptions: {
          radialBar: {
            startAngle: -120,
            endAngle: 120,
            fillColor: COLORS.primary,
            dataLabels: {
              value: {
                offsetY: -15,
                fontSize: smallLaptop ? "18px" : "24px",
                lineHeight: "29px",
                fontWeight: "600",
                color: COLORS.black,
                fontFamily: "Montserrat",
                formatter: function (val) {
                  return active_tab === "risk"
                    ? `$${Math.round(
                        statistics?.first_demurrage_statistic?.total
                      ).toLocaleString('en-US')}`
                    : Math.round(
                        (val * statistics.total_number_of_containers) / 100
                      ).toLocaleString('en-US');
                },
              },
            },
          },
        },
        fill: {
          colors: [COLORS.primary],
          dashArray: 4,
        },
        stroke: {
          fillColor: COLORS.primary,
          dashArray: 4,
        },
      },
    },
    // setKey(key + 1),
    [statistics]
  );
  const optionsDetention = useCallback(
    {
      series: [
        active_tab === "risk"
          ? (statistics?.first_detention_statistic
              ?.total_current_detention_amount *
              100) /
            statistics?.first_detention_statistic
              ?.total_potential_detention_amount
          : statistics?.number_of_containers === 0 &&
            statistics?.total_number_of_containers === 0
          ? 0
          : valueToPercentage(statistics.number_of_containers),
      ],
      colors: [COLORS.primary],
      options: {
        chart: {
          height: 100,
          type: "radialBar",
          offsetY: -45,
        },
        plotOptions: {
          radialBar: {
            startAngle: -120,
            endAngle: 120,
            fillColor: COLORS.primary,
            dataLabels: {
              value: {
                offsetY: -15,
                fontSize: smallLaptop ? "18px" : "24px",
                lineHeight: "29px",
                fontWeight: "600",
                color: COLORS.black,
                fontFamily: "Montserrat",
                formatter: function (val) {
                  return active_tab === "risk"
                    ? `$${Math.round(
                        statistics?.first_detention_statistic?.total
                      ).toLocaleString('en-US')}`
                    : Math.round(
                        (val * statistics.total_number_of_containers) / 100
                      ).toLocaleString('en-US');
                },
              },
            },
          },
        },
        fill: {
          colors: [COLORS.primary],
          dashArray: 4,
        },
        stroke: {
          fillColor: COLORS.primary,
          dashArray: 4,
        },
      },
    },
    // setKey(key + 1),
    [statistics]
  );
  return (
    <>
      {active_tab === "risk" ? (
        <MainDivRisk>
          <ContentRisk>
            <StatisticsCellRisk style={{ order: "1" }}>
              <Graph className="speed-chart">
                <ReactApexChart
                  key={key}
                  options={options.options}
                  series={options.series}
                  type="radialBar"
                  height={smallLaptop ? 150 : 200}
                />
              </Graph>
              <StatisticsLabel>Current and At risk demurrage</StatisticsLabel>
            </StatisticsCellRisk>
            <StatisticsCellRisk
              style={{ order: "2" }}
              className="dashboard-statistisc-left-middle"
            >
              <Graph>
                <CircularProgressWithLabel
                  value={
                    statistics?.second_demurrage_statistic
                      ?.total_potential_demurrage_amount /
                    (statistics?.second_demurrage_statistic
                      ?.total_at_risk_demurrage_amount !== 0
                      ? statistics?.second_demurrage_statistic
                      ?.total_at_risk_demurrage_amount
                      : 1)
                  }
                />
              </Graph>
              <StatisticsLabel sx={{ marginTop: "10px" }}>
                Demurrage 5-day risk
              </StatisticsLabel>
            </StatisticsCellRisk>

            <StatisticsCellRisk style={{ order: "3" }}>
              <Graph className="speed-chart">
                <ReactApexChart
                  key={key}
                  options={optionsDetention.options}
                  series={optionsDetention.series}
                  type="radialBar"
                  height={smallLaptop ? 150 : 200}
                />
              </Graph>
              <StatisticsLabel>Current and At risk detention</StatisticsLabel>
            </StatisticsCellRisk>
            <StatisticsCellRisk
              style={{ order: "4" }}
              className="dashboard-statistisc-left-middle"
            >
              <Graph>
                <CircularProgressWithLabel
                  value={
                    (statistics?.second_detention_statistic
                      ?.total_at_risk_detention_amount) /
                    (statistics?.second_detention_statistic
                      ?.total_potential_detention_amount !== 0 
                      ? statistics?.second_detention_statistic
                      ?.total_potential_detention_amount
                      : 1)
                    
                  }
                />
              </Graph>
              <StatisticsLabel sx={{ marginTop: "10px" }}>
                Detention 5-day risk
              </StatisticsLabel>
            </StatisticsCellRisk>
          </ContentRisk>
        </MainDivRisk>
      ) : (
        <MainDiv>
          <Content>
            <StatisticsCell
              style={{ order: "2" }}
              className="dashboard-statistisc-left-middle"
            >
              <Graph>
                <CircularProgressWithLabel
                  value={statistics.count_percentage}
                />
              </Graph>
              <StatisticsLabel
                sx={{ marginTop: smallLaptop ? "16px" : "10px" }}
              >
                {`${
                  active_tab === "active"
                    ? "Active demurrages"
                    : "Done demmurages"
                }`}
              </StatisticsLabel>
            </StatisticsCell>
            <StatisticsCell style={{ order: "1" }}>
              <Graph className="speed-chart">
                <ReactApexChart
                  key={key}
                  options={options.options}
                  series={options.series}
                  type="radialBar"
                  height={smallLaptop ? 150 : 200}
                />
              </Graph>
              <StatisticsLabel>
                {`${
                  active_tab === "active"
                    ? "Active containers"
                    : "Done containers"
                }`}
              </StatisticsLabel>
            </StatisticsCell>
            <StatisticsCell style={{ order: "3" }}>
              <Graph>
                $ {AmountToThousands(statistics.total_demurrage_amount)}
              </Graph>
              <StatisticsLabel>Invoiced</StatisticsLabel>
            </StatisticsCell>
          </Content>
        </MainDiv>
      )}
    </>
  );
};

export default DashboardStatistics;
