import React, { useEffect, useState } from "react";
import { COLORS } from "../../../../styles/themes/default/colors";
import {
  Content,
  ContentDiv,
  ContentHeader,
  ContentSection,
  ContentTitle,
  Icon,
  InformationLabel,
  InformationValue,
  LabelDiv,
} from "../style/StyledComponents";
import EditIcon from "../components/assets/edit_icon.svg";
import { Input } from "../../../../components/Cdl/Input";
import AutoComplete from "../../../../components/Cdl/AutoComplete";
import { City } from "country-state-city";
import alertService from "../../../../services/alertService";

const EditingSection = (props) => {
  const [isEditing, setIsEditing] = useState(false);
  const [editData, setEditData] = useState({});

  const [cities, setCities] = useState(editData?.state ? [] : []);
  const linkIdsList = props.links.map((item) => {
    return item.id;
  });
  useEffect(() => {
    if (editData?.state) {
      const stateIsoCode = props.usaStates.find(
        (x) => x.name === editData.state
      ).isoCode;
      setCities(City.getCitiesOfState("US", stateIsoCode));
    }
  }, [editData?.state]);
  const createEditData = () => {
    let data = {};
    props.data.forEach((item, index) => {
      if (props.backendLabels[index].includes(",")) {
        let splitedLables = props.backendLabels[index].split(",");
        let splitedValues = item.split(",");

        data[splitedLables[0]] =
          splitedValues[0].trim() === "Unknown" ? "" : splitedValues[0].trim();

        data[splitedLables[1]] =
          splitedValues[1].trim() === "Unknown" ? "" : splitedValues[1].trim();
      } else {
        data[props.backendLabels[index]] = item;
      }
    });
    setEditData(data);
  };

  const validationData = (data) => {
    if (Object.keys(data).includes("email") && data.email) {
      if (!/^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(data.email)) {
        alertService.info("Wrong email format");
        return false;
      }
    }
    if (Object.keys(data).includes("phone_number") && data.phone_number) {
      if (!/^\+\d{1,3} \(\d{3}\) \d{3}-\d{4}$/.test(data.phone_number)) {
        alertService.info("Wrong phone number format\n +_ (___) ___-____");
        return false;
      }
    }
    return true;
  };
  const nonEditableFields = ["bussiness_name", "website"];
  return (
    <ContentDiv>
      <ContentHeader>
        <ContentTitle style={{ margin: "0" }}>{props.title}</ContentTitle>
        {!props.hideEdit &&
          (isEditing ? (
            <div className="d-flex gap8">
              <button
                className="customer_edit_button red"
                onClick={() => {
                  setIsEditing(false);
                  setEditData({});
                }}
              >
                Discard
              </button>
              <button
                onClick={() => {
                  if (!validationData(editData)) return;
                  props.updateFunction(editData);
                  setEditData({});
                  setIsEditing(false);
                }}
                className="customer_edit_button green"
              >
                Save
              </button>
            </div>
          ) : (
            <button
              className="d-flex"
              style={{ all: "unset", cursor: "pointer" }}
              onClick={() => {
                setIsEditing(true);
                createEditData();
              }}
            >
              <img
                src={EditIcon}
                alt="edit_icon"
                style={{ marginRight: "4px" }}
              />
              <p className="c-blue m0 fw600">Edit</p>
            </button>
          ))}
      </ContentHeader>
      <Content>
        <ContentSection>
          {props.data.map((item, index) => {
            return (
              <>
                <LabelDiv>
                  <Icon src={props.icons[index]} alt="label_icon" />
                  <InformationLabel>{props.labels[index]}:</InformationLabel>
                </LabelDiv>
                <>
                  {isEditing &&
                  !nonEditableFields.includes(props.backendLabels[index]) ? (
                    props.backendLabels[index].includes(",") ? (
                      <div className="d-flex gap8 mb16">
                        <AutoComplete
                          options={props.usaStates.map((item) => {
                            return item.name;
                          })}
                          search={editData.state}
                          setSearch={(value) => {
                            setEditData({
                              ...editData,
                              state: value,
                            });
                          }}
                          placeholder="State"
                          css={{ width: "250px" }}
                        />
                        <AutoComplete
                          options={cities.map((item) => item.name)}
                          search={editData.city}
                          setSearch={(value) =>
                            setEditData({
                              ...editData,
                              city: value,
                            })
                          }
                          placeholder="City"
                          css={{ width: "250px" }}
                        />
                      </div>
                    ) : (
                      <Input
                        value={editData[props.backendLabels[index]]}
                        onChange={(e) =>
                          setEditData({
                            ...editData,
                            [props.backendLabels[index]]: e.target.value,
                          })
                        }
                        css={{ marginBottom: "16px" }}
                      />
                    )
                  ) : (
                    <InformationValue>
                      {linkIdsList.includes(index) ? (
                        <a
                          href={props.links.find((x) => x.id === index).link}
                          target="_blank"
                          style={{ color: COLORS.off_black }}
                          rel="noreferrer"
                        >
                          {item}
                        </a>
                      ) : (
                        item ?? "Unknown"
                      )}
                    </InformationValue>
                  )}
                </>
              </>
            );
          })}
        </ContentSection>
      </Content>
    </ContentDiv>
  );
};

export default EditingSection;
