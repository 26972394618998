import React from "react";
import EsgTextField from "../../EsgTextFields";
import { COLORS } from "../../../../../styles/themes/default/colors";

const OffsetProjectRow = (props) => {
  return (
    <div
      className="d-flex justify-between align-center mb8 gap8"
      style={{ height: "50px" }}
    >
      <div className="d-flex gap16 align-center">
        <button
          style={{ all: "unset", cursor: "pointer" }}
          onClick={() => props.changeIsActive(props.id)}
        >
          {React.cloneElement(props.data.icon, {
            fill: props.data.isActive ? props.data.color : COLORS.dark_grey,
            style: {
              width: "24px",
              height: "24px",
              transition: "0.3s all ease-in-out",
            },
          })}
        </button>

        <p
          className={`${props.data.isActive ? "c-white " : "c-dark-grey"} m0`}
          style={{
            whiteSpace: "nowrap",
            overflow: "hidden",
            textOverflow: "ellipsis",
            width: "220px",
            transition: "0.3s all ease-in-out",
          }}
        >
          {props.data.name}
        </p>
      </div>
      <div
        className="d-flex align-center gap8"
        style={{
          pointerEvents: props.data.isActive ? "auto" : "none",
          opacity: props.data.isActive ? 1 : 0.4,
          transition: "0.3s all ease-in-out",
        }}
      >
        <EsgTextField
          error={props.redBorder}
          minValue={0}
          decimal={true}
          disabled={!props.data.isActive}
          styles={{
            width: "115px",
          }}
          value={props.data.value}
          is_function={true}
          setValue={(value) => {
            props.changeValue(props.id, value);
          }}
        />
        <p className="m0 c-dark-grey">%</p>
      </div>
    </div>
  );
};
export default OffsetProjectRow;
