import React from "react";
import { Route, Switch } from "react-router-dom";

import DemurrageDashboard from "../Dashboard/DemurrageDashboard";
import DemurrageRequest from "../../pages/Demurrage/DemurrageRequest";
import DemurrageDetails from "../../pages/Demurrage/DemurrageDetails";

const Demurrage = ({ match }) => {
  const { path } = match;

  return (
    <div
      style={{
        width: "100%",
        padding: "0px",
        backgroundColor: "#F3F3F3",
        display: "flex",
        justifyContent: "center",
      }}
    >
      <div
        className="container"
        style={{
          padding: "0px",
          margin: "0",
          width: "100%",
          maxWidth: "1920px",
        }}
      >
        <Switch>
          <Route exact path={`${path}/list`} component={DemurrageDashboard} />
          <Route path={`${path}/request`} component={DemurrageRequest} />
          <Route path={`${path}/details/:id`} component={DemurrageDetails} />
        </Switch>
      </div>
    </div>
  );
};

export default Demurrage;
