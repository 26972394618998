import React from "react";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import OffsetBadges from "./OffsetBadges";
import esgStore from "../../../../store/esgStore";

const SuggestedProjects = (props) => {
  const history = useHistory();
  const offsetProjects = esgStore((state) => state.offsetProjects);
  return (
    <div className="cart_suggested_div">
      <div className="cart_suggested_header">
        <p className="cart_header">{props.title}</p>
        <p
          className="c-blue m0 fw600"
          style={{ cursor: "pointer" }}
          onClick={() => {
            history.push("/esg/offset-projects");
          }}
        >
          See all
        </p>
      </div>
      <div
        className="cart_suggested_main_div"
        // style={{ width: `${suggestedWidth}px`, overflowX: "auto" }}
      >
        <div
          style={{
            display: "flex",
            flexGrow: 1,
            whiteSpace: "nowrap",
            gap: "16px",
            overflow: "hidden",
            // width: `${document.getElementById("esg_main_div").offsetWidth}px`,
            maxWidth: "100%",
          }}
        >
          {offsetProjects.slice(0, 5).map((item, index) => {
            return (
              <div
                key={index}
                className="offset_other_projects_card"
                style={{ width: "300px" }}
              >
                <p className="offset_other_projects_card_title">{item.name}</p>
                {/* <p
              className="offset_dark_grey_400"
              style={{ fontSize: "14px", marginBottom: "16px" }}
            >
              {itemData.summary}
            </p> */}
                <img
                  src={item.image_url}
                  alt="main_picture"
                  style={{
                    marginBottom: "8px",
                    width: "100%",
                    height: "150px",
                    objectFit: "cover",
                    borderRadius: "8px",
                  }}
                />
                <p
                  className="offset_dark_grey_400"
                  style={{
                    fontSize: "14px",
                    marginBottom: "16px",
                    overflow: "hidden",
                    width: "100%",
                    whiteSpace: "pre-wrap",
                    height:
                      "4.8em" /* (Number of lines you want visible) * (line-height) */,
                    lineHeight: "1.6em",
                  }}
                >
                  {item.summary}...
                </p>
                <div className="offset_other_projects_div">
                  <p
                    className="link_paragraph"
                    onClick={() => {
                      history.push(`/esg/offset-projects/${item}`);
                    }}
                  >
                    Read more
                  </p>
                  <div style={{ display: "flex" }}>
                    <p
                      className="offset_purple_600"
                      style={{
                        fontSize: "14px",
                        marginRight: "6px",
                      }}
                    >
                      ${parseFloat(item.price_value).toFixed(2)}
                    </p>
                    <p
                      className="offset_purple_600"
                      style={{ fontSize: "14px", textTransform: "lowercase" }}
                    >
                      {item.price_unit}
                    </p>
                  </div>
                </div>
                <OffsetBadges
                  size={"24px"}
                  gap={"8px"}
                  badges={item?.additional_info.sdg_badges}
                />
              </div>
              // <div
              //   onClick={() => {
              //     history.push(`/esg/offset-projects/${index}`);
              //   }}
              //   key={index}
              //   style={{
              //     borderRadius: "8px",
              //     width: "250px",
              //     backgroundColor: "#313131",
              //     cursor: "pointer",
              //     overflow: "hidden",
              //   }}
              // >
              //   <div
              //     style={{
              //       width: "100%",
              //       height: "110px",
              //       backgroundImage: `linear-gradient(to bottom, rgba(245, 246, 252, 0.52), rgba(10, 10, 10, 0.83)),
              //   url(${item.image_url})`,

              //       backgroundSize: "100% auto",
              //       backgroundRepeat: "no-repeat",
              //       display: "flex",
              //       alignItems: "flex-end",
              //       padding: "8px",
              //     }}
              //   >
              //     <p className="esg_white_600" style={{ fontSize: "14px" }}>
              //       {item.name}
              //     </p>
              //   </div>
              //   <div
              //     style={{
              //       padding: "16px",
              //       display: "flex",
              //       justifyContent: "space-between",
              //     }}
              //   >
              //     {/* <img src={item.organisationPicture} alt="organisation" /> */}
              //     <div
              //       style={{
              //         display: "flex",
              //         alignItems: "center",
              //         justifyContent: "space-between",
              //         width: "100%",
              //       }}
              //     >
              //       <img src={GoldStandardImage} alt="gold_standard" />
              //       <p className="esg_purple_600" style={{ fontSize: "14px" }}>
              //         ${parseFloat(item.price_value).toFixed(2)} / tCO
              //         <sub>2</sub>e
              //       </p>
              //     </div>
              //   </div>
              //   <div style={{ marginLeft: "16px", marginBottom: "16px" }}>
              //     <OffsetBadges
              //       size={"24px"}
              //       gap={"8px"}
              //       badges={item?.additional_info.sdg_badges}
              //     />
              //   </div>
              // </div>
            );
          })}
        </div>
      </div>
    </div>
  );
};

export default SuggestedProjects;
