import React from "react";
import IOSwitch from "../../../../../components/CustomIOSwitch";
import EsgTextField from "../../EsgTextFields";
import { COLORS } from "../../../../../styles/themes/default/colors";

const AlternativeFuelRow = (props) => {
  return (
    <div className="d-flex justify-between align-center mb8 ">
      <div
        className="d-flex justify-between align-center"
        style={{ width: "160px" }}
      >
        <div className="d-flex gap8 align-center">
          <div
            style={{
              width: "2px",
              height: "2px",
              backgroundColor: COLORS.dark_grey,
              borderRadius: "25%",
            }}
          />
          <p className="c-dark-grey m0">{props.data.name}</p>
        </div>
        {props.data.showOnGraph && (
          <IOSwitch
            darkTheme={true}
            isActive={props.data.isActive}
            setIsActive={() => props.changeActive(props.id)}
          />
        )}
      </div>
      <div
        className="d-flex align-center gap8"
        style={{
          pointerEvents: props.data.isActive ? "auto" : "none",
          opacity: props.data.isActive ? 1 : 0.4,
          transition: "0.3s all ease-in-out",
        }}
      >
        <EsgTextField
          setFocus={props.setFocus}
          followFocus={true}
          type="number"
          minValue={0}
          maxValue={100}
          disabled={!props.data.isActive}
          styles={{ width: "90px" }}
          value={props.data.value}
          is_function={true}
          setValue={(value) => {
            props.changeValue(props.id, value);
          }}
        />
        <p className="m0 c-dark-grey">%</p>
      </div>
      <div
        className="d-flex gap8"
        style={{ opacity: props.data.isActive ? 1 : 0.4 }}
      >
        <p className="m0 c-white" style={{ width: "70px", textAlign: "right" }}>
          {Intl.NumberFormat("en-US").format(parseInt(props.data.emission))}
        </p>
        <p className="m0 c-dark-grey">
          tC0<sub>2</sub>
        </p>
      </div>
    </div>
  );
};
export default AlternativeFuelRow;
