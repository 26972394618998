import React from "react";
import { COLORS } from "../../../../styles/themes/default/colors";
import PromoCodeIcon from "../../assets/promo_code.svg";

const PromoCode = () => {
  return (
    <div className="promo_code_box">
      <p className="promo_code_header">Promo code</p>
      <div className="promo_code_flex_box">
        <input
          placeholder=""
          style={{
            width: "70%",
            backgroundColor: COLORS.off_black,
            color: COLORS.dark_grey,
            border: "none",
            heigth: "100%",
            marginRight: "16px",
            borderRadius: "8px",
            backgroundImage: `url(${PromoCodeIcon})`,
            backgroundPosition: "right 16px center",
            backgroundRepeat: "no-repeat",
          }}
        />
        <button className="promo_code_button">Apply code</button>
      </div>
    </div>
  );
};
export default PromoCode;
