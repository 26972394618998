import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";

import accountService from "../../services/accountService";
import alertService from "../../services/alertService";

const VerifyEmail = ({ history }) => {
  const EmailStatus = {
    Verifying: "Verifying",
    Failed: "Failed",
  };

  const [emailStatus, setEmailStatus] = useState(EmailStatus.Verifying);

  useEffect(() => {
    async function verify() {
      const { token } = " queryString.parse(location.search)";

      // remove token from url to prevent http referer leakage
      // history.replace(location.pathname)

      try {
        await accountService.verifyEmail(token);
        alertService.success("Verification successful, you can now login", {
          keepAfterRouteChange: true,
        });
        history.push("login");
      } catch (error) {
        setEmailStatus(EmailStatus.Failed);
      }
    }
    verify();
  }, []);

  function getBody() {
    switch (emailStatus) {
      case EmailStatus.Verifying:
        return <div>Verifying...</div>;
      case EmailStatus.Failed:
        return (
          <div>
            Verification failed, you can also verify your account using the{" "}
            <Link to="/account/forgot-password">forgot password</Link> page.
          </div>
        );
    }
  }

  return (
    <div>
      <h3 className="card-header">Verify Email</h3>
      <div className="card-body">{getBody()}</div>
    </div>
  );
};

export default VerifyEmail;
