import React from "react";
import { COLORS } from "../../../../styles/themes/default/colors";
import LocationIcon from "../../assets/location_purple.svg";
import moment from "moment";
import { useMediaQuery, createTheme } from "@mui/material";

const TableLocation = ({ data, currentEvent, lastUpdated }) => {
  const theme1 = createTheme({
    breakpoints: {
      values: {
        sl: 1400,
      },
    },
  });

  const smallLaptop = useMediaQuery(theme1.breakpoints.down("sl"));
  return (
    <div
      style={{
        height: smallLaptop ? "40px" : "52px",
        backgroundColor: "#090909",
        display: "flex",
        alignContent: "center",
        justifyContent: "space-between",
        padding: "16px",
        borderRadius: "8px",
      }}
    >
      <div
        style={{
          display: "flex",
          alignItems: "center",
        }}
      >
        <img src={LocationIcon} alt="" />
        <p
          style={{
            margin: 0,
            fontWeight: "600",
            color: COLORS.purple,
            fontSize: smallLaptop ? "10px" : "14px",
            marginLeft: "8px",
          }}
        >
          Current Location:
        </p>
        <p
          style={{
            margin: 0,
            fontWeight: "600",
            color: COLORS.off_white,
            marginLeft: "16px",
            fontSize: smallLaptop ? "11px" : "16px",
          }}
        >
          {data?.current_city && data?.current_state
            ? `${data?.current_city}
            , ${data?.current_state}`
            : "On Water"}
        </p>
      </div>
      <div style={{ display: "flex" }}>
        <p
          style={{
            margin: 0,
            color: COLORS.dark_grey,
            fontSize: smallLaptop ? "10px" : "14px",
          }}
        >
          Last updated:
        </p>
        <p
          style={{
            margin: 0,
            color: COLORS.off_white,
            marginLeft: "8px",
            fontSize: smallLaptop ? "10px" : "14px",
          }}
        >
          {moment(lastUpdated).fromNow()}
        </p>
      </div>
    </div>
  );
};

export default TableLocation;
