import React from "react";

import { createTheme } from "@mui/material";
import { styled } from "@mui/material/styles";

import { AmountToThousands } from "../../utils/constants";
import { COLORS } from "../../styles/themes/default/colors";

const theme1 = createTheme({
  breakpoints: {
    values: {
      xs: 0,
      mobile: 376,
      sm: 600,
      md: 900,
      lg: 1200,
      xl: 1536,
    },
  },
});

const smDown = theme1.breakpoints.down("sm");
const mdDown = theme1.breakpoints.down("md");
const lgDown = theme1.breakpoints.down("lg");
const mobileDown = theme1.breakpoints.down("mobile");

const MainDiv = styled("div")({
  display: "flex",
  flexDirection: "column",
  flex: "0 0 49.5%",
  backgroundColor: COLORS.white,
  paddingLeft: "16px",
  marginLeft: "6px",
});

const Title = styled("h2")({
  fontSize: "16px",
  fontWeight: "600",
  lineHeight: "20px",
  letterSpacing: "0em",
  textAlign: "left",
  marginBottom: "16px",
});

const StatusAmount = styled("p")({
  fontSize: "24px",
  fontWeight: "600",
  lineHeight: "29px",
  textAlign: "center",
  marginTop: "15px",
  marginBottom: "45px",
  "@media only screen and (max-width: 1400px) and (min-width: 1280px)": {
    fontSize: "1.1rem",
  },
});

const StatusLabel = styled("p")({
  textAlign: "center",
  fontSize: "14px",
  lineHeight: "17px",
  color: COLORS.dark_grey,
  marginBottom: "0",
  position: "relative",
  "@media only screen and (max-width: 1400px) and (min-width: 1280px)": {
    fontSize: "0.625rem",
  },

  ":before": {
    content: '""',
    display: "inline-block",
    width: "8px",
    height: "8px",
    borderRadius: "4px",
    marginRight: "5px",
  },
});

const DashboardStatus = ({ status, statistics, isExpanded, setIsExpanded }) => {
  const StatusCell = styled("div")({
    display: "flex",
    flexDirection: "column",
    flex: status === "active" ? "0 0 25%" : "0 0 50%",
    padding: "15px",
    "@media only screen and (max-width: 1400px) and (min-width: 1280px)": {
      padding: "0px",
    },
  });

  const Content = styled("div")({
    display: "flex",
    flexDirection: "row",

    "div:nth-of-type(1)": {
      "p:nth-of-type(2)::before": {
        backgroundColor: status === "active" ? COLORS.primary : COLORS.success,
      },
    },

    "div:nth-of-type(2)": {
      borderLeft: "1px solid rgba(189, 189, 189, 0.4)",
      borderRight:
        status === "active" ? "1px solid rgba(189, 189, 189, 0.4)" : "none",
      "p:nth-of-type(2)::before": {
        backgroundColor: status === "active" ? COLORS.success : COLORS.primary,
      },
    },

    "div:nth-of-type(3)": {
      borderLeft: "1px solid rgba(189, 189, 189, 0.4)",
      borderRight: "1px solid rgba(189, 189, 189, 0.4)",
      "p:nth-of-type(2)::before": {
        backgroundColor: COLORS.twilight,
      },
    },

    "div:nth-of-type(4)": {
      "p:nth-of-type(2)::before": {
        backgroundColor: COLORS.activity,
      },
    },
  });
  return (
    <MainDiv>
      {/* <Title>{`${
        status === "active" ? "Active" : "Done"
      } demurrage status`}</Title> */}
      <Content>
        {status === "active" ? (
          <>
            <StatusCell>
              <StatusAmount>
                $ {AmountToThousands(statistics?.OP?.amount)}
              </StatusAmount>
              <StatusLabel>Opened {`(${statistics?.OP?.count})`}</StatusLabel>
            </StatusCell>
            <StatusCell>
              <StatusAmount>
                $ {AmountToThousands(statistics?.AP?.amount)}
              </StatusAmount>
              <StatusLabel>Approved {`(${statistics?.AP?.count})`}</StatusLabel>
            </StatusCell>
            <StatusCell>
              <StatusAmount>
                $ {AmountToThousands(statistics?.PA?.amount)}
              </StatusAmount>
              <StatusLabel>Cleared {`(${statistics?.PA?.count})`}</StatusLabel>
            </StatusCell>
            <StatusCell>
              <StatusAmount>
                $ {AmountToThousands(statistics?.IN?.amount)}
              </StatusAmount>
              <StatusLabel>Invoiced {`(${statistics?.IN?.count})`}</StatusLabel>
            </StatusCell>
          </>
        ) : status === "done" ? (
          <>
            <StatusCell>
              <StatusAmount>
                $ {AmountToThousands(statistics?.CO?.amount)}
              </StatusAmount>
              <StatusLabel>
                Paid Back {`(${statistics?.CO?.count})`}
              </StatusLabel>
            </StatusCell>
            <StatusCell>
              <StatusAmount>
                $ {AmountToThousands(statistics?.RE?.amount)}
              </StatusAmount>
              <StatusLabel>Rejected {`(${statistics?.RE?.count})`}</StatusLabel>
            </StatusCell>
          </>
        ) : (
          <></>
        )}
      </Content>
    </MainDiv>
  );
};

export default DashboardStatus;
