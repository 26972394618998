import React from "react";
import { Box, styled } from "@mui/material";
import { COLORS } from "../../../styles/themes/default/colors";
import { formatValue } from "../../../utils/constants";
import { createTheme, useMediaQuery } from "@mui/material";

const ContainerBox = styled(Box)(({ theme }) => ({
  textAlign: "center",
  overflowX: "none",
  // fontSize: "14px",
  // fontSize: "14px",
}));

export default function ContainerTable({ containers, totalAmount }) {
  const theme1 = createTheme({
    breakpoints: {
      values: {
        sl: 1400,
      },
    },
  });

  const smallLaptop = useMediaQuery(theme1.breakpoints.down("sl"));
  const tableCellStyle = {
    width: "14%",
    fontSize: smallLaptop ? "0.685rem" : "1rem",
  };
  return (
    <ContainerBox>
      <table className="container_info_table">
        <tr
          style={{
            height: "58px",
            fontSize: "14px",
            borderTop: "1px solid #BDBDBDCC",
          }}
        >
          <th style={{ fontWeight: 600, ...tableCellStyle }}> Container Id</th>
          <th style={{ fontWeight: 600, ...tableCellStyle }}> Type</th>
          <th style={{ fontWeight: 600, ...tableCellStyle }}>Steamship Line</th>
          <th style={{ fontWeight: 600, ...tableCellStyle }}> MBL</th>
          <th style={{ fontWeight: 600, ...tableCellStyle }}>Demurrage days</th>
          <th style={{ fontWeight: 600, ...tableCellStyle }}>Detention days</th>
          <th style={{ fontWeight: 600, ...tableCellStyle }}>Amount</th>
        </tr>
        {containers.length > 0 &&
          containers.map((row, index) => {
            return (
              <tr
                style={{
                  borderTop: "1px solid #BDBDBD33",
                  fontSize: "14px",
                  height: "58px",
                }}
              >
                <td style={tableCellStyle}>{row.container_id}</td>
                <td style={tableCellStyle}>{row.type ?? "_"}</td>
                <td style={tableCellStyle}>{row.steamship_line ?? "_"}</td>
                <td style={tableCellStyle}>{row.mbl ?? "_"}</td>
                <td style={tableCellStyle}>{row.demurrage_days ?? "_"}</td>
                <td style={tableCellStyle}>{row.detention_days ?? "_"}</td>
                <td style={tableCellStyle}>{formatValue(row?.is_in_demurrage ? row?.demurrage_amount : row?.detention_amount)}
                </td>
              </tr>
            );
          })}
        {containers.length > 0 && (
          <tr
            style={{
              fontSize: "14px",
              borderTop: "1px solid #BDBDBDCC",
              height: "49px",
            }}
          >
            <td style={tableCellStyle} />
            <td style={tableCellStyle} />
            <td style={tableCellStyle} />
            <td style={tableCellStyle} />
            <td style={tableCellStyle} />
            <td
              style={{
                textAlign: "right",
                paddingRight: "10px",
                fontWeight: "600",
              }}
            >
              Total:
            </td>
            <td style={{ color: COLORS.primary, fontWeight: "600" }}>
              {formatValue(containers?.reduce((acc, container) =>
                acc + container.is_in_demurrage
                  ? container.demurrage_amount
                  : container.detention_amount, 0))
              }
            </td>
            {/* <td style={{ width: "16px" }}> </td>
            <td style={{ width: "16px" }}> </td> */}
          </tr>
        )}
      </table>
    </ContainerBox>
  );
}
