import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { Formik, Field, Form, ErrorMessage } from "formik";
import * as Yup from "yup";

import accountService from "../../services/accountService";
import alertService from "../../services/alertService";

const ResetPassword = ({ history }) => {
  const TokenStatus = {
    Validating: "Validating",
    Valid: "Valid",
    Invalid: "Invalid",
  };

  const [token, setToken] = useState(null);
  const [tokenStatus, setTokenStatus] = useState(TokenStatus.Validating);

  useEffect(() => {
    async function resetPassword() {
      const { token } = "queryString.parse(location.search)";

      // remove token from url to prevent http referer leakage
      // history.replace(location.pathname)

      try {
        await accountService.validateResetToken(token);
        setToken(token);
        setTokenStatus(TokenStatus.Valid);
      } catch (error) {
        setTokenStatus(TokenStatus.Invalid);
      }
    }
    resetPassword();
  }, []);

  function getForm() {
    const initialValues = {
      password: "",
      confirmPassword: "",
    };

    const validationSchema = Yup.object().shape({
      password: Yup.string()
        .min(6, "Password must be at least 6 characters")
        .required("Password is required"),
      confirmPassword: Yup.string()
        .oneOf([Yup.ref("password"), null], "Passwords must match")
        .required("Confirm Password is required"),
    });

    async function onSubmit({ password, confirmPassword }, { setSubmitting }) {
      try {
        alertService.clear();
        await accountService.resetPassword({
          token,
          password,
          confirmPassword,
        });
        alertService.success("Password reset successful, you can now login", {
          keepAfterRouteChange: true,
        });
        history.push("login");
      } catch (error) {
        setSubmitting(false);
        alertService.error(error);
      }
    }

    return (
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={onSubmit}
      >
        {({ errors, touched, isSubmitting }) => (
          <Form>
            <div className="form-group">
              <label>Password</label>
              <Field
                name="password"
                type="password"
                className={
                  "form-control" +
                  (errors.password && touched.password ? " is-invalid" : "")
                }
              />
              <ErrorMessage
                name="password"
                component="div"
                className="invalid-feedback"
              />
            </div>
            <div className="form-group">
              <label>Confirm Password</label>
              <Field
                name="confirmPassword"
                type="password"
                className={
                  "form-control" +
                  (errors.confirmPassword && touched.confirmPassword
                    ? " is-invalid"
                    : "")
                }
              />
              <ErrorMessage
                name="confirmPassword"
                component="div"
                className="invalid-feedback"
              />
            </div>
            <div className="form-row">
              <div className="form-group col">
                <button
                  type="submit"
                  disabled={isSubmitting}
                  className="btn btn-primary"
                >
                  {isSubmitting && (
                    <span className="spinner-border spinner-border-sm mr-1"></span>
                  )}
                  Reset Password
                </button>
                <Link to="login" className="btn btn-link">
                  Cancel
                </Link>
              </div>
            </div>
          </Form>
        )}
      </Formik>
    );
  }

  function getBody() {
    switch (tokenStatus) {
      case TokenStatus.Valid:
        return getForm();
      case TokenStatus.Invalid:
        return (
          <div>
            Token validation failed, if the token has expired you can get a new
            one at the <Link to="/account/forgot-password">forgot password</Link>{" "}
            page.
          </div>
        );
      case TokenStatus.Validating:
        return <div>Validating token...</div>;
    }
  }

  return (
    <div>
      <h3 className="card-header">Reset Password</h3>
      <div className="card-body">{getBody()}</div>
    </div>
  );
};

export default ResetPassword;
