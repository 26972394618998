import React from "react";
import { CircularProgress } from "@mui/material";
import { COLORS } from "../styles/themes/default/colors";

export const CustomLoader = (props) => {
  return (
    <CircularProgress
      {...props}
      sx={{
        color: props.loaderColor
          ? props.loaderColor
          : props.darktheme
          ? COLORS.purple
          : "#FC420C",
      }}
    />
  );
};
