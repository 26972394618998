import React from "react";

const QuantityCell = ({ value, changeValue }) => {
  return (
    <div style={{ display: "flex", gap: "8px", alignItems: "center" }}>
      <button
        disabled={value === 1}
        className="quantity_button"
        onClick={() => {
          changeValue(value - 1);
        }}
      >
        -
      </button>
      <div className="quantity_value">{value}</div>
      <button
        className="quantity_button"
        onClick={() => {
          changeValue(value + 1);
        }}
      >
        +
      </button>
    </div>
  );
};
export default QuantityCell;
