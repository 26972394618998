import React from "react";
import DonutChart from "./Donut";
import Skeleton from "react-loading-skeleton";
import { calculateObjectPercentages } from "../utils/constants";

const TopDemurrageReasons = ({ isLoading, data }) => {

    const percentageObject = calculateObjectPercentages(data);

    return (
        <div className="statistics_template_box" style={{ width: "33%" }}>
            {isLoading ? (
                <div style={{ width: "100%", height: "100%" }}>
                    <Skeleton width="100%" height="100%" />
                </div>
            ) : (
                <>
                    <div className="chart_title_box">
                        <p className="chart_title">Top demurrage reasons</p>
                    </div>
                    <div
                        style={{
                            display: "flex",
                            flex: 1,
                            justifyContent: "center",
                            alignItems: "center",
                            height: "100%",
                            width: "100%",
                        }}
                    >
                        <DonutChart
                            isPortChart={false}
                            series={[
                                {
                                    title: "Paperwork Errors",
                                    percentage: percentageObject?.paperwork_errors,
                                    color: "#FF1DA4",
                                },
                                {
                                    title: "Delayed Documents",
                                    percentage: percentageObject?.delayed_documents,
                                    color: "#3AB7EC",
                                },
                                {
                                    title: "Cargo Inspection",
                                    percentage: percentageObject?.cargo_inspection,
                                    color: "#51DA87",
                                },
                                {
                                    title: "Receiver Unreachable",
                                    percentage: percentageObject?.receiver_unreachable,
                                    color: "#C8DF6E",
                                },
                                {
                                    title: "Other",
                                    percentage: percentageObject?.other,
                                    color: "#9F30C6",
                                },
                            ]}
                            total={data?.total}
                            isLoading={isLoading}
                        />
                    </div>
                </>
            )}
        </div>
    );
};
export default TopDemurrageReasons;
