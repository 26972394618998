import React from "react";
import DonutWithTwoValues from "../DonutWithTwoValues";
import GaugeBarStatistics from "../GaugeBarStatistics";
import { useMediaQuery, createTheme } from "@mui/material";

const OnWaterStatistics = ({ statistics }) => {
  const theme1 = createTheme({
    breakpoints: {
      values: {
        sl: 1400,
      },
    },
  });

  const smallLaptop = useMediaQuery(theme1.breakpoints.down("sl"));
  return (
    <div style={{ display: "flex", flexDirection: "column", height: "100%" }}>
      <div className="template_container"
        style={{
          flexGrow: window.innerWidth > 1920 ? 1 : 0,
        }}>
        <p className="container_statistics_title">Transshipment/Direct</p>
        <DonutWithTwoValues
          colors={["#4990E3", "#70DFEE"]}
          labels={["Transshipment", "Direct"]}
          values={[statistics?.transshipment, statistics?.direct]}
        />
      </div>
      <div
        className="template_container"
        style={{
          marginTop: "16px",
          flexGrow: 1,
        }}
      >
        <p className="container_statistics_title">Export/Import</p>
        <GaugeBarStatistics
          labels={["Export", "Import"]}
          colors={["#4990E3", "#70DFEE"]}
          values={[statistics?.export, statistics?.import]}
        />
      </div>
    </div>
  );
};

export default OnWaterStatistics;
