import React from "react";
import { Input } from "../../../../components/Cdl/Input";

import SearchIcon from "../../../Container/assets/search_icon.svg";
import { COLORS } from "../../../../styles/themes/default/colors";
import {
  useHistory,
  useLocation,
} from "react-router-dom/cjs/react-router-dom.min";

import CartIcon from "../../assets/cartIcon.svg";
import CartIconActive from "../../assets/carticonactive.svg";
import { ReactComponent as HeartIcon } from "../../assets/offset_like.svg";

import OffsetProjectsIcon from "../../assets/offsetProjectsinactive.svg";
import OffsetProjectsIconActive from "../../assets/offsetProjectsActive.svg";
import OffsetProjectIconGrey from "../../assets/offset_project_grey.svg";
import CreatorIcon from "../../../../assets/img/creator_icon.svg";

import Select from "../../../../components/Cdl/Select";
import { IconButton } from "@mui/material";
import CustomBadge from "../CustomBadge";
import useStore from "../../../../store/store";
import AutoComplete from "../../../../components/Cdl/AutoComplete";
import esgStore from "../../../../store/esgStore";

const OffsetFilters = (props) => {
  const location = useLocation();
  const history = useHistory();
  const currentUser = useStore((state) => state.currentUser);
  const customers = esgStore((state) => state.customersFilter);
  const esgCart = esgStore((state) => state.esgCart);

  return (
    <div style={{ display: "flex", justifyContent: "space-between" }}>
      <div style={{ display: "flex" }}>
        <Input
          iconStart={SearchIcon}
          placeholder="Sustainable projects"
          css={{
            width: "300px",
            backgroundColor: COLORS.off_black,
            color: COLORS.dark_grey,
            border: "none",
          }}
          darkTheme={true}
          value={props.projectSearch}
          onChange={(e) => {
            props.setProjectSearch(e.target.value);
          }}
        />
        {currentUser.user.role.startsWith("C") && (
          <AutoComplete
            iconStart={CreatorIcon}
            search={props.customerSearch}
            setSearch={props.setCustomerSearch}
            triggerOnSelectOnly={true}
            setSelectedValue={props.setSelectedCustomer}
            options={customers}
            placeholder="All customers"
            css={{
              width: "400px",
              backgroundColor: COLORS.off_black,
              border: "none",
              marginLeft: "16px",
            }}
            cssDiv={{
              width: "400px",
              backgroundColor: COLORS.off_black,
              color: COLORS.dark_grey,
              marginLeft: "16px",
            }}
            darkTheme={true}
          />
        )}
        <Select
          icon={OffsetProjectIconGrey}
          value={props.projectFilter}
          onChange={props.setProjectFilter}
          placeholder="All"
          css={{
            width: "300px",
            backgroundColor: COLORS.off_black,
            color: COLORS.dark_grey,
            border: "none",
            marginLeft: "1rem",
          }}
          cssDiv={{
            marginLeft: "16px",
            width: "300px",
            backgroundColor: COLORS.off_black,
            color: COLORS.dark_grey,
          }}
          darkTheme={true}
          options={["Purchased", "Not Purchased"]}
          closeOnSelect={true}
          type="select"
        />
        <div style={{ position: "relative" }}>
          <button
            onClick={() => {
              props.setFavorite((prev) => !prev);
            }}
            className="esg_header_button"
            style={{
              textAlign: "left",
              width: "auto",
              padding: "7px 11px",
            }}
          >
            <HeartIcon
              style={{ transition: "0.3s all ease-in-out" }}
              fill={props.favorite ? COLORS.purple : "transparent"}
            />
          </button>
          <div className="esg_hover_container">
            <div className="esg_hover_inner_container">Favorites</div>
          </div>
        </div>
        <div style={{ position: "relative" }}>
          <button
            className="esg_header_button"
            onClick={() => {
              if (location.pathname.startsWith("/esg/offset-projects")) {
                history.push("/esg");
              } else {
                history.push("/esg/offset-projects");
              }
            }}
          >
            <img
              src={
                location.pathname.startsWith("/esg/offset-projects")
                  ? OffsetProjectsIconActive
                  : OffsetProjectsIcon
              }
              alt=""
            />
          </button>
          <div className="esg_hover_container">
            <div className="esg_hover_inner_container">Offset projects</div>
          </div>
        </div>
      </div>
      <div>
        <IconButton
          style={{ padding: "0", position: "relative" }}
          onClick={() => {
            history.push("/esg/cart");
          }}
        >
          <img
            src={location.pathname === "/esg/cart" ? CartIconActive : CartIcon}
            alt="cart"
          />
          {esgCart && Object.keys(esgCart).length > 0 && (
            <CustomBadge number={Object.keys(esgCart).length} />
          )}
        </IconButton>
      </div>
    </div>
  );
};

export default OffsetFilters;
