import React from "react";
import CircularProgressWithLabel from "../../../components/CircularProgressWithLabel";
import { AmountToThousands } from "../../../utils/constants";
import ReactApexChart from "react-apexcharts";
import { createTheme, useMediaQuery } from "@mui/material";

export default function CustomerListStatistics({
  topPaidDemurrage,
  topMissingPaidDemurrage,
  activated,
  invited,
  customerLength,
}) {
  const theme1 = createTheme({
    breakpoints: {
      values: {
        sl: 1400,
      },
    },
  });
  const smallLaptop = useMediaQuery(theme1.breakpoints.down("sl"));

  const valueToPercentage = (value) => {
    return (value * 100) / customerLength;
  };
  const options = {
    series: [
      invited === 0 && customerLength === 0 ? 0 : valueToPercentage(invited),
    ],
    colors: "#EE4A26",
    options: {
      chart: {
        height: 0,
        type: "radialBar",
        offsetY: -45,
      },
      plotOptions: {
        radialBar: {
          startAngle: -120,
          endAngle: 120,
          fillColor: "#EE4A26",
          dataLabels: {
            value: {
              offsetY: -15,
              fontSize: smallLaptop ? "17px" : "24px",
              lineHeight: "29px",
              fontWeight: "600",
              color: "black",
              fontFamily: "Montserrat",
              formatter: function (val) {
                return Math.round((val * customerLength) / 100).toFixed(0);
              },
            },
          },
        },
      },
      fill: {
        colors: "#EE4A26",
        dashArray: 4,
      },
      stroke: {
        fillColor: "#EE4A26",
        dashArray: 4,
      },
    },
  };

  return (
    <div className="customerStatisticsMainDiv">
      <div style={{ display: "flex", justifyContent: "space-around" }}>
        <div className="customerStatisticsActivated">
          <CircularProgressWithLabel value={activated / 100} />
          <p className="customerStatisticsLabel">Activated</p>
        </div>
        <div style={{ width: "25%", textAlign: "center" }}>
          <div className="customerStatisticsInvited">
            <ReactApexChart
              options={options.options}
              series={options.series}
              type="radialBar"
              height={smallLaptop ? "140px" : "190px"}
            />
          </div>
          <p className="customerStatisticsLabelInvited">Invited</p>
        </div>

        <div className="customerStatisticsRevenue">
          <p className="customerStatisticsValueGreen">
            {"$ " +
              AmountToThousands(
                topPaidDemurrage?.demurrage_statistics
                  .total_paid_demurrage_amount
              )}
          </p>
          <p className="p-16">{topPaidDemurrage?.business_name}</p>
          <p
            className="customerStatisticsLabel"
            style={{ marginTop: "15px", marginBottom: "0px" }}
          >
            Top revenue
          </p>
        </div>
        <div className="customerStatisticsMissing">
          <p className="customerStatisticsValue">
            {AmountToThousands(
              topMissingPaidDemurrage?.demurrage_statistics
                .total_unpaid_demurrage_amount
            )}
          </p>
          <p className="p-16">{topMissingPaidDemurrage?.business_name}</p>
          <p
            className="customerStatisticsLabel"
            style={{ color: "#8C8C8C", marginTop: "15px", marginBottom: "0px" }}
          >
            Top missing payments
          </p>
        </div>
      </div>
    </div>
  );
}
