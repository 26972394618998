import React from "react";

import "../../styles/successfullPayment.scss";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";

import CheckIcon from "@mui/icons-material/Check";
import BillIcon from "../../assets/bill.svg";
import BluredCertificate from "../../assets/Certificate_blur.png";
import AlertIcon from "../../assets/alert_icon.svg";
import SuggestedProjects from "./SuggestedProjects";
import OrderDetails from "./OrderDetails";

const SuccessfullPayment = (props) => {
  const history = useHistory();
  return (
    <div className="successfull_payment_main_div">
      <div className="request_div">
        <p
          className="c-white fw600 m0"
          style={{ fontSize: "24px", marginBottom: "24px" }}
        >
          Request has been successfully submitted!
        </p>

        <div className="request_images_div">
          <div className="request_circle">
            <img src={BillIcon} alt="bill" />
          </div>
          <div
            className="request_line"
            style={{ backgroundColor: "#51DA88" }}
          />
          <div className="request_circle">
            {/* <CheckIcon */}
            <CheckIcon
              style={{ color: "#51DA88", width: "40px", height: "45px" }}
            />
          </div>
          <div
            className="request_line"
            style={{ borderTop: "1px dashed #F5AF45" }}
          />
          <div
            className="request_circle"
            style={{ backgroundColor: "#F5AF450d" }}
          >
            <img
              src={BluredCertificate}
              alt="bill"
              style={{ width: "64px", height: "50px" }}
            />
          </div>
        </div>
        <p className="c-dark-grey m0" style={{ marginBottom: "24px" }}>
          Once the transaction is completed you will receive a carbon offset
          certificate
        </p>
        <button
          className="explore_button"
          onClick={() => {
            history.push("/esg/offset-projects");
          }}
        >
          Explore new projects
        </button>
      </div>
      <div className="request_summary_div">
        <OrderDetails values={props.successfullPaymentValues} />
        <div className="pending_transaction_div">
          <div style={{ display: "flex", gap: "8px", alignItems: "center" }}>
            <img src={AlertIcon} alt="alert_icon" />
            <p className="pending_transaction_text">
              Your carbon offset certificate will be delivered to your email
              after the invoice payment has been received and processed.
            </p>
          </div>
        </div>
        <SuggestedProjects
          title={"Recommended projects based on your previous purchase"}
        />
      </div>
    </div>
  );
};

export default SuccessfullPayment;
