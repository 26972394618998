import React, { useEffect, useState } from "react";

import FiberManualRecordIcon from "@mui/icons-material/FiberManualRecord";
import Chart from "react-apexcharts";

const ProcurementDiversity = (props) => {
  const [series, setSeries] = useState([]);
  const [max, setMax] = useState(1);
  useEffect(() => {
    let max = Math.max(...Object.values(props.data)) * 1.2;
    let temp_series = [];

    Object.values(props.data).forEach((item) => {
      temp_series.push((item * 100) / max);
    });
    setSeries(temp_series);
    setMax(max);
  }, [props.data]);
  //   var series = ["95", "87", "35"];
  let options = {
    plotOptions: {
      radialBar: {
        hollow: {
          size: "70%",
          padding: {
            bottom: -20,
          }, // Increase or decrease the percentage to adjust the bar width
        },
        track: {
          width: "70%", // Set the width of the track as a percentage of the total radius of the radialBar
          background: "#353535",
        },
        startAngle: -90,
        endAngle: 90,
        dataLabels: {
          enabled: false,
          offsetY: -20,
          name: {
            fontSize: "22px",
          },
          value: {
            offsetY: -30,
            fontSize: "20px",
          },
        },
      },
    },
    colors: ["#FF00B8", "#00FFFF", "#00FF65"],
    stroke: {
      width: 1,
      lineCap: "round", // Make radial lines rounded
    },
    grid: {
      padding: {
        bottom: -70,
        left: -25,
        right: -25,
        top: -10,
      },
    },
  };

  return (
    <div className="procurementDiversityDiv">
      <p
        className="p-16-b-white"
        style={{
          marginBottom: 0,
        }}
      >
        Procurement Diversity
      </p>
      <Chart options={options} series={series} type="radialBar" width="100%" />
      <div className="diversityLabels">
        <div style={{ display: "flex", gap: "4px", alignItems: "center" }}>
          <FiberManualRecordIcon style={{ color: "#FF1DA4", width: "8px" }} />

          <p className="c-white m0" style={{ fontSize: "14px" }}>
            Women Owned
          </p>
          <p className="c-dark-grey m0" style={{ fontSize: "14px" }}>
            (WBE)
          </p>
        </div>
        <p className="p-14-white" style={{ margin: 0 }}>
          {((series[0] * max) / 100)?.toFixed(2)}%
        </p>
      </div>
      <div className="diversityLabels">
        <div style={{ display: "flex", gap: "4px", alignItems: "center" }}>
          <FiberManualRecordIcon style={{ color: "#00FFFF", width: "8px" }} />

          <p className="c-white m0" style={{ fontSize: "14px" }}>
            Minority Owned
          </p>
          <p className="c-dark-grey m0" style={{ fontSize: "14px" }}>
            (MBE)
          </p>
        </div>
        {/* Small Business (SBE) */}
        <p className="p-14-white" style={{ margin: 0 }}>
          {((series[1] * max) / 100)?.toFixed(2)}%
        </p>
      </div>
      <div className="diversityLabels">
        <div style={{ display: "flex", gap: "4px", alignItems: "center" }}>
          <FiberManualRecordIcon style={{ color: "#00FF65", width: "8px" }} />

          <p className="c-white m0" style={{ fontSize: "14px" }}>
            Small Business
          </p>
          <p className="c-dark-grey m0" style={{ fontSize: "14px" }}>
            (SBE)
          </p>
        </div>
        {/* Small Business (SBE) */}
        <p className="p-14-white" style={{ margin: 0 }}>
          {((series[2] * max) / 100)?.toFixed(2)}%
        </p>
      </div>
    </div>
  );
};
export default ProcurementDiversity;
