import React, { useEffect, useState } from "react";

import "../../styles/offsetprojects.scss";
import { ReactComponent as HeartIcon } from "../../assets/offset_like.svg";

import PlantIcon from "../../assets/plant_icon.svg";
import goldenStandardImage from "../../assets/goldstandrad.svg";
import EsgTextField from "../EsgTextFields";
import useStore from "../../../../store/store";
import { COLORS } from "../../../../styles/themes/default/colors";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import OffsetFilters from "./OffsetFilters";
import OffsetBadges from "./OffsetBadges";
import apiFactory from "../../../Container/service/apiFactory";
import { MCLOUD_APP_URL } from "../../../../utils/constants";
import { useQuery } from "react-query";
import Skeleton from "react-loading-skeleton";
import { toggleFavorite } from "../../utils/functions";
import esgStore from "../../../../store/esgStore";
import alertService from "../../../../services/alertService";
const OffsetProjects = (props) => {
  const history = useHistory();
  const setEsgCart = esgStore((state) => state.setEsgCart);
  const esgCart = esgStore((state) => state.esgCart);

  const offsetProjects = esgStore((state) => state.offsetProjects);

  const [projectFilter, setProjectFilter] = useState("");
  const [projectSearch, setProjectSearch] = useState("");
  const [customerSearch, setCustomerSearch] = useState("");

  const [selectedCustomer, setSelectedCustomer] = useState("");

  const [filteredOffsets, setFilteredOffsets] = useState(offsetProjects);

  const [favorite, setFavorite] = useState(false);
  const [favoritesMap, setFavoriteMap] = useState({});

  const [quantities, setQuantities] = useState(
    Array.from({ length: offsetProjects.length }, () => 1)
  );
  const setNavbarLabel = useStore((state) => state.setNavbarLabel);
  useEffect(() => {
    setNavbarLabel({
      label: "Renewable Offset Projects",
      back_link: "/esg",
    });
  });
  const setOffsetProjects = esgStore((state) => state.setOffsetProjects);

  const { isLoading } = useQuery({
    queryKey: ["offsetProjects", selectedCustomer],
    queryFn: () => {
      return apiFactory
        .get(
          `${MCLOUD_APP_URL}offset/${
            selectedCustomer ? `?shipper=${selectedCustomer}` : ""
          }`
        )
        .then((resp) => setOffsetProjects(resp.data))
        .catch((error) => alertService.error(error.response.status));
    },
    staleTime: 0,
    cacheTime: 0,
    retry: false,
  });
  useEffect(() => {
    let favorites = {};
    offsetProjects.forEach((item) => (favorites[item.id] = item.is_favorite));
    setFavoriteMap(favorites);
  }, [offsetProjects]);

  useEffect(() => {
    let data = [...offsetProjects];

    if (favorite) {
      data = data.filter((item) => favoritesMap[item.id]);
    }
    if (projectSearch) {
      data = data.filter((item) =>
        item.name.toLowerCase().includes(projectSearch.toLowerCase())
      );
    }
    if (projectFilter) {
      data = data.filter((item) =>
        projectFilter === "Purchased"
          ? item?.total_amount > 0
          : item?.total_amount === 0 || item?.total_amount === undefined
      );
    }

    setFilteredOffsets(data);
  }, [projectFilter, projectSearch, offsetProjects, favorite, favoritesMap]);

  return (
    <div>
      <OffsetFilters
        projectFilter={projectFilter}
        setProjectFilter={setProjectFilter}
        projectSearch={projectSearch}
        setProjectSearch={setProjectSearch}
        selectedCustomer={selectedCustomer}
        setSelectedCustomer={setSelectedCustomer}
        customerSearch={customerSearch}
        setCustomerSearch={setCustomerSearch}
        setFavorite={setFavorite}
        favorite={favorite}
      />
      {isLoading ? (
        <div style={{ width: "100%", display: "flex", marginTop: "16px" }}>
          <div style={{ width: "25%" }}>
            <Skeleton width="100%" height={400} count={2} />
          </div>
          <div style={{ width: "25%" }}>
            <Skeleton width="100%" height={400} count={2} />
          </div>
          <div style={{ width: "25%" }}>
            <Skeleton width="100%" height={400} count={2} />
          </div>
          <div style={{ width: "25%" }}>
            <Skeleton width="100%" height={400} count={2} />
          </div>
        </div>
      ) : filteredOffsets && filteredOffsets.length === 0 ? (
        <div
          className="w100 d-flex justify-center align-center"
          style={{ height: "250px" }}
        >
          <p className="c-purple fw600 fs22">No projects match criteria!</p>
        </div>
      ) : (
        <div className="offset_main_box">
          {filteredOffsets &&
            filteredOffsets.length > 0 &&
            filteredOffsets.map((item, index) => {
              return (
                <div key={index} className="offset_card">
                  <div className="offset_header_div">
                    <p
                      className="offset_header_title"
                      id={`header_overflow_${index}`}
                      style={{
                        whiteSpace: "nowrap",
                        textOverflow: "ellipsis",
                        overflow: "hidden",
                        width: "90%",
                      }}
                    >
                      {item.name}
                    </p>
                    {item.name && item.name.length > 45 && (
                      <div className="offset_header_title_hover">
                        <div
                          style={{
                            backgroundColor: `#ff1da433`,
                            color: COLORS.purple,
                            fontWeight: "600",
                            padding: "8px",
                            borderRadius: "8px",
                          }}
                        >
                          {item.name}
                        </div>
                      </div>
                    )}
                    <button
                      style={{ all: "unset", cursor: "pointer" }}
                      onClick={() => {
                        setFavoriteMap({
                          ...favoritesMap,
                          [item.id]: !favoritesMap[item.id],
                        });
                        toggleFavorite(item.id);
                      }}
                    >
                      <HeartIcon
                        style={{ transition: "0.3s all ease-in-out" }}
                        fill={favoritesMap[item.id] ? COLORS.purple : "none"}
                      />
                    </button>
                    {/* <img src={offsetIcon} alt="" /> */}
                  </div>
                  {/* <p
              className="offset_dark_grey_400"
              style={{ fontSize: "14px", marginBottom: "16px" }}
            >
              {item.subtitle}
            </p> */}
                  <img
                    src={goldenStandardImage}
                    alt=""
                    style={{ marginBottom: "16px" }}
                  />

                  <img
                    src={item.image_url}
                    alt=""
                    style={{
                      marginBottom: "16px",
                      width: "100%",
                      height: "250px",
                      objectFit: "cover",
                      borderRadius: "8px",
                    }}
                  />
                  <p
                    className="offset_dark_grey_400"
                    style={{
                      marginBottom: "8px",
                      borderRadius: "8px",
                      height: "70px",
                      overflow: "hidden",
                      textOverflow: "ellipsis",
                      textAlign: "justify",
                    }}
                  >
                    {item.summary}
                  </p>
                  <p
                    className="link_paragraph"
                    style={{ marginBottom: "16px" }}
                    onClick={() => {
                      history.push(`/esg/offset-projects/${index}`);
                    }}
                  >
                    Read more
                  </p>
                  <div style={{ marginBottom: "16px" }}>
                    <OffsetBadges
                      size={"24px"}
                      gap={"8px"}
                      badges={item?.additional_info.sdg_badges}
                    />
                  </div>
                  <div
                    className="value_div"
                    style={{ marginBottom: "16px", justifyContent: "flex-end" }}
                  >
                    <div style={{ display: "flex", alignItems: "flex-end" }}>
                      <p
                        className="offset_purple_600"
                        style={{
                          fontSize: "24px",
                          marginRight: "4px",
                          lineHeight: "24px",
                        }}
                      >
                        ${parseFloat(item.price_value).toFixed(2)}
                      </p>
                      <p className="offset_purple_600">
                        / tCO<sub>2</sub>e
                      </p>
                    </div>
                  </div>
                  <div className="value_div" style={{ marginBottom: "24px" }}>
                    <p className="offset_white_400">
                      Total offset tCO<sub>2</sub>e
                    </p>
                    <div style={{ display: "flex", alignItems: "center" }}>
                      <p className="offset_white_600">
                        {item?.total_amount ?? 0}
                      </p>
                      <img
                        src={PlantIcon}
                        style={{ marginLeft: "8px" }}
                        alt=""
                      />
                    </div>
                  </div>
                  <div
                    className="value_div"
                    style={{ alignItems: "center", gap: "16px" }}
                  >
                    <div
                      style={{
                        display: "flex",
                        gap: "8px",
                        width: "50%",
                        alignItems: "center",
                      }}
                    >
                      <p className="offset_dark_grey_400">Quantity:</p>
                      <EsgTextField
                        is_function={true}
                        value={quantities[index]}
                        setValue={(value) => {
                          let arr = [...quantities];
                          arr[index] = value;
                          setQuantities(arr);
                        }}
                        type="number"
                        variant="outlined"
                        size="small"
                        styles={{
                          borderRadius: "8px",
                          backgroundColor: "#313131",
                          Input: {
                            color: "#8c8c8c",
                          },
                        }}
                      />
                    </div>
                    <button
                      className="offset_purple_button"
                      onClick={() => {
                        if (!Object.keys(esgCart).includes(index.toString())) {
                          setEsgCart({
                            ...esgCart,
                            [index]: parseInt(quantities[index]),
                          });
                        }
                      }}
                    >
                      Add to cart
                    </button>
                  </div>
                </div>
              );
            })}
        </div>
      )}
    </div>
  );
};

export default OffsetProjects;
