import React, { useState } from "react";

import "../../style/history_table.scss";
import TableSwitch from "../Switch";
import DnDTable from "./DnDTable";
import OffsetTable from "./OffsetTable";
import { useQuery } from "react-query";
import fetchWrapper from "../../../../../helpers/fetch-wrapper";
import { APP_URL, MCLOUD_APP_URL } from "../../../../../utils/constants";
import apiFactory from "../../../../Container/service/apiFactory";
const HistoryTable = (props) => {
  const [dndPagination, setDndPagination] = useState({
    page: 1,
    total: 0,
    count: 10,
  });
  const [offsetPagination, setOffsetPagination] = useState({
    page: 1,
    total: 0,
    count: 30,
  });
  const [totalOffsets, setTotalOffsets] = useState(0);

  const [toggleSwitch, setToggleSwitch] = useState(false);
  const [dndData, setDndData] = useState([]);
  const [offsetData, setOffsetData] = useState([]);
  const { isLoading } = useQuery({
    queryKey: ["dndData", dndPagination.page],
    queryFn: () => {
      if (props.customerName) {
        return fetchWrapper

          .get(`${APP_URL}/dnd/dnd_history?business_name=${props.customerName}`)
          .then((res) => {
            setDndPagination({ ...dndPagination, total: res.count });
            setDndData(res.results);
          });
      }
    },
    staleTime: 0,
    cacheTime: 0,
    retry: false,
  });
  const { isLoading: isLoadingOffsets } = useQuery({
    queryKey: ["offsetData", offsetPagination.page],
    queryFn: () => {
      if (props.customerName) {
        return apiFactory
          .get(
            `${MCLOUD_APP_URL}offset/purchase_history_by_shipper/?page=${offsetPagination.page}&limit=${offsetPagination.count}&shipper_name=${props.customerName}`
          )
          .then((res) => {
            setTotalOffsets(res.data.data.total_count);
            setOffsetData(res.data.data.offsets);
          });
      }
    },

    staleTime: 0,
    cacheTime: 0,
    retry: false,
  });
  return (
    <div className="history_main_div">
      <div className="history_header_div">
        <p className="history_header_title">
          {!toggleSwitch ? "D&D Payment" : "Offset Payment"}
        </p>
        <TableSwitch
          toggleSwitch={toggleSwitch}
          setToggleSwitch={setToggleSwitch}
        />
      </div>
      <div className="history_tables_tranisition">
        <div
          className={`history_table ${!toggleSwitch ? "leftVisible" : "left"}`}
        >
          {/* Table content for left table */}
          {!toggleSwitch && (
            <DnDTable
              setDndPagination={setDndPagination}
              dndPagination={dndPagination}
              data={dndData}
              isLoading={isLoading}
            />
          )}
        </div>
        <div
          className={`history_table ${toggleSwitch ? "rightVisible" : "right"}`}
        >
          {toggleSwitch && (
            <OffsetTable
              isLoading={isLoadingOffsets}
              offsetPagination={offsetPagination}
              data={offsetData}
              setOffsetPagination={setOffsetPagination}
              totalOffsets={totalOffsets}
            />
          )}
        </div>
      </div>
    </div>
  );
};
export default HistoryTable;
