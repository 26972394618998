import React from "react";
import { Route, Switch } from "react-router-dom";

import Details from "../../pages/Profile/Details";
import Update from "../../pages/Profile/Update";
import NotificationSettings from "../../pages/Profile/NotificationSettings";

const Profile = ({ match }) => {
  const { path } = match;

  return (
    <div className="p-4" style={{ width: "100%", justifyContent: "center" }}>
      <div style={{ width: "100%", maxWidth: "1920px" }}>
        <Switch>
          <Route exact path={path} component={Details} />
          <Route path={`${path}/update`} component={Update} />
          <Route
            path={`${path}/notification/settings`}
            component={NotificationSettings}
          />
        </Switch>
      </div>
    </div>
  );
};

export default Profile;
