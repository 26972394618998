import React from "react";

import "../styles/statistics.scss";
import ColumnBarStatistics from "./ColumnBar";
import DonutChart from "./Donut";
import Skeleton from "react-loading-skeleton";

const calculateTotal = (data1, data2, data3) => {
  const combinedArray = data1?.concat(data2 ?? [], data3 ?? []);
  return combinedArray?.reduce((sum, current) => sum + current, 0);
}

const calculatePercentage = (data, totalValue) => {
  if (data) {
    const value = data.reduce((sum, current) => sum + current, 0)
    return totalValue !== 0 ? Math.round(value / totalValue * 100) : 0
  } else {
    return 0
  }
}

const PortCharts = (props) => {

  let keySuffix = props?.selectedItem?.isDem ? 'dem' : 'det';
  let dataKey = `port_info_for_top_3_${keySuffix}`;
  let data = props?.data?.[dataKey]?.[props?.selectedItem?.name];
  let total = calculateTotal(data?.fees_paid, data?.fees_avoided, data?.fees_disputed)

  return (
    <div className="statistics_template_box" style={{ width: "66%" }}>
      {props.isLoading ? (
        <div style={{ height: "250px", width: "100%" }}>
          <Skeleton width="100%" height="100%" />
        </div>
      ) : (
        <>
          <div className="multiple_area_header_box">
            <div style={{
              display: 'flex',
              alignItems: 'center',
              gap: '10px'
            }}>
              <button
                className="link_button"
                style={{ padding: 0, zIndex: 9999 }}
                onClick={() => {
                  props?.setSelectedItem("")
                }}
              >
                {"< Back"}
              </button>
              <p className="chart_title">
                {props?.selectedItem?.name}
              </p>
            </div>
          </div>
          <div className="bussiest_port_main_box">
            <ColumnBarStatistics data={data} />
            <div style={{
              marginTop: window.innerWidth > 1920 ? 40 : 10
            }}>
              <DonutChart
                isPortChart={true}
                series={
                  [
                    {
                      title: "Fees paid",
                      percentage: calculatePercentage(data?.fees_paid, total),
                      color: "#FF1DA4"
                    },
                    {
                      title: "Fees saved",
                      percentage: calculatePercentage(data?.fees_avoided, total),
                      color: "#51DA87",
                    },
                    {
                      title: "Fees disputed",
                      percentage: calculatePercentage(data?.fees_disputed, total),
                      color: "#3AB7EC",
                    },
                  ]
                }
                total={total}
              />
            </div>
          </div>
        </>
      )}
    </div>
  );
};

export default PortCharts;
