import React from 'react';
import { styled } from "@mui/material";
import SuccessImg from "../../assets/img/success.svg";
import ErrorImg from "../../assets/img/Error.svg";
import { COLORS } from "../../styles/themes/default/colors";
import CloseIcon from "../../assets/img/closeModalIcon.png"

import "../../pages/Account/styles/loginAndRegister.scss";

const InputFieldLight = styled("input")((darkTheme) => ({
  borderRadius: "8px",
  border: "1px solid red",
  fontFamily: "Montserrat !important",
  height: "2.625rem",
  "@media only screen and (max-width: 1400px) and (min-width: 1280px)": {
    height: "1.875rem",
    fontSize: "0.6875rem",
  },
  "&:hover": {
    border: "1px solid rgba(238, 74, 38, 0.2)",
  },
  "&:focus": {
    border: "1px solid #EE4A26",
    outline: "none",
  },
}));

const InputFieldDark = styled("input")((darkTheme) => ({
  color: COLORS.dark_grey,
  borderRadius: "8px",
  border: "1px solid red",
  fontFamily: "Montserrat !important",
  height: "2.625rem",
  "@media only screen and (max-width: 1400px) and (min-width: 1280px)": {
    // height: "1.875rem",
    // fontSize: "0.6875rem",
  },
  "&:hover": {
    border: `1px solid ${COLORS.purple}33`,
  },
  "&:focus": {
    border: `1px solid ${COLORS.purple}`,
    outline: "none",
  },
}));

const processCssProps = (cssProps) => {
  const marginCss = {};
  const inputCss = {};

  const marginProps = ['margin', 'marginTop', 'marginRight', 'marginBottom', 'marginLeft'];

  Object.entries(cssProps).forEach(([key, value]) => {
    if (marginProps.includes(key)) {
      marginCss[key] = value;
    } else {
      inputCss[key] = value;
    }
  });

  return { marginCss, inputCss };
};

export const Input = ({
  placeholder,
  disabled,
  iconStart,
  iconEnd,
  message,
  messageType,
  onChange,
  css = {},
  value,
  onClick,
  darkTheme,
  register,
}) => {

  const customCss = processCssProps(css)

  const clearInput = () => {
    if (onChange) {
      onChange({ target: { value: '' } });
    }
  };

  let sx = {
    border:
      messageType === "success"
        ? "1px solid #51DA88"
        : messageType === "error"
          ? "1px solid #DA5151"
          : "1px solid rgba(189, 189, 189, 0.4)",
    backgroundImage: `url(${iconStart}), url(${iconEnd})`,
    backgroundPosition: "left 16px center, right 16px center",
    backgroundRepeat: "no-repeat",
    backgroundColor: "#FAFAFA",
    paddingLeft: iconStart ? "37px" : "6px",
    paddingRight: iconEnd ? "50px" : "26px",
    ...customCss.inputCss,
  };
  return (
    <div className="pos-rel" style={customCss.marginCss}>
      {darkTheme ? (
        <InputFieldDark
          type="text"
          placeholder={placeholder}
          disabled={disabled}
          onClick={onClick}
          onChange={onChange}
          sx={sx}
          value={value}
          darkTheme={darkTheme}
        />
      ) : (
        <InputFieldLight
          type="text"
          placeholder={placeholder}
          disabled={disabled}
          onClick={onClick}
          onChange={onChange}
          sx={sx}
          value={value}
          darkTheme={darkTheme}
          {...register}
        />
      )}
      {value && (
        <img
          src={CloseIcon}
          aria-label="clear"
          onClick={clearInput}
          style={{
            height: "10px",
            width: "10px",
            position: 'absolute',
            right: iconEnd ? "36px" : '12px',
            top: '50%',
            transform: 'translateY(-40%)',
          }}
        />
      )}
      <p
        className="inputErrorMsg"
        style={{
          color:
            messageType === "success"
              ? "#51DA88"
              : messageType === "error"
                ? "#DA5151"
                : "none",
        }}
      >
        {messageType && (
          <img
            src={messageType === "success" ? SuccessImg : ErrorImg}
            className="inputErrorIcon"
          />
        )}
        {message}
      </p>
    </div>
  );
};
