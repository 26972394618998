import React from "react";
import DonutBarStatistics from "../DonutChart";
import ColumnBarStatistics from "../ColumnBarStatistics";

const AtPortStatistics = ({ statistics, setChartRiskFilter, setRiskFilter, riskFilter, setRedirect }) => {
  return (
    <div style={{ display: "flex", flexDirection: "column", height: "100%" }}>
      <div className="template_container" style={{ height: "auto" }}>
        <div
          style={{
            display: "flex",
            width: "100%",
            justifyContent: "space-between",
            alignItems: "flex-start",
          }}
        >
          <p className="container_statistics_title">
            Demurrage
          </p>
          {riskFilter && (
            <button
              className="link_button"
              style={{ padding: 0, zIndex: 9999 }}
              onClick={() => {
                setChartRiskFilter("");
                setRiskFilter("");
              }}
            >
              Reset
            </button>
          )}
        </div>
        <div className="container_statistics_graph">
          <DonutBarStatistics
            colors={["#3D98CB", "#29E1CB", "#8C8C8C"]}
            statistics={statistics?.demurrage_risk}
            setChartRiskFilter={setChartRiskFilter}
            setRiskFilter={setRiskFilter}
            riskFilter={riskFilter}
            setRedirect={setRedirect}
          />
        </div>
      </div>
      <div
        className="template_container"
        style={{ marginTop: "16px", flexGrow: 1 }}
      >
        <div className="div-switch-title">
          <p className="container_statistics_title">
            Top 3 terminals by Demurrage
          </p>

          {/* <SwichButton /> */}
        </div>
        <ColumnBarStatistics
          colors={["#2A53BD", "#3D98CB", "#29E1C0"]}
          statistics={statistics?.top_demurrage_fees}
        />
      </div>
    </div>
  );
};

export default AtPortStatistics;
