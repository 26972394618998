import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import { Dialog, Button, createTheme, useMediaQuery } from "@mui/material";
import { Button as ButtonCDL } from "../../../components/Cdl/Button";
import CssTextField from "../../../components/StyledTextField";

import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";

import closeModalIcon from "../../../assets/img/closeModalIcon.png";
import successApprovedIcon from "../../../assets/img/successApprovedIcon.png";
import rejectedIcon from "../../../assets/img/rejectedIcon.png";

import FiberManualRecordSharpIcon from "@mui/icons-material/FiberManualRecordSharp";

import "../styles/guestApprovalModal.scss";

import axios from "axios";
import { APP_URL } from "../../../utils/constants";

import useStore from "../../../store/store";
import accountService from "../../../services/accountService";
import alertService from "../../../services/alertService";

const theme1 = createTheme({
  breakpoints: {
    values: {
      xs: 0,
      smallMobile: 321,
      mobile: 376,
      middleMobile: 407,
      sm: 600,
      md: 900,
      lg: 1200,
      xl: 1536,
    },
  },
});

export default function GuestConfirmPassword({
  openConfirmPassword,
  setOpenConfirmPassword,
  token,
  id,
  status,
  demurrage,
  setDemurrage,
  user,
}) {
  const history = useHistory();

  const mdUp = useMediaQuery(theme1.breakpoints.up("md"));

  const setLoggedUser = useStore((state) => state.setUser);

  const [password, setPassword] = useState("");
  const [isVisiblePassword, setIsVisiblePassword] = useState(false);

  const [loader, setLoader] = useState(false);
  const [actionSuccess, setActionSuccess] = useState(false);

  const handleApproveReject = () => {
    setLoader(true);
    axios
      .patch(
        `${APP_URL}/dnd/guest/approve/${id}/`,
        { status, password },
        {
          headers: { Authorization: `${token}` },
        }
      )
      .then((resp) => {
        axios
          .get(`${APP_URL}/dnd/guest/${id}/`, {
            headers: { Authorization: `${token}` },
          })
          .then((response) => setDemurrage(response.data));
        setActionSuccess(true);
        setLoader(false);
      })
      .catch((error) => {
        alertService.error(error.response.status, error.response.data.message);
        setLoader(false);
      });
  };

  async function redirectToPlatform() {
    const loggedUser = await accountService.login(user?.email, password);
    setLoggedUser(loggedUser);
    history.push("/");
  }

  return (
    <Dialog
      open={openConfirmPassword}
      onClose={() => {
        setActionSuccess(false);
        setOpenConfirmPassword(false);
      }}
    >
      <div className="modalMainDiv">
        <div className="modalDivHeader">
          <p className="titleModalText">
            {actionSuccess
              ? demurrage.status === "AP"
                ? "Demurrage approved"
                : "Demurrage rejected"
              : "Enter password"}
          </p>
          <img
            src={closeModalIcon}
            className="closeModalImg"
            onClick={() => {
              setActionSuccess(false);
              setOpenConfirmPassword(false);
            }}
          />
        </div>
        <div>
          {actionSuccess ? (
            <div
              className={
                demurrage.status === "AP"
                  ? "demurrageApproveModalDiv"
                  : "demurrageRejectedModalDiv"
              }
            >
              <p
                className={
                  demurrage.status === "AP"
                    ? "succesApprovalModalMessage"
                    : "rejectedApprocalModalMessage"
                }
              >
                <img
                  src={
                    demurrage.status === "AP"
                      ? successApprovedIcon
                      : rejectedIcon
                  }
                  className="successIcon"
                />
                {demurrage.status === "AP" ? "Demurrage approved" : "Denied"}
              </p>
              <p className="demurrageIdModal">
                <FiberManualRecordSharpIcon
                  sx={{ width: "8px", marginRight: "5px", marginBottom: "2px" }}
                />
                Demurrage ID: <b>#{demurrage?.demurrage_id}</b>
              </p>
            </div>
          ) : (
            <>
              <p className="modalText">
                This action will confirm your decision.
              </p>
              <CssTextField
                onChange={(e) => {
                  setPassword(e.target.value);
                }}
                placeholder="Enter password here"
                type={isVisiblePassword ? "text" : "password"}
                style={{ width: "100%" }}
                InputProps={{
                  style: {
                    fontSize: "15px",
                    fontFamily: "Montserrat",
                  },
                  endAdornment: (
                    <Button
                      style={{ outline: "none" }}
                      onClick={() => setIsVisiblePassword(!isVisiblePassword)}
                    >
                      {isVisiblePassword ? (
                        <VisibilityIcon style={{ color: "black" }} />
                      ) : (
                        <VisibilityOffIcon style={{ color: "black" }} />
                      )}
                    </Button>
                  ),
                }}
              />
            </>
          )}
        </div>
        <div className="buttonsDiv">
          {actionSuccess ? (
            <>
              <ButtonCDL
                text="Go to Home"
                type="primary"
                css={{ width: "120px", height: "42px", marginRight: "10px" }}
                onClick={() => redirectToPlatform()}
              />
              <ButtonCDL
                text="Close"
                type="link"
                css={{ width: "100px", height: "42px", marginRight: "10px" }}
                onClick={() => {
                  setOpenConfirmPassword(false);
                  setActionSuccess(false);
                }}
              />
            </>
          ) : (
            <>
              <ButtonCDL
                text="Confirm"
                type="primary"
                css={{ width: "100px", height: "42px", marginRight: "10px" }}
                onClick={() => {
                  handleApproveReject();
                }}
              />
              <ButtonCDL
                text="Cancel"
                type="link"
                css={{ width: "100px", height: "42px" }}
                onClick={() => {
                  setOpenConfirmPassword(false);
                  setActionSuccess(false);
                }}
              />
            </>
          )}
        </div>
      </div>
    </Dialog>
  );
}
