import React, { useState } from "react";

import "../styles/statistics.scss";
import ReactApexChart from "react-apexcharts";
import Skeleton from "react-loading-skeleton";
import { formatValue } from "../../../utils/constants";
import BlackSwitch from "./BlackSwitch";
import { formatMonths } from "../utils/constants";

const SingleLineChart = (props) => {
  const [demDetSwitch, setDemDetSwitch] = useState(false);
  let series = [
    {
      name: "",
      data: demDetSwitch
        ? props?.series?.detention_per_month &&
          props?.series?.detention_per_month.length > 0
          ? props.series.detention_per_month.map((item) => {
              return item / 1000;
            })
          : []
        : props?.series?.demurrage_per_month &&
          props?.series?.demurrage_per_month?.length > 0
        ? props.series.demurrage_per_month.map((item) => {
            return item / 1000;
          })
        : [],
    },
  ];
  let options = {
    colors: [props.color],
    chart: {
      type: "area",
      height: 350,
      zoom: {
        enabled: false,
      },
      toolbar: {
        show: false,
      },
    },
    fill: {
      type: "gradient",
      gradient: {
        shadeIntensity: 0,
        opacityFrom: 0.6,
        opacityTo: 0.1,
        stops: [0, 100],
      },
    },
    grid: { show: true, strokeDashArray: 5 },
    dataLabels: {
      enabled: false,
    },
    stroke: {
      curve: "straight",
      width: 1,
    },
    labels: formatMonths(props?.series?.months),
    xaxis: {
      type: "value",
      labels: {
        style: {
          fontFamily: "Montserrat",
          colors: "#adadad",
          fontSize: "12px",
          fontWeight: 600,
        },
      },
      axisBorder: {
        show: true,
        color: "#f9f9f9",
        height: 2,
        width: "100%",
      },
      axisTicks: {
        show: true,
        borderType: "solid",
        color: "#f9f9f9",
        height: 0,
      },
    },
    yaxis: {
      labels: {
        formatter: function (value) {
          return `$${value?.toFixed(1)}k`;
        },
        style: {
          fontFamily: "Montserrat",
          colors: "#adadad",
          fontWeight: 600,
          fontSize: "12px",
        },
      },
      axisBorder: {
        show: true,
        color: "#f9f9f9",
        width: 2,
        height: "100%",
      },
    },
    menu: {
      show: false,
    },
    legend: {
      horizontalAlign: "left",
    },
  };
  return (
    <div className="single_line_chart_box">
      {props.isLoading ? (
        <div style={{ width: "100%", height: "100%" }}>
          <Skeleton width="100%" height="100%" />
        </div>
      ) : (
        <>
          <div className="chart_title_box">
            <div
              style={{ backgroundColor: props.color }}
              className="chart_dot"
            />
            <div
              style={{
                width: "100%",
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                }}
              >
                <p className="chart_title">{props?.title}</p>
                <p className="chart_subtitle">{props?.subtitle}</p>
              </div>
              <BlackSwitch
                setDemDetSwitch={setDemDetSwitch}
                demDetSwitch={demDetSwitch}
              />
            </div>
          </div>

          <div className="single_line_chart_and_value">
            <ReactApexChart
              type="area"
              options={options}
              series={series}
              height={window.innerWidth > 1920 ? 180 : 170}
              style={{ width: window.innerWidth > 1920 ? "100%" : "95%" }}
            />
          </div>
          <div className="single_line_chart_value">
            <p className="chart_24px">
              {formatValue(
                demDetSwitch
                  ? props?.series?.detention_per_month &&
                    props?.series?.detention_per_month?.length > 0
                    ? props.series.detention_per_month.reduce((acc, curr) => {
                        return acc + curr;
                      }, 0)
                    : 0
                  : props?.series?.demurrage_per_month &&
                    props?.series?.demurrage_per_month?.length > 0
                  ? props.series.demurrage_per_month.reduce((acc, curr) => {
                      return acc + curr;
                    }, 0)
                  : 0
              )}
            </p>
          </div>
        </>
      )}
    </div>
  );
};

export default SingleLineChart;
