import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import useStore from "../../store/store";

import alertService from "../../services/alertService";
import fetchWrapper from "../../helpers/fetch-wrapper";
import { APP_URL } from "../../utils/constants";

import { Input } from "../../components/Cdl/Input";
import { Button } from "../../components/Cdl/Button";
import AutoComplete from "../../components/Cdl/AutoComplete";
import Select from "../../components/Cdl/Select";

import { useMediaQuery, createTheme, Checkbox } from "@mui/material";

import GeneralInfoIcon from "../../assets/img/generalInfoIcon.svg";
import PointOfContactIcon from "../../assets/img/pointOfContactIcon.svg";
import ContractIcon from "../../assets/img/contractIcon.svg";
import UploadIcon from "../../assets/img/uploadIcon.svg";
import usStates from "us-state-codes";
import { industryList } from "../../utils/constants";
import InputMask from "react-input-mask";
import { COLORS } from "../../styles/themes/default/colors";

export default function AddCustomer() {
  const history = useHistory();
  const [companyName, setCompanyName] = useState("");
  const [authority_approval, setAuthority_approval] = useState();
  const [otherCompanyInfo, setOtherCompanyInfo] = useState({
    business_email: "",
    address: "",
    business_phone: "",
    state: "",
    federalID: "",
    city: "",
    zipCode: "",
    industry: "",
    repayment_days: "14",
    interest: "3",
  });
  const [adminData, setAdminData] = useState({
    first_name: "",
    last_name: "",
    email: "",
    phone_number: "",
  });
  const [companyNameOptions, setCompanyNameOptions] = useState([]);

  const [invite, setInvite] = useState(false);
  const [activate, setActivate] = useState(false);
  const [documents, setDocuments] = useState({});
  const [mcleodData, setMcleodData] = useState([]);
  const [usaStates, setUsaStates] = useState([]);
  const [usaState, setUsaState] = useState("");
  const [cities, setCities] = useState([]);
  const [city, setCity] = useState("");
  const [industry, setIndustry] = useState("");
  const [disable, setDisable] = useState({
    address: false,
    state: false,
    city: false,
    zip_code: false,
  });

  const theme1 = createTheme({
    breakpoints: {
      values: {
        sl: 1400,
      },
    },
  });
  const smallLaptop = useMediaQuery(theme1.breakpoints.down("sl"));

  const setNavbarLabel = useStore((state) => state.setNavbarLabel);

  useEffect(() => {
    setNavbarLabel({
      back_link: "/customers",
    });
  }, []);

  useEffect(() => {
    fetchWrapper.get(`${APP_URL}/mcleod/customers/`).then((resp) => {
      setMcleodData(resp);

      const names = [];
      for (let i = 0; i < resp.length; i++) {
        names.push(resp[i].business_name);
      }
      setCompanyNameOptions(names);
    });
    fetchWrapper.get(`${APP_URL}/shipper/states/`).then((response) => {
      setUsaStates(response);
    });
  }, []);

  useEffect(() => {
    if (mcleodData) {
      setDisable({
        city: false,
        state: false,
        zip_code: false,
        address: false,
      });
      const company = mcleodData.find(
        (item) => item.business_name === companyName
      );
      if (company) {
        setOtherCompanyInfo({
          ...otherCompanyInfo,
          zipCode: company.zip_code ?? "",
          address: company.address1 ?? "",
        });
        setCity(company.city ?? "");
        setUsaState(usStates.getStateNameByStateCode(company.state_id) ?? "");

        setDisable({
          city: company.city !== null,
          state: company.state_id !== null,
          zip_code: company.zip_code !== null,
          address: company.address1 !== null,
        });
      } else {
        setOtherCompanyInfo({
          ...otherCompanyInfo,
          zipCode: "",
          address: "",
        });
        setCity("");
        setUsaState("");
      }
    }
  }, [companyName]);
  useEffect(() => {
    const city_results = usaStates.find((item) => item.name === usaState);

    if (city_results) {
      setCities(city_results.cities);
    } else {
      setCities([]);
    }
  }, [usaState]);

  const returnError = () => {
    let error = "";

    if (companyName === "") {
      error += "Company name is required.\n";
    }
    if (
      otherCompanyInfo.business_email !== "" &&
      !/^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(
        otherCompanyInfo.business_email
      )
    ) {
      error += "Invalid email format\n";
    }
    if (otherCompanyInfo.repayment_days === "") {
      error += "Number of repayment days is required\n";
    }
    if (
      otherCompanyInfo?.business_phone.includes("_") &&
      otherCompanyInfo?.business_phone !== "+1 (___) ___-____"
    ) {
      error += "Invalid phone number format\n";
    }
    if (adminData.first_name === "") {
      error += "Name of point of contact is required\n";
    }
    if (adminData.last_name === "") {
      error += "Last Name of point of contact is required\n";
    }
    if (
      adminData.phone_number.includes("_") &&
      adminData.phone_number !== "+1 (___) ___-____"
    ) {
      error += "Invalid phone number format\n";
    }
    if (
      adminData.email !== "" &&
      !/^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(adminData.email)
    ) {
      error += "Invalid email format\n";
    }
    return error;
  };

  const handleAddCustomer = () => {
    const mcleodId = mcleodData?.find(
      (item) => item.business_name === companyName
    )?.id;

    let error = returnError();
    if (error !== "") {
      alertService.error(1, error);
      return;
    }

    const approval = authority_approval === "Customer" ? "CUS" : "CDL";

    const data = {
      contact: {
        first_name: adminData.first_name,
        last_name: adminData.last_name,
        email: adminData.email,
        phone_number: adminData.phone_number,
      },
      company: {
        mcleod_id: mcleodId,
        business_name: companyName,
        business_email: otherCompanyInfo.business_email,
        business_phone: otherCompanyInfo.business_phone,
        federal_tax_id_number: otherCompanyInfo.federalID,
        industry: industry,
        address: otherCompanyInfo.address,
        city: city,
        zipCode: otherCompanyInfo.zipCode,
        state: usaState,
        repayment_days: otherCompanyInfo.repayment_days,
        authority_approval: approval,
        interest_percentage: otherCompanyInfo.interest,
      },
      activate_company: activate,
      send_invite_mail: invite,
    };
    fetchWrapper
      .post(`${APP_URL}/shipper/`, data)
      .then((resp) => {
        if (documents?.contract) {
          const newFormData = new FormData();
          newFormData.append("contract", documents?.contract);
          newFormData.append("company", resp.id);
          fetchWrapper
            .post(`${APP_URL}/shipper/add_contract/`, newFormData)
            .then(() => {
              alertService.success("Customer succesfully added");
              history.push("/customers");
            });
        } else {
          alertService.success("Customer succesfully added");
          history.push("/customers");
        }
      })
      .catch((error) => {
        let message = "";
        for (const [key, value] of Object.entries(error.response.data)) {
          message = value;
        }
        alertService.error(error.response.status, message);
      });
  };

  const handleFileAdd = (event) => {
    const files = event.target.files;
    const doc = {
      contract: files[0],
    };
    setDocuments(doc);
  };

  return (
    <div style={{ width: "100%" }}>
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          marginBottom: smallLaptop ? "14px" : "20px",
        }}
      >
        <p className="p-16">Fields with * are mandatory</p>
        <Button
          text="Add Customer"
          type="primary"
          css={{ width: smallLaptop ? "105px" : "150px" }}
          onClick={handleAddCustomer}
        />
      </div>
      <div
        style={{
          display: "flex",
          width: "100%",
          justifyContent: "space-between",
        }}
      >
        <div
          style={{
            width: "48%",
            background: "white",
            padding: smallLaptop ? "14px" : "20px",
            borderRadius: "8px",
          }}
        >
          <p className="p-16-b">
            <img
              src={GeneralInfoIcon}
              style={{
                marginRight: "10px",
                height: smallLaptop ? "11px" : "17px",
              }}
              alt=""
            />
            General information
          </p>
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              marginBottom: "10px",
            }}
          >
            <AutoComplete
              placeholder="Company name *"
              options={companyNameOptions}
              search={companyName}
              setSearch={setCompanyName}
              css={{ width: smallLaptop ? "230px" : "350px" }}
            />
            <Input
              value={otherCompanyInfo.business_email}
              onChange={(e) =>
                setOtherCompanyInfo({
                  ...otherCompanyInfo,
                  business_email: e.target.value,
                })
              }
              placeholder="Company email"
              css={{ width: smallLaptop ? "230px" : "350px" }}
            />
          </div>
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              marginBottom: "10px",
            }}
          >
            <Input
              value={otherCompanyInfo.address}
              disabled={disable.address}
              onChange={(e) =>
                setOtherCompanyInfo({
                  ...otherCompanyInfo,
                  address: e.target.value,
                })
              }
              placeholder="Company address"
              css={{ width: smallLaptop ? "230px" : "350px" }}
            />
            <InputMask
              mask="+1 (999) 999-9999"
              placeholder="Company phone"
              value={otherCompanyInfo.business_phone}
              onChange={(e) =>
                setOtherCompanyInfo({
                  ...otherCompanyInfo,
                  business_phone: e.target.value,
                })
              }
            >
              <Input css={{ width: smallLaptop ? "230px" : "350px" }} />
            </InputMask>
          </div>
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              marginBottom: "10px",
            }}
          >
            <AutoComplete
              disabled={disable.state}
              options={usaStates.map((item) => {
                return item.name;
              })}
              search={usaState}
              setSearch={setUsaState}
              placeholder="State"
              css={{ width: smallLaptop ? "230px" : "350px" }}
            />
            <Input
              value={otherCompanyInfo.federalID}
              onChange={(e) =>
                setOtherCompanyInfo({
                  ...otherCompanyInfo,
                  federalID: e.target.value,
                })
              }
              placeholder="Federal tax ID number"
              css={{ width: smallLaptop ? "230px" : "350px" }}
            />
          </div>
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              marginBottom: "10px",
            }}
          >
            <div style={{ display: "flex" }}>
              <AutoComplete
                disabled={disable.city}
                search={city}
                setSearch={setCity}
                options={cities.map((item) => {
                  return item.name;
                })}
                placeholder="City"
                css={{
                  width: smallLaptop ? "105px" : "165px",
                  marginRight: "20px",
                }}
              />
              <Input
                disabled={disable.zip_code}
                value={otherCompanyInfo.zipCode}
                onChange={(e) =>
                  setOtherCompanyInfo({
                    ...otherCompanyInfo,
                    zipCode: e.target.value,
                  })
                }
                placeholder="Zip code"
                css={{ width: smallLaptop ? "105px" : "165px" }}
              />
            </div>
            <AutoComplete
              search={industry}
              setSearch={setIndustry}
              options={industryList}
              placeholder="Industry"
              css={{ width: smallLaptop ? "230px" : "350px" }}
            />
          </div>
        </div>
        <div
          style={{
            width: "24%",
            background: "white",
            padding: smallLaptop ? "14px" : "20px",
            borderRadius: "8px",
          }}
        >
          <p className="p-16-b">
            <img
              src={PointOfContactIcon}
              style={{
                marginRight: "10px",
                height: smallLaptop ? "11px" : "16px",
              }}
              alt=""
            />
            Point of contact
          </p>
          <div>
            <Input
              placeholder="First name *"
              css={{
                marginBottom: "10px",
                width: smallLaptop ? "230px" : "350px",
              }}
              value={adminData.first_name}
              onChange={(e) => {
                setAdminData({
                  ...adminData,
                  first_name: e.target.value,
                });
              }}
            />
            <Input
              placeholder="Last name *"
              css={{
                marginBottom: "10px",
                width: smallLaptop ? "230px" : "350px",
              }}
              value={adminData.last_name}
              onChange={(e) => {
                setAdminData({
                  ...adminData,
                  last_name: e.target.value,
                });
              }}
            />
            <Input
              placeholder="Email *"
              css={{
                marginBottom: "10px",
                width: smallLaptop ? "230px" : "350px",
              }}
              value={adminData.email}
              onChange={(e) => {
                setAdminData({
                  ...adminData,
                  email: e.target.value,
                });
              }}
            />
            <InputMask
              mask="+1 (999) 999-9999"
              placeholder="Phone number *"
              value={adminData.phone_number}
              onChange={(e) => {
                setAdminData({
                  ...adminData,
                  phone_number: e.target.value,
                });
              }}
            >
              <Input css={{ width: smallLaptop ? "230px" : "350px" }} />
            </InputMask>
          </div>
        </div>
        <div
          style={{
            width: "24%",
            background: "white",
            padding: smallLaptop ? "14px" : "20px",
            borderRadius: "8px",
          }}
        >
          <p className="p-16-b">
            <img
              src={ContractIcon}
              style={{
                marginRight: "10px",
                height: smallLaptop ? "12px" : "17px",
              }}
              alt=""
            />
            Contract information
          </p>
          <div>
            <Select
              placeholder="Approval authority *"
              css={{
                marginBottom: "10px",
                width: smallLaptop ? "230px" : "350px",
              }}
              cssDiv={{ width: smallLaptop ? "230px" : "350px" }}
              value={authority_approval}
              onChange={setAuthority_approval}
              type="select"
              options={["Customer", "CDL 1000"]}
            />
            <Input
              placeholder="Interest *"
              css={{
                marginBottom: "10px",
                width: smallLaptop ? "230px" : "350px",
              }}
              value={otherCompanyInfo.interest}
              onChange={(e) => {
                setOtherCompanyInfo({
                  ...otherCompanyInfo,
                  interest: e.target.value,
                });
              }}
            />
            <Input
              placeholder="Repayment days *"
              css={{
                marginBottom: "10px",
                width: smallLaptop ? "230px" : "350px",
              }}
              value={otherCompanyInfo.repayment_days}
              onChange={(e) => {
                setOtherCompanyInfo({
                  ...otherCompanyInfo,
                  repayment_days: e.target.value,
                });
              }}
            />
            <div
              style={{
                borderRadius: "8px",
                border: "1px dashed #8C8C8C",
                width: smallLaptop ? "230px" : "350px",
                height: smallLaptop ? "30px" : "43px",
                display: "flex",
                justifyContent: "center",
                padding: "8px",
              }}
              onClick={() => document.getElementById("selectFile").click()}
            >
              <img
                src={UploadIcon}
                alt=""
                style={
                  smallLaptop
                    ? { width: "11px", height: "15px" }
                    : { width: "16px", height: "20px" }
                }
              />
              <p
                className="p-16-b"
                style={{ color: "#41BAEE", marginLeft: "10px" }}
              >
                Upload contract
              </p>
            </div>
            <input
              id="selectFile"
              type="file"
              style={{
                visibility: "hidden",
                height: 0,
                width: 0,
                outline: "none",
              }}
              onChange={(event) => {
                handleFileAdd(event);
              }}
            />
          </div>
        </div>
      </div>
      <div className="p-16" style={{ margin: "0" }}>
        <Checkbox
          style={{
            color: invite ? COLORS.primary : COLORS.dark_grey,
            paddingTop: "5px",
          }}
          onClick={() => setInvite(!invite)}
        />
        Send an invite after adding the user
      </div>
      <div className="p-16">
        <Checkbox
          style={{
            color: activate ? COLORS.primary : COLORS.dark_grey,
            paddingTop: "5px",
          }}
          onClick={() => setActivate(!activate)}
        />
        Activate customer
      </div>
    </div>
  );
}
