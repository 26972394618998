import React from "react";
import { formatValue } from "../../../utils/constants";
import { COLORS } from "../../../styles/themes/default/colors";
import moment from "moment";
import { periods } from "../utils/contants";

const TariffsTable = (props) => {
  const columnWidths = ["5%", "14%", "8%", "18%", "23%", "12%", "16%"];
  const getDatePeriod = (startDate, endDate) => {
    return startDate !== endDate
      ? `${startDate.split(".").slice(0, -1).join(".")} - ${endDate
          .split(".")
          .slice(0, -1)
          .join(".")}`
      : `${startDate.split(".").slice(0, -1).join(".")}`;
  };
  return (
    <table className="feesTable_dark">
      <tr>
        <td style={{ width: columnWidths[0] }} />
        <td className="tableDiv_dark" style={{ width: columnWidths[1] }}>
          Tariff
        </td>
        <td className="tableDiv_dark" style={{ width: columnWidths[2] }}>
          Type
        </td>
        <td
          className="tableDiv_dark"
          style={{ width: columnWidths[3], textAlign: "center" }}
        >
          Days
        </td>
        <td className="tableDiv_dark" style={{ width: columnWidths[4] }}>
          Dates
        </td>

        <td
          className="tableDiv_dark"
          style={{
            textAlign: "right",
            width: columnWidths[5],
          }}
        >
          Rate
        </td>
        <td
          className="tableDiv_dark"
          style={{
            textAlign: "right",
            width: columnWidths[6],
          }}
        >
          Amount
        </td>
      </tr>
      {props?.tariff && props?.tariff.length > 0
        ? props.tariff.map((item, index) => {
            return (
              <tr>
                <td style={{ width: columnWidths[0] }}>
                  <div
                    className="tariffDot"
                    style={{ backgroundColor: periods[index].color }}
                  />
                </td>

                <td style={{ width: columnWidths[1] }}>
                  {periods[index].label}
                </td>
                <td style={{ width: columnWidths[2] }}>
                  {props.areCalendarDays ? "CD" : "WD"}
                </td>

                <td style={{ width: columnWidths[3], textAlign: "center" }}>
                  {item?.days}
                </td>
                <td style={{ width: columnWidths[4] }}>
                  {getDatePeriod(item.startDate, item.endDate)}
                </td>
                <td
                  style={{
                    textAlign: "right",
                    width: columnWidths[5],
                  }}
                >
                  {formatValue(item?.rate)}
                </td>
                <td style={{ textAlign: "right", width: columnWidths[6] }}>
                  {formatValue(item?.amount)}
                </td>
              </tr>
            );
          })
        : props.data &&
          props.data.map((item, index) => {
            return (
              <tr>
                <td style={{ width: columnWidths[0] }}>
                  <div
                    className="tariffDot"
                    style={{ backgroundColor: periods[index].color }}
                  />
                </td>
                <td style={{ width: columnWidths[1] }}>
                  {periods[index].label}
                </td>
                <td style={{ width: columnWidths[2] }}>
                  {props.areCalendarDays ? "CD" : "WD"}
                </td>

                <td style={{ width: columnWidths[3], textAlign: "center" }}>
                  {item?.days}
                </td>
                <td style={{ width: columnWidths[4] }}>
                  {getDatePeriod(item.startDate, item.endDate)}
                </td>

                <td
                  style={{
                    textAlign: "right",
                    width: columnWidths[5],
                  }}
                >
                  {formatValue(item?.rate)}
                </td>
                <td style={{ textAlign: "right", width: columnWidths[6] }}>
                  {formatValue(item?.amount)}
                </td>
              </tr>
            );
          })}
      {props.showAtRisk &&
        props.atRiskFees &&
        (props.tariff === undefined || props.tariff.length === 0) && (
          <tr
            style={{
              color: COLORS.dark_grey,
            }}
          >
            <td style={{ width: columnWidths[0] }}>
              <div
                className="tariffDot"
                style={{ backgroundColor: "#8c8c8c" }}
              />
            </td>
            <td style={{ width: columnWidths[1] }}>At risk</td>
            <td style={{ width: columnWidths[2] }}>
              {props.areCalendarDays ? "CD" : "WD"}
            </td>

            <td style={{ width: columnWidths[3], textAlign: "center" }}>
              {props.atRiskFees.days}
            </td>
            <td style={{ width: columnWidths[4] }}>
              {moment(props.atRiskFees.endDate, "YYYY-MM-DD").format("MM.DD")}
            </td>
            <td style={{ textAlign: "right", width: columnWidths[5] }}>
              {formatValue(props.data?.at(-1)?.rate)}
            </td>
            <td style={{ textAlign: "right", width: columnWidths[6] }}>
              {formatValue(props.data.length > 0 && props.data?.at(-1).rate)}
            </td>
          </tr>
        )}
    </table>
  );
};

export default TariffsTable;
