import { React, useEffect } from "react";
import { useHistory, useLocation } from "react-router-dom";

import { IconButton, useMediaQuery, useTheme, Avatar } from "@mui/material";
import Cookies from "js-cookie";
import { useState } from "react";
import Tooltip from "./Tooltip";

import { COLORS } from "../styles/themes/default/colors";
import MenuIcon from "@mui/icons-material/Menu";
import Notifications from "./Notifications";
import KeyboardArrowLeftIcon from "@mui/icons-material/KeyboardArrowLeft";
import verifiedIcon from "../assets/img/Navbar/verified_icon.svg";

import UserDropDown from "./NavbarUserDropDown";
import { drawerWidth } from "../utils/constants";

import useStore from "../store/store";

const Navigation = ({ openDrawer, setOpenDrawer, setCookie, setPathname }) => {
  let cookie = Cookies.get("access_token");
  const location = useLocation();
  const navbarLabel = useStore((state) => state.navbarLabel);
  const setNavbarLabel = useStore((state) => state.setNavbarLabel);

  const openSidebar = useStore((state) => state.openSidebar);
  const setOpenSidebar = useStore((state) => state.setOpenSidebar);

  const [path, setPath] = useState(location.pathname);
  const theme = useTheme();
  const mdUp = useMediaQuery(theme.breakpoints.up("md"));
  // const darkMode = path.startsWith("/containers") || path.startsWith("/esg");
  const darkMode = ["/containers", "/esg", "/analytics"].some((item) =>
    path.startsWith(item)
  );
  const history = useHistory();

  useEffect(() => {
    setNavbarLabel({});
    setPath(location.pathname);
    setPathname(location.pathname);
  }, [location.pathname]);
  // only show navigation if user is logged in
  if (!cookie) return null;

  const getTitle = () => {
    if (path === "/customers") {
      return "Customers";
    } else if (path === "/customers/add") {
      return "Add Customer";
    } else if (path === "/demurrage/list") {
      return "D&D List";
    } else if (path === "/demurrage/request") {
      return "Create New D&D";
    } else if (path === "/demurrage/dashboard") {
      return "Demurrages";
    } else if (path === "/admin/users") {
      return "Users";
    } else if (path === "/profile/notification/settings") {
      return "Settings";
      // } else if (path === "/profile") {
      //   return "Account Information";
    } else if (path.startsWith("/demurrage/details/")) {
      return "D&D Details";
    } else if (path.startsWith("/customers/")) {
      return navbarLabel?.label ?? "Customer details";
    } else if (path === "/company") {
      return navbarLabel?.label ?? "Company info";
    } else if (path.startsWith("/containers")) {
      return navbarLabel?.label ?? "Container tracking";
    } else if (path.startsWith("/esg")) {
      return navbarLabel?.label ?? "ESG Dashboard - Carbon Footprint";
    } else if (path === "/profile") {
      return navbarLabel?.label ?? "Edit profile";
    } else if (path.startsWith("/admin/users/")) {
      return navbarLabel?.label ?? "Edit user";
    } else if (path.startsWith("/analytics")) {
      return "D&D analytics";
    }
  };
  return (
    <div style={{ width: "100%" }} id="navbar_container">
      <nav
        className="navbar navbar-expand navbar-light"
        style={{
          display: "flex",
          // borderBottom: `1px solid ${COLORS.border_gray}`,
          justifyContent: "space-between",
          // padding: 10,
          backgroundColor: darkMode ? "#313131" : COLORS.ligth_grey,
          marginLeft: mdUp
            ? openSidebar
              ? drawerWidth
              : window.innerWidth > 1920
              ? "100px"
              : "5%"
            : "0px",
          transition: "0.3s ease-in-out all",
          height: "100%",
        }}
      >
        {!mdUp && (
          <div>
            <IconButton
              color="inherit"
              aria-label="open drawer"
              edge="start"
              onClick={() => {
                setOpenSidebar(!openSidebar);
              }}
              sx={{ marginLeft: 2 }}
            >
              <MenuIcon style={{ width: 35, height: 35 }} />
            </IconButton>
          </div>
        )}
        <div style={{ translate: "-25px", position: "fixed", zIndex: 10000 }}>
          <Avatar
            sx={{
              backgroundColor: "#464646",
              width: "16px",
              height: "16px",
            }}
            onClick={() => setOpenSidebar(!openSidebar)}
          >
            <KeyboardArrowLeftIcon
              sx={{
                color: "white",
                width: "16px",
                heigth: "16px",
                transform: openSidebar ? "rotate(0deg)" : "rotate(180deg)",
              }}
            />
          </Avatar>
        </div>
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            height: "100%",
            width: "100%",
          }}
        >
          <div
            style={{
              width: "100%",
              display: "flex",
              justifyContent: "center",
              maxWidth: "1920px",
            }}
          >
            <div
              style={{
                display: "flex",
                alignItems: "center",
                width: "100%",
              }}
            >
              {(navbarLabel?.back_link || navbarLabel?.is_function) && (
                <button
                  onClick={() => {
                    if (navbarLabel?.is_function) {
                      navbarLabel.f();
                    } else {
                      history.push(navbarLabel?.back_link);
                    }
                  }}
                  className="navigationBackLabel"
                  style={{
                    width: "auto",
                    color: COLORS.activity,
                    outline: "none",
                  }}
                >
                  {"< "}Back
                </button>
              )}
              <p
                className="navigationLabel"
                style={{
                  color: darkMode ? COLORS.purple : COLORS.primary,
                }}
              >
                {getTitle()}
              </p>
              {/* dodaj ikonicu ovde koja treba */}
              {navbarLabel?.display_icon && (
                <div className="mcleod-verification">
                  <img
                    src={verifiedIcon}
                    alt=""
                    style={{ marginLeft: "15px" }}
                  />
                  <Tooltip type="McLeod" />
                </div>
              )}
            </div>
            <div style={{ display: "flex" }}>
              <Notifications darkMode={darkMode} />
              <UserDropDown setCookie={setCookie} darkMode={darkMode} />
            </div>
          </div>
        </div>
      </nav>
    </div>
  );
};

export default Navigation;
