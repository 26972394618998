import create from "zustand";
import { persist, devtools } from "zustand/middleware";

let store = (set) => ({
  atRiskContainer: {},
  setAtRiskContainer: (container) =>
    set((state) => ({ atRiskContainer: container })),
  containerUrlRouter: [],
  setContainerUrlRouter: (url) => set((state) => ({ containerUrlRouter: url })),
  currentDisplayContainer: {},
  setCurrentDisplayContainer: (container) =>
    set((state) => ({ currentDisplayContainer: container })),
  getContainerId: {},
  setGetContainerId: (container) =>
    set((state) => ({ getContainerId: container })),
  notificationContainerId: {},
  setNotificationContainerId: (container) =>
    set((state) => ({ notificationContainerId: container })),
});

store = devtools(store);
store = persist(store);

const containerStore = create(store);
export default containerStore;
