import React, { useEffect, useState } from "react";
import useStore from "../../store/store";
import "./styles/cart.scss";
import PromoCode from "./components/OffsetComponents/PromoCode";
import CartPaymentSummary from "./components/OffsetComponents/CartPaymentSummary";
import CartTable from "./components/OffsetComponents/CartTable";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import apiFactory from "../Container/service/apiFactory";
import { MCLOUD_APP_URL } from "../../utils/constants";
import SuccessfullPayment from "./components/OffsetComponents/SuccessfullPayment";
import SuggestedProjects from "./components/OffsetComponents/SuggestedProjects";
import alertService from "../../services/alertService";
import { CustomLoader } from "../../components/CustomLoader";
import { COLORS } from "../../styles/themes/default/colors";
import esgStore from "../../store/esgStore";

const Cart = (props) => {
  const setNavbarLabel = useStore((state) => state.setNavbarLabel);
  const offsetProjects = esgStore((state) => state.offsetProjects);

  const values = esgStore((state) => state.esgCart);
  const setValues = esgStore((state) => state.setEsgCart);
  const [sucessullPaymentValues, setSuccessfullPaymentValues] = useState({});
  const offsetContainerId = useStore((state) => state.offsetContainerId);

  const [loadingBuy, setLoadingBuy] = useState(false);
  const [successfullPayment, setSuccessfullPayment] = useState(false);

  const history = useHistory();
  useEffect(() => {
    if (successfullPayment) {
      setNavbarLabel({
        label: "Back to visibility",
        is_function: true,
        f: () => {
          history.push("/esg");
        },
      });
    } else {
      setNavbarLabel({ label: "My cart", back_link: "/esg/offset-projects" });
    }
  }, [successfullPayment]);

  const handlePaymentRequest = async () => {
    setLoadingBuy(true);
    const data = Object.keys(values).map((key) => {
      return {
        offset_id: offsetProjects.at(key).id,
        amount: values[key],
      };
    });

    await apiFactory
      .post(
        `${MCLOUD_APP_URL}offset/buy?container_id=${offsetContainerId}`,
        data
      )
      .then((resp) => {
        setLoadingBuy(false);
        setSuccessfullPaymentValues({ ...values });
        setValues("");
        setSuccessfullPayment(true);
      })
      .catch((err) => {
        setLoadingBuy(false);
        alertService.error(1, "Only Shipper users can buy offsets");
      });
  };
  return (
    <div style={{ paddingTop: "58px" }}>
      {successfullPayment ? (
        <SuccessfullPayment successfullPaymentValues={sucessullPaymentValues} />
      ) : (
        <>
          <div className="cart_main_div" id="cart_main">
            <div className="cart_payment_main_div">
              <div className="cart_table_div">
                <CartTable values={values} setValues={setValues} />
              </div>
              <div className="cart_payment_div">
                <div id="cart_rigth_div">
                  <div className="cart_header_box">
                    <p className="cart_header" style={{ marginBottom: "16px" }}>
                      Order summary
                    </p>
                    <p className="p-16-gray">
                      {offsetContainerId ? `#${offsetContainerId}` : ""}
                    </p>
                  </div>
                  <PromoCode />
                  <CartPaymentSummary
                    value={Object.keys(values).reduce(
                      (acc, curr) =>
                        acc +
                        values[curr] * offsetProjects.at(curr).price_value,
                      0
                    )}
                  />
                </div>
                <div className="cart_button_box">
                  <button
                    className="cart_button"
                    onClick={handlePaymentRequest}
                    disabled={Object.keys(values).length === 0}
                    style={{
                      width: loadingBuy ? "140px" : "auto",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                  >
                    {loadingBuy ? (
                      <CustomLoader
                        loaderColor={COLORS.off_white}
                        size={"24px"}
                      />
                    ) : (
                      "Confirm payment"
                    )}
                  </button>
                </div>
              </div>
            </div>
          </div>
          <SuggestedProjects
            title={"Projects that can offset your carbon emissions"}
          />
        </>
      )}
    </div>
  );
};

export default Cart;
