import React from "react";
import useStore from "../../store/store";

import {
  useMediaQuery,
  createTheme,
  useTheme,
  styled,
  Box,
} from "@mui/material";
import { AvatarIcon } from "../../components/ProfilePicture";
import { useState, useEffect } from "react";
import { useParams, useHistory } from "react-router-dom";

import { APP_URL } from "../../utils/constants";
import fetchWrapper from "../../helpers/fetch-wrapper";

import InputMask from "react-input-mask";

import { Button } from "../../components/Cdl/Button";
import Select from "../../components/Cdl/Select";
import { Input } from "../../components/Cdl/Input";
import DeleteIcon from "../../assets/img/deleteIcon.svg";

import { COLORS } from "../../styles/themes/default/colors";
import alertService from "../../services/alertService";

import EditIcon from "../../assets/img/editIcon.svg";

import "./styles/user.scss";

const Details = ({ match }) => {
  const currentUser = useStore((state) => state.currentUser);
  const history = useHistory();

  const setNavbarLabel = useStore((state) => state.setNavbarLabel);

  const [newData, setNewData] = useState({ role: "" });
  const [iconView, setIconView] = useState("");
  const [oldData, setOldData] = useState({});

  const [role, setRole] = useState("");
  const [first_name, setFirst_name] = useState("");
  const [last_name, setLast_name] = useState("");
  const [email, setEmail] = useState("");
  const [phone_number, setPhone_number] = useState("");
  const [company, setCompany] = useState("");

  const [reset, setReset] = useState(false);

  const theme = useTheme();
  const mdUp = useMediaQuery(theme.breakpoints.up("md"));
  const smUp = useMediaQuery(theme.breakpoints.up("sm"));

  const theme1 = createTheme({
    breakpoints: {
      values: {
        sl: 1400,
      },
    },
  });
  const smallLaptop = useMediaQuery(theme1.breakpoints.down("sl"));

  let { user_id } = useParams();
  useEffect(() => {
    async function getUser() {
      let url =
        currentUser.user.role === "CA"
          ? `${APP_URL}/user/${user_id}/`
          : `${APP_URL}/shipper/users/${user_id}/`;
      await fetchWrapper
        .get(url)
        .then((resp) => {
          setOldData(resp.user);
          setNewData(resp.user);
          setFirst_name(resp.user.first_name);
          setLast_name(resp.user.last_name);
          setEmail(resp.user.email);
          setPhone_number(resp.user.phone_number);
          setRole(resp.user.role);
          setCompany(resp?.company?.business_name);
        })
        .catch((error) => {
          history.push("/admin/users");
        });
    }

    setNavbarLabel({
      label: "Edit user",
      back_link: "/admin/users",
      display_icon: false,
    });

    getUser();
  }, [iconView, reset]);

  const returnError = () => {
    let error = "";
    if (first_name === "") {
      error += "First Name is required\n";
    }
    if (last_name === "") {
      error += "Last Name is required\n";
    }
    if (email === "") {
      error += "Email is required\n";
    } else if (!/^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(email)) {
      error += "Incorect email type.\n";
    }
    if (phone_number.includes("_") && phone_number !== "+1 (___) ___-____") {
      error += "Invalid phone number format";
    }
    return error;
  };

  const updateUser = async (data) => {
    let url =
      currentUser.user.role === "CA"
        ? `${APP_URL}/user/`
        : `${APP_URL}/shipper/users/`;
    await fetchWrapper
      .patch(url, data)
      .then((resp) => {
        setOldData(resp.user);
        setNewData(resp.user);
        alertService.success("Successfully updated profile");
        // history.push("/admin/users");
      })
      .catch((error) => {
        alertService.error(error.response.status);
      });
  };
  const returnRoleValue = (value) => {
    if (value === "Regular user") {
      if (currentUser.user.role === "CA") {
        return "CR";
      } else {
        return "SR";
      }
    } else if (value === "Administrator") {
      if (currentUser.user.role === "CA") {
        return "CA";
      } else {
        return "SA";
      }
    } else {
      return "CC";
    }
  };

  let disabled =
    oldData?.first_name === first_name &&
    oldData?.last_name === last_name &&
    oldData?.phone_number === phone_number &&
    oldData?.email === email &&
    oldData?.role === returnRoleValue(role);

  const addImage = async (e) => {
    setIconView(URL.createObjectURL(e.target.files[0]));

    let data = new FormData();
    data.append("picture", e.target.files[0]);
    data.append("id", oldData?.id);
    updateUser(data);
  };

  const handleDeleteImage = async () => {
    let data = new FormData();
    data.append("picture", "");
    data.append("id", oldData?.id);
    updateUser(data);
    setIconView("");
  };

  const handleSave = () => {
    let error = returnError();

    if (error !== "") {
      alertService.error(1, error);
      return;
    }

    let data = {
      id: oldData.id,
      first_name: first_name,
      last_name: last_name,
      email: email,
      phone_number: phone_number,
      role: returnRoleValue(role),
    };

    updateUser(data);
  };

  const displayRole = (role) => {
    switch (role) {
      case "CC":
        return "Accountant";
      case "CA":
      case "SA":
        return "Administrator";
      case "SR":
      case "CR":
        return "Regular user";
      default:
        return role;
    }
  };

  const Container = {
    width: "100%",
    display: "flex",
    justifyContent: "space-around",
  };
  const AvatarBox = styled(Box)(({ theme }) => ({
    marginLeft: smUp ? "5%" : "0%",
  }));
  const PictureChangeBox = styled(Box)(({ theme }) => ({
    // marginTop: "5px",
    marginLeft: smallLaptop ? "5px" : "20px",
    display: "flex",
    width: "100px",
  }));
  return (
    <div style={{ width: "100%" }}>
      <Box style={Container}>
        <div className="personal_div">
          <div style={{ width: "30%" }}>
            <p className="p-16-b" style={{ padding: "10px 0px 0 20px" }}>
              Personal
            </p>
            <AvatarBox>
              <AvatarIcon
                src={
                  oldData?.picture_64 !== null
                    ? `data:image/png;base64,${oldData?.picture_64}`
                    : ""
                }
                firstName={oldData?.first_name}
                lastName={oldData?.last_name}
                style={{
                  width: smallLaptop ? "5rem" : "7rem",
                  height: smallLaptop ? "5rem" : "7rem",
                  backgroundColor: "#C4C4C4",
                  fontSize: smUp ? "30px" : "25px",
                  fontFamily: "Montserrat",
                }}
              />
            </AvatarBox>
            <PictureChangeBox>
              <Button
                type="icon"
                icon={EditIcon}
                iconCss={{
                  marginTop: "3px",
                  marginRight: "5px",
                  width: smallLaptop ? "11px" : "0px",
                  paddingBottom: smallLaptop ? "10px" : "0px",
                }}
                onClick={() => document.getElementById("add_photo").click()}
              />

              <input
                id="add_photo"
                type="file"
                style={{ visibility: "hidden", height: 0 }}
                onChange={(event) => addImage(event)}
              />

              <Button
                type="icon"
                icon={DeleteIcon}
                iconCss={{
                  marginTop: "3px",
                  marginRight: smallLaptop ? "15px" : "5px",
                  width: "11px",
                }}
                onClick={handleDeleteImage}
              />
            </PictureChangeBox>
          </div>
          <div className="full_name_div">
            <p className="p-16-gray">First name:</p>
            <Input
              value={first_name}
              onChange={(e) => setFirst_name(e.target.value)}
              css={{ width: "90%" }}
            />

            <p className="p-16-gray" style={{ margin: "10px 0px 0px 0px" }}>
              Last name:
            </p>
            <Input
              value={last_name}
              onChange={(e) => setLast_name(e.target.value)}
              css={{ width: "90%" }}
            />
          </div>
        </div>
        <div className="contact_div">
          <p className="p-16-b">Contact</p>
          <p className="p-16-gray">Email:</p>
          <p className="p-16">{email}</p>
          <p className="p-16-gray">Phone number:</p>
          <InputMask
            mask="+1 (999) 999-9999"
            alwaysShowMask
            value={phone_number}
            onChange={(e) => {
              setPhone_number(e.target.value);
            }}
            placeholder="Phone number"
          >
            <Input css={{ width: "90%" }} />
          </InputMask>
        </div>

        <div className="company_div">
          <p className="p-16-b">Company</p>
          <p className="p-16-gray">Company name:</p>
          <p className="p-16">{company ? company : "CDL"}</p>
          <p className="p-16-gray">Role:</p>
          <Select
            type="select"
            disabled={currentUser.user.id == user_id} // needs to stay == => comparing int with string
            onChange={setRole}
            value={displayRole(role)}
            options={
              currentUser.user.role.includes("S")
                ? ["Administrator", "Regular user"]
                : ["Administrator", "Regular user", "Accountant"]
            }
            css={{ width: "60%" }}
            cssDiv={{ width: "60%" }}
          />
        </div>
      </Box>
      <div style={{ display: "flex", flexDirection: "row-reverse" }}>
        <div style={{ marginTop: "20px", marginRight: "1%" }}>
          <Button
            disabled={disabled}
            onClick={() => handleSave()}
            type="primary"
            text="Save changes"
            css={{
              marginRight: "10px",
              width: smallLaptop ? "120px" : "164px",
            }}
          />
          <Button
            onClick={() => setReset(!reset)}
            type="link"
            text="Discard"
            css={{ width: smallLaptop ? "80px" : "100px" }}
          />
        </div>
      </div>
    </div>
  );
};

export default Details;
