import React from "react";
import { ReactComponent as ShipIcon } from "../../assets/ship_icon.svg";
import { ReactComponent as TruckIcon } from "../../assets/truck.svg";
import { ReactComponent as TrainIcon } from "../../assets/rail_icon.svg";
import containerStore from "../../../../store/containerStore";

const LocationIcon = ({ data, setClusterData, setShowTable }) => {
  const setGetContainerId = containerStore((state) => state.setGetContainerId);

  return (
    <button
      onClick={() => {
        setGetContainerId(data.container_id);
        setClusterData([]);
        setShowTable(true);
      }}
      style={{
        backgroundColor: "transparent",
        border: "none",
        outline: "none",
      }}
    >
      {data?.icon === "truck" ? (
        <TruckIcon
          style={{
            width: "15px",
            height: "15px",

            fill: data?.color ?? "#8c8c8c",
            transform:
              data?.origin?.lng < data?.destination?.lng
                ? "scaleX(-1)"
                : "none",
          }}
        />
      ) : data?.icon === "ship" ? (
        <ShipIcon
          style={{
            width: "15px",
            height: "15px",
            fill: data?.color ?? "#8c8c8c",
          }}
        />
      ) : (
        <TrainIcon
          style={{
            width: "15px",
            height: "15px",
            stroke: data?.color ?? "#8c8c8c",
          }}
        />
      )}
    </button>
  );
};

export default LocationIcon;
