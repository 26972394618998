import React, { useState } from "react";
import EyeIcon from "../../components/assets/eye_icon.svg";
import Pagination from "../../../../../components/Pagination";
import moment from "moment";
import { CustomLoader } from "../../../../../components/CustomLoader";
import DndModal from "./DndModal";

const DnDTable = (props) => {
  const [displayData, setDisplayData] = useState({});
  let cellWidths = [20, 5, 20, 15, 17, 18, 5];
  return (
    <>
      {props.isLoading ? (
        <div className="history_no_content_div">
          <CustomLoader size="5vh" />
        </div>
      ) : props.data && props.data.length === 0 ? (
        <div className="history_no_content_div">No D&D purchased!</div>
      ) : (
        <>
          <DndModal data={displayData} setData={setDisplayData} />
          <table className="history_table_main">
            <thead>
              <tr
                className="history_row"
                style={{ borderBottom: "1px solid #bdbdbdcc" }}
              >
                <th className="left" style={{ width: `${cellWidths[0]}%` }}>
                  Container ID
                </th>
                <th className="right" style={{ width: `${cellWidths[1]}%` }}>
                  Date
                </th>
                <th style={{ width: `${cellWidths[2]}%` }} />
                <th className="left" style={{ width: `${cellWidths[3]}%` }}>
                  Type
                </th>
                <th className="right" style={{ width: `${cellWidths[4]}%` }}>
                  Amount
                </th>
                <th style={{ width: `${cellWidths[5]}%` }} />

                <th className="center" style={{ width: `${cellWidths[6]}%` }}>
                  Action
                </th>
              </tr>
            </thead>
            <tbody>
              {props.data &&
                props.data.length > 0 &&
                props.data.map((item, index) => {
                  return (
                    <tr className="history_row" key={index}>
                      <td style={{ width: `${cellWidths[0]}%` }}>
                        #{item.container_id}
                      </td>
                      <td
                        style={{ width: `${cellWidths[1]}%` }}
                        className=" right"
                      >
                        {moment(
                          item.demurrage_request.at(-1).created_at
                        ).format("MM/DD/YYYY")}
                      </td>
                      <td style={{ width: `${cellWidths[2]}%` }} />
                      <td
                        className=" dark_grey"
                        style={{ width: `${cellWidths[3]}%` }}
                      >
                        {item.is_in_demurrage ? "Demurrage" : "Detention"}
                      </td>
                      <td
                        className="orange right"
                        style={{ width: `${cellWidths[4]}%` }}
                      >
                        $
                        {item?.current_detention_amount ??
                          item?.current_demurrage_amount}
                      </td>
                      <td style={{ width: `${cellWidths[6]}%` }} />

                      <td
                        className="center"
                        style={{ width: `${cellWidths[7]}%` }}
                      >
                        <button
                          style={{
                            backgroundColor: "transparent",
                            border: "none",
                          }}
                          onClick={() => {
                            setDisplayData(item);
                          }}
                        >
                          <img alt="eye_icon" src={EyeIcon} />
                        </button>
                      </td>
                    </tr>
                  );
                })}
            </tbody>
          </table>
          <div className="table_footer">
            <Pagination
              totalNumber={props.dndPagination.total}
              page={props.dndPagination.page}
              setPage={(number) => {
                props.setDndPagination({
                  ...props.dndPagination,
                  page: number,
                });
              }}
              itemsPerPage={props.dndPagination.count}
            />
          </div>
        </>
      )}
    </>
  );
};
export default DnDTable;
