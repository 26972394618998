import React from "react";
import { COLORS } from "../../../../styles/themes/default/colors";
import Switch from "@mui/material/Switch";
import { styled } from "@mui/material/styles";
import containerStore from "../../../../store/containerStore";

const IOSSwitch = styled((props) => (
  <Switch focusVisibleClassName=".Mui-focusVisible" disableRipple {...props} />
))(({ theme }) => ({
  width: 42,
  height: 26,
  padding: 0,
  margin: 0,

  //   "& .css-5ryogn-MuiButtonBase-root-MuiSwitch-switchBase.Mui-disabled": {
  //     color: COLORS.sunrise_orange,
  //   },
  "& .css-5ryogn-MuiButtonBase-root-MuiSwitch-switchBase.Mui-checked.Mui-disabled":
    {
      color: COLORS.purple,
    },

  "& .MuiSwitch-switchBase": {
    padding: 0,
    margin: 2,
    transitionDuration: "300ms",
    color: COLORS.off_white,
    "&.Mui-checked": {
      transform: "translateX(16px)",
      color: "#fff",
      "& + .MuiSwitch-track": {
        backgroundColor: COLORS.purple,
        color: COLORS.purple,
        opacity: 1,
        border: 0,
      },
      "&.Mui-disabled + .MuiSwitch-track": {
        opacity: 0.5,
      },
    },
    "&.Mui-focusVisible .MuiSwitch-thumb": {
      color: "#33cf4d",
      border: "6px solid #fff",
    },
    "&.Mui-disabled .MuiSwitch-thumb": {
      opacity: 0.7,
    },
    "&.Mui-disabled + .MuiSwitch-track": {
      opacity: 0.7,
    },
  },
  "& .MuiSwitch-thumb": {
    boxSizing: "border-box",
    width: 22,
    height: 22,
  },
  "& .MuiSwitch-track": {
    borderRadius: 26 / 2,
    backgroundColor: COLORS.purple,
    border: "1px solid rgba(140, 140, 140, 0.4)",
    opacity: 1,
    // transition: theme.transitions.create(["background-color"], {
    //   duration: 500,
    // }),
  },
}));

const SearchHeader = ({ setShowTable, showTable, setClusetData }) => {
  const setCurrentDisplayContainer = containerStore(
    (state) => state.setCurrentDisplayContainer
  );
  const setGetContainerId = containerStore((state) => state.setGetContainerId);

  return (
    <div
      id="search_header_box"
      style={{
        width: "100%",
        marginBottom: "16px",
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
      }}
    >
      <p
        style={{
          fontSize: "22px",
          fontWeight: "600",
          color: COLORS.off_white,
          margin: 0,
        }}
      >
        Search results
      </p>
      <div style={{ display: "flex", alignItems: "center" }}>
        <p
          style={{
            margin: 0,
            marginRight: "8px",
            fontSize: "14px",
            fontWeight: "600",
            transition: "0.3s all ease-in-out",

            color: showTable ? COLORS.purple : COLORS.off_white,
          }}
        >
          Table
        </p>
        <IOSSwitch
          checked={!showTable}
          onChange={() => {
            setShowTable(!showTable);
            setGetContainerId("");
            setCurrentDisplayContainer({});
            setClusetData([]);
          }}
        />
        <p
          style={{
            margin: 0,
            marginLeft: "8px",
            fontSize: "14px",
            fontWeight: "600",
            transition: "0.3s all ease-in-out",
            color: !showTable ? COLORS.purple : COLORS.off_white,
          }}
        >
          Stats
        </p>
      </div>
    </div>
  );
};

export default SearchHeader;
