import React from "react";
import {
  Dialog,
  DialogTitle,
  DialogActions,
  DialogContent,
  useTheme,
  useMediaQuery,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";

import { useState } from "react";
import { COLORS } from "../../../styles/themes/default/colors";
import CssTextField from "../../../components/StyledTextField";
import { Input } from "../../../components/Cdl/Input";
import { Button } from "../../../components/Cdl/Button";
import Select from "../../../components/Cdl/Select";

import { APP_URL } from "../../../utils/constants";
import useStore from "../../../store/store";
import fetchWrapper from "../../../helpers/fetch-wrapper";
import alertService from "../../../services/alertService";
import InputMask from "react-input-mask";

export default function AddUserModal2({ open, setOpenModal, setReset, reset }) {
  const currentUser = useStore((state) => state.currentUser);

  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [phoneNumber, setPhoneNUmber] = useState("");
  const [role, setRole] = useState("");
  const [isSent, setisSent] = useState(null);

  const theme = useTheme();
  const mdUp = useMediaQuery(theme.breakpoints.up("md"));

  const userRoleOptions =
    currentUser?.user?.role === "SA" || currentUser?.user?.role === "SR"
      ? ["Administrator", "Regular user"]
      : ["Administrator", "Regular user", "Accountant"];

  const textFieldStyle = {
    width: "100%",
    marginBottom: "5%",
    backgroundColor: COLORS.white,
    fontFamily: "Montserrat",
    textAlign: "left",
  };

  const selectStyle = {
    width: "100%",
    marginBottom: "5%",
    backgroundColor: COLORS.white,
    fontFamily: "Montserrat",
    textAlign: "left",
  };
  const addButtonStyle = {
    marginRight: "5%",
    padding: "11px 16px",
  };
  const closeButtonStyle = {
    padding: "11px 16px",
  };

  const roleToAbbrvation = (role) => {
    if (currentUser?.user?.role === "SA") {
      switch (role) {
        case "Administrator":
          return "SA";
        case "Regular user":
          return "SR";
      }
    } else if (currentUser?.user?.role === "CA") {
      switch (role) {
        case "Administrator":
          return "CA";
        case "Regular user":
          return "CR";
        case "Accountant":
          return "CC";
      }
    }
  };

  const isValidData = () => {
    let error = "";
    if (firstName.trim() === "") {
      error += "First Name is required.\n";
    }
    if (lastName.trim() === "") {
      error += "Last Name is required.\n";
    }
    if (email.trim() === "") {
      error += "Email is required.\n";
    } else if (!/^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(email)) {
      error += "Incorect email type.\n";
    }
    if (role.trim() === "") {
      error += "Role is required.\n";
    }
    return error;
  };
  const handleAddUser = async () => {
    let error = isValidData();
    if (error !== "") {
      setisSent(false);
      alertService.error(1, error);
      return;
    }

    let data = {
      first_name: firstName,
      last_name: lastName,
      email: email,
      phone_number: phoneNumber,
      role: roleToAbbrvation(role),
    };
    let url =
      currentUser.user.role === "CA"
        ? `${APP_URL}/user/`
        : `${APP_URL}/shipper/users/`;
    await fetchWrapper
      .post(url, data)
      .then((resp) => {
        setOpenModal(false);
        setFirstName("");
        setLastName("");
        setEmail("");
        setPhoneNUmber("");
        setRole("");
        setisSent(null);
        setReset(!reset);
        alertService.success("User succesfully added");
      })
      .catch((error) => {
        alertService.error(error.response.status);
      });
  };
  return (
    <Dialog
      open={open}
      onClose={() => {
        setOpenModal(false);
      }}
      PaperProps={{
        style: {
          backgroundColor: COLORS.ligth_grey,
          padding: "16px",
          width: "384px",
        },
      }}
    >
      <DialogTitle
        style={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
          padding: "0",
          marginBottom: "24px",
          fontSize: "24px",
          fontWeight: "600",
          lineHeight: "29px",
        }}
      >
        Add User
        <CloseIcon
          onClick={(e) => {
            setisSent(null);
            setOpenModal(false);
          }}
        />
      </DialogTitle>
      <DialogContent
        style={{
          textAlign: "center",
          backgroundColor: COLORS.ligth_grey,
          padding: "0",
        }}
      >
        <Input
          placeholder="First name *"
          css={textFieldStyle}
          value={firstName}
          onChange={(e) => setFirstName(e.target.value)}
          error={isSent === false && firstName.trim() === ""}
        />
        {/* <CssTextField
          onChange={(e) => {
            setFirstName(e.target.value);
          }}
          inputProps={{
            style: {
              fontFamily: "Montserrat",
            },
          }}
          error={isSent === false && firstName.trim() === ""}
          value={firstName}
          style={textFieldStyle}
          size="small"
          placeholder="First name *"
        /> */}
        <Input
          placeholder="Last name *"
          css={textFieldStyle}
          value={lastName}
          onChange={(e) => setLastName(e.target.value)}
          error={isSent === false && lastName.trim() === ""}
        />
        {/* <CssTextField
          onChange={(e) => {
            setLastName(e.target.value);
          }}
          inputProps={{
            style: { fontFamily: "Montserrat" },
          }}
          value={lastName}
          style={textFieldStyle}
          size="small"
          placeholder="Last name *"
          error={isSent === false && lastName.trim() === ""}
        /> */}
        <Input
          placeholder="Email *"
          css={textFieldStyle}
          value={email}
          onChange={(e) => {
            setEmail(e.target.value);
          }}
          error={isSent === false && email.trim() === ""}
        />
        {/* <CssTextField
          onChange={(e) => {
            setEmail(e.target.value);
          }}
          inputProps={{
            style: { fontFamily: "Montserrat" },
          }}
          value={email}
          style={textFieldStyle}
          size="small"
          placeholder="Email *"
          error={isSent === false && email.trim() === ""}
          FormHelperTextProps={{
            style: {
              backgroundColor: "transparent",
              color: "red",
              fontFamily: "Montserrat",
            },
          }}
        /> */}
        <InputMask
          mask="+1 (999) 999-9999"
          alwaysShowMask
          value={phoneNumber}
          onChange={(e) => {
            setPhoneNUmber(e.target.value);
          }}
          placeholder="Phone number"
        >
          {/* <CssTextField
            inputProps={{
              style: { fontFamily: "Montserrat" },
            }}
            style={textFieldStyle}
            size="small"
            placeholder="Phone number"
          /> */}
          <Input
            placeholder="Phone number"
            css={textFieldStyle}
            error={isSent === false && email.trim() === ""}
          />
        </InputMask>

        <Select
          placeholder="Role *"
          css={selectStyle}
          options={userRoleOptions}
          type="select"
          value={role}
          onChange={setRole}
          cssDiv={{ position: "fixed", width: "352px", top: "545px" }}
        />

        {/* <CssTextField
          select
          size="small"
          value={role}
          style={textFieldStyle}
          onChange={(e) => {
            setRole(e.target.value);
          }}
          error={isSent === false && role.trim() === ""}
          label="Role *"
        >
          <MenuItem
            value={currentUser.user.role === "CA" ? "CA" : "SA"}
            style={{ fontFamily: "Montserrat" }}
          >
            Administrator
          </MenuItem>
          <MenuItem
            value={currentUser.user.role === "CA" ? "CR" : "SR"}
            style={{ fontFamily: "Montserrat" }}
          >
            Regular user
          </MenuItem>
          {currentUser.user.role === "CA" && (
            <MenuItem value={"CC"} style={{ fontFamily: "Montserrat" }}>
              Accountant
            </MenuItem>
          )}
        </CssTextField> */}
      </DialogContent>

      <DialogActions
        style={{
          justifyContent: "flex-end",
          display: "flex",
          marginBottom: "30px",
        }}
      >
        <Button
          type="primary"
          text="Add"
          css={addButtonStyle}
          onClick={handleAddUser}
        />
        <Button
          text="Close"
          type="link"
          css={closeButtonStyle}
          onClick={(e) => {
            setisSent(null);
            setOpenModal(false);
          }}
        />
      </DialogActions>
    </Dialog>
  );
}
