import React from "react";
import { COLORS } from "../../../../styles/themes/default/colors";

import DollarIcon from "../../assets/dollar2_icon.svg";
import BookingIcon from "../../assets/booking_icon.svg";
import CalendarIcon from "../../assets/calendar.svg";
import { formatValue } from "../../../../utils/constants";
import { useMediaQuery, createTheme } from "@mui/material";

const DetentionInformation = ({
  data,
  setIsModalOpen,
  setIsDetentionModalOpen,
}) => {
  const theme1 = createTheme({
    breakpoints: {
      values: {
        sl: 1400,
      },
    },
  });
  const smallLaptop = useMediaQuery(theme1.breakpoints.down("sl"));
  const isInternational = data?.is_international_dnd
  // data?.current_event.location_state !== 'US'
  // (data?.export && ['At Port', 'On Water', 'Out to Delivery', 'Vessel Arriving'].includes(data?.current_event.status)) ||
  //   (!data?.export && ['On Water', 'Out to Load', 'Vessel Departing'].includes(data?.current_event.status))

  return (
    <div
      style={{
        width: "100%",
        backgroundColor: "#090909",
        borderRadius: "8px",
        padding: "16px",
        marginTop: "8px",
        marginBottom: "16px",
      }}
    >
      <div
        style={{
          width: "100%",
          display: "flex",
          justifyContent: "space-between",
        }}
      >
        <p style={{ margin: 0, color: COLORS.off_white, fontWeight: 600 }}>
          {isInternational ? "D&D fees" : "Detention Fees"}
        </p>
        {isInternational && (
          <p className='p-16-gray' style={{ margin: 0, fontWeight: 600 }}>
            International SSL information is not supported
          </p>
        )}
        {(data?.export && data?.detention_fee_by_pricing && !data?.is_international_dnd) ||
          (data?.is_in_detention && (data?.at_risk_detention_fee || data?.detention_fee_by_pricing)) ||
          (data?.is_in_demurrage && (data?.at_risk_demurrage_fee || data?.demurrage_fee_by_pricing)) ||
          (!data?.export && ['At Port', 'Out to Delivery', 'Empty Returned'].includes(data?.current_event.status)) ? (
          <button
            className="link_button"
            style={{ padding: 0 }}
            onClick={() =>
              data.is_in_demurrage
                ? setIsModalOpen(true)
                : setIsDetentionModalOpen(true)
            }
          >
            Show details
          </button>
        ) : (
          <></>
        )}
      </div>

      {!isInternational && (
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            width: "100%",
            marginTop: "16px",
          }}
        >
          <div>
            <div className="port_grid_box_top">
              <img
                src={CalendarIcon}
                alt=""
                style={{
                  marginRight: smallLaptop ? "6" : "8px",
                  width: smallLaptop ? "11px" : "16px",
                  height: smallLaptop ? "11px" : "16px",
                }}
              />
              <p className="port_grid_title">First free day:</p>
            </div>
            <div style={{ padding: "8px 24px 8px 24px" }}>
              <p className="port_grid_main">
                {data?.first_free_detention_day
                  ? data?.first_free_detention_day
                  : data?.first_free_demurrage_day
                    ? data.first_free_demurrage_day
                    : "_"}
              </p>
            </div>
          </div>
          <div>
            <div className="port_grid_box_top">
              <img
                src={CalendarIcon}
                alt=""
                style={{
                  marginRight: smallLaptop ? "6" : "8px",
                  width: smallLaptop ? "11px" : "16px",
                  height: smallLaptop ? "11px" : "16px",
                }}
              />
              <p className="port_grid_title">Last free day:</p>
            </div>
            <div style={{ padding: "8px 24px 8px 24px" }}>
              <p className="port_grid_main">
                {data?.last_detention_free_day
                  ? data?.last_detention_free_day
                  : data?.last_demurrage_free_day
                    ? data.last_demurrage_free_day
                    : "_"}{" "}
              </p>
            </div>
          </div>
          <div>
            <div className="port_grid_box_top">
              <img
                src={BookingIcon}
                alt=""
                style={{
                  marginRight: smallLaptop ? "6" : "8px",
                  width: smallLaptop ? "11px" : "16px",
                  height: smallLaptop ? "11px" : "16px",
                }}
              />
              <p className="port_grid_title">Free days used:</p>
            </div>
            <div style={{ padding: "8px 24px 8px 24px" }}>
              <p className="port_grid_main">
                {data?.detention_days
                  ? data?.detention_days > data?.detention_free_days
                    ? data?.detention_free_days
                    : data.detention_days
                  : "_"}
                /{data?.detention_free_days ?? "_"}
              </p>
            </div>
          </div>
          <div>
            <div className="port_grid_box_top">
              <img
                src={DollarIcon}
                alt=""
                style={{
                  marginRight: smallLaptop ? "6" : "8px",
                  width: smallLaptop ? "11px" : "16px",
                  height: smallLaptop ? "11px" : "16px",
                }}
              />
              <p className="port_grid_title">Current amount:</p>
            </div>
            <div style={{ padding: "8px 24px 8px 24px" }}>
              <p className="port_grid_main_purple">
                {formatValue(data?.current_detention_amount)}
              </p>
            </div>
          </div>
          <div>
            <div className="port_grid_box_top">
              <img
                src={DollarIcon}
                alt=""
                style={{
                  marginRight: smallLaptop ? "6" : "8px",
                  width: smallLaptop ? "11px" : "16px",
                  height: smallLaptop ? "11px" : "16px",
                }}
              />
              <p className="port_grid_title">At risk amount:</p>
            </div>
            <div style={{ padding: "8px 24px 8px 24px" }}>
              <p className="port_grid_main_purple">
                {formatValue(data?.potential_detention_amount)}
              </p>
            </div>
          </div>
          {/* <div>
          {data?.current_demurrage_amount + data?.current_detention_amount >
            0 && (
            <button
              className="purple_button"
              onClick={() =>
                data.potential_detention_amount === null
                  ? setIsModalOpen(true)
                  : setIsDetentionModalOpen(true)
              }
              // style={{ fontSize: "16px", fontWeight: "600", width: "120px" }}
            >
              See Details
            </button>
          )}
        </div> */}
        </div>
      )}
    </div>
  );
};

export default DetentionInformation;
