import React, { useState } from "react";

import RadialBarStatistics from "./statistics_components/RadialBarStatistics";
import ColumnBarStatistics from "./statistics_components/ColumnBarStatistics";

import "./../styles/containerDetails.scss";
import "./../styles/statisticsfilters.scss";
import VesselsStatistics from "./statistics_components/VesselsStatistics";

import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";
import DonutBarStatistics from "./statistics_components/DonutChart";
import OutToLoadStatistics from "./statistics_components/StatisticFilterComponents/OutToLoad";
import OnWaterStatistics from "./statistics_components/StatisticFilterComponents/OnWater";
import VesselArrivingStatistics from "./statistics_components/StatisticFilterComponents/VesselArriving";
import AtPortStatistics from "./statistics_components/StatisticFilterComponents/AtPort";
import OutToDeliveryStatistics from "./statistics_components/StatisticFilterComponents/OutToDelivery";
import EmptyReturnedStatistics from "./statistics_components/StatisticFilterComponents/EmptyReturned";
import VesselDepartingStatistics from "./statistics_components/StatisticFilterComponents/VesselDeparting";

const ContainerStatistics = ({
  statistics,
  loading,
  chartStatusFilter,
  setChartStatusFilter,
  setChartRiskFilter,
  setMainColor,
  setRedirect,
  filterParams,
  statisticFilter,
  setStatisticFilter,
  riskFilter,
  setRiskFilter,
  showTable,
  containersPerStatusMaxValue,
  setContainersPerStatusMaxValue,
  setJourneyStatus
}) => {

  const statisticsToComponents = {
    "Out to Load": <OutToLoadStatistics statistics={statistics?.out_to_load} />,
    "Vessel Departing": (
      <VesselDepartingStatistics statistics={statistics?.vessel_departing} />
    ),
    "On Water": <OnWaterStatistics statistics={statistics?.on_water} />,
    "Vessel Arriving": <VesselArrivingStatistics statistics={statistics} />,
    "At Port": (
      <AtPortStatistics
        statistics={statistics?.at_port}
        loading={loading}
        setChartRiskFilter={setChartRiskFilter}
        setRiskFilter={setRiskFilter}
        riskFilter={riskFilter}
        setRedirect={setRedirect}
      />
    ),
    "Out to Delivery": (
      <OutToDeliveryStatistics statistics={statistics?.out_to_delivery} />
    ),
    "Empty Returned": (
      <EmptyReturnedStatistics statistics={statistics?.empty_returned} />
    ),
  };
  return (
    <div
      className="container_statistics"
      style={{
        height: document.getElementById("search_header_box")?.clientHeight
          ? "calc(100% - 50px)"
          : "100%",
      }}
    >
      <div className="container_statistics_div first">
        {loading ? (
          <Skeleton />
        ) : (
          <div
            style={{
              display: "flex",
              width: "100%",
              justifyContent: "space-between",
              alignItems: "flex-start",
            }}
          >
            <p className="container_statistics_title">Containers per status</p>
            {statisticFilter ? (
              <button
                className="link_button"
                style={{ padding: 0 }}
                onClick={() => {
                  setChartRiskFilter("");
                  setRiskFilter("");
                  setChartStatusFilter("");
                  setStatisticFilter("");
                  setJourneyStatus("")
                  setMainColor("");
                }}
              >
                Reset
              </button>
            ) : (
              <></>
            )}
          </div>
        )}
        <div className="container_statistics_graph">
          <RadialBarStatistics
            statistics={statistics}
            loading={loading}
            chartStatusFilter={chartStatusFilter}
            setChartStatusFilter={setChartStatusFilter}
            statisticFilter={statisticFilter}
            setStatisticFilter={setStatisticFilter}
            setMainColor={setMainColor}
            riskFilter={riskFilter}
            setRedirect={setRedirect}
            containersPerStatusMaxValue={containersPerStatusMaxValue}
            setContainersPerStatusMaxValue={setContainersPerStatusMaxValue}
          />
        </div>
      </div>
      {statisticFilter ? (
        <div
          style={{
            width: "100%",
            flex: 1,
          }}
        >
          {loading ? (
            <>
              <Skeleton width="100%" height={250} />
              <Skeleton
                width="100%"
                height="100%"
                style={{ marginTop: "16px" }}
              />
            </>
          ) : (
            statisticsToComponents[statisticFilter]
          )}
        </div>
      ) : (
        <>
          {loading ? (
            <div className="container_statistics_div second">
              <Skeleton width="100%" height="100%" />
            </div>
          ) : (
            <div className="container_statistics_div second">
              <div
                style={{
                  display: "flex",
                  width: "100%",
                  justifyContent: "space-between",
                  alignItems: "flex-start",
                }}
              >
                <p className="container_statistics_title">
                  {filterParams.includes("&export=true") ? "Detention" : "Demurrage & Detention"}
                </p>
                {riskFilter ? (
                  <button
                    className="link_button"
                    style={{ padding: 0, zIndex: 9999 }}
                    onClick={() => {
                      setChartRiskFilter("");
                      setRiskFilter("");
                    }}
                  >
                    Reset
                  </button>
                ) : (
                  <></>
                )}
              </div>
              <div className="container_statistics_graph">
                <DonutBarStatistics
                  statistics={statistics?.demurrage_risk}
                  loading={loading}
                  setChartRiskFilter={setChartRiskFilter}
                  setRiskFilter={setRiskFilter}
                  riskFilter={riskFilter}
                  setRedirect={setRedirect}
                />
              </div>
            </div>
          )}

          <div className="container_statistics_div third">
            {statistics?.vessel_statistics === undefined ? (
              <>
                <div className="div-switch-title">
                  <p className="container_statistics_title">
                    {loading ? (
                      <Skeleton width={200} />
                    ) : (
                      `Top 3 SSLs by ${filterParams.includes("&export=true") ? "detention" : "demurrage & detention"}`
                    )}
                  </p>
                </div>
                <ColumnBarStatistics
                  statistics={statistics?.get_top_3_ssls_stats}
                  loading={loading}
                  showTable={showTable}
                />
              </>
            ) : (
              <>
                <div className="div-switch-title">
                  <p className="container_statistics_title">
                    {loading ? <Skeleton width={40} /> : "Arriving vessels"}
                  </p>
                  {!loading && (
                    <p className="container_statistics_helper">
                      &nbsp;(this week){" "}
                    </p>
                  )}
                </div>
                <VesselsStatistics statistics={statistics} loading={loading} />
              </>
            )}
          </div>
        </>
      )}
    </div>
  );
};

export default ContainerStatistics;
