import React, { useEffect } from "react";
import { Route, Switch } from "react-router-dom";
import Cookies from "js-cookie";

import cdlLogoOrange from "../../img/cdlLogo.png";

import backgroundTextIcon from "../../img/backgroundText.png";

import "./styles/mainPage.scss";

import Register from "../../pages/Account/Register";
import Login from "../../pages/Account/Login";
import VerifyEmail from "../../pages/Account/VerifyEmail";
import ForgotPassword from "./ForgotPassword/ForgotPassword";
import ResetPassword from "../../pages/Account/ResetPassword";
import PasswordSetup from "./PasswordSetup";

import { useMediaQuery, useTheme } from "@mui/material";
const Account = ({ history, match }) => {
  const theme = useTheme();
  const breakPoint = useMediaQuery(theme.breakpoints.up("600"));

  const { path } = match;

  let cookieValue = Cookies.get("access_token");

  useEffect(() => {
    if (cookieValue && path === "/account") {
      history.push("/");
    }
  }, [cookieValue, history, path]);

  return (
    <div className="mainDiv">
      <div className="sidebardiv">
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <img src={cdlLogoOrange} className="cdlLogo" alt="login_backround" />
          {!breakPoint && (
            <img
              src={backgroundTextIcon}
              className="backgroundTextIcon"
              alt="from_a_to_better"
            />
          )}
        </div>
        <Switch>
          <Route path={`${path}/login`} component={Login} />
          <Route path={`${path}/register`} component={Register} />
          <Route path={`${path}/verify-email`} component={VerifyEmail} />
          <Route
            exact
            path={`${path}/password-setup/:token`}
            component={PasswordSetup}
          />
          <Route
            exact
            path={`${path}/forgot-password`}
            component={ForgotPassword}
          />

          <Route path={`${path}/reset-password`} component={ResetPassword} />
        </Switch>
        <div className="copyrightDiv">
          <p className="copyright">Copyright @ CDL 1000</p>
          <p className="copyright">{new Date().getFullYear()}</p>
        </div>
      </div>
      {breakPoint && (
        <div className="rightDiv">
          <p className="p-48-b-white">Welcome to DaaS</p>
          <p className="p-24-white">
            Increase visibility and transparency of your demurrage payments
          </p>
          <p className="p-24-white">
            and get faster and efficient container release with us!
          </p>
          <img
            src={backgroundTextIcon}
            className="backgroundTextIcon"
            alt="from_a_to_better"
          />
        </div>
      )}
    </div>
  );
};

export default Account;
