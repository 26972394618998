import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";

import fetchWrapper from "../../helpers/fetch-wrapper";
import { APP_URL } from "../../utils/constants";
import useStore from "../../store/store";

import SubmitDemurrageModal from "./components/SubmitDemurrageModal";
import DemurrageRequestLocation from "./components/Location";
import DemurrageRequestContainer from "./components/ContainersInput";
import DemurrageRequestDoc from "./components/Documents";
import SubmitModal from "../../components/Modal";
import CloseModal from "../../components/Modal";
import AlarmIcon from "../../assets/img/Alarm_fill.png";

import GeneralInformation from "./components/GeneralInformationInput";

import { Box, Typography, createTheme, useMediaQuery } from "@mui/material";

import { COLORS } from "../../styles/themes/default/colors";
import alertService from "../../services/alertService";
import { styled } from "@mui/system";

import { Button } from "../../components/Cdl/Button";

import Note from "./components/Note";
import Status from "./components/StatusBar";
import { light } from "@mui/material/styles/createPalette";
import containerStore from "../../store/containerStore";

const theme1 = createTheme({
  breakpoints: {
    values: {
      xs: 0,
      smallMobile: 321,
      mobile: 376,
      middleMobile: 407,
      sm: 600,
      md: 900,
      lg: 1200,
      xl: 1536,
      sl: 1400,
    },
  },
});

const mdDown = theme1.breakpoints.down("md");

const Container = styled(Box)(({ theme }) => ({
  width: "100%",
  maxHeigth: "90vh",
  backgroundColor: COLORS.ligth_grey,
  padding: window.innerWidth > 1920 ? "5px" : "24px",
  paddingTop: "16px",
  display: "flex",
  flexDirection: "column",

  [mdDown]: {
    padding: 0,
  },
}));

const ContainerTitle = styled(Typography)(({ theme }) => ({
  textAlign: "left",
  fontWeight: "600",
  fontSize: "24px",
  display: "flex",
  "@media only screen and (max-width: 1400px) and (min-width: 1280px)": {
    fontSize: "18px",
  },
}));

const Header = styled(Box)(({ theme }) => ({
  width: "100%",
  height: "92px",
  display: "flex",
  flex: 0,
  "@media only screen and (max-width: 1400px) and (min-width: 1280px)": {
    height: "65px",
  },
}));
const MainContainer = styled(Box)(({ theme }) => ({
  width: "100%",
  marginTop: "16px",
  display: "flex",
  flexDirection: "column",
  flex: 1,
}));

const FirstContainer = styled(Box)(({ theme }) => ({
  width: "100%",
  display: "flex",
  backgroundColor: COLORS.ligth_grey,
  flex: 1,
}));

const SecondContainer = styled(Box)(({ theme }) => ({
  width: "100%",
  display: "flex",
  marginTop: "16px",
  flex: 0,
  [mdDown]: {
    flexDirection: "column",
  },
}));

const GeneralInformationContainer = styled(Box)(({ theme }) => ({
  borderRadius: "8px",
  padding: "16px",
  marginTop: "16px",
  flex: 0,
  backgroundColor: COLORS.off_white,
  [mdDown]: {
    width: "100%",
  },
}));
const LocationContainer = styled(Box)(({ theme }) => ({
  borderRadius: "8px",
  width: "27%",
  backgroundColor: COLORS.off_white,
  padding: "16px",
  marginLeft: "16px",
  [mdDown]: {
    width: "100%",
  },
}));
const StatusContainer = styled(Box)(({ theme }) => ({
  width: "92%",
  display: "flex",
  backgroundColor: "blue",

  [mdDown]: {
    width: "90%",
    marginTop: "1rem",
    marginBottom: "1rem",
    justifyContent: "center",
  },
}));

const ContainerInfo = styled(Box)(({ theme }) => ({
  width: "73%",
  borderRadius: "8px",
  backgroundColor: COLORS.off_white,
  padding: "16px",
  paddingBottom: 0,

  [mdDown]: {
    width: "100%",
  },
}));

const CommentContainer = styled(Box)(({ theme }) => ({
  borderRadius: "8px",
  width: "27%",
  padding: "16px",
  marginLeft: "16px",
  backgroundColor: COLORS.off_white,
}));
const DocumentContainer = styled(Box)(({ theme }) => ({
  width: "73%",
  padding: "16px 16px 0 16px",
  borderRadius: "8px",

  backgroundColor: COLORS.off_white,
  [mdDown]: {
    width: "100%",
  },
}));

const DemurrageRequest = () => {
  const history = useHistory();

  const smallLaptop = useMediaQuery(theme1.breakpoints.down("sl"));

  const [totalAmount, setTotalAmount] = useState(0.0);
  const [showTerminal, setShowTerminals] = useState();

  const [openSubmitModal, setOpenSubmitModal] = useState(false);
  const [openCloseModal, setOpenCloseModal] = useState(false);

  //demurrage info
  const [shipper, setShipper] = useState("");
  const [carrier, setCarrier] = useState("");
  const [cause, setCause] = useState("");
  const [location, setLocation] = useState({});
  const [note, setNote] = useState();

  const [documents, setDocuments] = useState([]);
  const [containers, setContainers] = useState([]);

  const [isSent, setisSent] = useState(null);

  //data from BE

  const [terminals, setTerminals] = useState([]);
  const [causes, setCauses] = useState([]);
  const [shippers, setShippers] = useState([]);

  const atRiskData = containerStore((state) => state.atRiskContainer);
  const setAtRiskContainer = containerStore(
    (state) => state.setAtRiskContainer
  );

  useEffect(() => {
    fetchWrapper.get(`${APP_URL}/dnd/terminals/`).then((resp) => {
      setTerminals(resp);
    });
    fetchWrapper.get(`${APP_URL}/dnd/causes/`).then((resp) => {
      let cause = [];
      resp.forEach((item) => {
        cause = [...cause, item.cause];
      });
      setCauses(resp);
    });
    fetchWrapper.get(`${APP_URL}/shipper/`).then((resp) => {
      const shippersResp = resp.filter((item) => item.is_active === true);
      setShippers(shippersResp);
    });
  }, []);

  useEffect(() => {
    if (
      atRiskData &&
      Object.keys(atRiskData).length > 0 &&
      terminals.length > 0
    ) {
      setShipper(atRiskData.customer);
      if (terminals.length > 0) {
        const term = terminals.find(
          (item) => item.iana_code === atRiskData.terminal_iana_code
        );
        setLocation(term);
      }
      setContainers([
        {
          ...atRiskData,
          type: `${atRiskData.type} ${atRiskData.size}`,
          steamship_line: atRiskData.steamship_line,
          demurrage_amount: atRiskData.current_demurrage_amount,
          demurrage_days: atRiskData.demurrage_days,
          detention_days: atRiskData.detention_days ?? "_",
        },
      ]);
      setAtRiskContainer({});
    }
  }, [atRiskData, terminals]);

  useEffect(() => {
    let amount = 0.0;
    containers.forEach((item) => {
      amount =
        amount +
        parseFloat(
          item?.is_in_demurrage
            ? item?.demurrage_amount
            : item?.detention_amount
        );
    });
    setTotalAmount(amount.toFixed(2));
  }, [containers]);

  const submitDemurrage = () => {
    fetchWrapper
      .post(`${APP_URL}/dnd/create_shipper/`, { business_name: shipper })
      .then((response) => {
        const data = {
          shipper: response.id,
          carrier: carrier,
          cause_of_demurrage: cause
            ? causes.find((item) => item.cause === cause).id
            : "",
          terminal: location?.id,
          containers,
        };

        fetchWrapper.post(`${APP_URL}/dnd/`, data).then((resp) => {
          if (documents.length > 0) {
            documents.forEach((item) => {
              const newFormData = new FormData();
              newFormData.append("document", item.document);
              newFormData.append("document_type", item.document_type);
              newFormData.append("demurrage", resp.id);
              fetchWrapper.post(`${APP_URL}/dnd/add_document/`, newFormData);
            });
          }
          if (note) {
            const data = { text: note };
            fetchWrapper.post(`${APP_URL}/dnd/add_comment/${resp.id}/`, data);
          }

          fetchWrapper.post(`${APP_URL}/dnd/submit/${resp.id}/`).then(() => {
            history.push("/demurrage/list");
          });
        });
      });
  };

  function returnError() {
    let error = "";
    if (shipper === "") {
      error += "Shipper is required\n";
    }
    if (location?.id === undefined) {
      error += "Location is required\n";
    }
    if (containers.length === 0) {
      error += "Containers needs to be added";
    }
    return error;
  }

  async function submit() {
    const isValid = shipper && location?.id && containers.length > 0;

    if (isValid) {
      setOpenSubmitModal(true);
    } else {
      setisSent(false);
      alertService.error(1, returnError());
    }
  }

  async function save() {
    const isValid = shipper && location?.id && containers.length > 0;

    const data = {
      shipper: shippers.find((item) => item.business_name === shipper).id,
      carrier: carrier,
      cause_of_demurrage: cause
        ? causes.find((item) => item.cause === cause).id
        : "",
      terminal: location.id,
      containers,
    };

    if (isValid) {
      fetchWrapper.post(`${APP_URL}/dnd/`, data).then((resp) => {
        if (documents.length > 0) {
          documents.forEach((item) => {
            const newFormData = new FormData();
            newFormData.append("document", item.document);
            newFormData.append("document_type", item.document_type);
            newFormData.append("demurrage", resp.id);
            fetchWrapper.post(`${APP_URL}/dnd/add_document/`, newFormData);
          });
        }
        if (note) {
          const data = { text: note, demurrage_id: resp.id };
          fetchWrapper.post(`${APP_URL}/dnd/add_comment/${resp.id}/`, data);
        }
        history.push("/demurrage/list");
      });
    } else {
      setisSent(false);
      alertService.error(1, returnError());
    }
  }
  const handleFileAdd = (name, files) => {
    if (files.length > 0) {
      const doc = {
        document: files[0],
        document_type: name,
        filename: files[0].name,
      };
      const docs = [...documents, doc];
      setDocuments(docs);
    }
  };
  return (
    <Container>
      <Header>
        <StatusContainer>
          <Status status={""} guest={false} />
        </StatusContainer>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-between",
            width: "8%",
          }}
        >
          <Button
            text="Submit"
            type={"primary"}
            onClick={submit}
            css={{ width: "100%", height: smallLaptop ? "30px" : "42px" }}
          />
          <Button
            text="Save as draft"
            type={"secondary"}
            onClick={save}
            css={{ width: "100%", height: smallLaptop ? "30px" : "42px" }}
          />
        </div>
      </Header>
      <GeneralInformationContainer>
        <ContainerTitle>General</ContainerTitle>
        <GeneralInformation
          shipper={shipper}
          setShipper={setShipper}
          setCarrier={setCarrier}
          carrier={carrier}
          cause={cause}
          setCause={setCause}
          causes={causes}
          shippers={shippers}
          setLocation={setLocation}
          terminals={terminals}
          status={""}
          location={location}
          guest={false}
        />
      </GeneralInformationContainer>
      <MainContainer>
        <FirstContainer>
          <ContainerInfo>
            <ContainerTitle>Container info</ContainerTitle>
            <Typography
              sx={{
                fontSize: smallLaptop ? "11px" : "16px",
                margin: "8px 0px",
              }}
            >
              Please enter a container ID to check if there is any available
              information about the container
            </Typography>
            <DemurrageRequestContainer
              containers={containers}
              setContainers={setContainers}
              status={""}
              totalAmount={totalAmount}
              terminal={location}
            />
          </ContainerInfo>
          <LocationContainer>
            <DemurrageRequestLocation
              setLocation={setLocation}
              terminals={terminals}
              status={""}
              location={location}
              isSent={isSent}
              guest={false}
            />
          </LocationContainer>
        </FirstContainer>
        <SecondContainer>
          <DocumentContainer>
            <ContainerTitle>Documents</ContainerTitle>
            <Typography
              sx={{
                fontSize: smallLaptop ? "11px" : "16px",
                margin: "8px 0px",
              }}
            >
              Attach receipt or invoice
            </Typography>
            <DemurrageRequestDoc
              handleFileAdd={handleFileAdd}
              documents={documents}
              setDocuments={setDocuments}
              isDetailPage={false}
            />
          </DocumentContainer>
          <CommentContainer>
            <ContainerTitle>Note</ContainerTitle>
            <Typography
              sx={{
                fontSize: smallLaptop ? "11px" : "16px",
                margin: "8px 0px",
              }}
            >
              Add any additional information that you wish other people to see
            </Typography>
            <Note note={note} setNote={setNote} />
          </CommentContainer>
        </SecondContainer>
      </MainContainer>
      <CloseModal
        openModal={openCloseModal}
        setOpenModal={setOpenCloseModal}
        title={"CLOSING A DEMURRAGE?"}
        content={[
          "When you leave this page, the changes you made will not be saved. Make sure that you saved or submitted the changes.",
        ]}
        icon={AlarmIcon}
        buttons={[
          {
            label: "Yes",
            variant: "contained",
            color: "sunrise_orange",
            style: {
              fontWeight: 600,
              boxShadow: "none",
            },
            click: () => history.push("/demurrage/list"),
          },
          {
            label: "Cancel",
            variant: "outlined",
            color: "sunrise_orange",
            style: {
              fontWeight: 600,
              boxShadow: "none",
            },
            click: () => setOpenCloseModal(false),
          },
        ]}
      />
      <SubmitDemurrageModal
        openSubmitModal={openSubmitModal}
        setOpenSubmitModal={setOpenSubmitModal}
        submitDemurrage={submitDemurrage}
      />
    </Container>
  );
};

export default DemurrageRequest;
