import React, { useEffect, useState } from "react";

import { ReactComponent as TruckIcon } from "../../assets/truck.svg";
import { ReactComponent as TrainIcon } from "../../assets/rail_icon.svg";
import { ReactComponent as ShipIcon } from "../../assets/ship_icon_active.svg";

import ShowWholeRouteIcon from "../../assets/show_whole_route.svg";

import NotSavedIcon from "../../assets/not_saved_purple.svg";
import SavedIcon from "../../assets/purple_saved.svg";
import GridInfo from "../MutualComponents/GridInfo";
import TrackingEventsView from "../MutualComponents/TrackingView";
import DemurrageInformations from "../MutualComponents/DemurrageInformation";
import "../../styles/portDetail.scss";
import { useMediaQuery, createTheme } from "@mui/material";

import TableLocation from "../MutualComponents/Location";
import { colorDict, statusBoxClass } from "../../utils/contants";

import useStore from "../../../../store/store";
import { sliceString } from "../../utils/functions";
import { formatValue } from "../../../../utils/constants";
import { CustomLoader } from "../../../../components/CustomLoader";
import DetentionInformation from "../MutualComponents/DetentionInformation";
import containerStore from "../../../../store/containerStore";

const theme1 = createTheme({
  breakpoints: {
    values: {
      sl: 1400,
    },
  },
});
const StatusBar = ({ status }) => {
  const smallLaptop = useMediaQuery(theme1.breakpoints.down("sl"));
  return (
    <div
      className={statusBoxClass[status]}
      style={{ fontSize: smallLaptop ? "11px" : "16px" }}
    >
      <div className="dot" style={{ backgroundColor: colorDict[status] }} />
      {status}
    </div>
  );
};

const DetailsTableRow = ({
  data,

  handleFavorite,
  isInCluster,

  loadingContainer,
  setIsModalOpen,
  setIsDetentionModalOpen,
}) => {
  const smallLaptop = useMediaQuery(theme1.breakpoints.down("sl"));

  const currentUser = useStore((state) => state.currentUser);
  const [openDetail, setOpenDetail] = useState(null);
  const [isClosed, setIsClosed] = useState(null);
  const [gradientColor, setGradientColor] = useState("#1f1f1f");
  const [isFavorite, setIsFavorite] = useState(
    data?.favored_by.includes(currentUser.user.id)
  );
  const currentDisplayContainer = containerStore(
    (state) => state.currentDisplayContainer
  );
  const setCurrentDisplayContainer = containerStore(
    (state) => state.setCurrentDisplayContainer
  );
  const listOfColors = [
    "#1f1f1",
    "#343434",
    "#4b4b4b",
    "#626262",
    "#7b7b7b",
    "#626262",
    "#4b4b4b",
    "#343434",
    "#1f1f10",
    "#1f1f18",
    "#1f1f1f",
  ];
  const setGetContainerId = containerStore((state) => state.setGetContainerId);
  const getContainerId = containerStore((state) => state.getContainerId);

  useEffect(() => {
    if (isClosed === false && openDetail === false) {
      listOfColors.forEach((color, index) => {
        setTimeout(() => {
          setGradientColor(color);
        }, 50 * index);
      });
    }
  }, [isClosed]);

  const displayPay =
    (data?.is_in_detention && data?.current_detention_amount > 0) ||
    (data?.is_in_demurrage && data?.current_demurrage_amount > 0);
  useEffect(() => {
    if (getContainerId === data?.container_id) {
      setOpenDetail(true);
    } else {
      setOpenDetail(false);
    }
  }, [currentDisplayContainer]);
  return (
    <div style={{ width: "100%" }}>
      <div
        className={openDetail ? "port_table_row_dark" : "port_table_row"}
        onClick={() => {
          if (!loadingContainer) {
            if (currentDisplayContainer?.container_id === data.container_id) {
              setOpenDetail(false);
              setCurrentDisplayContainer({});
              setGetContainerId("");
            } else {
              setGetContainerId(data.container_id);
            }
          }
        }}
        style={{
          // background: openDetail ? "#090909" : COLORS.off_black,
          // transition: "all 1s ease-in-out",
          width: "100%",
          marginBottom: openDetail ? "8px" : "16px",
          border: isInCluster ? `1px solid ${data.color}` : "none",
        }}
      >
        <div
          className="container_id_terminal"
          onClick={(e) => {
            e.stopPropagation();
          }}
        >
          #{data.container_id}{" "}
        </div>
        {openDetail ? (
          <div style={{ width: "77%", display: "flex" }}>
            <div className="status_cell">
              <StatusBar status={data?.current_event?.status} />
            </div>
            <div
              style={{ width: "25%", display: "flex", alignItems: "center" }}
            >
              <button
                onClick={(e) => {
                  e.stopPropagation();
                  setCurrentDisplayContainer({
                    ...currentDisplayContainer,
                    whole_route: !currentDisplayContainer?.whole_route,
                  });
                }}
                className="link_button"
                style={{ display: "flex", alignItems: "center" }}
              >
                <img
                  src={ShowWholeRouteIcon}
                  alt=""
                  style={{ marginRight: "8px" }}
                />
                Show route
              </button>
            </div>
          </div>
        ) : (
          <div style={{ width: "77%", display: "flex" }}>
            <div className="dot_cell">
              <div
                className="dot"
                style={{
                  backgroundColor: colorDict[data?.current_event?.status],
                }}
              />
            </div>
            <span className="hover_status">
              <div
                style={{
                  width: "100%",
                  height: "100%",
                  backgroundColor: `${data?.color}33`,
                  color: data?.color,
                  padding: "8px",
                  borderRadius: "8px",
                }}
              >
                {data.current_event.status}
              </div>
            </span>

            <div
              className="availability_cell"
              style={{ width: displayPay ? "50%" : "100%" }}
            >
              {data.icon === "truck" ? (
                <TruckIcon
                  style={{
                    fill: "#FAFAFA",
                    width: smallLaptop ? "11px" : "16px",
                    height: smallLaptop ? "11px" : "16px",
                    marginRight: "8px",
                    marginBottom: "2px",
                  }}
                />
              ) : data.icon === "ship" ? (
                <ShipIcon
                  style={{
                    fill: "#FAFAFA",
                    width: smallLaptop ? "11px" : "16px",
                    height: smallLaptop ? "11px" : "16px",
                    marginRight: "8px",
                    marginBottom: "2px",
                  }}
                />
              ) : (
                <TrainIcon
                  style={{
                    stroke: "#FAFAFA",
                    width: smallLaptop ? "11px" : "16px",
                    height: smallLaptop ? "11px" : "16px",
                    marginRight: "8px",
                    marginBottom: "2px",
                  }}
                />
              )}

              {sliceString(
                data.current_event.description,
                displayPay ? 40 : 70
              )}
            </div>
            {loadingContainer && getContainerId === data.container_id && (
              <div style={{ display: "flex", alignItems: "center" }}>
                <CustomLoader darktheme="true" size="3vh" />
              </div>
            )}
            {displayPay && (
              <div className="pay_cell">
                <p style={{ paddingRight: "16px" }} className="white_text">
                  {formatValue(
                    data?.current_demurrage_amount +
                      data?.current_detention_amount
                  )}
                </p>
                <button
                  onClick={(e) => {
                    e.stopPropagation();
                    if (data?.is_in_detention) {
                      setIsDetentionModalOpen(true);
                    } else {
                      setIsModalOpen(true);
                    }
                    setGetContainerId(data.container_id);
                  }}
                  className="purple_button"
                >
                  Pay
                </button>
              </div>
            )}
          </div>
        )}
        <div className="bookmark_cell">
          <button
            onClick={(e) => {
              e.stopPropagation();

              handleFavorite(data?.container_id);
              setIsFavorite(!isFavorite);
            }}
            style={{
              outline: "none",
              border: "none",
              backgroundColor: "transparent",
            }}
          >
            <img src={isFavorite ? SavedIcon : NotSavedIcon} alt="" />
          </button>
        </div>
        {/* <div className="details_cell">
          <button
            
            className="link_button"
          >
            {openDetail ? "Hide details" : "Show details"}
            <img
              src={DropDownIcon}
              alt=""
              style={{
                marginLeft: "8px",
                transform: openDetail ? "rotate(180deg)" : "rotate(0deg)",
              }}
            />
          </button>
        </div> */}
      </div>
      {currentDisplayContainer?.container_id === data?.container_id && (
        <div
          style={{
            width: "100%",
            height: openDetail ? "auto" : "0px",
            transition: "0.3s all ease-in-out",
            overflow: openDetail ? "visible" : "hidden",
          }}
        >
          <TableLocation
            data={currentDisplayContainer}
            currentEvent={currentDisplayContainer?.current_event}
            lastUpdated={currentDisplayContainer?.last_updated}
          />
          <div
            style={{
              width: "100%",
              marginTop: "8px",
              display: "flex",
              height: "auto",
            }}
          >
            <GridInfo data={currentDisplayContainer} />
            <TrackingEventsView
              data={currentDisplayContainer?.events}
              currentEvent={currentDisplayContainer?.current_event}
              color={currentDisplayContainer.color}
            />
          </div>
          {data?.is_in_demurrage ? (
            <DemurrageInformations
              data={currentDisplayContainer}
              setIsModalOpen={setIsModalOpen}
              setIsDetentionModalOpen={setIsDetentionModalOpen}
            />
          ) : (
            <DetentionInformation
              data={currentDisplayContainer}
              setIsModalOpen={setIsModalOpen}
              setIsDetentionModalOpen={setIsDetentionModalOpen}
            />
          )}
        </div>
      )}
    </div>
  );
};

export default DetailsTableRow;
