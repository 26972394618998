import React, { useState } from "react";
import Chart from "react-apexcharts";
import EsgTextField from "../EsgTextFields";
import FiberManualRecordIcon from "@mui/icons-material/FiberManualRecord";
import finishIcon from "../../assets/finish.svg";

import EmissionIcon from "../../assets/tooltip-emission-icon.svg";
import AlternativeFuelIcon from "../../assets/tooltip-alternative-fuels.svg";
import OffsetIcon from "../../assets/tooltip-offsets.svg";
import FinalEmissionIcon from "../../assets/tooltip-final-emission.svg";
import TargetGoalIcon from "../../assets/tooltip-final-goals.svg";
import DownArrowIcon from "../../assets/arrow-down.svg";

const TimeLineGraph = (props) => {
  const [startYear, setStartYear] = useState(2024);
  const [endYear, setEndYear] = useState(2050);
  const colors = ["#FF1DA4", "#51DA88", "#41BAEE"];

  function createEvenlySpacedArray(first, last) {
    let arr = [first];
    let step = (last - first) / 7;

    for (let i = 1; i < 7; i++) {
      arr.push(Math.round(first + step * i));
    }
    arr.push(last);
    arr[-1] = last;

    console.log(arr);
    return arr;
  }

  let years = createEvenlySpacedArray(startYear, endYear);

  let redLineData = props?.data?.redLine
    ? years.map((item) => {
        return isNaN(parseInt(props.data.redLine[item]))
          ? 0
          : parseInt(props.data.redLine[item]);
      })
    : [0, 0, 0, 0, 0, 0, 0];
  let greenLineData = props?.data?.greenLine
    ? years.map((item) => {
        return isNaN(parseInt(props.data.greenLine[item]))
          ? 0
          : parseInt(props.data.greenLine[item]);
      })
    : [0, 0, 0, 0, 0, 0, 0, 0];
  let blueLineData = props?.data?.blueLine
    ? years.map((item) => {
        return isNaN(parseInt(props.data.blueLine[item]))
          ? 0
          : parseInt(props.data.blueLine[item]) ?? 0;
      })
    : [0, 0, 0, 0, 0, 0, 0, 0];

  // console.log(props.data.redLine, years, redLineData);

  const calculatePercentage = (end) => {


    let start = props.emission2015
    return parseFloat((end /start)*100 -100).toFixed(2)

    // start ... 100%
    // end .... x%
    // start/end = 100 /x
    // x=end*100/start
  }
  const series = [
    {
      name: "Emissions",
      description: "",
      data: redLineData,
    },
    {
      name: "Offsets",
      description: "Projects + Alternative fuel",
      data: greenLineData.map((value) => {
        return -value;
      }),
    },
    {
      name: "Final emissions",
      description: "",
      data: blueLineData,
    },
  ];

  const options = {
    colors: colors,
    chart: {
      type: "area",
      stacked: false,
      height: 350,
      toolbar: {
        show: false,
      },
      zoom: {
        enabled: false,
      },
    },
    dataLabels: {
      enabled: false,
    },
    stroke: {
      curve: "straight",
      width: 2,
    },
    markers: {
      size: 0,
    },
    fill: {
      type: "gradient",
      gradient: {
        shadeIntensity: 1,
        inverseColors: false,
        opacityFrom: 0.5,
        opacityTo: 0,
        stops: [0, 90, 100],
      },
    },
    yaxis: {
      show: true,
      axisBorder: {
        show: false,
      },
      axisTicks: {
        show: false,
      },
    },
    xaxis: {
      type: "value",
      labels: {
        style: {
          fontFamily: "Montserrat",
          fontSize: "12px",
          fontWeight: 600,
          colors: "#adadad",
        },
      },
      axisBorder: {
        show: false,
      },
      axisTicks: {
        show: false,
      },
    },
    tooltip: {
      enabled: true,
      theme: null,
      custom: function ({ series, seriesIndex, dataPointIndex, w }) {
        console.log(series,seriesIndex,dataPointIndex,w)

        return `<div style="background-color:#313131;">
                  <p style="font-weight:600;color:#f1f1f1;margin-bottom:16px">Scope 3 emission for ${w.globals.categoryLabels[dataPointIndex]}:</p>
                  <div style="display:flex">
                    <div> 
                      <div style="margin-bottom:16px">
                        <div style="display:flex;gap:8px;align-items:center;margin-bottom:8px">
                          <img src="${EmissionIcon}"/>
                          <p style="font-size:14px;color:#ff00bb;margin-bottom:0px">Emission:</p>
                        </div>
                        <div style="display:flex;padding-left:24px;gap:4px">
                          <p style="font-weight:600;color:#fafafa;margin-bottom:0px">${series[0][dataPointIndex]}</p>
                          <p style="color:#8c8c8c;margin-bottom:0px">tCO<sub>2</sub>e</p>
                        </div>
                      </div>
                      <div style="margin-bottom:16px">
                        <div style="display:flex;gap:8px;align-items:center;margin-bottom:8px">
                          <img src="${AlternativeFuelIcon}"/>
                          <p style="font-size:14px;color:#8c8c8c;margin-bottom:0px">Alternative Fuel:</p>
                        </div>
                        <div style="display:flex;padding-left:24px;gap:4px">
                          <p style="font-weight:600;color:#fafafa;margin-bottom:0px">21543</p>
                          <p style="color:#8c8c8c;margin-bottom:0px">tCO<sub>2</sub>e</p>
                        </div>
                      </div>
                      <div style="margin-bottom:16px">
                        <div style="display:flex;gap:8px;align-items:center;margin-bottom:8px">
                          <img src="${OffsetIcon}"/>
                          <p style="font-size:14px;color:#00ff65;margin-bottom:0px">Offsets:</p>
                        </div>
                        <div style="display:flex;padding-left:24px;gap:4px">
                          <p style="font-weight:600;color:#fafafa;margin-bottom:0px">${-1*series[1][dataPointIndex]}</p>
                          <p style="color:#8c8c8c;margin-bottom:0px">tCO<sub>2</sub>e</p>
                        </div>
                      </div>
                      <div>
                        <div style="display:flex;gap:8px;align-items:center;margin-bottom:8px">
                          <img src="${FinalEmissionIcon}"/>
                          <p style="font-size:14px;color:#03B9F2;margin-bottom:0px">Final Emission</p>
                        </div>
                        <div style="display:flex;padding-left:24px;gap:4px">
                          <p style="font-weight:600;color:#fafafa;margin-bottom:0px">${series[0][dataPointIndex] + series[1][dataPointIndex]}</p>
                          <p style="color:#8c8c8c;margin-bottom:0px">tCO<sub>2</sub>e</p>
                        </div>
                      </div>                      
                    </div>

                    <div>
                      <div>
                      <div style="display:flex;gap:8px;align-items:center;margin-bottom:8px">
                        <img src="${TargetGoalIcon}"/>
                        <p style="font-size:14px;color:#8c8c8c;margin-bottom:0px">Emission</p>
                      </div>
                      <div style="display:flex;padding-left:24px;gap:4px">
                        <img src="${DownArrowIcon}" style="margin-right:4px;transform:rotate(180deg)"/>
                        <p style="font-weight:600;color:#ff00bb;margin-bottom:0px">${calculatePercentage(series[0][dataPointIndex])}%</p>
                        <p style="color:#8c8c8c;margin-bottom:0px">from 2015</p>
                      </div>
                    </div>
                    </div>
                  </div>
                </div>`;
      },
    },
    legend: {
      show: false,
    },
    labels: createEvenlySpacedArray(startYear, endYear),
    grid: {
      show: true,
      borderColor: "white",
      borderWidth: 2,
      padding: {
        left: 20,
      },
      xaxis: {
        lines: {
          show: true,
        },
      },
      yaxis: {
        lines: {
          show: false,
          strokeDashArray: 5,
        },
      },
    },
    annotations: {
      yaxis: [
        {
          y: 0,
          borderColor: "white",
          borderWidth: 3,
          strokeDashArray: 0,
        },
      ],
    },
  };

  return (
    <div className="template_box w100 mb16 p-relative f-grow1 d-flex f-dir-col">
      <div className="d-flex justify-between">
        <p className="fw600 c-white mb8">Projected timeline graph</p>
        <div className="d-flex align-center gap16">
          <p className="m0 c-dark-grey">From:</p>
          <EsgTextField
            is_function
            value={startYear}
            setValue={(value) => setStartYear(value)}
            year
            minValue={2023}
          />
          <p className="m0 c-dark-grey">To:</p>
          <EsgTextField
            is_function
            value={endYear}
            setValue={(value) => setEndYear(value)}
            year
          />
        </div>
      </div>
      <div className="w100 f-grow1 timeline_graph">
        <Chart
          options={options}
          series={series}
          type="area"
          height={150}
          width={"98%"}
        />
      </div>
      <img
        className="p-absolute finish_icon"
        src={finishIcon}
        alt="finish icon"
      />

      <div className="d-flex justify-center gap120">
        {series?.map((item, index) => (
          <div className="d-flex gap8" key={index}>
            <FiberManualRecordIcon
              style={{ color: colors[index], width: "15px" }}
            />
            <p className="m0 c-white">{item?.name}</p>
            {item?.description && (
              <p className="m0 c-dark-grey">{`(${item?.description})`}</p>
            )}
          </div>
        ))}
      </div>
    </div>
  );
};

export default TimeLineGraph;
