import React, { useState, useEffect, useRef } from "react";
import { Input } from "./Input";
import { styled } from "@mui/material";

import arrowDown from "../../assets/img/arrowDown.svg";
import { COLORS } from "../../styles/themes/default/colors";
import { dark } from "@mui/material/styles/createPalette";

export default function AutoComplete({
  options,
  css,
  cssDiv,
  search,
  setSearch,
  triggerOnSelectOnly = false,
  setSelectedValue,
  iconStart,
  placeholder,
  disabled,
  darkTheme,
}) {
  const [display, setDisplay] = useState(false);
  const [searchValue, setSearchValue] = useState("");
  const wrapperRef = useRef(null);

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const handleClickOutside = (event) => {
    const { current: wrap } = wrapperRef;
    if (wrap && !wrap.contains(event.target)) {
      setDisplay(false);
    }
  };

  const click = (item) => {
    if (triggerOnSelectOnly) setSelectedValue(item);
    setSearch(item);
    setSearchValue("");
    setDisplay(false);
  };

  const getSuggestions = (searchValue) => {
    const inputValueLowercase = searchValue.toLowerCase();
    return options
      .filter((item) => item.toLowerCase().includes(inputValueLowercase))
      .sort((a, b) => {
        const aIndex = a.toLowerCase().indexOf(inputValueLowercase);
        const bIndex = b.toLowerCase().indexOf(inputValueLowercase);
        return aIndex - bIndex;
      });
  };

  const OptionDiv = styled("div")(() => ({
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    backgroundColor: darkTheme ? "#1f1f1f" : "#FAFAFA",
    margin: "0px 7px",
    padding: "10px",
    "&:hover": {
      backgroundColor: darkTheme ? "" : "#F2F2F2",
    },
    "&:focus": {
      backgroundColor: darkTheme ? "" : "#EAEAEA",
    },
    "@media only screen and (max-width: 1400px) and (min-width: 1280px)": {
      height: "1.875rem",
      fontSize: "0.6875rem",
    },
  }));

  return (
    <div ref={wrapperRef} className="pos-rel">
      <Input
        iconStart={iconStart}
        iconEnd={arrowDown}
        placeholder={placeholder}
        onClick={() => {
          setDisplay(!display);
          setSearchValue("");
        }}
        value={search}
        css={{
          borderRadius: "8px 8px 8px 8px",
          "&:focus": {
            borderWidth: "1px",
            borderStyle: "solid",
          },
          ...css,
        }}
        disabled={disabled}
        onChange={(e) => {
          setSearch(e.target.value);
          setSearchValue(e.target.value);
          if (e.target.value === "") {
            setSelectedValue("");
          }
        }}
        darkTheme={darkTheme}
      />
      {display && (
        <div
          style={{
            overflow: "auto",
            maxHeight: "200px",
            position: "absolute",
            borderRadius: "8px",
            // borderTop: "1px solid rgba(140, 140, 140, 0.4)",
            backgroundColor: "#FAFAFA",
            top: "50px",
            zIndex: 1000,
            boxShadow: "5px 5px 10px rgba(31, 31, 31, 0.15)",
            ...cssDiv,
            border: `1px solid ${
              darkTheme ? COLORS.dark_grey : COLORS.primary
            }`,
          }}
        >
          {options.filter((item) =>
            item.toLowerCase().includes(searchValue.toLowerCase())
          ).length === 0 ? (
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
                backgroundColor: darkTheme ? "#1f1f1f" : "#FAFAFA",
                margin: "0px 7px",
                padding: "10px 10px 0px 10px",
              }}
            >
              <p>{"No options"}</p>
            </div>
          ) : (
            getSuggestions(searchValue).map((item, index) => (
              <OptionDiv
                style={
                  options.length - 1 !== index
                    ? {
                        borderBottom: "1px solid rgba(140, 140, 140, 0.4)",
                      }
                    : { borderBottom: "0px", marginBottom: "3px" }
                }
                key={index}
                onClick={() => {
                  click(item);
                }}
                tabIndex="0"
              >
                <p style={{ margin: "0" }}>{item}</p>
              </OptionDiv>
            ))
          )}
        </div>
      )}
    </div>
  );
}
