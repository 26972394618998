import React from "react";
import { Checkbox } from "@mui/material"
import { COLORS } from "../../../../../styles/themes/default/colors";
import map from "../../../assets/optimizationMap.svg"

const OptimizationMap = () => {

    const data = [
        { location: "Savannah", state: "GE", percentage: 1, active: true },
        { location: "Long Beach", state: "LA", percentage: 5, active: true },
        { location: "Los Angeles", state: "LA", percentage: 27, active: true },
    ]
    return (
        <div className="w100">
            <div className="w100 d-flex f-dir-col gap6">
                {data?.map((item) => (
                    <div
                        key={item}
                        className="w100 d-flex justify-between"
                    >
                        <div className="d-flex align-center"
                            style={{
                                width: "210px",
                            }}
                        >
                            <Checkbox
                                checked={item.active}
                                style={{
                                    padding: 0,
                                    color: COLORS.purple
                                }}
                            />
                            <p className="c-white fs14 m0">{item.location}{", "}{item.state}</p>
                        </div>
                        <p className="fw600 fs14 c-white m0">{item.percentage}%</p>
                        <div className="d-flex gap4 justify-end" style={{ width: "140px" }} />
                    </div>
                ))}
            </div>
            <p className="c-dark-grey fs14 mb8">Optimization per state:</p>
            <div className="w100 d-flex justify-center">
                <img
                    src={map}
                    alt="Optimization map"
                />
            </div>

        </div >
    );
};
export default OptimizationMap;