import React from "react";

import plantGreen from "../../../../assets/img/plantGreen.svg";
import plantGray from "../../../../assets/img/plantGray.svg";
import plantHandBlue from "../../../../assets/img/plantHandBlue.svg";
import plantHandGray from "../../../../assets/img/plantHandGray.svg";
import plantHandGrayBlue from "../../../../assets/img/plantHandGrayBlue.svg";
import plantHalfGreen from "../../../../assets/img/half_rating.svg";

const CustomerRating = (props) => {
  return (
    <div className="ratingDivMain">
      <p className="c-white fw600">Rating</p>
      <div className="ratingDiv">
        <p className="c-white m0 mb8">Sustainability scores</p>
        <div className="ratingRow">
          <div className="plantDiv">
            {Array(5)
              .fill(0)
              .map((item, index) => {
                return index + 1 <=
                  props.customer_rating.sustainability_score ? (
                  <img
                    key={index}
                    src={plantGreen}
                    className="plantImg"
                    alt="plantImg"
                  />
                ) : index + 0.5 <= props.customer_rating.sustainability_score &&
                  index + 1 >= props.customer_rating.sustainability_score ? (
                  <img
                    alt="scores"
                    src={plantHalfGreen}
                    className="plantImg"
                    key={index}
                  />
                ) : (
                  <img
                    key={index}
                    src={plantGray}
                    className="plantImg"
                    alt="plantImg"
                  />
                );
              })}
          </div>
          <div className="ratingValue">
            <div className="ratingValueScore">
              <p className="p-24-b-white" style={{ marginBottom: 0 }}>
                {props.customer_rating.sustainability_score?.toFixed(2)}
              </p>
              <p className="p-24-gray">/5</p>
            </div>
            <div className="ratedDiv">
              <p className="ratedText">Rated by</p>
              <p className="ratedText">NCAA</p>
            </div>
          </div>
        </div>
        <p className="ratingRowText">Industry benchmark</p>
        <div className="ratingRow">
          <div className="plantDiv">
            {Array(5)
              .fill(0)
              .map((item, index) => {
                return index + 1 <=
                  props.customer_rating.industry_benchmark_score ? (
                  <img
                    key={index}
                    src={plantGreen}
                    className="plantImg"
                    alt="plantImg"
                  />
                ) : index + 0.5 <=
                    props.customer_rating.industry_benchmark_score &&
                  index + 1 >=
                    props.customer_rating.industry_benchmark_score ? (
                  <img
                    alt="scores"
                    src={plantHalfGreen}
                    className="plantImg"
                    key={index}
                  />
                ) : (
                  <img
                    key={index}
                    src={plantGray}
                    className="plantImg"
                    alt="plantImg"
                  />
                );
              })}
          </div>
          <div className="ratingValue">
            <div className="ratingValueScore">
              <p className="p-24-b-white" style={{ marginBottom: 0 }}>
                {props.customer_rating.industry_benchmark_score?.toFixed(2)}
              </p>
              <p className="p-24-gray">/5</p>
            </div>
            <div className="ratedDiv">
              <p className="ratedText">Rated by</p>
              <p className="ratedText">NCAA</p>
            </div>
          </div>
        </div>
        <p className="ratingRowText">Supply chain partner score</p>
        <div className="ratingRow" style={{ marginBottom: "0" }}>
          <div className="plantDiv">
            {Array(5)
              .fill(0)
              .map((item, index) => {
                return index + 1 <=
                  props.customer_rating.supply_chain_partner_score ? (
                  <img
                    key={index}
                    src={plantHandBlue}
                    className="plantImg"
                    alt="plantImg"
                  />
                ) : index + 0.5 <=
                    props.customer_rating.supply_chain_partner_score &&
                  index + 1 >=
                    props.customer_rating.supply_chain_partner_score ? (
                  <img
                    key={index}
                    src={plantHandGrayBlue}
                    className="plantImg"
                    alt="plantImg"
                  />
                ) : (
                  <img
                    key={index}
                    src={plantHandGray}
                    className="plantImg"
                    alt="plantImg"
                  />
                );
              })}
          </div>
          <div className="ratingValue">
            <div className="ratingValueScore">
              <p className="p-24-b-white" style={{ marginBottom: 0 }}>
                {props.customer_rating.supply_chain_partner_score?.toFixed(2)}
              </p>
              <p className="p-24-gray">/5</p>
            </div>
            <div className="ratedDiv">
              <p className="ratedText">Rated by</p>
              <p className="ratedText">NCAA</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default CustomerRating;
