import React, { useState, useEffect } from "react";

import { CustomLoader } from "../../components/CustomLoader";
import fetchWrapper from "../../helpers/fetch-wrapper";
import SingleNotification from "./components/SingleNotification";

import useStore from "../../store/store";

import { COLORS } from "../../styles/themes/default/colors";
import { APP_URL } from "../../utils/constants";
import { createTheme } from "@mui/material";
import { styled } from "@mui/material/styles";

const NotificationSettings = () => {
  const currentUser = useStore((state) => state.currentUser);
  const [newUserNotification, setNewUserNotification] = useState([]);
  const [
    demurrageStatusChangeNotifications,
    setDemurrageStatusChangeNotifications,
  ] = useState([]);
  const [dueDateNotifications, setDueDateNotifications] = useState([]);
  const [demurrageUpdateNotifications, setDemurrageUpdateNotifications] =
    useState([]);
  const [shipperUpdateNotifications, setShipperUpdateNotifications] = useState(
    []
  );
  const [activeAppNotifications, setActiveAppNotifications] = useState([]);
  const [activeEmailNotifications, setActiveEmailNotifications] = useState([]);
  const [loader, setLoader] = useState(false);

  useEffect(() => {
    async function getNotificationsSettings() {
      setLoader(true);
      await fetchWrapper
        .get(`${APP_URL}/notification/settings/`)
        .then((resp) => {
          setActiveAppNotifications(resp.app_active_notifications);
          setActiveEmailNotifications(resp.email_active_notifications);
          let temp = resp.notification_scheme.list_of_notifications;
          setNewUserNotification(
            temp.filter((item) => item.type === "NEW_USER")
          );
          setDemurrageStatusChangeNotifications(
            temp.filter((item) => item.type === "DEMURRAGE_STATUS_CHANGE")
          );
          setDueDateNotifications(
            temp.filter((item) => item.type === "DUE_DATE")
          );
          setDemurrageUpdateNotifications(
            temp.filter((item) => item.type === "DEMURRAGE_UPDATES")
          );
          setShipperUpdateNotifications(
            temp.filter((item) => item.type === "SHIPPER_UPDATES")
          );
          setLoader(false);
        });
    }
    getNotificationsSettings();
  }, []);

  const formatStatusTitle = (name) => {
    if (name.split("-").length <= 1) {
      return name;
    }
    let tmp = name.split("-")[1].trim();
    if (tmp === "PAID") {
      return "Cleared";
    } else if (tmp === "COMPLETED") {
      return "Paid Back";
    }
    return tmp[0].toUpperCase() + tmp.substring(1).toLowerCase();
  };

  const formatDemurrageDueDate = (date, purpose) => {
    let tmp = date.split("-")[1].replace("left", "").trim();
    if (purpose === "title") {
      return tmp + " before";
    } else {
      return tmp === "0 days" ? "Today" : "in " + tmp;
    }
  };

  const formatDemurrageUpdate = (name) => {
    let user =
      currentUser?.user?.role === "SA" || currentUser?.user?.role === "SR"
        ? "customer"
        : "CDL";
    if (user === "CDL") {
      if (name === "Customer commented on demurrage request") {
        return [
          "New customer comment",
          "Notify me when a customer leaves a comment on a demurrage request",
        ];
      } else
        return [
          "Container at risk - 1 day",
          "Notify me when a demurrage is at Risk",
        ];
    }
    return name === "New comment on demurrage"
      ? [
          "New CDL representative comment",
          "Notify me when a CDL representative leaves a comment on a demurrage request",
        ]
      : [
          "New document uploaded",
          "Notify me when a CDL representative uploads a document on a demurrage request",
        ];
  };

  const handleChangeAppNotifications = (id) => {
    fetchWrapper.post(`${APP_URL}/notification/update_app_notification/${id}/`);
  };

  const handleChangeEmailNotifications = (id) => {
    fetchWrapper.post(
      `${APP_URL}/notification/update_email_notification/${id}/`
    );
  };

  const theme1 = createTheme({
    breakpoints: {
      values: {
        xs: 0,
        mobile: 376,
        sm: 600,
        md: 900,
        lg: 1200,
        xl: 1536,
      },
    },
  });

  const smDown = theme1.breakpoints.down("sm");
  const mdDown = theme1.breakpoints.down("md");
  const lgDown = theme1.breakpoints.down("lg");
  const mobileDown = theme1.breakpoints.down("mobile");

  const LoaderDiv = styled("div")({
    width: "100%",
    height: "500px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  });

  const MainTitle = styled("h2")({
    fontSize: "25px",
    lineHeight: "30px",
    fontWeight: "700",
    [smDown]: {
      fontSize: "22px",
      lineHeight: "26px",
    },
    [mobileDown]: {
      fontSize: "16px",
      lineHeight: "20px",
    },
    "@media only screen and (max-width: 1400px) and (min-width: 1280px)": {
      fontSize: "18px",
    },
  });

  // const MainSubtitle = styled("p")({
  //   fontSize: "14px",
  //   fontWeight: "400",
  //   lineHeight: "17px",
  //   margin: "0",
  //   [mobileDown]: {
  //     fontSize: "12px",
  //     lineHeight: "14px",
  //   },
  // });

  const HeaderDiv = styled("div")({
    display: "flex",
    padding: "0px 50px 0 0 ",
    borderRadius: "5px",
    // [lgDown]: {
    //   padding: "30px 85px",
    // },
    // [mdDown]: {
    //   padding: "30px 75px",
    // },
    // [smDown]: {
    //   padding: "30px",
    // },
    // [mobileDown]: {
    //   padding: "20px",
    // },
  });

  const MainDiv = styled("div")({
    borderRadius: "5px",
    marginBottom: "15px",
    [lgDown]: {
      padding: "30px 85px",
    },
    [mdDown]: {
      padding: "30px 75px",
    },
    [smDown]: {
      padding: "30px",
    },
    [mobileDown]: {
      padding: "20px",
    },

    scrollbarWidth: "thin",
    "&::-webkit-scrollbar": {
      width: "0.4em",
    },
    "&::-webkit-scrollbar-track": {
      background: "#f1f1f1",
    },
    "&::-webkit-scrollbar-thumb": {
      backgroundColor: "#888",
    },
    "&::-webkit-scrollbar-thumb:hover": {
      background: "#555",
    },
  });

  const SectionTitle = styled("h3")({
    fontSize: "20px",
    padding: "20px",
    fontWeight: "600",
    lineHeight: "24px",
    color: COLORS.sunrise_orange,
    margin: "0",
    [smDown]: {
      fontSize: "18px",
      lineHeight: "20px",
    },
    [mobileDown]: {
      fontSize: "16px",
      lineHeight: "18px",
    },
    "@media only screen and (max-width: 1400px) and (min-width: 1280px)": {
      fontSize: "14px",
    },
  });

  const NotificationSection = styled("div")({
    margin: "5px 0",
    background: "#ffffff",
    paddingBottom: "10px",
  });

  const NotificationHeaderP = styled("p")({
    fontWeight: "600",
  });

  return (
    <>
      {loader ? (
        <LoaderDiv>
          <CustomLoader size="7vh" />
        </LoaderDiv>
      ) : (
        <div style={{ width: "65%" }}>
          <HeaderDiv>
            <div style={{ width: "80%" }}>
              <MainTitle>Notifications settings</MainTitle>
            </div>
            <div
              style={{
                width: "20%",
                display: "flex",
                justifyContent: "space-between",
                marginTop: "10px",
              }}
            >
              <p className="p-16-b">In-app</p>
              <p className="p-16-b">E-mail</p>
            </div>
          </HeaderDiv>
          <MainDiv>
            {currentUser?.user?.role === "CA" &&
              newUserNotification.length > 0 && (
                <NotificationSection>
                  <SectionTitle>Shipper changes</SectionTitle>
                  {newUserNotification.map((item) => {
                    let hasEmailNotification = item.has_email_notification;
                    let hasActiveApp = activeAppNotifications?.includes(
                      item.id
                    );
                    let hasActiveEmail = activeEmailNotifications?.includes(
                      item.id
                    );
                    return (
                      <SingleNotification
                        title={"New customer signed up"}
                        description={
                          "Notify me when a new shipper registers in the application."
                        }
                        hasActiveApp={hasActiveApp}
                        hasActiveEmail={hasActiveEmail}
                        handleChangeAppNotifications={
                          handleChangeAppNotifications
                        }
                        handleChangeEmailNotifications={
                          handleChangeEmailNotifications
                        }
                        id={item.id}
                        key={item.id}
                        hasEmailNotifications={hasEmailNotification}
                      />
                    );
                  })}
                </NotificationSection>
              )}
            {demurrageStatusChangeNotifications.length > 0 && (
              <NotificationSection>
                <SectionTitle>Demurrage request status change</SectionTitle>
                <p className="p-16" style={{ paddingLeft: "20px" }}>
                  Notify me when a demurrage status is changed
                </p>
                {demurrageStatusChangeNotifications.map((item) => {
                  let title = formatStatusTitle(item.name);
                  let hasEmailNotification = item.has_email_notification;
                  let hasActiveApp = activeAppNotifications?.includes(item.id);
                  let hasActiveEmail = activeEmailNotifications?.includes(
                    item.id
                  );
                  return (
                    <SingleNotification
                      title={title}
                      hasActiveApp={hasActiveApp}
                      hasActiveEmail={hasActiveEmail}
                      handleChangeAppNotifications={
                        handleChangeAppNotifications
                      }
                      handleChangeEmailNotifications={
                        handleChangeEmailNotifications
                      }
                      id={item.id}
                      key={item.id}
                      hasEmailNotifications={hasEmailNotification}
                    />
                  );
                })}
              </NotificationSection>
            )}
            {dueDateNotifications.length > 0 && (
              <NotificationSection>
                <SectionTitle>Due date notifications</SectionTitle>
                <p className="p-16" style={{ paddingLeft: "20px" }}>
                  Notify me when a demurrage payment is near due date.
                </p>
                {dueDateNotifications.map((item) => {
                  let hasEmailNotification = item.has_email_notification;
                  let hasActiveApp = activeAppNotifications?.includes(item.id);
                  let hasActiveEmail = activeEmailNotifications?.includes(
                    item.id
                  );
                  return (
                    <SingleNotification
                      title={formatDemurrageDueDate(item.name, "title")}
                      hasActiveApp={hasActiveApp}
                      hasActiveEmail={hasActiveEmail}
                      handleChangeAppNotifications={
                        handleChangeAppNotifications
                      }
                      handleChangeEmailNotifications={
                        handleChangeEmailNotifications
                      }
                      id={item.id}
                      key={item.id}
                      hasEmailNotifications={hasEmailNotification}
                    />
                  );
                })}
              </NotificationSection>
            )}
            {demurrageUpdateNotifications.length > 0 && (
              <NotificationSection>
                <SectionTitle>Demurrage request updates</SectionTitle>
                {demurrageUpdateNotifications.map((item) => {
                  // let [title, description] = formatDemurrageUpdate(item.name);
                  let hasEmailNotification = item.has_email_notification;
                  let hasActiveApp = activeAppNotifications?.includes(item.id);
                  let hasActiveEmail = activeEmailNotifications?.includes(
                    item.id
                  );
                  return (
                    <SingleNotification
                      title={item.name}
                      // description={description}
                      hasActiveApp={hasActiveApp}
                      hasActiveEmail={hasActiveEmail}
                      handleChangeAppNotifications={
                        handleChangeAppNotifications
                      }
                      handleChangeEmailNotifications={
                        handleChangeEmailNotifications
                      }
                      id={item.id}
                      key={item.id}
                      hasEmailNotifications={hasEmailNotification}
                    />
                  );
                })}
              </NotificationSection>
            )}
            {currentUser?.user?.role === "CC" &&
              shipperUpdateNotifications.length > 0 && (
                <NotificationSection>
                  <SectionTitle>Customer profile updates</SectionTitle>
                  {shipperUpdateNotifications.map((item) => {
                    let hasEmailNotification = item.has_email_notification;
                    let hasActiveApp = activeAppNotifications?.includes(
                      item.id
                    );
                    let hasActiveEmail = activeEmailNotifications?.includes(
                      item.id
                    );
                    return (
                      <SingleNotification
                        title={"Customers profile "}
                        description={
                          "Notify me when a customer updates their profile information"
                        }
                        hasActiveApp={hasActiveApp}
                        hasActiveEmail={hasActiveEmail}
                        handleChangeAppNotifications={
                          handleChangeAppNotifications
                        }
                        handleChangeEmailNotifications={
                          handleChangeEmailNotifications
                        }
                        id={item.id}
                        key={item.id}
                        hasEmailNotifications={hasEmailNotification}
                      />
                    );
                  })}
                </NotificationSection>
              )}
          </MainDiv>
        </div>
      )}
    </>
  );
};

export default NotificationSettings;
