import { styled } from "@mui/material";

export const MainTitle = ({ text, css }) => {
  const MainTitle = styled("p")(() => ({
    fontSize: "24px",
    fontWeight: "600",
    ...css,
  }));
  return <MainTitle>{text}</MainTitle>;
};

export const TextTitle = ({ text, css }) => {
  const TextTitle = styled("p")(() => ({
    fontSize: "16px",
    fontWeight: "600",
    ...css,
  }));
  return <TextTitle>{text}</TextTitle>;
};

export const Text = ({ text, css }) => {
  const Text = styled("p")(() => ({
    fontSize: "16px",
    ...css,
  }));
  return <Text>{text}</Text>;
};

export const LinkText = ({ text, css }) => {
  const Link = styled("p")(() => ({
    fontSize: "14px",
    fontWeight: "600",
    ...css,
  }));
  return <Link>{text}</Link>;
};

export const TableDescription = ({ text, css }) => {
  const TableDescription = styled("p")(() => ({
    fontSize: "14px",
    ...css,
  }));
  return <TableDescription>{text}</TableDescription>;
};
