import React from "react";

import "../../styles/carttable.scss";

import esgStore from "../../../../store/esgStore";

const CartTableSummary = ({ values, tableHeight }) => {
  const offsetProjects = esgStore((state) => state.offsetProjects);
  return (
    <div className="carttable_main_div" style={{}}>
      <div className="carttable_header">
        <div style={{ width: "40%" }}>
          <p className="c-white fw600 m0">Project</p>
        </div>
        <div
          style={{ width: "15%", display: "flex", justifyContent: "flex-end" }}
        >
          <p className="c-white fw600 m0">Price</p>
        </div>
        <div
          style={{ width: "30%", display: "flex", justifyContent: "center" }}
        >
          <p className="c-white fw600 m0">Quantity</p>
        </div>
        <div style={{ width: "15%", textAlign: "right", paddingRight: "16px" }}>
          <p className="c-white fw600 m0">Total</p>
        </div>
      </div>
      <div
        style={{
          // flexGrow: 1,
          // height: 0,
          overflowY: "auto",
          maxHeight: `${tableHeight}px`,
          width: "100%",
        }}
      >
        {Object.keys(values).map((item, index) => {
          let data = offsetProjects.at(item);
          return (
            <div className="carttable_row" key={index}>
              <div
                style={{
                  width: "40%",
                  display: "flex",
                  height: "100%",
                  padding: "16px",
                  paddingLeft: "0",
                }}
              >
                <img
                  src={data.image_url}
                  alt=""
                  style={{ width: "70px", borderRadius: "4px" }}
                />
                <div
                  style={{
                    height: "100%",
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "space-around",
                    marginLeft: "16px",
                  }}
                >
                  <p className="c-white m0" style={{ fontSize: "14px" }}>
                    {data.name.slice(0, 50)}
                  </p>
                  {/* <p
                      className="c-dark-grey m0"
                      style={{ fontSize: "14px" }}
                    >
                      {data.subtitle}
                    </p> */}
                </div>
              </div>
              <div
                style={{
                  width: "15%",
                  display: "flex",
                  height: "100%",
                  alignItems: "center",
                  justifyContent: "flex-end",
                }}
              >
                <p className="c-white m0">
                  ${parseFloat(data.price_value).toFixed(2)} / tCO<sub>2</sub>e
                </p>
              </div>
              <div
                style={{
                  width: "30%",
                  display: "flex",
                  height: "100%",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <p className="c-white fw600 m0">{values[item]}</p>
              </div>
              <div
                style={{
                  width: "15%",
                  display: "flex",
                  height: "100%",
                  alignItems: "center",
                  justifyContent: "flex-end",
                  padding: "16px",
                  paddingLeft: "0",
                }}
              >
                <p className="c-purple m0 fw600">
                  ${parseFloat(values[item] * data.price_value).toFixed(2)}
                </p>
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
};
export default CartTableSummary;
