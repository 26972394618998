import React from "react";
import DonutWithTwoValues from "../DonutWithTwoValues";
import ColumnBarStatistics from "../ColumnBarStatistics";
import SingleLabelColumnBar from "../SingleLabelColumnBar";

const OutToDeliveryStatistics = ({ statistics }) => {
  return (
    <div style={{ height: "100%", display: "flex", flexDirection: "column" }}>
      <div
        className="template_container"
        style={{
          height: "auto",
        }}
      >
        <p className="container_statistics_title">On time status</p>
        <DonutWithTwoValues
          colors={["#F5AF45", "#51DA87"]}
          labels={["Behind", "On Time"]}
          values={[statistics?.behind, statistics?.on_time]}
        />
      </div>
      <div
        className="template_container"
        style={{
          marginTop: "16px",
          flexGrow: 1,
        }}
      >
        <p className="container_statistics_title">Available Street Turns</p>
        <SingleLabelColumnBar
          colors={[
            "#F56E43",
            "#FF9979",
            "#F69235",
            "#FFB169",
            "#ECC437",
            "#FFDE67",
          ]}
          streetTurnData={statistics?.street_turns}
        />
      </div>
    </div>
  );
};

export default OutToDeliveryStatistics;
