import React from "react";
import ReactApexChart from "react-apexcharts";
import { COLORS } from "../../../../../styles/themes/default/colors";

const RadarChart = (props) => {
  let options = {
    chart: {
      type: "radar",
      toolbar: {
        show: false,
      },
    },

    fill: {
      opacity: 0.4,
      colors: ["#00FF65"],
    },

    stroke: {
      show: true,
      width: 2,
      colors: ["#00FF65"],
    },
    markers: {
      size: 0,
    },
    yaxis: {
      show: false,
      max: 100,
    },

    xaxis: {
      categories: props.labels,
      type: "value",
      labels: {
        offsetY: 5,
        style: {
          fontFamily: "Montserrat",
          colors:
            props.values && props.values.length > 0
              ? props.values.map((item) => {
                  return item > 0 ? COLORS.off_white : COLORS.dark_grey;
                })
              : COLORS.dark_grey,
          transition: "0.3s all ease-in-out",
          fontSize: "16px",
          fontWeight: 400,
        },
      },
    },

    plotOptions: {
      radar: {
        polygons: {
          strokeColors: [
            "#e8e8e8",
            "transparent",
            "transparent",
            "transparent",
            "transparent",
            "transparent",
            "transparent",
            "transparent",
            "transparent",
            "transparent",
          ],
          strokeWidth: 1,
          connectorColors: COLORS.dark_grey,
        },
      },
    },
    grid: {
      padding: {
        bottom: -50,
        top: -30,
      },
    },
  };
  return (
    <div className="radar_chart">
      <ReactApexChart
        type="radar"
        height={220}
        width={375}
        series={[{ name: "", data: props.values }]}
        options={options}
      />
    </div>
  );
};
export default RadarChart;
