import React from "react";
import { COLORS } from "../../../../styles/themes/default/colors";

const LocationWithNumber = ({ setClusterData, cluster, setShowTable }) => {
  const blackFont = [
    "#51da87",
    "#51B1DA",
    "#FF55BB",
    "#C8DF6E",
    "#DF826E",
  ].includes(cluster.group.at(0).color)
    ? true
    : false;
  return (
    <button
      onClick={() => {
        setShowTable(true);
        setClusterData({
          ids: cluster.group.map((item) => {
            return item.container_id;
          }),
          minIndex: Math.min.apply(
            Math,
            cluster.group.map((item) => {
              return item.index;
            })
          ),
        });
      }}
      style={{
        width: "24px",
        height: "24px",
        borderRadius: "25px",
        backgroundColor: cluster.mutual_statuses
          ? COLORS.off_white
          : cluster.group.at(0).color,
        color:
          blackFont || cluster.mutual_statuses
            ? COLORS.off_black
            : COLORS.off_white,
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        fontWeight: 600,
        fontSize: "14px",
        border: "none",
        outline: "none",
      }}
    >
      <p style={{ margin: 0 }}> {cluster.number}</p>
    </button>
  );
};

export default LocationWithNumber;
