import React, {
  useCallback,
  useMemo,
  useEffect,
  useState,
  useRef,
} from "react";
import { Box } from "@mui/material";
import {
  GoogleMap,
  useJsApiLoader,
  OverlayView,
  Polyline,
} from "@react-google-maps/api";
import tempIcon from "../../../../assets/img/Error.svg";
import { ReactComponent as DestinationIcon } from "../../assets/destination_icon.svg";
import { ReactComponent as OriginIcon } from "../../assets/origin_icon.svg";
import LocationCircle from "./LocationCircle";
import LocationIcon from "./LocationIcon";
import { CustomLoader } from "../../../../components/CustomLoader";
import { mapStyles } from "../../utils/contants";
import { HaversineDistance } from "../../utils/functions";

import LocationWithNumber from "./LocationWithNumber";
import { GOOGLE_API_KEY } from "../../../../utils/constants";
import containerStore from "../../../../store/containerStore";

const containerStyle = {
  width: "100%",
  height: "100%",
  borderRadius: "5px",
};

function TrackingMap({
  data,
  zoom,
  isTerminalView,
  isDetailView,
  loading,
  setClusterData,
  mainColor,
  setShowTable,
}) {
  const [polylineData, setPolylineData] = useState([]);
  const [dashedPolylineData, setDashedPolylineData] = useState([]);

  const currentDisplayContainer = containerStore(
    (state) => state.currentDisplayContainer
  );

  const mapRef = useRef();

  const getPixelPositionOffsetBottom = (width, height) => ({
    x: -(width / 2),
    y: -height,
  });
  const getPixelPositionOffsetCenter = (width, height) => ({
    x: -(width / 2),
    y: -(height / 2),
  });
  const onLoad = useCallback(
    (map) => {
      mapRef.current = map;
    },
    [mapRef]
  );

  const onUnmount = useCallback(() => {
    mapRef.current = undefined;
  }, []);
  const { isLoaded } = useJsApiLoader({
    id: "google-map-script",
    googleMapsApiKey: GOOGLE_API_KEY,
  });
  const options = useMemo(
    () => ({
      disableDefaultUI: true,
      zoomControl: isTerminalView || isDetailView ? true : false,
      styles: mapStyles,
      gestureHandling: isTerminalView || isDetailView ? "auto" : "auto",
      zoom: zoom,
      backgroundColor: "black",
      clickableIcons: false,
    }),
    [isTerminalView, isDetailView]
  );
  useEffect(() => {
    if (data && data.length > 0) {
      const bounds = new window.google.maps.LatLngBounds();
      if (Object.keys(currentDisplayContainer).length > 0) {
        if (currentDisplayContainer?.path.length > 0) {
          let min_index = 0;
          let min_distance = HaversineDistance(
            currentDisplayContainer?.path.at(0),
            currentDisplayContainer?.coordinates
          );
          currentDisplayContainer?.path.forEach((item, index) => {
            let distance = HaversineDistance(
              item,
              currentDisplayContainer?.coordinates
            );
            if (distance < min_distance) {
              min_distance = distance;
              min_index = index;
            }
          });
          setPolylineData(
            currentDisplayContainer?.path
              .slice(0, min_index)
              .concat(currentDisplayContainer?.coordinates)
          );
          setDashedPolylineData(
            [currentDisplayContainer.coordinates].concat(
              currentDisplayContainer?.path.slice(min_index)
            )
          );
        }
        if (
          currentDisplayContainer?.coordinates?.lat &&
          currentDisplayContainer?.coordinates?.lng
        ) {
          bounds.extend(currentDisplayContainer.coordinates);
        }

        if (currentDisplayContainer?.whole_route) {
          if (
            currentDisplayContainer?.destination?.lat &&
            currentDisplayContainer?.destination?.lng
          ) {
            bounds.extend({
              lat: currentDisplayContainer.destination.lat,
              lng: currentDisplayContainer.destination.lng,
            });
          }
          if (
            currentDisplayContainer?.origin?.lat &&
            currentDisplayContainer?.origin?.lng
          ) {
            bounds.extend({
              lat: currentDisplayContainer.origin.lat,
              lng: currentDisplayContainer.origin.lng,
            });
          }
        } else {
          const offset = 0.2;
          const offsetCoord1 = {
            lat: currentDisplayContainer?.coordinates?.lat + offset,
            lng: currentDisplayContainer?.coordinates?.lng + offset,
          };
          const offsetCoord2 = {
            lat: currentDisplayContainer?.coordinates?.lat - offset,
            lng: currentDisplayContainer?.coordinates?.lng - offset,
          };
          bounds.extend(offsetCoord1);
          bounds.extend(offsetCoord2);
        }
      } else {
        setDashedPolylineData([]);
        setPolylineData([]);
        if (data.length > 1) {
          if (data.at(0)?.group) {
            data.forEach((item) => {
              bounds.extend(item.group.at(0).coordinates);
            });
          } else {
            data.forEach((item) => {
              bounds.extend(item.coordinates);
            });
          }
        } else {
          const offset = 0.2;
          const coords = data?.at(0)?.group
            ? data?.at(0)?.group.at(0).coordinates
            : data.at(0).coordinates;
          const offsetCoord1 = {
            lat: coords?.lat + offset,
            lng: coords?.lng + offset,
          };
          const offsetCoord2 = {
            lat: coords?.lat - offset,
            lng: coords?.lng - offset,
          };
          bounds.extend(offsetCoord1);
          bounds.extend(offsetCoord2);
        }
      }
      mapRef.current.fitBounds(bounds, {
        top: 30,
        right: 30,
        bottom: 30,
        left: 30,
      });
    } else {
      setPolylineData([]);
      setDashedPolylineData([]);
    }
  }, [data, currentDisplayContainer]);
  return (
    <Box
      sx={{
        height: isTerminalView ? `calc(100% - 267px)` : "100%",
        width: "100%",
        marginTop: isTerminalView ? "16px" : "0px",
      }}
      id="map_box"
    >
      {isLoaded ? (
        <GoogleMap
          ref={mapRef}
          options={options}
          mapContainerStyle={containerStyle}
          onLoad={onLoad}
          onUnmount={onUnmount}
          style={{ position: "relative" }}
        >
          {loading ? (
            <div style={{ position: "absolute", top: "50%", left: "50%" }}>
              <CustomLoader darktheme="true" size="4vh" />
            </div>
          ) : Object.keys(currentDisplayContainer).length === 0 ? (
            data !== undefined &&
            data.length > 0 &&
            (isTerminalView || isDetailView ? (
              data.length > 1 ? (
                data.map((cluster, index) => {
                  return (
                    <OverlayView
                      key={cluster.group.at(0).index - 1}
                      mapPaneName={OverlayView.OVERLAY_MOUSE_TARGET}
                      icon={tempIcon}
                      position={cluster.group.at(0).coordinates}
                      animation={2}
                      getPixelPositionOffset={getPixelPositionOffsetCenter}
                    >
                      {cluster.number === 1 ? (
                        <LocationIcon
                          setClusterData={setClusterData}
                          color={cluster.group.at(0)?.color}
                          icon={cluster.group.at(0)?.icon}
                          data={cluster.group.at(0)}
                          setShowTable={setShowTable}
                        />
                      ) : (
                        <LocationWithNumber
                          color={cluster.group.at(0)?.color}
                          setClusterData={setClusterData}
                          cluster={cluster}
                          setShowTable={setShowTable}
                        />
                      )}
                    </OverlayView>
                  );
                })
              ) : (
                <OverlayView
                  mapPaneName={OverlayView.OVERLAY_MOUSE_TARGET}
                  icon={tempIcon}
                  position={data.at(0).group.at(0).coordinates}
                  animation={2}
                  getPixelPositionOffset={getPixelPositionOffsetCenter}
                >
                  {data.at(0).number === 1 ? (
                    <LocationIcon
                      setClusterData={setClusterData}
                      data={data?.at(0).group.at(0)}
                      setShowTable={setShowTable}
                    />
                  ) : (
                    <LocationWithNumber
                      setClusterData={setClusterData}
                      cluster={data.at(0)}
                      setShowTable={setShowTable}
                    />
                  )}
                </OverlayView>
              )
            ) : (
              data.map((item, index) => {
                return (
                  <OverlayView
                    key={index - 1}
                    mapPaneName={OverlayView.OVERLAY_MOUSE_TARGET}
                    icon={tempIcon}
                    position={item.coordinates}
                    animation={2}
                    getPixelPositionOffset={getPixelPositionOffsetCenter}
                  >
                    {isTerminalView || isDetailView ? (
                      <LocationIcon
                        color={item?.color}
                        icon={item?.icon}
                        data={item}
                        setClusterData={setClusterData}
                        setShowTable={setShowTable}
                      />
                    ) : (
                      <LocationCircle
                        // isOnLeftSide={
                        //   item.coordinates.lng <
                        //   (mapRef?.current && mapRef.current?.getCenter()
                        //     ? mapRef.current.getCenter().lng()
                        //     : centerUs.lng)
                        // }
                        mainColor={mainColor}
                        data={item}
                        isTerminalView={isTerminalView}
                      />
                    )}
                  </OverlayView>
                );
              })
            ))
          ) : (
            <>
              {HaversineDistance(
                {
                  lat: currentDisplayContainer?.origin?.lat,
                  lng: currentDisplayContainer?.origin?.lng,
                },
                currentDisplayContainer?.coordinates
              ) > 20 &&
                HaversineDistance(
                  {
                    lat: currentDisplayContainer.destination.lat,
                    lng: currentDisplayContainer.destination.lng,
                  },
                  currentDisplayContainer.coordinates
                ) > 20 && (
                  <OverlayView
                    mapPaneName={OverlayView.OVERLAY_MOUSE_TARGET}
                    icon={tempIcon}
                    position={currentDisplayContainer.coordinates}
                    animation={2}
                    getPixelPositionOffset={getPixelPositionOffsetCenter}
                  >
                    <LocationIcon
                      data={currentDisplayContainer}
                      setShowTable={setShowTable}
                    />
                  </OverlayView>
                )}

              <OverlayView
                mapPaneName={OverlayView.OVERLAY_MOUSE_TARGET}
                icon={tempIcon}
                position={{
                  lat: currentDisplayContainer?.origin?.lat,
                  lng: currentDisplayContainer?.origin?.lng,
                }}
                animation={2}
                getPixelPositionOffset={getPixelPositionOffsetBottom}
              >
                <OriginIcon
                  style={{
                    width: "20px",
                    height: "20px",
                    stroke: currentDisplayContainer?.color,
                  }}
                />
              </OverlayView>
              <OverlayView
                mapPaneName={OverlayView.OVERLAY_LAYER}
                icon={tempIcon}
                position={{
                  lat: currentDisplayContainer.destination.lat,
                  lng: currentDisplayContainer.destination.lng,
                }}
                animation={2}
                getPixelPositionOffset={getPixelPositionOffsetBottom}
              >
                <DestinationIcon
                  style={{
                    width: "20px",
                    height: "20px",
                    fill: currentDisplayContainer?.color,
                  }}
                />
              </OverlayView>

              <Polyline
                path={polylineData}
                options={{
                  strokeColor: currentDisplayContainer?.color,
                  strokeOpacity: 1,
                  strokeWeight: 2,
                }}
              />
              <Polyline
                path={dashedPolylineData}
                options={{
                  strokeColor: currentDisplayContainer.color,
                  strokeOpacity: 0,
                  strokeWeight: 0,
                  icons: [
                    {
                      icon: {
                        path: "M 0,-1 0,1",
                        strokeOpacity: 1,
                        scale: 2,
                      },
                      offset: "0",
                      repeat: "15px",
                    },
                  ],
                }}
              />
            </>
          )}
        </GoogleMap>
      ) : (
        <></>
      )}
    </Box>
  );
}

export default React.memo(TrackingMap);
