import React, { useState, useEffect } from "react";
import { Route, Switch, Redirect, BrowserRouter } from "react-router-dom";
import Cookies from "js-cookie";

import PrivateRoute from "./router/PrivateRoute";
import Navigation from "./components/Navigation";
// import Alert from "./components/Alert";
import GuestApprovalPage from "./pages/GuestApproval/GuestApprovalPage";
import Account from "./pages/Account/Index";
import Profile from "./pages/Profile/Index";
import Customer from "./pages/Customer/Index";
import Demurrage from "./pages/Demurrage/Index";
import Esg from "./pages/Esg/Index";
import Admin from "./pages/AdminPanel/Index";
import Container from "./pages/Container/Index";
import CustomerProfile from "./pages/Customer/CustomerProfile/CustomerProfile";
import Sidebar from "./components/Sidebar";

import loginBackground from "./img/loginBackgroundNew.webp";

import ForgotPassword from "./pages/Account/ForgotPassword/ForgotPassword";
import { ThemeProvider } from "@mui/material";
import theme from "./styles/theme";
import { COLORS } from "./styles/themes/default/colors";
import { SkeletonTheme } from "react-loading-skeleton";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Analytics from "./pages/D&DAnalytics/Index";
import { QueryClientProvider, QueryClient } from "react-query";
import "./styles/templates.scss";
// import { ReactQueryDevtools } from "react-query/devtools";

const App = () => {
  const [cookie, setCookie] = useState();
  const [openDrawer, setOpenDrawer] = useState(true);
  const [path, setPath] = useState(window.location.pathname);

  const [navbarHeight, setNavbarHeight] = useState(0);

  useEffect(() => {
    let cookieValue = Cookies.get("access_token");
    setCookie(cookieValue);
  }, []);

  global.setCookie = setCookie;
  const pathname = window.location.pathname;

  const queryClient = new QueryClient({});

  // let cookie = Cookies.get("access_token");
  // const user = JSON.parse(localStorage.getItem("currentUser"));

  const loginClass = {
    // display: "flex",
    backgroundImage: `url(${loginBackground})`,

    boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)",
    backgroundRepeat: "no-repeat",
    backgroundSize: "cover",
    minHeight: "100%",
    position: "relative",

    "&::WebkitScrollbar": {
      display: "none",
    },
  };

  const guestClass = {
    backgroundRepeat: "no-repeat",
    backgroundSize: "cover",
    minHeight: "100vh",

    scrollbarWidth: "thin",
    "&::-webkit-scrollbar": {
      width: "0.4em",
    },
    "&::-webkit-scrollbar-track": {
      background: "#f1f1f1",
    },
    "&::-webkit-scrollbar-thumb": {
      backgroundColor: "#888",
    },
    "&::-webkit-scrollbar-thumb:hover": {
      background: "#555",
    },
  };

  const dashClass = {
    display: "flex",
    backgroundRepeat: "no-repeat",
    backgroundSize: "cover",
    minHeight: `calc(100% - ${navbarHeight}px)`,
    // minHeight: "92%",
    backgroundColor: ["/containers", "/esg", "/analytics"].some((item) =>
      path.startsWith(item)
    )
      ? "#313131"
      : COLORS.ligth_grey,
    transition: "0.3s ease-in-out all",
  };

  useEffect(() => {
    setNavbarHeight(document.getElementById("navbar_container")?.clientHeight);
  }, [document.getElementById("navbar_container")]);
  return (
    <div style={{ height: "100%" }}>
      <ThemeProvider theme={theme}>
        <SkeletonTheme baseColor="#313131" highlightColor="#525252">
          <QueryClientProvider client={queryClient}>
            <ToastContainer
              position="top-right"
              autoClose={3000}
              // toastClassName={css{
              //   marginTop: "60px",
              //   zIndex: 10000,
              //   fontFamily: "Montserrat",
              //   borderRadius: "8px",
              // }}
              style={{ zIndex: 10000 }}
              toastClassName="toast_styles"
            />
            <BrowserRouter>
              <Navigation
                openDrawer={openDrawer}
                setOpenDrawer={setOpenDrawer}
                setCookie={setCookie}
                setPathname={setPath}
              />
              <div
                style={
                  cookie
                    ? dashClass
                    : pathname.slice(1, 6) === "guest"
                    ? guestClass
                    : loginClass
                }
              >
                <Sidebar
                  cookie={cookie}
                  setCookie={setCookie}
                  openDrawer={openDrawer}
                  setOpenDrawer={setOpenDrawer}
                />
                <Switch>
                  <Redirect from="/:url*(/+)" to={pathname.slice(0, -1)} />
                  <PrivateRoute
                    path="/profile"
                    component={Profile}
                    setCookie={setCookie}
                  />
                  <PrivateRoute
                    path="/customers"
                    component={Customer}
                    setCookie={setCookie}
                  />
                  <PrivateRoute
                    path="/demurrage"
                    component={Demurrage}
                    setCookie={setCookie}
                  />
                  <PrivateRoute
                    path="/company"
                    component={CustomerProfile}
                    setCookie={setCookie}
                  />
                  <PrivateRoute
                    path="/admin"
                    component={Admin}
                    setCookie={setCookie}
                  />
                  <PrivateRoute
                    path="/containers"
                    component={Container}
                    setCookie={setCookie}
                  />
                  <PrivateRoute
                    path="/esg"
                    component={Esg}
                    setCookie={setCookie}
                  />
                  <PrivateRoute
                    path="/analytics"
                    component={Analytics}
                    setCookie={setCookie}
                  />
                  <Route
                    path="/guest/approval/:id/:token"
                    component={GuestApprovalPage}
                  />
                  <Route path="/account" component={Account} />
                  <Route path="/forgot-password" component={ForgotPassword} />
                  <Redirect from="*" to="/demurrage/list" />
                </Switch>
              </div>
            </BrowserRouter>
            {/* <ReactQueryDevtools initialIsOpen={false} /> */}
          </QueryClientProvider>
        </SkeletonTheme>
      </ThemeProvider>
    </div>
  );
};

export default App;
