import React, { useRef, useState, useEffect } from "react";

import "../../styles/portDetail.scss";
import { COLORS } from "../../../../styles/themes/default/colors";
import AlertRowWithPayment from "./AlertRowWithPayment";
import { Checkbox, FormControlLabel } from "@mui/material";
import NoContentScreen from "./NoContentScreen";
import { formatValue } from "../../../../utils/constants";
import { useMediaQuery, createTheme } from "@mui/material";
import Select from "../../../../components/Cdl/Select";
import containerStore from "../../../../store/containerStore";
import MultipleContainerModal from "../../modals/MultipleContainerModal";

const AlertTableWithPayment = ({
  containers,
  handleFavorite,
  handleRead,
  loadingContainer,
  setIsModalOpen,
  setIsDetentionModalOpen,
  clusterData,
  setClusterData,
}) => {
  const theme1 = createTheme({
    breakpoints: {
      values: {
        sl: 1400,
      },
    },
  });
  const smallLaptop = useMediaQuery(theme1.breakpoints.down("sl"));

  const currentDisplayContainer = containerStore(
    (state) => state.currentDisplayContainer
  );
  const setCurrentDisplayContainer = containerStore(
    (state) => state.setCurrentDisplayContainer
  );
  const setGetContainerId = containerStore((state) => state.setGetContainerId);
  const getContainerId = containerStore((state) => state.getContainerId);
  const notificationContainerId = containerStore(
    (state) => state.notificationContainerId
  );
  const setNotificationContainerId = containerStore(
    (state) => state.setNotificationContainerId
  );

  const [checked, setChecked] = useState([]);
  const [checkedAll, setCheckedAll] = useState(false);
  const [checkedTerminal, setCheckedTerminal] = useState("");

  const [isMultipleModalOpen, setIsMultipleModalOpen] = useState(false);

  const [total, setTotal] = useState(0);
  const [sortedContainer, setSortedContainers] = useState([]);
  const [sortQuery, setSortQuery] = useState("");

  useEffect(() => {
    if (notificationContainerId) {
      setGetContainerId(notificationContainerId);
      setNotificationContainerId("");
    }
  }, []);

  useEffect(() => {
    let sum = 0;
    containers.forEach((item) => {
      item.group.forEach((cont) => {
        if (checked.includes(cont.container_id)) {
          sum +=
            cont?.potential_demurrage_amount + cont?.potential_detention_amount;
        }
      });
    });
    setTotal(sum);
  }, [checked]);
  const listRef = useRef(null);
  useEffect(() => {
    if (clusterData?.ids && clusterData.ids.length > 0 && listRef.current) {
      window.requestAnimationFrame(() => {
        listRef.current.scrollTo({
          top: clusterData?.minIndex * 81,
          behavior: "smooth",
        });
      });
    } else if (
      currentDisplayContainer &&
      Object.keys(currentDisplayContainer).length > 0
    ) {
      let index = -1;
      sortedContainer.forEach((item) => {
        if (item.container_id === getContainerId) {
          index = item.index;
        }
      });
      const timer = setTimeout(() => {
        window.requestAnimationFrame(() => {
          listRef.current.scrollTo({
            top: index * 81,
            behavior: "smooth",
          });
        });
      }, 50);
      return () => clearTimeout(timer);
    }
  }, [getContainerId, currentDisplayContainer, clusterData]);
  const no_content = containers.length === 0;

  const handleClickChecked = (data, terminal) => {
    if (checkedTerminal === "") {
      setCheckedTerminal(terminal);
    }
    if (checked.includes(data.container_id)) {
      let filtered = checked.filter((item) => item !== data.container_id);
      if (filtered.length === 0) {
        setCheckedTerminal("");
        setCheckedAll(false);
      }
      setChecked(filtered);
    } else {
      setChecked(checked.concat([data.container_id]));
    }
  };

  useEffect(() => {
    setCurrentDisplayContainer({});
    if (!sortQuery) {
      setSortedContainers(
        containers.length > 0
          ? containers
              .map((cluster) => {
                return cluster.group.map((item) => {
                  return item;
                });
              })
              .reduce((a, b) => {
                return a.concat(b);
              })
          : []
      );
    } else if (sortQuery === "Detention First") {
      let d = [...sortedContainer].sort((a, b) =>
        a.is_in_detention && b?.is_in_demurrage ? -1 : 1
      );

      setSortedContainers(
        d.map((item, index) => {
          return { ...item, index: index };
        })
      );
    } else if (sortQuery === "Demurrage First") {
      let d = [...sortedContainer].sort((a, b) =>
        a.is_in_detention && b?.is_in_demurrage ? 1 : -1
      );

      setSortedContainers(
        d.map((item, index) => {
          return { ...item, index: index };
        })
      );
    } else if (sortQuery === "Price: Low to High") {
      let d = [...sortedContainer].sort((a, b) =>
        a?.potential_demurrage_amount + a?.potential_detention_amount >
        b?.potential_demurrage_amount + b?.potential_detention_amount
          ? 1
          : -1
      );

      setSortedContainers(
        d.map((item, index) => {
          return { ...item, index: index };
        })
      );
    } else if (sortQuery === "Price: High to Low") {
      let d = [...sortedContainer].sort((a, b) =>
        a?.potential_demurrage_amount + a?.potential_detention_amount >
        b?.potential_demurrage_amount + b?.potential_detention_amount
          ? -1
          : 1
      );
      setSortedContainers(
        d.map((item, index) => {
          return { ...item, index: index };
        })
      );
    }
    if (listRef?.current) {
      window.requestAnimationFrame(() => {
        listRef.current.scrollTo({
          top: 0,
          behavior: "smooth",
        });
      });
    }
  }, [sortQuery, containers]);
  return (
    <>
      <MultipleContainerModal
        isModalOpen={isMultipleModalOpen}
        setIsModalOpen={setIsMultipleModalOpen}
        container_ids={checked}
      />
      <div
        style={{
          display: "flex",
          width: "97%",
          justifyContent: "space-between",
          alignItems: "center",
          margin: "0px 8px",
        }}
      >
        <div
          style={{
            display: "flex",
            alignItems: "center",
            opacity: checkedTerminal ? 1 : 0.4,
            transition: "0.3s all ease-in-out",
          }}
        >
          <FormControlLabel
            style={{ marginTop: "8px" }}
            control={
              <Checkbox
                disabled={checkedTerminal === ""}
                style={{ color: checkedAll ? COLORS.purple : COLORS.dark_grey }}
                onClick={() => {
                  if (!checkedAll) {
                    let data = [];
                    containers.forEach((item) => {
                      item.group.forEach((cont) => {
                        const fullName =
                          (cont?.export
                            ? cont?.pol?.facility_name
                            : cont?.pod?.facility_name) ??
                          cont.destination.facility_name ??
                          `${cont.destination.name}, ${cont.destination.state}`;

                        if (fullName === checkedTerminal) {
                          data.push(cont.container_id);
                        }
                      });
                    });
                    setChecked(data);
                  } else {
                    setChecked([]);
                    setCheckedTerminal("");
                  }
                  setCheckedAll((prev) => !prev);
                }}
              />
            }
            checked={checkedAll}
          />
          <p
            style={{
              fontWeight: "600",
              fontSize: smallLaptop ? "18px" : "24px",
              color: COLORS.purple,
              margin: 0,
            }}
          >
            Alerts
          </p>
        </div>
        <div style={{ display: "flex", alignItems: "center" }}>
          <p style={{ margin: 0, color: COLORS.dark_grey }}>Sort by:</p>
          <Select
            type="select"
            value={sortQuery}
            onChange={setSortQuery}
            placeholder="All"
            css={{
              width: smallLaptop ? "200px" : "224px",
              margin: " 0px 32px 0px 8px",

              backgroundColor: COLORS.off_black,
              border: "none",
              padding: "8px",
            }}
            cssDiv={{
              width: smallLaptop ? "200px" : "224px",
              marginLeft: "16px",
              backgroundColor: COLORS.off_black,
              color: COLORS.dark_grey,
            }}
            options={[
              "Detention First",
              "Demurrage First",
              "Price: Low to High",
              "Price: High to Low",
            ]}
            darkTheme={true}
            closeOnSelect={true}
          />
          <button
            onClick={() => {
              setClusterData({});
            }}
            style={{
              backgroundColor: "transparent",
              color: COLORS.activity,
              outline: "none",
              border: "none",
              fontSize: smallLaptop ? "10px" : "14px",
              fontWeight: "600",
            }}
          >
            Clear all
          </button>
        </div>
      </div>
      {no_content ? (
        <NoContentScreen title={"demurrage risk"} />
      ) : (
        <>
          <div
            ref={listRef}
            style={{
              width: "100%",
              height: `${
                document.getElementById("map_box")?.clientHeight - 120
              }px`,
              // height: window.innerWidth > 1920 ? "75vh" : "680px",
              display: "flex",
              flexDirection: "column",
              overflow: "auto",
              paddingTop: "11px",
            }}
          >
            {sortedContainer.length > 0 &&
              sortedContainer.map((item) => {
                return (
                  <AlertRowWithPayment
                    data={item}
                    key={item.index}
                    index={item.index}
                    handleClickChecked={handleClickChecked}
                    checked={checked}
                    handleFavorite={handleFavorite}
                    handleRead={handleRead}
                    setCurrentDisplayContainer={setCurrentDisplayContainer}
                    currentDisplayContainer={currentDisplayContainer}
                    setIsModalOpen={setIsModalOpen}
                    setGetContainerId={setGetContainerId}
                    getContainerId={getContainerId}
                    loadingContainer={loadingContainer}
                    setIsDetentionModalOpen={setIsDetentionModalOpen}
                    isInCluster={
                      clusterData?.ids &&
                      clusterData.ids.includes(item.container_id)
                    }
                    checkedTerminal={checkedTerminal}
                  />
                );
              })}
          </div>
          <div
            style={{
              width: "98%",
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              borderTop: `1px solid ${COLORS.dark_grey}CC`,
              paddingTop: "16px",
            }}
          >
            <p
              style={{
                margin: 0,
                color: COLORS.off_white,
                fontWeight: 600,
                fontSize: smallLaptop ? "18px" : "24px",
              }}
            >
              Total selected
            </p>
            <div style={{ display: "flex", alignItems: "center" }}>
              <p
                style={{
                  margin: 0,
                  fontWeight: 600,
                  color: COLORS.off_white,
                  marginRight: "16px",
                  fontSize: smallLaptop ? "11px" : "16px",
                }}
              >
                {formatValue(total)}
              </p>
              <button
                className="purple_button"
                onClick={() => {
                  if (checked.length > 0) {
                    setIsMultipleModalOpen(true);
                  }
                }}
              >
                Pay selection
              </button>
            </div>
          </div>
        </>
      )}
    </>
  );
};

export default AlertTableWithPayment;
