import React, { useEffect, useState } from "react";
// import {
//   OffsetProjectMock,
//   detailsPillData,
//   loremIpsum,
//   loremIpsum2,
// } from "../utils/offsetProjectsMock";
import { ReactComponent as HeartIcon } from "../../assets/offset_like.svg";
import PlantIcon from "../../assets/plant_icon.svg";

import "../../styles/offsetprojects.scss";
import EsgTextField from "../EsgTextFields";
import useStore from "../../../../store/store";
import OffsetMap from "./OffsetMap";
import {
  useHistory,
  useParams,
} from "react-router-dom/cjs/react-router-dom.min";
import OffsetBadges from "./OffsetBadges";
import { IconButton } from "@mui/material";
import CustomBadge from "../CustomBadge";
import CartIcon from "../../assets/cartIcon.svg";
import goldenStandardImage from "../../assets/goldstandrad.svg";
import { COLORS } from "../../../../styles/themes/default/colors";
import { toggleFavorite } from "../../utils/functions";
import esgStore from "../../../../store/esgStore";

const OffSetProjectDetail = (props) => {
  const param = useParams();
  const offsetProjects = esgStore((state) => state.offsetProjects);
  const setNavbarLabel = useStore((state) => state.setNavbarLabel);

  const [quantity, setQuantity] = useState(1);
  const history = useHistory();

  const data = offsetProjects.at(param.id);
  const [isFavorite, setIsFavorite] = useState(data.is_favorite);

  const setEsgCart = esgStore((state) => state.setEsgCart);
  const esgCart = esgStore((state) => state.esgCart);

  useEffect(() => {
    setNavbarLabel({
      label: "Renewable Offset Projects",
      back_link: "/esg/offset-projects",
    });
  });
  return (
    <div
      style={{
        width: "100%",
        height: "100%",
        display: "flex",
        flexDirection: "column",
      }}
    >
      <div
        style={{ width: "100%", display: "flex", justifyContent: "flex-end" }}
      >
        <div>
          <IconButton
            style={{ padding: "0", position: "relative" }}
            onClick={() => {
              history.push("/esg/cart");
            }}
          >
            <img src={CartIcon} alt="cart" />
            {esgCart && Object.keys(esgCart).length > 0 && (
              <CustomBadge number={Object.keys(esgCart).length} />
            )}
          </IconButton>
        </div>
      </div>
      <div
        className="offset_project_detail_box"
        style={{ flexGrow: 1, paddingBottom: "16px" }}
      >
        <div
          className="offset_project_detail_left_box"
          style={{ height: "100%", display: "flex", flexDirection: "column" }}
        >
          <div
            style={{
              flexGrow: 1,
              height: "0px",
              overflow: "auto",
              padding: "16px",
            }}
          >
            <div className="offset_header_div">
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                  width: "100%",
                }}
              >
                <div
                  style={{ display: "flex", gap: "24px", alignItems: "center" }}
                >
                  <p
                    className="offset_header_title"
                    style={{ fontSize: "24px" }}
                  >
                    {data.name}
                  </p>
                  <button
                    style={{ all: "unset", cursor: "pointer" }}
                    onClick={() => {
                      toggleFavorite(data.id);
                      setIsFavorite((prev) => !prev);
                    }}
                  >
                    <HeartIcon
                      style={{ transition: "0.3s all ease-in-out" }}
                      fill={isFavorite ? COLORS.purple : "transparent"}
                    />
                  </button>
                </div>
                <img src={goldenStandardImage} alt="logo" />
              </div>
            </div>
            <p
              className="offset_dark_grey_400"
              style={{ marginBottom: "16px" }}
            >
              {data.summary}
            </p>
            <img
              src={data.image_url}
              style={{
                width: "100%",
                marginBottom: "16px",
                height: "500px",
                objectFit: "cover",
                borderRadius: "8px",
              }}
              alt="main_picture"
            />

            {data.additional_info.details.map((item, index) => {
              return item.type === "h2" ? (
                <p
                  key={index}
                  className="offset_white_400"
                  style={{ marginBottom: "16px", fontSize: "24px" }}
                >
                  {item.text}
                </p>
              ) : (
                <p
                  key={index}
                  className="offset_white_400"
                  style={{ marginBottom: "24px" }}
                >
                  {item.text}
                </p>
              );
            })}
          </div>
        </div>
        <div className="offset_project_detail_rigth_box">
          <div className="offset_add_to_cart_div">
            <div style={{ display: "flex", alignItems: "center", gap: "24px" }}>
              <p className="offset_white_400">
                Total offset tCO<sub>2</sub>e
              </p>
              <div style={{ display: "flex", alignItems: "center" }}>
                <p className="offset_white_600">{data?.total_amount ?? 0}</p>
                <img src={PlantIcon} style={{ marginLeft: "8px" }} alt="" />
              </div>
            </div>
            <div style={{ display: "flex", alignItems: "center" }}>
              <div style={{ display: "flex", alignItems: "flex-end" }}>
                <p
                  className="offset_purple_600"
                  style={{
                    fontSize: "24px",
                    marginRight: "6px",
                    lineHeight: "24px",
                  }}
                >
                  ${parseFloat(data.price_value).toFixed(2)}
                </p>
                <p className="offset_purple_600">
                  / tCO<sub>2</sub>e
                </p>
              </div>
            </div>
            <div
              style={{
                display: "flex",
                gap: "8px",
                alignItems: "center",
              }}
            >
              <p className="offset_dark_grey_400">Quantity:</p>
              <EsgTextField
                setValue={setQuantity}
                value={quantity}
                type="number"
                variant="outlined"
                size="small"
                styles={{
                  borderRadius: "8px",
                  backgroundColor: "#313131",
                  width: "90px",
                  Input: {
                    color: "#8c8c8c",
                  },
                }}
              />
              <button
                className="offset_purple_button"
                style={{ flexGrow: 0, width: "auto" }}
                onClick={() => {
                  if (!Object.keys(esgCart).includes(param.id.toString())) {
                    setEsgCart({ ...esgCart, [param.id]: quantity });
                  }
                }}
              >
                Add to cart
              </button>
            </div>
          </div>
          <div className="offset_map_box">
            <p className="offset_white_600" style={{ marginBottom: "8px" }}>
              {data?.additional_info?.map_coordinates?.at(0)?.state
                ? `${data?.additional_info?.map_coordinates?.at(0)?.city}, ${
                    data?.additional_info?.map_coordinates?.at(0)?.state
                  }`
                : "Map location"}
            </p>
            <OffsetMap
              coordinates={data?.additional_info?.map_coordinates.at(0)}
            />
          </div>
          <div style={{ marginBottom: "16px" }}>
            <OffsetBadges
              size={"64px"}
              gap={"16px"}
              badges={data?.additional_info.sdg_badges}
            />
          </div>
          <div className="offset_other_projects_div">
            <p className="offset_white_600">
              Other projects that might interest you
            </p>
            <p
              className="link_paragraph"
              onClick={() => {
                history.push("/esg/offset-projects");
              }}
            >
              See all
            </p>
          </div>
          <div className="offset_other_projects_flex_div">
            {data.suggested_projects.slice(0, 3).map((item) => {
              let itemData = offsetProjects
                .filter((project) => project.id === item)
                .at(0);
              return (
                <div key={item} className="offset_other_projects_card">
                  <p className="offset_other_projects_card_title">
                    {itemData.name}
                  </p>
                  {/* <p
                    className="offset_dark_grey_400"
                    style={{ fontSize: "14px", marginBottom: "16px" }}
                  >
                    {itemData.summary}
                  </p> */}
                  <img
                    src={itemData.image_url}
                    alt="main_picture"
                    style={{
                      marginBottom: "16px",
                      width: "100%",
                      height: "150px",
                      objectFit: "cover",
                      borderRadius: "8px",
                    }}
                  />
                  <p
                    className="offset_dark_grey_400"
                    style={{
                      fontSize: "14px",
                      marginBottom: "16px",
                      overflow: "hidden",
                      maxHeight:
                        "4.8em" /* (Number of lines you want visible) * (line-height) */,
                      lineHeight: "1.6em",
                    }}
                  >
                    {itemData.summary}...
                  </p>
                  <div className="offset_other_projects_div">
                    <p
                      className="link_paragraph"
                      onClick={() => {
                        history.push(`/esg/offset-projects/${item}`);
                      }}
                    >
                      Read more
                    </p>
                    <div style={{ display: "flex" }}>
                      <p
                        className="offset_purple_600"
                        style={{
                          fontSize: "14px",
                          marginRight: "6px",
                        }}
                      >
                        ${parseFloat(itemData.price_value).toFixed(2)}
                      </p>
                      <p
                        className="offset_purple_600"
                        style={{ fontSize: "14px", textTransform: "lowercase" }}
                      >
                        {data.price_unit}
                      </p>
                    </div>
                  </div>
                  <OffsetBadges
                    size={"24px"}
                    gap={"8px"}
                    badges={itemData?.additional_info.sdg_badges}
                  />
                </div>
              );
            })}
          </div>
        </div>
      </div>
    </div>
  );
};

export default OffSetProjectDetail;
