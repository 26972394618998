import React from "react";

const NoContentScreen = ({ title }) => {
  return (
    <div className="no_content_div">
      <p className="no_content_dark_grey">
        There are currently no alerts regarding {title}
      </p>
      <p className="no_content_dark_grey">Smooth sailing</p>
      <p className="no_content_purple">Nice job!</p>
    </div>
  );
};

export default NoContentScreen;
