import { createTheme } from "@mui/material";
import { COLORS } from "./themes/default/colors";

const { palette } = createTheme();
const { augmentColor } = palette;
const createColor = (mainColor) => augmentColor({ color: { main: mainColor } });
const theme = createTheme({
  palette: {
    sunrise_orange: createColor(COLORS.sunrise_orange),
    safety_green: createColor(COLORS.safety_green),
  },
  typography: {
    fontFamily: "Montserrat",
  },
  components: {
    MuiCssBaseline: {
      styleOverrides: `
        ::-webkit-scrollbar {
          width: 10px;
          height: 10px;
        }
        ::-webkit-scrollbar-thumb {
          background: #888;
          border-radius: 10px;
        }
        ::-webkit-scrollbar-thumb:hover {
          background: #555;
        }
        `
    },
    MuiButton: {
      styleOverrides: {
        root: {
          "&:focus": {
            outline: "none",
          },
          "&:hover": {
            boxShadow: "none",
            outline: "none",
          },
        },
        outlined:{
          backgroundColor:COLORS.white
        },
        text:{
          backgroundColor:COLORS.white

        }
      },
    },
  },
});

export default theme;
