import React, { useEffect, useState } from "react";
import Chart from "react-apexcharts";
import FiberManualRecordIcon from "@mui/icons-material/FiberManualRecord";
import { useMediaQuery } from "@mui/material";

export default function ProcurementChart(props) {
  const [series, setSeries] = useState([]);
  let months = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec",
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec",
  ];
  const currentMonth = new Date().toLocaleString("en-us", {
    month: "short",
  });
  const indexOfMonth = months.lastIndexOf(currentMonth);
  let options = {
    colors: ["#FF1DA4", "#00FF65", "#00FFFF"],
    chart: {
      type: "area",
      zoom: {
        enabled: false,
      },
      toolbar: {
        show: false,
      },
    },
    grid: { show: true, strokeDashArray: 5 },
    dataLabels: {
      enabled: false,
    },
    stroke: {
      curve: "straight",
      width: 2,
    },
    fill: {
      type: "gradient",
      gradient: {
        shadeIntensity: 0,
        opacityFrom: 0.6,
        opacityTo: 0.1,
        stops: [0, 100],
      },
    },
    xaxis: {
      categories: months.slice(indexOfMonth - 5, indexOfMonth + 1),
      type: "value",
      labels: {
        style: {
          fontFamily: "Montserrat",
          fontSize: "12px",
          fontWeight: 600,
          colors: "#F1F1F1",
        },
      },
      axisBorder: {
        show: true,
        color: "#F1F1F1",
        height: 2,
        width: "100%",
      },
      axisTicks: {
        show: true,
        borderType: "solid",
        color: "#F1F1F1",
        height: 8,
      },
    },
    yaxis: {
      labels: {
        formatter: function (value) {
          return `${value} mi`;
        },
        categories: months.slice(indexOfMonth - 5, indexOfMonth + 1),
        min: 5,
        max: 25,
        style: {
          fontFamily: "Montserrat",
          colors: "#F1F1F1",
          fontWeight: 600,
          fontSize: "12px",
        },
      },
      axisBorder: {
        show: true,
        color: "#F1F1F1",
        width: 2,
        height: "100%",
      },
    },
    menu: {
      show: false,
    },
    legend: {
      horizontalAlign: "left",
      show: false,
    },
  };
  useEffect(() => {
    if (props.data) {
      let temp = [
        { name: "Biodiesel", data: [] },
        { name: "Hydrogen", data: [] },
        { name: "Electric", data: [] },
      ];
      props.data.forEach((item) => {
        temp[0].data.push(item["biodiesel_milage"] / 1000);
        temp[1].data.push(item["hydrogen_milage"] / 1000);
        temp[2].data.push(item["electric_milage"] / 1000);
      });
      setSeries(temp);
    }
  }, [props.data]);
  // const series = [
  //   {
  //     name: "Biodiesel",
  //     data: [5, 2, 8, 5, 19, 3],
  //   },
  //   {
  //     name: "Hydrogen",
  //     data: [5, 2, 18, 5, 9, 13],
  //   },
  //   {
  //     name: "Electric",
  //     data: [15, 22, 8, 15, 9, 7],
  //   },
  // ];

  const largeScreen = useMediaQuery("(min-height:1200px");

  return (
    <div className="procurementChart">
      <Chart
        options={options}
        series={series}
        type="area"
        height={largeScreen ? "100%" : 231}
      />
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "center",
          gap: 100,
        }}
      >
        <div className="procurementChartLegend">
          <FiberManualRecordIcon
            style={{ color: "#FF1DA4", width: "15px", marginRight: "10px" }}
          />
          <p>Biodiesel</p>
        </div>
        <div className="procurementChartLegend">
          <FiberManualRecordIcon
            style={{ color: "#0FF", width: "15px", marginRight: "10px" }}
          />
          <p>Hydrogen</p>
        </div>
        <div className="procurementChartLegend">
          <FiberManualRecordIcon
            style={{ color: "#00FF65", width: "15px", marginRight: "10px" }}
          />
          <p>Electric</p>
        </div>
      </div>
    </div>
  );
}
