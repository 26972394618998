import React from "react";
import ReductionRow from "./ReductionSandBox/ReductionRow";

const ReductionSandbox = (props) => {
  const handleChange = (name, value) => {
    props.setData((prevData) => ({
      ...prevData,
      [name]: value ? Number(value) : 0,
    }));
  };

  return (
    <div className="template_box w25 flex-shrink_box">
      <p className="fw600 c-white mb8">Emission reduction sandbox</p>
      <p className="c-dark-grey fs14 m0">
        Adjust the input will affect the timeline graph
      </p>
      <div className="d-flex f-dir-col gap24 pt16">
        <ReductionRow
          label="2015 Emissions"
          unit="tCO2e"
          name="firstEmission"
          value={props.data.firstEmission}
          changeValue={handleChange}
          starred
        />
        <ReductionRow
          label="2023 Emissions"
          unit="tCO2e"
          name="secondEmission"
          value={props.data.secondEmission}
          changeValue={handleChange}
          starred
        />
        <ReductionRow
          label="Annual grow projection"
          unit="%"
          name="annualGrowth"
          value={props.data.annualGrowth}
          changeValue={handleChange}
        />
        <ReductionRow
          label="Target 2015 reduction by 2030"
          unit="%"
          name="targetReduction"
          value={props.data.targetReduction}
          changeValue={handleChange}
        />
        <ReductionRow
          label="Annual top offset/emission"
          unit="%"
          name="annualTop"
          value={props.data.annualTop}
          changeValue={handleChange}
          toggleOffset={props.toggleOffset}
        />
        <ReductionRow
          label="Net 0 target year"
          unit=""
          name="targetYear"
          value={props.data.targetYear}
          changeValue={handleChange}
          year
        />
      </div>
    </div>
  );
};
export default ReductionSandbox;
