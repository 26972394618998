import React, { useState, useEffect } from "react";
import { useHistory, useLocation } from "react-router-dom";

import {
  Box,
  Drawer,
  ListItem,
  useMediaQuery,
  useTheme,
  styled,
  Divider,
} from "@mui/material";

import List from "@mui/material/List";

// import Role from "../../roles/role";
// import accountService from "../../services/accountService";

// BLACK ICONS IN CASE WE NEED THEM AGAIN
// import demurrageRequestIconBlack from "../assets/img/Demurrage_icon_black.webp";
// import logoutIcon from "../assets/img/Logout_Icon.webp";
// import settingIconBlack from "../assets/img/SettingsIconBlack.webp";
// import shipperIconBlack from "../assets/img/ShipperIconBLACK.webp";
// import UsersBlack from "../assets/img/UsersBlack.webp";
// import CompanyBlack from "../assets/img/company_information_black.png";
// import NotificationsBlack from "../assets/img/notification_settings_black.png";
// import demurrageListIconBlack from "../assets/img/Demurrage_list_black.webp";
// import settingsIcon from "../assets/img/SettingsIcon.webp";

// import helpIcon from "../assets/img/Sidebar/help_icon.svg";
import settingsIcon from "../assets/img/Sidebar/settings_icon.svg";
import logoutIcon from "../assets/img/Sidebar/logout_icon.svg";
import demurragesIcon from "../assets/img/Sidebar/demurrages_icon.svg";
import plusIcon from "../assets/img/Sidebar/plus_icon.svg";
import plusInactiveIcon from "../assets/img/Sidebar/plus_inactive.svg";

import usersIcon from "../assets/img/Sidebar/users_icon.svg";
import customerIcon from "../assets/img/Sidebar/customers_icon.svg";
import customerActiveIcon from "../assets/img/Sidebar/customers_icon_active.svg";

import containersIcon from "../assets/img/Sidebar/containers_icon.svg";
import containersIconActive from "../assets/img/Sidebar/containers_icon_active.svg";

import analyticsIcon from "../assets/img/Sidebar/analytics.svg";
import analyticsActiveIcon from "../assets/img/Sidebar/analytics_active.svg";

import esgIcon from "../assets/img/Sidebar/esg_icon.svg";
import esgActiveIcon from "../assets/img/Sidebar/esg_active_icon.svg";

import usersActiveIcon from "../assets/img/Sidebar/users_active.svg";
import demurragesActiveIcon from "../assets/img/Sidebar/demurrages_active.svg";

// import cdlLogo from "../assets/img/Sidebar/cdl_logo.svg";                                temporary for demo purposes
import batchLogo from "../assets/img/Sidebar/batch_pingvin.svg";
// import cdlLogoShort from "../assets/img/Sidebar/sidebar_icon_short.svg";                 temporary for demo purposes
import batchLogoShort from "../assets/img/Sidebar/pingvin.svg";

import { drawerWidth } from "../utils/constants";
import accountService from "../services/accountService";
import useStore from "../store/store";
import esgStore from "../store/esgStore";

const Sidebar = ({ cookie, setCookie, openDrawer, setOpenDrawer }) => {
  const history = useHistory();
  const currentUser = useStore((state) => state.currentUser);
  const setLoggedUser = useStore((state) => state.setUser);
  const setCart = esgStore((state) => state.setEsgCart);

  const openSidebar = useStore((state) => state.openSidebar);
  const setOpenSidebar = useStore((state) => state.setOpenSidebar);

  const theme = useTheme();
  const mdUp = useMediaQuery(theme.breakpoints.up("md"));
  const location = useLocation();
  const [path, setPath] = useState(location.pathname);

  useEffect(() => {
    setPath(location.pathname);
  }, [location.pathname]);

  if (!cookie) return null;

  const logout = () => {
    setCart("");
    setLoggedUser({});

    accountService.logout();
    setCookie();
  };

  const activeStyle = {
    fontSize: "1rem",
    height: "42px",
    width: openSidebar ? "188px" : "42px",
    padding: 0,
    backgroundColor: "#464646",
    borderRadius: "8px",
    fontWeight: 600,
    paddingLeft: "15px",
    height: "2.625rem",
    "&:hover": {
      backgroundColor: "#343434",
    },
    "@media only screen and (max-width: 1400px) and (min-width: 1280px)": {
      fontSize: "0.6875rem",
      width: openSidebar ? "8.3925rem" : "1.875rem",
      padding: "10px",
      height: "1.875rem",
    },
  };
  const displayStyle = {
    fontSize: "1rem",
    height: "42px",
    width: openSidebar ? "188px" : "42px",
    padding: "0",
    height: "2.625rem",
    color: "#BDBDBD",
    paddingLeft: "15px",
    borderRadius: "8px",
    transition: "0.3s ease-in-out all",
    "&:hover": {
      backgroundColor: "#343434",
    },
    "@media only screen and (max-width: 1400px) and (min-width: 1280px)": {
      fontSize: "0.6875rem",
      width: openSidebar ? "8.3925rem" : "1.875rem",
      padding: "0.625rem",
      height: "1.875rem",
    },
  };

  const DrawerFooter = styled(Box)(({ theme }) => ({
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    flexDirection: "column",
    paddingBottom: "35px",
  }));

  const DrawerHeader = styled(Box)(({ theme }) => ({
    display: "flex",
    paddingTop: "10px",
    justifyContent: "center",
    flexDirection: "column",
  }));
  const DrawerContainer = styled(Box)({
    color: "#ffffff",
    backgroundColor: "#1a1a1a",
    height: "100%",
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    transition: "0.3s ease-in-out all",
    alignItems: openSidebar ? "none" : "center",
  });
  const sidebarData = [
    {
      title: "New Demurrage",
      link: "/demurrage/request",
      icon: plusInactiveIcon,
      active_icon: plusIcon,
      roles: ["CA", "CR"],
    },
    {
      title: "D&D List",
      link: "/demurrage/list",
      icon: demurragesIcon,
      active_icon: demurragesActiveIcon,
      roles: ["ALL"],
    },

    {
      title: "ESG",
      link: "/esg",
      icon: esgIcon,
      active_icon: esgActiveIcon,
      roles: ["ALL"],
    },

    {
      title: "Containers",
      link: "/containers",
      icon: containersIcon,
      active_icon: containersIconActive,
      roles: ["ALL"],
    },
    {
      title: "Analytics",
      link: "/analytics",
      icon: analyticsIcon,
      active_icon: analyticsActiveIcon,
      roles: ["ALL"],
    },
  ];
  return (
    <>
      <Box
        sx={{
          width: !mdUp
            ? "0px"
            : openSidebar
            ? window.innerWidth > 1920
              ? "250px"
              : "14%"
            : window.innerWidth > 1920
            ? "110px"
            : "5%",
          height: "100%",
        }}
        aria-label="sidebar"
      >
        <Drawer
          variant={mdUp ? "permanent" : "temporary"}
          anchor="left"
          open={openSidebar}
          onClose={() => setOpenSidebar(false)}
          ModalProps={{
            keepMounted: true,
          }}
          transitionDuration={{ appear: 500, enter: 500, exit: 500 }}
          sx={{
            display: "block",
            height: "100%",
            "& .MuiDrawer-paper": {
              boxSizing: "border-box",
              width: !mdUp
                ? openSidebar
                  ? drawerWidth
                  : "0px"
                : openSidebar
                ? drawerWidth
                : window.innerWidth > 1920
                ? "100px"
                : "5%",
              overflow: "hidden",
              transition: "0.3s ease-in-out all",
              borderRight: "0px",
            },
          }}
        >
          <DrawerContainer>
            <DrawerHeader>
              <Box
                sx={{
                  width: "100%",
                  display: "flex",
                  justifyContent: "center",
                }}
              >
                <button
                  style={{
                    backgroundColor: "transparent",
                    border: "none",
                    outline: "none",
                  }}
                  onClick={() => {
                    history.push("/demurrage/list");
                  }}
                >
                  <img
                    src={openSidebar ? batchLogo : batchLogoShort} //          temporary for demo purposes
                    alt=""
                    style={{ width: openSidebar ? "113px" : "35px" }}
                  />
                </button>
              </Box>
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  flexDirection: "column",
                  width: "100%",
                  transition: "0.3s ease-in-out all",
                }}
              >
                <Box
                  sx={{
                    paddingTop: "24px",
                    // "@media only screen and (max-width: 1400px) and (min-width: 1280px)": {
                    //   // width: "100%",
                    // },
                  }}
                >
                  {sidebarData
                    .filter((item) =>
                      ["ALL", currentUser?.user?.role].some((el) =>
                        item.roles.includes(el)
                      )
                    )
                    .map((item, index) => {
                      return (
                        <div key={index + 2}>
                          <ListItem
                            button
                            onClick={(e) => {
                              history.push(item.link);
                              if (!mdUp) {
                                setOpenSidebar(false);
                              }
                            }}
                            sx={
                              path.startsWith(item.link)
                                ? activeStyle
                                : displayStyle
                            }
                          >
                            <img
                              src={
                                path.startsWith(item.link)
                                  ? item.active_icon
                                  : item.icon
                              }
                              className="sidebarIcon"
                              alt=""
                            />
                            <span
                              style={{
                                visibility: openSidebar ? "visible" : "hidden",
                              }}
                            >
                              {item.title}
                            </span>
                          </ListItem>
                          {!(item.title === "Analytics") && (
                            <Divider
                              sx={{
                                bgcolor: "#BDBDBD",
                                width: openSidebar ? "188px" : "42px",
                                opacity: 0.4,
                                "@media only screen and (max-width: 1400px) and (min-width: 1280px)":
                                  {
                                    width: openSidebar
                                      ? "8.3925rem"
                                      : "1.875rem",
                                  },
                              }}
                            />
                          )}
                        </div>
                      );
                    })}
                </Box>
              </Box>
            </DrawerHeader>
            <DrawerFooter>
              <List
                sx={
                  {
                    // "@media only screen and (max-width: 1400px) and (min-width: 1280px)": {
                    //   // width: "100%",
                    // },
                  }
                }
              >
                {/* <ListItem
                  button
                  key={0}
                  onClick={(e) => {
                    // history.push(item.link);
                  }}
                  sx={displayStyle}
                >
                  <img src={helpIcon} style={iconStyles} alt="" />
                  {openSidebar && "Help"}
                </ListItem> */}
                {/* <Divider
                  sx={{
                    bgcolor: "#BDBDBD",
                    width: openSidebar ? "188px" : "42px",
                    opacity: 0.4,
                  }}
                /> */}
                {!["SA", "SR"].includes(currentUser?.user?.role) ? (
                  <ListItem
                    button
                    onClick={(e) => {
                      history.push("/customers");
                    }}
                    sx={path === "/customers" ? activeStyle : displayStyle}
                  >
                    <img src={customerIcon} className="sidebarIcon" alt="" />
                    {openSidebar && "Customers"}
                  </ListItem>
                ) : (
                  <ListItem
                    button
                    onClick={(e) => {
                      history.push("/company");
                    }}
                    sx={path === "/company" ? activeStyle : displayStyle}
                  >
                    <img src={customerIcon} className="sidebarIcon" alt="" />
                    {openSidebar && "Company Info"}
                  </ListItem>
                )}

                <Divider
                  sx={{
                    bgcolor: "#BDBDBD",
                    width: openSidebar ? "188px" : "42px",
                    opacity: 0.4,
                    "@media only screen and (max-width: 1400px) and (min-width: 1280px)":
                      {
                        width: openSidebar ? "8.3925rem" : "1.875rem",
                      },
                  }}
                />
                <ListItem
                  button
                  onClick={(e) => {
                    history.push("/admin/users");
                  }}
                  sx={path === "/admin/users" ? activeStyle : displayStyle}
                >
                  <img
                    src={path === "/admin/users" ? usersActiveIcon : usersIcon}
                    className="sidebarIcon"
                    alt=""
                  />
                  {openSidebar && "Users"}
                </ListItem>
                <Divider
                  sx={{
                    bgcolor: "#BDBDBD",
                    width: openSidebar ? "188px" : "42px",
                    opacity: 0.4,
                    "@media only screen and (max-width: 1400px) and (min-width: 1280px)":
                      {
                        width: openSidebar ? "8.3925rem" : "1.875rem",
                      },
                  }}
                />
                <ListItem
                  button
                  onClick={(e) => {
                    history.push("/profile/notification/settings");
                  }}
                  sx={
                    path === "/profile/notification/settings"
                      ? activeStyle
                      : displayStyle
                  }
                >
                  <img src={settingsIcon} className="sidebarIcon" alt="" />
                  {openSidebar && "Settings"}
                </ListItem>
                <Divider
                  sx={{
                    bgcolor: "#BDBDBD",
                    width: openSidebar ? "188px" : "42px",
                    opacity: 0.4,
                    "@media only screen and (max-width: 1400px) and (min-width: 1280px)":
                      {
                        width: openSidebar ? "8.3925rem" : "1.875rem",
                      },
                  }}
                />
                <ListItem button onClick={logout} sx={displayStyle}>
                  <img src={logoutIcon} className="sidebarIcon" alt="" />
                  <span
                    style={{
                      visibility: openSidebar ? "block" : "hidden",
                    }}
                  >
                    Sign out
                  </span>
                </ListItem>
              </List>
            </DrawerFooter>
          </DrawerContainer>
        </Drawer>
      </Box>
    </>
  );
};

export default Sidebar;
