import { Subject } from "rxjs";
import { filter } from "rxjs/operators";
import { toast } from "react-toastify";

const alertSubject = new Subject();
const defaultId = "default-alert";

const alertService = {
  onAlert: function (id = defaultId) {
    return alertSubject.asObservable().pipe(filter((x) => x && x.id === id));
  },
  success: function (message) {
    toast.success(message);
  },
  error: function (error_code, message = "") {
    if (error_code === 400) {
      toast.error(message ? message : "Something went wrong");
    } else if (error_code === 500) {
      toast.error("Network error");
    } else if (error_code === 403) {
      toast.error("You don't have permission for this action");
    } else if (error_code === 413) {
      toast.error("File is too big");
    } else {
      toast.error(message ? message : "Error on our side");
    }
  },
  info: function (message) {
    toast.info(message);
  },
};

export default alertService;
